// LeaveButtons.js
import React from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import ButtonBlue from "../../shared/general/ButtonBlue";

const LeaveButtons = ({ onInfoClick, onQuotaClick, onHistoryClick }) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      gap: 2, 
      mt: 2 
    }}>
      <ButtonBlue 
        variant="contained" 
        onClick={onInfoClick} 
        fullWidth
      >
        {`${t("LeaveInformation")}`}
      </ButtonBlue>
      <ButtonBlue 
        variant="contained" 
        onClick={onQuotaClick}
        fullWidth
      >
        {`${t("AllLeaveRights")}`}
      </ButtonBlue>
      <ButtonBlue 
        variant="contained" 
        onClick={onHistoryClick}
        fullWidth
      >
        {`${t("LeaveRecord")}`}
      </ButtonBlue>
    </Box>
  );
};

export default LeaveButtons;