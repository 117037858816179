import dayjs from 'dayjs';
import ExcelJS from 'exceljs';

export const ExportExcel = async (t, data = []) => {

  const workBook = new ExcelJS.Workbook();

  const worksheet = workBook.addWorksheet("พนักงานลาออก");

  const headerRow = worksheet.addRow([
    t("EmployeeID"), t("FirstName"), t("LastName"), t("Division"), t("Department"), t("Section"), t("Position"), t("EmploymentType"), 
    t("ResignDate"), t("ResignationReason"), t("OtherReason")
  ]);

  const headerStyle = {
    font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFF' } },
    alignment: { horizontal: "center", vertical: 'middle' },
    fill: {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '081F5C' } // Grey background color
    },
  };

  headerRow.eachCell((cell) => {
    cell.style = headerStyle;
    cell.border = {
      top: { style: 'thin', color: { argb: '000000' } },
      left: { style: 'thin', color: { argb: '000000' } },
      bottom: { style: 'thin', color: { argb: '000000' } },
      right: { style: 'thin', color: { argb: '000000' } }
    };
  });

  headerRow.height = 50;

  const colWidths = [
    { key: "employeeID", width: 30 },
    { key: "firstname_TH", width: 50 },
    { key: "lastname_TH", width: 50 },
    { key: "divisionName", width: 50 },
    { key: "departmentName", width: 50 },
    { key: "sectionName", width: 50 },
    { key: "positionName", width: 50 },
    { key: "employmentTypeName", width: 50 },
    { key: "resignDate", width: 30 },
    { key: "resignationReason", width: 30 },
    { key: "resignationOtherReason", width: 30 },
  ];

  colWidths.forEach((col, index) => {
    worksheet.getColumn(index + 1).width = col.width;
  });

  data.map(item => {
    const row = [
      item.employeeID || "",
      item.firstname_TH || "",
      item.lastname_TH || "",
      item.divisionName || "",
      item.departmentName || "",
      item.sectionName || "",
      item.positionName || "",
      item.employmentTypeName || "",
      item.resignDate ? dayjs(item.resignDate).format("DD/MM/YYYY") : "",
      item.ResignationReason ? item.ResignationReason : "-",
      item.reasonResignOther ? item.reasonResignOther : "-",
    ]

    const excelRow = worksheet.addRow(row);
    const contentStyle = {
      font: { size: 18, name: 'TH SarabunPSK' },
      alignment: { horizontal: "center", vertical: 'middle' },
    };

    excelRow.eachCell((cell) => {
      cell.style = contentStyle;
      cell.border = {
        top: { style: 'thin', color: { argb: '000000' } },
        left: { style: 'thin', color: { argb: '000000' } },
        bottom: { style: 'thin', color: { argb: '000000' } },
        right: { style: 'thin', color: { argb: '000000' } }
      };
    });
  })

  workBook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${t("SummaryOfResignedEmployees")}.xlsx`;
    a.click();
    URL.revokeObjectURL(url);
  });
}