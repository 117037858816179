import DoneAll from "@mui/icons-material/DoneAll";
import { Avatar, Box, IconButton, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getLeaveRequest } from "../../../../../actions/employee";
import {
  approveRequestLeaveByAdmin,
  getAdminPendingRequestLeave,
  getAllLeaveWithDrawBy,
  updateApproveLeaveEmployeeBy,
} from "../../../../../actions/leave";
import fileService from "../../../../../services/file.service";
import ChipWithDrawCustom from "../../../shared/chipWithDrawCustom";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import StatusRequest from "../../../shared/general/stausRequest";
import TableCustom from "../../../shared/tableCustom";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import {
  getUserFullName,
  getUserPosition,
} from "../../../../../utils/userData";
import { getRoundNumber } from "../../../../../utils";
import { openNotificationAlert } from "../../../../../actions/notificationAlert";

const RequestLeave = (props) => {
  const { searchDate, selectedEmployee, setSnackBarConfig, setNumberOfList } =
    props;
  const { t, i18n } = useTranslation();

  const { result: leaveEmployeesList } = useSelector(
    (state) => state.leaveEmployees
  );
  const { result: leaveEmployeeWithdrawsList } = useSelector(
    (state) => state.leaveEmployeesWithDraw
  );

  const { result: adminPendingRequestLeave, isFetching: isFetchingAdminPendingRequestLeave } = useSelector(state => state.adminPendingRequestLeave);

  const { result: userProfile } = useSelector((state) => state.userProfile);

  const [pendingRows, setPendingRows] = useState([]);

  const [selectedRows, setSelectedRows] = useState([]);

  const dispatch = useDispatch();

  const getStatusManagerLV1 = (row) => {
    if(row.isDoubleApproval === 1){
      if(row.isManagerLV1Approve === 1){
        return 1
      } else if (row.isManagerLV1Approve === 0){
        return 0
      } else {
        return 2
      }
    } else if(row.isDoubleApproval === 0 && row.approvalLevel === 1){
      if(row.isManagerLV1Approve === 1){
        return 1
      } else if (row.isManagerLV1Approve === 0){
        return 0
      } else {
        return 2
      }
    } else if(row.isDoubleApproval === 0 && row.approvalLevel === 2){
      return 3
    }
  }

  const getStatusManagerLV2 = (row) => {
    if(row.isDoubleApproval === 1){ 
      if(row.isManagerLV1Approve === 1 && row.isManagerLV2Approve === 1){
        return 1
      } else if (row.isManagerLV1Approve === 1 && row.isManagerLV2Approve === 0){
        return 0
      } else if ((row.isManagerLV1Approve === 1 || row.isManagerLV1Approve === null) && row.isManagerLV2Approve === null) {
        return 2
      } else if (row.isManagerLV1Approve === 0) {
        return 3
      }
    } else if(row.isDoubleApproval === 0 && row.approvalLevel === 1){
      return 3
    } else if(row.isDoubleApproval === 0 && row.approvalLevel === 2){
      if(row.isManagerLV2Approve === 1){
        return 1
      } else if (row.isManagerLV2Approve === 0){
        return 0
      } else {
        return 2
      }
    }
  }

  const calculateLeaveDays = (startText, endText) => {
    const startDateTime = dayjs(startText, 'DD/MM/YYYY HH:mm');
    const endDateTime = dayjs(endText, 'DD/MM/YYYY HH:mm');

    if (startDateTime.isSame(endDateTime) && startDateTime.format('HH:mm') === '00:00' && endDateTime.format('HH:mm') === '00:00') {
      return 1;
    }

    if (startDateTime.format('HH:mm') === '00:00' && endDateTime.format('HH:mm') === '00:00') {
      const numberOfDays = endDateTime.diff(startDateTime, 'day');
      return numberOfDays;
    }

    const durationInHours = endDateTime.diff(startDateTime, 'hour', true);

    if (durationInHours < 24) {
      return durationInHours.toFixed(2);
    }

    const fullDays = endDateTime.startOf('day').diff(startDateTime.startOf('day'), 'day');
    const remainingHours = (endDateTime.diff(endDateTime.startOf('day'), 'hour', true)) +
      (24 - startDateTime.diff(startDateTime.startOf('day'), 'hour', true));

    return fullDays + (remainingHours / 24);
  };

  const columns = [
    {
      name: `${t("FullName")}`,
      minWidth: "230px",
      width: "230px",
      maxWidth: "230px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Avatar
            style={{
              marginRight: "8px",
              width: 40,
              height: 40,
              "& img": { objectFit: "contain" },
            }}
            src={row.imageURL}
          />
          <Box flexGrow={1} overflow="hidden">
          <Typography whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">{getUserFullName(row)}</Typography>
            <Typography color="text.third" fontSize="14px">
              {getUserPosition(row)}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      name: `${t("Type")}`,
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Fragment>
          {row.idLeaveEmployeesWithdraw && <ChipWithDrawCustom />}
          <Typography fontSize={14}>
            {i18n.resolvedLanguage === "th"
              ? row.leaveGroupName
              : row.leaveGroupName_EN? row.leaveGroupName_EN : row.leaveGroupName}
          </Typography>
          <Typography fontSize={14} color="text.secondary">
            {row.holidayName}
          </Typography>
        </Fragment>
      ),
    },
    {
      name: `${t("Start")}`,
      headerTextAlign: "center",
      minWidth: "130px",
      width: "130px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>
            {dayjs(row.start).format(
              i18n.resolvedLanguage === "th" ? "D MMM BBBB" : "D MMM YYYY"
            )}
          </Typography>
          {row.isFullDay === 1 ? (
            <Typography>{`${t("Full_day")}`}</Typography>
          ) : (
            <Typography>
              {dayjs(row.start).format(
                i18n.resolvedLanguage === "th" ? "HH:mm" : "h:mm A"
              )}
            </Typography>
          )}
        </Box>
      ),
    },
    {
      name: `${t("End")}`,
      headerTextAlign: "center",
      minWidth: "130px",
      width: "130px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>
            {dayjs(row.end).format(
              i18n.resolvedLanguage === "th" ? "D MMM BBBB" : "D MMM YYYY"
            )}
          </Typography>
          {row.isFullDay === 1 ? (
            <Typography>{`${t("Full_day")}`}</Typography>
          ) : (
            <Typography>
              {dayjs(row.end).format(
                i18n.resolvedLanguage === "th" ? "HH:mm" : "h:mm A"
              )}
            </Typography>
          )}
        </Box>
      ),
    },
    {
      name: `${t("totalUsedLeaves")}`,
      headerTextAlign: "center",
      minWidth: "130px",
      width: "130px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          {row.isLeaveCompensate === 0 ? (
            <Typography>
              {row.isFullDay === 0 && row.used && row.used > 0 ? getRoundNumber(row.used, 2, true): getRoundNumber(row.used, 2, true)} {t("Unit.Days")}
            </Typography>
          ) : row.isLeaveCompensate === 1 ? (
            <Typography>
              {row.used && row.used > 0 ? getRoundNumber(row.used, 2, true) : getRoundNumber(row.used, 2, true)} {t("Unit.Hours")}
            </Typography>
          ) : (
            <Typography>-</Typography>
          )}
        </Box>
      ),
    },
    {
      name: `${t("Reason")}`,
      headerTextAlign: "center",
      minWidth: "200px",
      width: "200px",
      cellRender: (row) => (
        <Box>
          <Typography color="text.third" fontSize="14px">
            {row.description}
          </Typography>
        </Box>
      ),
    },
    {
      name: t("File"),
      headerTextAlign: "center",
      minWidth: "130px",
      width: "130px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          {row.filename &&
            <IconButton
              style={{
                width: "40px",
              }}
              aria-label="file"
              onClick={async () => {
                await fileService.downloadBucketFile(`leave/${row.idCompanyCreate}/${row.idLeave}/${row.filename}`).then(res => {
                  const url = window.URL.createObjectURL(new Blob([res.data]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', row.filename);
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                });
              }}
            >
              <i className="fa-regular fa-file" style={{ color: "rgba(0, 0, 0, 0.54)", fontSize: "20px" }} />
            </IconButton>
          }
        </Box>
      ),
    },
    {
      name: `${t("ManagerLV")} 1`,
      minWidth: "280px",
      width: "280px",
      maxWidth: "280px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          {(row.managerGroupLV1ApproveBy || row.managerLV1ApproveBy) && (
            <Fragment>
              <StatusRequest
                boxSize={40}
                fontSize={22}
                borderRadius={8}
                status={getStatusManagerLV1(row)}
              />
              <Avatar
                sx={{
                  marginLeft: "8px",
                  marginRight: "8px",
                  width: 40,
                  height: 40,
                  "& img": { objectFit: "contain" },
                }}
                src={(row.managerLV1 && row.managerLV1.imageURL)? row.managerLV1.imageURL: null}
              />
              <Box flexGrow={1} overflow="hidden">
                {row.managerLV1.idManagerGroup && (
                  <Typography fontSize="14px" fontWeight="500" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                    {row.managerLV1.managerGroupName}
                  </Typography>
                )}
                {row.managerLV1.idEmployees && (
                  <Typography whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                    {getUserFullName({
                      firstname_TH: row.managerLV1.firstname_TH,
                      lastname_TH: row.managerLV1.lastname_TH,
                      firstname_EN: row.managerLV1.firstname_EN,
                      lastname_EN: row.managerLV1.lastname_EN,
                    })}
                  </Typography>
                )}
                {/* <Typography color="text.third" fontSize="14px">
                  {getUserPosition({
                    positionName: row.managerLV1_positionName,
                    positionName_EN: row.managerLV1_positionName_EN,
                  })}
                </Typography> */}
              </Box>
            </Fragment>
          )}
        </Box>
      ),
    },
    {
      name: `${t("ManagerLV")} 2`,
      minWidth: "280px",
      width: "280px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          {(row.managerGroupLV2ApproveBy || row.managerLV2ApproveBy) && (
            <Fragment>
              <StatusRequest
                boxSize={40}
                fontSize={22}
                borderRadius={8}
                status={getStatusManagerLV2(row)}
              />
              <Avatar
                sx={{
                  marginLeft: "8px",
                  marginRight: "8px",
                  width: 40,
                  height: 40,
                  "& img": { objectFit: "contain" },
                }}
                src={(row.managerLV2 && row.managerLV2.imageURL)? row.managerLV2.imageURL: null}
              />
              <Box flexGrow={1}>
                {row.managerLV2.idManagerGroup && (
                  <Typography fontSize="14px" fontWeight="500" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                    {row.managerLV2.managerGroupName}
                  </Typography>
                )}
                {row.managerLV2.idEmployees && (
                  <Typography whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                    {getUserFullName({
                      firstname_TH: row.managerLV2.firstname_TH,
                      lastname_TH: row.managerLV2.lastname_TH,
                      firstname_EN: row.managerLV2.firstname_EN,
                      lastname_EN: row.managerLV2.lastname_EN,
                    })}
                  </Typography>
                )}
                {/* <Typography color="text.third" fontSize="14px">
                  {getUserPosition({
                    positionName: row.managerLV1_positionName,
                    positionName_EN: row.managerLV1_positionName_EN,
                  })}
                </Typography> */}
              </Box>
            </Fragment>
          )}
        </Box>
      ),
    },
    {
      name: t("CreationDate"),
      headerTextAlign: "center",
      minWidth: "130px",
      width: "130px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography color="text.third" fontSize="14px">
            {row.createDate? dayjs(row.createDate).locale(i18n.language).format("D MMMM YYYY HH:mm"): "-"}
          </Typography>
        </Box>
      ),
    },
  ];

  const getStatusLeaveManagerApprove = (requestLeave) => {
    if (requestLeave.fillInApprove) {
      return 3;
    }

    if (
      requestLeave.idLeaveEmployeesWithDraw &&
      requestLeave.managerApprove === userProfile.idEmployees
    ) {
      if (requestLeave.isApprove === 1) {
        return 1;
      } else if (requestLeave.isApprove === 0) {
        return 0;
      } else {
        return 2;
      }
    }

    if (requestLeave.isApprove === 1) {
      return 1;
    } else if (requestLeave.isApprove === 0) {
      return 0;
    } else {
      return 2;
    }
  };

  const handleApprovalRequest = async (approve) => {

    // const idLeave = [];
    // const idLeaveEmployeesWithdraw = [];
    // const idLeaveWithdraw = [];

    const idLeaveLV1 = selectedRows.filter(item => {
      return (!item.idLeaveEmployeesWithdraw && item.isManagerLV1Approve === null && (item.isDoubleApproval === 1 || (item.isDoubleApproval === 0 && item.approvalLevel === 1)))
    }).map(item => item.idLeave)

    const idLeaveLV2 = selectedRows.filter(item => {
      return (!item.idLeaveEmployeesWithdraw && item.isManagerLV2Approve === null && (item.isDoubleApproval === 1 || (item.isDoubleApproval === 0 && item.approvalLevel === 2)))
    }).map(item => item.idLeave)

    const leaveEmployeeWithdraw = selectedRows.filter(item => {
      return (item.idLeaveEmployeesWithdraw && item.isApprove === null)
    })

    const idLeaveEmployeesWithdraw = leaveEmployeeWithdraw.map(item => item.idLeaveEmployeesWithdraw);
    const idLeaveWithdraw = leaveEmployeeWithdraw.map(item => item.idLeave);

    const formData = [
      {
        idLeaveLV1: idLeaveLV1,
        isManagerLV1Approve: approve,
        // managerLV1ApproveBy: userProfile.idEmployees,
        managerLV1ApproveDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
        // managerLV1Comment: comment? comment: null,
      },
      {
        idLeaveLV2: idLeaveLV2,
        isManagerLV2Approve: approve,
        // managerLV2ApproveBy: userProfile.idEmployees,
        managerLV2ApproveDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
        // managerLV2Comment: comment? comment: null,
      },
      {
        idLeaveEmployeesWithdraw: idLeaveEmployeesWithdraw,
        idLeave: idLeaveWithdraw,
        isApprove: approve,
        approveDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
        // commentManager: comment? comment: null,
      },
    ];

    console.log("formData", formData)

    const result = await dispatch(approveRequestLeaveByAdmin(formData))

    if(result && result.status === 200) {
      dispatch(openNotificationAlert({
        type: "success",
        message: t("DataSaveSuccessful"),
      }));
      setSelectedRows([])
      dispatch(getAdminPendingRequestLeave());
    } else {
      dispatch(openNotificationAlert({
        type: "error",
        message: t("AnErrorOccurred")
      }));
    }
    // setIsOpenRejectDialog(false)

    // selectedRows.map((x) => {
    //   if (x.idLeave && !x.idLeaveEmployeesWithdraw) {
    //     idLeave.push(x.idLeave);
    //   } else if (x.idLeaveEmployeesWithdraw) {
    //     idLeaveEmployeesWithdraw.push(x.idLeaveEmployeesWithdraw);
    //     idLeaveWithdraw.push(x.idLeave);
    //   }
    // });

    // const formData = [
    //   {
    //     idLeave: idLeave,
    //     isApprove: approve,
    //     approveDate: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    //   },
    //   {
    //     idLeaveEmployeesWithdraw: idLeaveEmployeesWithdraw,
    //     idLeave: idLeaveWithdraw,
    //     isApprove: approve,
    //     approveDate: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    //   },
    // ];

    // const result = await dispatch(
    //   updateApproveLeaveEmployeeBy("admin", formData)
    // );
    // if (result) {
    //   setSelectedRows([]);
    //   if (result.status === 200) {
    //     dispatch(getLeaveRequest("admin", searchDate));
    //     dispatch(getAllLeaveWithDrawBy("admin", searchDate));
    //     setSnackBarConfig({
    //       open: true,
    //       message: `${t("Successfully")}`,
    //       type: "success",
    //     });
    //   } else {
    //     setSnackBarConfig({
    //       open: true,
    //       message: `${t("Unsuccessful")}`,
    //       type: "error",
    //     });
    //   }
    // } else {
    //   setSnackBarConfig({
    //     open: true,
    //     message: `${t("Unsuccessful")}`,
    //     type: "error",
    //   });
    // }
  };

  useEffect(() => {
    const tempPending = [];

    adminPendingRequestLeave.map((r) => {
      if (
        // r.isApprove === null &&
        (selectedEmployee
          ? selectedEmployee.idEmployees === r.idEmployees
          : true)
        // && (
        //   r.idManager || r.idManagerGroup
        // )
      ) {
        tempPending.push(r);
      }
    });

    // leaveEmployeeWithdrawsList.map((r) => {
    //   if (
    //     r.isApprove === null &&
    //     (selectedEmployee
    //       ? selectedEmployee.idEmployees === r.idEmployees
    //       : true)
    //     && r.managerApprove
    //   ) {
    //     tempPending.push(r);
    //   }
    // });

    let pendingList = tempPending.sort((a,b) => new Date(b.createDate) - new Date(a.createDate))

    setPendingRows(pendingList);
    setNumberOfList(pendingList.length);

    // console.log(pendingList);
  }, [selectedEmployee, adminPendingRequestLeave]);

  return (
    <Box>
      {selectedRows.length > 0 && (
        <Box display="flex" justifyContent="flex-end" paddingBottom="24px">
          <ButtonBlue
            style={{ marginRight: 8 }}
            startIcon={<DoneAll />}
            variant="contained"
            onClick={() => handleApprovalRequest(1)}
          >
            {`${t("Approved")} ${selectedRows.length > 0
              ? `${selectedRows.length} ${t("List")}`
              : ""
              }`}
          </ButtonBlue>
          <ButtonBlue
            startIcon={<DoneAll />}
            variant="outlined"
            onClick={() => handleApprovalRequest(0)}
          >
            {`${t("NotApproved")} ${selectedRows.length > 0
              ? `${selectedRows.length} ${t("List")}`
              : ""
              }`}
          </ButtonBlue>
        </Box>
      )}

      <TableCustom
        columns={columns}
        rows={adminPendingRequestLeave? pendingRows: []}
        canSelect={userProfile.isSuperAdmin === 1}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />
    </Box>
  );
};

export default RequestLeave;
