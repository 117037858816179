import { httpClient } from "./httpClient";

const getCompanyProfile = (query) => {
  return httpClient.get(`/company/profile`, { params: query });
};

const updateCompanyProfile = (formData) => {
  return httpClient.put(`/company/profile`, formData);
};

const putCompanyProfileMainContact = (id, formData) => {
  return httpClient.put(`/company/${id}/profile/main-contact`, formData);
};

const postCompanyAddress = (formData) => {
  return httpClient.post(`/company/${formData.idCompany}/address`, formData);
};

const putCompanyAddress = (id, formData) => {
  return httpClient.put(
    `/company/${id}/address/${formData.idCompanyAddress}`,
    formData
  );
};

const getCompanyOrganization = () => {
  return httpClient.get(`/company/organization`);
};

const getDepartment = (query) => {
  return httpClient.get(`/departments`, { params: query });
};

const getDepartmentByIdCompany = (id) => {
  return httpClient.get(`/departments/${id}`);
};

const getWorkInfoByidCompany = (query) => {
  return httpClient.get(`work-info`, { params: query });
};

const addCompanyLogo = (formData) => {
  return httpClient.post("company/manage/logo", formData);
};

const deleteCompanyLogo = (query) => {
  return httpClient.delete("company/manage/logo", { params: query });
};

const addCompanyAuthorizedSignatureOne = (formData) => {
  return httpClient.post("company/manage/authorizedSignatureOne", formData);
};

const deleteCompanyAuthorizedSignatureOne = (query) => {
  return httpClient.delete("company/manage/authorizedSignatureOne", {
    params: query,
  });
};

const addCompanyAuthorizedSignatureTwo = (formData) => {
  return httpClient.post("company/manage/authorizedSignatureTwo", formData);
};

const deleteCompanyAuthorizedSignatureTwo = (query) => {
  return httpClient.delete("company/manage/authorizedSignatureTwo", {
    params: query,
  });
};

const addCompanyWitnessSignature = (formData) => {
  return httpClient.post("company/manage/witnessSignature", formData);
};

const deleteCompanyWitnessSignature = (query) => {
  return httpClient.delete("company/manage/witnessSignature", {
    params: query,
  });
};

const addCompanyCertificateSignature = (formData) => {
  return httpClient.post("company/manage/certificateSignature", formData);
};

const deleteCompanyCertificateSignature = (query) => {
  return httpClient.delete("company/manage/certificateSignature", {
    params: query,
  });
};

const getallCompany = () => {
  return httpClient.get(`/company/allcompany`);
};

const getStandardMenuCompany = () => {
  return httpClient.get(`/standard-menu`);
};

const getAffiliate = () => {
  return httpClient.get(`/affiliate`);
};

const getAffiliateOrganization = () => {
  return httpClient.get(`/affiliate/organization`);
};

const getAllAdmin = () => {
  return httpClient.get(`/admin`);
};

const updateAdmin = (formData) => {
  return httpClient.put(`/admin`, formData);
};

const getCompanyOrganizationList = (query) => {
  return httpClient.get(`/company/organization-list`, { params: query });
};

const getAllBusinessUnit = (idCompany) => {
  return httpClient.get("business-units", { params: { idCompany } });
};

const getAllDivision = (idCompany) => {
  return httpClient.get("divisions", { params: { idCompany } });
};

const updateAdminBusinessUnit = (formData) => {
  return httpClient.put(`admin/business-unit`, formData);
};

const updateAdminDivision = (formData) => {
  return httpClient.put(`admin/division`, formData);
};

const getSignatureCertificateCompany = (idCompany) => {
  return httpClient.get(`signatureCompany`, { params: { idCompany: idCompany } });
};

const updatePaymentDetail = (id, formData) => {
  return httpClient.put(`/company/${id}/payment`, formData);
};

export default {
  getCompanyProfile,
  putCompanyProfileMainContact,
  postCompanyAddress,
  putCompanyAddress,
  getCompanyOrganization,
  getDepartment,
  updateCompanyProfile,
  getWorkInfoByidCompany,
  addCompanyLogo,
  deleteCompanyLogo,
  addCompanyAuthorizedSignatureOne,
  deleteCompanyAuthorizedSignatureOne,
  addCompanyAuthorizedSignatureTwo,
  deleteCompanyAuthorizedSignatureTwo,
  addCompanyWitnessSignature,
  deleteCompanyWitnessSignature,
  getallCompany,
  getStandardMenuCompany,
  addCompanyCertificateSignature,
  deleteCompanyCertificateSignature,
  getAffiliate,
  getAffiliateOrganization,
  getAllAdmin,
  updateAdmin,
  getDepartmentByIdCompany,
  getCompanyOrganizationList,
  getAllBusinessUnit,
  getAllDivision,
  updateAdminBusinessUnit,
  updateAdminDivision,
  getSignatureCertificateCompany,
  updatePaymentDetail,
};
