import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { TextField, Grid, FormControl, Select, MenuItem } from "@mui/material";

import AlertResponse from "../../../shared/general/AlertResponse";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../shared/general/Drawer";
import { getDepartment } from "../../../../../actions/company";
import {
  Addterminatelist,
  EditTerminatelist,
} from "../../../../../actions/offBoarding";

// Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .GridTopicInput": {
    display: "flex",
    alignItems: "center",
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const DialogName = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { open, mode, handleClose, data } = props;
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const [formData, setFormData] = useState(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    dispatch(getDepartment());
    if (open) {
      if (mode === "Edit") {
        setFormData({
          name: data.name,
          idTerminateList: data.idTerminateList,

          assignmentType: data.assignmentType || "all",
        });
      } else {
        setFormData({
          name: "",
          day: "",
          assignmentType: "all",
        });
      }
    }
  }, [open]);

  const handleSubmit = async () => {
    if (!formData.name) {
      handleOpenAlert(true);
      handleChangeAlertType(`${t("PleaseFillInRequiredInformations")}`);
    } else {
      if (mode === "AddList") {
        const data = {
          name: formData.name,
          idCompany: userProfile.idCompany,
          assignmentType: formData.assignmentType,
        };
        console.log(data);
        const result = await dispatch(Addterminatelist(data));
        if (result) {
          handleClose();
        } else {
          handleOpenAlert(true);
          handleChangeAlertType("error");
        }
      } else {
        const data = {
          name: formData.name,
          idTerminateList: formData.idTerminateList,

          assignmentType: formData.assignmentType,
        };
        const result = await dispatch(EditTerminatelist(data));
        if (result) {
          handleClose();
        } else {
          handleOpenAlert(true);
          handleChangeAlertType("error");
        }
      }
    }
  };

  return (
    <DrawerCustom
      title={
        mode === "Edit" ? t("EditResignationList") : t("AddResignationList")
      }
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      {formData && (
        <StyledRoot>
          <Grid container spacing={2} style={{ marginBottom: 16, width: 400 }}>
            <Grid item xs={12} sm={6} className="GridTopicInput">
              {t("ResignationName")}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                name="name"
                multiline
                value={formData.name}
                onChange={handleChange}
                margin="dense"
                fullWidth
              />
            </Grid>
          </Grid>
          {/* Add more fields similar to OnBoarding */}
          <Grid container spacing={2} style={{ marginBottom: 16, width: 400 }}>
            <Grid item xs={12} sm={6} className="GridTopicInput">
              {t("ProceedInternally")} ({t("Unit.Days")})
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                name="day"
                multiline
                value={formData.day}
                onChange={handleChange}
                margin="dense"
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginBottom: 16, width: 400 }}>
            <Grid item xs={12} sm={6} className="GridTopicInput">
              {t("AssignmentType")}
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Select
                  name="assignmentType"
                  value={formData.assignmentType}
                  onChange={handleChange}
                  variant="outlined"
                >
                  <MenuItem value="all">{t("All")}</MenuItem>
                  <MenuItem value="individual">{t("Individual")}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <StyledFooter>
            <ButtonBlue className="cancel" onClick={handleClose}>
              {t("Cancel")}
            </ButtonBlue>
            <ButtonBlue variant="contained" onClick={handleSubmit} autoFocus>
              {t("SaveData")}
            </ButtonBlue>
          </StyledFooter>
        </StyledRoot>
      )}
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </DrawerCustom>
  );
};

export default DialogName;
