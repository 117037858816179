import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  Fragment,
} from "react";
import { useTranslation } from "react-i18next";
import ExcelJS from "exceljs";
import DataGrid, {
  Button,
  Column,
  Paging,
  Pager,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Scrolling,
  Sorting,
  Selection,
  TotalItem,
  Summary,
  Export,
  ColumnChooser,
  ColumnChooserSearch,
  ColumnChooserSelection,
  Position,
} from "devextreme-react/data-grid";
import dayjs from "dayjs";

export const PayrollDataGridCard = (props) => {
  const {
    customizeDate,
    customizeHours,
    customizeNumber,
    onSelectionChanged,
    onExporting,
    doCompare,
    handleClickIndividual,
    gridColumnsOT,
    payRunDetail,
    data,
    gridColumnsShift,
    gridColumnsDeduction,
    gridColumnsAddition,
    selectedColumns,
    lastPayrun,
    selectedEmployees,
  } = props;

  const { t, i18n } = useTranslation();

  const onExportingFilteringEmp = (data, selectedColumn) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(t("PayrollDifferenceReport"));

    const headerRow = worksheet.addRow([
      t("Period"),
      t("EmployeeID"),
      t("Title"),
      t("FirstName"),
      t("LastName"),
      t("Division"),
      t("Department"),
      t("Section"),
      t("Position"),
      `${selectedColumn.name}`,
    ]);

    headerRow.height = 50;

    const headerStyle = {
      font: { bold: true, size: 18, name: "TH SarabunPSK" },
      alignment: { horizontal: "center", vertical: "middle" },
      fill: {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "DDDDDD" },
      },
    };

    headerRow.eachCell((cell) => {
      cell.style = headerStyle;
      cell.border = {
        top: { style: "thin", color: { argb: "000000" } },
        left: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
        right: { style: "thin", color: { argb: "000000" } },
      };
    });

    data &&
      data.map((item) => {
        const row = [
          dayjs(item.monthPeriod).format("YYYY-MM-DD"),
          item.employeeID,
          item.title_TH,
          item.firstname_TH,
          item.lastname_TH,
          item.divisionName,
          item.departmentName,
          item.sectionName,
          item.positionName,
          item[selectedColumn.dataField], // Assuming selectedColumn.dataField holds the correct field name
        ];

        const excelRow = worksheet.addRow(row);

        // Apply font size to the entire row
        excelRow.font = { size: 16, name: "TH SarabunPSK" };

        // Apply black border for every column
        excelRow.eachCell((cell) => {
          cell.border = {
            top: { style: "thin", color: { argb: "000000" } },
            left: { style: "thin", color: { argb: "000000" } },
            bottom: { style: "thin", color: { argb: "000000" } },
            right: { style: "thin", color: { argb: "000000" } },
          };
        });
      });

    const colWidths = [
      { key: "monthPeriod", width: 20 },
      { key: "employeeID", width: 20 },
      { key: "firstname_TH", width: 20 },
      { key: "lastname_TH", width: 20 },
      { key: "divisionName", width: 30 },
      { key: "departmentName", width: 30 },
      { key: "sectionName", width: 20 },
      { key: "positionName", width: 30 },
      { key: `${selectedColumn.name}`, width: 30 },
    ];

    colWidths.forEach(
      (col, index) => (worksheet.getColumn(index + 1).width = col.width)
    );

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${t("PayrollDifferenceReport")}.xlsx`;
      a.click();
      URL.revokeObjectURL(url);
    });
  };

  return (
    <div>
      {selectedColumns ? (
        <DataGrid
          dataSource={
            lastPayrun && lastPayrun.length > 0
              ? selectedEmployees
                ? lastPayrun.filter(
                    (item) => item.idEmployees === selectedEmployees
                  )
                : lastPayrun
              : data
              ? data
              : []
          }
          showBorders={false}
          showColumnLines={false}
          rowAlternationEnabled={true}
          columnAutoWidth={true}
          onSelectionChanged={onSelectionChanged}
          onExporting={() =>
            onExportingFilteringEmp(
              lastPayrun && lastPayrun.length > 0
                ? lastPayrun
                : data
                ? data
                : [],
              selectedColumns ? selectedColumns : null
            )
          }
        >
          <Column
            caption={`${t("Period")}`}
            dataField="monthPeriod"
            width={120}
            dataType="date"
            alignment="center"
            cssClass="column-info"
            customizeText={customizeDate}
          />

          <Column
            caption={`${t("EmployeeID")}`}
            dataField="employeeID"
            dataType="string"
            width={120}
            cssClass="column-info"
          />

          <Column
            caption={`${t("Title")}`}
            dataType="string"
            cssClass="column-info"
            dataField="title_TH"
          />
          <Column
            caption={`${t("FirstName")}`}
            dataType="string"
            cssClass="column-info"
            dataField="firstname_TH"
          />
          <Column
            caption={`${t("LastName")}`}
            dataType="string"
            cssClass="column-info"
            dataField="lastname_TH"
          />

          <Column
            dataField="divisionName"
            caption={`${t("Division")}`}
            dataType="string"
            cssClass="column-info"
          />
          <Column
            dataField="departmentName"
            caption={`${t("Department")}`}
            dataType="string"
            cssClass="column-info"
          />
          <Column
            dataField="sectionName"
            caption={`${t("Section")}`}
            dataType="string"
            cssClass="column-info"
          />

          <Column
            dataField="positionName"
            caption={`${t("Position")}`}
            dataType="string"
            cssClass="column-info"
          />

          {(selectedColumns || (selectedColumns && selectedEmployees)) && (
            <Column
              key={selectedColumns.dataField}
              caption={selectedColumns.name}
              dataField={selectedColumns.dataField}
              format="###,###,##0.00"
              cssClass="column-info"
            />
          )}

          <Export enabled={true} fileName={t("PayrollDifferenceReport")} />

          {/* {selectedColumnList && selectedColumnList.map(column => (
                        <Column
                            key={column.dataField}
                            caption={column.name}
                            dataField={column.dataField}
                            format="###,###,##0.00"
                            cssClass="column-info"
                        />
                    ))} */}
        </DataGrid>
      ) : (
        <DataGrid
          dataSource={
            selectedEmployees
              ? data.filter((item) => item.idEmployees === selectedEmployees)
              : data
              ? data
              : []
          }
          showBorders={false}
          showColumnLines={false}
          rowAlternationEnabled={true}
          columnAutoWidth={true}
          onSelectionChanged={onSelectionChanged}
          onExporting={onExporting}
        >
          <ColumnChooser enabled={true} mode={"select"}>
            <Position
              my="right top"
              at="right bottom"
              of=".dx-datagrid-column-chooser-button"
            />

            <ColumnChooserSearch
              enabled={true}
              editorOptions={{ placeholder: "Search column" }}
            />

            <ColumnChooserSelection
              allowSelectAll={true}
              selectByClick={true}
            />
          </ColumnChooser>

          {!doCompare && (
            <Column
              type="buttons"
              width={60}
              alignment="center"
              cssClass="column-info"
            >
              <Button
                icon="fa-regular fa-pen-to-square"
                onClick={handleClickIndividual}
              />
            </Column>
          )}

          <Column
            caption={`${t("No_Number")}`}
            dataField="index"
            width={60}
            allowFiltering={false}
            alignment="center"
            cssClass="column-info"
          />

          <Column
            caption={`${t("EmployeeID")}`}
            dataField="employeeID"
            dataType="string"
            width={120}
            cssClass="column-info"
          />

          <Column
            caption={`${t("Title")}`}
            dataType="string"
            cssClass="column-info"
            dataField="title_TH"
          />
          <Column
            caption={`${t("FirstName")}`}
            dataType="string"
            cssClass="column-info"
            dataField="firstname_TH"
          >
            <HeaderFilter allowSearch={true} />
          </Column>
          <Column
            caption={`${t("LastName")}`}
            dataType="string"
            cssClass="column-info"
            dataField="lastname_TH"
          >
            <HeaderFilter allowSearch={true} />
          </Column>

          {!doCompare && (
            <Column
              dataField="personalID"
              caption={`${t("PersonalID")}`}
              cssClass="column-info"
            />
          )}

          <Column
            dataField="divisionName"
            caption={`${t("Division")}`}
            dataType="string"
            cssClass="column-info"
          />
          <Column
            dataField="departmentName"
            caption={`${t("Department")}`}
            dataType="string"
            cssClass="column-info"
          />
          <Column
            dataField="sectionName"
            caption={`${t("Section")}`}
            dataType="string"
            cssClass="column-info"
          />

          <Column
            dataField="positionName"
            caption={`${t("Position")}`}
            dataType="string"
            cssClass="column-info"
          />

          {/* <Column
                            dataField="workingType"
                            caption={`${t("EmployeeType")}`}
                            dataType="string"
                            cssClass="column-info"
                        /> */}

          {!doCompare && (
            <Column
              dataField="paymentType"
              caption={`${t("Payment")}`}
              dataType="string"
              cssClass="column-info"
            />
          )}

          {!doCompare && (
            <Column
              dataField="costCenterCharge"
              caption="Cost Center"
              dataType="string"
              cssClass="column-info"
            />
          )}

          {!doCompare && (
            <Column
              dataField="employeeStatusType"
              caption={`${t("EmployeeStatus")}`}
              dataType="string"
              cssClass="column-info"
            />
          )}

          {!doCompare && (
            <Column
              dataField="workingDay"
              caption={`${t("WorkingDays")}`}
              dataType="string"
              cssClass="column-info"
              alignment="center"
            />
          )}

          <Column
            dataField="salaryPaid"
            caption={`${t("Salary")}`}
            format="###,###,##0.00"
            cssClass="column-salary"
            minWidth={100}
          />

          {/* <Column
                            dataField="salaryActual"
                            caption={`${t("WageAfterDeducting")}`}
                            format="###,###,##0.00"
                            cssClass="column-salary"
                            minWidth={100}
                        /> */}

          {!doCompare &&
            gridColumnsOT.map((item, key) => (
              <Column
                dataField={item.dataField}
                caption={item.caption}
                format={item.format}
                cssClass={item.cssClass}
              />
            ))}

          {!doCompare && (
            <Column
              dataField="totalOTHours"
              caption={`รวม OT (${t("Unit.ShortHours")})`}
              format="###,###,##0.00"
              cssClass="column-ot"
            />
          )}

          <Column
            dataField="totalOT"
            caption={`รวม OT (${t(
              `CurrencyUnit.${payRunDetail.paymentCurrency}`
            )})`}
            format="###,###,##0.00"
            cssClass="column-ot"
          />

          {!doCompare &&
            gridColumnsShift.map((item, key) => (
              <Column
                dataField={item.dataField}
                caption={item.caption}
                format="###,###,##0.00"
                cssClass={item.cssClass}
              />
            ))}

          <Column
            dataField="totalShiftPay"
            caption="รวมค่ากะ"
            format="###,###,##0.00"
            cssClass="column-shift"
          />

          {!doCompare &&
            gridColumnsAddition.map((item, key) => (
              <Column
                dataField={item.dataField}
                caption={item.caption}
                format="###,###,##0.00"
                cssClass={item.cssClass}
              />
            ))}

          <Column
            dataField="additionsTaxable"
            caption="รวมเงินเพิ่ม(คำนวนภาษี)"
            format="###,###,##0.00"
            cssClass="column-total-earnings"
          />

          <Column
            dataField="totalEarnings"
            caption="รวมเงินได้"
            format="###,###,##0.00"
            cssClass="column-total-earnings-all"
          />

          {!doCompare && (
            <Column
              dataField="pfName"
              caption="ชื่อกองทุนสำรองเลี้ยงชีพ"
              cssClass="column-info"
            />
          )}

          {!doCompare && (
            <Column
              dataField="pfCompanyPercent"
              caption={`กองทุนสำรองเลี้ยงชีพส่วนบริษัท (%)`}
              cssClass="column-info"
            />
          )}

          <Column
            dataField="pfCompanyTHB"
            caption={`กองทุนสำรองเลี้ยงชีพส่วนบริษัท (${t(
              `CurrencyUnit.${payRunDetail.paymentCurrency}`
            )})`}
            format="###,###,##0.00"
            cssClass="column-info"
          />

          {!doCompare && (
            <Column
              dataField="pfEmployeePercent"
              caption={`กองทุนสำรองเลี้ยงชีพส่วนพนักงาน (%)`}
              cssClass="column-info"
            />
          )}

          <Column
            dataField="pfEmployeeTHB"
            caption={`กองทุนสำรองเลี้ยงชีพส่วนพนักงาน (${t(
              `CurrencyUnit.${payRunDetail.paymentCurrency}`
            )})`}
            format="###,###,##0.00"
            cssClass="column-pf"
          />

          <Column
            dataField="ssoEmployee"
            caption="ประกันสังคม"
            format="###,###,##0.00"
            cssClass="column-sso"
          />

          <Column
            dataField="tax"
            caption="ภาษี"
            format="###,###,##0.00"
            cssClass="column-tax"
          />

          {!doCompare && (
            <Column
              dataField="lateEarlyMinute"
              caption={`มาสาย/กลับก่อน (นาที)`}
              format="###,###,##0.00"
              cssClass="column-tax"
            />
          )}

          {!doCompare && (
            <Column
              dataField="lateEarly"
              caption={`มาสาย/กลับก่อน (${t(
                `CurrencyUnit.${payRunDetail.paymentCurrency}`
              )})`}
              format="###,###,##0.00"
              cssClass="column-tax"
            />
          )}

          {!doCompare && (
            <Column
              dataField="absentDay"
              caption={`ขาดงาน (วัน)`}
              format="###,###,##0.00"
              cssClass="column-tax"
            />
          )}

          {!doCompare && (
            <Column
              dataField="absent"
              caption={`ขาดงาน (${t(
                `CurrencyUnit.${payRunDetail.paymentCurrency}`
              )})`}
              format="###,###,##0.00"
              cssClass="column-tax"
            />
          )}

          {!doCompare && (
            <Column
              dataField="leaveWithoutPayDay"
              caption={`ลาไม่รับค่าจ้าง (วัน)`}
              format="###,###,##0.00"
              cssClass="column-tax"
            />
          )}

          {!doCompare && (
            <Column
              dataField="leaveWithoutPay"
              caption={`ลาไม่รับค่าจ้าง (${t(
                `CurrencyUnit.${payRunDetail.paymentCurrency}`
              )})`}
              format="###,###,##0.00"
              cssClass="column-tax"
            />
          )}

          {!doCompare &&
            gridColumnsDeduction.map((item, key) => (
              <Column
                dataField={item.dataField}
                caption={item.caption}
                format={item.format}
                cssClass={item.cssClass}
              />
            ))}

          <Column
            dataField="deductionTaxable"
            caption="รวมเงินหัก(คำนวนภาษี)"
            format="###,###,##0.00"
            cssClass="column-total-deduction"
          />

          <Column
            dataField="totalDeductions"
            caption="รวมเงินหัก"
            format="###,###,##0.00"
            cssClass="column-total-deduction-all"
          />

          {
            payRunDetail.idCompany === 74 && <Column
            dataField="netAll"
            caption="เงินได้ทั้งหมด"
            format="###,###,##0.00"
            cssClass="column-net"
          />
          }
          {
            payRunDetail.idCompany === 74 && <Column
            dataField="netAboard"
            caption="เงินได้ส่วนต่างประเทศ"
            format="###,###,##0.00"
            cssClass="column-net"
          />
          }

          <Column
            dataField="net"
            caption="เงินได้สุทธิ"
            format="###,###,##0.00"
            cssClass="column-net"
          />

          {!doCompare && (
            <Column
              dataField="bookBank"
              caption="ชื่อธนาคาร"
              cssClass="column-info"
            />
          )}

          {!doCompare && (
            <Column
              dataField="bookID"
              caption="เลขที่บัญชีธนาคาร"
              cssClass="column-info"
            />
          )}

          {/* <Column
                            dataField="ytdIrregular"
                            caption="รวมเงินได้ทั้งปี(ไม่คงที่)"
                            cssClass="column-accumulate"
                            format="###,###,##0.00"
                    /> */}

          {!doCompare && (
            <Column
              dataField="accumulateEarnings"
              caption="เงินได้สะสม"
              cssClass="column-accumulate"
              format="###,###,##0.00"
            />
          )}

          {!doCompare && (
            <Column
              dataField="accumulateTax"
              caption="ภาษีสะสม"
              cssClass="column-accumulate"
              format="###,###,##0.00"
            />
          )}

          {!doCompare && (
            <Column
              dataField="accumulateSSO"
              caption="ประกันสังคมสะสม"
              cssClass="column-accumulate"
              format="###,###,##0.00"
            />
          )}
          {!doCompare && (
            <Column
              dataField="accumulatePF"
              caption="กองทุนสำรองเลี้ยงชีพสะสม"
              cssClass="column-accumulate"
              format="###,###,##0.00"
            />
          )}

          {!doCompare && (
            <Column
              dataField="isNetLessThenZero"
              caption="หมายเหตุ"
              cssClass="column-net"
            />
          )}

          <Export enabled={true} allowExportSelectedData={true} />
          <Paging defaultPageSize={20} />
          <Pager
            visible={true}
            allowedPageSizes={[10, 20, 30]}
            showPageSizeSelector={true}
            showNavigationButtons={true}
            showInfo={true}
          />
          <Selection mode="single" />
          <Sorting mode="multiple" />
          <Scrolling columnRenderingMode="virtual" />
          <FilterRow visible={false} />
          <HeaderFilter visible={true} />
          <SearchPanel visible={true} width={240} placeholder="Search..." />
          <Summary>
            <TotalItem
              column="salaryPaid"
              customizeText={customizeNumber}
              summaryType="sum"
            />
            <TotalItem
              column="salaryActual"
              customizeText={customizeNumber}
              summaryType="sum"
            />

            {!doCompare &&
              gridColumnsOT.map((item, index) => (
                // <Column
                //   dataField={item.dataField}
                //   caption={item.caption}
                //   format={item.format}
                //   cssClass={item.cssClass}
                // />
                <TotalItem
                  column={item.dataField}
                  customizeText={
                    index % 2 === 0 ? customizeHours : customizeNumber
                  }
                  summaryType="sum"
                />
              ))}

            <TotalItem
              column="totalOTHours"
              customizeText={customizeHours}
              summaryType="sum"
            />
            <TotalItem
              column="totalOT"
              summaryType="sum"
              customizeText={customizeNumber}
            />

            {!doCompare &&
              gridColumnsShift.map((item, index) => (
                // <Column
                //   dataField={item.dataField}
                //   caption={item.caption}
                //   format={item.format}
                //   cssClass={item.cssClass}
                // />
                <TotalItem
                  column={item.dataField}
                  customizeText={customizeNumber}
                  summaryType="sum"
                />
              ))}

            <TotalItem
              column="totalShiftPay"
              summaryType="sum"
              customizeText={customizeNumber}
            />

            {gridColumnsAddition.map((item, key) => (
              <TotalItem
                column={item.dataField}
                summaryType="sum"
                customizeText={customizeNumber}
              />
            ))}

            <TotalItem
              column="totalEarnings"
              summaryType="sum"
              customizeText={customizeNumber}
            />

            <TotalItem
              column="pfCompanyTHB"
              summaryType="sum"
              customizeText={customizeNumber}
            />

            <TotalItem
              column="pfEmployeeTHB"
              summaryType="sum"
              customizeText={customizeNumber}
            />

            <TotalItem
              column="ssoEmployee"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem
              column="tax"
              summaryType="sum"
              customizeText={customizeNumber}
            />

            <TotalItem
              column="lateEarly"
              summaryType="sum"
              customizeText={customizeNumber}
            />

            <TotalItem
              column="absent"
              summaryType="sum"
              customizeText={customizeNumber}
            />

            {!doCompare &&
              gridColumnsDeduction.map((item, key) => (
                <TotalItem
                  column={item.dataField}
                  summaryType="sum"
                  customizeText={customizeNumber}
                />
              ))}

            <TotalItem
              column="totalDeductions"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem
              column="net"
              summaryType="sum"
              customizeText={customizeNumber}
            />
            <TotalItem column="chargeCompensated" summaryType="sum" />
          </Summary>
        </DataGrid>
      )}
    </div>
  );
};
