import React, { Fragment, useEffect, useState, useMemo } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { PDFDocument } from "pdf-lib";
import fontkit from "@pdf-lib/fontkit";

import {
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  MenuItem,
  Paper,
  styled,
  Typography,
  Stack,
  TextField,
  Box,
  Popper,
  Menu
} from "@mui/material";

import AlertResponse from "../../shared/general/AlertResponse";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DialogVisaDate from "./dialogVisaDate";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import DialogPersonID from "../../shared/general/dialogPersonID";

import { getDataWritePDF } from "../../../../actions/user";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import { PND91PdfFile } from "../governmentReport/filePDFgeneration.js/PND91PdfFile";
import { Tawi50PDFfile } from "../governmentReport/filePDFgeneration.js/50tawiPDFfile";
import {
  getEmployeesTaxDetails,
} from "../../../../actions/employee";

import {
  getDowloadEmployeeDocument,
  getDowloadCompanyDocument
} from "../../../../actions/document";

import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

const RootStyled = styled("div")({
  "& .paper-list": {
    margin: "24px 0px 48px",
    padding: 16,
  },
  "& .filename": {
    fontSize: 16,
  },
  "& .navBox": {
    display: "flex",
    height: 56,
    alignItems: "center",
    "& .downloadButton": {
      width: 96,
      minWidth: 96,
      height: 36,
      marginLeft: 16,
    },
  },
});

const DividerStyled = styled(Divider)({
  margin: "8px 0",
});

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const DownloadFileButton = ({ type, year, disabled, handleOpenAlertError }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [openDateDialog, setOpenDateDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const fillParagraph = (text, font, fontSize, maxWidth) => {
    var paragraphs = text.split("\n");
    for (let index = 0; index < paragraphs.length; index++) {
      var paragraph = paragraphs[index];
      if (font.widthOfTextAtSize(paragraph, fontSize) > maxWidth) {
        var words = paragraph.split(" ");
        var newParagraph = [];
        var i = 0;
        newParagraph[i] = [];
        for (let k = 0; k < words.length; k++) {
          var word = words[k];
          newParagraph[i].push(word);
          if (
            font.widthOfTextAtSize(newParagraph[i].join(" "), fontSize) >
            maxWidth
          ) {
            newParagraph[i].splice(-1);
            i = i + 1;
            newParagraph[i] = [];
            newParagraph[i].push(word);
          }
        }
        paragraphs[index] = newParagraph.map((p) => p.join(" ")).join("\n");
      }
    }
    return paragraphs.join("\n");
  };

  const renderPDFThai = async (type, data) => {
    const url = `${process.env.REACT_APP_API_URL}files/${type}.pdf`;
    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
    let pdfDoc = await PDFDocument.load(existingPdfBytes);
    let pages = pdfDoc.getPages();
    const urlFont = `${process.env.REACT_APP_API_URL}fonts/THSarabunNew.ttf`;
    const fontBytes = await fetch(urlFont).then((res) => res.arrayBuffer());
    pdfDoc.registerFontkit(fontkit);

    const font = await pdfDoc.embedFont(fontBytes);
    const widthSize = 200;
    let imgSource = "";
    let sigImg = "";
    let signatureName = "";
    let signaturePosition = "";

    data.map(async (page, index) => {
      const currentPage = pages[index];
      await Promise.all(
        page.map(async (item) => {
          if (item.text) {
            let xContainer = 0;
            if (item.containerSize !== undefined) {
              const textWidth = font.widthOfTextAtSize(item.text, item.size);
              xContainer = (widthSize - textWidth) / 2;
            }

            currentPage.drawText(
              type === "PND91"
                ? item.text.replace(/\u200B/g, "").trim()
                : item.text,
              {
                x: xContainer === 0 ? item.x : item.x + xContainer,
                y: item.y,
                ...(item.font ? { font: font } : {}),
                ...(item.size ? { size: item.size } : {}),
                ...(item.maxWidth ? { maxWidth: item.maxWidth } : {}),
                ...(item.fontWeight ? { fontWeight: item.fontWeight } : {}),
              }
            );
          }

          if (item.imageLogo !== undefined) {
            imgSource = item.imageLogo;
          }

          if (item.signatureImage !== undefined) {
            sigImg = item.signatureImage;
          }

          if (item.certificateSignatureName !== undefined) {
            signatureName = item.certificateSignatureName;
          }

          if (item.certificateSignaturePosition !== undefined) {
            signaturePosition = item.certificateSignaturePosition;
          }
        })
      );
    });

    if (imgSource) {
      const pngImage = await pdfDoc.embedPng(imgSource);
      const pngDims = pngImage.scale(0.2);
      pages[0].drawImage(pngImage, {
        x: 40,
        y: 700,
        width: pngDims.width,
        height: pngDims.height,
      });
    }

    if (sigImg) {
      const pngImage = await pdfDoc.embedPng(sigImg);
      const pngDims = pngImage.scale(0.2);
      pages[0].drawImage(pngImage, {
        x: 310 + (150 - pngDims.width) / 2,
        y: 400,
        width: pngDims.width,
        height: pngDims.height,
      });
    }

    if (signatureName) {
      const textWidth = font.widthOfTextAtSize(signatureName, 16);
      pages[0].drawText(signatureName, {
        x: 310 + (150 - textWidth) / 2,
        y: 380,
        size: 16,
        font: font,
      });
    }

    if (signaturePosition) {
      const textWidth = font.widthOfTextAtSize(signaturePosition, 16);
      pages[0].drawText(signaturePosition, {
        x: 310 + (150 - textWidth) / 2,
        y: 360,
        size: 16,
        font: font,
      });
    }

    const pdfBytes = await pdfDoc.save();
    const bytes = new Uint8Array(pdfBytes);
    const blob = new Blob([bytes], { type: "application/pdf" });
    const docUrl = URL.createObjectURL(blob);
    window.open(docUrl, "_blank");
  };

  const renderPDFEnglish = async (type, data) => {
    const widthSize = 200;
    let diffSignatureY = 0;
    if (type === "businessVisa" || type === "touristVisaNoDate") {
      diffSignatureY = 150;
    } else if (type === "certificateEmpEN" || type === "certificateSalaryEN") {
      diffSignatureY = 80;
    }

    const url = `${process.env.REACT_APP_API_URL}files/EmptyPDF.pdf`;
    const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
    let pdfDoc = await PDFDocument.load(existingPdfBytes);
    let pages = pdfDoc.getPages();
    const urlFont = `${process.env.REACT_APP_API_URL}fonts/THSarabunNew.ttf`;
    const fontBytes = await fetch(urlFont).then((res) => res.arrayBuffer());
    pdfDoc.registerFontkit(fontkit);

    const urlFontBold = `${process.env.REACT_APP_API_URL}fonts/THSarabunNew Bold.ttf`;
    const fontBoldBytes = await fetch(urlFontBold).then((res) =>
      res.arrayBuffer()
    );

    const font = await pdfDoc.embedFont(fontBytes);
    const fontBold = await pdfDoc.embedFont(fontBoldBytes);
    let imgSource = "";
    let sigImg = "";
    let signatureName = "";
    let signaturePosition = "";

    data.map(async (page, index) => {
      const currentPage = pages[index];
      await Promise.all(
        page.map(async (item) => {
          if (item.text) {
            let xContainer = 0;
            if (item.containerSize !== undefined) {
              const textWidth = font.widthOfTextAtSize(item.text, item.size);
              xContainer = (widthSize - textWidth) / 2;
            }

            if (item.multiline !== true) {
              currentPage.drawText(
                type === "PND91"
                  ? item.text.replace(/\u200B/g, "").trim()
                  : item.text,
                {
                  x: xContainer === 0 ? item.x : item.x + xContainer,
                  y: item.y,
                  ...(item.font ? { font: font } : {}),
                  ...(item.size ? { size: item.size } : {}),
                  ...(item.fontWeight ? { font: fontBold } : {}),
                  ...(item.maxWidth ? { maxWidth: item.maxWidth } : {}),
                  ...(item.fontWeight ? { fontWeight: item.fontWeight } : {}),
                }
              );
            } else {
              var longString = [item.text[0], item.text[1], item.text[2]].join(
                "\n\n"
              );

              currentPage.drawText(
                fillParagraph(longString, font, item.size, 520),
                {
                  x: item.x,
                  y: item.y,
                  ...(item.font ? { font: font } : {}),
                  ...(item.size ? { size: item.size } : {}),
                }
              );
            }
          }

          if (item.imageLogo !== undefined) {
            imgSource = item.imageLogo;
          }

          if (item.signatureImage !== undefined) {
            sigImg = item.signatureImage;
          }

          if (item.certificateSignatureName !== undefined) {
            signatureName = item.certificateSignatureName;
          }

          if (item.certificateSignaturePosition !== undefined) {
            signaturePosition = item.certificateSignaturePosition;
          }
        })
      );
    });

    if (imgSource) {
      const pngImage = await pdfDoc.embedPng(imgSource);
      const pngDims = pngImage.scale(0.2);
      pages[0].drawImage(pngImage, {
        x: 50,
        y: 700,
        width: pngDims.width,
        height: pngDims.height,
      });
    } else {
      pages[0].drawText("(Company image not found.)", {
        x: 50,
        y: 700,
        size: 18,
        font: font,
      });
    }

    if (sigImg) {
      const pngImage = await pdfDoc.embedPng(sigImg);
      const pngDims = pngImage.scale(0.2);
      pages[0].drawImage(pngImage, {
        x: 50,
        y: 400 - diffSignatureY,
        width: pngDims.width,
        height: pngDims.height,
      });
    } else {
      pages[0].drawText("(Certificate signature not found.)", {
        x: 50,
        y: 400 - diffSignatureY,
        size: 18,
        font: font,
      });
    }

    if (signatureName) {
      pages[0].drawText(signatureName, {
        x: 50,
        y: 380 - diffSignatureY,
        size: 18,
        font: font,
      });
    } else {
      pages[0].drawText("(Certificate name not found.)", {
        x: 50,
        y: 380 - diffSignatureY,
        size: 18,
        font: font,
      });
    }

    if (signaturePosition) {
      pages[0].drawText(signaturePosition, {
        x: 50,
        y: 360 - diffSignatureY,
        size: 18,
        font: font,
      });
    } else {
      pages[0].drawText("(Certificate position not found.)", {
        x: 50,
        y: 360 - diffSignatureY,
        size: 18,
        font: font,
      });
    }

    const pdfBytes = await pdfDoc.save();
    const bytes = new Uint8Array(pdfBytes);
    const blob = new Blob([bytes], { type: "application/pdf" });
    const docUrl = URL.createObjectURL(blob);
    window.open(docUrl, "_blank");
  };

  const handleOnCertEmp = async (rangeDate, language) => {
    const formData = {
      startDate: rangeDate ? dayjs(rangeDate.startDate).format("YYYY/MM/DD") : null,
      endDate: rangeDate ? dayjs(rangeDate && rangeDate.endDate).format("YYYY/MM/DD") : null,
    };

    const res = await getDataWritePDF(`${type}${(language && language !== "th") ? language.toUpperCase() : ""}`, year, formData);
    if (res.status === 200 && res.data.data && res.data.data.length > 0) {
      if (
        (
          (
            type === "certificateSalary" ||
            type === "certificateEmp" ||
            type === "resignationCertificate"
          ) && language === "th"
        ) ||
        type === "50tawi" ||
        type === "PND91"
      ) {
        renderPDFThai(type, res.data.data);
      } else {
        renderPDFEnglish(type, res.data.data);
      }
    } else if (res.status === 404) {
      handleOpenAlertError(`${t("IncompleteInformation")}`);
    }
  };

  return (
    <Fragment>
      <ButtonBlue
        variant="contained"
        className="downloadButton"
        onClick={(event) => {
          if (type === "businessVisa" || type === "touristVisaNoDate") {
            setOpenDateDialog(true);
          } else if (
            type === "certificateSalary" ||
            type === "certificateEmp" ||
            type === "resignationCertificate"
          ) {
            setAnchorEl(event.currentTarget);
          } else {
            handleOnCertEmp();
          }
        }}
        disabled={disabled}
      >
        {`${t("Download")}`}
      </ButtonBlue>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            handleOnCertEmp(null, "th");
            setAnchorEl(null);
          }}
        >ภาษาไทย</MenuItem>
        <MenuItem
          onClick={() => {
            handleOnCertEmp(null, "en");
            setAnchorEl(null);
          }}
        >English</MenuItem>
      </Menu>
      {openDateDialog && (
        <DialogVisaDate
          open={openDateDialog}
          onClose={() => setOpenDateDialog(false)}
          onHandleSubmit={async (rangeDate) => {
            let range = {
              startDate: dayjs(rangeDate.startDate).format("YYYY-MM-DD"),
              endDate: dayjs(rangeDate.endDate).format("YYYY-MM-DD")
            }
            return await handleOnCertEmp(range);
          }}
        />
      )}
    </Fragment>
  );
};


const MyDocument = () => {
  const { result: UserProfile } = useSelector((state) => state.userProfile);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [openDialog, setOpenDialog] = useState(true);
  const [openAlert, setOpenAlert] = useState({
    isOpen: false,
    type: null,
    label: null,
  });

  const currentYear = new Date().getFullYear();

  const thaiYears = Array.from(
    { length: 6 },
    (_, index) => currentYear + 543 - index
  );

  const [selectedType, setSelectedType] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedYear2, setSelectedYear2] = useState(null);
  const [selectedIdPayrollType, setSelectedIdPayrollType] = useState(null);

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleYearChange2 = (event) => {
    setSelectedYear2(event.target.value);
  };

  const { control, watch } = useForm({
    defaultValues: {
      year50Tawi: "",
      yearPND91: "",
    },
  });

  const handleOpenAlertError = (label) => {
    setOpenAlert({ isOpen: true, type: "error", label: label });
  };

  const handleCloseAlert = () => {
    setOpenAlert({ isOpen: false, type: null, label: null });
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const modifyPdf = async (selectedMonth, type, selectedYear, idPayrollType) => {
    setSelectedIdPayrollType(idPayrollType);

    try {
      const data = await getEmployeesTaxDetails(selectedMonth, parseInt(selectedYear) - 543, idPayrollType, UserProfile && UserProfile.idEmployees, type, UserProfile && UserProfile.idCompany);
      if (!data.data) {
        handleOpenAlertError("ไม่มีข้อมูล");
        return;
      }

      const generateFunctionMap = {
        "PND91": PND91PdfFile,
        "50tawi": Tawi50PDFfile
      };

      const generateFunction = generateFunctionMap[type];
      if (!generateFunction) {
        handleOpenAlertError("Unsupported report type");
        return;
      }

      generateFunction(type, selectedYear, data.data);
    } catch (error) {
      // You might want to handle different types of errors here
      handleOpenAlertError("เกิดข้อผิดพลาขณะดึงข้อมูล");
    }
  };

  useEffect(() => {
    if ((selectedYear || selectedYear2) && selectedIdPayrollType) {
      modifyPdf(0, selectedType, selectedYear || selectedYear2, selectedIdPayrollType);
    }
  }, [UserProfile, selectedYear, selectedIdPayrollType, selectedYear2]);

  return (
    <RootStyled className="page">
      <Container maxWidth="lg">
        {openDialog && (
          <DialogPersonID open={openDialog} handleClose={handleCloseDialog} />
        )}
        {!openDialog && (
          <Fragment>
            <Typography variant="h4" style={{ paddingTop: 8 }}>
              {`${t("MyDocuments")}`}
            </Typography>
            <Paper className="paper-list">
              <List>
                <ListItem>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography className="filename">
                        {`${t("IncomeCertificate")}`}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <div className="navBox">
                        <DownloadFileButton
                          type={"certificateSalary"}
                          handleOpenAlertError={handleOpenAlertError}
                          idEmployees={UserProfile && UserProfile.idEmployees}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </ListItem>
                <DividerStyled />
                <ListItem>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography className="filename">
                        {`${t("EmploymentCertificate")}`}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <div className="navBox">
                        <DownloadFileButton
                          type={"certificateEmp"}
                          handleOpenAlertError={handleOpenAlertError}
                          idEmployees={UserProfile && UserProfile.idEmployees}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </ListItem>
                <DividerStyled />
                {/* <ListItem>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography className="filename">
                        {`${t("ResignationCertificate")}`}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <div className="navBox">
                        <DownloadFileButton
                          type={"resignationCertificate"}
                          handleOpenAlertError={handleOpenAlertError}
                          idEmployees={UserProfile && UserProfile.idEmployees}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </ListItem>
                <DividerStyled /> */}
                <ListItem>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography className="filename">
                        50 {`${t("Tawi")}`}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <div className="navBox">
                        <Stack spacing={2} direction={"row"} alignItems={"center"}>
                          <Controller
                            name="year"
                            control={control}
                            render={({ field }) => (
                              <TextFieldTheme
                                {...field}
                                label={`${t("SelectYear")}`}
                                select
                                value={selectedYear}
                                onChange={(e) => handleYearChange(e)}
                                style={{ width: 150 }}
                              >
                                {thaiYears.map((year) => (
                                  <MenuItem key={year} value={year}>
                                    {year}
                                  </MenuItem>
                                ))}
                              </TextFieldTheme>
                            )}
                          />
                          <ButtonBlue
                            variant="contained"
                            type="submit"
                            // disabled={selectedYear === null}
                            disabled={(UserProfile && (UserProfile.idCompany !== 1 && UserProfile.idCompany !== 2)) || selectedYear === null}
                            onClick={
                              async () => {
                                setSelectedType("50tawi")
                                await modifyPdf(0, "50tawi", selectedYear, 12);
                              }}
                            style={{ width: 95 }}
                          >
                            {t("View")} PDF
                          </ButtonBlue>
                        </Stack>
                      </div>
                    </Grid>
                  </Grid>
                </ListItem>
                <DividerStyled />
                <ListItem>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography className="filename">{`${t("P.N.D.91")}`}</Typography>
                    </Grid>
                    <Grid item>
                      <div className="navBox">
                        <Stack spacing={2} direction={"row"} alignItems={"center"}>
                          {/* <Controller
                            name="year"
                            control={control}
                            render={({ field }) => (
                              <TextFieldTheme
                                {...field}
                                label={`${t("SelectYear")}`}
                                select
                                value={selectedYear2}
                                onChange={(e) => handleYearChange2(e)}
                                style={{ width: 150 }}
                              >
                                {thaiYears.map((year) => (
                                  <MenuItem key={year} value={year}>
                                    {year}
                                  </MenuItem>
                                ))}
                              </TextFieldTheme>
                            )}
                          /> */}
                          <ButtonBlue
                            variant="contained"
                            type="submit"
                            disabled={selectedYear2 === null}
                            onClick={
                              async () => {
                                setSelectedType("PND91")
                                await modifyPdf(0, "PND91", selectedYear2, 12);
                              }}
                            style={{ width: 95 }}
                          >
                            {t("View")} PDF
                          </ButtonBlue>
                        </Stack>
                      </div>
                    </Grid>
                  </Grid>
                </ListItem>
                <DividerStyled />
                <ListItem>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography className="filename">
                        Business Visa
                      </Typography>
                    </Grid>
                    <Grid item>
                      <div className="navBox">
                        <DownloadFileButton
                          type={"businessVisa"}
                          handleOpenAlertError={handleOpenAlertError}
                          idEmployees={UserProfile && UserProfile.idEmployees}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </ListItem>
                <DividerStyled />
                <ListItem>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    alignItems="center"
                  >
                    <Grid item>
                      <Typography className="filename">Tourist Visa</Typography>
                    </Grid>
                    <Grid item>
                      <div className="navBox">
                        <DownloadFileButton
                          type={"touristVisaNoDate"}
                          handleOpenAlertError={handleOpenAlertError}
                          idEmployees={UserProfile && UserProfile.idEmployees}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </ListItem>
              </List>
            </Paper>
          </Fragment>
        )}
      </Container>
      {openAlert.isOpen && (
        <AlertResponse
          open={openAlert.isOpen}
          alertType={openAlert.type}
          label={openAlert.label}
          handleClose={handleCloseAlert}
        />
      )}
    </RootStyled>
  );
};

export default MyDocument;