import { httpClient } from "./httpClient";

const getMetadataObjective = () => {
  return httpClient.get(`/okrs/metadata/objective`);
};

const getAllObjective = () => {
  return httpClient.get(`/okrs/objective`);
};

const addIndividualObjective = (formData) => {
  return httpClient.post(`/okrs/individual-objective`, formData);
};

const addTeamObjective = (formData) => {
  return httpClient.post(`/okrs/team-objective`, formData);
};

const addOrganizationObjective = (formData) => {
  return httpClient.post(`/okrs/organization-objective`, formData);
};

const getMyIndividualObjective = () => {
  return httpClient.get(`/okrs/individual-objective`);
};

const getAllIndividualObjectiveByIdEmp = (idEmployee) => {
  return httpClient.get(`/okrs/individual-objective-employee/` + idEmployee);
};

const getTeamObjective = () => {
  return httpClient.get(`/okrs/team-objective`);
};

const getOrganizationObjectives = () => {
  return httpClient.get(`/okrs/organization-objective`);
};

const getIndividualObjectiveByIdIndividualObjective = (idIndividualObjective) => {
  return httpClient.get(`/okrs/individual-objective/${idIndividualObjective}`);
};

const getTeamObjectiveByIdTeamObjective = (idTeamObjective) => {
  return httpClient.get(`/okrs/team-objective/${idTeamObjective}`);
};

const updateTeamObjectiveByIdTeamObjective = (
  idTeamObjective,
  idTeamKeyResult,
  payload
) => {
  return httpClient.put(
    `/okrs/team-objective/${idTeamObjective}/${idTeamKeyResult}`,
    payload
  );
};

const getOrganizationObjective = (idOrganizationObjective) => {
  return httpClient.get(`/okrs/organization-objective/${idOrganizationObjective}`);
};

const updateOrganizationObjectiveByIdOrganizationObjective = (
  idOrganizationObjective,
  idOrganizationKeyResult,
  payload
) => {
  // return httpClient.put(
  //   `/okrs/organization-objective/${idOrganizationObjective}/${idOrganizationKeyResult}`,
  //   payload
  // );
};

const updateIndividualKeyResult = (idIndividualKeyResult, formData) => {
  return httpClient.put(`/okrs/individual-keyresult/${idIndividualKeyResult}`, formData);
};

const updateTeamKeyResult = (idTeamKeyResult, formData) => {
  return httpClient.put(`/okrs/team-keyresult/${idTeamKeyResult}`, formData);
};

const updateOrganizationObjective = (idOrganizationObjective, formData) => {
  return httpClient.put(`/okrs/organization-objective/${idOrganizationObjective}`, formData);
};

const updateOrganizationKeyResult = (idOrganizationKeyResult, formData) => {
  return httpClient.put(`/okrs/organization-keyresult/${idOrganizationKeyResult}`, formData);
};

const getAllObjectiveForApprove = () => {
  return httpClient.get(`/okrs/approval`);
};

const getSelfObjectiveForEvaluate = () => {
  return httpClient.get(`/okrs/self-evaluation`);
};

const getSelfObjectiveForEvaluateById = (idIndividualObjective) => {
  return httpClient.get(`okrs/self-objective-evaluate/${idIndividualObjective}`);
};

const getAllObjectiveForEvaluate = () => {
  return httpClient.get(`/okrs/evaluation`);
};

const getIndividualObjectiveForApprove = (idIndividualObjective) => {
  return httpClient.get(`/okrs/individual-objective-approve/${idIndividualObjective}`);
};

const getIndividualObjectiveForEvaluate = (idIndividualObjective) => {
  return httpClient.get(`/okrs/individual-objective-evaluate/${idIndividualObjective}`);
};

const getAllIndividualKeyResultProgress = (idIndividualKeyResult) => {
  return httpClient.get(`/okrs/individual-keyresult/${idIndividualKeyResult}/progress`);
};

const getAllTeamKeyResultProgress = (idTeamKeyResult) => {
  return httpClient.get(`/okrs/team-keyresult/${idTeamKeyResult}/progress`);
};

const getAllOrganizationKeyResultProgress = (idOrganizationKeyResult) => {
  return httpClient.get(`/okrs/organization-keyresult/${idOrganizationKeyResult}/progress`);
};

const addIndividualKeyResultProgress = (idIndividualKeyResult, formData) => {
  return httpClient.post(`/okrs/individual-keyresult/${idIndividualKeyResult}/progress`, formData);
};

const updateIndividualKeyResultProgress = (idIndividualKeyResultProgress, formData) => {
  return httpClient.put(`/okrs/individual-keyresult/${idIndividualKeyResultProgress}/progress`, formData);
};

const addTeamKeyResultProgress = (idTeamKeyResult, formData) => {
  return httpClient.post(`/okrs/team-keyresult/${idTeamKeyResult}/progress`, formData);
};

const updateTeamKeyResultProgress = (idTeamKeyResultProgress, formData) => {
  return httpClient.put(`/okrs/team-keyresult/${idTeamKeyResultProgress}/progress`, formData);
};

const addOrganizationKeyResultProgress = (idOrganizationKeyResult, formData) => {
  return httpClient.post(`/okrs/organization-keyresult/${idOrganizationKeyResult}/progress`, formData);
};

const getDashboardIndividual = (query) => {
  return httpClient.get(`/okrs/dashboard/individual`, { params: query });
};

const getDashboardTeam = (query) => {
  return httpClient.get(`/okrs/dashboard/team`, { params: query });
};

const getDashboardOrganization = (query) => {
  return httpClient.get(`/okrs/dashboard/organization`, { params: query });
};

const getCarlibrateOrganization = () => {
  return httpClient.get(`/okrs/carlibrate/organization`);
};

const getCarlibrateDepartment = () => {
  return httpClient.get(`/okrs/carlibrate/department`);
};

const addGradeOKR = (formData) => {
  return httpClient.post("/okrs/grade", formData);
};

const getOKRScoreboard = (query) => {
  return httpClient.get("/okrs/scoreboard", { params: query });
};

export default {
  getMetadataObjective,
  getAllObjective,
  addIndividualObjective,
  addTeamObjective,
  addOrganizationObjective,
  getMyIndividualObjective,
  getAllIndividualObjectiveByIdEmp,
  getTeamObjective,
  getOrganizationObjective,
  getIndividualObjectiveByIdIndividualObjective,
  getTeamObjectiveByIdTeamObjective,
  getOrganizationObjectives,
  updateIndividualKeyResult,
  updateTeamKeyResult,
  updateOrganizationObjectiveByIdOrganizationObjective,
  updateTeamObjectiveByIdTeamObjective,
  updateOrganizationObjective,
  updateOrganizationKeyResult,
  getAllObjectiveForApprove,
  getSelfObjectiveForEvaluate,
  getSelfObjectiveForEvaluateById,
  getAllObjectiveForEvaluate,
  getIndividualObjectiveForApprove,
  getIndividualObjectiveForEvaluate,
  getAllIndividualKeyResultProgress,
  getAllTeamKeyResultProgress,
  getAllOrganizationKeyResultProgress,
  addIndividualKeyResultProgress,
  updateIndividualKeyResultProgress,
  addTeamKeyResultProgress,
  updateTeamKeyResultProgress,
  addOrganizationKeyResultProgress,
  getDashboardIndividual,
  getDashboardTeam,
  getDashboardOrganization,
  getCarlibrateOrganization,
  getCarlibrateDepartment,
  addGradeOKR,
  getOKRScoreboard,
}