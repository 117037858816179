import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
  styled,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import { getEmployeeShiftChange, getEmployeeShiftChangeHistory } from "../../../../../../actions/employee";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import MenuShiftChangeType from "./menuShiftChangeType";
import HistoryEmployeeShiftPanel from "./historyEmployeeShiftPanel";
import HistoryEmployeeDailyShiftPanel from "./historyEmployeeDailyShiftPanel";

const StyledRoot = styled(Box)({});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const ShiftChange = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: employeeProfile } = useSelector((state) => state.employeeProfile);
  const { result: employeeShiftChangeHistory } = useSelector((state) => state.employeeShiftChangeHistory);

  const [selectedShiftChangeType, setSelectedShiftChangeType] = useState(1)

  useEffect(() => {
    dispatch(getEmployeeShiftChangeHistory(employeeProfile.idEmployees));
  }, []);

  return (
    <StyledRoot>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StyledHeadLabel color="text.secondary">
            {t("WorkingType")}
          </StyledHeadLabel>
          <Typography variant="h6">
            {employeeProfile.workingType
              ? String(employeeProfile.workingType).toUpperCase()
              : "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledHeadLabel color="text.secondary">
            {t("ShiftGroup")}
          </StyledHeadLabel>
          <Typography variant="h6">
            {employeeProfile.shift && employeeProfile.shift.shiftGroupName
              ? employeeProfile.shift.shiftGroupName
              : "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledHeadLabel color="text.secondary">
            {t("ShiftName")}
          </StyledHeadLabel>
          <Typography variant="h6">
            {employeeProfile.shift && employeeProfile.shift.shiftName
              ? employeeProfile.shift.shiftName
              : "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledHeadLabel color="text.secondary">
            {t("WorkingDays")}
          </StyledHeadLabel>
          <Typography variant="h6">
            {employeeProfile.shift && employeeProfile.shift.workDay
              ? employeeProfile.shift.workDay
              : "-"}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <StyledHeadLabel color="text.secondary">
            {t("WorkingTimePerDay")}
          </StyledHeadLabel>
          <Typography variant="h6">
            {employeeProfile.shift && employeeProfile.shift.workingHours
              ? `${
                  Number(employeeProfile.shift.workingHours / 60) -
                  (employeeProfile.shift.breakTime === 0 ? 1 : 0)
                } ${t("Unit.ShortHours")}/${t("Days")}`
              : "-"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <StyledHeadLabel color="text.secondary">
            {t("TimeIn")}-{t("TimeOut")}
          </StyledHeadLabel>
          <Typography variant="h6">
            {employeeProfile.shift &&
            employeeProfile.shift.timeIn &&
            employeeProfile.shift.timeOut
              ? `${dayjs(employeeProfile.shift.timeIn, "HH:mm:ss").format(
                  "HH:mm"
                )} - ${dayjs(employeeProfile.shift.timeOut, "HH:mm:ss").format(
                  "HH:mm"
                )}`
              : "-"}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <StyledDivider />
        </Grid>
        <Grid item xs={6}>
          <StyledHeadLabel color="text.secondary">
            {t("SaveChanges")}
          </StyledHeadLabel>
        </Grid>
        <Grid item xs={6} container justifyContent="flex-end">
          <MenuShiftChangeType selectedShiftChangeType={selectedShiftChangeType} setSelectedShiftChangeType={setSelectedShiftChangeType}/>
        </Grid>

        <Grid item xs={12}>
          {selectedShiftChangeType === 1 && (<HistoryEmployeeShiftPanel historyList={employeeShiftChangeHistory.EmployeeShiftPermanant}/>)}
          {selectedShiftChangeType === 2 && (<HistoryEmployeeShiftPanel isTemporary historyList={employeeShiftChangeHistory.EmployeeShiftTemporary}/>)}
          {selectedShiftChangeType === 3 && (<HistoryEmployeeDailyShiftPanel historyList={employeeShiftChangeHistory.EmployeeShiftDaily}/>)}
        </Grid>
      </Grid>
    </StyledRoot>
  );
};

export default ShiftChange;
