import React, { useState, useEffect,  useRef  } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  FormControl,
  Select,
  MenuItem,
  Grid,
  Typography,
  FormControlLabel,
  Button,
  Paper
} from "@mui/material";
import AlertResponse from "../../shared/general/AlertResponse";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DrawerCustom from "../../shared/general/Drawer";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import Checkbox from '@mui/material/Checkbox';
import { CloudDownload, CloudOff } from "@mui/icons-material";
import Lottie from 'lottie-react';
import animationData2 from './Animation/Animation - 1721107029244.json'

import {
  addCompanyDocumentType,
  addCompanyDocumentGroup,
  editCompanyDocumentTypeGroup,
} from "../../../../actions/document";
import Swal from 'sweetalert2';
//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .GridTopicInput": {
    display: "flex",
    alignItems: "center",
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyledButton = styled(Button)(({ active }) => ({
  padding: '10px 20px',
  margin: '10px',
  fontSize: '16px',
  cursor: 'pointer',
  border: `2px solid ${active ? '#000000' : '#616161'}`, 
  borderRadius: '8px',
  background: active ? '#46CBE2' : 'white', 
  color: active ? '#000000' : '#616161', 
  transition: 'background-color 0.3s, color 0.3s, border-color 0.3s',
  boxShadow: active ? '0px 0px 8px rgba(0, 0, 0, 0.3)' : 'none', 
  '&:hover': {
    backgroundColor: '#e0e0e0', 
    color: '#1976d2', 
  },
  '&:focus': {
    outline: 'none',
  },
}));

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  "&.MuiCheckbox-root": {
    transition: "transform 0.3s ease-in-out",
  },
  "&.Mui-checked": {
    color: theme.palette.primary.main,
    transform: "scale(1.2)",
  },
  "& .MuiSvgIcon-root": {
    fontSize: 28,
  },
  "&:hover": {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    borderRadius: "4px",
  },
}));


const DialogType = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { open, mode, handleClose, data, companyDocumentGroup, ReloadData, companyProfile} = props;
  console.log('dataxxx open: ', open);
  console.log('dataxxx ภภภ: ', data);
  console.log('dataxxx mode: ', mode);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [documentGroupName, setDocumentGroupName] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };
  
  const handleCloseAlert = () => {
    setOpenAlert(false);
  };
  
  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };
          
  useEffect(() => {
    if (companyDocumentGroup) {
      setDocumentGroupName(companyDocumentGroup);
    }
  }, [companyDocumentGroup]);                                                                                                                                                                                                                      

  const [formData, setFormData] = useState({});  
  console.log('dataformDataxx: ', formData);

  const handleChange = (event) => {
    const { name, value } = event.target;
    console.log('name,: ', name,);
     if (name === 'documentGroupName'  && documentGroupName) {
      const selectedIndex = documentGroupName.findIndex(item => item.documentGroupName === value);
      console.log('selectedIndex: ', selectedIndex);
      if (selectedIndex !== -1 && documentGroupName[selectedIndex]) {
        setFormData({
          ...formData,
          [name]: value,
          idCompanyDocumentGroup: documentGroupName[selectedIndex].idCompanyDocumentGroup
        });
      } else {
        console.warn('ไม่พบ documentGroupName ที่ตรงกัน');
        setFormData({
          ...formData,
          [name]: value,
          idCompanyDocumentGroup: null  
        });
      }
    } else {  
      setFormData({
        ...formData,
        [name]: event.target.type === 'checkbox' ? event.target.checked : event.target.value,
      });
    }
  };

  useEffect(() => {
    if (open) {
      switch (mode) {
        case "editDoc":
          setFormData({
            documentTypeName: data.documentTypeName,
            idDocumentType: data.idCompanyDocumentType,
            documentGroupName: data.documentGroupName,
            idCompanyDocumentGroup: data.idCompanyDocumentGroup,
            idCompany: data.idCompany,
            isDownload: data.isDownload,
            detail: data.detail,
            file: null,
          });
          break;
        case "Add":
        default:
          setFormData({
            documentTypeName: null,
            idDocumentType: null,
            documentGroupName: null,
            idCompanyDocumentGroup: null,
            idCompany: companyProfile.idCompany,
            isDownload: false,
            detail: null,
            file: null,
          });
          break;
      }
    }
  }, [open, data, mode]);


  const handleSubmit = async (selectedTab) => {
  console.log('Form data is valid, proceeding with submission...');
  console.log('data selectedTab: ', selectedTab);
      if (!formData.documentGroupName || (!formData.documentTypeName && selectedTab !== 'category')) 
        {    
      handleOpenAlert(true);
      handleChangeAlertType(`${t("ThisFieldIsRequired")}`);
    } else {
      console.log('Entering switch statement with mode:', mode);

      const postData = new FormData();
      let data;
      switch (mode) {
        case "editDoc":
          data = {
            documentTypeName: formData.documentTypeName || '',
            documentGroupName: formData.documentGroupName || '',
            idDocumentType: formData.idDocumentType || 0,
            idCompanyDocumentGroup: formData.idCompanyDocumentGroup || 1,
            isDownload: formData.isDownload || false,
            idCompany: companyProfile.idCompany || 1,
            updateDate: dayjs(new Date()).format("YYYY-MM-DD"),
            createDate: dayjs(new Date()).format("YYYY-MM-DD"),
            detail: formData.detail || '',
          };
          break;
        case "Add":
          data = {
            documentTypeName: formData.documentTypeName,
            documentGroupName: formData.documentGroupName || '',
            idDocumentType: formData.idDocumentType || 0,
            idCompanyDocumentGroup: formData.idCompanyDocumentGroup || 1,
            isDownload: formData.isDownload || false,
            idCompany: companyProfile.idCompany || 1,
            updateDate: dayjs(new Date()).format("YYYY-MM-DD"),
            createDate: dayjs(new Date()).format("YYYY-MM-DD"),
            detail: formData.detail,
          };
          break;
        default:
          handleOpenAlert(true);
          handleChangeAlertType(`${t("InvalidMode")}`);
          return;
      }
      Object.keys(data).map((k) => {
        postData.append(k, JSON.stringify(data[k]));
      });
      
      if (formData.file) {
        postData.append("file", formData.file);
      }
      console.log('postData: ', Array.from(postData.entries()));

      setLoading(true);
      try {
        let result;
        
        switch (mode) {
          case 'Add':
            if (selectedTab === 'documentName') {
              result = await dispatch(addCompanyDocumentType(postData));
            } else if (selectedTab === 'category') { 
              result = await dispatch(addCompanyDocumentGroup(postData));
            }
            break;
          case 'editDoc':
            result = await dispatch(editCompanyDocumentTypeGroup(postData));
            break;
          default:
            handleOpenAlert(true);
            handleChangeAlertType(`${t("InvalidMode")}`);
            break;
        }
        if (result) {
          Swal.fire({
            icon: 'success',
            title: getSuccessTitle(),
            showConfirmButton: false,
            timer: 1500,
          });
          handleClose();
          } else {
          Swal.fire({
            icon: 'error',
            title:
              mode === 'Add' && selectedTab === 'documentName'
                ? 'ไม่สามารถเพิ่มชนิดเอกสารได้'
                : mode === 'Add' && selectedTab === 'category'
                ? 'ไม่สามารถเพิ่ม Group เอกสารได้'
                : mode === 'editDoc'
                ? 'ไม่สามารถแก้ไขชนิดเอกสารได้!!'
                : 'เกิดข้อผิดพลาด!',
            text: 'กรุณาลองใหม่อีกครั้ง',
            confirmButtonText: 'OK',
          });
        }
      } catch (error) {
        console.error("Error while editing document: ", error);
        Swal.fire({
          icon: 'error',
          title: 'เกิดข้อผิดพลาด!',
          text: 'กรุณาลองใหม่อีกครั้ง',
          confirmButtonText: 'OK',
        });
      } finally {
        setLoading(false);
        ReloadData();
      }
    }
  };

  const getSuccessTitle = () => {
    if (mode === 'Add') {
      if (selectedTab === 'documentName') {
        return 'เพิ่มชนิดเอกสารสำเร็จ!';
      } else if (selectedTab === 'category') {
        return 'เพิ่ม Group เอกสารสำเร็จ!!';
      }
    } else if (mode === 'editDoc') {
      return 'แก้ไขชนิดเอกสารสำเร็จ!!';
    }
    
    return 'สำเร็จ!';
  };
  
  const [activeButton, setActiveButton] = useState('documentName');
  const [selectedTab, setSelectedTab] = useState('documentName');
  console.log('selectedTab: ', selectedTab);

  const handleTabChange = (tabName) => {
    setSelectedTab(tabName);
    setActiveButton(tabName);
  };
  const lottieRef = useRef(null);

  useEffect(() => {
    if (lottieRef.current) {
      if (!loading ) {
        lottieRef.current.play();
      }
    }
  }, [loading]);

  return (
    <>
    {mode !== 'delete' && (
    <DrawerCustom
      title={`${t("DocumentDescription")}`}
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      {formData && (
        <StyledRoot>
          <Grid
            container
            spacing={2}
            component="form"
            noValidate    
            autoComplete="off"
          >
            {mode === "editDoc" ? (
              <>
               { loading ? (
                          <>
                           <Grid item xs={12}  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
                            <Typography 
                              variant="h6" 
                              style={{
                                marginTop: '16px',
                                color: '#3f51b5',
                                textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',
                                fontWeight: 'bold',
                                userSelect: 'none',
                              }}
                              >
                                กำลังแก้ไขชนิดประเภทเอกสาร...
                             </Typography>
                            </Grid>
                            <Grid item xs={12}  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '150px' }}>
                              <div style={{ width: '60%', height: '60%' }}>
                                  <Lottie
                                    lottieRef={lottieRef}
                                    animationData={animationData2} // นำเข้าข้อมูล Animation จากไฟล์ .json
                                    loop={true}
                                    autoplay={true}
                                  />
                                </div>
                            </Grid>
                            <Grid item xs={12}  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
                              <Typography 
                                variant="h6" 
                                style={{
                                  paddingTop: '8px',
                                  color: '#708090',
                                  textShadow: '1px 1px 2px rgba(0, 0, 0, 0.3)',
                                  fontWeight: 'bold',
                                  userSelect: 'none',
                                }}
                              >
                                  
                                ดำเนินการย้ายโฟลเดอร์เอกสาร...
                             </Typography>
                             </Grid>
                          </>
                ) : (
                  <>
                  <Grid item xs={12} className="GridTopicInput">
                      <Paper elevation={3} style={{ padding: 10 }}>
                        <Typography variant="body1" style={{ fontWeight: 'bold', marginBottom: 5 }}>
                          {t("DocumentName")} {data.documentTypeName}
                        </Typography>
                        <Typography variant="body1">
                          {t("Category")} {data.documentGroupName}
                        </Typography>
                      </Paper>
                    </Grid>
                    <Grid item xs={12} className="GridTopicInput" style={{ marginTop: 15}}>
                          {`${t("DocumentName")}`}
                    </Grid>
                    <Grid item xs={12}>
                          <TextFieldTheme
                            name="documentTypeName"
                            fullWidth
                            onChange={handleChange}
                            value={formData.documentTypeName}
                          />
                    </Grid>
                    <Grid item xs={12} sm={12} className="GridTopicInput">
                          {`${t("Category")}`}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                        <FormControl fullWidth>
                          <Select
                            name="documentGroupName"
                            value={formData.documentGroupName}
                            onChange={handleChange}
                          >
                            {companyDocumentGroup.map((row, index) => (
                              <MenuItem key={index} value={row.documentGroupName}>
                                {row.documentGroupName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={3} className="GridTopicInput">
                            {`${t("Descriptions")}`}
                    </Grid>
                    <Grid item xs={12}>
                          <TextFieldTheme
                            name="detail"
                            fullWidth
                            multiline
                            rows={3}
                            onChange={handleChange}
                            value={formData.detail}
                          />
                    </Grid>
                    <Grid item xs={12} sm={4} className="GridTopicInput">
                            <Typography variant="subtitle1">{t("เปิดให้ดาวโหลดเอกสารได้")}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={8}>
                            <FormControlLabel
                              control={
                                <CustomCheckbox
                                  name="isDownload"
                                  checked={formData.isDownload}
                                  onChange={handleChange}
                                  icon={<CloudOff fontSize="large" />}
                                  checkedIcon={<CloudDownload fontSize="large" />}
                                />
                              }
                              label=""
                            />
                    </Grid>
                    <Grid item xs={12}>
                            <StyledFooter>
                              <ButtonBlue 
                                className="cancel"
                                onClick={handleClose}
                              >
                                {`${t("Cancel")}`}
                              </ButtonBlue>
                              <ButtonBlue 
                                variant="contained"
                                onClick={() => handleSubmit(selectedTab)}                          
                                autoFocus
                              >
                                {`${t("SaveData")}`}
                              </ButtonBlue>
                            </StyledFooter>
                    </Grid>
                 </>
                )}
              </>
            ) : (
              <Grid>
                  <Grid 
                    item xs={12} sm={12} 
                    display="flex" justifyContent="center" 
                    alignItems="center" 
                    my={2}
                  >      
                  <StyledButton
                    type="button"
                    active={activeButton === 'category'}
                    onClick={() => handleTabChange('category')}
                    sx={{
                      fontSize: '15px',
                      borderRadius: '8px', 
                    }}
                  >
                    {`${t("AddDocumentCategories")}`}
                  </StyledButton>
                  <StyledButton
                    variant="contained"
                    type="button"
                    active={activeButton === 'documentName'}
                    onClick={() => handleTabChange('documentName')}
                    sx={{
                      fontSize: '15px',
                      borderRadius: '8px', 
                    }}
                  >
                     {`${t("AddDocumentType")}`}
                   </StyledButton>
                  </Grid>
                 {selectedTab === 'category' && (
                  <>
                    <Grid item xs={12} className="GridTopicInput" textAlign="center">
                      {`${t("Category")}`}
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldTheme
                        type="text"
                        name="documentGroupName"
                        fullWidth
                        onChange={handleChange}
                        value={formData.documentGroupName}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <StyledFooter>
                        <ButtonBlue 
                          className="cancel"
                          onClick={handleClose}
                         >
                          {`${t("Cancel")}`}
                        </ButtonBlue>
                        <ButtonBlue 
                          variant="contained"
                          onClick={() => handleSubmit(selectedTab)}                          
                          autoFocus
                         >
                          {`${t("SaveData")}`}
                        </ButtonBlue>
                      </StyledFooter>
                    </Grid>
                   </>
                 )}
                 {selectedTab === 'documentName' && (
                    <Grid>    
                    <Grid item xs={12} className="GridTopicInput">
                      {`${t("DocName")}`}
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldTheme
                        name="documentTypeName"
                        fullWidth
                        onChange={handleChange}
                        value={formData.documentTypeName}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} className="GridTopicInput">
                      {`${t("Category")}`}
                    </Grid>
                    <Grid item xs={12} sm={12}>
                    <FormControl fullWidth>
                      <Select
                        name="documentGroupName"
                        value={formData.documentGroupName}
                        onChange={handleChange}
                      >
                        {companyDocumentGroup.map((row, index) => (
                          <MenuItem key={index} value={row.documentGroupName}>
                            {row.documentGroupName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                    <Grid item xs={12} sm={3} className="GridTopicInput">
                        {`${t("Descriptions")}`}
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldTheme
                        name="detail"
                        fullWidth
                        multiline
                        rows={3}
                        onChange={handleChange}
                        value={formData.detail}
                      />
                    </Grid>
                    <Grid container spacing={0} alignItems="center">
                      <Grid item xs={12} sm={8} className="GridTopicInput">
                        <Typography variant="subtitle1">{t("OpenForDownloadingDocuments")}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <FormControlLabel
                          control={
                            <CustomCheckbox
                              name="isDownload"
                              checked={formData.isDownload}
                              onChange={handleChange}
                              icon={<CloudOff fontSize="large" />}
                              checkedIcon={<CloudDownload fontSize="large" />}
                            />
                          }
                          label=""
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <StyledFooter>
                        <ButtonBlue 
                          className="cancel" 
                          onClick={handleClose}
                        >
                          {`${t("Cancel")}`}
                        </ButtonBlue>
                        <ButtonBlue 
                          variant="contained" 
                          onClick={() => handleSubmit(selectedTab)}                          
                          autoFocus
                        >
                          {`${t("SaveData")}`}
                        </ButtonBlue>
                      </StyledFooter>
                    </Grid>
                    </Grid>
                  )}
            </Grid>
            )}
          </Grid>
        </StyledRoot>
      )}
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </DrawerCustom> 
    )}
    </>
  );
};

export default DialogType;
