import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, Select, MenuItem, Grid, TextField } from "@mui/material";

import AlertResponse from "../../../shared/general/AlertResponse";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../shared/general/Drawer";
import { getDepartment } from "../../../../../actions/company";
import { AddOnBoardingReviewer } from "../../../../../actions/onBoarding";
import { getAllEmployees } from "../../../../../actions/employee";

//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .GridTopicInput": {
    display: "flex",
    alignItems: "center",
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const DialogReview = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { open, handleClose, data } = props;
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [formData, setFormData] = useState(null);
  const { result: onBoardingList } = useSelector(
    (state) => state.onBoardingList
  );
  const { result: employees } = useSelector((state) => state.employees);

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  useEffect(() => {
    dispatch(getDepartment());
    dispatch(getAllEmployees());

    if (open) {
      setFormData({
        idOnBoardingList: data.idOnBoardingList,
        idEmployees: null,
        itemName: data.name,
      });
    }
  }, [open]);

  const handleSubmit = async () => {
    if (!formData.idOnBoardingList || !formData.idEmployees) {
      handleOpenAlert(true);
      handleChangeAlertType(`${t("PleaseFillInRequiredInformations")}`);
    } else {
      const data = {
        idOnBoardingList: formData.idOnBoardingList,
        idEmployees: formData.idEmployees,
      };
      dispatch(AddOnBoardingReviewer(data));
      handleClose();
    }
  };

  return (
    <DrawerCustom
      title={`${t("AddInspector")}`}
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      {formData && employees && (
        <StyledRoot>
          <Grid container spacing={2} style={{ marginBottom: 16, width: 400 }}>
            <Grid item xs={12} sm={6} className="GridTopicInput">
              {t("ItemName")}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="itemName"
                value={formData.itemName}
                InputProps={{ readOnly: true }}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginBottom: 16, width: 400 }}>
            <Grid item xs={12} sm={6} className="GridTopicInput">
              {t("InspectorName")}
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <Select
                  name="idEmployees"
                  value={formData.idEmployees}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      idEmployees: event.target.value,
                    });
                  }}
                >
                  {employees.map((row) => (
                    <MenuItem
                      key={row.idEmployees}
                      value={row.idEmployees}
                    >{`${row.firstname_TH}  ${row.lastname_TH}`}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <StyledFooter>
            <ButtonBlue className="cancel" onClick={handleClose}>
              {t("Cancel")}
            </ButtonBlue>
            <ButtonBlue variant="contained" onClick={handleSubmit} autoFocus>
              {t("SaveData")}
            </ButtonBlue>
          </StyledFooter>
        </StyledRoot>
      )}
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </DrawerCustom>
  );
};

export default DialogReview;
