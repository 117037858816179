import React from 'react';
import {
  Dialog as MuiDialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Button,
  Grid,
  Box,
  TextField,
  styled,
  Rating,
} from '@mui/material';
import { Controller } from 'react-hook-form';
import ButtonBlue from '../../shared/general/ButtonBlue'; 
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import { addKpiProgressbar, updateKPIEvaluationManager, updateKPIEvaluationEmployee } from "../../../../actions/kpi"; 
import Swal from "sweetalert2";
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  borderRadius: '30px',
  overflow: 'hidden',
  border: '1px solid #EEEEEE',
  display: 'flex',
  justifyContent: 'center',
  width: 'fit-content',
}));

const StyledToggleButton = styled(ToggleButton)(({ theme, selectedColor }) => ({
  flex: 1,
  borderRadius: '30px',
  '&.Mui-selected': {
    backgroundColor: selectedColor,
    color: '#FFFFFF',
  },
  '&.MuiToggleButtonGroup-grouped': {
    border: 'none',
    '&:not(:first-of-type)': {
      borderLeft: '1px solid #EEEEEE',
    },
  },
  '&:hover': {
    backgroundColor: `${selectedColor} !important`,
    color: '#FFFFFF',
  },
  backgroundColor: '#EEEEEE',
  color: '#000000',
  textAlign: 'center',
  minWidth: '100px',
  maxWidth: '200px',
}));

const Dialogs = ({
  dialogType, 
  open,
  onClose,
  progressValue,
  setProgressValue,
  control,
  ratingValue,
  setRatingValue,
  handleSubmit,
  dispatch, 
  idKPI,
  onSuccess
}) => {
  const handleConfirmProgress = async () => {
    const {t} = useTranslation();
    const formData = {
      progress: progressValue,
      idKPI: idKPI,
    };

    const res = await dispatch(addKpiProgressbar(formData));
    if (res && res.status === 200) {
      Swal.fire({
        title: t("Success"),
        text: t("KPIProgressUpdated"),
        icon: "success",
        timer: 2000,
        showConfirmButton: false,
      });
      onClose(); 
      if (onSuccess) onSuccess(); 
    } else {
      Swal.fire({
        title: t("Error"),
        text: t("FailedToUpdateKPIProgress"),
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  const handleConfirmManagerEvaluation = async (data) => {
    const formData = {
      managerEvaluate: data.managerEvaluate === "1" ? 1 : 0,
      managerRating: data.managerRating || 0,
      managerFeedback: data.managerFeedback || "",
    };

    const res = await dispatch(updateKPIEvaluationManager(idKPI, formData));
    if (res && res.status === 200) {
      Swal.fire({
        title: t("Success"),
        text: t("KPIManagerEvaluationUpdated"),
        icon: "success",
        timer: 2000,
        showConfirmButton: false,
      });
      onClose(); 
      if (onSuccess) onSuccess(); 
    } else {
      Swal.fire({
        title: t("Error"),
        text: t("FailedToUpdateKPIManagerEvaluation"),
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  const handleConfirmEmployeeEvaluation = async (data) => {
    const formData = {
      employeeEvaluate: data.employeeEvaluate === "1" ? 1 : 0,
      employeeRating: data.employeeRating || 0,
      employeeFeedback: data.employeeFeedback || "",
    };

    console.log("Employee Evaluation Data:", formData); 

    const res = await dispatch(updateKPIEvaluationEmployee(idKPI, formData)); 
    if (res && res.status === 200) {
      Swal.fire({
        title: t("Success"),
        text: t("KPIEmployeeEvaluationUpdated"),
        icon: "success",
        timer: 2000,
        showConfirmButton: false,
      });
      onClose(); 
      if (onSuccess) onSuccess(); 
    } else {
      Swal.fire({
        title: t("Error"),
        text: t("FailedToUpdateKPIEmployeeEvaluation"),
        icon: "error",
        timer: 2000,
        showConfirmButton: false,
      });
    }
  };

  return (
    <>
      {dialogType === 'progress' && (
        <MuiDialog
          open={open}
          onClose={onClose}
          aria-labelledby="progress-dialog-title"
          sx={{
            "& .MuiDialog-paper": {
              width: "500px",
              height: "200px",
              borderRadius: "20px",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
              backgroundColor: "#FAFAFA",
            },
          }}
        >
          <DialogTitle id="progress-dialog-title">
            <Typography variant="h5">Progress</Typography>
          </DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="progress"
              label="Progress"
              type="number"
              fullWidth
              variant="standard"
              value={progressValue}
              onChange={(e) => setProgressValue(e.target.value)}
              InputProps={{
                endAdornment: <Typography>%</Typography>,
              }}
            />
            <Typography variant="caption" color="error">
              {t("Over100PercentAllowed")}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button type="button" color="error" onClick={onClose}>
              {t("Cancel")}
            </Button>
            <ButtonBlue variant="text" onClick={handleConfirmProgress}>
              {t("Confirm")}
            </ButtonBlue>
          </DialogActions>
        </MuiDialog>
      )}

      {dialogType === 'employee' && (
        <MuiDialog
          open={open}
          onClose={onClose}
          aria-labelledby="employee-dialog-title"
          sx={{
            "& .MuiDialog-paper": {
              width: "550px",
              padding: "20px",
              borderRadius: "20px",
              boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
              backgroundColor: "#FAFAFA",
            },
          }}
        >
          <DialogTitle id="employee-dialog-title">
            <Typography variant="h5">Employee Evaluate</Typography>
          </DialogTitle>
          <DialogContent>
            <form onSubmit={handleSubmit(handleConfirmEmployeeEvaluation)}>
              <Grid container spacing={2}>
                <Grid item xs={12} display="flex" alignItems="center">
                  <Box>
                    <Controller
                      name="employeeEvaluate"
                      control={control}
                      render={({ field }) => (
                        <div>
                          <Typography fontSize={16} marginBottom="8px">
                            {t("Evaluate")}
                          </Typography>
                          <StyledToggleButtonGroup
                            {...field}
                            exclusive
                            value={field.value}
                          >
                            <StyledToggleButton
                              value="1"
                              selectedColor="#50B498"
                            >
                              {t("Success")}
                            </StyledToggleButton>
                            <StyledToggleButton
                              value="0"
                              selectedColor="#FF4C4C"
                            >
                              {t("Failure")}
                            </StyledToggleButton>
                          </StyledToggleButtonGroup>
                        </div>
                      )}
                    />
                  </Box>
                  <Box marginLeft="30px">
                    <Typography fontSize={16} marginBottom="4px">
                      Rating {ratingValue ? `${ratingValue}.0` : ""}
                    </Typography>
                    <Controller
                      name="employeeRating"
                      control={control}
                      render={({ field }) => (
                        <Box
                          display="flex"
                          gap="8px"
                          paddingLeft="16px"
                          maxWidth="400px"
                        >
                          <Rating
                            {...field}
                            name="employeeRating"
                            min={1}
                            max={5}
                            precision={1}
                            value={ratingValue}
                            icon={
                              <StarIcon
                                sx={{
                                  fontSize: "50px",
                                  color: "#FFC403",
                                }}
                              />
                            }
                            emptyIcon={
                              <StarBorderIcon
                                sx={{
                                  fontSize: "50px",
                                  color: "#ddd",
                                }}
                              />
                            }
                            onChange={(_, newValue) => {
                              field.onChange(newValue);
                              setRatingValue(newValue);
                            }}
                          />
                        </Box>
                      )}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="employeeFeedback"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="Feedback"
                        multiline
                        rows={2}
                        fullWidth
                        variant="outlined"
                      />
                    )}
                  />
                </Grid>
                <DialogActions   sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%", 
                }}>
                  <Button type="button" color="error" onClick={onClose}>
                    {t("Cancel")}
                  </Button>
                  <ButtonBlue type="submit" variant="text">
                {t("Confirm")}
              </ButtonBlue>
            </DialogActions>
          </Grid>
        </form>
      </DialogContent>
    </MuiDialog>
  )}

  {dialogType === 'manager' && (
    <MuiDialog
      open={open}
      onClose={onClose}
      aria-labelledby="manager-dialog-title"
      sx={{
        "& .MuiDialog-paper": {
          width: "550px",
          padding: "20px",
          borderRadius: "20px",
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          backgroundColor: "#FAFAFA",
        },
      }}
    >
      <DialogTitle id="manager-dialog-title">
        <Typography variant="h5">{t("Manager")} {t("Evaluate")}</Typography>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(handleConfirmManagerEvaluation)}>
          <Grid container spacing={2}>
            <Grid item xs={12} display="flex" alignItems="center">
              <Box>
                <Controller
                  name="managerEvaluate"
                  control={control}
                  render={({ field }) => (
                    <div>
                      <Typography fontSize={16} marginBottom="8px">
                        {t("Evaluate")}
                      </Typography>
                      <StyledToggleButtonGroup
                        {...field}
                        exclusive
                        value={field.value}
                      >
                        <StyledToggleButton
                          value="1"
                          selectedColor="#50B498"
                        >
                          {t("Success")}
                        </StyledToggleButton>
                        <StyledToggleButton
                          value="0"
                          selectedColor="#FF4C4C"
                        >
                          {t("Failure")}
                        </StyledToggleButton>
                      </StyledToggleButtonGroup>
                    </div>
                  )}
                />
              </Box>
              <Box marginLeft="30px">
                <Typography fontSize={16} marginBottom="4px">
                  Rating {ratingValue ? `${ratingValue}.0` : ""}
                </Typography>
                <Controller
                  name="managerRating"
                  control={control}
                  render={({ field }) => (
                    <Box
                      display="flex"
                      gap="8px"
                      paddingLeft="16px"
                      maxWidth="400px"
                    >
                      <Rating
                        {...field}
                        name="managerRating"
                        min={1}
                        max={5}
                        precision={1}
                        value={ratingValue}
                        icon={
                          <StarIcon
                            sx={{
                              fontSize: "50px",
                              color: "#FFC403",
                            }}
                          />
                        }
                        emptyIcon={
                          <StarBorderIcon
                            sx={{
                              fontSize: "50px",
                              color: "#ddd",
                            }}
                          />
                        }
                        onChange={(_, newValue) => {
                          field.onChange(newValue);
                          setRatingValue(newValue);
                        }}
                      />
                    </Box>
                  )}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="managerFeedback"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Feedback"
                    multiline
                    rows={2}
                    fullWidth
                    variant="outlined"
                  />
                )}
              />
            </Grid>
            <DialogActions
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "100%", 
              }}
            >
              <Button type="button" color="error" onClick={onClose}>
                {t("Cancel")}
              </Button>
              <ButtonBlue type="submit" variant="text">
                {t("Confirm")}
              </ButtonBlue>
            </DialogActions>
          </Grid>
        </form>
      </DialogContent>
    </MuiDialog>
  )}
</>
);
};
export default Dialogs;