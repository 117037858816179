import React, { useState, useEffect, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import {
  Box,
  Grid,
  Link,
  Typography,
  FilledInput,
  InputAdornment,
  FormControl,
  IconButton,
  Chip,
  Button,
  Tooltip,
} from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import dayjs from "dayjs";

import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import SearchIcon from "@mui/icons-material/Search";
import PublishedWithChangesRoundedIcon from "@mui/icons-material/PublishedWithChangesRounded";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import DoNotDisturbOnRoundedIcon from "@mui/icons-material/DoNotDisturbOnRounded";

import AlertResponse from "../../../../shared/general/AlertResponse";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import {
  getAllLocations,
  updateGroupGpsLocations,
  updateGpsLocations,
} from "../../../../../../actions/gpsLocations";

import DialogGpsLocations from "./DialogGpsLocations";
import ListMap from "./listMap";

import QrCodeIcon from "@mui/icons-material/QrCode";
import DialogQRCode from "./DialogQRCode";

//Translator TH-EN
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  locations_container: {
    marginTop: theme.spacing(2),
  },
  loading: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  locationCard: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderRadius: "20px",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  },
  infoLocationBox: {
    position: "relative",
    paddingLeft: theme.spacing(4),
    height: "100%",
  },
  lastUpdateBox: {
    position: "absolute",
    bottom: 0,
    right: 0,
  },
  locationNameBox: {
    marginBottom: theme.spacing(2),
  },
}));

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: "none",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  width: "100%",
  borderRadius: 8,
  marginBottom: 8,
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
  "& .wrap": {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  paddingTop: 0,
  borderTop: "none",
  "& .wrap-location": {
    display: "flex",
    flexWrap: "wrap",
    "& .inner-box": {
      width: 300,
      margin: 8,
      position: "relative",
      "& .btn-delete": {
        position: "absolute",
        right: -16,
        top: 22,
        zIndex: 10,
        "& .MuiButtonBase-root": {
          color: "#d32f2f",
        },
      },
    },
  },
  "& .wrap-add": {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 8,
  },
  "& .btn-delete-position": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 14,
    "& .MuiSvgIcon-root": {
      marginRight: 4,
      fontSize: "inherit",
    },
  },
}));

const StyledRoot = styled("div")({
  marginTop: 28,
  "& .MuiTypography-h6": {
    fontSize: "1.5rem",
  },
  "& .MuiTypography-subtitle1": {
    fontSize: "0.9rem",
  },
  "& .MuiInput-underline.Mui-disabled:before": {
    borderBottomStyle: "none",
  },
  "& .wrap-btn-cancel-location": {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 8,
  },
});

const StyledEmpty = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 150,
});

const StyledFilledInput = styled(FilledInput)({
  backgroundColor: "#919eab14",
  height: 56,
  padding: "0px 12px",
  borderRadius: 8,
  "& .MuiFilledInput-input": {
    paddingTop: 18,
    paddingBottom: 18,
  },
  "&.Mui-focused": {
    backgroundColor: "transparent",
  },
  "& .MuiInputAdornment-root": {
    width: 32,
    marginTop: "0!important",
    fontSize: 24,
    color: "#919EAB",
    "& i": {
      marginRight: 8,
    },
  },
  "& .MuiAutocomplete-endAdornment": {
    "& .MuiButtonBase-root": {
      fontSize: 14,
      width: 22,
      height: 22,
    },
  },
  "&:hover": {
    backgroundColor: "#919eab29",
    "&:before": {
      border: "none !important",
    },
  },
  "&::after": {
    border: "none",
  },
  "&::before": {
    border: "none",
  },
});

const StyledBoxSearch = styled(Box)({
  marginBottom: 24,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledChip = styled(Chip)({
  backgroundColor: "#f15e5e",
  fontWeight: 600,
  color: "#FFFFFF",
});

function LocationList(props) {
  const { selectedCompany, isReadOnly } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: locationsList } = useSelector((state) => state.locations);
  const { isFetching: locationsListIsFetching } = useSelector(
    (state) => state.locations
  );
  const [expanded, setExpanded] = React.useState("");
  const [searchGroup, setSearchGroup] = React.useState(null);
  const [method, setMethod] = React.useState("");
  const [openDialogGpsLocations, setOpenDialogGpsLocations] =
    React.useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [mode, setMode] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState("");

  const [isOpenDialogQRCode, setIsOpenDialogQRCode] = useState({
    open: false,
    idGroupGpsLocations: null,
    groupGpsLocationsName: null,
  });

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const handleOpenDialogGpsLocations = () => {
    setMethod("group");
    setMode("add");
    setOpenDialogGpsLocations(true);
  };

  const handleOpenDialogAddGpsPositions = (selected) => (event) => {
    event.stopPropagation();
    setMethod("gps");
    setMode("add");
    setSelectedGroup(selected);
    setOpenDialogGpsLocations(true);
  };

  const handleOpenDialogEditGpsPositions = (selected) => (event) => {
    setMethod("gps");
    setMode("edit");
    setSelectedGroup(selected);
    setOpenDialogGpsLocations(true);
  };

  const handleOpenDialogEditGroup = (selected) => (event) => {
    setMethod("group");
    setMode("edit");
    setSelectedGroup(selected);
    setOpenDialogGpsLocations(true);
  };

  const handleChangeAlertType = (status) => {
    setAlertType(status);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleClose = () => {
    setOpenDialogGpsLocations(false);
  };

  const handleCloseAlert = () => {
    setOpenAlert("");
  };

  const handleChangeSearch = (event) => {
    const { name, value } = event.target;
    setSearchGroup(value);
  };

  const handleSaveGroupGpsLocations = async (selected) => {
    if (userProfile) {
      console.log("selected: ", selected);
      let formDataRequest = { ...selected };

      formDataRequest.updateBy = userProfile.idEmployees;

      formDataRequest.isActive = true;

      console.log("formDataRequest: ", formDataRequest);

      let result = await dispatch(updateGroupGpsLocations(formDataRequest));

      if (result) {
        handleOpenAlert();
        if (result.status === 200) {
          handleChangeAlertType("success");
          if (userProfile) {
            dispatch(getAllLocations(userProfile.idCompany));
          }
        } else {
          handleChangeAlertType(result.data.message);
        }
        handleClose();
      } else {
        handleOpenAlert();
        handleChangeAlertType("error");
        handleClose();
      }
    } else {
      handleClose();
    }
  };

  const handleUpdateGpsLocations = async (selected) => {
    if (userProfile) {
      console.log("selected: ", selected);
      let formData = { ...selected };
      formData.isActive = false;
      formData.editBy = userProfile.idEmployees;
      formData.editDate = dayjs().format("YYYY-MM-DD HH:mm:ss");

      const result = await dispatch(updateGpsLocations(formData));
      if (result) {
        handleOpenAlert();
        if (result.status === 200) {
          handleChangeAlertType("success");
          if (userProfile) {
            dispatch(getAllLocations({ idCompany: selectedCompany.idCompany }));
          }
        } else {
          handleChangeAlertType("error");
        }
        handleClose();
      } else {
        handleOpenAlert();
        handleChangeAlertType("error");
        handleClose();
      }
    } else {
      handleClose();
    }
  };

  useEffect(() => {
    if (userProfile && selectedCompany) {
      dispatch(getAllLocations({ idCompany: selectedCompany.idCompany }));
    }
  }, [userProfile, selectedCompany]);

  return (
    <StyledRoot className={classes.root}>
      <Box style={{ marginBottom: 16 }}>
        <StyledBoxSearch>
          <FormControl fullWidth variant="filled">
            <StyledFilledInput
              id="filled-sarch-name"
              placeholder={t("SearchArea")}
              name="search"
              onChange={handleChangeSearch}
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
            />
          </FormControl>
        </StyledBoxSearch>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="h6">
              {t("AreaList")}/{t("Location")}
            </Typography>
          </Grid>
          <Grid item>
            <ButtonBlue
              variant={"contained"}
              onClick={handleOpenDialogGpsLocations}
            >
              {t("AddNewArea")}
            </ButtonBlue>
          </Grid>
        </Grid>
      </Box>
      {locationsListIsFetching ? (
        <StyledEmpty className="empty-holiday">
          <Typography color="text.secondary" align="center">
            {t("LoadingData")}...
          </Typography>
        </StyledEmpty>
      ) : (
        <Fragment>
          {locationsList && locationsList.length > 0 ? (
            <div>
              {/* {console.log(locationsList
                .filter((x) => {
                  if (searchGroup) {
                    return x.name.includes(searchGroup);
                  } else {
                    return x;
                  }
                }))} */}
              {locationsList
                .filter((x) => {
                  if (searchGroup) {
                    return x.name.includes(searchGroup);
                  } else {
                    return x;
                  }
                })
                .map((value, index) => (
                  <Accordion
                    expanded={expanded === `panel${index}`}
                    onChange={handleChange(`panel${index}`)}
                  >
                    <AccordionSummary
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                    >
                      <div className="wrap">
                        <div style={{ display: "flex" }}>
                          <Typography
                            style={{ fontWeight: 500, marginRight: 4 }}
                          >{`${value.name}`}</Typography>
                          {value.isActive === 0 && (
                            <StyledChip label={t("Inactive")} size="small" />
                          )}
                        </div>

                        <div>
                          {value.isActive ? (
                            <Fragment>
                              <IconButton
                                disabled={value.isActive === 0}
                                style={{ marginRight: 4 }}
                                aria-label="qr code"
                                onClick={(event) => {
                                  event.stopPropagation();
                                  setIsOpenDialogQRCode({
                                    open: true,
                                    idGroupGpsLocations:
                                      value.idGroupGpsLocations,
                                    groupGpsLocationsName: value.name,
                                  });
                                }}
                              >
                                <QrCodeIcon fontSize="inherit" />
                              </IconButton>
                              <IconButton
                                disabled={value.isActive === 0}
                                style={{ marginRight: 4 }}
                                aria-label="add"
                                onClick={handleOpenDialogAddGpsPositions(value)}
                              >
                                <AddRoundedIcon fontSize="inherit" />
                              </IconButton>
                              <IconButton
                                disabled={value.isActive === 0}
                                aria-label="edit"
                                onClick={handleOpenDialogEditGroup(value)}
                              >
                                <EditNoteRoundedIcon fontSize="inherit" />
                              </IconButton>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <IconButton
                                aria-label="renew"
                                onClick={() =>
                                  handleSaveGroupGpsLocations(value)
                                }
                              >
                                <PublishedWithChangesRoundedIcon fontSize="inherit" />
                              </IconButton>
                            </Fragment>
                          )}
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="wrap-location">
                        {value.locations &&
                          value.locations.map((item, index) => (
                            <Box className="inner-box" key={`${item.idGpsLocations}`}>
                              {value.locations.length > 1 && !isReadOnly && (
                                <div className="btn-delete">
                                  <Tooltip title="ปิดการใช้">
                                    <IconButton
                                      aria-label="delete"
                                      onClick={() =>
                                        handleUpdateGpsLocations(item)
                                      }
                                    >
                                      <DoNotDisturbOnRoundedIcon />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              )}

                              <Link
                                onClick={handleOpenDialogEditGpsPositions(item)}
                                underline="hover"
                                color="inherit"
                              >
                                <Typography
                                  align="center"
                                  variant="h6"
                                  style={{ lineHeight: 1.25, marginBottom: 8 }}
                                >
                                  {item.name}
                                </Typography>
                              </Link>
                              <ListMap
                                id={`map-${item.name}-${index}`}
                                positions={item.positions}
                              />
                              <Typography
                                align="right"
                                variant="subtitle1"
                                style={{ color: "#9e9e9e" }}
                              >
                                {t("LatestUpdate")} :{" "}
                                {item.editDate
                                  ? dayjs(item.editDate).format("DD MMM YYYY")
                                  : dayjs(item.createDate).format(
                                    "DD MMM YYYY"
                                  )}
                              </Typography>
                            </Box>
                          ))}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                ))}
            </div>
          ) : (
            <StyledEmpty className="empty-holiday">
              <Typography color="text.secondary" align="center">
                {t("NoData")}
              </Typography>
            </StyledEmpty>
          )}
        </Fragment>
      )}

      <DialogGpsLocations
        open={openDialogGpsLocations}
        method={method}
        mode={mode}
        selectedGroup={selectedGroup}
        handleClose={handleClose}
        handleOpenAlert={handleOpenAlert}
        handleChangeAlertType={handleChangeAlertType}
        selectedCompany={selectedCompany}
      />

      <DialogQRCode
        open={isOpenDialogQRCode.open}
        idGroupGpsLocations={isOpenDialogQRCode.idGroupGpsLocations}
        groupGpsLocationsName={isOpenDialogQRCode.groupGpsLocationsName}
        onClose={() => {
          setIsOpenDialogQRCode({
            ...isOpenDialogQRCode,
            open: false,
          });
        }}
      />

      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </StyledRoot>
  );
}

export default LocationList;
