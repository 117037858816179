import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import { Container, Grid, MenuItem, Tab, Tabs } from "@mui/material";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import DatePickerCustom from "../../shared/date/datePicker";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import useMediaQuery from "@mui/material/useMediaQuery";

import ButtonBlue from "../../shared/general/ButtonBlue";
import SnackBarCustom from "../../shared/snackbarCustom";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import {
  getUsersTimeforApprove
} from "../../../../actions/workforceEmployees";
import {
  getAllRequestTimeBy,
  getAllRequestTimeWithDrawBy,
} from "../../../../actions/requestTime";
import {
  getLeaveRequest,
  getEmployeeChangeTime,
} from "../../../../actions/employee";
import { getAllLeaveWithDrawBy } from "../../../../actions/leave";
import { getPayrollSetting } from "../../../../actions/paytypes";

import RequestTime from "./RequestTime";
import RequestOverTime from "./RequestOverTime";
import RequestLeave from "./RequestLeave";
import RequestTimeShift from "./RequestTimeShift";
import RequestCompensate from "./RequestCompensate";
import RequestInputHourUserWorkforce from "./RequestInputHourUserWorkforce"
//Translator TH-EN
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import loading from "../../assets/social-media.gif";

const StyledRoot = styled("div")(({ theme }) => ({
  background: "#FFFFFF !important",
  "& .displayFlexEnd": {
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "8px 0",
    },
  },
}));

const StyledBoxSearch = styled(Box)({
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledCard = styled(Card)({
  padding: 16,
  boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  "& .MuiCardContent-root": {
    padding: 24,
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Requestlist = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [search, setSearch] = React.useState({ start: null, end: null });
  const [approveTabsValue, setApproveTabsValue] = React.useState(0);

  const { isFetching: isFetchingEmployeeChangeShift, result: employeeChangeShiftList } = useSelector(
    (state) => state.employeeChangeShift
  );
  const { isFetching: isFetchingRequestTime, result: requestTimeList } = useSelector((state) => state.requestTime);
  const { isFetching: isFetchingRequestTimeWithDraw, result: requestTimeWithDrawList } = useSelector(
    (state) => state.requestTimeWithDraw
  );
  const { isFetching: isFetchingLeaveEmployees, result: leaveEmployeesList } = useSelector(
    (state) => state.leaveEmployees
  );
  const { isFetching: isFetchingLeaveEmployeeWithdraws, result: leaveEmployeeWithdrawsList } = useSelector(
    (state) => state.leaveEmployeesWithDraw
  );

  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: payrollSetting } = useSelector(
    (state) => state.payrollSetting
  );

  const { result: projectGetUserTimeforApprove } = useSelector(
    (state) => state.projectGetUserTimeforApprove
  );

  const mobile = useMediaQuery("(max-width:600px)");

  const [snackBarConfig, setSnackBarConfig] = useState({
    open: false,
    message: "",
    type: "success",
  });

  useEffect(() => {
    if(userProfile){
      dispatch(getEmployeeChangeTime("manager"));
      dispatch(getLeaveRequest("manager", search));
      dispatch(getAllLeaveWithDrawBy("manager", search));
      dispatch(getAllRequestTimeBy("manager", search));
      dispatch(getAllRequestTimeWithDrawBy("manager", search));
      dispatch(getPayrollSetting());
      dispatch(getUsersTimeforApprove())
    }
  }, [userProfile]);

  const handleApproveTabsChange = (event, newValue) => {
    setApproveTabsValue(newValue);
  };

  const handleApproveTabsChangeSelect = (event) => {
    setApproveTabsValue(event.target.value);
  };

  const handleClickSearch = async () => {
    dispatch(getLeaveRequest("manager", search));
    dispatch(getAllLeaveWithDrawBy("manager", search));
    dispatch(getAllRequestTimeBy("manager", search));
    dispatch(getAllRequestTimeWithDrawBy("manager", search));
  };

  return (
    <StyledRoot className="page">
      <SnackBarCustom config={snackBarConfig} setConfig={setSnackBarConfig} />

      <Container maxWidth="lg" style={{ paddingBottom: 40 }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h4" gutterBottom style={{ paddingTop: 8 }}>
              {`${t("ApprovalHistory")}`}
            </Typography>
          </Grid>
          <Grid item className="displayFlexEnd">
            <ButtonBlue
              variant="contained"
              style={{ height: "fit-content" }}
              startIcon={<TextSnippetIcon />}
              // onClick={() => props.history.push("/manager/approve")}
              component={Link}
              to={"/waiting-approve"}
            >
              {`${t("PendingApprovalList")}`}
            </ButtonBlue>
          </Grid>
        </Grid>
        <Box style={{ paddingBottom: 16 }}>
          <Grid container alignItems="flex-end" spacing={2}>
            <Grid item xs={12} sm={3}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {`${t("StartDate")}`}
                </Typography>
                <div className="search-date">
                  <DatePickerCustom
                    inputFormat="DD/MM/YYYY"
                    minDate={dayjs().subtract(1, "year").startOf("year")}
                    maxDate={dayjs(search.end)}
                    value={search.start}
                    name="start"
                    views={["year", "month", "day"]}
                    onChange={(newValue) => {
                      setSearch({
                        ...search,
                        start: newValue,
                      });
                    }}
                  />
                </div>
              </StyledBoxSearch>
            </Grid>
            <Grid item xs={12} sm={3}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {`${t("EndDate")}`}
                </Typography>
                <div className="search-date">
                  <DatePickerCustom
                    inputFormat="DD/MM/YYYY"
                    minDate={dayjs(search.start)}
                    maxDate={dayjs()}
                    value={search.end}
                    name="start"
                    views={["year", "month", "day"]}
                    onChange={(newValue) => {
                      setSearch({
                        ...search,
                        end: newValue,
                      });
                    }}
                  />
                </div>
              </StyledBoxSearch>
            </Grid>
            <Grid item xs={12} sm={3}>
              <div style={{ paddingBottom: 12 }}>
                <ButtonBlue variant={"contained"} onClick={handleClickSearch}>
                  {`${t("Search")}`}
                </ButtonBlue>
              </div>
            </Grid>
          </Grid>
        </Box>
        <StyledCard>
          <Box paddingBottom="24px">
            {mobile ? (
              <TextFieldTheme
                value={approveTabsValue}
                onChange={handleApproveTabsChangeSelect}
                select
              >
                <MenuItem value={0}>{`${t("WorkingTimeRequest")}`}</MenuItem>
                <MenuItem value={1}>{`${t("OvertimeRequest")}`}</MenuItem>
                <MenuItem value={4}>{`${t("CompensateRequest")}`}</MenuItem>
                <MenuItem value={2}>{`${t("LeaveRequest")}`}</MenuItem>
                <MenuItem value={3}>{`${t("ShiftRequest")}`}</MenuItem>
                <MenuItem value={5}>{`${t("คำขอลงเวลา")}`}</MenuItem>
              </TextFieldTheme>
            ) : (
              <Tabs
                value={approveTabsValue}
                onChange={handleApproveTabsChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="basic tabs example"
                sx={{
                  "& .MuiTab-root.Mui-selected": {
                    color: "#46cbe2",
                  },
                }}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#46cbe2",
                  },
                }}
              >
                <Tab
                  label={`${t("WorkingTimeRequest")}`}
                  {...a11yProps(1)}
                  value={0}
                />
                <Tab label={`${t("Overtime")}`} {...a11yProps(1)} value={1} />
                <Tab
                  label={`${t("CompensateRequest")}`}
                  {...a11yProps(4)}
                  value={4}
                />
                <Tab
                  label={`${t("LeaveRequest")}`}
                  {...a11yProps(2)}
                  value={2}
                />
                <Tab
                  label={`${t("ShiftRequest")}`}
                  {...a11yProps(3)}
                  value={3}
                />
                <Tab
                  label={`${t("คำขอลงเวลา")}`}
                  {...a11yProps(5)}
                  value={5}
                />
              </Tabs>
            )}
          </Box>
          {userProfile && (
            <Box style={{ width: "100%" }}>
              {(
                [0,1,4].includes(approveTabsValue) ? (isFetchingRequestTime || isFetchingRequestTimeWithDraw) :
                  approveTabsValue === 2 ? (isFetchingLeaveEmployees || isFetchingLeaveEmployeeWithdraws) :
                    (approveTabsValue === 3 && isFetchingEmployeeChangeShift)
              ) &&
                <Box
                  style={{
                    width:"100%",
                    display:"flex",
                    justifyContent:"center",
                    paddingTop:"40px"
                  }}
                >
                  <img width="120" alt="loading" src={loading} />
                </Box>
              }
              {requestTimeList && requestTimeWithDrawList && (
                <TabPanel value={approveTabsValue} index={0}>
                  <RequestTime
                    search={search}
                    setSnackBarConfig={setSnackBarConfig}
                  />
                </TabPanel>
              )}

              {requestTimeList && requestTimeWithDrawList && payrollSetting && (
                <TabPanel value={approveTabsValue} index={1}>
                  <RequestOverTime
                    search={search}
                    setSnackBarConfig={setSnackBarConfig}
                  />
                </TabPanel>
              )}

              {leaveEmployeesList && leaveEmployeeWithdrawsList && (
                <TabPanel value={approveTabsValue} index={2}>
                  <RequestLeave
                    searchDate={search}
                    setSnackBarConfig={setSnackBarConfig}
                  />
                </TabPanel>
              )}

              {employeeChangeShiftList && (
                <TabPanel value={approveTabsValue} index={3}>
                  <RequestTimeShift />
                </TabPanel>
              )}

              {requestTimeList && requestTimeWithDrawList && (
                <TabPanel value={approveTabsValue} index={4}>
                  <RequestCompensate
                    searchDate={search}
                    setSnackBarConfig={setSnackBarConfig}
                  />
                </TabPanel>
              )}
              {projectGetUserTimeforApprove && projectGetUserTimeforApprove && (
                <TabPanel value={approveTabsValue} index={5}>
                  <RequestInputHourUserWorkforce
                    searchDate={search}
                    setSnackBarConfig={setSnackBarConfig}
                  />
                </TabPanel>
              )}
            </Box>
          )}
        </StyledCard>
      </Container>
    </StyledRoot>
  );
};

export default Requestlist;
