import React, { Fragment } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField, styled } from "@mui/material";
import i18next from "i18next";
import TextFieldTheme from "../general/TextFieldTheme";

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiFilledInput-input": {
      padding: "7px 4px",
    },
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      "& button": {
        color: "#919EAB",
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

class newAdapter extends AdapterDayjs {
  constructor({ locale, formats, instance }) {
    super({ locale, formats, instance });
  }
  formatByString = (date, format) => {
    const yearFormat = i18next.resolvedLanguage === "th" ? "BBBB" : "YYYY";
    const newFormat = format.replace(/\bYYYY\b/g, yearFormat);
    return this.dayjs(date).format(newFormat);
  };
}

const DatePickerCustom = React.forwardRef((props, ref) => {
  const { variant } = props;
  return (
    <LocalizationProvider
      dateAdapter={newAdapter}
      adapterLocale={i18next.resolvedLanguage}
    >
      <DatePicker
        inputFormat={
          i18next.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY"
        }
        renderInput={(params) => (
          <Fragment>
            {props.variant === "outlined" ? (
              <TextFieldTheme style={{ width: "100%" }} ref={ref} {...params} />
            ) : (
              <StyledTextField variant="filled" fullWidth ref={ref} {...params} />
            )}
          </Fragment>
        )}
        PaperProps={{
          sx: {
            "& .Mui-selected": {
              backgroundColor: "#46cbe2!important",
            },
          },
        }}
        DialogProps={{
          sx: {
            "& .Mui-selected": {
              backgroundColor: "#46cbe2!important",
            },
          },
        }}
        {...props}
      />
    </LocalizationProvider>
  );
});

export default DatePickerCustom;
