import React from "react";
import {
  Box,
  Container,
  styled,
  Typography,
} from "@mui/material";
import CompanyDocumentPage from "./companyDocumentPage";
import CardStyle from "../../shared/general/Card";

import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  backgroundColor: "#f5f5f5",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
});

const WrapHeader = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  ["@media only screen and (max-width:600px)"]: {
    flexDirection: "column",
  },
  marginTop: 8,
  marginBottom: 16,
});

const DocumentCompany = () => {
  const { t, i18n } = useTranslation();

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <WrapHeader>
            <Typography variant="h4">{t("CompanyDocuments")}</Typography>
        </WrapHeader>
        <CardStyle style={{ padding: 16 }}>
          <CompanyDocumentPage/>
        </CardStyle>
      </Container>
    </StyledRoot>
  );
};

export default  DocumentCompany;
