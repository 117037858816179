import React, { Fragment, useState } from "react";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import {
  Typography,
  Grid,
  Box,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
  CircularProgress,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckIcon from "@mui/icons-material/Check";
import PersonIcon from '@mui/icons-material/Person';
import DomainIcon from '@mui/icons-material/Domain';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EventIcon from '@mui/icons-material/Event';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../shared/general/Drawer";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import {
  getUserFullName,
  getUserPosition,
} from "../../../../../utils/userData";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .GridTopicInput": {
    display: "flex",
    alignItems: "center",
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "space-between",
});

const score = {
  verygood: 100,
  good: 80,
  meduim: 60,
  fair: 40,
  prettybad: 20,
  bad: 0,
};

const DialogProbation = (props) => {
  const { open, handleClose, user, detail, handleSubmit } = props;

  let scorePercents = 0;
  if (detail && detail.question) {
    scorePercents = Math.round(detail.question.reduce((acc, curr) => acc + score[curr.answer], 0) / detail.question.length) || 0;
  }

  const { t, i18n } = useTranslation();
  const [probationResult, setProbationResult] = useState(detail.result);

  const clickSubmit = () => {
    handleSubmit({
      idProbation: detail.idProbation,
      probationResult: probationResult,
    });
  };

  return (
    <DrawerCustom
      title={`${t("ProbationEvaluationForm")}`}
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      <StyledRoot>
        <Grid container spacing={2} style={{ marginBottom: 16 }}>
          <Grid item xs={12} display={"flex"} alignItems={"center"} gap={1}>
            <PersonIcon />
            <Typography>
              {`${t("PersonBeingAssessed")} : ${getUserFullName(user)}`}
            </Typography>
          </Grid>
          <Grid item xs={12} display={"flex"} alignItems={"center"} gap={1}>
            <DomainIcon />
            <Typography>
              {`${t("Position")} : ${getUserPosition(user)}`}
            </Typography>
          </Grid>
          <Grid item xs={12} display={"flex"} alignItems={"center"} gap={1}>
            <AssessmentIcon />
            <Typography>
              {`${t("ProbationRound")} : ${detail.probationRound}`}{" "}
            </Typography>
          </Grid>
          {detail.expandTime && (
            <Grid item xs={12} display={"flex"} alignItems={"center"} gap={1}>
              <AddCircleIcon />
              <Typography>
                {`${t("ExtendProbationPeriod")}: ${detail.expandTime} ${t("Unit.Days")}`}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} display={"flex"} alignItems={"center"} gap={1}>
            <EventIcon />
            <Typography>
              {`${t("ProbationaryExpirationDate")} :  ${dayjs(
                detail.probationDate
              ).format(
                i18n.resolvedLanguage === "th" ? "D MMM BBBB" : "D MMM YYYY"
              )}`}
            </Typography>
          </Grid>
          <Grid item xs={12} display={"flex"} alignItems={"center"} gap={1}>
            <CheckCircleIcon />
            <Typography>
              {`${t("EvaluatedBy")} : ${
                detail.approveBy === null
                  ? t("SystemEvaluation")
                  : i18n.resolvedLanguage === "th"
                  ? detail.approvedBy
                  : detail.approvedBy_EN
              }`}
            </Typography>
          </Grid>
          {detail && detail.question && (
            <>
              <Grid item xs={12}>
                {detail.question.map((data, index) => (
                  <Grid key={index} container>
                    <Grid item xs={12} marginTop={2}>
                      <i className="fa-regular fa-square-question fa-xl"></i>
                      &nbsp;&nbsp;
                      {`${t("Question")} ${index + 1} : ${data.question}`}
                    </Grid>
                    <Grid item xs={12} marginTop={1}>
                      <Fragment>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          value={data.answer}
                        >
                          <FormControlLabel
                            value="verygood"
                            control={<Radio />}
                            label={t("Excellence")}
                          />
                          <FormControlLabel
                            value="good"
                            control={<Radio />}
                            label={t("Good")}
                          />
                          <FormControlLabel
                            value="meduim"
                            control={<Radio />}
                            label={t("Neutral")}
                          />
                          <FormControlLabel
                            value="fair"
                            control={<Radio />}
                            label={t("Fair")}
                          />
                          <FormControlLabel
                            value="prettybad"
                            control={<Radio />}
                            label={t("Poor")}
                          />
                          <FormControlLabel
                            value="bad"
                            control={<Radio />}
                            label={t("VeryPoor")}
                          />
                        </RadioGroup>
                      </Fragment>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider
                        sx={{
                          marginTop: "16px",
                          borderColor: "#9e9e9e66",
                        }}
                      />
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <Grid item xs={12}>
                <i className="fa-regular fa-bone fa-xl"></i>
                &nbsp;&nbsp;{t("Strength")}
              </Grid>
              <Grid item xs={12}>
                <TextFieldTheme
                  inputProps={{ readOnly: true }}
                  multiline
                  rows={3}
                  value={detail.goodPoint || ""}
                ></TextFieldTheme>
              </Grid>
              <Grid item xs={12}>
                <i className="fa-regular fa-bone-break fa-xl"></i>
                &nbsp;&nbsp;{t("Weakness")}
              </Grid>
              <Grid item xs={12}>
                <TextFieldTheme
                  inputProps={{ readOnly: true }}
                  multiline
                  rows={3}
                  value={detail.weakPoint || ""}
                ></TextFieldTheme>
              </Grid>
              <Grid item xs={12}>
                {t("Other")}
              </Grid>
              <Grid item xs={12}>
                <TextFieldTheme
                  inputProps={{ readOnly: true }}
                  multiline
                  rows={3}
                  value={detail.others || ""}
                ></TextFieldTheme>
              </Grid>
              <Grid item xs={12}>
                {t("AdditionalCommentsFromSupervisor")}
              </Grid>
              <Grid item xs={12}>
                <TextFieldTheme
                  inputProps={{ readOnly: true }}
                  multiline
                  rows={3}
                  value={detail.commentManagerLV1 || ""}
                />
              </Grid>
              <Grid
                item
                xs={12}
                marginTop={2}
                marginBottom={2}
                display={"flex"}
                justifyContent={"center"}
              >
                <Box style={{ position: "relative", display: "inline-flex" }}>
                  <CircularProgress
                    variant="determinate"
                    style={{
                      color: "#eeeeee",
                    }}
                    size={70}
                    thickness={4}
                    value={100}
                  />
                  <CircularProgress
                    variant="determinate"
                    value={scorePercents}
                    style={{
                      color: "#007afe",
                      animationDuration: "550ms",
                      position: "absolute",
                      left: 0,
                    }}
                    size={70}
                  ></CircularProgress>
                  <Box
                    style={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: "absolute",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="h6"
                      style={{ fontSize: 16 }}
                      component="div"
                      color="text.third"
                    >
                      {scorePercents}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                align: "center",
                gap: 8,
              }}
            >
              <ButtonBlue
                sx={{ backgroundColor: "#00c853" }}
                variant="contained"
                disabled={probationResult === 1}
                onClick={() => setProbationResult(1)}
              >{`${t("PassedProbation")}`}</ButtonBlue>
              <ButtonBlue
                sx={{ backgroundColor: "#ff5252" }}
                variant="contained"
                disabled={probationResult === 0}
                onClick={() => setProbationResult(0)}
              >{`${t("NotPassedProbation")}`}</ButtonBlue>
            </div>
          </Grid>
          {detail.result === null && (
            <Grid item xs={12}>
              <Typography marginBottom={1}>{t("ReasonForExtendingProbation")}</Typography>
              <TextFieldTheme
                inputProps={{ readOnly: true }}
                multiline
                rows={3}
                value={detail.reasonExpandTime || ""}
              />
            </Grid>
          )}
          {detail.result === 0 && (
            <Grid item xs={12}>
              <Typography marginBottom={1}>{t("ReasonForNotPassingProbation")}</Typography>
              <TextFieldTheme
                inputProps={{ readOnly: true }}
                multiline
                rows={3}
                value={detail.reasonTermination || t("ReasonForNotPassingProbation")}
              />
            </Grid>
          )}
        </Grid>
        <StyledFooter>
          <ButtonBlue onClick={handleClose} startIcon={<ArrowBackIcon />}>
            {t("Back")}
          </ButtonBlue>
          <ButtonBlue onClick={clickSubmit} startIcon={<CheckIcon />}>
            {t("EditData")}
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogProbation;
