import { httpClient } from "./httpClient";

const getBotResponse = (query) => {
  return httpClient.post("/webhook/getBotResponse", query);
};

const getLeaveQuota = (query) => {
  return httpClient.post("/webhook/getLeaveQuota", query);
};

const getLateAbsent = (rangeDate) => {
  return httpClient.post("/webhook/getLateAbsent", rangeDate);
};

const getOvertimeShift = (rangeDate) => {
  return httpClient.post("/webhook/getOvertimeShift", rangeDate);
};

const getDocumentPDFFile = (query) => {
  return httpClient.post("/webhook/getDocumentPDFFile", query, {
    responseType: "arraybuffer",
  });
};

const getEmpHistoryEnroll = (filter) => {
  return httpClient.get(`/webhook/getEmpHistoryEnroll`, { params: filter });
};

const getEmpSkillById = (filter) => {
  return httpClient.get(`/webhook/getEmpSkillById`, { params: filter });
};

const getCompetencyEvaluate = (filter) => {
  return httpClient.get(`/webhook/getCompetencyEvaluate`, { params: filter });
};

const getEmpIDP = (filter) => {
  return httpClient.get(`/webhook/getEmpIDP`, { params: filter });
};

const getEmpOnJobTraining = (filter) => {
  return httpClient.get(`/webhook/getEmpOnJobTraining`, { params: filter });
};

const getEmpListEnrollCourse = (filter) => {
  return httpClient.get(`/webhook/getEmpListEnrollCourse`, { params: filter });
};

const getMyCertificate = (filter) => {
  return httpClient.get(`/webhook/getMyCertificate`, { params: filter });
};

const getMyCertificateData = (id, type) => {
  return httpClient.get(`/webhook/getMyCertificateData/${id}?type=${type}`)
}

const getCourseStatus = (filter) => {
  return httpClient.get(`/webhook/getCourseStatus`, { params: filter });
};

const getSkillStatus = (filter) => {
  return httpClient.get(`/webhook/getSkillStatus`, { params: filter });
};

const getOnJobTrainingStatus = (filter) => {
  return httpClient.get(`/webhook/getOnJobTrainingStatus`, { params: filter });
};

export default {
  getBotResponse,
  getLeaveQuota,
  getLateAbsent,
  getOvertimeShift,
  getDocumentPDFFile,
  getEmpHistoryEnroll,
  getEmpSkillById,
  getCompetencyEvaluate,
  getEmpIDP,
  getEmpOnJobTraining,
  getEmpListEnrollCourse,
  getMyCertificate,
  getMyCertificateData,
  getCourseStatus,
  getSkillStatus,
  getOnJobTrainingStatus,
};
