import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import EmployeeInfo from "../../../shared/pages/okrs/components/EmployeeInfo";
import dayjs from "dayjs";

import {
  Grid,
  Slider,
  TextField,
  Toolbar,
  Typography,
  Button,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  FormHelperText,
  InputAdornment,
  Container,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { styled } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { getAllIdvPlan } from "../../../../../actions/IndividualPlans";
import { getAllEmployees } from "../../../../../actions/employee";
import { getUserProfile } from "../../../../../actions/user";
import { putEvaluationIdvPlan } from "../../../../../actions/IndividualPlans";

import DescriptionIcon from "@mui/icons-material/Description";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiSlider-root": {
      width: "80%",
    },
    "& .MuiSelect-root": {
      width: "150px",
    },
    "& .MuiTypography-h6": {},
    "& .MuiSlider-marked": {
      marginTop: 24,
    },
    "& .MuiSlider-thumb": {
      width: "15px",
      height: "15px",
    },
    "& .MuiSlider-rail": {
      height: "5px",
    },
    "& .MuiSlider-track": {
      height: "5px",
    },
    "& .MuiSlider-mark": {
      height: "5px",
    },
  },
  imageEmp: {
    width: 160,
    height: 160,
    borderRadius: "50%",
    marginLeft: 40,
    marginBottom: 24,
  },
  employeeInfo: {},
  headerCard: {
    marginTop: 16,
    background: "#fafbff",
    borderRadius: "24px 24px 0px 0px",
    padding: 32,
    borderBottom: "1px solid #eeeeee",
  },
  cardPlan: {
    borderRadius: "0px 0px  24px 24px",
    padding: 32,
    background: "#fff",
  },
  mainData: {
    fontSize: 19,
  },
  data: {
    fontSize: 20,
    fontWeight: "bold",
  },
}));

const ContainerStyled = styled(Container)({
  marginTop: "100px",
});

const marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 0.1,
    label: "0.1",
  },
  {
    value: 0.2,
    label: "0.2",
  },
  {
    value: 0.3,
    label: "0.3",
  },
  {
    value: 0.4,
    label: "0.4",
  },
  {
    value: 0.5,
    label: "0.5",
  },
  {
    value: 0.6,
    label: "0.6",
  },
  {
    value: 0.7,
    label: "0.7",
  },
  {
    value: 0.8,
    label: "0.8",
  },
  {
    value: 0.9,
    label: "0.9",
  },
  {
    value: 1.0,
    label: "1.0",
  },
];

const achieveOptions = [
  { id: "Achieved", title: "Achieved" },
  { id: "Not Achieved", title: "Not Achieved" },
];

function IdvEvaluation() {
  const {t,i18n} = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { result: empStore } = useSelector((state) => state.employees);
  const { result: idvPlanStore } = useSelector((state) => state.IdvPlans);
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const location = useLocation();
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [employeeId, setEmployeeId] = useState(0);
  const [values, setValues] = useState(location.state.planList);
  const [hasError, setHasError] = useState(false);

  function valuetext(value) {
    return `${value}`;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const onPutEvaluationIdvPlan = async (id, formData) => {
    await dispatch(putEvaluationIdvPlan(id, formData));
    history.push("/idvevaluationlist");
  };

  const onSubmit = () => {
    if (values.achieveResult == null) {
      setHasError(true);
    } else {
      console.log("Data Submit", values);

      const formData = {
        achieveResult: values.achieveResult,
        achieveDescription: values.achieveDescription,
        achieveResultKR1: values.achieveResultKR1,
        krScore1: values.krScore1,
        evidence1: values.evidence1,
        achieveResultKR2: values.achieveResultKR2,
        krScore2: values.krScore2,
        evidence2: values.evidence2,
        achieveResultKR3: values.achieveResultKR3,
        krScore3: values.krScore3,
        evidence3: values.evidence3,
        achievedByEmpId: userProfile.idEmployees,
        dateAchieved: dayjs(new Date()).format("YYYY-MM-DD"),
        evaluateStatus: "Waiting Approval",
        planIdvId: values.planIdvId,
      };

      onPutEvaluationIdvPlan(formData.planIdvId, formData);
    }
  };

  useEffect(() => {
    if (currentUser) {
      dispatch(getUserProfile(currentUser.username));
    }

    setEmployeeId(location.state.planList.createByEmpId);
  }, []);

  return (
    <ContainerStyled>
      <div className={classes.root}>
        <div className="card-section">
          <div className="header-section">
            <Grid container>
              <Grid item>
                <h3 className="header-topic">Individual Evaluation</h3>
              </Grid>
            </Grid>
          </div>
          {employeeId && <EmployeeInfo idEmployees={employeeId} />}
        </div>
        <div className={classes.headerCard}>
          <Grid
            container
            justifyContent="center"
            alignItems="flex-end"
            sx={{ marginBottom: 2 }}
          >
            <Grid item xs={10} sm={3} md={3} lg={3} xl={3}>
              <Typography className={classes.data}>
                {/* <FlagIcon style={{ marginRight: '5px' }} /> */}
                Objective
              </Typography>
            </Grid>
            <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
              <Typography className={classes.mainData}>
                {values.objectiveName}
              </Typography>
            </Grid>
          </Grid>
          <Grid
            container
            justifyContent="center"
            alignItems="flex-end"
            sx={{ marginBottom: 2 }}
          >
            <Grid item xs={10} sm={3} md={3} lg={3} xl={3}>
              <Typography className={classes.data}>Impact</Typography>
            </Grid>
            <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
              <Typography className={classes.mainData}>
                {values.impactName}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div className={classes.cardPlan}>
          <form onSubmit={handleSubmit(onSubmit)} novalidate autocomplete="off">
            <Grid container justifyContent="center">
              <Grid item xl={9} lg={9} md={10} sm={10} xs={10}>
                <Grid container sx={{ marginBottom: 2 }}>
                  <Grid
                    item
                    xl={4}
                    lg={4}
                    md={4}
                    sm={12}
                    xs={12}
                    style={{ marginBottom: 20 }}
                  >
                    <Typography className={classes.data}>Achieve</Typography>
                    <Typography style={{ fontSize: 15 }}>
                      description of the Achievement
                    </Typography>
                  </Grid>
                  <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                    <FormControl
                      sx={{ marginBottom: 2 }}
                      fullWidth
                      error={hasError}
                    >
                      <InputLabel>Select Achieve</InputLabel>
                      <Select
                        name="achieveResult"
                        label="Select Achieve"
                        value={values.achieveResult}
                        onChange={handleInputChange}
                      >
                        {achieveOptions.map((item) => (
                          <MenuItem key={item.id} value={item.id}>
                            {item.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <TextField
                      name="achieveDescription"
                      value={values.achieveDescription}
                      onChange={handleInputChange}
                      fullWidth
                      sx={{ marginBottom: 2 }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <DescriptionIcon />
                          </InputAdornment>
                        ),
                      }}
                      label="Description"
                      placeholder="Description"
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            {/* Kr1 */}
            {values.keyResult1 && (
              <>
                <hr className="hrTag" />
                <Grid
                  container
                  justifyContent="center"
                  alignItems="flex-end"
                  sx={{ marginBottom: 2 }}
                >
                  <Grid item xs={10} sm={3} md={3} lg={3} xl={3}>
                    <Typography className={classes.data}>
                      {/* <VpnKeyRoundedIcon fontSize='large' className='svgKey' /> */}
                      {t("KeyResult")} 1
                    </Typography>
                  </Grid>
                  <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
                    <Typography className={classes.mainData}>
                      {values.keyResult1}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="flex-end"
                  sx={{ marginBottom: 2 }}
                >
                  <Grid item xs={10} sm={3} md={3} lg={3} xl={3}>
                    <Typography className={classes.data}>
                      Description
                    </Typography>
                  </Grid>
                  <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
                    <Typography className={classes.mainData}>
                      {values.description1}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center">
                  <Grid item xl={9} lg={9} md={10} sm={10} xs={10}>
                    <Grid container sx={{ marginBottom: 2 }}>
                      <Grid
                        item
                        xl={4}
                        lg={4}
                        md={4}
                        sm={12}
                        xs={12}
                        style={{ marginBottom: 20 }}
                      >
                        <Typography className={classes.data}>
                          Achieve
                        </Typography>
                        <Typography style={{ fontSize: 15 }}>
                          description of the Achievement
                        </Typography>
                      </Grid>
                      <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                        <FormControl
                          sx={{ marginBottom: 2 }}
                          fullWidth
                          error={hasError}
                        >
                          <InputLabel>Select Achieve</InputLabel>
                          <Select
                            name="achieveResultKR1"
                            label="Select Achieve"
                            value={values.achieveResultKR1}
                            onChange={handleInputChange}
                          >
                            {achieveOptions.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <Slider
                          value={values.krScore1}
                          defaultValue={0}
                          getAriaValueText={valuetext}
                          aria-labelledby="discrete-slider-custom"
                          step={0.1}
                          min={0}
                          max={1}
                          marks={marks}
                          onChange={(e, newValue) =>
                            setValues({ ...values, krScore1: newValue })
                          }
                        />
                        <TextField
                          name="evidence1"
                          value={values.evidence1}
                          onChange={handleInputChange}
                          fullWidth
                          sx={{ marginBottom: 2, marginTop: 2 }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <DescriptionIcon />
                              </InputAdornment>
                            ),
                          }}
                          label="Evidence"
                          placeholder="Evidence"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}

            {/* Kr2 */}
            {values.keyResult2 && (
              <>
                <hr className="hrTag" />
                <Grid
                  container
                  justifyContent="center"
                  alignItems="flex-end"
                  sx={{ marginBottom: 2 }}
                >
                  <Grid item xs={10} sm={3} md={3} lg={3} xl={3}>
                    <Typography className={classes.data}>
                      {/* <VpnKeyRoundedIcon fontSize='large' className='svgKey' /> */}
                      {t("KeyResult")} 2
                    </Typography>
                  </Grid>
                  <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
                    <Typography className={classes.mainData}>
                      {values.keyResult2}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="flex-end"
                  sx={{ marginBottom: 2 }}
                >
                  <Grid item xs={10} sm={3} md={3} lg={3} xl={3}>
                    <Typography className={classes.data}>
                      Description
                    </Typography>
                  </Grid>
                  <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
                    <Typography className={classes.mainData}>
                      {values.description2}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container justifyContent="center">
                  <Grid item xl={9} lg={9} md={10} sm={10} xs={10}>
                    <Grid container>
                      <Grid
                        item
                        xl={4}
                        lg={4}
                        md={4}
                        sm={12}
                        xs={12}
                        style={{ marginBottom: 20 }}
                      >
                        <Typography className={classes.data}>
                          Achieve
                        </Typography>
                        <Typography style={{ fontSize: 15 }}>
                          description of the Achievement
                        </Typography>
                      </Grid>
                      <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                        <FormControl
                          sx={{ marginBottom: 2 }}
                          fullWidth
                          error={hasError}
                        >
                          <InputLabel>Select Achieve</InputLabel>
                          <Select
                            name="achieveResultKR2"
                            label="Select Achieve"
                            value={values.achieveResultKR2}
                            onChange={handleInputChange}
                          >
                            {achieveOptions.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <Slider
                          value={values.krScore2}
                          defaultValue={0}
                          getAriaValueText={valuetext}
                          aria-labelledby="discrete-slider-custom"
                          step={0.1}
                          min={0}
                          max={1}
                          marks={marks}
                          onChange={(e, newValue) =>
                            setValues({ ...values, krScore2: newValue })
                          }
                        />
                        <TextField
                          name="evidence2"
                          value={values.evidence2}
                          onChange={handleInputChange}
                          fullWidth
                          sx={{ marginBottom: 2, marginTop: 2 }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <DescriptionIcon />
                              </InputAdornment>
                            ),
                          }}
                          label="Evidence"
                          placeholder="Evidence"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}

            {/* Kr3 */}
            {values.keyResult3 && (
              <>
                <hr className="hrTag" />
                <Grid
                  container
                  justifyContent="center"
                  alignItems="flex-end"
                  sx={{ marginBottom: 2 }}
                >
                  <Grid item xs={10} sm={3} md={3} lg={3} xl={3}>
                    <Typography className={classes.data}>
                      {/* <VpnKeyRoundedIcon fontSize='large' className='svgKey' /> */}
                      {t("KeyResult")} 3
                    </Typography>
                  </Grid>
                  <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
                    <Typography className={classes.mainData}>
                      {values.keyResult3}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="center"
                  alignItems="flex-end"
                  sx={{ marginBottom: 2 }}
                >
                  <Grid item xs={10} sm={3} md={3} lg={3} xl={3}>
                    <Typography className={classes.data}>
                      Description
                    </Typography>
                  </Grid>
                  <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
                    <Typography className={classes.mainData}>
                      {values.description3}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="center"
                  sx={{ marginBottom: 2 }}
                >
                  <Grid item xl={9} lg={9} md={10} sm={10} xs={10}>
                    <Grid container>
                      <Grid
                        item
                        xl={4}
                        lg={4}
                        md={4}
                        sm={12}
                        xs={12}
                        style={{ marginBottom: 20 }}
                      >
                        <Typography className={classes.data}>
                          Achieve
                        </Typography>
                        <Typography style={{ fontSize: 15 }}>
                          description of the Achievement
                        </Typography>
                      </Grid>
                      <Grid item xl={8} lg={8} md={8} sm={12} xs={12}>
                        <FormControl
                          sx={{ marginBottom: 2 }}
                          fullWidth
                          error={hasError}
                        >
                          <InputLabel>Select Achieve</InputLabel>
                          <Select
                            name="achieveResultKR3"
                            label="Select Achieve"
                            value={values.achieveResultKR3}
                            onChange={handleInputChange}
                          >
                            {achieveOptions.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <Slider
                          value={values.krScore3}
                          defaultValue={0}
                          getAriaValueText={valuetext}
                          aria-labelledby="discrete-slider-custom"
                          step={0.1}
                          min={0}
                          max={1}
                          marks={marks}
                          onChange={(e, newValue) =>
                            setValues({ ...values, krScore3: newValue })
                          }
                        />
                        <TextField
                          name="evidence3"
                          value={values.evidence3}
                          onChange={handleInputChange}
                          fullWidth
                          sx={{ marginBottom: 2, marginTop: 2 }}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <DescriptionIcon />
                              </InputAdornment>
                            ),
                          }}
                          label="Evidence"
                          placeholder="Evidence"
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}

            <hr className="hrTag" />
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  className="saveButton"
                  type="submit"
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </form>
        </div>
      </div>
    </ContainerStyled>
  );
}

export default IdvEvaluation;
