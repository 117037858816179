import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  FormControl,
  Grid,
  ListSubheader,
  IconButton,
  MenuItem,
  Select,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import DrawerCustom from "../general/Drawer";
import dayjs from "dayjs";
import {
  addRequestTime,
  getRequestReason,
  putRequestTime
} from "../../../../actions/requestTime";
import { useDispatch, useSelector } from "react-redux";
import DatePickerCustom from "../date/datePicker";
import TextFieldTheme from "../general/TextFieldTheme";
import TimePicker from "../general/TimePicker";
import ButtonBlue from "../general/ButtonBlue";
import {
  calculatorOvertime,
  calculatorOvertimeByWorkingHours,
} from "./calculatorOvertime";
import { getAttendanceById } from "../../../../actions/attendance";
import { openNotificationAlert } from "../../../../actions/notificationAlert";
import { Delete } from '@mui/icons-material';
import { useForm } from 'react-hook-form';

//Translator TH-EN
import { useTranslation } from "react-i18next";
import calculateService from "../../../../services/calculate.service";

const StyledRoot = styled(Box)({
  width: 350,
  padding: 24,
  "@media only screen and (max-width: 375px)": {
    width: 320,
  },
  "& .wrap-status": {
    marginTop: 4,
    display: "flex",
    alignItems: "center",
    "& .MuiBox-root": {
      marginRight: 8,
    },
  },
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiList-root": {
    maxHeight: 300,
    overflow: "auto",
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 280,
    },
  },
};

const StyledFooter = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  // "& .cancel": {
  //   marginRight: 8,
  // },
});

const DrawerRequestTime = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const {
    open,
    onClose,
    values,
    payrollSetting,
    mode,
    isEdit,
    employee,
    searchDate,
    overtimeValues,
    setNotify,
    handleClickOpenConfirmDelete
  } = props;

  const { setValue, watch } = useForm();
  const [formInputData, setFormData] = useState(null);
  const [reason, setReason] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [resultCalOT, setResultCalOT] = useState(null);

  const { result: userProfile } = useSelector((state) => state.userProfile);

  const fetchRequestReason = async (idCompany) => {
    const result = await dispatch(getRequestReason({ idCompany: idCompany || userProfile.idCompany }));
    if (result) {
      setReason(result);
    } else {
    }
  };

  useEffect(() => {
    if (values) {
      let idCompany = (values.employee && values.employee.idCompany) ? values.employee.idCompany : null;
      fetchRequestReason(idCompany);
      setValue('startDate', new Date(values.date));
      setValue('endDate', new Date(values.date));
      if (isEdit) {
        setFormData({
          idRequestTime: overtimeValues.idRequestTime,
          idRequestType: overtimeValues.idRequestType,
          startDate: dayjs(overtimeValues.startText, "DD/MM/YYYY HH:mm"),
          startMinute: dayjs(
            overtimeValues.startText,
            "DD/MM/YYYY HH:mm"
          ).format("mm"),
          startHours: dayjs(
            overtimeValues.startText,
            "DD/MM/YYYY HH:mm"
          ).format("HH"),
          endDate: dayjs(overtimeValues.endText, "DD/MM/YYYY HH:mm"),
          endMinute: dayjs(overtimeValues.endText, "DD/MM/YYYY HH:mm").format(
            "mm"
          ),
          endHours: dayjs(overtimeValues.endText, "DD/MM/YYYY HH:mm").format(
            "HH"
          ),
          idManagerLV1: overtimeValues.idManagerLV1,
          otherReason: overtimeValues.otherReason,
          idRequestReason: overtimeValues.idRequestReason,
          isDoubleApproval: overtimeValues.isDoubleApproval,
          approvalLevel: overtimeValues.approvalLevel,
          managerLV1Name: overtimeValues.managerLV1Name,
          isManagerLV1Approve: overtimeValues.isManagerLV1Approve,
          managerLV1ApproveDate: overtimeValues.managerLV1ApproveDate
            ? dayjs(overtimeValues.managerLV1ApproveDate).format(
              "DD/MM/BBBB HH:mm"
            )
            : null,
          positionManagerLV1: overtimeValues.positionManagerLV1,
          managerLV2Name: overtimeValues.managerLV2Name,
          isManagerLV2Approve: overtimeValues.isManagerLV2Approve,
          managerLV2ApproveDate: overtimeValues.managerLV2ApproveDate
            ? dayjs(overtimeValues.managerLV2ApproveDate).format(
              "DD/MM/BBBB HH:mm"
            )
            : null,
          positionManagerLV2: overtimeValues.positionManagerLV2,
          createDateText: overtimeValues.createDateText,
        });
      } else {
        setFormData({
          idRequestType: "",
          startDate: dayjs(values.date, "YYYY-MM-DD"),
          startMinute: "",
          startHours: "",
          endDate: dayjs(values.date, "YYYY-MM-DD"),
          endMinute: "",
          endHours: "",
          idRequestReason: "",
          otherReason: "",
        });
      }
    } else {
      fetchRequestReason();
    }
    setIsSubmitting(false);
  }, [open, values]);

  // useEffect(async () => {
  //   if(watch("idRequestType") === 2){
  //     console.log("watch : ",watch());
  //     if(
  //       watch("startDate") && 
  //       watch("startHours") && 
  //       watch("startMinute") &&
  //       watch("endDate") &&
  //       watch("endHours") &&
  //       watch("endMinute")
  //     ){
  //       let curReason = reasonChosen();
  //       let result = await calculateService.calculateOT(
  //         values.date,
  //         dayjs(watch("startDate"))
  //           .set("hour", watch("startHours"))
  //           .set("minute", watch("startMinute"))
  //           .set("second", 0).format("YYYY-MM-DD HH:mm"),
  //         dayjs(watch("endDate"))
  //           .set("hour", watch("endHours"))
  //           .set("minute", watch("endMinute"))
  //           .set("second", 0).format("YYYY-MM-DD HH:mm"),
  //         {
  //           ...values,
  //           isReasonBreak: curReason 
  //           ? Boolean(curReason.isBreak) : false
  //         }
  //       );
  //       if(result.status == 200){
  //         console.log("result API : ",result.data);
  //         setResultCalOT(result.data);
  //       }
  //     }
  //   }
  // },[
  //   watch("idRequestType"), watch("startDate"), watch("startHours"), watch("startMinute"),
  //   watch("endDate"), watch("endHours"), watch("endMinute"), watch("idRequestReason")
  // ]);

  const handleSaveRequestTime = async () => {
    setIsSubmitting(true);
    if (
      formInputData.idRequestType === "" ||
      formInputData.startDate === null ||
      formInputData.startMinute === "" ||
      formInputData.startHours === "" ||
      formInputData.endDate === null ||
      formInputData.endMinute === "" ||
      formInputData.endHours === "" ||
      formInputData.idRequestReason === ""
    ) {
      dispatch(
        openNotificationAlert({
          type: "error",
          message: `${t("PleaseFillInRequiredInformations")}`,
        })
      );
      setIsSubmitting(false);
      return;
    }

    const startDate = dayjs(formInputData.startDate)
      .set("hour", formInputData.startHours)
      .set("minute", formInputData.startMinute)
      .set("second", 0);
    const endDate = dayjs(formInputData.endDate)
      .set("hour", formInputData.endHours)
      .set("minute", formInputData.endMinute)
      .set("second", 0);

    if (startDate.isSameOrAfter(endDate)) {
      dispatch(
        openNotificationAlert({
          type: "error",
          message: `${t("InputDateTimeIncorrect")}`,
        })
      );
      setIsSubmitting(false);
      return;
    }

    const formData = {
      workDate: values.date,
      start: startDate.format("YYYY-MM-DD HH:mm:ss"),
      end: endDate.format("YYYY-MM-DD HH:mm:ss"),
      idRequestReason: formInputData.idRequestReason,
      idRequestType: formInputData.idRequestType,
      otherReason: formInputData.otherReason,
      idEmployees: employee.idEmployees,
      isManagerLV1Approve: null,
      isManagerLV2Approve: null,
      isDoubleApproval: 0,
      approvalLevel: 1,
      xOT: 0,
      xOTHoliday: 0,
      xWorkingDaily: 0,
      xWorkingDailyHoliday: 0,
      xWorkingMonthlyHoliday: 0,
      isActive: 1,
      managerLV1ApproveBy: employee.idManagerLV1,
      managerLV2ApproveBy: employee.idManagerLV2,
      idManagerGroupLV1: employee.idManagerGroupLV1,
      idManagerGroupLV2: employee.idManagerGroupLV2,
    };

    const workStartDate = dayjs(`${values.date} ${values.pattern.timeIn}`);
    const workEndDate = workStartDate.add(
      values.pattern.workingHours,
      "minute",
      true
    );

    if (isEdit) {
      formData.isManagerLV1Approve = overtimeValues.isManagerLV1Approve;
      formData.isManagerLV2Approve = overtimeValues.isManagerLV2Approve;
      formData.isDoubleApproval = overtimeValues.isDoubleApproval;
      formData.approvalLevel = overtimeValues.approvalLevel;
    }

    if (!isEdit && ["admin", "manager"].includes(mode)) {
      formData.fillInCreate = userProfile.idEmployees;
    }

    if (formData.idRequestType === 2 || formData.idRequestType === 3) {
      const overtime = calculatorChoosing(
        dayjs(formData.start),
        dayjs(formData.end),
        (payrollSetting && payrollSetting.dayCutOffTime) || null
      );

      if (formData.idRequestType === 2) {
        formData.xOT = overtime.xOT;
        formData.xOTHoliday = overtime.xOTHoliday;
        formData.xWorkingDaily = overtime.xWorkingDaily;
        formData.xWorkingDailyHoliday = overtime.xWorkingDailyHoliday;
        formData.xWorkingMonthlyHoliday = overtime.xWorkingMonthlyHoliday;

        if (formData.xOTHoliday > 0) {
          formData.isDoubleApproval = 1;
          formData.approvalLevel = 2;
        } else {
          formData.isDoubleApproval = 0;
          formData.approvalLevel = 1;
        }
      }

      formData.amountHours =
        overtime.xOT +
        overtime.xOTHoliday +
        overtime.xWorkingDaily +
        overtime.xWorkingDailyHoliday +
        overtime.xWorkingMonthlyHoliday;
    } else {
      formData.amountHours = endDate.diff(startDate, "minute", true);
    }

    if (mode === "admin" && !userProfile.isFillIn) {
      if (isEdit) {
        if (overtimeValues.managerLV1ApproveBy) {
          formData.isManagerLV1Approve = true
          formData.fillInApproveLV1 = userProfile.idEmployees
          // formData.managerLV1ApproveDate = dayjs().format("YYYY-MM-DD HH:mm")
        }
        if (overtimeValues.managerLV2ApproveBy || formData.isDoubleApproval === 1) {
          formData.isManagerLV2Approve = true
          formData.fillInApproveLV2 = userProfile.idEmployees
          // formData.managerLV2ApproveDate = dayjs().format("YYYY-MM-DD HH:mm")
        }
      } else {
        formData.isManagerLV1Approve = true
        formData.fillInApproveLV1 = userProfile.idEmployees
        // formData.managerLV1ApproveDate = dayjs().format("YYYY-MM-DD HH:mm")
      }
    }

    if (
      formData.idRequestType === 2 &&
      [10, 11, 12, 13, 15].includes(Number(formData.idRequestReason))
    ) {
      formData.idShiftGroup = values.pattern.idShiftGroup;

      if (Number(formData.idRequestReason) === 15) {
        formData.workEndDate = workEndDate;
      }
    }

    if (!isEdit) {
      const result = await dispatch(addRequestTime(formData, formData.i));

      if (result) {
        if (result.status === 200) {
          if (result.data.errorCode === "ERROR_DUPLICATED") {
            dispatch(
              openNotificationAlert({
                type: "info",
                message: `${t("DuplicateData")}`,
              })
            );
            setIsSubmitting(false);
          } else {
            dispatch(
              openNotificationAlert({
                type: "success",
                message: `${t("DataSaveSuccessful")}`,
              })
            );
            await dispatch(getAttendanceById({
              start: searchDate.start.add(-7, 'day').format("YYYY-MM-DD"),
              end: searchDate.end.format("YYYY-MM-DD")
            }, employee.idEmployees));
            onClose();
            setIsSubmitting(false);
          }
        } else {
          dispatch(
            openNotificationAlert({
              type: "error",
              message: `${t("AnErrorOccurred")}`,
            })
          );
          await dispatch(getAttendanceById({
            start: searchDate.start.add(-7, 'day').format("YYYY-MM-DD"),
            end: searchDate.end.format("YYYY-MM-DD")
          }, employee.idEmployees));
          onClose();
          setIsSubmitting(false);
        }
      }
    } else {
      const result = await dispatch(
        putRequestTime(formData, formInputData.idRequestTime)
      );

      if (result) {
        if (result.status === 200) {
          if (result.data.errorCode === "ERROR_DUPLICATED") {
            dispatch(
              openNotificationAlert({
                type: "info",
                message: `${t("DuplicateData")}`,
              })
            );
            setIsSubmitting(false);
          } else {
            dispatch(
              openNotificationAlert({
                type: "success",
                message: `${t("DataSaveSuccessful")}`,
              })
            );
            await dispatch(getAttendanceById({
              start: searchDate.start.add(-7, 'day').format("YYYY-MM-DD"),
              end: searchDate.end.format("YYYY-MM-DD")
            }, employee.idEmployees));
            onClose();
            setIsSubmitting(false);
          }
        } else {
          dispatch(
            openNotificationAlert({
              type: "error",
              message: `${t("AnErrorOccurred")}`,
            })
          );
          await dispatch(getAttendanceById({
            start: searchDate.start.add(-7, 'day').format("YYYY-MM-DD"),
            end: searchDate.end.format("YYYY-MM-DD")
          }, employee.idEmployees));
          onClose();
          setIsSubmitting(false);
        }
      }
    }
    setIsSubmitting(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "idRequestType" && (value === 2 || value === 3)) {
      if (values.pattern.isTimeFrame === 1) {
        if (values.pattern.isWorkingDay === 1) {
          let leaveFind =
            values.leave &&
            values.leave.length > 0 &&
            values.leave.find(
              (v) =>
                v.isApprove === 1 && v.isWithdraw !== 1 && v.isFullDay === 1
            );
          if (
            values.pattern.isCompensation ||
            values.holiday ||
            leaveFind
          ) {
            const startDate = dayjs(`${values.date} ${values.pattern.timeIn}`);
            const endDate = startDate.add(
              values.pattern.workingHours,
              "minute",
              true
            );

            let startHours =
              (values.pattern &&
                values.pattern.timeIn &&
                values.pattern.timeIn.split(":")[0]) ||
              "";
            let startMinute =
              (values.pattern &&
                values.pattern.timeIn &&
                values.pattern.timeIn.split(":")[1]) ||
              "";
            let endHours =
              (values.pattern &&
                values.pattern.timeOut &&
                Number(values.pattern.timeOut.split(":")[0])) ||
              "";
            let endMinute =
              (values.pattern &&
                values.pattern.timeOut &&
                values.pattern.timeOut.split(":")[1]) ||
              "";

            setValue(name, value);
            setValue("startHours", startHours);
            setValue("startMinute", startMinute);
            setValue("endHours", endHours);
            setValue("endMinute", endMinute);
            setValue("startDate", startDate);
            setValue("endDate", endDate);
            setFormData({
              ...formInputData,
              [name]: value,
              startHours: startHours,
              startMinute: startMinute,
              endHours: endHours,
              endMinute: endMinute,
              startDate: startDate,
              endDate: endDate,
            });

            return;
          }

          let startHours =
            (values.pattern &&
              values.pattern.timeOut &&
              values.pattern.timeOut.split(":")[0]) ||
            "";
          let startMinute =
            (values.pattern &&
              values.pattern.timeOut &&
              values.pattern.timeOut.split(":")[1]) ||
            "";
          let endHours =
            (values.attendance &&
              values.attendance.checkOut &&
              values.attendance.checkOut.length > 0 &&
              values.attendance.checkOut[0] &&
              values.attendance.checkOut[0].attendanceTextTime.split(":")[0]) ||
            "";
          let endMinute =
            (values.attendance &&
              values.attendance.checkOut &&
              values.attendance.checkOut.length > 0 &&
              values.attendance.checkOut[0] &&
              values.attendance.checkOut[0].attendanceTextTime.split(":")[1]) ||
            "";
          let startDate = dayjs(values.date, "YYYY-MM-DD");
          let endDate = dayjs(values.date, "YYYY-MM-DD");

          if (startHours < Number(values.pattern.timeIn.split(":")[0])) {
            startDate = startDate.add(1, "day");
            endDate = endDate.add(1, "day");
          }
          setValue(name, value);
          setValue("startHours", startHours);
          setValue("startMinute", startMinute);
          setValue("endHours", endHours);
          setValue("endMinute", endMinute);
          setValue("startDate", startDate);
          setValue("endDate", endDate);
          setFormData({
            ...formInputData,
            [name]: value,
            startHours: startHours,
            startMinute: startMinute,
            endHours: endHours,
            endMinute: endMinute,
            startDate: startDate,
            endDate: endDate,
          });
        } else {
          const startDate = dayjs(`${values.date} ${values.pattern.timeIn}`);
          const endDate = startDate.add(
            values.pattern.workingHours,
            "minute",
            true
          );

          let startHours =
            (values.pattern &&
              values.pattern.timeIn &&
              values.pattern.timeIn.split(":")[0]) ||
            "";
          let startMinute =
            (values.pattern &&
              values.pattern.timeIn &&
              values.pattern.timeIn.split(":")[1]) ||
            "";
          let endHours =
            (values.pattern &&
              values.pattern.timeOut &&
              values.pattern.timeOut.split(":")[0]) ||
            "";
          let endMinute =
            (values.pattern &&
              values.pattern.timeOut &&
              values.pattern.timeOut.split(":")[1]) ||
            "";
          setValue(name, value);
          setValue("startHours", startHours);
          setValue("startMinute", startMinute);
          setValue("endHours", endHours);
          setValue("endMinute", endMinute);
          setValue("startDate", startDate);
          setValue("endDate", endDate);
          setFormData({
            ...formInputData,
            [name]: value,
            startHours: startHours,
            startMinute: startMinute,
            endHours: endHours,
            endMinute: endMinute,
            startDate: startDate,
            endDate: endDate,
          });
        }
      } else {
        setValue(name, value);
        setFormData({
          ...formInputData,
          [name]: value,
        });
      }

      return;
    } else if (name === "idRequestType" && value === 1) {
      setFormData({
        ...formInputData,
        [name]: value,
        startHours: "",
        startMinute: "",
        endHours: "",
        endMinute: "",
        startDate: dayjs(values.date, "YYYY-MM-DD"),
        endDate: dayjs(values.date, "YYYY-MM-DD"),
      });
      return;
    }
    setValue(name, value);
    setFormData({
      ...formInputData,
      [name]: value,
    });
  };

  const handleChangeTime = (name, unit, periodTime, value) => {
    setValue(name, value);
    setFormData({
      ...formInputData,
      [name]: value,
    });
  };

  const renderTitle = () => {
    if (isEdit) {
      if (overtimeValues.idRequestType === 2) {
        return `${t("OvertimeRequest")}`;
      } else {
        return `${t("WorkingTimeRequestList")}`;
      }
    } else {
      return `${t("AddItem")}`;
    }
  };

  const reasonChosen = () => {
    let result = null;

    if (reason && reason.length > 0) {
      result = reason.find(
        (r) => r.idRequestReason === formInputData.idRequestReason
      );
    }

    return result;
  };

  const calculatorChoosing = (start, end, dayCutOffTime) => {
    let curReason = reasonChosen();
    if (values.pattern.isTimeFrame === 0 && values.pattern.workingHours) {
      return calculatorOvertimeByWorkingHours(start, end, {
        curPattern: values.pattern,
        idPaymentType: values.employee.idPaymentType,
        attendance: values.attendance,
        requestTime: values.requestTime,
        date: values.date,
        ot: values.ot,
        leave: values.leave,
        employee: values.employee,
        isFinger: values.employee.isFinger,
        idRequestType: formInputData.idRequestType,
      });
    } else {
      return calculatorOvertime(start, end, {
        prePattern: values.previousPattern,
        curPattern: values.pattern,
        nextPattern: values.nextPattern,
        idPaymentType: values.employee.idPaymentType,
        attendances: values.attendance,
        requestTime: values.requestTime,
        isFinger: values.employee.isFinger,
        leave: values.leave,
        preLeave: values.previousLeave,
        nextLeave: values.nextLeave,
        reason: curReason,
        dayCutOffTime: dayCutOffTime,
        employee: values.employee,
        idRequestType: formInputData.idRequestType,
      });
    }
  };

  const disabledButtonSubmit = () => {
    let overtimeObj = calculatorChoosing(
      dayjs(formInputData.startDate)
        .set("hour", formInputData.startHours)
        .set("minute", formInputData.startMinute)
        .set("second", 0),
      dayjs(formInputData.endDate)
        .set("hour", formInputData.endHours)
        .set("minute", formInputData.endMinute)
        .set("second", 0),
      (payrollSetting && payrollSetting.dayCutOffTime) || null
    );

    return overtimeObj.overlapWorking > 0 || !overtimeObj.isInTimeFrame || overtimeObj.isErrorBreakTime || overtimeObj.isOutOfRange;
  };

  return (
    <DrawerCustom
      title={renderTitle()}
      header={(
        <div
          style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
        >
          <Typography>{renderTitle()}</Typography>
          {(mode === "admin" && isEdit) &&
            <IconButton
              size="small"
              onClick={() => { handleClickOpenConfirmDelete(overtimeValues) }}
            ><Delete style={{ color: "#FF0000", fontSize: "20px" }} /></IconButton>
          }
        </div>
      )}
      anchor="right"
      open={open}
      onClose={onClose}
    >
      <StyledRoot>
        {formInputData && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>
                {t("WorkingDay")}: {dayjs(values.date).format("D/MM/BBBB ")}
              </Typography>
              <Typography>
                {t("WorkingTime")}:{" "}
                {values.pattern.idWorkingType === 1
                  ? `${values.pattern.shiftGroupName}  (${values.pattern.shiftName})`
                  : `${values.pattern.shiftGroupName}`}
                <Typography
                  component="span"
                  color="text.third"
                  style={{
                    fontStyle: "oblique",
                    fontWeight: "normal",
                  }}
                >
                  {values.pattern.isWorkingDay === 1 &&
                    values.pattern.isTimeFrame === 1
                    ? ` (${values.pattern.timeIn.slice(
                      0,
                      -3
                    )} - ${values.pattern.timeOut.slice(0, -3)})`
                    : ` (${values.pattern.shiftTypeName})`}
                </Typography>
              </Typography>
              {values.holiday && (
                <Typography
                  fontWeight={600}
                  fontSize={12}
                  textAlign="right"
                  style={{ color: "#7451ff" }}
                >
                  {t("AnnualHoliday")}
                </Typography>
              )}
            </Grid>

            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("RequestType")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <StyledFormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="idRequestType"
                  value={formInputData.idRequestType}
                  onChange={handleChange}
                  inputProps={{ readOnly: isEdit }}
                >
                  <MenuItem value={1}>{t("WorkingTimeRequest")}</MenuItem>
                  <MenuItem value={2}>{t("OvertimeRequest")}</MenuItem>
                  <MenuItem value={3}>{t("CompensateRequest")}</MenuItem>
                </Select>
              </StyledFormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("StartDate")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <DatePickerCustom
                minDate={
                  values.pattern.isTimeFrame === 0
                    ? new Date(dayjs(values.date))
                    : new Date(
                      dayjs(values.date)
                        .subtract(1, "day")
                        .format("YYYY-MM-DD")
                    )
                }
                maxDate={
                  values.pattern.isTimeFrame === 0
                    ? new Date(dayjs(values.date))
                    : new Date(
                      dayjs(values.date).add(1, "day").format("YYYY-MM-DD")
                    )
                }
                value={formInputData.startDate}
                onChange={(newDate) => {
                  setValue("startDate", newDate);
                  setFormData({ ...formInputData, ["startDate"]: newDate });
                }}
                renderInput={(params) => (
                  <TextFieldTheme style={{ width: "100%" }} {...params} />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("StartTime")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <div style={{ display: "flex" }}>
                <div style={{ width: "100%", marginRight: 4 }}>
                  <TimePicker
                    handleChangeTime={handleChangeTime}
                    name="startHours"
                    unit="hour"
                    value={formInputData.startHours}
                  />
                </div>
                <div style={{ width: "100%", marginLeft: 4 }}>
                  <TimePicker
                    handleChangeTime={handleChangeTime}
                    name="startMinute"
                    unit="minute"
                    step="1"
                    value={formInputData.startMinute}
                  />
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("EndDate")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <DatePickerCustom
                maxDate={
                  values.pattern.isTimeFrame === 0
                    ? new Date(dayjs(values.date))
                    : new Date(
                      dayjs(values.date).add(1, "day").format("YYYY-MM-DD")
                    )
                }
                minDate={
                  values.pattern.isTimeFrame === 0
                    ? new Date(dayjs(values.date))
                    : new Date(
                      dayjs(values.date)
                        .subtract(1, "day")
                        .format("YYYY-MM-DD")
                    )
                }
                value={formInputData.endDate}
                onChange={(newDate) => {
                  setValue("endDate", newDate);
                  setFormData({ ...formInputData, ["endDate"]: newDate });
                }}
                renderInput={(params) => (
                  <TextFieldTheme style={{ width: "100%" }} {...params} />
                )}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("EndTime")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <div style={{ display: "flex" }}>
                <div style={{ width: "100%", marginRight: 4 }}>
                  <TimePicker
                    handleChangeTime={handleChangeTime}
                    name="endHours"
                    unit="hour"
                    value={formInputData.endHours}
                  />
                </div>
                <div style={{ width: "100%", marginLeft: 4 }}>
                  <TimePicker
                    handleChangeTime={handleChangeTime}
                    name="endMinute"
                    unit="minute"
                    step="1"
                    value={formInputData.endMinute}
                  />
                </div>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("Reason")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <StyledFormControl fullWidth>
                {reason && (
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="idRequestReason"
                    value={formInputData.idRequestReason}
                    onChange={handleChange}
                    MenuProps={MenuProps}
                    disabled={!formInputData || !formInputData.idRequestType}
                  >
                    {(formInputData.idRequestType === 2 ||
                      userProfile.idCompany === 2) && (
                        <ListSubheader>{t("AppMenu.NoShiftValue")}</ListSubheader>
                      )}
                    {reason
                      .filter((x) => {
                        if (userProfile.idCompany === 2) {
                          return !x.isPayShift;
                        } else {
                          return (
                            !x.isPayShift &&
                            (formInputData.idRequestType === x.idRequestType ||
                              (formInputData.idRequestType === 3 &&
                                x.idRequestType === 2))
                          );
                        }
                      })
                      .sort((a, b) => a.index - b.index)
                      .map((item, index) => (
                        <MenuItem key={index} value={item.idRequestReason}>
                          {i18n.resolvedLanguage === "th"
                            ? item.name
                            : item.name_EN}
                        </MenuItem>
                      ))}
                      {(formInputData.idRequestType === 2 ||
                        userProfile.idCompany === 2) && employee.shift && employee.shift.idActualWorkingType === 1 && (
                          <ListSubheader>{t("AppMenu.ShiftValueExists")}</ListSubheader>
                        )
                      }
                      {reason
                        .filter((x) => {
                          if (employee.shift && employee.shift.idActualWorkingType === 1) {
                            if (userProfile.idCompany === 2) {
                              return x.isPayShift;
                            } else {
                              return (
                                x.isPayShift &&
                                (formInputData.idRequestType === x.idRequestType ||
                                  (formInputData.idRequestType === 3 &&
                                    x.idRequestType === 2))
                              );
                            }
                          } else {
                            return false
                          }
                        })
                        .sort((a, b) => a.index - b.index)
                        .map((item, index) => (
                          <MenuItem key={index} value={item.idRequestReason}>
                            {i18n.resolvedLanguage === "th"
                              ? item.name
                              : item.name_EN}
                          </MenuItem>
                        ))}
                  </Select>
                )}
              </StyledFormControl>
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <StyledContentLabel>{t("OtherReason")}</StyledContentLabel>
            </Grid>
            <Grid item xs={12} sm={8}>
              <StyledFormControl fullWidth>
                <TextField
                  name="otherReason"
                  onChange={handleChange}
                  id="outlined-multiline-static"
                  multiline
                  valuess={3}
                  value={formInputData.otherReason}
                />
              </StyledFormControl>
            </Grid>

            {formInputData.idRequestType === 2 && (
              <Fragment>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <StyledContentLabel>{t("TotalHours")}</StyledContentLabel>
                </Grid>
                <Grid item xs={12} sm={8}>
                  {RenderOT(
                    payrollSetting,
                    calculatorChoosing(
                      dayjs(formInputData.startDate)
                        .set("hour", formInputData.startHours)
                        .set("minute", formInputData.startMinute)
                        .set("second", 0),
                      dayjs(formInputData.endDate)
                        .set("hour", formInputData.endHours)
                        .set("minute", formInputData.endMinute)
                        .set("second", 0),
                      (payrollSetting && payrollSetting.dayCutOffTime) || null
                    ),
                    t
                  )}
                </Grid>
              </Fragment>
            )}

            {formInputData.idRequestType === 3 && (
              <Fragment>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <StyledContentLabel>{t("TotalHours")}</StyledContentLabel>
                </Grid>
                <Grid item xs={12} sm={8}>
                  {RenderCompensateHour(
                    calculatorChoosing(
                      dayjs(formInputData.startDate)
                        .set("hour", formInputData.startHours)
                        .set("minute", formInputData.startMinute)
                        .set("second", 0),
                      dayjs(formInputData.endDate)
                        .set("hour", formInputData.endHours)
                        .set("minute", formInputData.endMinute)
                        .set("second", 0),
                      (payrollSetting && payrollSetting.dayCutOffTime) || null
                    ),
                    t
                  )}
                </Grid>
              </Fragment>
            )}

            {(formInputData.idRequestType === 2 ||
              formInputData.idRequestType === 3) &&
              (values.pattern.isTimeFrame === 0
                ? values.isEarlyOut
                : false) && (
                <Grid
                  item
                  xs={12}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <StyledContentLabel style={{ color: "#FF0000" }}>
                    *{t("IncompleteWorkingTime")}
                  </StyledContentLabel>
                </Grid>
              )}

            <Grid item xs={12}>
              <StyledFooter>
                <ButtonBlue
                  className="cancel"
                  color="secondary"
                  variant="text"
                  onClick={onClose}
                >
                  {t("Cancel")}
                </ButtonBlue>
                <ButtonBlue
                  variant="contained"
                  onClick={handleSaveRequestTime}
                  disabled={
                    isSubmitting ||
                    ((formInputData.idRequestType === 2 ||
                      formInputData.idRequestType === 3) &&
                      disabledButtonSubmit()) ||
                    ((formInputData.idRequestType === 2 ||
                      formInputData.idRequestType === 3) &&
                      (values.pattern.isTimeFrame === 0
                        ? values.isEarlyOut
                        : false))
                  }
                >
                  {t("SaveData")}
                </ButtonBlue>
              </StyledFooter>
            </Grid>
          </Grid>
        )}
      </StyledRoot>
    </DrawerCustom>
  );
};

const RenderOT = (payrollSetting, overtimeObj, t) => {
  const {
    xOT,
    xOTHoliday,
    xWorkingDaily,
    xWorkingDailyHoliday,
    xWorkingMonthlyHoliday,
    overlapWorking,
    isInTimeFrame,
    isBeforeWorkTime,
    isErrorBreakTime,
    isOutOfRange
  } = overtimeObj;
  let otList = [];
  // console.log("overtimeObj : ",overtimeObj);
  if (xWorkingDailyHoliday > 0) {
    otList.push({
      x: payrollSetting.xWorkingDailyHoliday,
      hours: (xWorkingDailyHoliday / 60).toFixed(2),
    });
  }
  if (xWorkingMonthlyHoliday > 0) {
    otList.push({
      x: payrollSetting.xWorkingMonthlyHoliday,
      hours: (xWorkingMonthlyHoliday / 60).toFixed(2),
    });
  }
  if (xWorkingDaily > 0) {
    otList.push({
      x: 1,
      hours: (xWorkingDaily / 60).toFixed(2),
    });
  }
  if (xOT > 0) {
    otList.push({
      x: payrollSetting.xOT,
      hours: (xOT / 60).toFixed(2),
    });
  }
  if (xOTHoliday > 0) {
    otList.push({
      x: payrollSetting.xOTHoliday,
      hours: (xOTHoliday / 60).toFixed(2),
    });
  }

  return (
    <Fragment>
      {overlapWorking > 0 ? (
        <Typography style={{ fontSize: 14, fontWeight: 500, color: "#f15e5e" }}>
          {t("CantRequestOTNormalHours")}
        </Typography>
      ) : (
        <Fragment>
          {isOutOfRange ? (
            <Typography
              style={{ fontSize: 14, fontWeight: 500, color: "#f15e5e" }}
            >
              ไม่สามารถขอค่าล่วงเวลาที่เลือกในวันนี้ได้
            </Typography>
          ) : isBeforeWorkTime ? (
            <Typography
              style={{ fontSize: 14, fontWeight: 500, color: "#f15e5e" }}
            >
              ไม่สามารถขอค่าล่วงเวลาก่อนเวลาทำงานได้
            </Typography>
          ) : !isInTimeFrame ? (
            <Typography
              style={{ fontSize: 14, fontWeight: 500, color: "#f15e5e" }}
            >
              {t("CantRequestToWorkHaveRecorded")}
            </Typography>
          ) : (
            isErrorBreakTime && (
              <Typography
                style={{ fontSize: 14, fontWeight: 500, color: "#f15e5e" }}
              >
                เวลาทำงานช่วงพัก ไม่ถูกต้อง
              </Typography>
            )
          )}
          {isInTimeFrame &&
            !isOutOfRange &&
            !isBeforeWorkTime &&
            !isErrorBreakTime &&
            otList.map((item, index) => (
              <Box
                key={index}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  fontWeight={500}
                  variant="subtitle1"
                  color="text.third"
                >
                  OT x {item.x}
                </Typography>
                <Typography variant="h6">
                  {item.hours} {t("Unit.ShortHours")}
                </Typography>
              </Box>
            ))}
        </Fragment>
      )}
    </Fragment>
  );
};

const RenderCompensateHour = (overtimeObj, t) => {
  const {
    xOT,
    xOTHoliday,
    xWorkingDaily,
    xWorkingDailyHoliday,
    xWorkingMonthlyHoliday,
    overlapWorking,
    isInTimeFrame,
  } = overtimeObj;

  console.log("overtimeObj: ", overtimeObj);

  let otList = 0;

  if (xWorkingDailyHoliday > 0) {
    otList += xWorkingDailyHoliday / 60;
  }
  if (xWorkingMonthlyHoliday > 0) {
    otList += xWorkingMonthlyHoliday / 60;
  }
  if (xWorkingDaily > 0) {
    otList += xWorkingDaily / 60;
  }
  if (xOT > 0) {
    otList += xOT / 60;
  }
  if (xOTHoliday > 0) {
    otList += xOTHoliday / 60;
  }

  return (
    <Fragment>
      {overlapWorking > 0 ? (
        <Typography style={{ fontSize: 14, fontWeight: 500, color: "#f15e5e" }}>
          {t("CantRequestCompensateNormalHours")}
        </Typography>
      ) : (
        <Fragment>
          {!isInTimeFrame && (
            <Typography
              style={{ fontSize: 14, fontWeight: 500, color: "#f15e5e" }}
            >
              {t("CantRequestCompensateHaveRecorded")}
            </Typography>
          )}
          {isInTimeFrame && (
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                fontWeight={500}
                variant="subtitle1"
                color="text.third"
              >
                Compensate
              </Typography>
              <Typography variant="h6">
                {Math.round(otList * 100) / 100} {t("Unit.ShortHours")}
              </Typography>
            </Box>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default DrawerRequestTime;
