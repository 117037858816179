import React from "react";
import {
  Avatar,
  Box,
  Card,
  Fab,
  Grid,
  Icon,
  styled,
  Typography,
} from "@mui/material";
import CardData from "../../shared/dashboard/CardData";
import { useSelector } from "react-redux";
import Utils from "../../../../utils";
import CardDashboard from "../../shared/dashboard/CardDashboard";
import ChartPolarAreaCustom from "../../shared/dashboard/ChartPolarArea";
import ChartAreaCustom from "../../shared/dashboard/ChartArea";
import dayjs from "dayjs";
import TableCustom from "../../shared/tableCustom";
import EmployeeInOut from "./employeeInOut";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getUserFullName } from "../../../../utils/userData";

const StyledRoot = styled(Box)({
  "& .top-employee": {
    padding: "8px",
    // paddingTop: "16px",
    display: "flex",
    alignItems: "center",
    border: "1px solid #ececec",
    borderRadius: "4px",
  },
});

const OverviewPanel = (props) => {
  const { month, year } = props;
  const { t, i18n } = useTranslation();

  const { result: dashboardOverview } = useSelector(
    (state) => state.dashboardOverview
  );

  const leaveColumns = [
    {
      name: `${t("FullName")}`,
      minWidth: "230px",
      width: "230px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Avatar
            sx={{
              marginRight: "8px",
              width: 40,
              height: 40,
              "& img": { objectFit: "contain" },
            }}
            src={row.imageURL? row.imageURL: null}
          />
          <Box flexGrow={1}>
            <Typography>{getUserFullName(row)}</Typography>
          </Box>
        </Box>
      ),
    },
    {
      name: `${t("Type")}`,
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => <Typography fontSize={14}>
        {i18n.resolvedLanguage === "th"
          ? row.leaveGroupName
          : row.leaveGroupName_EN ? row.leaveGroupName_EN : row.leaveGroupName}
      </Typography>,
    },
    {
      name: `${t("Start")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>
            {dayjs(row.start).format(
              i18n.resolvedLanguage === "th" ? "D MMM BBBB" : "D MMM YYYY"
            )}
          </Typography>
          {row.isFullDay === 1 ? (
            <Typography>{`${t("Full_day")}`}</Typography>
          ) : (
            <Typography>
              {dayjs(row.start).format("HH:mm")}
            </Typography>
          )}
        </Box>
      ),
    },
    {
      name: `${t("End")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>
            {dayjs(row.end).format(
              i18n.resolvedLanguage === "th" ? "D MMM BBBB" : "D MMM YYYY"
            )}
          </Typography>
          {row.isFullDay === 1 ? (
            <Typography>{`${t("Full_day")}`}</Typography>
          ) : (
            <Typography>
              {dayjs(row.end).format("HH:mm")}
            </Typography>
          )}
        </Box>
      ),
    },
    {
      name: `${t("Reason")}`,
      minWidth: "200px",
      cellRender: (row) => (
        <Box>
          <Typography color="text.third" fontSize="14px">
            {row.description}
          </Typography>
        </Box>
      ),
    },
  ];

  return (
    <StyledRoot>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <CardData
                    title={`${t("Employees")}`}
                    dataValue={Utils.numberWithCommasWithoutDot(
                      dashboardOverview.employeeTotal
                    )}
                    dataUnit={`${t("People")}`}
                    dynamicFontSize
                    titleIcon={
                      <Icon
                        baseClassName="fal"
                        className="fa-users"
                        color="primary"
                        style={{ display: "inline-table" }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CardData
                    title={`${t("SalaryTotal")}`}
                    dataValue={Utils.numberWithCommas(dashboardOverview.salaryTotal)}
                    dataUnit={`${t("Baht")}`}
                    allowedPermission={dashboardOverview.canManagerAccessPayroll}
                    dynamicFontSize
                    titleIcon={
                      <Icon
                        className="fal fa-sack-dollar"
                        color="warning"
                        style={{ display: "inline-table", color: "#ffcd38" }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CardData
                    title={`${t("OTOVer36Hr")}`}
                    // dataValue={[{name: "1", value: "20"}, {name: "2", value: "30"}]}
                    dataValue={dashboardOverview.employeeOTOver36Total.weekInMonth.map(
                      (week) => {
                        return {
                          name: `${dayjs(week.weekStartDateText).format(
                            "dd D MMM"
                          )} - ${dayjs(week.weekEndDateText).format(
                            "dd D MMM"
                          )}`,
                          value: week.over36HoursEmployeeTotal,
                        };
                      }
                    )}
                    dataUnit={`${t("People")}`}
                    dynamicFontSize
                    select
                    titleIcon={
                      <Icon
                        className="far fa-alarm-exclamation"
                        color="error"
                        style={{ display: "inline-table" }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CardData
                    title={`${t("OTTotal")}`}
                    dataValue={Utils.numberWithCommas(
                      Object.keys(dashboardOverview.otTotal).reduce(
                        (a, b) => a + dashboardOverview.otTotal[b].payTotal,
                        0
                      )
                    )}
                    dataUnit={`${t("Baht")}`}
                    allowedPermission={dashboardOverview.canManagerAccessPayroll}
                    dynamicFontSize
                    titleIcon={
                      <Icon
                        className="fal fa-hand-holding-usd"
                        color="warning"
                        style={{ display: "inline-table", color: "#ffcd38" }}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} lg={6}>
              <CardDashboard>
                <Box height="inherit" display="flex" flexDirection="column">
                  <Typography marginBottom="16px">
                    {`${t("SalaryTotal")}`} ({`${t("Yearly")}`} {year})
                  </Typography>
                  {dashboardOverview.canManagerAccessPayroll? (
                    <ChartAreaCustom
                      series={[
                        {
                          name: `${t("SalaryTotal")}`,
                          data: dashboardOverview.salaryTotalAllYear.map((s) => {
                            return {
                              x: dayjs()
                                .month(s.month - 1)
                                .format("MMM"),
                              y: s.salaryTotal,
                            };
                          }),
                        },
                      ]}
                    />
                    ) : (
                      <Box flexGrow="1" display="flex" justifyContent="center" alignItems="center">
                        <Typography color="error" fontSize="14px" align="center" margin="auto">{t("AccessDenied")}</Typography>
                      </Box>
                    )
                  }
                </Box>
              </CardDashboard>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <CardDashboard>
                <Typography marginBottom="16px">
                  {`${t("OTTotalByType")}`}
                </Typography>
                <ChartPolarAreaCustom
                  series={Object.keys(dashboardOverview.otTotal).map(
                    (ot) => dashboardOverview.otTotal[ot].payTotal
                  )}
                  labels={Object.keys(dashboardOverview.otTotal).map(
                    (ot) => `OT x${ot}`
                  )}
                />
              </CardDashboard>
            </Grid>

            <Grid item xs={12} lg={6}>
              <CardDashboard>
                <Box height="inherit" display="flex" flexDirection="column">
                  <Typography marginBottom="16px">
                    {`${t("OTTotal")}`} ({`${t("Yearly")}`} {year})
                  </Typography>
                  {dashboardOverview.canManagerAccessPayroll? (
                    <ChartAreaCustom
                      series={[
                        {
                          name: `${t("OTTotal")}`,
                          data: dashboardOverview.otTotalAllYear.map((s) => {
                            return {
                              x: dayjs()
                                .month(s.month - 1)
                                .format("MMM"),
                              y: s.payTotal,
                            };
                          }),
                        },
                      ]}
                    />
                    ) : (
                      <Box flexGrow="1" display="flex" justifyContent="center" alignItems="center">
                        <Typography color="error" fontSize="14px" align="center" margin="auto">{t("AccessDenied")}</Typography>
                      </Box>
                    )
                  }
                </Box>
              </CardDashboard>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
              <CardDashboard>
                <Typography marginBottom="16px">
                  {`${t("Leave")}`} {`${t("Monthly")}`}
                  {dayjs()
                    .month(month - 1)
                    .format("MMMM")}
                </Typography>
                <TableCustom
                  columns={leaveColumns}
                  rows={dashboardOverview.workingTimeEmployeeInfo.leave}
                  rowsPerPageProp={5}
                  rowsPerPageOptions={[5]}
                />
              </CardDashboard>
            </Grid>
            <Grid item xs={12} lg={4}>
              <CardDashboard>
                <Typography marginBottom="16px">
                  {`${t("Top5Absent")}`} {`${t("Monthly")}`}
                  {dayjs()
                    .month(month - 1)
                    .format("MMMM")}
                </Typography>
                {dashboardOverview.workingTimeEmployeeInfo.top5AbsentEmployees
                  .length > 0 && (
                    <Box
                      style={{
                        display: "flex",
                        gap: "16px",
                        flexDirection: "column",
                      }}
                    >
                      {dashboardOverview.workingTimeEmployeeInfo.top5AbsentEmployees.map(
                        (e, index) => (
                          <Box key={index} className="top-employee">
                            <Icon
                              className={`fa-solid fa-square-${index + 1}`}
                              color="primary"
                              style={{
                                display: "inline-table",
                                color: index <= 2 ? "#ffcd38" : undefined,
                              }}
                            />
                            <Box
                              style={{
                                flexGrow: "1",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Avatar
                                src={e.imageURL? e.imageURL: null}
                                style={{
                                  marginRight: "8px",
                                  width: "32px",
                                  height: "32px",
                                }}
                              />
                              <Typography>{getUserFullName(e)}</Typography>
                            </Box>
                            <Typography>
                              {e.totalAbsent} {`${t("Days")}`}
                            </Typography>
                          </Box>
                        )
                      )}
                    </Box>
                  )}
                {dashboardOverview.workingTimeEmployeeInfo.top5AbsentEmployees
                  .length <= 0 && (
                    <Typography textAlign="center" color="text.third">
                      {`${t("NoAbsent")}`}
                    </Typography>
                  )}
              </CardDashboard>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={3}>
              <CardData
                title={`${t("EmployeeIn")}`}
                dataValue={String(
                  dashboardOverview.employeeInfo.employeeInTotal
                )}
                dataUnit={`${t("People")}`}
                dynamicFontSize
                titleIcon={
                  <Icon
                    className="fas fa-transporter"
                    color="success"
                    style={{ display: "inline-table" }}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <CardData
                title={`${t("EmployeeOut")}`}
                dataValue={String(
                  dashboardOverview.employeeInfo.employeeOutTotal
                )}
                dataUnit={`${t("People")}`}
                dynamicFontSize
                titleIcon={
                  <Icon
                    className="fas fa-person-circle-xmark"
                    color="error"
                    style={{ display: "inline-table" }}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <CardData
                title="Turnover Rate"
                dataValue={"-"}
                dataUnit="%"
                dynamicFontSize
                titleIcon={
                  <Icon
                    className="fas fa-chart-line"
                    color="primary"
                    style={{ display: "inline-table" }}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <CardData
                title="Turnover Year"
                dataValue={"-"}
                dataUnit="%"
                dynamicFontSize
                titleIcon={
                  <Icon
                    className="fas fa-calendar-days"
                    color="primary"
                    style={{ display: "inline-table" }}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <CardData
                title={`${t("Male")}`}
                dataValue={String(dashboardOverview.employeeInfo.maleTotal)}
                dataUnit={`${t("People")}`}
                dynamicFontSize
                titleIcon={
                  <Icon
                    className="fas fa-mars"
                    color="warning"
                    style={{ display: "inline-table", color: "#35baf6" }}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <CardData
                title={`${t("Female")}`}
                dataValue={String(dashboardOverview.employeeInfo.femaleTotal)}
                dataUnit={`${t("People")}`}
                dynamicFontSize
                titleIcon={
                  <Icon
                    className="fas fa-venus"
                    color="warning"
                    style={{ display: "inline-table", color: "#ed4b82" }}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <CardData
                title={`${t("AverageAge")}`}
                dataValue={String(dashboardOverview.employeeInfo.averageAge)}
                dataUnit={`${t("Years")}`}
                titleIcon={
                  <Icon
                    className="fas fa-people-group"
                    color="primary"
                    style={{ display: "inline-table" }}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <CardData
                title={`${t("AverageWork")}`}
                dataValue={String(
                  dashboardOverview.employeeInfo.averageWorkExperience
                )}
                dataUnit={`${t("Years")}`}
                titleIcon={
                  <Icon
                    className="fas fa-briefcase"
                    color="primary"
                    style={{ display: "inline-table" }}
                  />
                }
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <CardDashboard>
            <Typography marginBottom="16px">
              {`${t("ListNewHiresAndResignations")}`}
              {dayjs()
                .month(month - 1)
                .format("MMMM")}
            </Typography>
            <EmployeeInOut />
          </CardDashboard>
        </Grid>
      </Grid>
    </StyledRoot>
  );
};

export default OverviewPanel;
