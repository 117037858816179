import React from "react";
import { useSelector } from "react-redux";

import AdminOKRS from "../admin/okrs";
import ManagerOKRS from "../manager/okrs";
import UserOKRS from "../user/okrs";

const OKRSPage = () => {
  const { result: userProfile } = useSelector((state) => state.userProfile);
  return (
    <>
      {userProfile ? (
        userProfile.roles.includes("ROLE_ADMIN") ? (
          <AdminOKRS />
        ) : userProfile.roles.includes("ROLE_MANAGER") ? (
          <ManagerOKRS />
        ) : (
          <UserOKRS />
        )
      ) : null}
    </>
  );
};

export default OKRSPage;
