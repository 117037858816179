import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";

import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";

import EmpSlipInfo from "./info";
import TableSlip from "./table";
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  marginTop: 16,
  marginBottom: 24,
  "& .confidential": {
    color: "#000",
    fontWeight: 700,
  },
  "& .payslip": {
    color: "#C40000",
    fontWeight: 700,
    fontSize: "1.2rem !important",
  },
});

const StyledSlipSection = styled("div")({
  width: 896,
  height: "1267.2px",
  margin: "auto",
  padding: 40,
});

const PaySlipDetail = (props) => {
  const { t, i18n } = useTranslation();
  const { currentSlip } = props;

  return (
    <StyledRoot>
      {currentSlip ? (
        <StyledSlipSection id="divToPrint">
          <Box style={{ marginBottom: "50px" }}>
          {currentSlip.companyImage && (
              <img
                src={`${currentSlip.companyImage}`}
                style={{ maxWidth: "150px", minHeight: "150px" }}
              />
            )}
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography className="confidential">Confidential</Typography>
              </Grid>
              <Grid item>
                <Typography className="payslip">PAY SLIP</Typography>
              </Grid>
            </Grid>
          </Box>
          <EmpSlipInfo currentSlip={currentSlip} />
          <TableSlip currentSlip={currentSlip} />
        </StyledSlipSection>
      ) : (
        <Box style={{ marginTop: "20px" }}>
          <Grid container justifyContent="center">
            <Grid item>
              <Typography variant="subtitle">{t("NoData")}</Typography>
            </Grid>
          </Grid>
        </Box>
      )}
    </StyledRoot>
  );
};

export default PaySlipDetail;
