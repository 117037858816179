import React, { useEffect } from "react";
import DrawerCustom from "../../shared/general/Drawer";
import { Box, Checkbox, FormControlLabel, Grid, Typography, styled } from "@mui/material";
import { Controller, useForm } from "react-hook-form";

import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import { useTranslation } from "react-i18next";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { getShift, updateShiftGroup } from "../../../../actions/shift";
import { useDispatch } from "react-redux";
import { openNotificationAlert } from "../../../../actions/notificationAlert";
import CircularProgress from "@mui/material/CircularProgress";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiCheckbox-root": {
    "&.Mui-checked": {
      color: "#46cbe2",
    },
  },
})

const DrawerShiftGroupSetting = (props) => {

  const { drawerConfig, handleClose } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const validateYupSchema = yup.object({
    shiftGroupCodeCustom: yup.string().when("isCustomShiftGroupCode", {
      is: true,
      then: schema => schema.required(t(`ThisFieldIsRequired`))
    })
    // .test("", t("ThisFieldIsRequired"), (value, context) => {
      
    // })
  })

  const useHookForm = useForm({
    defaultValues: {
      isCustomShiftGroupCode: false,
      shiftGroupCodeCustom: "",
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all"
  })

  const isCustomShiftGroupCodeWatch = useHookForm.watch("isCustomShiftGroupCode");

  const onSubmit = async (data) => {
    // console.log(data)

    const formData = {
      // idShiftGroup: drawerConfig.data.idShiftGroup,
      shiftGroupCodeCustom: data.isCustomShiftGroupCode? (data.shiftGroupCodeCustom? data.shiftGroupCodeCustom: null): null,
    }

    const result = await dispatch(updateShiftGroup(drawerConfig.data.idShiftGroup, formData));

    if(result && result.status === 200) {
      handleClose();
      dispatch(openNotificationAlert({
        type: "success",
        message: t("DataSaveSuccessful"),
      }));
      dispatch(getShift({idCompany: drawerConfig.data.idCompany}))
    } else {
      if(result && result.data && result.data.error_code === "SHIFT_GROUP_CODE_DUPLICATED"){
        dispatch(openNotificationAlert({
          type: "error",
          message: t("ShiftGroupCodeIsAlreadyExits", { code: data.shiftGroupCodeCustom })
        }));
      } else {
        dispatch(openNotificationAlert({
          type: "error",
          message: t("AnErrorOccurred")
        }));
      }
    }
  }

  useEffect(() => {
    if(drawerConfig.isOpen){

      useHookForm.setValue("isCustomShiftGroupCode", drawerConfig.data.isCustomShiftGroupCode? drawerConfig.data.isCustomShiftGroupCode: false, { shouldDirty: false, shouldTouch: false, shouldValidate: false})
      useHookForm.setValue("shiftGroupCodeCustom", drawerConfig.data.shiftGroupCodeCustom? drawerConfig.data.shiftGroupCodeCustom: "", { shouldDirty: false, shouldTouch: false, shouldValidate: false})

    }
  }, [drawerConfig.isOpen])

  return (
    <DrawerCustom
      title={t("ShiftGroup")}
      anchor="right"
      open={drawerConfig.isOpen}
      onClose={() => {
        if(useHookForm.formState.isSubmitting) return
        handleClose()
      }}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px"
        }
      }}
    >
      <StyledRoot>
        <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="4px">
                <Typography fontSize="14px" fontWeight="500">{t("ShiftGroupCode")}</Typography>
                <Controller
                  name={"isCustomShiftGroupCode"}
                  control={useHookForm.control}
                  render={({field}) => (
                    <FormControlLabel
                      control={
                        <Checkbox
                          {...field}
                          checked={field.value}
                          onChange={(e) => {
                            field.onChange(e.target.checked);
                          }}
                        />
                      }
                      label={<Typography fontSize="14px">{t("Custom")}</Typography>}
                      labelPlacement="start"
                    />
                  )}
                />
              </Box>
              {isCustomShiftGroupCodeWatch? (
                <Controller
                  name={"shiftGroupCodeCustom"}
                  control={useHookForm.control}
                  render={({field, fieldState}) => (
                    <TextFieldTheme
                      {...field}
                      helperText={fieldState.error? fieldState.error.message : null}
                      error={fieldState.error? true : false}
                    />
                  )}
                />
              ): (
                <Typography fontSize="20px" fontWeight="500">{drawerConfig.data.shiftGroupCode}</Typography>
              )}
            </Grid>

            <Grid item xs={12} container justifyContent="space-between">
              <ButtonBlue variant="text" onClick={handleClose} disabled={useHookForm.formState.isSubmitting}>{t("Cancel")}</ButtonBlue>
              <ButtonBlue
                variant="contained"
                type="submit"
                disabled={useHookForm.formState.isSubmitting}
                startIcon={useHookForm.formState.isSubmitting && (
                  <CircularProgress
                    color="inherit"
                    size={16}
                    // sx={{fontSize: "inherit"}}
                  />
                )}
              >
                {t("Save")}
              </ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  )
}

export default DrawerShiftGroupSetting