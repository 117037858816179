import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Container,
  IconButton,
  MenuItem,
  Typography,
  styled,
  Button,
} from "@mui/material";
import Edit from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";
import ClearIcon from "@mui/icons-material/Clear";
import CardStyle from "../../../../../shared/general/Card";
import { AccessTimeRounded } from "@mui/icons-material";
import ButtonBlue from "../../../../../shared/general/ButtonBlue";
import TextFieldTheme from "../../../../../shared/general/TextFieldTheme";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import {
  addTeamKeyResultProgress,
  updateTeamKeyResultProgress,
  getAllTeamKeyResultProgress,
  updateTeamKeyResult,
} from "../../../../../../../actions/okrs";
import { Controller, useForm } from "react-hook-form";
//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledRoot = styled(Box)({});

const ResponsiveButtonBlue = styled(Button)(({ theme }) => ({
  backgroundColor: "rgba(219, 65, 120, 1)",
  width: "10%",
  height: "50px",
  fontSize: "15px",
  fontWeight: "bold",
  borderRadius: "7px",
  "&:hover": {
    backgroundColor: "#C53B6C",
  },
  [theme.breakpoints.down("sm")]: {
    width: "10%",
    fontSize: "14px",
    height: "40px",
  },
}));

const UpdateProgressTeamKeyResult = (props) => {
  const { match } = props;
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  const [TeamKeyResult, setTeamKeyResult] = useState(null);

  const useHookForm = useForm({
    defaultValues: {
      message: "",
    },
  });

  const getValueStatus = (status) => {
    switch (status) {
      case null:
        return "";
      case 1:
        return "1";
      case 0:
        return "0";
    }
  };

  const onSubmit = async (formData) => {
    const response = await dispatch(
      addTeamKeyResultProgress(match.params.idTeamKeyResult, formData)
    );

    if (response) {
      if (response.status === 200) {
        setTeamKeyResult((prev) => ({
          ...prev,
          teamKeyResultProgress: [
            {
              idTeamKeyResultProgress: response.data.idTeamKeyResultProgress,
              message: response.data.message,
              createDate: response.data.createDate,
            },
            ...prev.teamKeyResultProgress,
          ],
        }));
        useHookForm.setValue("message", "");
      }
    }
  };

  const onChangeStatus = async (status) => {
    let formData = {
      isAchieve: status,
      achieveDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    };

    if (formData.isAchieve === "") {
      formData.isAchieve = null;
    } else {
      formData.isAchieve = Number(formData.isAchieve);
    }

    const response = await dispatch(
      updateTeamKeyResult(match.params.idTeamKeyResult, formData)
    );

    if (response.status === 200) {
      setTeamKeyResult((prev) => ({
        ...prev,
        isAchieve: formData.isAchieve,
        achieveDate: formData.achieveDate,
      }));
    }
  };

  const handleUpdateKeyResultProgress = async (
    idTeamKeyResultProgress,
    formData
  ) => {
    const responseUpdate = await dispatch(
      updateTeamKeyResultProgress(idTeamKeyResultProgress, formData)
    );
    if (responseUpdate) {
      const res = await dispatch(
        getAllTeamKeyResultProgress(match.params.idTeamKeyResult)
      );
      if (res.status === 200) {
        setTeamKeyResult(res.data);
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    dispatch(getAllTeamKeyResultProgress(match.params.idTeamKeyResult)).then(
      (res) => {
        if (isMounted) {
          if (res.status === 200) {
            setTeamKeyResult(res.data);
          }
        }
      }
    );

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <StyledRoot className="page">
      <Box padding="24px 0">
        <Container maxWidth="lg">
          {TeamKeyResult && (
            <Fragment>
              <CardStyle>
                <Box padding="24px">
                  <Box display="flex" alignItems="flex-start">
                    <Typography flexGrow="1" fontSize="18px" fontWeight="500">
                      {TeamKeyResult.keyResultName}
                    </Typography>

                    {/* <ButtonBlue variant="contained">เปลี่ยนสถานะ</ButtonBlue> */}
                    <TextFieldTheme
                      value={getValueStatus(TeamKeyResult.isAchieve)}
                      select
                      onChange={(event) => {
                        onChangeStatus(event.target.value);
                      }}
                      SelectProps={{
                        displayEmpty: true,
                      }}
                      style={{
                        maxWidth: "200px",
                      }}
                    >
                      <MenuItem value="">{`${t("InProgress")}`}</MenuItem>
                      <MenuItem value="1">{`${t("Successfully")}`}</MenuItem>
                      <MenuItem value="0">{`${t("Unsuccessful")}`}</MenuItem>
                    </TextFieldTheme>
                    {/* <Box display="flex" alignItems="center">
                    <Typography fontSize="14px">อัพเดทสถานะ</Typography>
                    <ChevronRightRounded style={{marginLeft: "8px", fontSize: "22px"}}/>
                  </Box> */}
                  </Box>
                  <Box display="flex" alignItems="center" marginTop="8px">
                    <AccessTimeRounded
                      style={{ marginRight: "8px", fontSize: "16px" }}
                    />
                    <Typography fontSize="14px" lineHeight="1">
                      {dayjs(TeamKeyResult.startDate).format("D MMM BBBB")} -{" "}
                      {dayjs(TeamKeyResult.endDate).format("D MMM BBBB")}
                    </Typography>
                  </Box>
                </Box>
              </CardStyle>

              <Box marginTop="24px">
                <CardStyle>
                  <Box padding="24px">
                    <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
                      <Controller
                        name="message"
                        control={useHookForm.control}
                        render={({ field }) => (
                          <TextFieldTheme
                            {...field}
                            maxRows={5}
                            multiline
                            inputProps={{
                              placeholder: `${t("WriteMessage")}`,
                            }}
                          />
                        )}
                      />
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        marginTop="16px"
                      >
                        <ResponsiveButtonBlue
                          variant="contained"
                          type="submit"
                          disabled={useHookForm.formState.isSubmitting}
                        >{`${t("Update")}`}</ResponsiveButtonBlue>
                      </Box>
                    </form>
                  </Box>
                </CardStyle>
              </Box>

              <Box marginTop="24px">
                {TeamKeyResult.teamKeyResultProgress.map((progress, index) => (
                  <KeyResultProgress
                    key={index}
                    data={progress}
                    handleUpdateKeyResultProgress={
                      handleUpdateKeyResultProgress
                    }
                  />
                ))}
              </Box>
            </Fragment>
          )}
        </Container>
      </Box>
    </StyledRoot>
  );
};

const StyledKeyResultProgressRoot = styled(Box)({
  marginBottom: "16px",
  "&:last-child": {
    marginBottom: 0,
  },
});

const KeyResultProgress = (props) => {
  const { data, handleUpdateKeyResultProgress } = props;
  const [mode, setMode] = useState(false);
  const [edit, setEdit] = useState("");

  const handleChangeMode = () => {
    setEdit(data.message);
    setMode((prev) => !prev);
  };

  const handleUpdate = () => {
    setMode(false);
    handleUpdateKeyResultProgress(data.idTeamKeyResultProgress, {
      message: edit,
    });
  };

  return (
    <StyledKeyResultProgressRoot>
      <CardStyle>
        <Box padding="24px">
          {mode ? (
            <TextFieldTheme
              value={edit}
              onChange={(e) => setEdit(e.target.value)}
            />
          ) : (
            <Typography whiteSpace="pre-line">{data.message}</Typography>
          )}
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems={"center"}
            gap={1}
          >
            {mode ? (
              <>
                <IconButton onClick={handleUpdate} color="success">
                  <DoneIcon />
                </IconButton>
                <IconButton onClick={handleChangeMode} color="error">
                  <ClearIcon />
                </IconButton>
              </>
            ) : (
              <IconButton onClick={handleChangeMode} color="primary">
                <Edit />
              </IconButton>
            )}
            <Typography fontSize="12px" color="text.third">
              {dayjs(data.updateDate).format("D MMM BBBB HH.mm")}
            </Typography>
          </Box>
        </Box>
      </CardStyle>
    </StyledKeyResultProgressRoot>
  );
};

export default UpdateProgressTeamKeyResult;
