import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ConfirmDialog from "../../../shared/general/ConfirmDialog";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import { styled } from "@mui/styles";

import { useSelector, useDispatch } from "react-redux";
import { getUserProfile } from "../../../../../actions/user";
import {
  getOrgPlanById,
  postOrgPlan,
  putOrgPlan,
} from "../../../../../actions/OrganizationPlans";

// Components
import {
  ButtonGroup,
  Button,
  Grid,
  Typography,
  TextField,
  FormControl,
  MenuItem,
  InputLabel,
  Avatar,
  InputAdornment,
  Select,
  IconButton,
  Box,
  Container,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

// Icons
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CircleIcon from "@mui/icons-material/Circle";
import StarIcon from "@mui/icons-material/Star";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import NotesIcon from "@mui/icons-material/Notes";
import Crop32Icon from "@mui/icons-material/Crop32";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import EscalatorIcon from "@mui/icons-material/Escalator";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import { useTranslation } from "react-i18next";

const InitialValues = () => ({
  planOrgId: 0,
  objectiveName: "",
  impactName: "",
  quater: "Q1",
  keyResult1: "",
  description1: "",
  scope1: "",
  tactics1: "",
  measureEvidence1: "",
  commitQuality1: "",
  krType1: "",
  keyResult2: "",
  description2: "",
  scope2: "",
  tactics2: "",
  measureEvidence2: "",
  commitQuality2: "",
  krType2: "",
  keyResult3: "",
  description3: "",
  scope3: "",
  tactics3: "",
  measureEvidence3: "",
  commitQuality3: "",
  krType3: "",
  createByEmpId: null,
  dateCreate: new Date(),
  planStatus: "Approved",
  kR1Status: "Approved",
  kR2Status: "Approved",
  kR3Status: "Approved",
});

const useStyles = makeStyles(() => ({
  spaceFromLocationToQ: {
    marginTop: 5,
    fontSize: 18,
    fontWeight: "bold",
  },
  circle: {
    color: "#bcbcbc",
  },
  notSelectedQ: {
    backgroundColor: "#bcbcbc",
    width: 16,
    height: 16,
  },
  selectedQ: {
    // backgroundColor : '#3f51b5',
    width: 50,
    height: 50,
  },
  selectedIcon: {
    fontSize: 28,
  },
  name: {
    fontSize: 23,
    fontWeight: "bold",
  },
  placeholder: {
    color: "#aaa",
  },
}));

const ContainerStyled = styled(Container)({
  marginTop: "100px",
});

function OrganizationPlan() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const {t,i18n} = useTranslation();
  const { result: orgPlanStore } = useSelector((state) => state.OrgPlans);
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const [values, setValues] = useState(InitialValues);
  const [disabled, setDisabled] = useState(false);
  const location = useLocation();
  const [numberKeyResult, setNumberKeyResult] = useState(1);
  const [numberQuater, setNumberQuater] = useState(0);
  const [iconStatus, setIconStatus] = useState(true);
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    typeConfirm: "",
    title: "",
    subTitle: "",
  });
  const [errors, setErrors] = useState({
    objectiveName: false,
    impactName: false,
    keyResult1: false,
  });

  const krType = [
    { id: "None", title: "None" },
    { id: "Numeric", title: "Numeric" },
    { id: "Descriptive", title: "Description" },
  ];

  const onPostOrgPlan = async (formData) => {
    await dispatch(postOrgPlan(formData));
    history.push("/organizationList");
  };

  const onPutOrgPlan = async (id, formData) => {
    await dispatch(putOrgPlan(id, formData));
    history.push("/organizationList");
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (values.objectiveName && values.impactName && values.keyResult1) {
      const formData = {
        objectiveName: values.objectiveName,
        impactName: values.impactName,
        quater: values.quater,
        keyResult1: values.keyResult1,
        description1: values.description1,
        tactics1: values.tactics1,
        measureEvidence1: values.measureEvidence1,
        commitQuality1: values.commitQuality1,
        krType1: values.krType1,
        keyResult2: values.keyResult2,
        description2: values.description2,
        tactics2: values.tactics2,
        measureEvidence2: values.measureEvidence2,
        commitQuality2: values.commitQuality2,
        krType2: values.krType2,
        keyResult3: values.keyResult3,
        description3: values.description3,
        tactics3: values.tactics3,
        measureEvidence3: values.measureEvidence3,
        commitQuality3: values.commitQuality3,
        krType3: values.krType3,
        createByEmpId: userProfile.idEmployees,
        dateCreate: dayjs(new Date()).format("YYYY-MM-DD"),
        planStatus: "Approved",
        kR1Status: "Approved",
        kR2Status: "Approved",
        kR3Status: "Approved",
      };

      //Post to DB
      if (values.planOrgId == 0) {
        onPostOrgPlan(formData);
      } else {
        onPutOrgPlan(values.planOrgId, values);
      }
    } else {
      setErrors({
        objectiveName: true,
        impactName: true,
        keyResult1: true,
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  useEffect(() => {
    if (orgPlanStore) {
      setValues(orgPlanStore[0]);
      setNumberQuater(5);
      setNumberKeyResult(3);
      setIconStatus(false);
    }
  }, [orgPlanStore]);

  useEffect(() => {
    if (location.isInfo == true) {
      setDisabled(true);
      setValues(location.state.planList);
    } else {
      if (location.state.planList !== undefined) {
        //Edit Plan
        setValues(location.state.planList);
        setNumberKeyResult(3);
      } else {
        //Add Plan
        if (currentUser) {
          dispatch(getUserProfile(currentUser.username));
        }

        const date = new Date();
        const month = date.getMonth() + 1;
        const quater = Math.ceil(month / 3);
        setValues({ ...values, quater: "Q" + quater });
        setNumberQuater(quater);
      }
    }
  }, []);

  return (
    <ContainerStyled>
      <div className="card-section">
        <div className="header-section">
          <Grid container>
            <Grid item>
              <h3 className="header-topic">Organization Planning</h3>
            </Grid>
          </Grid>
        </div>
        <form noValidate autoComplete="off">
          <Grid container justifyContent="center" alignItems="center">
            <Grid
              item
              xl={2}
              md={2}
              xs={2}
              style={{ textAlign: "-webkit-center" }}
            >
              {values.quater == "Q1" ? (
                <Avatar
                  sx={{ backgroundColor: "primary.main" }}
                  className={classes.selectedQ}
                >
                  <LocationOnIcon className={classes.selectedIcon} />
                </Avatar>
              ) : (
                <Avatar className={classes.notSelectedQ}>
                  <CircleIcon className={classes.circle} />
                </Avatar>
              )}
              <Typography className={classes.spaceFromLocationToQ}>
                Q1
              </Typography>
            </Grid>

            <Grid
              item
              xl={2}
              md={2}
              xs={2}
              style={{ textAlign: "-webkit-center" }}
            >
              {values.quater == "Q2" ? (
                <Avatar
                  sx={{ backgroundColor: "primary.main" }}
                  className={classes.selectedQ}
                >
                  <LocationOnIcon className={classes.selectedIcon} />
                </Avatar>
              ) : (
                <Avatar className={classes.notSelectedQ}>
                  <CircleIcon className={classes.circle} />
                </Avatar>
              )}
              <Typography className={classes.spaceFromLocationToQ}>
                Q2
              </Typography>
            </Grid>
            <Grid
              item
              xl={2}
              md={2}
              xs={2}
              style={{ textAlign: "-webkit-center" }}
            >
              {values.quater == "Q3" ? (
                <Avatar
                  sx={{ backgroundColor: "primary.main" }}
                  className={classes.selectedQ}
                >
                  <LocationOnIcon className={classes.selectedIcon} />
                </Avatar>
              ) : (
                <Avatar className={classes.notSelectedQ}>
                  <CircleIcon className={classes.circle} />
                </Avatar>
              )}
              <Typography className={classes.spaceFromLocationToQ}>
                Q3
              </Typography>
            </Grid>
            <Grid
              item
              xl={2}
              md={2}
              xs={2}
              style={{ textAlign: "-webkit-center" }}
            >
              {values.quater == "Q4" ? (
                <Avatar
                  sx={{ backgroundColor: "primary.main" }}
                  className={classes.selectedQ}
                >
                  <LocationOnIcon className={classes.selectedIcon} />
                </Avatar>
              ) : (
                <Avatar className={classes.notSelectedQ}>
                  <CircleIcon className={classes.circle} />
                </Avatar>
              )}
              <Typography className={classes.spaceFromLocationToQ}>
                Q4
              </Typography>
            </Grid>
          </Grid>
          <br />

          <Grid container justifyContent="center" spacing={2}>
            <Grid item xl={10} md={10} sm={10} xs={10}>
              <TextField
                name="objectiveName"
                value={values.objectiveName}
                onChange={handleInputChange}
                fullWidth
                error={errors.objectiveName}
                helperText={
                  errors.objectiveName ? "This field is required" : null
                }
                label="Objective"
                placeholder="Objective"
                disabled={disabled}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <StarIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xl={10} md={10} sm={10} xs={10}>
              <TextField
                name="impactName"
                value={values.impactName}
                onChange={handleInputChange}
                fullWidth
                disabled={disabled}
                error={errors.impactName}
                helperText={errors.impactName ? "This field is required" : null}
                // multiline
                label="Impact"
                placeholder="Impact"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AutoAwesomeIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>

          {/* ขึ้น key result แรก */}
          <hr className="hrTag" />
          <Grid
            container
            direction="row"
            style={{ paddingLeft: 30, paddingRight: 30 }}
          >
            <Grid item xl={4} lg={4} md={6} xs={8}>
              <Typography
                sx={{ color: "primary.main" }}
                className={classes.name}
              >
                {t("The1KeyResult")}
              </Typography>
            </Grid>
            <Grid item xl={8} lg={8} md={6} xs={4} style={{ textAlign: "end" }}>
              {!disabled ? (
                <>
                  {numberKeyResult < 3 ? (
                    <IconButton
                      aria-label="add-demand"
                      style={{
                        marginRight: 5,
                        backgroundColor: "#7dc581",
                        color: "#357a38",
                      }}
                    >
                      <AddIcon
                        onClick={() => {
                          setNumberKeyResult(numberKeyResult + 1);
                        }}
                      />
                    </IconButton>
                  ) : (
                    <IconButton
                      aria-label="add-demand"
                      style={{ marginLeft: 5 }}
                      disabled
                      color="primary"
                    >
                      <AddIcon />
                    </IconButton>
                  )}
                  {numberKeyResult != 1 ? (
                    <IconButton
                      aria-label="delete"
                      style={{
                        marginLeft: 5,
                        backgroundColor: "#dd99b1",
                        color: "#ab003c",
                      }}
                    >
                      <DeleteIcon
                        onClick={() => setNumberKeyResult(numberKeyResult - 1)}
                      />
                    </IconButton>
                  ) : (
                    <IconButton
                      aria-label="delete"
                      style={{ marginLeft: 5 }}
                      disabled
                      color="primary"
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </>
              ) : null}
            </Grid>
            <Grid container justifyContent="center" style={{ marginTop: 20 }}>
              <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xl={3}
                    lg={3}
                    md={4}
                    sm={12}
                    xs={12}
                    style={{ marginBottom: 20 }}
                  >
                    <Typography style={{ fontSize: 17, fontWeight: "bold" }}>
                      {t("MainData")}
                    </Typography>
                    <Typography style={{ fontSize: 15 }}>
                      {t("DescriptionOfKeyResult")}
                    </Typography>
                  </Grid>
                  <Grid item xl={9} lg={9} md={8} sm={12} xs={12}>
                    <TextField
                      name="keyResult1"
                      value={values.keyResult1}
                      onChange={handleInputChange}
                      fullWidth
                      disabled={disabled}
                      // multiline
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <DriveFileRenameOutlineIcon />
                          </InputAdornment>
                        ),
                      }}
                      label={t("KeyResult") + "1"}
                      placeholder={t("KeyResult") + "1"}
                      sx={{ paddingBottom: 2 }}
                      error={errors.keyResult1}
                      helperText={
                        errors.keyResult1 ? "This field is required" : null
                      }
                    />
                    <TextField
                      name="description1"
                      value={values.description1}
                      onChange={handleInputChange}
                      fullWidth
                      disabled={disabled}
                      multiline
                      rows={2}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <NotesIcon />
                          </InputAdornment>
                        ),
                      }}
                      label="Description"
                      placeholder="Description"
                      sx={{ paddingBottom: 2 }}
                    />
                    {/*
                    <TextField
                      name="scope1"
                      value={values.scope1}
                      onChange={handleInputChange}
                      fullWidth
                      disabled={disabled}
                      // multiline
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Crop32Icon />
                          </InputAdornment>
                        ),
                      }}
                      label="Scope"
                      placeholder="Scope"
                    />
                    */}
                  </Grid>
                </Grid>
              </Grid>

              <Grid
                item
                xl={10}
                lg={10}
                md={10}
                sm={10}
                xs={10}
                style={{ marginTop: 10 }}
              >
                <Grid container>
                  <Grid
                    item
                    xl={3}
                    lg={3}
                    md={4}
                    sm={12}
                    xs={12}
                    style={{ marginBottom: 20 }}
                  >
                    <Typography style={{ fontSize: 17, fontWeight: "bold" }}>
                      {t("SecondData")}
                    </Typography>
                    <Typography style={{ fontSize: 15 }}>
                      {t("DetailOfKeyResult")}
                    </Typography>
                  </Grid>
                  <Grid item xl={9} lg={9} md={8} sm={12} xs={12}>
                    <TextField
                      name="tactics1"
                      value={values.tactics1}
                      onChange={handleInputChange}
                      fullWidth
                      disabled={disabled}
                      // multiline
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <AutoFixHighIcon />
                          </InputAdornment>
                        ),
                      }}
                      label="Tactics"
                      placeholder="Tactics"
                      sx={{ paddingBottom: 2 }}
                      //{...register("tactics1", { required: true })}
                      /*
                        error={errors.tactics1 ? true : false}
                      helperText={errors.tactics1 ? "Enter Tactics" : ""}
                       */
                    />
                    <TextField
                      name="measureEvidence1"
                      value={values.measureEvidence1}
                      onChange={handleInputChange}
                      fullWidth
                      disabled={disabled}
                      // multiline
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EscalatorIcon />
                          </InputAdornment>
                        ),
                      }}
                      label="Measure Evidence"
                      placeholder="Measure Evidence"
                      sx={{ paddingBottom: 2 }}
                      //{...register("measureEvidence1", { required: true })}
                      /*
                        error={errors.measureEvidence1 ? true : false}
                      helperText={
                        errors.measureEvidence1 ? "Enter Measure Evidence" : ""
                      }
                       */
                    />
                    <TextField
                      name="commitQuality1"
                      value={values.commitQuality1}
                      onChange={handleInputChange}
                      fullWidth
                      disabled={disabled}
                      // multiline
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EqualizerIcon />
                          </InputAdornment>
                        ),
                      }}
                      label="Commit Quality"
                      placeholder="Commit Quality"
                      sx={{ paddingBottom: 2 }}
                      //{...register("commitQuality1", { required: true })}
                      /*
                      error={errors.commitQuality1 ? true : false}
                      helperText={
                        errors.commitQuality1 ? "Enter Measure Evidence" : ""
                      }
                      */
                    />
                    <FormControl fullWidth>
                      <InputLabel>Select Type</InputLabel>
                      <Select
                        name="krType1"
                        value={values.krType1}
                        label="Select Type"
                        onChange={handleInputChange}
                        disabled={disabled}
                      >
                        {krType.map((item) => (
                          <MenuItem
                            key={item.id}
                            value={item.id == "None" ? "" : item.id}
                          >
                            {item.title}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* ขึ้น key result ที่ 2 */}
          <Box style={numberKeyResult < 2 ? { display: "none" } : {}}>
            <hr className="hrTag" />
            <Grid
              container
              direction="row"
              style={{ paddingLeft: 30, paddingRight: 30 }}
            >
              <Grid item xl={4} lg={4} md={6} xs={8}>
                <Typography
                  sx={{ color: "primary.main" }}
                  className={classes.name}
                >
                  {t("The2KeyResult")}
                </Typography>
              </Grid>
              <Grid
                item
                xl={8}
                lg={8}
                md={6}
                xs={4}
                style={{ textAlign: "end" }}
              >
                {!disabled ? (
                  <>
                    {numberKeyResult < 3 ? (
                      <IconButton
                        aria-label="add-demand"
                        style={{
                          marginRight: 5,
                          backgroundColor: "#7dc581",
                          color: "#357a38",
                        }}
                      >
                        <AddIcon
                          onClick={() => {
                            setNumberKeyResult(numberKeyResult + 1);
                          }}
                        />
                      </IconButton>
                    ) : (
                      <IconButton
                        aria-label="add-demand"
                        style={{ marginLeft: 5 }}
                        disabled
                        color="primary"
                      >
                        <AddIcon />
                      </IconButton>
                    )}
                    {numberKeyResult != 1 ? (
                      <IconButton
                        aria-label="delete"
                        style={{
                          marginLeft: 5,
                          backgroundColor: "#dd99b1",
                          color: "#ab003c",
                        }}
                      >
                        <DeleteIcon
                          onClick={() =>
                            setNumberKeyResult(numberKeyResult - 1)
                          }
                        />
                      </IconButton>
                    ) : (
                      <IconButton
                        aria-label="delete"
                        style={{ marginLeft: 5 }}
                        disabled
                        color="primary"
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </>
                ) : null}
              </Grid>
              <Grid container justifyContent="center" style={{ marginTop: 20 }}>
                <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                  <Grid container>
                    <Grid
                      item
                      xl={3}
                      lg={3}
                      md={4}
                      sm={12}
                      xs={12}
                      style={{ marginBottom: 20 }}
                    >
                      <Typography style={{ fontSize: 17, fontWeight: "bold" }}>
                        {t("MainData")}
                      </Typography>
                      <Typography style={{ fontSize: 15 }}>
                        {t("DescriptionOfKeyResult")}
                      </Typography>
                    </Grid>
                    <Grid item xl={9} lg={9} md={8} sm={12} xs={12}>
                      <TextField
                        name="keyResult2"
                        value={values.keyResult2}
                        onChange={handleInputChange}
                        fullWidth
                        disabled={disabled}
                        // multiline
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <DriveFileRenameOutlineIcon />
                            </InputAdornment>
                          ),
                        }}
                        label={t("KeyResult") + "2"}
                        placeholder={t("KeyResult") + "2"}
                        sx={{ paddingBottom: 2 }}
                        // inputRef={register({ required: true })}
                        // error={errors.keyResult2 ? true : false}
                        // helperText={errors.keyResult2 ? 'Enter Objective Name' : ''}
                      />
                      <TextField
                        name="description2"
                        value={values.description2}
                        onChange={handleInputChange}
                        fullWidth
                        disabled={disabled}
                        multiline
                        rows={2}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <NotesIcon />
                            </InputAdornment>
                          ),
                        }}
                        label="Description"
                        placeholder="Description"
                        sx={{ paddingBottom: 2 }}
                      />
                      {/*
                      <TextField
                        name="scope2"
                        value={values.scope2}
                        onChange={handleInputChange}
                        fullWidth
                        disabled={disabled}
                        // multiline
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Crop32Icon />
                            </InputAdornment>
                          ),
                        }}
                        label="Scope"
                        placeholder="Scope"
                      />
                      */}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                  <Grid container>
                    <Grid
                      item
                      xl={3}
                      lg={3}
                      md={4}
                      sm={12}
                      xs={12}
                      style={{ marginBottom: 20 }}
                    >
                      <Typography style={{ fontSize: 17, fontWeight: "bold" }}>
                        {t("SecondData")}
                      </Typography>
                      <Typography style={{ fontSize: 15 }}>
                        {t("DetailOfKeyResult")}
                      </Typography>
                    </Grid>
                    <Grid item xl={9} lg={9} md={8} sm={12} xs={12}>
                      <TextField
                        name="tactics2"
                        value={values.tactics2}
                        onChange={handleInputChange}
                        fullWidth
                        disabled={disabled}
                        // multiline
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AutoFixHighIcon />
                            </InputAdornment>
                          ),
                        }}
                        label="Tactics"
                        placeholder="Tactics"
                        sx={{ paddingBottom: 2 }}
                        // inputRef={register({ required: true })}
                        // error={errors.keyResult2 ? true : false}
                        // helperText={errors.keyResult2 ? 'Enter Tactics' : ''}
                      />
                      <TextField
                        name="measureEvidence2"
                        value={values.measureEvidence2}
                        onChange={handleInputChange}
                        fullWidth
                        disabled={disabled}
                        // multiline
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <EscalatorIcon />
                            </InputAdornment>
                          ),
                        }}
                        label="Measure Evidence"
                        placeholder="Measure Evidence"
                        sx={{ paddingBottom: 2 }}
                        // inputRef={register({ required: true })}
                        // error={errors.keyResult2 ? true : false}
                        // helperText={errors.keyResult2 ? 'Enter Measure Evidence' : ''}
                      />
                      <TextField
                        name="commitQuality2"
                        value={values.commitQuality2}
                        onChange={handleInputChange}
                        fullWidth
                        disabled={disabled}
                        // multiline
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <EqualizerIcon />
                            </InputAdornment>
                          ),
                        }}
                        label="Commit Quality"
                        placeholder="Commit Quality"
                        sx={{ paddingBottom: 2 }}
                        // inputRef={register({ required: true })}
                        // error={errors.keyResult2 ? true : false}
                        // helperText={errors.keyResult2 ? 'Enter Measure Evidence' : ''}
                      />
                      <FormControl fullWidth>
                        <InputLabel>Select Type</InputLabel>
                        <Select
                          name="krType2"
                          value={values.krType2}
                          label="Select Type"
                          onChange={handleInputChange}
                          disabled={disabled}
                        >
                          {krType.map((item) => (
                            <MenuItem
                              key={item.id}
                              value={item.id == "None" ? "" : item.id}
                            >
                              {item.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>

          {/* ขึ้น key result ที่ 3 */}
          <Box style={numberKeyResult < 3 ? { display: "none" } : {}}>
            <hr className="hrTag" />
            <Grid
              container
              direction="row"
              style={{ paddingLeft: 30, paddingRight: 30 }}
            >
              <Grid item xl={4} lg={4} md={6} xs={8}>
                <Typography
                  sx={{ color: "primary.main" }}
                  className={classes.name}
                >
                  {t("The3KeyResult")}
                </Typography>
              </Grid>
              <Grid
                item
                xl={8}
                lg={8}
                md={6}
                xs={4}
                style={{ textAlign: "end" }}
              >
                {!disabled ? (
                  <>
                    {numberKeyResult < 3 ? (
                      <IconButton
                        aria-label="add-demand"
                        style={{
                          marginRight: 5,
                          backgroundColor: "#7dc581",
                          color: "#357a38",
                        }}
                      >
                        <AddIcon
                          onClick={() => {
                            setNumberKeyResult(numberKeyResult + 1);
                          }}
                        />
                      </IconButton>
                    ) : (
                      <IconButton
                        aria-label="add-demand"
                        style={{ marginLeft: 5 }}
                        disabled
                        color="primary"
                      >
                        <AddIcon />
                      </IconButton>
                    )}
                    {numberKeyResult != 1 ? (
                      <IconButton
                        aria-label="delete"
                        style={{
                          marginLeft: 5,
                          backgroundColor: "#dd99b1",
                          color: "#ab003c",
                        }}
                      >
                        <DeleteIcon
                          onClick={() =>
                            setNumberKeyResult(numberKeyResult - 1)
                          }
                        />
                      </IconButton>
                    ) : (
                      <IconButton
                        aria-label="delete"
                        style={{ marginLeft: 5 }}
                        disabled
                        color="primary"
                      >
                        <DeleteIcon />
                      </IconButton>
                    )}
                  </>
                ) : null}
              </Grid>
              <Grid container justifyContent="center" style={{ marginTop: 20 }}>
                <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                  <Grid container>
                    <Grid
                      item
                      xl={3}
                      lg={3}
                      md={4}
                      sm={12}
                      xs={12}
                      style={{ marginBottom: 20 }}
                    >
                      <Typography style={{ fontSize: 17, fontWeight: "bold" }}>
                        {t("MainData")}
                      </Typography>
                      <Typography style={{ fontSize: 15 }}>
                        {t("DescriptionOfKeyResult")}
                      </Typography>
                    </Grid>
                    <Grid item xl={9} lg={9} md={8} sm={12} xs={12}>
                      <TextField
                        name="keyResult3"
                        value={values.keyResult3}
                        onChange={handleInputChange}
                        fullWidth
                        disabled={disabled}
                        // multiline
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <DriveFileRenameOutlineIcon />
                            </InputAdornment>
                          ),
                        }}
                        label={t("KeyResult") + "3"}
                        placeholder={t("KeyResult") + "3"}
                        sx={{ paddingBottom: 2 }}
                      />
                      <TextField
                        name="description3"
                        value={values.description3}
                        onChange={handleInputChange}
                        fullWidth
                        disabled={disabled}
                        multiline
                        rows={2}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <NotesIcon />
                            </InputAdornment>
                          ),
                        }}
                        label="Description"
                        placeholder="Description"
                        sx={{ paddingBottom: 2 }}
                      />
                      {/*
                      <TextField
                        name="scope3"
                        value={values.scope3}
                        onChange={handleInputChange}
                        fullWidth
                        disabled={disabled}
                        // multiline
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Crop32Icon />
                            </InputAdornment>
                          ),
                        }}
                        label="Scope"
                        placeholder="Scope"
                      />
                      */}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
                  <Grid container>
                    <Grid
                      item
                      xl={3}
                      lg={3}
                      md={4}
                      sm={12}
                      xs={12}
                      style={{ marginBottom: 20 }}
                    >
                      <Typography style={{ fontSize: 17, fontWeight: "bold" }}>
                        {t("SecondData")}
                      </Typography>
                      <Typography style={{ fontSize: 15 }}>
                        {t("DetailOfKeyResult")}
                      </Typography>
                    </Grid>
                    <Grid item xl={9} lg={9} md={8} sm={12} xs={12}>
                      <TextField
                        name="tactics3"
                        value={values.tactics3}
                        onChange={handleInputChange}
                        fullWidth
                        disabled={disabled}
                        // multiline
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <AutoFixHighIcon />
                            </InputAdornment>
                          ),
                        }}
                        label="Tactics"
                        placeholder="Tactics"
                        sx={{ paddingBottom: 2 }}
                      />
                      <TextField
                        name="measureEvidence3"
                        value={values.measureEvidence3}
                        onChange={handleInputChange}
                        fullWidth
                        disabled={disabled}
                        // multiline
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <EscalatorIcon />
                            </InputAdornment>
                          ),
                        }}
                        label="Measure Evidence"
                        placeholder="Measure Evidence"
                        sx={{ paddingBottom: 2 }}
                      />
                      <TextField
                        name="commitQuality3"
                        value={values.commitQuality3}
                        onChange={handleInputChange}
                        fullWidth
                        disabled={disabled}
                        // multiline
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <EqualizerIcon />
                            </InputAdornment>
                          ),
                        }}
                        label="Commit Quality"
                        placeholder="Commit Quality"
                        sx={{ paddingBottom: 2 }}
                      />
                      <FormControl fullWidth>
                        <InputLabel>Select Type</InputLabel>
                        <Select
                          name="krType3"
                          value={values.krType3}
                          label="Select Type"
                          onChange={handleInputChange}
                          disabled={disabled}
                        >
                          {krType.map((item) => (
                            <MenuItem
                              key={item.id}
                              value={item.id == "None" ? "" : item.id}
                            >
                              {item.title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
          {disabled ? null : (
            <>
              <hr className="hrTag" />
              <Box sx={{ paddingRight: 4 }}>
                <Grid container justifyContent="flex-end" spacing={2}>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      className="saveButton"
                      type="submit"
                      onClick={handleOnSubmit}
                    >
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
        </form>
      </div>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </ContainerStyled>
  );
}

export default OrganizationPlan;
