import React, { Fragment, useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";
import {
  CardContent,
  CardMedia,
  Button,
  Typography,
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  FormLabel,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { useDispatch, useSelector } from "react-redux";
import { Formik } from "formik";
import Logo from "./assets/logo/unihr_logo.png";
import StyledCard from "./shared/general/Card";
import { styled } from "@mui/material/styles";

import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import ClearIcon from "@mui/icons-material/Clear";
import TranslateIcon from "@mui/icons-material/Translate";
import CircularProgress from "@mui/material/CircularProgress";

import { login, logout } from "../../actions/auth";
import TextFieldTheme from "./shared/general/TextFieldTheme";
import { Link, useLocation } from "react-router-dom";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import { useHistory, Redirect } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    display: "flex",
    justifyContent: "center",
  },
  rootCard: {
    width: 345,
    marginTop: 100,
  },
  media: {
    height: 155,
    padding: 20,
    paddingBottom: 0,
    margin: 10,
  },
  version: {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    marginTop: 10,
  },
  showPassword: {
    position: "absolute",
    justifyContent: "center",
  },
});

const validate = (values) => {
  const errors = {};
  if (!values.username) {
    errors.username = "Required";
  }

  if (!values.password) {
    errors.password = "Required";
  } else if (values.password.length < 8) {
    errors.password = "Contain at least 8 characters";
  }

  return errors;
};

const StyledIconButtonTranslate = styled(IconButton)(({}) => ({
  color: "#007AFE",
  backgroundColor: "transparent",
  border: "1px solid #007AFE",
  borderRadius: 8,
  padding: 8,
  marginRight: 8,
  fontSize: 28,
  // "& .MuiSvgIcon-root":{
  //   fontSize: 24,
  // }
  "&:hover": {
    transform: "scale(1.09) translateZ(0px)",
  },
}));

const LoginButton = withStyles((theme) => ({
  root: {
    marginTop: 16,
    borderColor: "#46cbe2",
    color: "#ffffff",
    backgroundColor: "#46cbe2",
    borderRadius: 8,
    "&:hover": {
      borderColor: "#247785",
      backgroundColor: "#247785",
    },
  },
}))(Button);

const PasswordField = ({ isSubmitting, values, handleChange, error }) => {
  const [showPassword, setShowPassword] = useState(false);
  const { t, i18n } = useTranslation();
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <Fragment>
      <TextFieldTheme
        style={{ width: "100%" }}
        disabled={isSubmitting}
        name="password"
        id="password"
        type={showPassword ? "text" : "password"}
        value={values.password}
        onChange={handleChange}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
              size="large"
            >
              {values.showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      ></TextFieldTheme>
    </Fragment>
  );
};

const StyledFormLabel = styled(FormLabel)({
  fontWeight: 600,
  fontSize: 14,
  color: "#637381",
  marginTop: 8,
  marginBottom: 8,
});

const LoginPage = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { user: currentUser } = useSelector((state) => state.auth);
  const [error, setError] = useState(false);

  const location = useLocation();
  const linkState = location.state;

  //Translator TH-EN
  const [anchorEl_Trans, setAnchorEl_Trans] = React.useState(null);
  const open_Trans = Boolean(anchorEl_Trans);

  const handleClick_Trans = (event) => {
    setAnchorEl_Trans(event.currentTarget);
  };
  const handleClose_Trans = () => {
    setAnchorEl_Trans(null);
  };

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if(currentUser){
      dispatch(logout());
    }
  },[]);

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    handleClose_Trans();
  };

  if(currentUser) {
    return <Redirect to="/home" />
  }

  const showForm = ({
    values,
    errors,
    handleChange,
    handleSubmit,
    isSubmitting,
  }) => {
    return (
      <form onSubmit={handleSubmit}>
        <StyledFormLabel component="legend">
          {`${t("Username")}`}
        </StyledFormLabel>
        <TextFieldTheme
          variant="outlined"
          fullWidth
          id="username"
          name="username"
          onChange={handleChange}
          value={values.username}
          autoFocus
          error={errors.username}
          disabled={isSubmitting}
        ></TextFieldTheme>

        {errors.username && (
          <Typography style={{ color: "#f44336" }}>
            {errors.username}
          </Typography>
        )}
        <StyledFormLabel component="legend">
          {`${t("Password")}`}
        </StyledFormLabel>
        <PasswordField
          isSubmitting={isSubmitting}
          values={values}
          handleChange={handleChange}
          name="password"
          error={errors.password}
        ></PasswordField>
        {errors.password && (
          <Typography style={{ color: "#f44336" }}>
            {errors.password}
          </Typography>
        )}
        {error ? (
          <Box style={{ marginTop: 2, marginBottom: 2 }}>
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={2} lg={2} md={2}>
                <IconButton
                  style={{ color: "#f44336", backgroundColor: "#ffebee" }}
                  size="small"
                >
                  <ClearIcon />
                </IconButton>
              </Grid>
              <Grid item xs={10} lg={10} md={10}>
                <Typography variant="body2" sx={{ color: "#f44336" }}>
                  {`${t("UnableLogIn")}`}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        ) : null}

        <Box sx={{ marginTop: "16px" }}>
          <Typography
            variant="body2"
            color="text.third"
            component={Link}
            to="/forget-password"
          >
            {`${t("ForgotPassword")}`}
          </Typography>
        </Box>

        <LoginButton
          type="submit"
          fullWidth
          variant="contained"
          disabled={isSubmitting}
          className={classes.submit}
        >
          {isSubmitting ? (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <CircularProgress
                color="inherit"
                size={20}
                sx={{ marginRight: 2 }}
              />
              {`${t("JustAMoment")}`}
            </Box>
          ) : (
            `${t("LogIn")}`
          )}
        </LoginButton>
        <Typography variant="body2" noWrap className={classes.version}>
          Version {process.env.REACT_APP_VERSION}
        </Typography>
      </form>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.rootCard}>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: 8,
          }}
        >
          <StyledIconButtonTranslate
            aria-label="translate"
            size="small"
            aria-controls={open_Trans ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open_Trans ? "true" : undefined}
            onClick={handleClick_Trans}
          >
            <TranslateIcon fontSize="small" />
          </StyledIconButtonTranslate>
          <Menu
            anchorEl={anchorEl_Trans}
            open={open_Trans}
            onClose={handleClose_Trans}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
          >
            <MenuItem onClick={() => changeLanguage("th")}>
        <img
          src="https://flagcdn.com/16x12/th.png"
          alt="ภาษาไทย"
          style={{ marginRight: "8px" }}
        />
        ภาษาไทย
      </MenuItem>
      <MenuItem onClick={() => changeLanguage("en")}>
        <img
          src="https://flagcdn.com/16x12/gb.png"
          alt="English"
          style={{ marginRight: "8px" }}
        />
        English
      </MenuItem>
      <MenuItem onClick={() => changeLanguage("la")}>
        <img
          src="https://flagcdn.com/16x12/la.png"
          alt="ລາວ"
          style={{ marginRight: "8px" }}
        />
        ລາວ
      </MenuItem>
      <MenuItem onClick={() => changeLanguage("kh")}>
        <img
          src="https://flagcdn.com/16x12/kh.png"
          alt="កម្ពុជា"
          style={{ marginRight: "8px" }}
        />
        កម្ពុជា
      </MenuItem>
      <MenuItem onClick={() => changeLanguage("mm")}>
        <img
          src="https://flagcdn.com/16x12/mm.png"
          alt="မြန်မာ"
          style={{ marginRight: "8px" }}
        />
        မြန်မာ
      </MenuItem>
      <MenuItem onClick={() => changeLanguage("cn")}>
        <img
          src="https://flagcdn.com/16x12/cn.png"
          alt="中国"
          style={{ marginRight: "8px" }}
        />
        中国
      </MenuItem>
      <MenuItem onClick={() => changeLanguage("kr")}>
        <img
          src="https://flagcdn.com/16x12/kr.png"
          alt="대한민국"
          style={{ marginRight: "8px" }}
        />
        대한민국
      </MenuItem>
      <MenuItem onClick={() => changeLanguage("vn")}>
        <img
          src="https://flagcdn.com/16x12/vn.png"
          alt="Việt Nam"
          style={{ marginRight: "8px" }}
        />
        Việt Nam
      </MenuItem>
          </Menu>
        </div>
        <StyledCard>
          {
            <CardMedia
              style={{ backgroundSize: "contain" }}
              className={classes.media}
              image={Logo}
              title="Contemplative Reptile"
            />
          }
          <CardContent>
            {/* <Typography variant="h4">{`${t("LogIn")}`}</Typography> */}
            {/* HOC */}
            <Formik
              onSubmit={(values, { setSubmitting }) => {
                dispatch(login(values.username, values.password))
                  .then((result) => {
                    if (linkState && linkState.redirect) {
                      history.push(linkState.redirect);
                      window.location.reload();
                    } else {
                      history.replace("/home");
                      window.location.reload();
                    }
                  })
                  .catch((err) => {
                    setSubmitting(false);
                    setError(true);
                  });
              }}
              initialValues={{
                username: "",
                password: "",
                showPassword: false,
              }}
              validate={validate}
            >
              {(props) => showForm(props)}
            </Formik>
          </CardContent>
        </StyledCard>
      </div>
    </div>
  );
};

export default LoginPage;
