import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import "./Evaluation.css";
import { styled } from "@mui/styles";
import { useSelector, useDispatch } from "react-redux";
import { getAllEmployees, getEmployeeProfile } from "../../../../../actions/employee";
import { putEvaluateMngIdvPlan } from "../../../../../actions/IndividualPlans";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Toolbar,
  Typography,
  Slider,
  TextField,
  Button,
  IconButton,
  InputAdornment,
  Container,
  Box,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

//icon
import VpnKeyRoundedIcon from "@mui/icons-material/VpnKeyRounded";
import ArticleIcon from "@mui/icons-material/Article";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const ContainerStyled = styled(Container)({
  marginTop: "100px",
});

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiSlider-root": {
      width: "80%",
    },
    "& .MuiSlider-marked": {
      marginTop: 24,
    },
    "& .MuiSlider-thumb": {
      width: "15px",
      height: "15px",
    },
    "& .MuiSlider-rail": {
      height: "5px",
    },
    "& .MuiSlider-track": {
      height: "5px",
    },
    "& .MuiSlider-mark": {
      height: "5px",
    },
  },
  imageEmp: {
    width: 160,
    height: 160,
    borderRadius: "50%",
    marginBottom: 16,
    
  },
  cardPlan: {
    marginTop: 24,
    borderRadius: "24px",
    paddingTop: 64,
    paddingLeft: 32,
    paddingRight: 32,
    paddingBottom: 64,
    background: "#fff",
  },
  achieved: {
    backgroundColor: "#e0f2f1",
    color: "#009688",
    width: "100px",
    borderRadius: "15px",
    padding: "5px",
    textAlign: "center",
  },
  notAchieved: {
    backgroundColor: "#f9dde0",
    color: "#c62828",
    width: "125px",
    borderRadius: "15px",
    padding: "5px",
    textAlign: "center",
  },
  employeeInfo: {
    
  },
  mainData: {
    fontSize: 19,
  },
  data: {
    fontSize: 20,
    fontWeight: "bold",
  },
}));

const marks = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 0.1,
    label: "0.1",
  },
  {
    value: 0.2,
    label: "0.2",
  },
  {
    value: 0.3,
    label: "0.3",
  },
  {
    value: 0.4,
    label: "0.4",
  },
  {
    value: 0.5,
    label: "0.5",
  },
  {
    value: 0.6,
    label: "0.6",
  },
  {
    value: 0.7,
    label: "0.7",
  },
  {
    value: 0.8,
    label: "0.8",
  },
  {
    value: 0.9,
    label: "0.9",
  },
  {
    value: 1.0,
    label: "1.0",
  },
];

function EvaluateManager() {
  const {t,i18n} = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  // const { result: empStore } = useSelector((state) => state.employees);
  const { result: employeeProfile } = useSelector((state) => state.employeeProfile);

  const [employee, setEmployee] = useState([]);
  const [values, setValues] = useState(location.state.planList);
  const [ratingValue, setRatingValue] = useState(0);
  const [moonShotRating, setMoonShotRating] = useState({
    rating1: 0,
    rating2: 0,
    rating3: 0,
  });
  const [moonShotHover, setMoonShotHover] = useState({
    hover1: 0,
    hover2: 0,
    hover3: 0,
  });

  function valuetext(value) {
    return `${value}`;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const onPutEvaluateMngIdvPlan = async (formData) => {
    const res = await dispatch(putEvaluateMngIdvPlan(formData));
    if(res) {
      history.push("/approval");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = {
      manager_KRScore1: values.manager_KRScore1,
      manager_Evidence1: values.manager_Evidence1,
      moonShotAfterKR1: values.moonShotAfterKR1,
      manager_KRScore2: values.manager_KRScore2,
      manager_Evidence2: values.manager_Evidence2,
      moonShotAfterKR2: values.moonShotAfterKR2,
      manager_KRScore3: values.manager_KRScore3,
      manager_Evidence3: values.manager_Evidence3,
      moonShotAfterKR3: values.moonShotAfterKR3,
      evaluateStatus: values.evaluateStatus,
      planIdvId: values.planIdvId,
    };

    console.log("formData", formData);

    onPutEvaluateMngIdvPlan(formData);
  };

  useEffect(() => {
    setValues({
      ...values,
      moonShotAfterKR1: moonShotRating.rating1,
    });
  }, [moonShotRating.rating1]);

  useEffect(() => {
    setValues({
      ...values,
      moonShotAfterKR2: moonShotRating.rating2,
    });
  }, [moonShotRating.rating2]);

  useEffect(() => {
    setValues({
      ...values,
      moonShotAfterKR3: moonShotRating.rating3,
    });
  }, [moonShotRating.rating3]);

  // useEffect(() => {
  //   if (empStore) {
  //     let empList = empStore.filter(
  //       (x) => x.idEmployees === location.state.planList.createByEmpId
  //     );

  //     setEmployee(empList[0]);
  //   }
  // }, [empStore]);

  useEffect(() => {
    dispatch(getAllEmployees());
    dispatch(getEmployeeProfile(location.state.planList.createByEmpId));
    //set status
    setValues({
      ...values,
      evaluateStatus: "Approved",
      dateManagerEvaluate: new Date(),
    });
  }, []);

  return (
    <ContainerStyled>
      <div className="card-section">
        <div className="header-section">
          <Grid container>
            <Grid item>
              <h3 className="header-topic">Manager Evaluate</h3>
            </Grid>
          </Grid>
        </div>
        {employeeProfile ? (
          <Toolbar>
            <Grid container justifyContent="space-between">
              <Grid item xs={12} md={5} lg={5} justifyContent="center">
                <Grid item xs={12}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    {employeeProfile.imageProfile === null || employeeProfile.image == "string" ? (
                      <AccountCircleIcon
                        sx={{ color: "#e0e0e0" }}
                        className={classes.imageEmp}
                      />
                    ) : (
                      <img
                        // src={`/images/employees/${employee.image}`}
                        src={employeeProfile.imageProfile}
                        className={classes.imageEmp}
                      />
                    )}
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography className={classes.data} align="center">
                    {`${employeeProfile.firstname_TH} ${employeeProfile.lastname_TH}`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    component="p"
                    align="center"
                    color="textSecondary"
                    className={classes.employeeInfo}
                  >
                    {employeeProfile.positionName}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    variant="subtitle1"
                    component="p"
                    align="center"
                    color="textSecondary"
                    className={classes.employeeInfo}
                  >
                    {employeeProfile.departmentName}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item={12} md={7} lg={7} justifyContent="center">
                <Grid container>
                  <Grid item xs={6} md={5} lg={5}>
                    <Typography color="textSecondary" variant="subtitle1">
                      ESY :{" "}
                    </Typography>
                    <Typography variant="subtitle1">{`${employee.monthsESY}/${employee.yearsESY}`}</Typography>
                  </Grid>
                  <Grid item xs={6} md={5} lg={5}>
                    <Typography color="textSecondary" variant="subtitle1">
                      Service :{" "}
                    </Typography>
                    <Typography variant="subtitle1">
                      {`${employee.serviceMonths}/${employee.serviceYears}`}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6} md={5} lg={5}>
                    <Typography color="textSecondary" variant="subtitle1">
                      TIG :{" "}
                    </Typography>
                    <Typography variant="subtitle1">{`${employee.monthsTIG}/${employee.yearsTIG}`}</Typography>
                  </Grid>
                  <Grid item xs={6} md={5} lg={5}>
                    <Typography color="textSecondary" variant="subtitle1">
                      TIP :{" "}
                    </Typography>
                    <Typography variant="subtitle1">{`${employee.monthsTIP}/${employee.yearsTIP}`}</Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6} md={5} lg={5}>
                    <Typography color="textSecondary" variant="subtitle1">
                      Div :{" "}
                    </Typography>
                    <Typography variant="subtitle1">
                      {employeeProfile.divisionName}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={5} lg={5}>
                    <Typography color="textSecondary" variant="subtitle1">
                      Depth :{" "}
                    </Typography>
                    <Typography variant="subtitle1">
                      {employeeProfile.departmentName}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6} md={5} lg={5}>
                    <Typography color="textSecondary" variant="subtitle1">
                      Sect :{" "}
                    </Typography>
                    <Typography variant="subtitle1">
                      {employeeProfile.sectionName}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={5} lg={5}>
                    <Typography color="textSecondary" variant="subtitle1">
                      Report To :{" "}
                    </Typography>
                    <Typography variant="subtitle1">
                      {`${employeeProfile.managerLV1_firstname_TH} ${employeeProfile.managerLV1_lastname_TH}`}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6} md={5} lg={5}>
                    <Typography color="textSecondary" variant="subtitle1">
                      Email :{" "}
                    </Typography>
                    <Typography variant="subtitle1">
                      {employeeProfile.email}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} md={5} lg={5}>
                    <Typography color="textSecondary" variant="subtitle1">
                      Tel. :{" "}
                    </Typography>
                    <Typography variant="subtitle1">
                      {employeeProfile.telephoneMobile}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        ) : (
          <Grid container justifyContent="center">
            <Typography style={{ fontSize: 27, fontWeight: "bold" }}>
              {t("NoData")}
            </Typography>
          </Grid>
        )}
      </div>
      <div className={classes.cardPlan}>
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <Grid container justifyContent="center" sx={{ marginBottom: 2 }}>
            <Grid item xs={10} sm={3} md={3} lg={3} xl={3}>
              <Typography className={classes.data}>Objective</Typography>
            </Grid>
            <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
              <Typography className={classes.mainData}>
                {values.objectiveName}
              </Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent="center" sx={{ marginBottom: 2 }}>
            <Grid item xs={10} sm={3} md={3} lg={3} xl={3}>
              <Typography className={classes.data}>Impact</Typography>
            </Grid>
            <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
              <Typography className={classes.mainData}>
                {values.impactName}
              </Typography>
            </Grid>
          </Grid>
          <hr className="hrTag" />
          <Grid container justifyContent="center" sx={{ marginBottom: 2 }}>
            <Grid item xs={10} sm={3} md={3} lg={3} xl={3}>
              <Typography className={classes.data}>Acheive</Typography>
            </Grid>
            <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
              <IconButton
                className={
                  values.achieveResult == "Achieved"
                    ? `${classes.achieved}`
                    : `${classes.notAchieved}`
                }
                size="large"
              >
                <Typography style={{ fontWeight: 600 }} variant="subtitle2">
                  {values.achieveResult}
                </Typography>
              </IconButton>
            </Grid>
          </Grid>
          <Grid container justifyContent="center" sx={{ marginBottom: 2 }}>
            <Grid item xs={10} sm={3} md={3} lg={3} xl={3}>
              <Typography className={classes.data}>Description</Typography>
            </Grid>
            <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
              <Typography className={classes.mainData}>
                {values.achieveDescription}
              </Typography>
            </Grid>
          </Grid>
          <hr className="hrTag" />
          <Grid container justifyContent="center" sx={{ marginBottom: 2 }}>
            <Grid item xs={10} sm={3} md={3} lg={3} xl={3}>
              <Typography className={classes.data}>
                {/* <VpnKeyRoundedIcon fontSize='large' className='svgKey' /> */}
                {t("KeyResult")} 1
              </Typography>
            </Grid>
            <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
              <Typography className={classes.mainData}>
                {values.keyResult1}
              </Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent="center" sx={{ marginBottom: 2 }}>
            <Grid item xs={10} sm={3} md={3} lg={3} xl={3}>
              <Typography className={classes.data}>Description</Typography>
            </Grid>
            <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
              <Typography className={classes.mainData}>
                {values.description1}
              </Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent="center" sx={{ marginBottom: 2 }}>
            <Grid item xs={10} sm={3} md={3} lg={3} xl={3}>
              <Typography className={classes.data}>Achieve</Typography>
            </Grid>
            <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
              <IconButton
                className={
                  values.achieveResultKR1 == "Achieved"
                    ? `${classes.achieved}`
                    : `${classes.notAchieved}`
                }
                size="large"
              >
                <Typography style={{ fontWeight: 600 }} variant="subtitle2">
                  {values.achieveResultKR1
                    ? values.achieveResultKR1
                    : "Not Achieved"}
                </Typography>
              </IconButton>
            </Grid>
          </Grid>
          <Grid container justifyContent="center" sx={{ marginBottom: 2 }}>
            <Grid item xs={10} sm={3} md={3} lg={3} xl={3}>
              <Typography className={classes.data}>Individual Score</Typography>
            </Grid>
            <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
              <div className="scoreCircle">
                {values.krScore1 ? values.krScore1 : 0}
              </div>
            </Grid>
          </Grid>
          <Grid container justifyContent="center" sx={{ marginBottom: 2 }}>
            <Grid item xs={10} sm={3} md={3} lg={3} xl={3}>
              <Typography className={classes.data}>
                Individual Evidence
              </Typography>
            </Grid>
            <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
              <Typography className={classes.mainData}>
                {values.evidence1}
              </Typography>
            </Grid>
          </Grid>
          <div style={{ height: "20px" }}></div>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{ marginBottom: 2 }}
          >
            <Grid item xs={10} sm={3} md={3} lg={3} xl={3}>
              <Typography className={classes.data}>Score</Typography>
            </Grid>
            <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
              <Slider
                value={values.manager_KRScore1}
                defaultValue={0}
                getAriaValueText={valuetext}
                aria-labelledby="discrete-slider-custom"
                step={0.1}
                min={0}
                max={1}
                marks={marks}
                onChange={(e, newValue) =>
                  setValues({ ...values, manager_KRScore1: newValue })
                }
              />
            </Grid>
          </Grid>
          <Grid container justifyContent="center" sx={{ marginBottom: 2 }}>
            <Grid item xs={10} sm={3} md={3} lg={3} xl={3}>
              <Typography className={classes.data}>Manager Evidence</Typography>
            </Grid>
            <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
              <TextField
                name="manager_Evidence1"
                value={values.manager_Evidence1}
                onChange={handleInputChange}
                fullWidth
                label="Manager Evidence"
                placeholder="Manager Evidence"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ArticleIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <div style={{ height: "50px" }}></div>
          <Grid container justifyContent="center" sx={{ marginBottom: 2 }}>
            <Grid item>
              <Typography className={classes.data}>Moon Shot</Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <Grid container item justifyContent="center">
              <div style={{ textAlign: "center" }}>
                <i
                  className={
                    ratingValue <
                    (moonShotHover.hover1 || moonShotRating.rating1)
                      ? "moonShot_Selected"
                      : "moonShot"
                  }
                  onMouseEnter={() => {
                    setMoonShotHover({
                      hover1: ratingValue + 1,
                    });
                  }}
                  onMouseLeave={() => {
                    setMoonShotHover({
                      hover1: 0,
                    });
                  }}
                  onClick={() => {
                    setMoonShotRating({
                      ...moonShotRating,
                      rating1:
                        moonShotRating.rating1 == 0 ? ratingValue + 1 : 0,
                    });
                  }}
                  class="fa-solid fa-space-station-moon"
                ></i>

                <p style={{ textAlign: "center" }}>Moon Shot Stamp</p>
              </div>
            </Grid>
          </Grid>
          {values.keyResult2 && (
            <>
              <hr className="hrTag" />
              <Grid container justifyContent="center" sx={{ marginBottom: 2 }}>
                <Grid item xs={10} sm={3} md={3} lg={3} xl={3}>
                  <Typography
                    className={classes.data}
                    //className="keyResult"
                    gutterBottom
                  >
                    <VpnKeyRoundedIcon fontSize="large" className="svgKey" />
                    {t("KeyResult")} 2
                  </Typography>
                </Grid>
                <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
                  <Typography className={classes.mainData}>
                    {values.keyResult2}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent="center" sx={{ marginBottom: 2 }}>
                <Grid item xs={10} sm={3} md={3} lg={3} xl={3}>
                  <Typography className={classes.data}>Description</Typography>
                </Grid>
                <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
                  <Typography className={classes.mainData}>
                    {values.description2}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent="center" sx={{ marginBottom: 2 }}>
                <Grid item xs={10} sm={3} md={3} lg={3} xl={3}>
                  <Typography className={classes.data}>Achieve</Typography>
                </Grid>
                <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
                  <IconButton
                    className={
                      values.achieveResultKR2 == "Achieved"
                        ? `${classes.achieved}`
                        : `${classes.notAchieved}`
                    }
                    size="large"
                  >
                    <Typography style={{ fontWeight: 600 }} variant="subtitle2">
                      {values.achieveResultKR2
                        ? values.achieveResultKR2
                        : "Not Achieved"}
                    </Typography>
                  </IconButton>
                </Grid>
              </Grid>
              <Grid container justifyContent="center" sx={{ marginBottom: 2 }}>
                <Grid item xs={10} sm={3} md={3} lg={3} xl={3}>
                  <Typography className={classes.data}>
                    Individual Score
                  </Typography>
                </Grid>
                <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
                  <div className="scoreCircle">
                    {values.krScore2 ? values.krScore2 : 0}
                  </div>
                </Grid>
              </Grid>
              <Grid container justifyContent="center" sx={{ marginBottom: 2 }}>
                <Grid item xs={10} sm={3} md={3} lg={3} xl={3}>
                  <Typography className={classes.data}>
                    Individual Evidence
                  </Typography>
                </Grid>
                <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
                  <Typography className={classes.mainData}>
                    {values.evidence2}
                  </Typography>
                </Grid>
              </Grid>
              <div style={{ height: "20px" }}></div>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ marginBottom: 2 }}
              >
                <Grid item xs={10} sm={3} md={3} lg={3} xl={3}>
                  <Typography className={classes.data}>Score</Typography>
                </Grid>
                <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
                  <Slider
                    value={values.manager_KRScore2}
                    defaultValue={0}
                    getAriaValueText={valuetext}
                    aria-labelledby="discrete-slider-custom"
                    step={0.1}
                    min={0}
                    max={1}
                    marks={marks}
                    onChange={(e, newValue) =>
                      setValues({ ...values, manager_KRScore2: newValue })
                    }
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent="center" sx={{ marginBottom: 2 }}>
                <Grid item xs={10} sm={3} md={3} lg={3} xl={3}>
                  <Typography className={classes.data}>
                    Manager Evidence
                  </Typography>
                </Grid>
                <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
                  <TextField
                    name="manager_Evidence2"
                    value={values.manager_Evidence2}
                    onChange={handleInputChange}
                    fullWidth
                    label="Manager Evidence"
                    placeholder="Manager Evidence"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ArticleIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <div style={{ height: "50px" }}></div>
              <Grid container justifyContent="center" sx={{ marginBottom: 2 }}>
                <Grid item>
                  <Typography className={classes.data}>Moon Shot</Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent="center">
                <Grid container item justifyContent="center">
                  <div style={{ textAlign: "center" }}>
                    <i
                      className={
                        ratingValue <
                        (moonShotHover.hover2 || moonShotRating.rating2)
                          ? "moonShot_Selected"
                          : "moonShot"
                      }
                      onMouseEnter={() => {
                        setMoonShotHover({
                          hover2: ratingValue + 1,
                        });
                      }}
                      onMouseLeave={() => {
                        setMoonShotHover({
                          hover2: 0,
                        });
                      }}
                      onClick={() => {
                        setMoonShotRating({
                          ...moonShotRating,
                          rating2:
                            moonShotRating.rating2 == 0 ? ratingValue + 1 : 0,
                        });
                      }}
                      class="fa-solid fa-space-station-moon"
                    ></i>

                    <p style={{ textAlign: "center" }}>Moon Shot Stamp</p>
                  </div>
                </Grid>
              </Grid>
            </>
          )}
          {values.keyResult3 && (
            <>
              <hr className="hrTag" />
              <Grid container justifyContent="center" sx={{ marginBottom: 2 }}>
                <Grid item xs={10} sm={3} md={3} lg={3} xl={3}>
                  <Typography
                    className={classes.data}
                    //className="keyResult"
                    gutterBottom
                  >
                    <VpnKeyRoundedIcon fontSize="large" className="svgKey" />
                    {t("KeyResult")} 3
                  </Typography>
                </Grid>
                <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
                  <Typography className={classes.mainData}>
                    {values.keyResult3}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent="center" sx={{ marginBottom: 2 }}>
                <Grid item xs={10} sm={3} md={3} lg={3} xl={3}>
                  <Typography className={classes.data}>Description</Typography>
                </Grid>
                <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
                  <Typography className={classes.mainData}>
                    {values.description1}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent="center" sx={{ marginBottom: 2 }}>
                <Grid item xs={10} sm={3} md={3} lg={3} xl={3}>
                  <Typography className={classes.data}>Achieve</Typography>
                </Grid>
                <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
                  <IconButton
                    className={
                      values.achieveResultKR3 == "Achieved"
                        ? `${classes.achieved}`
                        : `${classes.notAchieved}`
                    }
                    size="large"
                  >
                    <Typography style={{ fontWeight: 600 }} variant="subtitle2">
                      {values.achieveResultKR3
                        ? values.achieveResultKR3
                        : "Not Achieved"}
                    </Typography>
                  </IconButton>
                </Grid>
              </Grid>
              <Grid container justifyContent="center" sx={{ marginBottom: 2 }}>
                <Grid item xs={10} sm={3} md={3} lg={3} xl={3}>
                  <Typography className={classes.data}>
                    Individual Score
                  </Typography>
                </Grid>
                <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
                  <div className="scoreCircle">
                    {values.krScore3 ? values.krScore3 : 0}
                  </div>
                </Grid>
              </Grid>
              <Grid container justifyContent="center" sx={{ marginBottom: 2 }}>
                <Grid item xs={10} sm={3} md={3} lg={3} xl={3}>
                  <Typography className={classes.data}>
                    Individual Evidence
                  </Typography>
                </Grid>
                <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
                  <Typography className={classes.mainData}>
                    {values.evidence3}
                  </Typography>
                </Grid>
              </Grid>
              <div style={{ height: "20px" }}></div>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ marginBottom: 2 }}
              >
                <Grid item xs={10} sm={3} md={3} lg={3} xl={3}>
                  <Typography className={classes.data}>Score</Typography>
                </Grid>
                <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
                  <Slider
                    value={values.manager_KRScore3}
                    defaultValue={0}
                    getAriaValueText={valuetext}
                    aria-labelledby="discrete-slider-custom"
                    step={0.1}
                    min={0}
                    max={1}
                    marks={marks}
                    onChange={(e, newValue) =>
                      setValues({ ...values, manager_KRScore3: newValue })
                    }
                  />
                </Grid>
              </Grid>
              <Grid container justifyContent="center" sx={{ marginBottom: 2 }}>
                <Grid item xs={10} sm={3} md={3} lg={3} xl={3}>
                  <Typography className={classes.data}>
                    Manager Evidence
                  </Typography>
                </Grid>
                <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
                  <TextField
                    name="manager_Evidence3"
                    value={values.manager_Evidence3}
                    onChange={handleInputChange}
                    fullWidth
                    label="Manager Evidence"
                    placeholder="Manager Evidence"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <ArticleIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <div style={{ height: "50px" }}></div>
              <Grid container justifyContent="center" sx={{ marginBottom: 2 }}>
                <Grid item>
                  <Typography className={classes.data}>Moon Shot</Typography>
                </Grid>
              </Grid>
              <Grid container justifyContent="center">
                <Grid container item justifyContent="center">
                  <div style={{ textAlign: "center" }}>
                    <i
                      className={
                        ratingValue <
                        (moonShotHover.hover3 || moonShotRating.rating3)
                          ? "moonShot_Selected"
                          : "moonShot"
                      }
                      onMouseEnter={() => {
                        setMoonShotHover({
                          hover3: ratingValue + 1,
                        });
                      }}
                      onMouseLeave={() => {
                        setMoonShotHover({
                          hover3: 0,
                        });
                      }}
                      onClick={() => {
                        setMoonShotRating({
                          ...moonShotRating,
                          rating3:
                            moonShotRating.rating3 == 0 ? ratingValue + 1 : 0,
                        });
                      }}
                      class="fa-solid fa-space-station-moon"
                    ></i>

                    <p style={{ textAlign: "center" }}>Moon Shot Stamp</p>
                  </div>
                </Grid>
              </Grid>
            </>
          )}

          <hr className="hrTag" />
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                className="saveButton"
                type="submit"
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </ContainerStyled>
  );
}

export default EvaluateManager;
