import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Controller, useForm } from "react-hook-form";
import { Typography, Alert, MenuItem, Stack } from "@mui/material";
import DrawerCustom from "../../shared/general/Drawer";
import ButtonBlue from "../../shared/general/ButtonBlue";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import { useHistory } from "react-router-dom";
import { getAllCostcenterListByMonth } from "../../../../actions/payruns-export";
import { useDispatch, useSelector } from "react-redux";
import {
  getAffiliateOrganization,
  getCompanyOrganizationList,
} from "../../../../actions/company";
import SelectAffiliateCompany from "../../shared/general/SelectAffiliateCompany";
import { useTranslation } from "react-i18next";
import { CostcenterListMonthXlsx } from "./xlsx-export/payroll-costcenter-xlsx";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .GridTopicInput": {
    display: "flex",
    alignItems: "center",
  },
});

const PayrollCostCenter = (props) => {
  const dispatch = useDispatch();
  const { open, handleClose } = props;
  const { control } = useForm({});
  const { t, i18n } = useTranslation();
  const currentYear = new Date().getFullYear();

  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: affiliateOrganizationList } = useSelector(
    (state) => state.affiliateOrganization
  );

  const [noData, setNoData] = useState(true);
  const [isFetching, setIsFetching] = useState(true);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  // const [selectedType, setSelectedType] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [costcenterList, setCostcenterList] = useState(null);

  const handleOpenAlertError = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
    setSelectedYear(null);
    setSelectedMonth(null);
    // setSelectedType(null);
  };

  const onChangeCompany = (newValue) => {
    setSelectedCompany(newValue);
  };

  const fetchedData = async () => {
    try {
      setIsFetching(true);
      const response = await getAllCostcenterListByMonth(
        selectedCompany && selectedCompany.idCompany,
        selectedYear - 543,
        selectedMonth
      );
      if (response && response.data && response.data.length > 0) {
        setNoData(false);
        setIsFetching(false);
        setCostcenterList(response.data);
      } else {
        setNoData(true);
        setIsFetching(false);
        handleOpenAlertError();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (selectedCompany && selectedYear && selectedMonth) {
      fetchedData();
    } else {
      setIsFetching(false);
    }
  }, [selectedYear, selectedMonth, selectedCompany]);

  const handleFileExport = () => {
    // console.log(costcenterList);
    if (costcenterList && costcenterList.length > 0) {
      CostcenterListMonthXlsx(costcenterList);
    } else {
      handleOpenAlertError();
    }
  };

  const thaiYears = Array.from(
    { length: 6 },
    (_, index) => currentYear + 543 - index
  );

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  // const handleTypeChange = (event) => {
  //   setSelectedType(event.target.value);
  // };

  const months = [
    { value: "01", name: t("Months.January") },
    { value: "02", name: t("Months.February") },
    { value: "03", name: t("Months.March") },
    { value: "04", name: t("Months.April") },
    { value: "05", name: t("Months.May") },
    { value: "06", name:  t("Months.June") },
    { value: "07", name: t("Months.July") },
    { value: "08", name: t("Months.August") },
    { value: "09", name: t("Months.September") },
    { value: "10", name:  t("Months.October") },
    { value: "11", name: t("Months.November") },
    { value: "12", name: t("Months.December") },
  ];

  // const types = [
  //   { value: "departmentName", name: "Department" },
  //   { value: "sectionName", name: "Section" },
  // ];

  useEffect(() => {
    dispatch(getAffiliateOrganization());
  }, []);

  useEffect(() => {
    if (selectedCompany) {
      dispatch(
        getCompanyOrganizationList({
          idCompany: selectedCompany.idCompany,
          getSection: true,
        })
      );
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (
      userProfile &&
      affiliateOrganizationList &&
      affiliateOrganizationList.length > 0
    ) {
      let ownCompany = affiliateOrganizationList.find(
        (x) => x.idCompany === userProfile.idCompany
      );
      if (!ownCompany) {
        ownCompany = affiliateOrganizationList[0];
      }
      setSelectedCompany(ownCompany);
    }
  }, [userProfile, affiliateOrganizationList]);

  return (
    <DrawerCustom
      title={t("PayrollReportByCostCenter")}
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      <StyledRoot style={{ width: 400 }}>
        <Stack spacing={4}>
          {openAlert && (
            <Alert severity="error" onClose={() => handleCloseAlert()}>
              {t("NoData")}
            </Alert>
          )}
          <Stack spacing={2}>
            <Stack spacing={1}>
              <Typography
                color="text.third"
                fontSize="14px"
                fontWeight="600"
                marginBottom="8px"
              >
                {t("Company")}
              </Typography>
              <SelectAffiliateCompany
                options={affiliateOrganizationList}
                value={selectedCompany}
                disabled={isFetching || openAlert === true}
                onChange={(_, value) => {
                  onChangeCompany(value);
                }}
              />
            </Stack>
            <Stack spacing={1}>
              <Typography>{t("SelectYear")}</Typography>
              <Stack
                direction={"row"}
                justifyContent="space-between"
                alignItems={"center"}
                spacing={2}
              >
                <Controller
                  name="year"
                  control={control}
                  render={({ field }) => (
                    <TextFieldTheme
                      {...field}
                      variant="filled"
                      select
                      fullWidth
                      value={selectedYear}
                      onChange={handleYearChange}
                      disabled={openAlert === true}
                    >
                      {thaiYears.map((year) => (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </TextFieldTheme>
                  )}
                />
              </Stack>
            </Stack>
            <Stack spacing={1}>
              <Typography>{t("SelectMonth")}</Typography>
              <Stack
                direction={"row"}
                justifyContent="space-between"
                alignItems={"center"}
                spacing={2}
              >
                <Controller
                  name="year"
                  control={control}
                  render={({ field }) => (
                    <TextFieldTheme
                      {...field}
                      variant="filled"
                      select
                      fullWidth
                      value={selectedMonth}
                      onChange={handleMonthChange}
                      disabled={openAlert === true}
                    >
                      {months.map((month) => (
                        <MenuItem key={month} value={month.value}>
                          {month.name}
                        </MenuItem>
                      ))}
                    </TextFieldTheme>
                  )}
                />
              </Stack>
            </Stack>
            
          </Stack>

          <ButtonBlue
            variant="contained"
            disabled={isFetching || noData}
            onClick={() => handleFileExport()}
          >
            {t("Download")}
          </ButtonBlue>
        </Stack>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default PayrollCostCenter;
