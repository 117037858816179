import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";

import {
  Container,
  Typography,
  CardContent,
  TextField,
  Grid,
  Popper,
  Menu,
  MenuItem,
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

import StyledCard from "../../../shared/general/Card";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../shared/general/ButtonBlue";

import General from "./general";
import Welfare from "./welfare";
import PerDiem from "./per-diem";
import Mileage from "./mileage";
import FlexCoin from "./flex-coin";
import { getCoinBalance } from "../../../../../actions/flexCoin";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Advance from "./advance";
import { getEmployeesRoleAdmin } from "../../../../../actions/admins";

const StyledRoot = styled("div")({
  maxWidth: "1280px !important",
  margin: "auto",
  backgroundColor: "#FFFFFF !important",
  "& .MuiContainer-root": {
    paddingBottom: 24,
  },
  "& .head": {
    paddingTop: 8,
    marginBottom: 24,
    "& .MuiTypography-subtitle1": {
      fontWeight: 500,
    },
  },
});

const StyledCardContent = styled(CardContent)({
  padding: 24,
  "& .MuiFormControl-root": {
    marginBottom: 8,
  },
  "& .wrap-head": {
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
  },
  "& .wrap-row-sum": {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
});

const StyledAutocompleteFilled = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 48,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  "& .MuiPaper-root": {
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      paddingLeft: 8,
      margin: "0 8px",
      borderRadius: 8,
      "& .MuiTypography-root": {
        textAlign: "left",
        width: "100%",
      },
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const ITEM_HEIGHT = 48;

const ExpenseUserCreatePage = () => {
  const { t, i18n } = useTranslation();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: flexCoin } = useSelector((state) => state.flexCoin);
  const { result: employees } = useSelector((state) => state.employees);
  const { result: admins } = useSelector((state) => state.admins);
  const dispatch = useDispatch();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [listMenuTaxOrWithholding, setListMenuTaxOrWithholding] =
    React.useState(null);
  const open = Boolean(anchorEl);

  const typeExpense = [
    t("GeneralExpense"),
    t("Welfare"),
    t("Allowance"),
    "Mileage",
    "Flex Coin",
    t("advanceRequest"),
  ];

  const [formData, setFormData] = useState({
    nameExpense: "",
    typeExpenseName: typeExpense[0],
  });

  const handleCloseMenuTax = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    dispatch(getEmployeesRoleAdmin());
  }, []);

  return (
    <StyledRoot className="page">
      <Container maxWidth="xl">
        <div className="head">
          <Typography variant="h4" gutterBottom>
            {t("CreateExpense")}
          </Typography>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <StyledAutocompleteFilled
                fullWidth
                name="typeExpenseName"
                value={formData.typeExpenseName}
                options={typeExpense}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setFormData({ ...formData, ["typeExpenseName"]: newValue });
                  } else {
                    setFormData({
                      ...formData,
                      ["typeExpenseName"]: typeExpense[0],
                    });
                  }
                }}
                popupIcon={<i class="fa-light fa-chevron-down"></i>}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    name="typeExpenseName"
                  />
                )}
                PopperComponent={StyledPopper}
                noOptionsText={t("NoData")}
              />
            </Grid>
          </Grid>
        </div>
        <StyledCard>
          <StyledCardContent>
            {formData.typeExpenseName === t("GeneralExpense") && (
              <General typeExpense={typeExpense[0]} />
            )}
            {formData.typeExpenseName === t("Welfare") && <Welfare />}
            {formData.typeExpenseName === t("Allowance") && <PerDiem />}
            {formData.typeExpenseName === "Mileage" && <Mileage />}
            {formData.typeExpenseName === "Flex Coin" && <FlexCoin />}
            {formData.typeExpenseName === t("advanceRequest") && <Advance />}
          </StyledCardContent>
        </StyledCard>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenuTax}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {listMenuTaxOrWithholding &&
            listMenuTaxOrWithholding.length > 0 &&
            listMenuTaxOrWithholding.map((item) => (
              <MenuItem onClick={handleCloseMenuTax}>{item}</MenuItem>
            ))}
        </Menu>
      </Container>
    </StyledRoot>
  );
};

export default ExpenseUserCreatePage;
