import React, { Fragment, useEffect, useState } from "react";
import { Box, Grid, MenuItem, Pagination, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getEmployeeLeaveQuotaByIdEmp, getLeaveRequestAllYear, getLeaveWithdrawEmployeeYear } from "../../../../../../actions/employee";
import { useTranslation } from "react-i18next";
import utils, { getRoundNumber } from "../../../../../../utils";
import { getLeaveAvaiable } from "../../../../../../actions/leave";
import dayjs from "dayjs";
import ButtonBlue from "../../../../shared/general/ButtonBlue";

import AddRoundedIcon from "@mui/icons-material/AddRounded";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import leaveService from "../../../../../../services/leave.service";
import DialogNewLeave from "./DialogNewLeave";
import CardHistoryLeave from "./CardHistoryLeave";
import { getManager } from "../../../../../../actions/manager";

const CARD_PER_PAGE = 10

const LeaveTab = (props) => {

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: employeeProfile } = useSelector((state) => state.employeeProfile);
  const { result: leaveAvailable, isFetching: isFetchingLeaveAvailable } = useSelector((state) => state.leaveAvailable);
  const { result: leaveEmployeesList } = useSelector((state) => state.leaveEmployees);

  const [leaveRoundList, setLeaveRoundList] = useState([]);
  const [selectedLeaveRound, setSelectedLeaveRound] = useState("");
  const [leaveRoundConfig, setLeaveRoundConfig] = useState({
    date: "",
    start: "",
    end: ""
  })
  const [drawerConfig, setDrawerConfig] = useState({
    isOpen: false,
    isEdit: false,
    data: {},
  });
  const [page, setPage] = useState(1);

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  const onDrawerClose = () => {
    setDrawerConfig((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  const onDrawerOpen = () => {
    setDrawerConfig(prev => ({
      ...prev,
      isOpen: true,
      data: {}
    }))
  }

  const fetchingLeaveAvailable = () => {
    dispatch(getLeaveAvaiable({ date: selectedLeaveRound, idEmployees: employeeProfile.idEmployees }));
  }

  const fetchLeaveRound = (idCompany) => {
    leaveService.getLeaveRoundList({ idCompany: idCompany }).then(res => {
      setLeaveRoundList(res.data)
      const today = dayjs();
      const foundLeaveRound = res.data.find(item => (
        dayjs(today).isBetween(
          dayjs(item.start),
          dayjs(item.end),
          "[]"
        )
      ))
      if (foundLeaveRound) {
        setSelectedLeaveRound(foundLeaveRound.date);
        setLeaveRoundConfig(prev => ({
          ...prev,
          ...foundLeaveRound,
        }))
      } else {
        setSelectedLeaveRound("");
        setLeaveRoundConfig(prev => ({
          ...prev,
          date: "",
          start: "",
          end: "",
        }))
      }
    }).catch(error => {
      setLeaveRoundList([]);
      setSelectedLeaveRound("");
      setLeaveRoundConfig(prev => ({
        ...prev,
        date: "",
        start: "",
        end: "",
      }))
    })
  }

  useEffect(() => {
    dispatch(getManager());
    fetchLeaveRound(employeeProfile.idCompany);
  }, [])

  useEffect(() => {
    if (selectedLeaveRound) {
      fetchingLeaveAvailable();
      dispatch(
        getLeaveRequestAllYear({
          filter: dayjs(selectedLeaveRound).format("YYYY"),
          idEmployees: employeeProfile.idEmployees,
        })
      );
      dispatch(
        getLeaveWithdrawEmployeeYear({
          filter: dayjs(selectedLeaveRound).format("YYYY"),
          employees: employeeProfile.idEmployees,
        })
      );
    }
  }, [selectedLeaveRound])

  const renderValueLeaveRound = (value) => {
    const foundLeaveRoundValue = leaveRoundList.find(item => item.date === value)

    if (foundLeaveRoundValue) {
      return <Typography>
        {i18n.language === "th" ?
          `${dayjs(foundLeaveRoundValue.start).format("D MMMM BBBB")} - ${dayjs(foundLeaveRoundValue.end).format("D MMMM BBBB")}`
          :
          `${dayjs(foundLeaveRoundValue.start).locale(i18n.language).format("D MMMM YYYY")} - ${dayjs(foundLeaveRoundValue.end).locale(i18n.language).format("D MMMM YYYY")}`
        }
      </Typography>
    } else {
      return <Typography>-</Typography>
    }
  }

  return (
    <Box>

      <Grid container spacing={2}>

        <Grid item xs={12} container justifyContent="space-between" alignItems="center">
          <Box
            width="100%"
            maxWidth="min-content"
          >
            <TextFieldTheme
              value={selectedLeaveRound}
              onChange={(event) => {
                setSelectedLeaveRound(event.target.value)
                setLeaveRoundConfig(prev => ({
                  ...prev,
                  ...leaveRoundList.find(item => item.date === event.target.value)
                }))
              }}
              select
              // helperText={fieldState.error? fieldState.error.message: null}
              // error={fieldState.error? true: false}
              SelectProps={{
                size: "small",
                displayEmpty: true,
                renderValue: (selected) => {
                  if (selected.length === 0) {
                    return <Typography color="text.secondary">เลือกรอบการลา</Typography>
                  } else {
                    return renderValueLeaveRound(selected)
                  }
                }
              }}
            >
              <MenuItem value="" disabled>เลือกรอบการลา</MenuItem>
              {leaveRoundList.map(item => (
                <MenuItem value={item.date}>
                  {i18n.language === "th" ?
                    `${dayjs(item.start).format("D MMMM BBBB")} - ${dayjs(item.end).format("D MMMM BBBB")}`
                    :
                    `${dayjs(item.start).locale(i18n.language).format("D MMMM YYYY")} - ${dayjs(item.end).locale(i18n.language).format("D MMMM YYYY")}`
                  }
                </MenuItem>
              ))}
            </TextFieldTheme>
          </Box>

          <ButtonBlue
            startIcon={<AddRoundedIcon />}
            variant="contained"
            disabled={isFetchingLeaveAvailable}
            onClick={onDrawerOpen}
          >
            {t("AddLeaveTransaction")}
          </ButtonBlue>
        </Grid>

        {isFetchingLeaveAvailable && (
          <Grid item xs={12}>
            <Typography fontSize="20px" textAlign="center">{`${t("LoadingData")}...`}</Typography>
          </Grid>
        )}

        {!isFetchingLeaveAvailable && (
          <Fragment>
            <Grid item xs={12}>
              <TableContainer
                style={{
                  border: "1px solid rgba(0,0,0,0.1)",
                  borderRadius: "16px",
                }}
              >
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ minWidth: 250 }}>
                        {t("LeaveType")}
                      </TableCell>
                      <TableCell align="center" style={{ minWidth: 100 }}>
                        {t("LeaveRights")}/{t("AccumulatedRights")} ({t("Unit.Days")})
                      </TableCell>
                      <TableCell align="center" style={{ minWidth: 50 }}>
                        {t("Spent")} ({t("Unit.Days")})
                      </TableCell>
                      <TableCell align="center" style={{ minWidth: 50 }}>
                        {t("Remaining")} ({t("Unit.Days")})
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {leaveAvailable &&
                      leaveAvailable.map((leave) => {
                        return (
                          <TableRow key={leave.idLeaveType}>
                            <TableCell
                              style={{ display: "flex", minHeight: "24.5px" }}
                            >
                              <Box display="flex" alignItems="center" gap="8px">
                                <Typography>
                                  {i18n.resolvedLanguage === "th"
                                    ? leave.name
                                    : leave.name_EN ? leave.name_EN : leave.name}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell align="center">
                              {leave.leaveValue === null ? (
                                <Typography>{t("Unlimited")}</Typography>
                              ) : (
                                <Typography>
                                  {`${getRoundNumber(leave.leaveValue, 2, true)}`}
                                  {`${leave.carryValue ? "/" + `${getRoundNumber(leave.carryValue)}` : ""
                                    }`}
                                  {leave.isLeaveCompensate === 1 ? " "+t("Unit.ShortHours") : ""}
                                </Typography>
                              )}
                            </TableCell>
                            <TableCell align="center">{`${getRoundNumber(leave.leaveUsed)}`}</TableCell>
                            <TableCell align="center">
                              {leave.leaveValue === null ? (
                                <Typography>{t("Unlimited")}</Typography>
                              ) : (
                                <Typography align="center">
                                  {`${getRoundNumber(leave.leaveRemain)}`}
                                  {`${leave.carryRemain ? "/" + `${getRoundNumber(leave.carryRemain)}` : ""
                                    }`}
                                  {leave.isLeaveCompensate === 1 ? " "+t("Unit.ShortHours") : ""}
                                </Typography>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Fragment>
        )}

        <Grid item xs={12}>
          <Typography fontSize="22px" fontWeight="400">
            {t("LeaveRecord")}
          </Typography>
        </Grid>

        {leaveEmployeesList && leaveEmployeesList.length > 0 ? (
          <Grid item xs={12}>
            <Box display={"flex"} justifyContent={"flex-end"}>
              <Pagination
                count={Math.ceil(leaveEmployeesList.length / CARD_PER_PAGE)}
                page={page}
                onChange={handleChangePage}
                color="primary"
              />
            </Box>
            <Grid container spacing={1} marginTop={1}>
              {leaveEmployeesList
                .slice((page - 1) * CARD_PER_PAGE, page * CARD_PER_PAGE)
                .map((item) => (
                  <Grid item xs={12} sm={6} key={item.idLeave}>
                    <CardHistoryLeave
                      data={item}
                      // handleDeleteLeave={handleDeleteLeave}
                      // setDataWithDraw={setDataWithDraw}
                      // openConfirmDialog={openConfirmDialog}
                      // setOpenConfirmDialog={setOpenConfirmDialog}
                    />
                  </Grid>
                ))}
            </Grid>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <Typography color="text.secondary">ไม่มีประวัติการลางาน</Typography>
          </Grid>
        )}

      </Grid>
      <DialogNewLeave
        fetchingLeaveAvailable={fetchingLeaveAvailable}
        drawerConfig={drawerConfig}
        onClose={onDrawerClose}
      />
    </Box>
  )
}

export default LeaveTab;