import React, { useEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import { Link, withRouter, NavLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import NotificationsIcon from "@mui/icons-material/Notifications";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { useDispatch, useSelector } from "react-redux";

import TranslateIcon from "@mui/icons-material/Translate";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";

import Logo from "../../pages/assets/logo/unihr_logo.png";
import ButtonBlue from "../../pages/shared/general/ButtonBlue";
import DialogAnnouncement from "../../pages/dialogAnnouncement";

import { logout } from "../../../actions/auth";
import { getUserProfile } from "../../../actions/user";
import { getAnnouncementActiveByIdCompany } from "../../../actions/announcement";
import { Box } from "@mui/system";

import {
  AccountBalanceWallet,
  ShoppingCart,
  HistoryEdu,
  Bloodtype,
  NotificationsOutlined,
} from "@mui/icons-material";
import { Button } from "@mui/material";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getUserFullName } from "../../../utils/userData";
import dayjs from "dayjs";

const drawerWidth = 280;
const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
  },
  appBar: {
    backgroundColor: "#FFFFFF",
    zIndex: 1100,
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
  },
  drawerClose: {
    overflowX: "hidden",
    width: 48,
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: 16,
  },
  content: {
    flexGrow: 1,
    padding: 16,
  },
  sectionDesktop: {
    display: "none",
  },
  sectionMobile: {
    display: "flex",
  },
  grow: {
    flexGrow: 1,
  },
}));

const StyledAppBar = styled(AppBar)(({}) => ({
  backgroundColor: "transparent",
  boxShadow: "none",
  width: "100%",
  ["@media (min-width: 1200px)"]: {
    width: "calc(100% - 281px)",
    "&.menuClose": {
      width: "100% !important",
    },
  },

  "& .MuiToolbar-regular": {
    color: "#212b36",
    backgroundColor: "#ffffffcc",
    transition:
      "height 250ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, background-color 250ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
    backdropFilter: "blur(6px)",
    ["@media (min-width: 900px)"]: {
      height: 76,
    },

    "& .MuiContainer-root": {
      ["@media (max-width: 768px)"]: {
        padding: 0,
      },
      display: "flex",
      alignItems: "center",
      ["@media (min-width: 1200px)"]: {
        padding: 0,
        "&.menuClose": {
          paddingLeft: 24,
          paddingRight: 24,
        },
      },
      "& .headerAction": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "& .translate, .MuiDivider-root": {
          ["@media (max-width: 768px)"]: {
            display: "none",
          },
        },
      },
    },
  },
}));

const StyledIconButtonMenu = styled(IconButton)(({}) => ({
  //marginRight: 24,
}));

const StyledIconButtonTranslate = styled(IconButton)(({}) => ({
  color: "#007AFE",
  backgroundColor: "transparent",
  border: "1px solid #007AFE",
  borderRadius: 8,
  padding: 8,
  marginRight: 8,
  fontSize: 28,
  // "& .MuiSvgIcon-root":{
  //   fontSize: 24,
  // }
  "&:hover": {
    transform: "scale(1.09) translateZ(0px)",
  },
}));

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const Header = (props) => {
  const { open, matchesBig, isLoggedIn } = props;
  const classes = useStyles();

  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: announcementActive } = useSelector(
    (state) => state.announcementActive
  );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [cart, setCart] = useState([]);
  const [openAnnouncement, setOpenAnnouncement] = React.useState(false);

  //Translator TH-EN
  const [anchorEl_Trans, setAnchorEl_Trans] = React.useState(null);
  const open_Trans = Boolean(anchorEl_Trans);

  const handleClick_Trans = (event) => {
    setAnchorEl_Trans(event.currentTarget);
  };
  const handleClose_Trans = () => {
    setAnchorEl_Trans(null);
  };

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    handleClose_Trans();
  };

  const isMenuOpen = Boolean(anchorEl);
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCloseAnnouncement = () => {
    setOpenAnnouncement(false);
    localStorage.setItem("announcement", "close");
  };

  const handleOpenAnnouncement = () => {
    setOpenAnnouncement(true);
  };

  const logOut = () => {
    dispatch(logout());
  };

  useEffect(() => {
    const callCart = JSON.parse(localStorage.getItem("cart"));
    if (JSON.stringify(callCart) != JSON.stringify(cart)) {
      setCart(callCart);
    }
  }, [cart]);

  useEffect(() => {
    if (currentUser) {
      //Check JWT Token expiration
      const JWT = currentUser.accessToken;
      const jwtPayload = JSON.parse(window.atob(JWT.split(".")[1]));
      const jwtUnix = jwtPayload.exp * 1000;

      let todayUnix = Date.now();

      if (todayUnix > jwtUnix) {
        //if token expire force to logout
        logOut();
      }

      dispatch(getUserProfile(currentUser.id));
    }
  }, []);

  useEffect(() => {
    if (
      announcementActive &&
      announcementActive.length > 0 &&
      localStorage.getItem("announcement") !== "close"
    ) {
      handleOpenAnnouncement();
      localStorage.setItem("announcement", "open");
    }
  }, [announcementActive]);

  useEffect(() => {
    if (userProfile) {
      dispatch(getAnnouncementActiveByIdCompany(userProfile.idCompany));
    }
  }, [userProfile]);

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      id={menuId}
      open={isMenuOpen}
      onClose={handleMenuClose}
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
          },
          "&:before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            backgroundColor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
    >
      <Box
        style={{
          width: 178,
          padding: "6px 20px",
          // margin: "12px 0",
          display: "flex",
          alignItems: "center",
          gap: 8,
        }}
      >
        <div
          style={{
            minWidth: 0,
          }}
        >
          {userProfile && (
            <Typography
              variant="h6"
              style={{
                fontSize: 16,
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {getUserFullName(userProfile)}
            </Typography>
          )}
          <Typography
            color="text.secondary"
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontSize: "14px",
            }}
          >
            {userProfile && userProfile.email}
          </Typography>
        </div>
      </Box>
      <Divider />
      <MenuItem component={Link} to="/home" onClick={handleMenuClose}>
        <ListItemIcon>
          <HomeOutlinedIcon fontSize="small" />
        </ListItemIcon>
        {`${t("Homepage")}`}
      </MenuItem>

      {/* <MenuItem onClick={handleMenuClose} component={Link} to="/cart">
        <ListItemIcon>
          <Badge badgeContent={cart && cart.length} color="error">
            <ShoppingCart />
          </Badge>
        </ListItemIcon>
        Cart
      </MenuItem>
      <MenuItem onClick={handleMenuClose} component={Link} to="/history">
        <ListItemIcon>
          <HistoryEdu />
        </ListItemIcon>
        History
      </MenuItem>
      <MenuItem onClick={handleMenuClose} component={Link} to="/bookingHistory">
        <ListItemIcon>
          <Bloodtype />
        </ListItemIcon>
        Health check booking
      </MenuItem> */}
      {userProfile && userProfile.roles.includes("ROLE_USER") && (
        <MenuItem
          component={Link}
          to="/profile"
          onClick={handleMenuClose}
          disabled={userProfile && userProfile.idRole === 4}
        >
          <ListItemIcon>
            <PersonOutlineOutlinedIcon fontSize="small" />
          </ListItemIcon>
          {`${t("Profile")}`}
        </MenuItem>
      )}
      {announcementActive && (
        <MenuItem
          onClick={handleOpenAnnouncement}
          disabled={announcementActive.length === 0}
        >
          <ListItemIcon>
            <Badge badgeContent={announcementActive.length} color="error">
              <NotificationsOutlined fontSize="small" />
            </Badge>
          </ListItemIcon>
          {`${t("Announcement")}`}
        </MenuItem>
      )}
      {userProfile &&
        (userProfile.roles.includes("ROLE_ADMIN") ||
          userProfile.roles.includes("ROLE_MANAGER")) && (
          <MenuItem
            component={Link}
            to="/change-password"
            onClick={handleMenuClose}
          >
            <ListItemIcon>
              <LockOpenOutlinedIcon fontSize="small" />
            </ListItemIcon>
            {`${t("ChangePassword")}`}
          </MenuItem>
        )}
      <MenuItem component={Link} to="/landing" onClick={logOut}>
        <ListItemIcon>
          <LogoutIcon fontSize="small" />
        </ListItemIcon>
        {`${t("LogOut")}`}
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.root}>

      <ElevationScroll {...props}>
        <StyledAppBar
          className={clsx({ menuClose: open === false && matchesBig })}
        >
          <Toolbar>
            <Container
              className={clsx({ menuClose: open === false && matchesBig })}
            >
              <StyledIconButtonMenu
                aria-label="open drawer"
                onClick={props.handleDrawerOpen}
                edge="start"
                size="large"
              >
                <MenuIcon />
              </StyledIconButtonMenu>
              <Link to={"/home"}>
                <img src={Logo} alt="Uni HR logo" width={60} />
              </Link>
              <div style={{ flexGrow: 1 }}></div>
              <div className="headerAction">
                <div>
                  <StyledIconButtonTranslate
                    aria-label="translate"
                    size="small"
                    aria-controls={open_Trans ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open_Trans ? "true" : undefined}
                    onClick={handleClick_Trans}
                  >
                    <TranslateIcon fontSize="small" />
                  </StyledIconButtonTranslate>
                  <Menu
                    anchorEl={anchorEl_Trans}
                    open={open_Trans}
                    onClose={handleClose_Trans}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem onClick={() => changeLanguage("th")}>
        <img
          src="https://flagcdn.com/16x12/th.png"
          alt="ภาษาไทย"
          style={{ marginRight: "8px" }}
        />
        ภาษาไทย
      </MenuItem>
      <MenuItem onClick={() => changeLanguage("en")}>
        <img
          src="https://flagcdn.com/16x12/gb.png"
          alt="English"
          style={{ marginRight: "8px" }}
        />
        English
      </MenuItem>
      <MenuItem onClick={() => changeLanguage("la")}>
        <img
          src="https://flagcdn.com/16x12/la.png"
          alt="ລາວ"
          style={{ marginRight: "8px" }}
        />
        ລາວ
      </MenuItem>
      <MenuItem onClick={() => changeLanguage("kh")}>
        <img
          src="https://flagcdn.com/16x12/kh.png"
          alt="កម្ពុជា"
          style={{ marginRight: "8px" }}
        />
        កម្ពុជា
      </MenuItem>
      <MenuItem onClick={() => changeLanguage("mm")}>
        <img
          src="https://flagcdn.com/16x12/mm.png"
          alt="မြန်မာ"
          style={{ marginRight: "8px" }}
        />
        မြန်မာ
      </MenuItem>
      <MenuItem onClick={() => changeLanguage("cn")}>
        <img
          src="https://flagcdn.com/16x12/cn.png"
          alt="中国"
          style={{ marginRight: "8px" }}
        />
        中国
      </MenuItem>
      <MenuItem onClick={() => changeLanguage("kr")}>
        <img
          src="https://flagcdn.com/16x12/kr.png"
          alt="대한민국"
          style={{ marginRight: "8px" }}
        />
        대한민국
      </MenuItem>
      <MenuItem onClick={() => changeLanguage("vn")}>
        <img
          src="https://flagcdn.com/16x12/vn.png"
          alt="Việt Nam"
          style={{ marginRight: "8px" }}
        />
        Việt Nam
      </MenuItem>
                    
                  </Menu>
                </div>
                <Divider
                  style={{ margin: "0 16px", height: 24 }}
                  orientation="vertical"
                />
                {isLoggedIn ? (
                  <div>
                    <IconButton
                      edge="end"
                      aria-label="account of current user"
                      aria-controls={menuId}
                      aria-haspopup="true"
                      color="inherit"
                      onClick={(event) => setAnchorEl(event.currentTarget)}
                      size="large"
                    >
                      <Avatar
                        alt={
                          userProfile &&
                          `${userProfile.firstname_EN} ${userProfile.lastname_EN}`
                        }
                        src={(userProfile && userProfile.imageProfile) || ""}
                      />
                    </IconButton>
                  </div>
                ) : (
                  <div>
                    <ButtonBlue variant="outlined">Contact Us</ButtonBlue>
                    <ButtonBlue
                      variant="contained"
                      style={{ marginLeft: 8 }}
                      component={NavLink}
                      to="/login"
                    >
                      Sign in
                    </ButtonBlue>
                  </div>
                )}
              </div>
            </Container>
          </Toolbar>
        </StyledAppBar>
      </ElevationScroll>
      {renderMenu}
      {announcementActive && announcementActive.length > 0 ? (
        <DialogAnnouncement
          announcement={announcementActive}
          open={openAnnouncement}
          handleClose={handleCloseAnnouncement}
        />
      ) : null}
    </div>
  );
};

export default withRouter(Header);
