import React, { Fragment, useState, useEffect } from "react";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import {
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Link,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  Autocomplete,
} from "@mui/material";

//Translator TH-EN
import { useTranslation } from "react-i18next";

import DrawerCustom from "../../../shared/general/Drawer";
import AddIcon from "../../../assets/add.png";
import DragDropImage from "./dragdropImage";
import FileSelected from "./file-selected";
import ShowResponse from "./show-response";

import {
  dumpExcelAddressEmployees,
  dumpExcelBirthdayEmployees,
  dumpExcelBookBankEmployees,
  dumpExcelContractTermainatoinEmployees,
  dumpExcelCostEmployees,
  dumpExcelEducation1Employees,
  dumpExcelEducation2Employees,
  dumpExcelEducation3Employees,
  dumpExcelEmailEmployees,
  dumpExcelEmergencyContactEmployees,
  dumpExcelEmployees,
  dumpExcelGenderEmployees,
  dumpExcelManagerLV1Employees,
  dumpExcelManagerLV2Employees,
  dumpExcelNationalityEmployees,
  dumpExcelNicknameEmployees,
  dumpExcelPassportEmployees,
  dumpExcelResignEmployees,
  dumpExcelStatusEmployees,
  dumpExcelTelephoneEmployees,
  dumpExcelTimeEmployees,
  dumpExcelWorkPermitEmployees,
  getAllEmployees,
  uploadExcelEmployeeFreelance,
  uploadExcelEmployees,
  uploadExcelManager2UserManager,
  dumpExcelReligionEmployees,
} from "../../../../../actions/employee";
import { uploadExcelLeaveEmployees } from "../../../../../actions/leave";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import { getAffiliateCompany } from "../../../../../actions/affiliate";
import { uploadExcelAdditionDeductionRecurring } from "../../../../../actions/uploadExcel";

const StyledRoot = styled("div")({
  width: 550,
  padding: 24,
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
});

const StyleSelect = styled(FormControl)({
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    padding: "0px 12px",
    "& .MuiSelect-select": {
      padding: "13.5px 0px",
    },
  },
});

const StyledImportFile = styled("div")({
  maxWidth: 450,
  margin: "auto",
  marginBottom: 24,
  marginTop: 24,
  "& .dropzone-upload-file": {
    textAlign: "center",
    backgroundColor: "#F9F9FB",
    border: "1px solid #D0D0D0",
    borderStyle: "dashed",
    borderRadius: 8,
    height: 200,
    display: "flex",
    justifyContent: "center",
    "& .inner-dropzone": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      "& img": {
        marginBottom: 16,
      },
    },
  },
});

const StyledLoading = styled("div")({
  height: 200,
  maxWidth: 450,
  margin: "auto",
  marginBottom: 24,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
});

const StyledRadio = styled((props) => <Radio {...props} />)(
  ({ theme, checked }) => ({
    "&.Mui-checked": {
      color: "#46CBE2",
    },
  })
);

const dumpList = [
  {
    id: 1,
    name: "ข้อมูลพนักงาน",
    disabled: false,
  },
  {
    id: 2,
    name: "รูปพนักงาน",
    disabled: false,
  },
  {
    id: 3,
    name: "ผลงาน",
    disabled: true,
  },
  {
    id: 4,
    name: "หัวหน้างาน",
    disabled: true,
  },
  {
    id: 5,
    name: "ประสบการณ์พนักงาน",
    disabled: true,
  },
  {
    id: 6,
    name: "ปรับระดับ",
    disabled: true,
  },
  {
    id: 7,
    name: "ปรับค่าจ้าง",
    disabled: false,
  },
  {
    id: 8,
    name: "Additions/Deductions",
    disabled: false,
  },
  {
    id: 9,
    name: "วันลาพนักงาน",
    disabled: false,
  },
  {
    id: 10,
    name: "ชื่อเล่น",
    disabled: false,
  },
  {
    id: 11,
    name: "เพศ",
    disabled: false,
  },
  {
    id: 12,
    name: "สัญชาติ",
    disabled: false,
  },
  {
    id: 13,
    name: "สถานะ",
    disabled: false,
  },
  {
    id: 14,
    name: "เลขพาสปอร์ต",
    disabled: false,
  },
  {
    id: 15,
    name: "เลขที่ใบอนุญาตทำงาน",
    disabled: false,
  },
  {
    id: 16,
    name: "วันเกิด",
    disabled: false,
  },
  {
    id: 17,
    name: "ที่อยู่",
    disabled: false,
  },
  {
    id: 18,
    name: "เบอร์โทรศัพท์",
    disabled: false,
  },
  {
    id: 19,
    name: "Email",
    disabled: false,
  },
  {
    id: 20,
    name: "ผู้ติดต่อ",
    disabled: false,
  },
  {
    id: 21,
    name: "บันทึกเวลาทำงาน",
    disabled: false,
  },
  {
    id: 22,
    name: "ผู้จัดการลำดับที่ 1",
    disabled: false,
  },
  {
    id: 23,
    name: "ผู้จัดการลำดับที่ 2",
    disabled: false,
  },
  {
    id: 24,
    name: "ประเภทการทำงาน",
    disabled: true,
  },
  {
    id: 26,
    name: "วันหมดสัญญา",
    disabled: false,
  },
  {
    id: 27,
    name: "วันลาออก",
    disabled: false,
  },
  {
    id: 28,
    name: "การศึกษา1",
    disabled: false,
  },
  {
    id: 29,
    name: "การศึกษา2",
    disabled: false,
  },
  {
    id: 30,
    name: "การศึกษา3",
    disabled: false,
  },
  {
    id: 31,
    name: "การประเมินประจำปี",
    disabled: true,
  },
  {
    id: 32,
    name: "บริษัทที่เรียกเก็บเงิน",
    disabled: false,
  },
  {
    id: 33,
    name: "ข้อมูลธนาคาร",
    disabled: false,
  },
  {
    id: 34,
    name: "ผลงานย้อนหลัง",
    disabled: false,
  },
  {
    id: 35,
    name: "ข้อมูลครอบครัว",
    disabled: false,
  },
  {
    id: 36,
    name: "ประสบการณ์ทำงาน",
    disabled: false,
  },
  {
    id: 37,
    name: "ปรับระดับ",
    disabled: false,
  },
  {
    id: 38,
    name: "โอนย้าย",
    disabled: false,
  },
  {
    id: 39,
    name: "การศึกษา",
    disabled: false,
  },
  {
    id: 40,
    name: "กองทุนสำรองเลี้ยงชีพ",
    disabled: false,
  },
];

const DialogUpload = (props) => {
  const { open, handleClose, setStatusResult, isReadOnly } = props;
  const dispatch = useDispatch();
  const today = dayjs().toDate();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: affiliateList } = useSelector((state) => state.affiliate);
  const [dumpSelect, setDumpSelect] = React.useState("");
  const [selectedDumpList, setSelectedDumpList] = React.useState(null);
  const [fileSelected, setFileSelected] = useState(null);
  const [companySelected, setCompanySelected] = useState(null);
  const [formFileSelected, setFormFileSelected] = useState(null);
  const [openLoading, setOpenLoading] = useState(false);
  const [errorResponse, setErrorResponse] = useState(null);
  const [waitingAPI, setWaitingAPI] = useState(false);
  const [type, setType] = useState(null);
  const { t, i18n } = useTranslation();

  const [selectedCompany, setSelectedCompany] = useState(null);

  useEffect(() => {
    setDumpSelect("");
    setErrorResponse(null);
    setFileSelected(null);
    setCompanySelected(null);
    setFormFileSelected(null);
    setOpenLoading(false);
    setSelectedDumpList("historyDumpList");
    dispatch(getAffiliateCompany());
  }, [open]);

  useEffect(() => {
    setDumpSelect("");
    setErrorResponse(null);
    setFileSelected(null);
    setCompanySelected(null);
    setFormFileSelected(null);
    setOpenLoading(null);
  }, [selectedDumpList]);

  const handleChangeDumpSelect = (event) => {
    setDumpSelect(event.target.value);
    setSelectedCompany(null);
  };

  const handleChangeDumpListSelect = (event) => {
    setSelectedDumpList(event.target.value);
    setDumpSelect("");
    if (event.target.value === "historyDumpList") {
      setType("history");
    } else if (event.target.value === "editDumpList") {
      setType("edit");
    } else if (event.target.value === "insertDumpList") {
      setType("insert");
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    onDrop: (acceptedFiles) => {
      const formData = new FormData();
      acceptedFiles.map((file) => {
        formData.append("file", file);
        formData.append("selected", companySelected);
        formData.append(
          "idCompany",
          selectedCompany ? selectedCompany.idCompany : null
        );
      });
      setFormFileSelected(formData);
      setFileSelected({
        name: acceptedFiles[0].name,
        size: acceptedFiles[0].size,
      });
    },
    maxFiles: 1,
  });

  const historyDumpList = [
    {
      id: 6,
      name: `${t("EmployeePersonalLevel")}`,
      disabled: false,
    },
    {
      id: 9,
      name: `${t("LeaveDay")}`,
      disabled: false,
    },
    {
      id: 35,
      name: `${t("FamilyInfo")}`,
      disabled: false,
    },
    {
      id: 36,
      name: `${t("WorkExperience")}`,
      disabled: false,
    },
    {
      id: 39,
      name: `${t("Education")}`,
      disabled: false,
    },
  ];

  const editDumpList = [
    {
      id: 2,
      name: `${t("EmployeeImage")}`,
      disabled: false,
    },
    {
      id: 3,
      name: `${t("Performance")}`,
      disabled: true,
    },
    {
      id: 4,
      name: `${t("Manager")}`,
      disabled: false,
    },
    {
      id: 7,
      name: `${t("Wages")}`,
      disabled: false,
    },
    {
      id: 10,
      name: `${t("Nickname")}`,
      disabled: false,
    },
    {
      id: 11,
      name: `${t("Gender")}`,
      disabled: false,
    },
    {
      id: 12,
      name: `${t("Nationality")}`,
      disabled: false,
    },
    {
      id: 13,
      name: `${t("MaritalStatus")}`,
      disabled: false,
    },
    {
      id: 14,
      name: `${t("PassportNumber")}`,
      disabled: false,
    },
    {
      id: 15,
      name: `${t("WorkPermitNumber")}`,
      disabled: false,
    },
    {
      id: 16,
      name: `${t("BirthDate")}`,
      disabled: false,
    },
    {
      id: 17,
      name: `${t("Address")}`,
      disabled: false,
    },
    {
      id: 18,
      name: `${t("PhoneNumber")}`,
      disabled: false,
    },
    {
      id: 19,
      name: `${t("Email")}`,
      disabled: false,
    },
    {
      id: 20,
      name: `${t("EmergencyContact")}`,
      disabled: false,
    },
    {
      id: 21,
      name: `${t("RecordAttendance")}`,
      disabled: false,
    },
    {
      id: 24,
      name: `${t("WorkType")}`,
      disabled: true,
    },
    {
      id: 26,
      name: `${t("TerminateDate")}`,
      disabled: false,
    },
    {
      id: 27,
      name: `${t("ResignDate")}`,
      disabled: false,
    },
    // {
    //   id: 28,
    //   name: "การศึกษา1",
    //   disabled: false,
    // },
    // {
    //   id: 29,
    //   name: "การศึกษา2",
    //   disabled: false,
    // },
    // {
    //   id: 30,
    //   name: "การศึกษา3",
    //   disabled: false,
    // },
    {
      id: 31,
      name: `${t("AnnualEvaluation")}`,
      disabled: true,
    },
    {
      id: 32,
      name: `${t("CompanyCharge")}`,
      disabled: false,
    },
    {
      id: 33,
      name: `${t("BankInfo")}`,
      disabled: false,
    },
    {
      id: 34,
      name: `${t("ThePast5YearsPerformance")}`,
      disabled: false,
    },
    {
      id: 37,
      name: `${t("EmployeePersonalLevel")}`,
      disabled: false,
    },
    {
      id: 40,
      name: `${t("ProvidentFund")}`,
      disabled: false,
    },{
      id: 44,
      name: `${t("Religion")}`,
      disabled: false,
    },
  ];

  const insertDumpList = [
    {
      id: 1,
      name: `${t("EmployeeInformation")}`,
      disabled: false,
    },
    {
      id: 42,
      name: `${t("EmployeeInformation")} 40(2)`,
      disabled: false,
    },
    {
      id: 3,
      name: `${t("Performance")}`,
      disabled: true,
    },
    {
      id: 22,
      name: `${t("EmployeeInformation")} 1`,
      disabled: false,
    },
    {
      id: 23,
      name: `${t("EmployeeInformation")} 2`,
      disabled: false,
    },
    // {
    //   id: 37,
    //   name: "ปรับระดับ",
    //   disabled: true,
    // },
    {
      id: 38,
      name: `${t("JobTransfer")}`,
      disabled: false,
    },
    {
      id: 40,
      name: `${t("ProvidentFund")}`,
      disabled: false,
    },
    {
      id: 41,
      name: `${t("AdjustSalary")}`,
      disabled: false,
    },
    {
      id: 8,
      name: `${t("Addition/DeductionRecurring")}`,
      disabled: false,
    },
    {
      id: 43,
      name: `${t("ChangeManagerToUserManager")}`,
      disabled: false,
    },
  ];

  const handleClickUpload = async () => {
    setOpenLoading(true);
    // if (dumpSelect === 1) {
    //   const result = await dispatch(addUsersFirstTime(formFileSelected));
    //   if (result) {
    //     setErrorResponse(result);
    //     setOpenLoading(false);
    //   }
    // } else if (dumpSelect === 2) {
    //   const result = await dispatch(addUsers(formFileSelected));
    //   if (result) {
    //     setErrorResponse(result);
    //     setOpenLoading(false);
    //   }
    // } else if (dumpSelect === 8) {
    //   const result = await dispatch(
    //     addEmployeeAdditionDeduction(formFileSelected)
    //   );
    // } else if (dumpSelect === 7) {
    //   const result = await dispatch(employeeSalary(formFileSelected));
    //   if (result) {
    //     setErrorResponse(result);
    //     // handleChangeAlertType("success");
    //     // handleOpenAlert(true);
    //     // handleClose();
    //   }
    // }
    if (dumpSelect === 1) {
      const formData = formFileSelected;
      formData.set(
        "idCompany",
        selectedCompany ? selectedCompany.idCompany : null
      );
      const result = await dispatch(uploadExcelEmployees(formData));
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
        await dispatch(getAllEmployees());
      }
    } else if (dumpSelect === 4 || dumpSelect === 6) {
      const result = await dispatch(
        dumpExcelEmployees(formFileSelected, {
          type: type,
          document: template[`template${dumpSelect}`].sheet[0],
        })
      );
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
        await dispatch(getAllEmployees());
      }
    } else if (dumpSelect === 7) {
      const result = await dispatch(
        dumpExcelEmployees(formFileSelected, {
          type: type,
          document: template[`template${dumpSelect}`].sheet[0],
        })
      );
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
        await dispatch(getAllEmployees());
      }
    } else if (dumpSelect === 8) {
      const result = await dispatch(uploadExcelAdditionDeductionRecurring(formFileSelected))
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else if (dumpSelect === 9) {
      const result = await dispatch(
        uploadExcelLeaveEmployees(formFileSelected)
      );
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
        await dispatch(getAllEmployees());
      }
    } else if (dumpSelect === 10) {
      const result = await dispatch(
        dumpExcelNicknameEmployees(formFileSelected)
      );
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else if (dumpSelect === 11) {
      const result = await dispatch(dumpExcelGenderEmployees(formFileSelected));
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else if (dumpSelect === 12) {
      const result = await dispatch(
        dumpExcelNationalityEmployees(formFileSelected)
      );
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else if (dumpSelect === 13) {
      const result = await dispatch(dumpExcelStatusEmployees(formFileSelected));
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else if (dumpSelect === 14) {
      const result = await dispatch(
        dumpExcelPassportEmployees(formFileSelected)
      );
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else if (dumpSelect === 15) {
      const result = await dispatch(
        dumpExcelWorkPermitEmployees(formFileSelected)
      );
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else if (dumpSelect === 16) {
      const result = await dispatch(
        dumpExcelBirthdayEmployees(formFileSelected)
      );
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else if (dumpSelect === 17) {
      const result = await dispatch(
        dumpExcelAddressEmployees(formFileSelected)
      );
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else if (dumpSelect === 18) {
      const result = await dispatch(
        dumpExcelTelephoneEmployees(formFileSelected)
      );
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else if (dumpSelect === 19) {
      const result = await dispatch(dumpExcelEmailEmployees(formFileSelected));
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else if (dumpSelect === 20) {
      const result = await dispatch(
        dumpExcelEmergencyContactEmployees(formFileSelected)
      );
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else if (dumpSelect === 21) {
      const result = await dispatch(dumpExcelTimeEmployees(formFileSelected));
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else if (dumpSelect === 22) {
      const result = await dispatch(
        dumpExcelManagerLV1Employees(formFileSelected)
      );
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else if (dumpSelect === 23) {
      const result = await dispatch(
        dumpExcelManagerLV2Employees(formFileSelected)
      );
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else if (dumpSelect === 26) {
      const result = await dispatch(
        dumpExcelContractTermainatoinEmployees(formFileSelected)
      );
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else if (dumpSelect === 27) {
      const result = await dispatch(dumpExcelResignEmployees(formFileSelected));
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else if (dumpSelect === 28) {
      const result = await dispatch(
        dumpExcelEducation1Employees(formFileSelected)
      );
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else if (dumpSelect === 29) {
      const result = await dispatch(
        dumpExcelEducation2Employees(formFileSelected)
      );
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else if (dumpSelect === 30) {
      const result = await dispatch(
        dumpExcelEducation3Employees(formFileSelected)
      );
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else if (dumpSelect === 32) {
      const result = await dispatch(dumpExcelCostEmployees(formFileSelected));
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else if (dumpSelect === 33) {
      const result = await dispatch(
        dumpExcelBookBankEmployees(formFileSelected)
      );
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else if (dumpSelect > 33 && dumpSelect < 42) {
      const result = await dispatch(
        dumpExcelEmployees(formFileSelected, {
          type: type,
          document: template[`template${dumpSelect}`].sheet[0],
        })
      );
      if (result) {
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else if (dumpSelect === 42) {
      const formData = formFileSelected;
      formData.set(
        "idCompany",
        selectedCompany ? selectedCompany.idCompany : null
      );
      const result = await dispatch(uploadExcelEmployeeFreelance(formData));
      if (result) {
        dispatch(getAllEmployees());
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else if (dumpSelect === 43) {
      const result = await dispatch(
        uploadExcelManager2UserManager(formFileSelected)
      );
      if (result) {
        dispatch(getAllEmployees());
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else if (dumpSelect === 44){
      const formData = formFileSelected;
      formData.set(
        "idCompany",
        selectedCompany ? selectedCompany.idCompany : null
      );
      const result = await dispatch(
        dumpExcelReligionEmployees(formData)
      );
      if (result) {
        dispatch(getAllEmployees());
        setErrorResponse(result);
        setOpenLoading(false);
      }
    } else {
      setOpenLoading(false);
    }
  };

  const handleChange = (event) => {
    setCompanySelected(event.target.value);
  };

  const handleCloseLoading = () => {
    //setOpenLoading(false);
    handleClose();
  };

  const defaultHeader = {
    รหัสพนักงาน: "",
    ชื่อ: "",
    สกุล: "",
    เลขบัตรประจำตัวประชาชน: "",
  };

  const template = {
    template4: {
      sheet: ["manager"],
      fileName: "หัวหน้างาน.xlsx",
      header: {
        ...defaultHeader,
        "email หัวหน้างานระดับที่ 1": "",
        "email หัวหน้างานระดับที่ 2": "",
      },
    },
    template6: {
      sheet: ["personal level"],
      fileName: "ระดับพนักงาน.xlsx",
      header: {
        ...defaultHeader,
        ชื่อบริษัท: "",
        ระดับพนักงาน: "",
        "วัน/เดือน/ปีเริ่มต้น": "",
        "วัน/เดือน/ปีสิ้นสุด": "",
        หมายเหตุ: "",
      },
    },
    template7: {
      sheet: ["salary"],
      fileName: "ปรับค่าจ้าง.xlsx",
      header: {
        รหัสพนักงาน: "",
        ชื่อ: "",
        สกุล: "",
        เลขบัตรประจำตัวประชาชน: "",
        เงินเดือน: "",
        หมายเหตุ: "",
      },
    },
    template10: {
      sheet: ["nickname"],
      fileName: "ชื่อเล่น.xlsx",
      header: {
        รหัสพนักงาน: "",
        ชื่อ: "",
        สกุล: "",
        เลขบัตรประจำตัวประชาชน: "",
        Nickname: "",
      },
    },
    template11: {
      sheet: ["gender"],
      fileName: "เพศ.xlsx",
      header: {
        รหัสพนักงาน: "",
        ชื่อ: "",
        สกุล: "",
        เลขบัตรประจำตัวประชาชน: "",
        Gender: "",
      },
    },
    template12: {
      sheet: ["nationality"],
      fileName: "สัญชาติ.xlsx",
      header: {
        รหัสพนักงาน: "",
        ชื่อ: "",
        สกุล: "",
        เลขบัตรประจำตัวประชาชน: "",
        สัญชาติ: "",
      },
    },
    template13: {
      sheet: ["status"],
      fileName: "สถานะ.xlsx",
      header: {
        รหัสพนักงาน: "",
        ชื่อ: "",
        สกุล: "",
        เลขบัตรประจำตัวประชาชน: "",
        สถานะ: "",
      },
    },
    template14: {
      sheet: ["passport number"],
      fileName: "เลขพาสปอร์ต.xlsx",
      header: {
        รหัสพนักงาน: "",
        ชื่อ: "",
        สกุล: "",
        เลขบัตรประจำตัวประชาชน: "",
        "Passport Number": "",
      },
    },
    template15: {
      sheet: ["work permit number"],
      fileName: "เลขที่ใบอนุญาตทำงาน.xlsx",
      header: {
        รหัสพนักงาน: "",
        ชื่อ: "",
        สกุล: "",
        เลขบัตรประจำตัวประชาชน: "",
        "Work Permit Number": "",
      },
    },
    template16: {
      sheet: ["birthday"],
      fileName: "วันเกิด.xlsx",
      header: {
        รหัสพนักงาน: "",
        ชื่อ: "",
        สกุล: "",
        เลขบัตรประจำตัวประชาชน: "",
        Birthday: "",
      },
    },
    template17: {
      sheet: ["address"],
      fileName: "ที่อยู่.xlsx",
      header: {
        รหัสพนักงาน: "",
        ชื่อ: "",
        สกุล: "",
        เลขบัตรประจำตัวประชาชน: "",
        บ้านเลขที่: "",
        หมู่บ้าน: "",
        หมู่ที่: "",
        ซอย: "",
        ถนน: "",
        "ตำบล/แขวง": "",
        "อำเภอ/เขต": "",
        จังหวัด: "",
        รหัสไปรณีย์: "",
      },
    },
    template18: {
      sheet: ["telephone mobile"],
      fileName: "เบอร์โทรศัพท์.xlsx",
      header: {
        รหัสพนักงาน: "",
        ชื่อ: "",
        สกุล: "",
        เลขบัตรประจำตัวประชาชน: "",
        "Telephone Mobile": "",
      },
    },
    template19: {
      sheet: ["email"],
      fileName: "อีเมล.xlsx",
      header: {
        รหัสพนักงาน: "",
        ชื่อ: "",
        สกุล: "",
        เลขบัตรประจำตัวประชาชน: "",
        Email: "",
      },
    },
    template20: {
      sheet: ["emergency contact"],
      fileName: "ผู้ติดต่อ.xlsx",
      header: {
        รหัสพนักงาน: "",
        ชื่อ: "",
        สกุล: "",
        เลขบัตรประจำตัวประชาชน: "",
        ผู้ติดต่อหลัก: "",
        ความสัมพันธ์: "",
        เบอร์ติดต่อผู้ใกล้ชิด: "",
      },
    },
    template21: {
      sheet: ["time attendance"],
      fileName: "บันทึกเวลาทำงาน.xlsx",
      header: {
        รหัสพนักงาน: "",
        ชื่อ: "",
        สกุล: "",
        เลขบัตรประจำตัวประชาชน: "",
        บันทึกเวลาทำงาน: "",
      },
    },
    template22: {
      sheet: ["manager level 1"],
      fileName: "ผู้จัดการลำดับที่ 1.xlsx",
      header: {
        รหัสพนักงาน: "",
        ชื่อ: "",
        สกุล: "",
        เลขบัตรประจำตัวประชาชน: "",
        "Reporting Manager Level 1": "",
        "Manager Position Level 1": "",
        "Manager E-Mail Level 1": "",
      },
    },
    template23: {
      sheet: ["manager level 2"],
      fileName: "ผู้จัดการลำดับที่ 2.xlsx",
      header: {
        รหัสพนักงาน: "",
        ชื่อ: "",
        สกุล: "",
        เลขบัตรประจำตัวประชาชน: "",
        "Reporting Manager Level 2": "",
        "Manager Position Level 2": "",
        "Manager E-Mail Level 2": "",
      },
    },
    // template24: {
    //     sheet: ["working type"],
    //     fileName: "ประเภทการทำงาน.xlsx",
    //     header: {
    //         รหัสพนักงาน: "",
    //         ชื่อ: "",
    //         สกุล: "",
    //         เลขบัตรประจำตัวประชาชน: "",
    //         "Working Type": "",
    //         "Shift Type": "",
    //         "Shift Name": "",
    //         จำนวนวันทำงาน: "",
    //         จำนวนวันหยุด: "",
    //     },
    // },
    // template25: {
    //     sheet: ["cost of operation"],
    //     fileName: "ค่าดำเนินการ.xlsx",
    //     header: {
    //         รหัสพนักงาน: "",
    //         ชื่อ: "",
    //         สกุล: "",
    //         เลขบัตรประจำตัวประชาชน: "",
    //         ค่าดำเนินการปกติ: "",
    //         "ค่าดำเนินการ OT": "",
    //         ค่าดำเนินการเหมา: "",
    //         ค่าดำเนินการค่าชดเชย: "",
    //     },
    // },
    template26: {
      sheet: ["contract termainatoin date"],
      fileName: "วันหมดสัญญา.xlsx",
      header: {
        รหัสพนักงาน: "",
        ชื่อ: "",
        สกุล: "",
        เลขบัตรประจำตัวประชาชน: "",
        "Contract Termainatoin Date": "",
      },
    },
    template27: {
      sheet: ["resign date"],
      fileName: "วันลาออก.xlsx",
      header: {
        รหัสพนักงาน: "",
        ชื่อ: "",
        สกุล: "",
        เลขบัตรประจำตัวประชาชน: "",
        "Resign Date": "",
      },
    },
    template28: {
      sheet: ["education degree1"],
      fileName: "การศึกษา1.xlsx",
      header: {
        รหัสพนักงาน: "",
        ชื่อ: "",
        สกุล: "",
        เลขบัตรประจำตัวประชาชน: "",
        "(1)Education Degree": "",
        "(1)Education School or University": "",
        "(1)Faculty": "",
        "(1)Major": "",
        "(1)From Year": "",
        "(1)End Year": "",
        "(1)GPA": "",
      },
    },
    template29: {
      sheet: ["education degree2"],
      fileName: "การศึกษา2.xlsx",
      header: {
        รหัสพนักงาน: "",
        ชื่อ: "",
        สกุล: "",
        เลขบัตรประจำตัวประชาชน: "",
        "(2)Education Degree": "",
        "(2)Education School or University": "",
        "(2)Faculty": "",
        "(2)Major": "",
        "(2)From Year": "",
        "(2)End Year": "",
        "(2)GPA": "",
      },
    },
    template30: {
      sheet: ["education degree3"],
      fileName: "การศึกษา3.xlsx",
      header: {
        รหัสพนักงาน: "",
        ชื่อ: "",
        สกุล: "",
        เลขบัตรประจำตัวประชาชน: "",
        "(3)Education Degree": "",
        "(3)Education School or University": "",
        "(3)Faculty": "",
        "(3)Major": "",
        "(3)From Year": "",
        "(3)End Year": "",
        "(3)GPA": "",
      },
    },
    // template31: {
    //     sheet: ["performance review year"],
    //     fileName: "การประเมินประจำปี.xlsx",
    //     header: {
    //         รหัสพนักงาน: "",
    //         ชื่อ: "",
    //         สกุล: "",
    //         เลขบัตรประจำตัวประชาชน: "",
    //         "Performance Review Year (Past 1 Year)": "",
    //         "Performance Review Year (Past 2 Year)": "",
    //         "Performance Review Year (Past 3 Year)": "",
    //         "Performance Review Year (Past 4 Year)": "",
    //         "Performance Review Year (Past 5 Year)": "",
    //     },
    // },
    template32: {
      sheet: ["cost"],
      fileName: "บริษัทที่เรียกเก็บเงิน.xlsx",
      header: {
        รหัสพนักงาน: "",
        ชื่อ: "",
        สกุล: "",
        เลขบัตรประจำตัวประชาชน: "",
        "Cost Center Charge": "",
        "Cost Element Charge": "",
        IO: "",
      },
    },
    template33: {
      sheet: ["book bank"],
      fileName: "ข้อมูลธนาคาร.xlsx",
      header: {
        รหัสพนักงาน: "",
        ชื่อ: "",
        สกุล: "",
        เลขบัตรประจำตัวประชาชน: "",
        "Book Bank": "",
        "Book Bank Branch Name": "",
        "Book ID": "",
        "Report ส่งธนาคาร ชื่อธนาคาร": "",
        "Report ส่งธนาคาร รหัสธนาคาร": "",
        "Report ส่งธนาคาร เลขที่บัญชี": "",
        "Report ส่งธนาคาร ชื่อผู้รับเงิน": "",
        "Report ส่งธนาคาร อ้างอิง": "",
      },
    },
    template34: {
      sheet: ["performance review"],
      fileName: "ผลงานย้อนหลัง.xlsx",
      header: {
        ...defaultHeader,
        "ผลงานย้อนหลัง 1 ปี": "",
        "ผลงานย้อนหลัง 2 ปี": "",
        "ผลงานย้อนหลัง 3 ปี": "",
        "ผลงานย้อนหลัง 4 ปี": "",
        "ผลงานย้อนหลัง 5 ปี": "",
      },
    },
    template35: {
      sheet: ["family"],
      fileName: "ข้อมูลครอบครัว.xlsx",
      header: {
        ...defaultHeader,
        ความสัมพันธ์: "",
        "ชื่อ(สมาชิกครอบครัว)": "",
        "สกุล(สมาชิกครอบครัว)": "",
        "เลขบัตรประจำตัวประชาชน(สมาชิกครอบครัว)": "",
        "วัน/เดือน/ปีเกิด(สมาชิกครอบครัว)": "",
      },
    },
    template36: {
      sheet: ["experience"],
      fileName: "ประสบการณ์การทำงาน.xlsx",
      header: {
        ...defaultHeader,
        "เดือน/ปีเริ่มต้น": "",
        "เดือน/ปีสิ้นสุด": "",
        ชื่อบริษัท: "",
        ชื่อตำแหน่ง: "",
        รายละเอียด: "",
      },
    },
    template37: {
      sheet: ["personal level"],
      fileName: "ระดับพนักงาน.xlsx",
      header: {
        ...defaultHeader,
        ชื่อบริษัท: "",
        ระดับพนักงาน: "",
        หมายเหตุ: "",
      },
    },
    template38: {
      sheet: ["transfer"],
      fileName: "โอนย้าย.xlsx",
      header: {
        ...defaultHeader,
        "วัน/เดือน/ปีที่มีผล": "",
        ชื่อบริษัท: "",
        ชื่อตำแหน่ง: "",
        BU: "",
        Division: "",
        Department: "",
        Section: "",
        "email หัวหน้างานระดับที่ 1": "",
        "email หัวหน้างานระดับที่ 2": "",
      },
    },
    template39: {
      sheet: ["education degree"],
      fileName: "การศึกษา.xlsx",
      header: {
        ...defaultHeader,
        "Education Degree": "",
        "Education School or University": "",
        Faculty: "",
        Major: "",
        "From Year": "",
        "End Year": "",
        GPA: "",
      },
    },
    template40: {
      sheet: ["provident fund"],
      fileName: "กองทุนสำรองเลี้ยงชีพ.xlsx",
      header: {
        ...defaultHeader,
        วันที่เข้ากองทุน: "",
        "PF ฝั่งพนักงาน (%)": "",
        "PF ฝั่งบริษัท (%)": "",
      },
    },
    template41: {
      sheet: ["salary"],
      fileName: "ปรับค่าจ้าง.xlsx",
      header: {
        ...defaultHeader,
        "วัน/เดือน/ปี ที่มีผล": "",
        เงินเดือน: "",
        เหตุผล: "",
        หมายเหตุ: "",
      },
    },
  };

  const handleClickDownload = () => {
    console.log(dumpSelect);

    if (dumpSelect === 1) {
      window.open(
        `${process.env.REACT_APP_API_URL}files/PIS Master Template v.1.0.3.xlsx`
      );
    }
    if (dumpSelect === 9) {
      window.open(
        `${process.env.REACT_APP_API_URL}files/แบบฟอร์มวันลาของพนักงานแบบไม่ระบุวันที่.xlsx`
      );
    }

    if (dumpSelect === 8) {
      window.open(
        `${process.env.REACT_APP_API_URL}files/Addition_Deduction_Recurring_Template v.1.0.1.xlsx`
      );
    }

    const downloadList = [
      4, 6, 7, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
      26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41,
    ];

    if (downloadList.includes(dumpSelect)) {
      const elementList = [];

      elementList.push(template[`template${dumpSelect}`].header);

      const ws = XLSX.utils.json_to_sheet(elementList);

      var workbook = XLSX.utils.book_new();


      template[`template${dumpSelect}`].sheet.forEach((element) => {
        XLSX.utils.book_append_sheet(workbook, ws, element);
      });

      XLSX.writeFile(workbook, template[`template${dumpSelect}`].fileName, {
        type: "file",
      });
    }

    if (dumpSelect === 42) {
      window.open(
        `${process.env.REACT_APP_API_URL}files/PIS 40(2) Template v.2.0.1.xlsx`
      );
    }

    if (dumpSelect === 43) {
      window.open(
        `${process.env.REACT_APP_API_URL}files/PIS Manager2UserManager Template v1.0.0105.xlsx`
      );
    }
    if (dumpSelect === 44) {
      window.open(
        `${process.env.REACT_APP_API_URL}files/ศาสนา.xlsx`
      );
    }
  };

  const displayTextLink = (dumpSelect) => {
    switch (dumpSelect) {
      case 1:
        return `${t("DownloadTemplate")} PIS Master Template v.1.0.3.xlsx`;
      case 42:
        return `${t("DownloadTemplate")} PIS 40(2) Template v.2.0.1.xlsx`;
      case 43:
        return `${t(
          "DownloadTemplate"
        )} PIS Manager2UserManager Template v1.0.0105.xlsx`;
      default:
        return t("DownloadTemplate");
    }
  };

  const handleOpenStatusResult = async (status, result, label) => {
    setStatusResult({ isOpen: open, result: result, label: label });
    setWaitingAPI(false);
    if (status === 200) {
      handleClose();
      await dispatch(getAllEmployees());
    }
  };

  return (
    <DrawerCustom
      title={`${t("Upload")}`}
      anchor={"right"}
      open={open}
      onClose={() => {
        if (!(waitingAPI || openLoading)) {
          handleClose();
        }
      }}
    >
      <StyledRoot>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <StyleSelect fullWidth disabled={waitingAPI || openLoading}>
              <FormLabel variant="subtitle1">{t("SelectType")}</FormLabel>
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="selectedDumpList"
                value={selectedDumpList}
                onChange={handleChangeDumpListSelect}
              >
                <FormControlLabel
                  value="historyDumpList"
                  control={<StyledRadio />}
                  label={t("UploadHistory")}
                />
                <FormControlLabel
                  value="editDumpList"
                  control={<StyledRadio />}
                  label={t("UploadEdit")}
                />
                <FormControlLabel
                  value="insertDumpList"
                  control={<StyledRadio />}
                  label={t("UploadInsert")}
                />
              </RadioGroup>
            </StyleSelect>
          </Grid>
          <Grid item xs={12}>
            <StyleSelect
              fullWidth
              disabled={waitingAPI || selectedDumpList === null}
            >
              <InputLabel id="demo-simple-select-label">
                {t("SelectUploadItem")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                name="dump-list"
                label={`${t("SelectUploadItem")}`}
                value={dumpSelect}
                onChange={handleChangeDumpSelect}
                disabled={openLoading}
              >
                {selectedDumpList ? (
                  selectedDumpList === "historyDumpList" ? (
                    historyDumpList.map((item) => (
                      <MenuItem disabled={item.disabled} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))
                  ) : selectedDumpList === "editDumpList" ? (
                    editDumpList.map((item) => (
                      <MenuItem disabled={item.disabled} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))
                  ) : selectedDumpList === "insertDumpList" ? (
                    insertDumpList.map((item) => (
                      <MenuItem disabled={item.disabled} value={item.id}>
                        {item.name}
                      </MenuItem>
                    ))
                  ) : (
                    <></>
                  )
                ) : null}
              </Select>
            </StyleSelect>
          </Grid>

          {[1, 43, 8].includes(dumpSelect) && (
            <Grid item xs={12}>
              <Autocomplete
                options={affiliateList ? affiliateList : []}
                getOptionLabel={(option) => `${option.companyName}`}
                isOptionEqualToValue={(option, value) =>
                  option.idCompany === value.idCompany
                }
                disabled={openLoading}
                renderInput={(params) => (
                  <TextFieldTheme
                    {...params}
                    placeholder={`${t("SelectCompany")}`}
                  // onBlur={field.onBlur}
                  // helperText={fieldState.error? fieldState.error.message: null}
                  // error={fieldState.error? true: false}
                  />
                )}
                value={selectedCompany}
                onChange={(_, value) => {
                  setSelectedCompany(value);
                }}
                noOptionsText={`${t("NoData")}`}
                disableClearable
              />
            </Grid>
          )}

          {(<Grid item xs={12}>
              {openLoading ? (
                <StyledLoading>
                  <Typography variant="h6" color="text.third" gutterBottom>
                    {t("SavingData")}...
                  </Typography>
                  <div>
                    <CircularProgress />
                  </div>
                </StyledLoading>
              ) : (
                <Fragment>
                  {((![1, 43, 8].includes(dumpSelect) ||
                      ([1, 43, 8].includes(dumpSelect) && selectedCompany))) && <Fragment>
                    {errorResponse ? (
                      <ShowResponse
                        errorResponse={errorResponse}
                        handleCloseLoading={handleCloseLoading}
                      />
                    ) : (
                      <Fragment>
                        {dumpSelect && <Fragment>
                          <StyledImportFile>
                            {dumpSelect !== 2 && (
                              <div
                                style={{
                                  marginBottom: 16,
                                  display: "flex",
                                  justifyContent: "flex-end",
                                  cursor: "pointer",
                                }}
                              >
                                <Link variant="body2" onClick={handleClickDownload}>
                                  {displayTextLink(dumpSelect)}
                                </Link>
                              </div>
                            )}
                            {dumpSelect !== 2 ? (
                              <div
                                {...getRootProps({
                                  className: "dropzone-upload-file",
                                })}
                              >
                                <input {...getInputProps()} />
                                <div className="inner-dropzone">
                                  <img alt="AddIcon" src={AddIcon} width="80" />
                                  <Typography
                                    style={{
                                      marginTop: 8,
                                      backgroundColor: "transparent",
                                    }}
                                    className={`${fileSelected != 0 && `placeholderLabel`
                                      }`}
                                    variant="body2"
                                    color="text.secondary"
                                  >
                                    {t("DragDropOrClickSelectFile")}
                                  </Typography>
                                </div>
                              </div>
                            ) : (
                              <DragDropImage
                                waitingAPI={waitingAPI}
                                setWaitingAPI={setWaitingAPI}
                                handleOpenStatusResult={handleOpenStatusResult}
                              />
                            )}
                          </StyledImportFile>
                          {fileSelected && dumpSelect !== 2 && (
                            <FileSelected
                              fileSelected={fileSelected}
                              handleClickUpload={handleClickUpload}
                              isReadOnly={isReadOnly}
                            />
                          )}
                        </Fragment>}
                      </Fragment>
                    )}
                  </Fragment>}
                </Fragment>
              )}
            </Grid>
          )}
        </Grid>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogUpload;
