import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from "@pdf-lib/fontkit";
import dayjs from 'dayjs';

export const SPS103PdfFile = async (type, employeeDetails, imageData) => {
    const url = `${process.env.REACT_APP_API_URL}files/${type}.pdf`;
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const urlFont = `${process.env.REACT_APP_API_URL}fonts/THSarabunNew.ttf`;
    const fontBytes = await fetch(urlFont).then((res) => res.arrayBuffer());
    pdfDoc.registerFontkit(fontkit);

    const font = await pdfDoc.embedFont(fontBytes);
    const pages = pdfDoc.getPages();
    const page1 = pages[0];

    const fontSize = 16;
    const contentWidth = 480;
    const firstname = `${employeeDetails && employeeDetails.firstname_TH} `;
    const lastname = `${employeeDetails && employeeDetails.lastname_TH} `;

    if (employeeDetails && employeeDetails.companyName) {
        page1.drawText(employeeDetails.companyName, {
            x: 130, y: 742, size: fontSize, font: font
        });
    }

    if (employeeDetails && employeeDetails.socialSecurityAccount) {
        const socialAccount = employeeDetails.socialSecurityAccount;

        const formattedsocialAccount1 = `${socialAccount[0] + '  ' + socialAccount[1]}`
        const formattedsocialAccount2 = `${'  ' + socialAccount[2] + '  ' + socialAccount[3] + ' ' + socialAccount[4] + '  ' + socialAccount[5] + ' ' + socialAccount[6] + '  ' + socialAccount[7] + '  ' + socialAccount[8]}`
        const formattedsocialAccount3 = `${socialAccount[9]} `

        // Draw the formatted social account
        page1.drawText(formattedsocialAccount1, {
            x: 395, y: 739, size: 20, font: font
        });
        page1.drawText(formattedsocialAccount2, {
            x: 431, y: 739, size: 20, font: font
        });
        page1.drawText(formattedsocialAccount3, {
            x: 550, y: 739, size: 20, font: font
        });
    }

    //personal id
    if (employeeDetails && employeeDetails.taxID) {
        const taxID = `${employeeDetails.taxID} `;

        const formattedtaxID1 = `${' ' + taxID[0] + '   ' + taxID[1] + '  ' + taxID[2]} `
        const formattedtaxID2 = `${' ' + taxID[3] + '   ' + taxID[4]} `
        const formattedtaxID3 = `${taxID[5] + '   ' + taxID[6] + '   ' + taxID[7] + '  ' + taxID[8] + '   ' + taxID[9] + '  ' + taxID[10] + '   ' + taxID[11] + '    '} `
        const formattedtaxID4 = `${taxID[12]} `

        page1.drawText(`${formattedtaxID1}`, {
            x: 145,
            y: 722,
            size: fontSize,
            font: font
        });

        page1.drawText(`${formattedtaxID2}`, {
            x: 200,
            y: 722,
            size: fontSize,
            font: font
        });

        page1.drawText(`${formattedtaxID3}`, {
            x: 245,
            y: 722,
            size: fontSize,
            font: font
        });

        page1.drawText(`${formattedtaxID4}`, {
            x: 360,
            y: 722,
            size: fontSize,
            font: font
        });
    }

    const presentDate = dayjs();
    const day = presentDate.format('DD');
    const month = presentDate.format('MMMM');
    const thaiYear = presentDate.year() + 543;

    page1.drawText(`${day + ' ' + month + ' ' + thaiYear}`, {
        x: 400,
        y: 358,
        size: 14,
        maxWidth: contentWidth,
        font: font
    });


    // name and birthday
    page1.drawText(`${firstname}`, { x: 250, y: 638, size: fontSize, maxWidth: contentWidth, font: font });
    page1.drawText(`${lastname}`, { x: 385, y: 638, size: fontSize, maxWidth: contentWidth, font: font });

    if (employeeDetails && employeeDetails.hiringDate) {
        const hiringDate = dayjs(employeeDetails.hiringDate);
        const day = hiringDate.format('DD');
        const month = hiringDate.format('MMMM');
        const thaiYear = hiringDate.year() + 543;

        page1.drawText(`${day + ' ' + month + ' ' + thaiYear}`, {
            x: 140,
            y: 708,
            size: fontSize,
            maxWidth: contentWidth,
            font: font
        });
    }


    if (employeeDetails && employeeDetails.birthday) {
        const gregorianBirthday = dayjs(employeeDetails.birthday);
        const day = gregorianBirthday.format('DD');
        const month = gregorianBirthday.format('MMMM');
        const thaiYear = gregorianBirthday.year() + 543;

        page1.drawText(`${day}`, {
            x: 90,
            y: 622,
            size: fontSize,
            font: font,
        });

        page1.drawText(`${month}`, {
            x: 140,
            y: 622,
            size: fontSize,
            font: font,
        });

        page1.drawText(`${thaiYear.toString()}`, {
            x: 220,
            y: 622,
            size: fontSize,
            font: font,
        });
    }

    page1.drawText(`X`, {
        x: employeeDetails &&
            employeeDetails.maritalStatus === "หม้าย" ?
            187 : (employeeDetails && employeeDetails.maritalStatus) === "สมรส" ?
                105 : 50,
        y: 572,
        size: 10,
    });

    if (imageData) {
        const pngImage = await pdfDoc.embedPng(imageData);
        const pngDims = pngImage.scale(0.05);
        page1.drawImage(pngImage, {
            x: 425, y: 400, width: pngDims.width, height: pngDims.height
        })
    }

    page1.drawText(`${employeeDetails && employeeDetails.certificateSignatureName ? employeeDetails.certificateSignatureName : "-"}`, {
        x: 400, y: 390, size: fontSize, font: font,
    });
    page1.drawText(`${employeeDetails && employeeDetails.certificateSignaturePosition ? employeeDetails.certificateSignaturePosition : "-"}`, {
        x: 390, y: 373, size: fontSize, font: font,
    });

    const pdfBytes = await pdfDoc.save();
    const bytes = new Uint8Array(pdfBytes);
    const blob = new Blob([bytes], { type: "application/pdf" });
    const docUrl = URL.createObjectURL(blob);
    window.open(docUrl, "_blank");
}