import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import html2canvas from "html2canvas";
import { PDFDocument } from "pdf-lib";
import {
  Grid,
  Typography,
  Box,
  TextField,
  Container,
  Popper,
} from "@mui/material";

import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

import DatePickerCustom from "../../../shared/date/datePicker";

import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";

import CardStyle from "../../../shared/general/Card";
import ButtonBlue from "../../../shared/general/ButtonBlue";

import { getPayslipByEmployee } from "../../../../../actions/payruns";

import SlipDetails from "../../../shared/payslip";
import fileService from "../../../../../services/file.service";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import DialogPersonID from "../../../shared/general/dialogPersonID";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .MuiContainer-root": {
    paddingBottom: 16,
  },
});

const StyledWrapSearch = styled("div")({
  padding: 16,
  marginBottom: 16,
});

const StyledBoxSearch = styled(Box)({
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledSlipDetails = styled(Box)({
  width: "100%",
  backgroundColor: "#FFFFFF",
  overflowX: "auto",
  marginLeft: "auto",
  marginRight: "auto",
  borderRadius: 8,
});

const PayslipAdmin = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: payslipDetail } = useSelector((state) => state.payslipDetail);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [search, setSearch] = useState(new Date());
  const [openDialog, setOpenDialog] = useState(true);
  const [selectedEmployee, setSelectedEmployee] = React.useState(null);

  useEffect(() => {
    dispatch(
      getPayslipByEmployee(
        dayjs(search)
          .set("date", dayjs(search).daysInMonth())
          .format("YYYY-MM-DD")
      )
    );
  }, []);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const printDocument = () => {
    const input = document.getElementById("divToPrint");
    html2canvas(input, {
      scale: 2,
    }).then(async (canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = await PDFDocument.create();
      const page = pdf.addPage();
      let companyLogo = await fileService
        .getCompanyLogoFile(userProfile.idCompany)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return null;
        });
      const pageSize = page.getSize();
      const pngImage = await pdf.embedPng(imgData);
      const pngDims = pngImage.scale(0.305);
      page.drawImage(pngImage, {
        x: 0,
        y: pageSize.height - (pngDims.height - 25),
        width: pngDims.width,
        height: pngDims.height,
      });
      
      if (companyLogo) {
        const pngCompanyLogo = await pdf.embedPng(companyLogo);
        const pngDimsCompanyLogo = pngCompanyLogo.scale(0.2);
        page.drawImage(pngCompanyLogo, {
          x: 30,
          y: pageSize.height - pngDimsCompanyLogo.height,
          width: pngDimsCompanyLogo.width,
          height: pngDimsCompanyLogo.height,
        });
      }
      const pdfBytes = await pdf.save();
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      const bytes = new Uint8Array(pdfBytes);
      const blob = new Blob([bytes], { type: "application/pdf" });
      const docUrl = window.URL.createObjectURL(blob);
      a.href = docUrl;

      a.download =
        "สลิปเงินเดือน " +
        dayjs(payslipDetail[0].monthPeriod).format("MMMM BBBB") +
        ".pdf";
      a.click();
      a.setAttribute(
        "id",
        "สลิปเงินเดือน " +
          dayjs(payslipDetail[0].monthPeriod).format("MMMM BBBB") +
          ".pdf"
      );
      document
        .getElementById(
          "สลิปเงินเดือน " +
            dayjs(payslipDetail[0].monthPeriod).format("MMMM BBBB") +
            ".pdf"
        )
        .remove();
      window.URL.revokeObjectURL(docUrl);
    });
  };

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        {openDialog && (
          <DialogPersonID 
            open={openDialog} 
            handleClose={handleCloseDialog} 
          />
        )}
        {!openDialog && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <StyledWrapSearch>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <StyledBoxSearch>
                      <Typography className="label" color="text.third">
                        {`${t("SearchDate")}`}
                      </Typography>
                      <div className="search-date">
                        <DatePickerCustom
                          inputFormat="MMMM YYYY"
                          value={search}
                          name="start"
                          minDate={new Date().setFullYear(new Date().getFullYear() - 4)}
                          maxDate={new Date()}
                          views={["year", "month"]}
                          onChange={(newValue) => {
                            setSearch(newValue);
                            dispatch(
                              getPayslipByEmployee(
                                dayjs(newValue)
                                  .set("date", dayjs(newValue).daysInMonth())
                                  .format("YYYY-MM-DD")
                              )
                            );
                          }}
                        />
                      </div>
                    </StyledBoxSearch>
                  </Grid>
                </Grid>
              </StyledWrapSearch>
            </Grid>

            <Grid item xs={12}>
              {payslipDetail && payslipDetail.length > 0 ? (
                payslipDetail.map((payslip, index) => (
                  <Fragment key={index}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginBottom: 16,
                      }}
                    >
                      <ButtonBlue
                        variant="text"
                        disabled={!payslipDetail}
                        startIcon={<DownloadRoundedIcon />}
                        onClick={printDocument}
                      >
                        {`${t("Download")}`}
                      </ButtonBlue>
                    </div>
                    <CardStyle style={{ padding: 16, marginBottom: 16 }}>
                      <StyledSlipDetails>
                        <SlipDetails currentSlip={payslip} />
                      </StyledSlipDetails>
                    </CardStyle>
                  </Fragment>
                ))
              ) : (
                <div>
                  <Typography color="text.secondary" align="center">
                    {`${t("NoData")}`}
                  </Typography>
                </div>
              )}
            </Grid>
          </Grid>
        )}
      </Container>
    </StyledRoot>
  );
};

export default PayslipAdmin;
