import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Box,
  Popper,
  Grid,
  Avatar,
  Typography,
  MenuItem,
  FormControl,
} from "@mui/material";
import { styled } from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import dayjs from "dayjs";
import DoneAll from "@mui/icons-material/DoneAll";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import AddTaskIcon from "@mui/icons-material/AddTask";
import { useTranslation } from "react-i18next";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import SnackBarCustom from "../../../shared/snackbarCustom";
import Paper from "@mui/material/Paper";
import { getAllEmployees } from "../../../../../actions/employee";
import {
  approveTerminateEmployee,
  clearTerminateEmployee,
  getTerminateEmployeeByID,
} from "../../../../../actions/offBoarding";
import DialogAdd from "./DialogAdd";
import { getUserFullName } from "../../../../../utils/userData";
import { getAffiliateOrganization } from "../../../../../actions/company";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import { exportToExcel } from "./exportToExcel";

const StyledWrapBranch = styled("div")({
  marginBottom: 36,
  padding: 16,
  backgroundColor: "#ffffff",
  borderRadius: 8,
  boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
});

const StyledBoxSearch = styled(Box)({
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  "& .MuiFilledInput-root": {
    backgroundColor: "#f7f7f7",
    borderRadius: 8,
    padding: "8px 12px",
    "&:hover": {
      backgroundColor: "#eeeeee",
    },
    "&.Mui-focused": {
      backgroundColor: "#ffffff",
    },
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StatusBadge = styled(Box)(({ color }) => ({
  display: "flex",
  alignItems: "center",
  backgroundColor: color,
  borderRadius: "16px",
  padding: "4px 12px",
  color: "#fff",
  fontWeight: 600,
}));
const ReviewerOffBoarding = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { result: terminateEmployee } = useSelector(
    (state) => state.terminateEmployeeByID
  );
  const { result: employees } = useSelector((state) => state.employees);
  const { result: affiliateOrganizationList } = useSelector(
    (state) => state.affiliateOrganization
  );
  const [getCompanyName, setGetCompanyName] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [formData, setFormData] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [filterStatus, setFilterStatus] = useState("all");
  const [snackBarConfig, setSnackBarConfig] = useState({
    open: false,
    message: "",
    type: "success",
  });

  useEffect(() => {
    dispatch(getAllEmployees());
    dispatch(getAffiliateOrganization());
    dispatch(clearTerminateEmployee());
  }, [dispatch]);

  useEffect(() => {
    if (selectedEmployee) dispatch(getTerminateEmployeeByID(selectedEmployee));
  }, [selectedEmployee, openDialog, dispatch]);

  useEffect(() => {
    if (selectedCompany && employees) {
      const filtered = employees.filter(
        (employee) => employee.companyName === selectedCompany.companyName
      );
      setFilteredEmployees(filtered);
      setSelectedEmployee(null);
      setGetCompanyName(selectedCompany.companyName);
    } else {
      setFilteredEmployees([]);
    }
  }, [selectedCompany, employees]);

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setFormData(selectedEmployee);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setFormData(null);
    window.location.reload();
  };

  const handleApprovalRequest = async () => {
    const idTerminateEmployee = selectedRows.map((x) => x.idTerminateEmployee);
    const data = {
      idTerminateEmployee,
      isApprove: 1,
      updateDate: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
    };

    const result = await dispatch(approveTerminateEmployee(data));
    if (result) {
      setSelectedRows([]);
      if (result.status === 200) {
        dispatch(getTerminateEmployeeByID(selectedEmployee));
        setSnackBarConfig({
          open: true,
          message: `${t("Successfully")}`,
          type: "success",
        });
      } else {
        setSnackBarConfig({
          open: true,
          message: `${t("Unsuccessful")}`,
          type: "error",
        });
      }
    } else {
      setSnackBarConfig({
        open: true,
        message: `${t("Unsuccessful")}`,
        type: "error",
      });
    }
    setSelectedRows([]);
  };

  const selectedEmployeeData =
    employees && selectedEmployee
      ? employees.find((employee) => employee.idEmployees === selectedEmployee)
      : null;

  const completedCount =
    (terminateEmployee &&
      terminateEmployee.filter((item) => item.isApprove).length) ||
    0;
  const inProgressCount =
    (terminateEmployee &&
      terminateEmployee.filter((item) => !item.isApprove).length) ||
    0;

  const filteredOffBoardingEmployee = terminateEmployee
    ? terminateEmployee.filter((item) => {
        const fullName = `${item.reviewer_firstname_TH} ${item.reviewer_lastname_TH}`;
        const matchName = fullName
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
        const matchStatus =
          filterStatus === "all"
            ? true
            : filterStatus === "completed"
            ? item.isApprove === 1
            : item.isApprove === 0;
        return matchName && matchStatus;
      })
    : [];

  return (
    <div>
      <StyledWrapBranch>
        <SnackBarCustom config={snackBarConfig} setConfig={setSnackBarConfig} />
        <Box>
          <Grid
            container
            alignItems="center"
            spacing={1}
            marginBottom={4}
            justifyContent={"space-between"}
          >
            <Grid item xs={12} sm={6}>
              <StyledAutocomplete
                options={affiliateOrganizationList}
                getOptionLabel={(option) => option.companyName}
                onChange={(event, newValue) => setSelectedCompany(newValue)}
                renderInput={(params) => (
                  <TextFieldTheme
                    {...params}
                    variant="filled"
                    placeholder={t("SelectCompany")}
                  />
                )}
                noOptionsText={t("NoData")}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <StyledAutocomplete
                options={filteredEmployees}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setSelectedEmployee(newValue.idEmployees);
                  } else {
                    setSelectedEmployee(null);
                    dispatch(clearTerminateEmployee());
                    setSelectedRows([]);
                  }
                }}
                getOptionLabel={(option) => getUserFullName(option)}
                renderInput={(params) => (
                  <TextFieldTheme
                    {...params}
                    variant="filled"
                    placeholder={t("SelectEmp")}
                  />
                )}
                noOptionsText={t("NoData")}
              />
            </Grid>
            {selectedEmployee && (
              <>
                <Grid item xs={12} sm={6}>
                  <TextFieldTheme
                    fullWidth
                    variant="outlined"
                    placeholder={t("Search Inspector")}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <TextFieldTheme
                      select
                      label={t("Status")}
                      value={filterStatus}
                      onChange={(e) => setFilterStatus(e.target.value)}
                      variant="outlined"
                    >
                      <MenuItem value="all">{t("All")}</MenuItem>
                      <MenuItem value="completed">{t("Completed")}</MenuItem>
                      <MenuItem value="inProgress">{t("InProgress")}</MenuItem>
                    </TextFieldTheme>
                  </FormControl>
                </Grid>
              </>
            )}
            {selectedEmployee && (
              <Grid item xs={12} sm={4}>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  {selectedRows.length > 0 && (
                    <ButtonBlue
                      style={{ marginRight: "8px" }}
                      size="small"
                      startIcon={<DoneAll />}
                      variant="contained"
                      onClick={handleApprovalRequest}
                    >
                      {`${t("Approved")} ${
                        selectedRows.length > 0
                          ? `${selectedRows.length} ${t("Unit.Items")}`
                          : ""
                      }`}
                    </ButtonBlue>
                  )}
                </div>
              </Grid>
            )}
          </Grid>
        </Box>
        {terminateEmployee && terminateEmployee.length > 0 ? (
          <div>
            <Box
              display="flex"
              alignItems="center"
              marginBottom={2}
              justifyContent="space-between"
            >
              {selectedEmployeeData && selectedEmployeeData.imageProfile && (
                <Box display="flex" alignItems="center" marginBottom={2}>
                  <Avatar
                    alt={`${selectedEmployeeData.firstname_TH} ${selectedEmployeeData.lastname_TH}`}
                    src={selectedEmployeeData.imageProfile}
                    style={{ marginRight: 16, width: 56, height: 56 }}
                  />
                  <Typography variant="h6">
                    {`${selectedEmployeeData.firstname_TH} ${selectedEmployeeData.lastname_TH}`}
                  </Typography>
                </Box>
              )}
              <Box display="flex" alignItems="center" gap={2}>
                <StatusBadge color="#4caf50">
                  {`${completedCount} ${t("items Completed")}`}
                </StatusBadge>
                <StatusBadge color="#ff9800">
                  {`${inProgressCount} ${t("items In progress")}`}
                </StatusBadge>
                <ButtonBlue
                  size="small"
                  variant="contained"
                  onClick={handleOpenDialog}
                >
                  {t("AddItem")}
                </ButtonBlue>
                <ButtonBlue
                  size="small"
                  variant="outlined"
                  onClick={() =>
                    exportToExcel(filteredOffBoardingEmployee, getCompanyName)
                  }
                  disabled={filteredOffBoardingEmployee.length === 0}
                >
                  {t("Download")}
                </ButtonBlue>
              </Box>
            </Box>

            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 600 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">{t("ItemName")}</TableCell>
                    <TableCell align="center">{t("Inspector")}</TableCell>
                    <TableCell align="center">{t("Division")}</TableCell>
                    <TableCell align="center">{t("Department")}</TableCell>
                    <TableCell align="center">{t("Status")}</TableCell>
                    <TableCell align="center">{t("Date")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredOffBoardingEmployee.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">
                        {row.TerminateListName}{" "}
                      </TableCell>
                      <TableCell align="center">
                        <Box display="flex" alignItems="center">
                          <Avatar
                            alt={`${row.reviewer_firstname_TH} ${row.reviewer_lastname_TH}`}
                            src={
                              row.imageProfile
                                ? `data:image/jpeg;base64,${row.imageProfile}`
                                : ""
                            }
                            style={{ marginRight: 8, width: 32, height: 32 }}
                          />
                          {`${row.reviewer_firstname_TH}  ${row.reviewer_lastname_TH}`}{" "}
                        </Box>
                      </TableCell>
                      <TableCell align="center" sx={{ minWidth: "80px" }}>
                        {row.divisionName}{" "}
                      </TableCell>
                      <TableCell align="center" sx={{ minWidth: "50px" }}>
                        {row.departmentName}{" "}
                      </TableCell>
                      <TableCell align="center">
                        {row.isApprove ? (
                          <CheckCircleOutlineIcon style={{ color: "green" }} />
                        ) : selectedRows.includes(row) ? (
                          <AddTaskIcon
                            onClick={() => {
                              setSelectedRows(
                                selectedRows.filter((x) => x !== row)
                              );
                            }}
                          />
                        ) : (
                          <CancelOutlinedIcon
                            style={{ color: "red" }}
                            onClick={() => {
                              setSelectedRows((selectedRows) => [
                                ...selectedRows,
                                row,
                              ]);
                            }}
                          />
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {dayjs(row.updateDate).format("DD/MM/YYYY HH:mm")}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          `${t("NoItems")}`
        )}
      </StyledWrapBranch>

      {openDialog && (
        <DialogAdd
          open={openDialog}
          data={formData}
          handleClose={handleClose}
        />
      )}
    </div>
  );
};

export default ReviewerOffBoarding;
