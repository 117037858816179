import React, { Fragment, useEffect, useState } from "react";
import { Box, Chip, Container, Typography, styled } from "@mui/material";
import { useDispatch } from "react-redux";
import { getIndividualObjectiveByIdIndividualObjective } from "../../../../../../../actions/okrs";
import CardStyle from "../../../../../shared/general/Card";
import {
  AccessTimeRounded,
  ChevronRightRounded,
  Edit,
} from "@mui/icons-material";
import dayjs from "dayjs";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { useTranslation } from "react-i18next";

const StyledRoot = styled(Box)({
  marginBottom: "20px",
  "& .objective-information-container": {
    padding: "16px",
    "& .objective-name": {
      fontSize: "20px",
      fontWeight: "500",
    },
    "& .objective-description": {
      paddingTop: "16px",
      fontSize: "14px",
    },
    "& .impact-header": {
      paddingRight: "8px",
      color: "#919eab",
    },
  },
  "& .key-result-topic-container": {
    margin: "16px 0",
    padding: "0 16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiTypography-root": {
      fontSize: "18px",
    },
  },
  "& .key-result-wrap": {
    height: "100%",
  },
  "& .key-result-wrap:last-child": {
    marginBottom: "0",
  },
  "& .key-result-list-container": {
    "& .key-result-item-container": {
      padding: "16px",
      "& .key-result-name": {
        flexGrow: 1,
        fontWeight: "500",
      },
      "& .key-result-description": {
        paddingTop: "16px",
        fontSize: "14px",
      },
      "& .due-date-container": {
        paddingTop: "16px",
        display: "flex",
        alignItems: "center",
        color: "#919eab",
        fontSize: "14px",
        "& .MuiSvgIcon-root": {
          marginRight: "8px",
        },
        "& .MuiTypography-root, & .MuiSvgIcon-root": {
          fontSize: "inherit",
          color: "inherit",
        },
      },
      "& .MuiDivider-root": {
        paddingTop: "16px",
      },
      "& .manager-label": {
        paddingRight: "8px",
        color: "#919eab",
      },
    },
  },
});

const UpdateProgressIndividualObjective = (props) => {
  const { match } = props;

  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [individualObjective, setIndividualObjective] = useState(null);
  const [isFetching, setIsFetching] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    let isMounted = true;
    dispatch(
      getIndividualObjectiveByIdIndividualObjective(
        match.params.idIndividualObjective
      )
    ).then((res) => {
      if (isMounted) {
        setIsFetching(false);
        if (res.status === 200) {
          setIndividualObjective(res.data);
        } else {
          setIsError(true);
        }
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <StyledRoot className="page">
      <Box padding="24px 0">
        <Container maxWidth="lg">
          {individualObjective && (
            <Fragment>
              <CardStyle style={{ backgroundColor: "white" }}>
                <Box padding="24px">
                  <Chip
                    label={`Objective ${dayjs()
                      .set("year", individualObjective.year)
                      .format(
                        i18n.resolvedLanguage === "th" ? "BBBB" : "YYYY"
                      )}, Q${individualObjective.quarter}`}
                    sx={{
                      border: "2px solid #DB4178",
                      color: "#DB4178",
                      bgcolor: "transparent",
                      fontSize: "20px",
                    }}
                  />
                  <div
                    style={{
                      padding: 8,
                      display: "flex",
                      flexDirection: "column",
                      gap: 8,
                      marginTop: "20px",
                    }}
                  >
                    <Typography fontSize="24px" fontWeight={500}>
                      {individualObjective.objectiveName}
                    </Typography>
                    {individualObjective.description && (
                      <Typography>{individualObjective.description}</Typography>
                    )}
                    <Typography>
                      <span style={{ paddingRight: "8px" }}>{`${t(
                        "TheResultingOutcome"
                      )}`}</span>
                      {individualObjective.impactName}
                    </Typography>
                  </div>
                </Box>
              </CardStyle>

              <Box
                className="key-result-topic-container"
                style={{ marginTop: "30px" }}
              >
                <Typography fontSize={18}>{t("KeyResult")}</Typography>
                <Typography fontSize={18}>
                  {individualObjective.keyResults.length} {`${t("List")}`}
                </Typography>
              </Box>

              <Box>
                {individualObjective.keyResults.map((keyResult, index) => (
                  <KeyResult key={index} data={keyResult} />
                ))}
              </Box>
            </Fragment>
          )}
        </Container>
      </Box>
    </StyledRoot>
  );
};

const StyledKeyResultRoot = styled(Box)({
  marginBottom: "16px",
  "&:last-child": {
    marginBottom: 0,
  },
  "& a": {
    color: "unset",
    textDecoration: "unset",
  },
});

const KeyResult = (props) => {
  const { data } = props;
  const { t, i18n } = useTranslation();

  return (
    <StyledKeyResultRoot>
      <Link
        to={`/okrs/update-progress/individual/keyresult/${data.idIndividualKeyResult}`}
      >
        <CardStyle>
          <Box padding="24px">
            <Box display="flex" alignItems="flex-start">
              <Typography flexGrow="1" fontSize="18px" fontWeight="500">
                {data.keyResultName}
              </Typography>
              <Box display="flex" alignItems="center">
                <Typography fontSize="14px">{`${t(
                  "UpdateStatus"
                )}`}</Typography>
                <ChevronRightRounded
                  style={{ marginLeft: "8px", fontSize: "22px" }}
                />
              </Box>
            </Box>
            <Box display="flex" alignItems="center" marginTop="8px">
              <AccessTimeRounded
                style={{ marginRight: "8px", fontSize: "16px" }}
              />
              <Typography fontSize="14px" lineHeight="1">
                {dayjs(data.startDate).format("D MMM BBBB")} -{" "}
                {dayjs(data.endDate).format("D MMM BBBB")}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" marginTop="8px">
              <Edit style={{ marginRight: "8px", fontSize: "16px" }} />
              <Typography fontSize="14px" lineHeight="1">{`${t(
                "Update"
              )} ${dayjs(data.updateDate).format(
                "D MMM BBBB HH:mm"
              )}`}</Typography>
            </Box>
          </Box>
        </CardStyle>
      </Link>
    </StyledKeyResultRoot>
  );
};

export default UpdateProgressIndividualObjective;
