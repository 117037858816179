import dayjs from "dayjs";
import ExcelJS from "exceljs";

export const exportToExcel = async (data, companyName) => {
  if (!Array.isArray(data)) {
    console.error("Data is not an array:", data);
    return;
  }

  const workbook = new ExcelJS.Workbook();
  const worksheet1 = workbook.addWorksheet("Probation");

  const headerRow = worksheet1.addRow([
    "บริษัท",
    "เจ้าของรายการ",
    "รายการ",
    "ผู้ตรวจสอบ",
    "ตำแหน่ง",
    "ฝ่าย",
    "ส่วน",
    "ดำเนินการภายใน(วัน)",
    "สถานะ",
    "วันที่",
  ]);

  const headerStyle = {
    font: {
      bold: true,
      size: 18,
      name: "TH SarabunPSK",
      color: { argb: "FFFFFF" },
    },
    alignment: { horizontal: "center", vertical: "middle" },
    fill: {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "002060" },
    },
  };

  headerRow.eachCell((cell) => {
    cell.style = headerStyle;
    cell.border = {
      top: { style: "thin", color: { argb: "000000" } },
      left: { style: "thin", color: { argb: "000000" } },
      bottom: { style: "thin", color: { argb: "000000" } },
      right: { style: "thin", color: { argb: "000000" } },
    };
  });

  headerRow.height = 50;

  worksheet1.columns = [
    { header: "บริษัท", key: "company", width: 25 },
    { header: "เจ้าของรายการ", key: "employee", width: 25 },
    { header: "รายการ", key: "item", width: 30 },
    { header: "ตำแหน่ง", key: "position", width: 25 },
    { header: "ผู้ตรวจสอบ", key: "inspector", width: 25 },
    { header: "ฝ่าย", key: "division", width: 20 },
    { header: "ส่วน", key: "department", width: 20 },
    { header: "ดำเนินการภายใน (วัน)", key: "days", width: 20 },
    { header: "สถานะ", key: "status", width: 15 },
    { header: "วันที่", key: "date", width: 20 },
  ];

  data.forEach((item) => {
    worksheet1.addRow({
      company: companyName || "-",
      employee: `${item.firstname_TH} ${item.lastname_TH}`,
      item: item.OnBoardingListName || "-",
      inspector:
        `${item.reviewer_firstname_TH} ${item.reviewer_lastname_TH}` || "",
      position: item.positionName || "-",
      division: item.divisionName || "-",
      department: item.departmentName || "-",
      days: item.day || "-",
      status: item.isApprove ? "สำเร็จ" : "กำลังดำเนินการ",
      date: dayjs(item.updateDate).format("DD/MM/YYYY HH:mm") || "-",
    });
  });

  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const firstEmployee = data.length > 0 ? data[0] : null;
    const fileName = firstEmployee
      ? `มอบหมายตรวจ-${firstEmployee.firstname_TH} ${firstEmployee.lastname_TH}.xlsx`
      : "มอบหมายตรวจ.xlsx";

    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(url);
  });
};
