import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, styled, Grid, IconButton, Divider } from "@mui/material";
import dayjs from "dayjs";

import EditIcon from "@mui/icons-material/Edit";
import KeyboardArrowRightRoundedIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import ButtonBlue from "../../../../shared/general/ButtonBlue";

import utils from "../../../../../../utils";

import DialogEdit from "./DialogEdit";

//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledWrapTop = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 16,
  "& .MuiTypography-body2": {
    margin: 0,
  },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledRoot = styled("div")({
  "& .salary": {
    "& .MuiTypography-h6": {
      marginRight: 8,
    },
    display: "flex",
    alignItems: "baseline",
  },
});

const SettingPayroll = (props) => {
  const { changeGroupTap, changeTabs } = props;
  const { t, i18n } = useTranslation();

  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const [openDialog, setOpenDialog] = useState(false);
  const [mode, setMode] = useState("");

  const handleClick = (mode) => {
    console.log("handleClick");
    setOpenDialog(true);
    setMode(mode);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const changeTimeToDate = (time) => {
    let splitTime = time.split(":");
    return new Date().setHours(splitTime[0], splitTime[1], splitTime[2]);
  };

  return (
    <StyledRoot>
      <StyledWrapTop>
        <StyledHeadLabel variant="body2" gutterBottom>
          {t("SalaryPayment")}
        </StyledHeadLabel>
        {!userProfile.readOnly && (
          <ButtonBlue
            variant="text"
            size="small"
            startIcon={<EditIcon />}
            // onClick={() => handleClick("cost")}
            onClick={() => {
              handleClick("payroll");
            }}
          >
            {t("EditData")}
          </ButtonBlue>
        )}
      </StyledWrapTop>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("CalculateSalary")} ?
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.isPayroll ? `${t("Yes")}` : `${t("No")}`}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <StyledDivider />

      <StyledWrapTop>
        <StyledHeadLabel variant="body2" gutterBottom>
          {t("IncomeTax")}
        </StyledHeadLabel>
      </StyledWrapTop>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("CalculateIncomeTax")} ?
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.isTax ? `${t("Yes")}` : `${t("No")}`}
            </Typography>
          </div>
        </Grid>
        {/* <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("PayingIncomeTax")} ?
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.isSelfTaxPaid
                ? `${t("EmployeePayIncomeTax")}`
                : `${t("CompanyPayIncomeTax")}`}
            </Typography>
          </div>
        </Grid> */}
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("IncomeUnderSection")} ?
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.incomeUnderSection || `${t("NotSpecified")}`}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <StyledDivider />
      <StyledWrapTop>
        <StyledHeadLabel variant="body2" gutterBottom>
          {t("SSO")}
        </StyledHeadLabel>
      </StyledWrapTop>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("CalculateSSO")} ?
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.isSso ? `${t("Yes")}` : `${t("No")}`}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <StyledDivider />
      <StyledWrapTop>
        <StyledHeadLabel variant="body2" gutterBottom>
          {t("ProvidentFund")}
        </StyledHeadLabel>
      </StyledWrapTop>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("CalculateProvidentFund")} ?
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.isPf ? `${t("Yes")}` : `${t("No")}`}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <StyledDivider />
      <StyledWrapTop>
        <StyledHeadLabel variant="body2" gutterBottom>
          {t("OT")}
        </StyledHeadLabel>
      </StyledWrapTop>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("CalculateOT")} ?
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.isOT ? `${t("Yes")}` : `${t("No")}`}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={4}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("OvertimePayRate")} ({t("Times")})
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.xOT || `ตามตั้งค่าบริษัท`}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={4}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("HolidayPayRate")} ({t("Times")})
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.xWorkingHoliday || `ตามตั้งค่าบริษัท`}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={4}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("OvertimePayRateOnHolidays")} ({t("Times")})
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.xOTHoliday || `ตามตั้งค่าบริษัท`}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <StyledDivider />
      <StyledWrapTop>
        <StyledHeadLabel variant="body2" gutterBottom>
          {t("ShiftFee")}
        </StyledHeadLabel>
      </StyledWrapTop>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("CalculateShiftFee")} ?
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.isShiftFee ? `${t("Yes")}` : `${t("No")}`}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <StyledDivider />
      <StyledWrapTop>
        <StyledHeadLabel variant="body2" gutterBottom>
          {t("DiligenceAllowance")}
        </StyledHeadLabel>
      </StyledWrapTop>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div>
            <StyledHeadLabel color="text.secondary">
              {t("CalculateDiligenceAllowance")} ?
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.isDiligenceAllowance
                ? `${t("Yes")}`
                : `${t("No")}`}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <StyledDivider />
      <StyledWrapTop>
        <StyledHeadLabel variant="body2" gutterBottom>
          นำกลับคำนวณเงินเดือน
        </StyledHeadLabel>
      </StyledWrapTop>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div>
            <StyledHeadLabel color="text.secondary">
              นำกลับมาคำนวณ ?
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.isReturnPayroll ? `${t("Yes")}` : `${t("No")}`}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <StyledDivider />
      <StyledWrapTop>
        <StyledHeadLabel variant="body2" gutterBottom>
          ส่วนคำนวณจ่ายพนักงานต่างชาติ
        </StyledHeadLabel>
      </StyledWrapTop>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <div>
            <StyledHeadLabel color="text.secondary">
              วิธีคำนวณยอดจำนวนเงิน
            </StyledHeadLabel>
            <Typography variant="h6">
              {employeeProfile.isForeignPaySpecify === null
                ? "ยังไม่ระบุ"
                : employeeProfile.isForeignPaySpecify === 1
                ? `หักจำนวนตามระบุ`
                : `หักเปอร์เซ็นต์`}
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          {employeeProfile.isForeignPaySpecify !== null && (
            <div>
              <StyledHeadLabel color="text.secondary">
                จำนวนเงิน/จำนวนเปอร์เซ็นต์
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.foreignPayTH || 0}
              </Typography>
            </div>
          )}
        </Grid>
      </Grid>

      <DialogEdit
        mode={mode}
        open={openDialog}
        handleCloseDialog={handleCloseDialog}
      />
    </StyledRoot>
  );
};

export default SettingPayroll;
