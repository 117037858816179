import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Pagination from "../../../shared/general/Pagination";
import ShowMiniData from "../../../shared/pages/okrs/components/ShowMiniData";
import { useTranslation } from "react-i18next";
import FlagIcon from "@mui/icons-material/Flag";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import SearchIcon from "@mui/icons-material/Search";
import VpnKeyRoundedIcon from "@mui/icons-material/VpnKeyRounded";
import EventIcon from "@mui/icons-material/Event";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import PostAddIcon from "@mui/icons-material/PostAdd";
import DescriptionIcon from "@mui/icons-material/Description";

import { useSelector, useDispatch } from "react-redux";
import { getAllTeamPlan } from "../../../../../actions/TeamPlans";

import {
  Grid,
  Button,
  Toolbar,
  TextField,
  InputAdornment,
  Typography,
  Avatar,
  LinearProgress,
  IconButton,
  Container,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";
import { styled } from "@mui/styles";

const ContainerStyled = styled(Container)({
  marginTop: "100px",
});

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiGrid-container": {
      marginBottom: "0 !important",
    },
    "& .MuiCardContent-root": {
      padding: 0,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 16,
    },
  },
  quater: {
    backgroundColor: "#e53935",
    fontWeight: "600",
    width: 48,
    height: 48,
  },
  PlanList_Object: {
    fontSize: "1.3rem",
    fontWeight: "600",
    color: "#222f3e",
  },
  PlanList_Impact: {
    color: "#9e9e9e",
    marginTop: 8,
  },
  PlanList_KR: {
    color: "#222f3e",
    fontSize: "1.1rem",
    fontWeight: "700",
  },
  PlanList_Status: {
    display: "flex",
    alignItems: "center",
    color: "#9e9e9e",
    marginBottom: 8,
  },
  noData_Section: {
    display: "flex",
    alignItems: "center",
  },
  noDataSvg: {
    fontSize: "30px",
    marginRight: 8,
  },
  inProgress: {
    backgroundColor: "#eeeeee",
    color: "#9e9e9e",
    minWidth: "100px",
    borderRadius: "15px",
    padding: "5px 10px 5px 10px",
    textAlign: "center",
    marginLeft: 8,
    "&:hover": {
      backgroundColor: "#eeeeee",
    },
  },
  achieved: {
    backgroundColor: "#e0f2f1",
    color: "#009688",
    width: "90px",
    borderRadius: "15px",
    padding: "5px",
    textAlign: "center",
    marginLeft: 8,
    "&:hover": {
      backgroundColor: "#e0f2f1",
    },
  },
  notAchieved: {
    backgroundColor: "#f9dde0",
    color: "#c62828",
    width: "110px",
    borderRadius: "15px",
    padding: "5px",
    textAlign: "center",
    marginLeft: 8,
    "&:hover": {
      backgroundColor: "#f9dde0",
    },
  },
  cardList_History: {
    cursor: "pointer",
    backgroundColor: "#fff3e0",
    color: "#ff9800",
    width: 40,
    height: 40,
    marginRight: 8,
    marginTop: 8,
  },
  cardList_Edit: {
    cursor: "pointer",
    backgroundColor: "#e8f5e9",
    color: "#4caf50",
    width: 40,
    height: 40,
    marginRight: 8,
    marginTop: 8,
  },
  cardList_KR: {
    backgroundColor: "#ffebee",
    color: "#f44336",
  },
}));

function TeamUpdate() {
  const {t,i18n} = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { result: teamPlanStore } = useSelector((state) => state.TeamPlans);

  const [planList, setPlanList] = useState([]);
  const [planItems, setPlanItems] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(2);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = planList.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const fetchData = () => {
    dispatch(getAllTeamPlan());
  };

  useEffect(() => {
    if (teamPlanStore) {
      const team = "HR TPE";

      const records = teamPlanStore.filter((item) => {
        return (
          item.teamName.includes(team) &&
          item.planStatus.includes("Approved") &&
          item.achieveResult == null
        );
      });

      setPlanList(records);
      setPlanItems(records);
      setIsLoading(false);
    }
  }, [teamPlanStore]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let x = [...planItems];
    x = x.filter((y) => {
      return y.objectiveName
        .toLowerCase()
        .includes(searchKey.toLocaleLowerCase());
    });
    setPlanList(x);
  }, [searchKey]);

  return (
    <ContainerStyled>
      <div className="card-section">
        <div className="header-section">
          <Grid container>
            <Grid item>
              <h3 className="header-topic">Team Plan Update</h3>
            </Grid>
          </Grid>
        </div>
        <Toolbar>
          <Grid container justifyContent="flex-end">
            <Grid item xl={4} md={4} lg={4} sm={6} xs={12}>
              <TextField
                variant="outlined"
                style={{ width: "100%" }}
                label="Search Objective"
                value={searchKey}
                onChange={(e) => setSearchKey(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </div>
      {isLoading ? (
        <LinearProgress />
      ) : (
        <div>
          {(planList.length > 0 &&
            currentPosts.map((item) => (
              <div key={item.planTeamId}>
                <Typography variant="h6" className="keyResult">
                  <FlagIcon fontSize="large" className="svgKey" />
                  Objective
                </Typography>
                <div className="cardPlan_Objective">
                  <Grid
                    container
                    alignItems="flex-start"
                    alignContent="space-between"
                  >
                    <Grid item xs={3} sm={2} md={1} lg={1} xl={1}>
                      <Avatar className={classes.quater}>{item.quater}</Avatar>
                    </Grid>
                    <Grid item xs={7} sm={7} md={8} lg={8} xl={8}>
                      <Typography
                        className={classes.PlanList_Object}
                        gutterBottom
                      >
                        {item.objectiveName}
                      </Typography>
                    </Grid>
                    <Grid
                      container
                      item
                      xs={2}
                      sm={3}
                      md={3}
                      lg={3}
                      xl={3}
                      justifyContent="flex-end"
                    >
                      <Avatar
                        className={classes.cardList_History}
                        onClick={() => {
                          history.push({
                            pathname: `/teamrecords/${item.objectiveName}`,
                            state: { planList: item },
                          });
                        }}
                      >
                        <DescriptionIcon />
                      </Avatar>
                      <Avatar
                        className={classes.cardList_Edit}
                        onClick={() => {
                          history.push({
                            pathname: `/updateKRTeam/${item.objectiveName}`,
                            state: { planList: item },
                          });
                        }}
                      >
                        <PostAddIcon />
                      </Avatar>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={2} md={1} lg={1} xl={1} sm={2}></Grid>
                    <Grid item xs={10} sm={10} md={8} lg={8} xl={8}>
                      <Typography className={classes.PlanList_Status}>
                        {item.impactName}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container>
                    <Grid item xs={2} sm={2} md={1} lg={1} xl={1}></Grid>
                    <Grid item xs={10} sm={10} md={10} lg={9} xl={9}>
                      <ShowMiniData
                        date={item.dateCreate}
                        status={item.planStatus}
                        progress={item.achieveResult}
                      />
                    </Grid>
                  </Grid>
                </div>
                <Typography variant="h6" className="keyResult">
                  <VpnKeyRoundedIcon fontSize="large" className="svgKey" />
                  {t("KeyResult")}
                </Typography>
                {item.keyResult1 && (
                  <div className="cardPlan">
                    <Grid container alignItems="center">
                      <Grid item xs={2} md={1} lg={1}>
                        <Avatar className={classes.cardList_KR}>
                          <KeyboardArrowRightIcon />
                        </Avatar>
                      </Grid>
                      <Grid item xs={10} md={8} lg={8}>
                        <Typography className={classes.PlanList_KR}>
                          {item.keyResult1}
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                )}
                {item.keyResult2 && (
                  <div className="cardPlan">
                    <Grid container alignItems="center">
                      <Grid item xs={2} md={1} lg={1}>
                        <Avatar className={classes.cardList_KR}>
                          <KeyboardArrowRightIcon />
                        </Avatar>
                      </Grid>
                      <Grid item xs={10} md={8} lg={8}>
                        <Typography className={classes.PlanList_KR}>
                          {item.keyResult2}
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                )}
                {item.keyResult3 && (
                  <div className="cardPlan" style={{ marginBottom: "30px" }}>
                    <Grid container alignItems="center">
                      <Grid item xs={2} md={1} lg={1}>
                        <Avatar className={classes.cardList_KR}>
                          <KeyboardArrowRightIcon />
                        </Avatar>
                      </Grid>
                      <Grid item xs={10} md={8} lg={8}>
                        <Typography className={classes.PlanList_KR}>
                          {item.keyResult3}
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                )}
              </div>
            ))) || (
            <div className="cardPlan">
              <Grid container justifyContent="center">
                <Grid item>
                  <Typography variant="h6" className={classes.noData_Section}>
                    <FindInPageIcon className={classes.noDataSvg} />
                    No Data
                  </Typography>
                </Grid>
              </Grid>
            </div>
          )}
          {planList.length > 0 ? (
            <Pagination
              paginate={paginate}
              postsPerPage={postsPerPage}
              totalPosts={planList.length}
            />
          ) : null}
        </div>
      )}
    </ContainerStyled>
  );
}

export default TeamUpdate;
