import React, { useEffect, useState } from "react";
import DrawerCustom from "../../../../shared/general/Drawer";
import { Box, Grid, MenuItem, Typography, styled } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { getEmployeeProfile } from "../../../../../../actions/employee";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  addEmployeePerformance,
  updateEmployeePerformance,
} from "../../../../../../actions/estimatePerformance";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import { openNotificationAlert } from "../../../../../../actions/notificationAlert";
import { updateUser } from "../../../../../../actions/user";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
  "& .field-label": {
    paddingBottom: "4px",
    fontWeight: "500",
    fontSize: "14px",
  },
});

const DrawerEditRole = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { drawerConfig, onClose } = props;

  const { result: employeeProfile } = useSelector((state) => state.employeeProfile);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const SelectRoleMenuList = {
    "1": t("AppMenu.Employees"),
    "2": `${t("AppMenu.Employees")} + ${t("Manager")}`,
    "3": t("Manager"),
  }

  const validateYupSchema = yup.object({
    idRole: yup.string().required(t("PleaseSelectInformation")),
  });

  const useHookForm = useForm({
    defaultValues: {
      idRole: "",
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all",
  });

  useEffect(() => {
    if (drawerConfig.isOpen === true) {
      useHookForm.reset({
        idRole: drawerConfig.data.idRole || "",
      });
    }
  }, [drawerConfig.isOpen]);

  const onSubmit = async (data) => {
    const formData = {
      idRole: data.idRole,
      idEmployees: employeeProfile.idEmployees,
      UpdateBy: userProfile.idEmployees,
      updateDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    }

    const result = await dispatch(updateUser(formData));

    if (result) {
      if (result.status === 200) {
        
        dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
        onClose();
        dispatch(openNotificationAlert({
          type: "success",
          message: t("DataSaveSuccessful")
        }))
      } else {
        dispatch(openNotificationAlert({
          type: "error",
          message: t("AnErrorOccurred")
        }))
      }
    } else {
      dispatch(openNotificationAlert({
        type: "error",
        message: t("AnErrorOccurred")
      }))
    }
  };

  return (
    <DrawerCustom
      title={t("EditPermission")}
      anchor="right"
      open={drawerConfig.isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <StyledRoot>
        <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className="field-label">
                {t("SystemAccessPermission")}
              </Typography>
              <Controller
                name="idRole"
                control={useHookForm.control}
                render={({ field, fieldState }) => (
                  <TextFieldTheme
                    {...field}
                    label=""
                    select
                    helperText={fieldState.error? fieldState.error.message: null}
                    error={fieldState.error ? true : false}
                    SelectProps={{
                      displayEmpty: true,
                      placeholder: t("SelectData"),
                      renderValue: (selected) => {
                        if(selected.length === 0){
                          return <Typography color="text.secondary">{t("SelectData")}</Typography>
                        } else {
                          return <Typography>{SelectRoleMenuList[selected]}</Typography>
                        }
                      }
                    }}
                  >
                    <MenuItem value="" disabled>{t("SelectData")}</MenuItem>
                    {Object.keys(SelectRoleMenuList).map((key, index) => (
                      <MenuItem key={index} value={key}>{SelectRoleMenuList[key]}</MenuItem>
                    ))}
                  </TextFieldTheme>
                )}
              />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="space-between">
              <ButtonBlue
                variant="text"
                onClick={onClose}
                disabled={useHookForm.formState.isSubmitting}
              >
                {t("Cancel")}
              </ButtonBlue>
              <ButtonBlue
                variant="contained"
                type="submit"
                disabled={useHookForm.formState.isSubmitting}
              >
                {t("Save")}
              </ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DrawerEditRole;
