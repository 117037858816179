import React, { useState, useEffect, useRef, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import makeStyles from "@mui/styles/makeStyles";
import { NavLink } from "react-router-dom";
import Slider from "react-slick";
import clsx from "clsx";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import CircularProgress from "@mui/material/CircularProgress";
import { Button } from "@mui/material";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Typography, Avatar, Grid, Menu, MenuItem } from "@mui/material";

import DoneAllIcon from "@mui/icons-material/DoneAll";
import TranslateIcon from "@mui/icons-material/Translate";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneIphoneOutlinedIcon from "@mui/icons-material/PhoneIphoneOutlined";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import FacebookIcon from "@mui/icons-material/Facebook";

import ButtonBlue from "../shared/general/ButtonBlue";
import ReactSlickDemo from "./slick";

import Unicorn from "../assets/unicorn-1.png";
import UnicornBG from "../assets/background/5160998.jpg";

import Ant1 from "./assets/ant1.png";
import Coin13D from "./assets/coin3d-1.png";
import Cloud1 from "./assets/cloud1.png";
import Cloud2 from "./assets/cloud2.png";
import Logo from "../assets/logo/unihr_logo.png";

import Recruitment from "./assets/recruitment.png";
import HRCore from "./assets/hr-core.png";
import TimeManagement from "./assets/time-management.png";
import Payroll from "./assets/payroll.png";
import Quote from "./assets/quote.png";
import Quote1 from "./assets/quote1.png";
import Quote2 from "./assets/quote2.png";
import Quote3 from "./assets/quote3.png";
import Quote4 from "./assets/quote4.png";
import Quote5 from "./assets/quote5.png";
import VdoBenefit from "./assets/vdo-benefit.png";
import TechBG from "./assets/Tech_BG.jpg";
import WebApp from "./assets/web_app.png";
import Rpa from "./assets/rpa.png";
import Blockchain from "./assets/blockchain.png";
import Ai from "./assets/ai.png";
import Robot from "./assets/robot.png";
import Signature from "./assets/digital-signature.png";
import Free from "./assets/free.png";
import SCG from "./assets/Siam_Cement_Group_Logo.svg.png";
import SCGC from "../assets/logo/SCG_Chem.png";
import ZERO_TO_ONE from "../assets/logo/zero_to-one.png";
import TPE from "../assets/logo/0100.png";
import ICO from "../assets/logo/SCG ico.png";
import QGEN from "../assets/logo/qgen.png";
import NPI from "../assets/logo/NPI.png";
import GRANDSIAM from "../assets/logo/grandsiam.png";
import CustomerService from "./assets/customerservice.png";
import Light from "./assets/light.png";
import Gold from "./assets/gold.png";
import CheckList from "./assets/check-list.png";
import Timer from "./assets/timer.png";
import Calendar from "./assets/calendar.png";
import Calendar1 from "./assets/calendar1.png";

import { logout } from "../../../actions/auth";

import "./index.css";

import { isEmptyArray } from "formik";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles({
  firstBg: {
    height: 600,
    ["@media only screen and (max-width: 1200px)"]: {
      height: 320,
    },
    ["@media only screen and (max-width: 600px)"]: {
      height: 560,
    },
  },
  secondBg: {
    height: 300,
    "& .wrapSecondContent": {
      height: "100%",
      "& .left": {
        "& .left-2": {
          fontSize: 20,
          marginTop: 16,
        },
        ["@media only screen and (max-width: 600px)"]: {
          marginTop: 36,
          margin: "0 8px",
          textAlign: "center",
        },
        ["@media only screen and (min-width:600px)"]: {},
        ["@media only screen and (min-width:768px)"]: {
          marginTop: 72,
          margin: "auto",
          width: "70%",
          textAlign: "center",
          "& .left-2": {
            fontSize: 20,
            marginTop: 16,
            width: "100%",
          },
        },
        ["@media only screen and (min-width:992px)"]: {
          "& .left-2": {
            maxWidth: 450,
          },
          marginTop: 120,
          marginLeft: 56,
        },
      },
      "& .right": {
        "& .inner-right": {
          position: "relative",
          height: "100%",
          ["@media only screen and (max-width: 600px)"]: {
            display: "flex",
            justifyContent: "center",
            margin: "auto",
            flexWrap: "wrap",
            padding: "20px 0",
            width: "100%",
            "& .item1, .item2, .item3, .item4, .item5, .item6, .item7": {
              display: "flex",
              margin: 16,
            },
          },
          ["@media only screen and (min-width:600px)"]: {},
          ["@media only screen and (min-width:768px)"]: {
            display: "flex",
            justifyContent: "center",
            margin: "auto",
            flexWrap: "wrap",
            padding: "20px 0",
            width: "80%",
            "& .item1, .item2, .item3, .item4, .item5, .item6, .item7": {
              display: "flex",
              margin: 16,
            },
          },
          ["@media only screen and (min-width:992px)"]: {
            display: "flex",
            justifyContent: "center",
            margin: "auto",
            flexWrap: "wrap",
            padding: "100px 0",
            width: "80%",
            "& .item1, .item2, .item3, .item4, .item5, .item6, .item7": {
              display: "flex",
              margin: 16,
            },
          },
          ["@media only screen and (min-width:1200px)"]: {
            display: "block",
            justifyContent: "center",
            margin: 0,
            flexWrap: "wrap",
            padding: 0,
            width: "100%",
            "& .item1": {
              position: "absolute",
              top: 80,
              left: "30%",
            },
            "& .item2": {
              position: "absolute",
              top: 80,
              right: "30%",
            },
            "& .item3": {
              position: "absolute",
              top: 230,
              left: "20%",
            },
            "& .item4": {
              position: "absolute",
              top: 230,
              left: "42.5%",
            },
            "& .item5": {
              position: "absolute",
              top: 230,
              right: "20%",
            },
            "& .item6": {
              position: "absolute",
              top: 380,
              right: "30%",
            },
            "& .item7": {
              position: "absolute",
              top: 380,
              left: "30%",
            },
          },
        },
      },
    },
    ["@media only screen and (max-width: 600px)"]: {
      height: 480,
    },
    // ["@media only screen and (max-width: 320px)"]: {
    //   height: 1000,
    // },
  },
  avatarAndBoost: {
    textAlign: "center",
  },
  thirdBg: {
    "& .MuiTypography-h3": {
      ["@media only screen and (max-width: 600px)"]: {
        padding: "16px 0",
        textAlign: "center",
      },
      ["@media only screen and (min-width:768px)"]: {
        padding: "16px 0",
        textAlign: "center",
      },
      ["@media only screen and (min-width:992px)"]: {
        paddingTop: 24,
        paddingLeft: 56,
        textAlign: "left",
      },
    },

    ["@media only screen and (max-width: 600px)"]: {},
    ["@media only screen and (max-width: 320px)"]: {},
  },
  fourBg: {
    "& .MuiTypography-h3": {
      padding: "32px 0",
    },
  },
  itemSectFour: {
    maxWidth: 500,
    "& .text-2": {
      minHeight: 48,
      fontSize: 22,
    },
    "& .itemImageSectFour": {
      margin: "36px 0",
    },
  },
  fiveBg: {
    height: 482,
    overflow: "auto",
    "& .wrapContentFive": {
      height: "100%",
      "& .left": {
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      },
      "& .innerContent": {
        height: "100%",
        maxWidth: 550,
        margin: "auto",
        "& .MuiTypography-h3": {
          paddingTop: 75,
          ["@media only screen and (max-width: 600px)"]: {
            paddingTop: 28,
          },
        },
        "& .MuiTypography-h6": {
          paddingTop: 24,
        },
      },
    },
    "& .MuiTypography-h4": {
      paddingLeft: 56,
    },
  },
  sixBg: {
    ["@media only screen and (max-width: 600px)"]: {
      height: 650,
    },
  },
  avatar: {
    width: 80,
    height: 80,
  },
});

const StyledFragment = styled("div")({
  backgroundImage: `url(${UnicornBG})`,
});

const StyledRoot = styled("div")(({}) => ({
  "& .MuiTypography-root": {
    fontFamily: `'Francois One' !important`,
    color: "#212B36",
  },
  ["@media (min-width: 900px)"]: {
    marginTop: 76,
  },
  ["@media (min-width: 600px)"]: {
    marginTop: 64,
  },
}));

const AvatarAndBoost = ({ value, boost }) => {
  //const classes = useStyles();
  return (
    <div className="avatarAndBoost">
      <Typography variant="subtitle1">{boost}</Typography>
      <Avatar
        alt={value.username}
        src={`${process.env.REACT_APP_API_URL}image/profile/${value.image}`}
        className="avatar"
      />
    </div>
  );
};

const StyledWrapFirstContent = styled("div")(({}) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  justifyContent: "center",
  height: "100%",
  position: "relative",
  "& .MuiTypography-root": {
    color: "#FFFFFF",
  },
  "& .wrap-panel-3d": {
    ["@media (max-width: 1200px)"]: {
      display: "none",
    },
  },
  "& .wrap3D": {
    position: "absolute",
    animation: "$ant 3.5s ease-in-out 0s infinite normal ",
    bottom: 70,
    left: 140,
    zIndex: 10,
  },
  "& .wrapCoin13D": {
    position: "absolute",
    right: -4,
    top: 152,
    zIndex: 1000,
  },
  "& .wrapTimer": {
    position: "absolute",
    right: 100,
    top: -7,
    zIndex: 1000,
  },
  "& .wrapCalendar": {
    position: "absolute",
    left: 60,
    top: -5,
    zIndex: 1100,
  },
  "& .wrapCloud1": {
    position: "absolute",
    left: -45,
    top: 16,
    zIndex: 1000,
  },
  "& .wrapCloud2": {
    position: "absolute",
    right: 0,
    bottom: 102,
    zIndex: 1000,
  },
  "& .wrapCalendar1": {
    position: "absolute",
    right: 22,
    bottom: 39,
    zIndex: 1100,
  },
  "& .wrapLight": {
    position: "absolute",
    left: -18,
    top: 188,
    zIndex: 1000,
  },
  "& .wrapCheckList": {
    position: "absolute",
    left: 70,
    bottom: 9,
    zIndex: 1000,
  },

  "& .wrap-header-text": {
    marginTop: 140,
    ["@media only screen and (max-width: 1200px)"]: {
      marginTop: 80,
    },
  },
  "& .wrap-text": {
    "& .MuiTypography-root": {
      lineHeight: 1.3,
    },
    "& .main-line1": {
      fontSize: 50,
      color: "#212B36",
      "& span": {
        fontSize: 50,
        color: "#46cbe2",
      },
      ["@media only screen and (max-width: 600px)"]: {
        fontSize: 40,
        textAlign: "center",
      },
    },
    "& .main-line2": {
      fontSize: 40,
      color: "#212B36",
      "& span": {
        fontSize: 50,
        color: "#46cbe2",
      },
      ["@media only screen and (max-width: 600px)"]: {
        fontSize: 36,
        textAlign: "center",
      },
    },
    "& .main-line3": {
      color: "#999999",
      fontSize: 24,
      ["@media only screen and (max-width: 600px)"]: {
        textAlign: "right",
      },
    },
  },
  "& .wrap-start-button": {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
  },
  "& .header3D": {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    marginTop: 55,
    "& .wrapCircle3D": {
      width: 300,
      height: 300,
      position: "absolute",
    },
  },
}));

const StyledWrapTwoContent = styled("div")(({}) => ({
  "& .grid-container": {
    display: "grid",
    gridTemplateColumns: "auto auto auto auto",
    gridGap: 20,
    padding: 10,
    marginTop: 48,
    alignItems: "center",
    ["@media (max-width: 768px)"]: {
      gridTemplateColumns: "repeat(2, 1fr)",
      padding: 0,
      gridGap: 0,
      rowGap: 20,
    },
    ["@media (min-width: 768px)"]: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
    ["@media (min-width: 900px)"]: {
      gridTemplateColumns: "repeat(4, 1fr)",
    },
    ["@media (min-width: 1200px)"]: {
      gridTemplateColumns: "repeat(4, 1fr)",
    },
  },
  "& .grid-item": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const StyledWrapThreeContent = styled("div")(({}) => ({
  marginTop: 160,
  "& .grid-container": {
    display: "grid",
    gridTemplateColumns: "auto auto auto auto",
    gridGap: 20,
    padding: 10,
    marginTop: 48,
    ["@media (max-width: 768px)"]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    ["@media (min-width: 768px)"]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    ["@media (min-width: 900px)"]: {
      gridTemplateColumns: "repeat(5, 1fr)",
    },
    ["@media (min-width: 1200px)"]: {
      gridTemplateColumns: "repeat(5, 1fr)",
    },
  },
  "& .grid-item": {
    width: 250,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    ["@media (max-width: 768px)"]: {
      gridTemplateColumns: "repeat(1, 1fr)",
      width: "100%",
    },
  },
}));

const StyledWrapFourContent = styled("div")(({}) => ({
  marginTop: 140,
  "& .wrapFourContent": {
    "& .left": {
      display: "flex",
      justifyContent: "center",
      "& .img-ant1": {
        width: 200,
        ["@media only screen and (max-width: 600px)"]: {
          position: "absolute",
          right: 0,
          bottom: 28,
          width: 100,
        },
      },
      "& .left-1": {
        display: "flex",
        alignItems: "center",
        marginBottom: 28,
        "& .MuiTypography-root": {
          marginLeft: 16,
        },
      },
      ["@media only screen and (max-width: 600px)"]: {
        textAlign: "center",
        flexDirection: "row-reverse",
        position: "relative",
      },
      ["@media only screen and (min-width:600px)"]: {},
      ["@media only screen and (min-width:768px)"]: {},
      ["@media only screen and (min-width:992px)"]: {
        paddingTop: 40,
      },
    },
    "& .outer-right": {
      ["@media only screen and (max-width:1200px)"]: {
        display: "none",
      },
      "& .right": {
        height: "100%",
        display: "flex",
        alignItems: "center",
        ["@media only screen and (max-width: 600px)"]: {
          justifyContent: "center",
        },
        ["@media only screen and (min-width:768px)"]: {
          width: "100%",
          justifyContent: "center",
        },
        "& .right-vdo": {
          ["@media only screen and (max-width: 600px)"]: {
            width: 350,
          },
          ["@media only screen and (max-width: 320px)"]: {
            width: 300,
          },
          ["@media only screen and (min-width:600px)"]: {},
          ["@media only screen and (min-width:768px)"]: {
            width: 400,
          },
          ["@media only screen and (min-width:992px)"]: {
            width: 350,
          },
          ["@media only screen and (min-width:1200px)"]: {
            width: 480,
          },
        },
      },
    },
  },
}));

const StyledWrapFiveContent = styled("div")(({}) => ({
  marginTop: 120,
  marginBottom: 120,
}));

const StyledWrapSixContent = styled("div")(({}) => ({
  background: `linear-gradient(to bottom, rgba(22, 28, 36, 0.88), rgba(22, 28, 36, 0.88)),url(${TechBG})`,
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  padding: "80px 0px",
  "& .grid-container": {
    display: "grid",
    //gridTemplateColumns: "320px 320px 320px 320px 320px",
    gridGap: 20,
    padding: 10,
    marginTop: 48,
    justifyContent: "center",
    /* Screen larger than 600px? 2 column */
    ["@media (min-width: 600px)"]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    ["@media (min-width: 900px)"]: {
      gridTemplateColumns: "repeat(3, 1fr)",
    },
  },
  "& .itemTech": {
    height: 120,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 24,
    backgroundColor: "#919eab14",
    borderRadius: 8,
    "& img": {
      marginBottom: 16,
    },
    "& .MuiTypography-root": {
      color: "#FFFFFF",
    },
    "& .primaryText": {
      fontSize: 20,
    },
    "& .secondaryText": {
      fontSize: 16,
      color: "#919EAB",
    },
  },
}));

const StyledWrapSevenContent = styled("div")(({}) => ({
  marginTop: 140,
  marginBottom: 140,
  "& .MuiContainer-root": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

const StyledWrapEightContent = styled("div")(({}) => ({
  marginTop: 120,
  marginBottom: 120,
  "& .MuiContainer-root": {
    "& .wrapFree": {
      display: "flex",
      justifyContent: "space-evenly",
      height: 400,
      borderRadius: 16,
      backgroundImage: "linear-gradient(135deg, #007afe 0%, #0046b7 100%)",
      ["@media only screen and (max-width: 768px)"]: {
        flexDirection: "column",
        height: 580,
      },
      "& .image-free": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& img": {
          width: 350,
          ["@media only screen and (max-width: 768px)"]: {
            width: 280,
          },
        },
      },
      "& .text-free": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        ["@media only screen and (max-width: 600px)"]: {
          textAlign: "center",
        },
        "& .MuiFormControl-root": {
          marginTop: 24,
          backgroundColor: "#ffffff",
          borderRadius: 8,
          "& fieldset": {
            border: "none",
          },
        },
      },
    },
  },
}));

const StyledWrapCustomerService = styled("div")(({}) => ({
  marginBottom: 120,
  backgroundColor: "#F9FAFB",
  padding: "30px 0px",
  "& .wrapDetail": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
    "& .item": {
      marginBottom: 24,
      "& .label": {
        display: "flex",
        marginBottom: 8,
      },
    },
  },
}));

const StyledWrapCopyRight = styled("div")(({}) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  marginBottom: 36,
}));

const StyledAppBar = styled(AppBar)(({}) => ({
  boxShadow: "none",
  backgroundColor: "transparent",
  "& .MuiToolbar-regular": {
    color: "#212b36",
    backgroundColor: "#ffffffcc",
    transition:
      "height 250ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, background-color 250ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
    backdropFilter: "blur(6px)",
    ["@media (min-width: 900px)"]: {
      height: 76,
    },

    "& .MuiContainer-root": {
      display: "flex",
      alignItems: "center",
      ["@media (max-width: 768px)"]: {
        padding: 0,
      },
      "& .headerAction": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "& .translate, .MuiDivider-root, .contact-us": {
          ["@media (max-width: 768px)"]: {
            display: "none",
          },
        },
      },
    },
  },
}));

const StyledIconButtonTranslate = styled(IconButton)(({}) => ({
  color: "#007AFE",
  backgroundColor: "#FFFFFF",
  border: "1px solid #007AFE",
  borderRadius: 8,
  padding: 8,
  marginRight: 8,
  fontSize: 28,
  // "& .MuiSvgIcon-root":{
  //   fontSize: 24,
  // }
  "&:hover": {
    transform: "scale(1.09) translateZ(0px)",
  },
}));

const StyledIconButtonContactSmall = styled(IconButton)({
  ["@media (min-width: 768px)"]: {
    display: "none",
  },
  color: "#007AFE",
  backgroundColor: "#FFFFFF",
  border: "1px solid #007AFE",
  borderRadius: 8,
  padding: 6,
  marginRight: 8,
  fontSize: 24,
  "&:hover": {
    color: "#FFFFFF",
    borderColor: "#0046b7",
    backgroundColor: "#0046b7",
  },
});

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const Landing = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [state, setState] = useState({ nav1: null, nav2: null });
  const slider1 = useRef();
  const slider2 = useRef();
  const { t, i18n } = useTranslation();
  const [anchorEl_Trans, setAnchorEl_Trans] = useState(null);
  const open_Trans = Boolean(anchorEl_Trans);

  const handleClick_Trans = (event) => {
    setAnchorEl_Trans(event.currentTarget);
  };
  const handleClose_Trans = () => {
    setAnchorEl_Trans(null);
  };

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    handleClose_Trans();
  };

  useEffect(() => {
    dispatch(logout());
    setState({
      nav1: slider1.current,
      nav2: slider2.current,
      isMobile: window.screen.width <= 768,
    });
  }, []);

  const { nav1, nav2 } = state;

  return (
    <StyledFragment>
      <ElevationScroll {...props}>
        <StyledAppBar>
          <Toolbar>
            <Container maxWidth="lg">
              <div>
                <img src={Logo} alt="logo" width={80} />
              </div>
              <div style={{ flexGrow: 1 }}></div>
              <div className={`headerAction`}>
                <div className="translate">
                  <StyledIconButtonTranslate
                    aria-label="translate"
                    size="small"
                    aria-controls={open_Trans ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open_Trans ? "true" : undefined}
                    onClick={handleClick_Trans}
                  >
                    <TranslateIcon fontSize="small" />
                  </StyledIconButtonTranslate>
                  <Menu
                    anchorEl={anchorEl_Trans}
                    open={open_Trans}
                    onClose={handleClose_Trans}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem onClick={() => changeLanguage("th")}>
        <img
          src="https://flagcdn.com/16x12/th.png"
          alt="ภาษาไทย"
          style={{ marginRight: "8px" }}
        />
        ภาษาไทย
      </MenuItem>
      <MenuItem onClick={() => changeLanguage("en")}>
        <img
          src="https://flagcdn.com/16x12/gb.png"
          alt="English"
          style={{ marginRight: "8px" }}
        />
        English
      </MenuItem>
      <MenuItem onClick={() => changeLanguage("la")}>
        <img
          src="https://flagcdn.com/16x12/la.png"
          alt="ລາວ"
          style={{ marginRight: "8px" }}
        />
        ລາວ
      </MenuItem>
      <MenuItem onClick={() => changeLanguage("kh")}>
        <img
          src="https://flagcdn.com/16x12/kh.png"
          alt="កម្ពុជា"
          style={{ marginRight: "8px" }}
        />
        កម្ពុជា
      </MenuItem>
      <MenuItem onClick={() => changeLanguage("mm")}>
        <img
          src="https://flagcdn.com/16x12/mm.png"
          alt="မြန်မာ"
          style={{ marginRight: "8px" }}
        />
        မြန်မာ
      </MenuItem>
      <MenuItem onClick={() => changeLanguage("cn")}>
        <img
          src="https://flagcdn.com/16x12/cn.png"
          alt="中国"
          style={{ marginRight: "8px" }}
        />
        中国
      </MenuItem>
      <MenuItem onClick={() => changeLanguage("kr")}>
        <img
          src="https://flagcdn.com/16x12/kr.png"
          alt="대한민국"
          style={{ marginRight: "8px" }}
        />
        대한민국
      </MenuItem>
      <MenuItem onClick={() => changeLanguage("vn")}>
        <img
          src="https://flagcdn.com/16x12/vn.png"
          alt="Việt Nam"
          style={{ marginRight: "8px" }}
        />
        Việt Nam
      </MenuItem>
                  </Menu>
                </div>
                <Divider
                  style={{ margin: "0 16px", height: 24 }}
                  orientation="vertical"
                />
                <div>
                  <ButtonBlue
                    className="contact-us"
                    variant="outlined"
                    component={NavLink}
                    to="/contact-us"
                  >
                    {t("ContactUs")}
                  </ButtonBlue>
                  <StyledIconButtonContactSmall
                    aria-label="contact"
                    component="span"
                    size="large"
                  >
                    <i class="fal fa-phone-rotary"></i>
                  </StyledIconButtonContactSmall>
                  <ButtonBlue
                    variant={"contained"}
                    style={{ marginLeft: 8 }}
                    component={NavLink}
                    to="/login"
                  >
                    {t("LogIn")}
                  </ButtonBlue>
                </div>
              </div>
            </Container>
          </Toolbar>
        </StyledAppBar>
      </ElevationScroll>
      <StyledRoot>
        <div className={`${classes.firstBg}`}>
          <Container maxWidth="lg">
            <StyledWrapFirstContent>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={7}>
                  <div className={`wrap-header-text`}>
                    <div className="wrap-text">
                      <Typography className={`main-line1`}>
                        Super HR platform for Asean
                      </Typography>
                      <Typography className={`main-line2`}>
                        <span variant="h1">5X</span> Cost saving
                      </Typography>
                      <Typography className={`main-line2`}>
                        <span variant="h1">10X</span> Workload saving
                      </Typography>
                      <Typography className={`main-line2`}>
                        <span variant="h1">20X</span> HR process & performance
                      </Typography>
                      <Typography className={`main-line3`}>By AntHR</Typography>
                    </div>
                    <div className="wrap-start-button">
                      <ButtonBlue
                        component={NavLink}
                        to="/contact-us"
                        variant={"contained"}
                        style={{
                          marginTop: 20,
                          marginBottom: 20,
                          borderRadius: 8,
                          height: 48,
                          fontSize: 18,
                        }}
                        endIcon={<ChevronRightIcon />}
                      >
                        Try for FREE
                      </ButtonBlue>
                    </div>
                  </div>
                </Grid>
                <Grid item lg={5} className="wrap-panel-3d">
                  <div className={`header3D`}>
                    <div className={`wrap3D`}>
                      <img src={Unicorn} alt="unicorn" width="220" />
                    </div>
                    <div className={`wrapCoin13D`}>
                      <img src={Gold} alt="coin" width="55" />
                    </div>
                    <div className={`wrapCalendar`}>
                      <img src={Calendar} alt="calendar" width="70" />
                    </div>
                    <div className={`wrapCloud1`}>
                      <img src={Cloud1} alt="coin" width="180" />
                    </div>
                    <div className={`wrapCloud2`}>
                      <img src={Cloud2} alt="coin" width="140" />
                    </div>
                    <div className={`wrapCalendar1`}>
                      <img src={Calendar1} alt="coin" width="80" />
                    </div>
                    <div className={`wrapLight`}>
                      <img src={Light} alt="coin" width="65" />
                    </div>
                    <div className={`wrapCheckList`}>
                      <img src={CheckList} alt="coin" width="60" />
                    </div>
                    <div className={`wrapTimer`}>
                      <img src={Timer} alt="coin" width="60" />
                    </div>
                    <div className={`wrapCircle3D`}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        viewBox="0 0 520 520"
                      >
                        <g
                          fill="none"
                          fillRule="evenodd"
                          stroke="none"
                          strokeDasharray="3"
                          strokeWidth="1"
                          opacity="0.24"
                        >
                          <g
                            stroke="#919EAB"
                            strokeWidth="2"
                            transform="translate(-757 1.852)"
                          >
                            <path d="M1017 518c143.042 0 259-115.958 259-259S1160.042 0 1017 0 758 115.958 758 259s115.958 259 259 259z"></path>
                          </g>
                        </g>
                      </svg>
                      {/* <img src={Circle} alt="cricle" height="400" width="400" /> */}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </StyledWrapFirstContent>
          </Container>
        </div>
        <StyledWrapTwoContent className={`${classes.secondBg}`}>
          <Container maxWidth="lg">
            <Typography variant="h2" align="center">
              Our Partner
            </Typography>
            {/* <Typography
              variant="body1"
              align="center"
              style={{ marginTop: 24, color: "#999999" }}
            >
              Curabitur a felis in nunc fringilla tristique. Fusce egestas elit
              eget lorem. Etiam vitae tortor.
            </Typography> */}
            <div className={`grid-container`}>
              <Box className={`grid-item`}>
                <img width="140" src={SCG} alt="SCG" />
              </Box>
              <Box className={`grid-item`}>
                <img width="180" src={SCGC} alt="SCGC" />
              </Box>
              <Box className={`grid-item`}>
                <img width="140" src={ZERO_TO_ONE} alt="ZERO_TO_ONE" />
              </Box>
              <Box className={`grid-item`}>
                <img width="100" src={TPE} alt="TPE" />
              </Box>
              <Box className={`grid-item`}>
                <img width="180" src={ICO} alt="ICO" />
              </Box>
              <Box className={`grid-item`}>
                <img width="160" src={GRANDSIAM} alt="GRANDSIAM" />
              </Box>
              <Box className={`grid-item`}>
                <img width="160" src={NPI} alt="NPI" />
              </Box>
              <Box className={`grid-item`}>
                <img width="140" src={QGEN} alt="QGEN" />
              </Box>
            </div>
          </Container>
        </StyledWrapTwoContent>
        <StyledWrapThreeContent className={`${classes.thirdBg}`}>
          <Container maxWidth="lg">
            <Typography variant="body2" style={{ color: "#999999" }}>
              OUR SERVICES
            </Typography>
            <Typography variant="h2" style={{}}>
              Featured
            </Typography>

            <div className="grid-container">
              <div className="grid-item">
                <img
                  src={Recruitment}
                  width={80}
                  style={{ marginBottom: 36 }}
                />

                <Typography
                  align="center"
                  variant="h5"
                  style={{ marginBottom: 16, height: 64 }}
                >
                  Sourcing & Recruit
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  align="center"
                >
                  E-form request which automate check manpower plannibg and Find
                  quality candidate in any job
                </Typography>
              </div>
              <div className="grid-item">
                <img src={HRCore} width={80} style={{ marginBottom: 36 }} />
                <Typography
                  align="center"
                  variant="h5"
                  style={{ marginBottom: 16, height: 64 }}
                >
                  HR Core data
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  align="center"
                >
                  Easier to manage employee data whice easier interface to other
                  system
                </Typography>
              </div>
              <div className="grid-item">
                <img
                  src={TimeManagement}
                  width={80}
                  style={{ marginBottom: 36 }}
                />
                <Typography
                  align="center"
                  variant="h5"
                  style={{ marginBottom: 16, height: 64 }}
                >
                  Time Management
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  align="center"
                >
                  Accurate time manqgement by multi channel & best technology
                  with flexible platform for employee time management
                </Typography>
              </div>
              <div className="grid-item">
                <img src={Payroll} width={80} style={{ marginBottom: 36 }} />
                <Typography
                  align="center"
                  variant="h5"
                  style={{ marginBottom: 16, height: 64 }}
                >
                  Payroll
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  align="center"
                >
                  Accurate payroll & payment by easy user experience &
                  autonomate platform for checking accuracy
                </Typography>
              </div>
            </div>
          </Container>
        </StyledWrapThreeContent>
        <StyledWrapFourContent className={`${classes.fourBg}`}>
          <Container maxWidth="lg">
            <Grid container className={`wrapFourContent`}>
              <Grid item xs={12} sm={12} md={6} className={`outer-right`}>
                <div className={`right`}>
                  <img className={`right-vdo`} src={VdoBenefit} alt="vdo" />
                </div>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Typography variant="h2" align="center" style={{}}>
                  Benefits Achieved
                </Typography>
                <div className={`left`}>
                  <div>
                    <div className={`left-1`}>
                      <DoneAllIcon style={{ color: "#007afe" }} />
                      <Typography variant="h5">
                        100% Data on platform
                      </Typography>
                    </div>
                    <div className={`left-1`}>
                      <DoneAllIcon style={{ color: "#007afe" }} />
                      <Typography variant="h5">
                        faster 10X work in process
                      </Typography>
                    </div>
                    <div className={`left-1`}>
                      <DoneAllIcon style={{ color: "#007afe" }} />
                      <Typography variant="h5">
                        reduce 3X workload of HR
                      </Typography>
                    </div>
                    <div className={`left-1`}>
                      <DoneAllIcon style={{ color: "#007afe" }} />
                      <Typography variant="h5">
                        100% accuracy transaction
                      </Typography>
                    </div>
                    <div className={`left-1`}>
                      <DoneAllIcon style={{ color: "#007afe" }} />
                      <Typography variant="h5">
                        reduce cost of company
                      </Typography>
                    </div>
                    <div className={`left-1`}>
                      <DoneAllIcon style={{ color: "#007afe" }} />
                      <Typography variant="h5">
                        add satisfaction of employee
                      </Typography>
                    </div>
                  </div>
                  <div>
                    <img className={`img-ant1`} src={Ant1} alt="ant1" />
                  </div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </StyledWrapFourContent>
        <StyledWrapFiveContent className={`${classes.fiveBg}`}>
          <Container maxWidth="lg" style={{ position: "relative" }}>
            <Typography variant="h2" align="center">
              Our Customer Say
            </Typography>
            <ReactSlickDemo />
          </Container>
        </StyledWrapFiveContent>
        <StyledWrapSixContent>
          <Container maxWidth="lg">
            <Typography
              variant="h2"
              style={{ color: "#FFFFFF", marginBottom: 16 }}
            >
              Ours Technology
            </Typography>
            <Typography variant="body1" style={{ color: "#FFFFFF" }}>
              We integated all technology for the most product ours mission. All
              outsourcing All is key of creation
            </Typography>
            <div className={`grid-container`}>
              <Box className={`itemTech`}>
                <img src={WebApp} alt="webapp" width="50" />
                <Typography className={`primaryText`}>
                  Web Application
                </Typography>
              </Box>
              <Box className={`itemTech`}>
                <img src={Blockchain} alt="blockchain" width="50" />
                <Typography className={`primaryText`}>Blockchain</Typography>
              </Box>
              <Box className={`itemTech`}>
                <img src={Robot} alt="bot" width="50" />
                <Typography className={`primaryText`}>Chat Bot</Typography>
              </Box>
              <Box className={`itemTech`}>
                <img src={Rpa} alt="rpa" width="50" />
                <Typography className={`primaryText`}>
                  Robotic Process Automation
                </Typography>
                <Typography className={`secondaryText`}>(RPA)</Typography>
              </Box>
              <Box className={`itemTech`}>
                <img src={Ai} alt="ai" width="50" />
                <Typography className={`primaryText`}>
                  Artificial Intelligence
                </Typography>
                <Typography className={`secondaryText`}>(AI)</Typography>
              </Box>
              <Box className={`itemTech`}>
                <img src={Signature} alt="ai" width="50" />
                <Typography className={`primaryText`}>
                  Electronic Signature
                </Typography>
                <Typography className={`secondaryText`}>
                  (e-Signature)
                </Typography>
              </Box>
            </div>
          </Container>
        </StyledWrapSixContent>
        <StyledWrapSevenContent>
          <Container maxWidth="lg">
            <Typography variant="h2" align="center">
              Still have questions?
            </Typography>
            <Typography
              variant="body1"
              align="center"
              style={{ marginTop: 24, color: "#999999" }}
            >
              Please describe your case to receive the most accurate advice.
            </Typography>
            <ButtonBlue
              variant={"contained"}
              style={{ marginTop: 24, height: 48, fontSize: 16 }}
            >
              {t("ContactUs")}
            </ButtonBlue>
          </Container>
        </StyledWrapSevenContent>
        <StyledWrapEightContent>
          <Container maxWidth="lg">
            <div className={`wrapFree`}>
              <Box className={`image-free`}>
                <img src={Free} alt="free" width="350" />
              </Box>
              <Box className={`text-free`}>
                <Typography variant="h2" style={{ color: "#FFFFFF" }}>
                  Register TODAY
                </Typography>
                <Typography variant="h2" style={{ color: "#FFFFFF" }}>
                  Get Special Offer
                </Typography>
                <FormControl variant="outlined">
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={"text"}
                    endAdornment={
                      <InputAdornment position="end">
                        <ButtonBlue variant={"contained"}>
                          {t("Register")}
                        </ButtonBlue>
                      </InputAdornment>
                    }
                  />
                </FormControl>
              </Box>
            </div>
          </Container>
        </StyledWrapEightContent>
        <StyledWrapCustomerService>
          <Container maxWidth="lg">
            <Grid container>
              <Grid item xs={12} sm={6}>
                <div className={`wrapDetail`}>
                  <div className={`item`}>
                    <div className={`label`}>
                      <MailOutlineIcon
                        style={{ marginRight: 8, color: "#007afe" }}
                      />
                      <Typography style={{ color: "#007afe" }}>
                        Email
                      </Typography>
                    </div>
                    <Typography variant="h5" style={{ fontSize: 26 }}>
                      customer_service@anthr.com
                    </Typography>
                  </div>
                  <div className={`item`}>
                    <div className={`label`}>
                      <PhoneIphoneOutlinedIcon
                        style={{ marginRight: 8, color: "#007afe" }}
                      />
                      <Typography style={{ color: "#007afe" }}>
                        Phone
                      </Typography>
                    </div>
                    <Typography variant="h5" style={{ fontSize: 26 }}>
                      082-889-4498
                    </Typography>
                  </div>
                  <div className={`item`}>
                    <div className={`label`}>
                      <FmdGoodOutlinedIcon
                        style={{ marginRight: 8, color: "#007afe" }}
                      />
                      <Typography style={{ color: "#007afe" }}>
                        Address
                      </Typography>
                    </div>
                    <Typography variant="h5" style={{ fontSize: 30 }}>
                      Bangkok, Thailand
                    </Typography>
                  </div>
                  <Divider style={{ borderStyle: "dashed", width: 350 }} />
                  <div>
                    <Typography style={{ marginTop: 16 }}>FOLLOW US</Typography>
                    <div>
                      <IconButton
                        aria-label="facebook"
                        size="large"
                        style={{ marginRight: 16 }}
                      >
                        <FacebookIcon
                          fontSize="small"
                          style={{ color: "#212B36", fontSize: 25 }}
                        />
                      </IconButton>
                      <IconButton
                        aria-label="line"
                        size="large"
                        style={{ marginRight: 16 }}
                      >
                        <i
                          className="fab fa-line"
                          style={{ color: "#212B36", fontSize: 22 }}
                        ></i>
                      </IconButton>
                      <IconButton
                        aria-label="linkedin"
                        size="large"
                        style={{ marginRight: 16 }}
                      >
                        <i
                          className="fab fa-linkedin"
                          style={{ color: "#212B36", fontSize: 22 }}
                        ></i>
                      </IconButton>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div style={{ textAlign: "center" }}>
                  <img
                    src={CustomerService}
                    alt="CustomerService"
                    width="350"
                  />
                </div>
              </Grid>
            </Grid>
          </Container>
        </StyledWrapCustomerService>
        <StyledWrapCopyRight>
          <img src={Logo} alt="logo" width="75" />
          <Typography>© All rights reserved</Typography>
          <Typography>made by minimals.cc</Typography>
        </StyledWrapCopyRight>
      </StyledRoot>
    </StyledFragment>
  );
};

export default Landing;
