import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  CardActionArea,
  Chip,
  Container,
  Divider,
  Grid,
  Stack,
  SvgIcon,
  Typography,
  styled,
} from "@mui/material";
import CardStyle from "../../../../shared/general/Card";
import Loading from "../../../../shared/loading";
import { useDispatch } from "react-redux";
import { getIndividualObjectiveByIdIndividualObjective } from "../../../../../../actions/okrs";

import dayjs from "dayjs";
import ChipStatus from "../../../../shared/pages/okrs/components/ChipStatus";
import { AccessTimeRounded, StarRounded } from "@mui/icons-material";
import { ReactComponent as MoonshotIcon } from "../../../../assets/moonshot_logo.svg";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import DetailIndividual from "./dialogDetail";
import RatingChip from "../../Planning/Organization/Information/ratingChip";
import { getUserManagerFullName } from "../../../../../../utils/userData";

const StyledRoot = styled(Box)({
  "& .objective-information-container": {
    padding: "16px",
    "& .objective-name": {
      fontSize: "20px",
      fontWeight: "500",
    },
    "& .objective-description": {
      paddingTop: "16px",
      fontSize: "14px",
    },
    "& .impact-header": {
      paddingRight: "8px",
      color: "#919eab",
    },
  },
  "& .key-result-list-container": {
    "& .key-result-item-container": {
      padding: "16px",
      "& .key-result-name": {
        flexGrow: 1,
        fontWeight: "500",
      },
      "& .key-result-description": {
        paddingTop: "16px",
        fontSize: "14px",
      },
      "& .due-date-container": {
        paddingTop: "16px",
        display: "flex",
        alignItems: "center",
        color: "#919eab",
        fontSize: "14px",
        "& .MuiSvgIcon-root": {
          marginRight: "8px",
        },
        "& .MuiTypography-root, & .MuiSvgIcon-root": {
          fontSize: "inherit",
          color: "inherit",
        },
      },
      "& .MuiDivider-root": {
        paddingTop: "16px",
      },
      "& .manager-label": {
        paddingRight: "8px",
        color: "#919eab",
      },
    },
  },
});

const KeyResult = (props) => {
  const { data } = props;
  const { t, i18n } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);

  let resultRating = 0
  if (data.isMoonShotPlanning && data.isMoonShotEvaluation) {
    resultRating = data.managerRating * 3
  } else if (data.isMoonShotPlanning || data.isMoonShotEvaluation) {
    resultRating = data.managerRating * 2
  } else {
    resultRating = data.managerRating
  }
  
  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const getStatusApprove = (status) => {
    if (status === null) {
      return 1;
    } else if (status === 1) {
      return 2;
    } else if (status === 0) {
      return 3;
    } else {
      return 1;
    }
  };

  const getStatusAchieveApprove = (status) => {
    if (status === 1) {
      return 5;
    } else if (status === 0) {
      return 6;
    } else {
      return 4;
    }
  };

  return (
    <>
      <CardStyle style={{ height: "100%" }}>
        <CardActionArea onClick={handleClickOpen}>
          <Box padding="24px">
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="flex-start"
              marginBottom="8px"
            >
              <Typography fontSize="20px">{data.keyResultName}</Typography>
              <Box display="flex" justifyContent="space-between" gap="8px">
                {data.isApprove !== 1 && (
                  <ChipStatus
                    status={getStatusApprove(data.isApprove)}
                    size="large"
                  />
                )}
                {data.isApprove === 1 && (
                  <ChipStatus
                    status={getStatusAchieveApprove(data.isAchieve)}
                    size="large"
                  />
                )}
                {data.isApprove === 1 &&
                  data.isAchieve !== null &&
                  data.isSuccess === null && (
                    <ChipStatus status={7} size="large" />
                  )}
              </Box>
            </Box>
            {(data.isMoonShotPlanning === 1 ||
              data.isMoonShotEvaluation === 1 ||
              data.rating !== null) && (
              <Box display="flex" alignItems="center" gap="8px">
                <Stack direction="row" gap={1} minHeight={"30px"}>
                  {data.isMoonShotPlanning === 1 && (
                    <SvgIcon>
                      <MoonshotIcon />
                    </SvgIcon>
                  )}
                  {data.isMoonShotEvaluation === 1 && (
                    <SvgIcon>
                      <MoonshotIcon />
                    </SvgIcon>
                  )}
                </Stack>
                {data.rating !== null && data.isSuccess !== null && (
                  <Chip
                    icon={<StarRounded />}
                    label={Math.round(data.rating * 100) / 100}
                    sx={{
                      backgroundColor: "#fff9db",
                      "& .MuiSvgIcon-root": {
                        color: "rgb(255, 221, 64)",
                      },
                      "& .MuiChip-label": {
                        lineHeight: 1,
                        fontWeight: "500",
                      },
                    }}
                  />
                )}
              </Box>
            )}
            <Box display="flex" alignItems="flex-end">
              <AccessTimeRounded
                fontSize="14px"
                style={{ marginRight: "8px", color: "#919eab" }}
              />
              <Typography
                fontSize="14px"
                lineHeight="1"
                color="text.third"
              >{`${dayjs(data.startDate).format("D MMM")} - ${dayjs(
                data.endDate
              ).format("D MMM BB")}`}</Typography>
              {data.managerRating !== null && (
                <div style={{ marginLeft: "auto", height: "60px" }}>
                  <RatingChip rating={resultRating} />
                </div>
              )}
            </Box>
            <Divider style={{ marginTop: "16px" }} />
            <Box marginTop="16px">
              <Typography fontSize="14px">
                <span style={{ color: "#919eab", paddingRight: "8px" }}>{`${t(
                  "Approver"
                )}`}</span>
                {`${getUserManagerFullName(data)}`}
              </Typography>
            </Box>
          </Box>
        </CardActionArea>
      </CardStyle>
      {openDialog && (
        <DetailIndividual
          open={openDialog}
          handleClos={handleClose}
          data={data}
        />
      )}
    </>
  );
};

const IndividualInformationPage = (props) => {
  const { match } = props;
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();

  const [individualObjective, setIndividualObjective] = useState(null);
  const [isFetching, setIsFetching] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    dispatch(
      getIndividualObjectiveByIdIndividualObjective(
        match.params.idIndividualObjective
      )
    ).then((res) => {
      setIsFetching(false);
      if (res.status === 200) {
        setIndividualObjective(res.data);
      } else {
        setIsError(true);
      }
    });
  }, []);

  if (isError) {
    return (
      <StyledRoot className="page">
        <Container maxWidth="lg">
          <Typography
            textAlign="center"
            fontSize="32px"
            paddingTop="24px"
          >{`${t("NoData")}`}</Typography>
        </Container>
      </StyledRoot>
    );
  }

  if (isFetching) {
    return (
      <StyledRoot className="page">
        <Container maxWidth="lg">
          <Box display="flex" justifyContent="center" paddingTop="24px">
            <Loading />
          </Box>
        </Container>
      </StyledRoot>
    );
  }

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg" sx={{ marginTop: 3 }}>
        {individualObjective && (
          <Fragment>
            <CardStyle style={{ backgroundColor: "#ffd9d9" }}>
              <Box padding="24px">
                <Chip
                  label={`Objective ${dayjs()
                    .set("year", individualObjective.year)
                    .format(
                      i18n.resolvedLanguage === "th" ? "BBBB" : "YYYY"
                    )}, Q${individualObjective.quarter}`}
                  sx={{
                    backgroundColor: "#FFA6A6",
                    color: "#ffffff",
                    fontSize: "20px",
                  }}
                />
                <div
                  style={{
                    padding: 8,
                    display: "flex",
                    flexDirection: "column",
                    gap: 8,
                  }}
                >
                  <Typography fontSize="24px" fontWeight={500}>
                    {individualObjective.objectiveName}
                  </Typography>
                  {individualObjective.description && (
                    <Typography
                      fontSize="14px"
                      paddingTop="16px"
                      whiteSpace="pre-line"
                    >
                      <span style={{ paddingRight: "8px" }}>{`${t(
                        "Descriptions"
                      )}`}</span>
                      {individualObjective.description}
                    </Typography>
                  )}
                  <Typography fontSize="16px">
                    <span style={{ paddingRight: "8px" }}>{`${t(
                      "TheResultingOutcome"
                    )}`}</span>
                    {individualObjective.impactName}
                  </Typography>
                </div>
              </Box>
            </CardStyle>
            <Typography fontSize="18px" padding="16px">
              {`${t("KeyResult")} ${individualObjective.keyResults.length} ${t("List")}`}
            </Typography>

            <Grid container gap={"16px"}>
              {individualObjective.keyResults.map((keyResult, index) => (
                <Grid key={index} item xs={12}>
                  <KeyResult data={keyResult} />
                </Grid>
              ))}
            </Grid>
          </Fragment>
        )}
      </Container>
    </StyledRoot>
  );
};

export default IndividualInformationPage;
