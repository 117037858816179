import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Container,
  Grid,
  Popper,
  Tab,
  Tabs,
  TextField,
  autocompleteClasses,
  styled,
  Card,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import { getAllObjective } from "../../../../../actions/okrs";
import { getAllEmployees } from "../../../../../actions/employee";
import { useDispatch, useSelector } from "react-redux";
import StatusPlan from "./statusPlan";
import StatusApprove from "./statusApprove";
import { useTranslation } from "react-i18next";
import { getUserFullName } from "../../../../../utils/userData";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiCardContent-root": {
      padding: 0,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 16,
    },
    "& .MuiOutlinedInput-input": {
      padding: "16px 14px",
    },
    "& .MuiTableCell-root": {
      paddingTop: "5px",
      paddingBottom: "5px",
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
  },
}));

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const ContainerStyled = styled(Container)({
  paddingTop: "5px",
  height: "100%",
  minHeight: "100dvh",
});

const StyledRoot = styled(Box)({
  "& .objectcard-wrap": {
    marginBottom: "16px",
    "&:last-child": {
      marginBottom: 0,
    },
  },
  "& .objective-card": {
    padding: "16px",
  },
  "& .MuiFilledInput-root input": {
    padding: "16px 12px 16px 12px",
  },
  "& .MuiFilledInput-root input": {
    padding: "16px 12px 16px 12px",
  },
});

const StyledCard = styled(Card)({
  minHeight: "70px",
  boxShadow: "none",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  background:
    "linear-gradient(to right, rgba(235, 148, 192, 0.5), rgba(188, 144, 213, 0.5))",
  "& .cardTitle": {
    fontSize: 22,
  },
  "& .MuiCardContent-root": {
    padding: 24,
  },
  "& .cardHeader": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
  "& .searchContainer": {
    marginLeft: "41px",
    marginBottom: "100px",
  },
  "@media (max-width: 600px)": {
    "& .searchContainer": {
      marginBottom: "10px",
    },
  },
});

const StyleHeadBox = styled(Box)({
  "& .box-header": {
    minHeight: "200px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    backgroundColor:
      "linear-gradient(to right, rgba(235, 148, 192, 0.5), rgba(188, 144, 213, 0.5))",
    "& .text-header": {
      fontSize: "48px",
      fontWeight: "bold",
      marginLeft: "40px",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      marginTop: "80px",
    },
    "& .img-header": {
      objectFit: "contain",
      // width: "33%",
      // height: "auto",
      // marginBottom: "10px",
      // justifyContent: "flex-end",
      // alignItems: "flex-end",
      // marginRight: "20px",
    },
  },

  "@media (max-width: 600px)": {
    "& .box-header": {
      flexDirection: "column",
      minHeight: "unset",
      justifyContent: "center",
      alignItems: "center",
      "& .text-header": {
        fontSize: "30px",
        marginLeft: "50px",
        marginTop: "20px",
      },
      // "& .img-header": {
      //   width: "30%",
      //   justifyContent: "flex-start",
      //   alignItems: "flex-start",
      // },
    },
  },
});

function OKRStatusPlanApprove() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [view, setView] = useState(0);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [dataObjective, setDataObjective] = useState([]);
  const [objectiveApprove, setObjectiveApprove] = useState([]);

  const { result: employeesList } = useSelector((state) => state.employees);

  const handleChangeTab = (event, newValue) => {
    setView(newValue);
  };

  const handleChageEmployee = (idEmployees) => {
    const employeeObjective = dataObjective.filter(
      (item) => item.idEmployees === idEmployees
    );
    setDataObjective(employeeObjective);
    const employeeApprove = objectiveApprove.filter(
      (item) => item.idEmployees === idEmployees
    );
    setObjectiveApprove(employeeApprove);
  };

  useEffect(() => {
    dispatch(getAllObjective()).then((res) => {
      if (res.status === 200) {
        const data = res.data;
        if (selectedEmployee) {
          const filterdEmployeeObjective = data.filter(
            (item) => item.idEmployees === selectedEmployee.idEmployees
          );
          setDataObjective(filterdEmployeeObjective);

          const result = [];
          for (const e of filterdEmployeeObjective) {
            const keyResults = e.keyResults.filter((item) => item.isApprove);
            if (keyResults.length > 0) {
              result.push({ ...e, keyResults });
            }
          }
          setObjectiveApprove(result);
        } else {
          setDataObjective(data);
          const result = [];
          for (const e of data) {
            const keyResults = e.keyResults.filter((item) => item.isApprove);
            if (keyResults.length > 0) {
              result.push({ ...e, keyResults });
            }
          }
          setObjectiveApprove(result);
        }
      }
    });
  }, [selectedEmployee]);

  useEffect(() => {
    dispatch(getAllEmployees());
  }, []);

  return (
    <StyledRoot className="page">
      <Box padding="24px 0">
        <Container maxWidth="lg">
          <StyledCard
            style={{
              marginBottom: "48px",
              marginTop: "20px",
            }}
          >
            <StyleHeadBox>
              <Box className="box-header">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className="text-header">{`${t(
                      "OKRStatusList"
                    )}`}</Typography>
                  </Grid>

                  <Grid item xs={12} className="searchContainer">
                    {employeesList && (
                      <StyledAutocomplete
                        sx={{
                          maxWidth: "400px",
                        }}
                        style={{
                          backgroundColor: "white",
                          borderRadius: "10px",
                        }}
                        options={employeesList}
                        value={selectedEmployee}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            handleChageEmployee(newValue.idEmployees);
                          }
                          setSelectedEmployee(newValue);
                        }}
                        popupIcon={<i className="fa-light fa-chevron-down"></i>}
                        getOptionLabel={(option) =>
                          `${getUserFullName(option)}`
                        }
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.idEmployees}>
                              {`${getUserFullName(option)}`}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="filled"
                            placeholder={`${t("SelectEmp")}`}
                          />
                        )}
                        PopperComponent={StyledPopper}
                        noOptionsText={`${t("NoData")}`}
                      />
                    )}
                  </Grid>
                </Grid>
                <img
                  className="img-header"
                  src={`${process.env.PUBLIC_URL}/assets/okrs/admin/plan-progress-status.png`}
                  alt="icon"
                />
              </Box>
            </StyleHeadBox>
          </StyledCard>

          <div className={classes.root}>
            <Tabs
              value={view}
              onChange={handleChangeTab}
              indicatorColor="secondary"
              textColor="secondary"
              style={{ marginLeft: "50px", marginBottom: "30px" }}
            >
              <Tab
                style={{ fontSize: "16px" }}
                label={`${t("ListOfPlans")}`}
                {...a11yProps(0)}
              />
              <Tab
                style={{ fontSize: "16px" }}
                label={`${t("ListApprovedOutcomes")}`}
                {...a11yProps(1)}
              />
            </Tabs>
          </div>
          <ContainerStyled>
            <Box sx={{ paddingLeft: 2, paddingRight: 2 }}>
              <CustomTabPanel value={view} index={0}>
                <StatusPlan data={dataObjective} />
              </CustomTabPanel>
              <CustomTabPanel value={view} index={1}>
                <StatusApprove data={objectiveApprove} />
              </CustomTabPanel>
            </Box>
          </ContainerStyled>
        </Container>
      </Box>
    </StyledRoot>
  );
}

export default OKRStatusPlanApprove;
