import * as React from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import FormLabel from "@mui/material/FormLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import DatePickerCustom from "../../../shared/date/datePicker";

import SaveIcon from "@mui/icons-material/Save";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

import dayjs from "dayjs";

import ButtonBlue from "../../../shared/general/ButtonBlue";
import {
  getAllEmployeesShift,
  getAllEmployeesNotInShift,
  employeeChangeShift,
} from "../../../../../actions/employee";

import {
  getCompanyShift,
  getShift,
  getShiftGroupById,
} from "../../../../../actions/shift";
import { getAffiliateOrganization } from "../../../../../actions/company";
import { Box } from "@mui/material";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import { getUserFullName, getUserPosition } from "../../../../../utils/userData";

function not(a, b) {
  return a.filter(
    (value) => b.map((B) => B.idEmployees).indexOf(value.idEmployees) === -1
  );
}

function intersection(a, b) {
  return a.filter(
    (value) => b.map((B) => B.idEmployees).indexOf(value.idEmployees) !== -1
  );
}

function union(a, b) {
  return [...a, ...not(b, a)];
}

const StyledCard = styled(Card)(({}) => ({
  borderRadius: 8,
  "& .MuiCardHeader-root": {
    padding: 4,
  },
}));

const StyledFormControlSelect = styled(FormControl)(({}) => ({
  marginBottom: 16,
  "& .MuiSelect-select": {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: 300,
  },
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
  },
  "& .MuiInputLabel-root": {
    top: -7,
  },
  "& .MuiInputLabel-root.Mui-focused": {
    top: 0,
  },
}));

const StyledAvatar = styled(Avatar)(({}) => ({
  width: 40,
  height: 40,
}));

const StyledList = styled(List)(({}) => ({
  width: 300,
  height: 230,
  paddingTop: 0,
  bgcolor: "background.paper",
  overflow: "auto",
  "& .MuiListItemAvatar-root": {
    minWidth: 0,
    marginRight: 8,
  },
}));

const StyledDialog = styled(Dialog)(({}) => ({
  "& .textFieldTheme": {
    marginTop: 16,
    "& .MuiOutlinedInput-root": {
      borderRadius: 8,
    },
  },
  "& .wrap-date": {
    marginBottom: 8,
    marginTop: 8,
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  "& .MuiDialogActions-root": {
    justifyContent: "space-between",
  },
}));

const StyledDatePicker = styled(TextField)(({}) => ({
  width: "43%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
  },
  "& .MuiOutlinedInput-input": {
    padding: "8.5px 14px",
  },
  "& .MuiInputLabel-root": {
    top: -7,
  },
  "& .MuiInputLabel-root.Mui-focused": {
    top: 0,
  },
}));

const StyledFormLabel = styled(FormLabel)({
  fontWeight: 600,
  fontSize: 14,
  color: "#637381",
});

export default function DialogSwitchShift(props) {
  const {
    open,
    handleClose,
    pattern,
    setOpenErrorSwitch,
    handleOpenAlert,
    handleChangeAlertType,
    idShiftGroup,
    handleGetEmployeeShiftHistory,
    selectedCompany,
  } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: shiftGroup } = useSelector((state) => state.shiftGroup);
  const { result: shiftGroupList } = useSelector((state) => state.shift);
  const { result: companyShiftGroupList } = useSelector(
    (state) => state.companyShift
  );
  const { result: employeesShift } = useSelector(
    (state) => state.employeeShift
  );
  const { result: affiliateOrganizationList } = useSelector(
    (state) => state.affiliateOrganization
  );
  // const { result: employeesNotInShift } = useSelector(
  //   (state) => state.employeeNotInShift
  // );
  const [checked, setChecked] = React.useState([]);
  const [checkboxTemporary, setCheckboxTemporary] = React.useState(false);
  const [dateRangePicker, setDateRangePicker] = React.useState([null, null]);
  const [dataShift, setDataShift] = React.useState(null);
  const [left, setLeft] = React.useState([]);
  const [right, setRight] = React.useState([]);
  const [result, setResult] = React.useState([]);
  const [isSubmit, setIsSubmit] = React.useState(false);

  const [selectedShiftCompany, setSelectedShiftCompany] = React.useState(null);

  const [shiftSelect, setShiftSelect] = React.useState({
    shiftLeft: "",
    shiftRight: "",
  });

  const [filterSearchEmployees, setFilterSearchEmployees] = React.useState({
    left: "",
    right: "",
  })

  const onChangeShiftCompany = (event) => {
    dispatch(getCompanyShift({ idCompany: event.target.value }));
    setShiftSelect((prev) => ({ ...prev, shiftRight: "" }));
    setResult([]);
    setRight([]);
    setSelectedShiftCompany(event.target.value);
  };

  React.useEffect(() => {
    // dispatch(getAffiliateOrganization())
    dispatch(getCompanyShift({ idCompany: shiftGroup.idCompany }));
    dispatch(getAllEmployeesNotInShift(shiftGroup.idShiftGroup));
  }, []);

  React.useEffect(() => {
    if (affiliateOrganizationList.length > 0) {
      setSelectedShiftCompany(selectedCompany.idCompany);
    }
  }, [affiliateOrganizationList]);

  React.useEffect(() => {
    if (employeesShift && shiftGroup) {
      if (shiftSelect.shiftLeft) {
        setLeft([
          ...not(
            employeesShift.filter(
              (employee) => employee.idShift === shiftSelect.shiftLeft
            ),
            result
          ),
          ...result.filter((res) => res.idShift === shiftSelect.shiftLeft),
        ]);
      }
      if (shiftSelect.shiftRight) {
        if (pattern) {
          setRight([
            ...result.filter((res) => res.idShift === shiftSelect.shiftRight),
          ]);
        } else {
          setRight([
            ...not(
              employeesShift.filter(
                (employee) => employee.idShift === shiftSelect.shiftRight
              ),
              result
            ),
            ...result.filter((res) => res.idShift === shiftSelect.shiftRight),
          ]);
        }
      }
    }
  }, [employeesShift, shiftSelect, pattern]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);
  const handleToggle = (value) => () => {
    const currentIndex = checked
      .map((check) => check.idEmployees)
      .indexOf(value.idEmployees);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  const numberOfChecked = (items) => intersection(checked, items).length;

  const handleToggleAll = (items) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const handleCheckedRight = () => {
    let tempResult = result;
    let isDelete = false;
    let listIndexDelete = [];
    leftChecked.map((check) => {
      let index = tempResult
        .map((res) => res.idEmployees)
        .indexOf(check.idEmployees);
      if (index !== -1) {
        if (
          shiftSelect.shiftRight ===
          employeesShift.find((emp) => emp.idEmployees === check.idEmployees)
            .idShift
        ) {
          isDelete = true;
          listIndexDelete.push(index);
        }
        tempResult[index].idShift = shiftSelect.shiftRight;
      } else {
        tempResult.push({
          ...check,
          idShift: shiftSelect.shiftRight,
          prevShift: check.idShift,
        });
      }
    });
    setResult(tempResult);
    setLeft(not(left, leftChecked));
    setRight([
      ...right,
      ...not(
        tempResult.filter((res) => res.idShift === shiftSelect.shiftRight),
        right
      ),
    ]);
    if (isDelete) {
      listIndexDelete.map((index) => {
        tempResult.splice(index, 1);
      });
    }
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    let tempResult = result;
    let isDelete = false;
    let listIndexDelete = [];
    rightChecked.map((check) => {
      let index = tempResult
        .map((res) => res.idEmployees)
        .indexOf(check.idEmployees);
      if (index !== -1) {
        if (
          shiftSelect.shiftLeft ===
          employeesShift.find((emp) => emp.idEmployees === check.idEmployees)
            .idShift
        ) {
          isDelete = true;
          listIndexDelete.push(index);
        }
        tempResult[index].idShift = shiftSelect.shiftLeft;
      } else {
        tempResult.push({
          ...check,
          idShift: shiftSelect.shiftLeft,
          prevShift: check.idShift,
        });
      }
    });
    setResult(tempResult);
    setRight(not(right, rightChecked));
    setLeft([
      ...left,
      ...not(
        tempResult.filter((res) => res.idShift === shiftSelect.shiftLeft),
        left
      ),
    ]);
    if (isDelete) {
      listIndexDelete.map((index) => {
        tempResult.splice(index, 1);
      });
    }
    setChecked(not(checked, rightChecked));
  };

  const handleSubmitFunction = async () => {
    if (
      !dateRangePicker[0] ||
      (!dateRangePicker[1] && checkboxTemporary) ||
      !shiftSelect.shiftLeft ||
      !shiftSelect.shiftRight ||
      result.length === 0
    ) {
      setIsSubmit(true);
    } else {
      result.forEach((element) => {
        companyShiftGroupList.forEach((item) => {
          const found = item.shift.find((x) => x.idShift === element.idShift);
          if (found) {
            element.idShiftGroup = found.idShiftGroup;
          }
        });
      });

      let data = {
        temporary: checkboxTemporary,
        dateRange: [
          dayjs(dateRangePicker[0]).format("YYYY-MM-DD HH:mm:ss"),
          dayjs(dateRangePicker[1]).format("YYYY-MM-DD HH:mm:ss"),
        ],
        listEmployeeSwitch: result,
      };

      console.log(data);
      const response = await dispatch(employeeChangeShift(data));
      if (response.status === 200) {
        if (response.data.isSwitched) {
          handleChangeAlertType(
            "ไม่สามารถสลับกะได้ เนื่องจากพนักงานมีการสลับกะไปแล้ว"
          );
        } else {
          handleChangeAlertType("success");
          handleGetEmployeeShiftHistory(idShiftGroup);
        }
      } else {
        handleChangeAlertType("error");
      }
      handleOpenAlert();
      dispatch(getAllEmployeesShift(shiftGroup.idShiftGroup));
      dispatch(getShiftGroupById(shiftGroup.idShiftGroup));
      handleCloseDialog();
    }
  };

  const handleCloseDialog = () => {
    handleClose();
    setChecked([]);
    setCheckboxTemporary(false);
    setDateRangePicker([null, null]);
    setShiftSelect({ shiftLeft: "", shiftRight: "" });
    setResult([]);
    setIsSubmit(false);
  };

  const resetForm = () => {
    setChecked([]);
    setCheckboxTemporary(false);
    setDateRangePicker([null, null]);
    setShiftSelect({ shiftLeft: "", shiftRight: "" });
    setResult([]);
    setLeft([]);
    setRight([]);
  };

  const customList = (title, items, side, filter) => (
    <StyledCard variant="outlined">
      <CardHeader
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={
              numberOfChecked(items) === items.length && items.length !== 0
            }
            indeterminate={
              numberOfChecked(items) !== items.length &&
              numberOfChecked(items) !== 0
            }
            disabled={items.length === 0}
            inputProps={{
              "aria-label": "all items selected",
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <StyledList dense component="div" role="list">
        {items.filter(item => (
          filter === "" || String(`${getUserFullName(item)}`).includes(filter)
        )).map((value) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItemButton
              key={value.idEmployeeShift}
              role="listitem"
              onClick={handleToggle(value)}
            >
              <ListItemIcon>
                <Checkbox
                  checked={
                    checked
                      .map((check) => check.idEmployees)
                      .indexOf(value.idEmployees) !== -1
                  }
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    "aria-labelledby": labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemAvatar>
                <StyledAvatar alt={"username"} />
              </ListItemAvatar>
              <ListItemText
                id={labelId}
                primary={`${getUserFullName(value)}`}
                secondary={getUserPosition(value)}
              />
            </ListItemButton>
          );
        })}
        <ListItem />
      </StyledList>
    </StyledCard>
  );

  const handleChangeShiftSelect = (event) => {
    setShiftSelect({ ...shiftSelect, [event.target.name]: event.target.value });
  };

  const handleChangeTemporaryCheckbox = (event) => {
    setCheckboxTemporary(event.target.checked);
  };

  const handleChangeDate = (newValue, indexType) => {
    if (indexType === 0) {
      if (
        !dateRangePicker[1] ||
        (dateRangePicker[1] &&
          new Date(newValue) > new Date(dateRangePicker[1]))
      ) {
        setDateRangePicker([newValue, newValue]);
      } else {
        setDateRangePicker([newValue, dateRangePicker[1]]);
      }
    } else {
      setDateRangePicker([dateRangePicker[0], newValue]);
    }
  };

  return (
    <div>
      <StyledDialog
        open={open}
        onClose={handleClose}
        maxWidth={"md"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {pattern ? t("MoveShiftPlan") : t("SwitchShift")}
        </DialogTitle>
        <DialogContent>
          <FormControlLabel
            control={
              <Checkbox
                name="temporary"
                onChange={handleChangeTemporaryCheckbox}
                checked={checkboxTemporary}
                style={{ color: "#007afe" }}
              />
            }
            label={`${pattern ? t("MoveShiftPlan") : t("SwitchShift")}${t(
              "Temporary"
            )}`}
          />
          <div className="wrap-date" style={{}}>
            <DatePickerCustom
              label={t("StartDate")}
              value={dateRangePicker[0]}
              onChange={(event) => handleChangeDate(event, 0)}
              renderInput={(params) => (
                <StyledDatePicker
                  {...params}
                  error={isSubmit && !dateRangePicker[0]}
                />
              )}
              minDate={new Date(dayjs().add(-1, "month").set("date", 1))}
            />
            {checkboxTemporary && (
              <DatePickerCustom
                label={t("EndDate")}
                value={dateRangePicker[1]}
                onChange={(event) => handleChangeDate(event, 1)}
                renderInput={(params) => (
                  <StyledDatePicker
                    {...params}
                    error={isSubmit && checkboxTemporary && !dateRangePicker[1]}
                  />
                )}
                minDate={
                  dateRangePicker[0]
                    ? dateRangePicker[0]
                    : new Date(dayjs().add(-1, "month").set("date", 1))
                }
              />
            )}
          </div>

          {pattern && (
            <Box>
              <StyledFormLabel component="legend">
                {t("SelectCompany")}
              </StyledFormLabel>
              <StyledFormControlSelect fullWidth>
                <Select
                  labelId="demo-simple-select-company-label"
                  id="demo-simple-select-company"
                  size="small"
                  onChange={onChangeShiftCompany}
                  value={selectedShiftCompany}
                >
                  {affiliateOrganizationList &&
                    affiliateOrganizationList.map((item, index) => (
                      <MenuItem key={index} value={item.idCompany}>
                        {item.companyName}
                      </MenuItem>
                    ))}
                </Select>
              </StyledFormControlSelect>
            </Box>
          )}

          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <div>
                <StyledFormLabel component="legend">
                  {t("ChooseShift")}
                </StyledFormLabel>
                <StyledFormControlSelect
                  fullWidth
                  error={isSubmit && !shiftSelect.shiftLeft}
                >
                  {pattern ? (
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={shiftSelect.shiftLeft}
                      name="shiftLeft"
                      onChange={handleChangeShiftSelect}
                      size="small"
                    >
                      {shiftGroupList &&
                        shiftGroupList
                          .filter((x) => {
                            return x.idShiftGroup === shiftGroup.idShiftGroup;
                          })
                          .map((shiftGroup) => {
                            return shiftGroup.shift.map((shift) => (
                              <MenuItem
                                key={shift.idShift}
                                value={shift.idShift}
                                disabled={
                                  shift.idShift === shiftSelect.shiftRight
                                }
                              >
                                {`${shiftGroup.shiftGroupName} : ${shift.shiftName}`}
                              </MenuItem>
                            ));
                          })}
                    </Select>
                  ) : (
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={shiftSelect.shiftLeft}
                      name="shiftLeft"
                      onChange={handleChangeShiftSelect}
                      size="small"
                      label={t("ChooseShift")}
                    >
                      {shiftGroup &&
                        shiftGroup.shift.map((shift) => (
                          <MenuItem
                            key={`Shift ${shift.shiftName} shiftLeft`}
                            value={shift.idShift}
                            disabled={shift.idShift === shiftSelect.shiftRight}
                          >
                            {`${shiftGroup.shiftGroupName} : ${shift.shiftName}`}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                </StyledFormControlSelect>
                <Box marginBottom="16px">
                  <StyledFormLabel component="legend">
                    {t("SearchEmp")}
                  </StyledFormLabel>
                  <TextFieldTheme
                    value={filterSearchEmployees.left}
                    onChange={(event) => {
                      setFilterSearchEmployees(prev => ({
                        ...prev,
                        left: event.target.value,
                      }))
                    }}
                  />
                </Box>
                {/* {left.filter(item => (
                    filterSearchEmployees.left === "" || String(`${item.firstname_TH} ${item.lastname_TH}`).includes(filterSearchEmployees.left)
                  )),} */}
                {employeesShift && customList(t("SelectAll"), left ,"left", filterSearchEmployees.left)}
              </div>
            </Grid>
            <Grid item>
              <Grid container direction="column" alignItems="center">
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedRight}
                  disabled={
                    leftChecked.length === 0 || shiftSelect.shiftRight === ""
                  }
                  aria-label="move selected right"
                >
                  &gt;
                </Button>
                <Button
                  sx={{ my: 0.5 }}
                  variant="outlined"
                  size="small"
                  onClick={handleCheckedLeft}
                  disabled={
                    rightChecked.length === 0 || shiftSelect.shiftLeft === ""
                  }
                  aria-label="move selected left"
                >
                  &lt;
                </Button>
              </Grid>
            </Grid>
            <Grid item>
              <div>
                <StyledFormLabel component="legend">
                  {t("ChooseShift")}
                </StyledFormLabel>
                <StyledFormControlSelect
                  fullWidth
                  error={isSubmit && !shiftSelect.shiftRight}
                >
                  {pattern ? (
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={shiftSelect.shiftRight}
                      name="shiftRight"
                      onChange={handleChangeShiftSelect}
                      size="small"
                    >
                      {companyShiftGroupList &&
                        companyShiftGroupList
                          .filter((x) => {
                            return x.idShiftGroup !== shiftGroup.idShiftGroup;
                          })
                          .map((shiftGroup) => {
                            return shiftGroup.shift.map((shift) => (
                              <MenuItem
                                key={shift.idShift}
                                value={shift.idShift}
                                disabled={
                                  shift.idShift === shiftSelect.shiftLeft
                                }
                              >
                                {`${shiftGroup.shiftGroupName} : ${shift.shiftName}`}
                              </MenuItem>
                            ));
                          })}
                    </Select>
                  ) : (
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={shiftSelect.shiftRight}
                      name="shiftRight"
                      onChange={handleChangeShiftSelect}
                      size="small"
                      label={t("ChooseShift")}
                    >
                      {shiftGroup &&
                        shiftGroup.shift.map((shift) => (
                          <MenuItem
                            key={`Shift ${shift.shiftName} shiftLeft`}
                            value={shift.idShift}
                            disabled={shift.idShift === shiftSelect.shiftLeft}
                          >
                            {`${shiftGroup.shiftGroupName} : ${shift.shiftName}`}
                          </MenuItem>
                        ))}
                    </Select>
                  )}
                </StyledFormControlSelect>
                
                <Box marginBottom="16px">
                  <StyledFormLabel component="legend">
                    {t("SearchEmp")}
                  </StyledFormLabel>
                  <TextFieldTheme
                    value={filterSearchEmployees.right}
                    onChange={(event) => {
                      setFilterSearchEmployees(prev => ({
                        ...prev,
                        right: event.target.value,
                      }))
                    }}
                  />
                </Box>

                {employeesShift && customList(t("SelectAll"), right, "right", filterSearchEmployees.right)}
              </div>
            </Grid>
          </Grid>
          {isSubmit && result.length === 0 && (
            <Typography
              style={{
                color: "#d32f2f",
                marginTop: "10px",
                marginLeft: "10px",
              }}
            >
              {`* ${t("NoEmpListInShift")}`}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <div>
            <ButtonBlue startIcon={<RestartAltIcon />} onClick={resetForm}>
              {t("RestoreDefault")}
            </ButtonBlue>
          </div>
          <div>
            <Button onClick={handleCloseDialog} style={{ color: "#212b36" }}>
              {t("Cancel")}
            </Button>
            <ButtonBlue
              variant={"contained"}
              startIcon={<SaveIcon />}
              onClick={handleSubmitFunction}
            >
              {t("SaveData")}
            </ButtonBlue>
          </div>
        </DialogActions>
      </StyledDialog>
    </div>
  );
}
