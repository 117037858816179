import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Box, TextField, InputAdornment, IconButton } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";

import CardStyle from "../../shared/general/Card";
import Logo from "../../assets/Chatbot.png";

import { getBotResponse } from "../../../../actions/webhook";
import dayjs from "dayjs";

const ChatBot = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState("");
  const [type, setType] = useState("");
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const firstDayOfMonth = dayjs().startOf("month");
  const lastDayOfMonth = dayjs().endOf("month");

  const scrollToBottom = () => {
    setTimeout(() => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }, 100); // 100ms delay
  };

  useEffect(() => {
    if (userProfile && userProfile.firstname_TH) {
      setMessages([
        {
          text: `สวัสดีครับคุณ ${userProfile.firstname_TH} มีอะไรให้ Jarvis ช่วยไหมครับ?`,
          sender: "bot",
        },
      ]);
    } else {
      setMessages([
        { text: "สวัสดีครับ มีอะไรให้ Jarvis ช่วยไหมครับ?", sender: "bot" },
      ]);
    }
  }, [userProfile]);

  useEffect(scrollToBottom, [messages, isLoading]);

  const handleSend = async () => {
    if (currentMessage.trim()) {
      // Add user message
      handleSetMessages(currentMessage, "user");
      setCurrentMessage("");
      setIsLoading(true);

      try {
        const response = await getBotResponse({
          message: currentMessage,
        });

        if (response && response.data) {
          const tag = response.data.tag;
          setType(tag);
          handleSetMessages(response.data.message, "bot");
        }
      } catch (error) {
        handleSetMessages(`${t("ContactAdmin")}`, "bot");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSetMessages = async (
    text,
    sender,
    file = null,
    fileName = null
  ) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        text: text,
        sender: sender,
        file: file,
        fileName: fileName,
      },
    ]);
  };

  return (
    <CardStyle
      style={{
        width: "100%",
        // maxWidth: "80%",
        height: "80vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "16px",
      }}
    >
      <Box className="content">
        {messages.map((message, index) => (
          <Box key={index} className={`${message.sender}-message`}>
            {message.sender === "bot" && (
              <img
                src={Logo}
                alt="Logo"
                className="bot-logo"
                width="40"
                height="40"
              />
            )}
            <Box className={`${message.sender}-bubble`}>{message.text}</Box>
          </Box>
        ))}

        {isLoading && (
          <Box className="bot-message">
            <img
              src={Logo}
              alt="Logo"
              className="bot-logo"
              width="40"
              height="40"
            />
            <Box className="bot-bubble">
              <span className="thinking-dots">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </Box>
          </Box>
        )}
        <div ref={messagesEndRef} />
      </Box>
      <TextField
        multiline
        minRows={1}
        maxRows={4}
        variant="outlined"
        placeholder="Type your message here..."
        fullWidth
        value={currentMessage}
        onChange={(e) => setCurrentMessage(e.target.value)}
        disabled={isLoading}
        onKeyPress={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            handleSend();
          }
        }}
        InputProps={{
          style: { resize: "none" },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleSend}>
                <SendIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </CardStyle>
  );
};

export default ChatBot;
