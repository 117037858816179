import React, { useEffect, Fragment } from "react";
import { useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { Box, Container, Dialog, DialogContent } from "@mui/material";
import Grid from "@mui/material/Grid";
import Chip from "@mui/material/Chip";
import CompleteImage from "./../../../assets/complete.png";
import CardStyle from "../../../shared/general/Card";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import CardCandidateSent from "./share/cardCandidateSent";
import CardSendManpower from "./share/CardSendManpower";
import CardPassedCandidate from "./share/cardPassedCandidate";
import dayjs from "dayjs";
//Translator TH-EN
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import {
  addManpowersToCandidate,
  getCandidatesAlreadySendToJob,
  getPassedCandidatesInJob,
} from "../../../../../actions/candidate";
import { getAllFreeUsersByIdVendor } from "../../../../../actions/employee";
import { getJobRequestByIdJobRequest } from "../../../../../actions/jobRecruit";
import CardInterviewPanel from "./share/cardInterviewPanel";
import {
  getUserCompany,
  getUserDepartment,
  getUserPosition,
} from "../../../../../utils/userData";

const StyledRoot = styled("div")(({ theme }) => ({
  background: "#FFFFFF !important",
  "& .displayFlexEnd": {
    display: "flex",
    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "8px 0",
    },
  },
  minWidth: 350,
  width: "100%",
  backgroundColor: "#f1f4f9",
  paddingBottom: 36,
  "& .column-name": {
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    color: "#000000",
    "& .MuiAvatar-root": {
      marginRight: 8,
      width: 40,
      height: 40,
    },
  },
  "& .textAndIcon": {
    display: "flex",
    alignItems: "center",
    "& .topic": {
      marginRight: 8,
    },
    "& .icon": {
      marginRight: 16,
    },
    "& .textMedium": {
      fontSize: 18,
    },
    "& .iconMedium": {
      fontSize: 16,
    },
    "& .value": {
      marginLeft: 32,
    },
  },
  "& .css-rkrx5i-MuiChip-root": {
    backgroundColor: "#775eb9",
  },
}));

const StyledChip = styled(Chip)({
  color: "#b72136",
  backgroundColor: "#ff928f",
  fontWeight: 700,
  borderRadius: 6,
});

const StyledContent = styled("div")({
  padding: 36,
  paddingTop: 24,
  "& .part-one": {
    display: "flex",
    alignItems: "flex-start",
    marginTop: 8,
    ["@media only screen and (max-width: 600px)"]: {
      flexDirection: "column",
    },
    "& .detail": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      "& .start": {
        "& .position": {
          marginBottom: 12,
        },
        "& div": {
          marginRight: 32,
          ["@media only screen and (max-width: 600px)"]: {
            marginRight: 0,
          },
        },
      },
      "& .end": {
        textAlign: "end",
      },
      ["@media only screen and (max-width: 600px)"]: {
        flexDirection: "column",
        marginTop: 16,
      },
    },
    "& .MuiAvatar-root": {
      width: 120,
      height: 120,
      fontSize: 42,
      marginRight: 36,
      borderRadius: 12,
      backgroundColor: "white",
      color: "inherit",
      "& img": {
        width: "inherit",
        height: "inherit",
        objectFit: "contain",
      },
    },
    "& .MuiTypography-caption": {
      fontSize: 14,
    },
  },
  "& .part-two": {
    display: "flex",
    justifyContent: "end",
  },
  "& .wrap-item": {
    "& .label": {
      fontWeight: 400,
      fontSize: 16,
      marginBottom: 4,
      "& span": {
        marginLeft: 16,
      },
    },
    "& .value": {
      fontSize: 18,
      marginLeft: 32,
    },
    "& .fal": {
      marginRight: 8,
    },
  },
  "&.selected-candidate": {
    minHeight: 200,
    "& .part-one": {
      marginBottom: 16,
    },
    "& .wrap-selected-candidate-list": {
      display: "grid",
      columnGap: 16,
      gridTemplateColumns: "repeat(4, 1fr)",
    },
    "& .candidate-id": {
      "& .MuiTypography-h6": {
        textDecoration: "none",
        "&:hover": {
          textDecoration: "underline",
        },
      },
    },
    "& .date-appointment": {
      marginTop: 8,
      display: "flex",
      flexDirection: "column",
    },
  },
  "&.candidate": {
    minHeight: 200,
    "& .part-one": {
      marginBottom: 16,
      display: "flex",
      justifyContent: "space-between",
    },
    "& .wrap-candidate-list": {
      display: "grid",
      columnGap: 16,
      gridTemplateColumns: "repeat(4, 1fr)",
      "& .candidate-id": {
        "& .MuiTypography-h6": {
          textDecoration: "none",
          "&:hover": {
            textDecoration: "underline",
          },
        },
      },
    },
  },
  "& .MuiInputBase-root": {
    borderRadius: "16px !important",
  },
  "& .part-company": {
    padding: "32px 0",
    "& .MuiAvatar-root": {
      width: 56,
      height: 56,
      backgroundColor: "white",
      "& img": {
        width: "inherit",
        height: "inherit",
        objectFit: "contain",
      },
    },
    "& .MuiTypography-root": {
      fontSize: 20,
    },
  },
  "& .attach-file-container": {
    marginLeft: 32,
    display: "flex",
    gap: 16,
    "& i": {
      marginLeft: 8,
      color: "#919eab",
    },
  },
});

const DialogStyled = styled(Dialog)({
  "& .MuiPaper-root": {
    borderRadius: 16,
    padding: 16,
  },
});

const BoxStatus = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "& .icon": {
    fontSize: 112,
  },
  "& .text": {
    marginTop: 24,
  },
  "& .error": {
    color: "#e93d3d",
  },
  "& .success": {
    color: "#2e7d32",
  },
  "& .CloseButton": {
    width: 80,
    marginTop: 16,
    padding: 8,
    border: "1px solid rgba(224, 224, 224, 1)",
    borderRadius: 8,
    cursor: "pointer",
    "& .textButton": {
      color: `#707070`,
    },
  },
});

const renderWorkExp = (min, max) => {
  if (Number(min) === 0 && Number(max) === 0) {
    return "ไม่จำกัด";
  } else if (Number(min) > 0 && Number(max) === 0) {
    return `${min} ปีขึ้นไป`;
  } else {
    return `${min} - ${max} ปี`;
  }
};

const RequestInformationPageOpenJob = (props) => {
  // console.log(props)
  const dispatch = useDispatch();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const { isFetching: isJobFetching, result: JobDetail } = useSelector(
    (state) => state.jobRecruitDetail
  );
  const { result: UserMe } = useSelector((state) => state.userProfile);
  const { result: AllUsersVendorFree } = useSelector(
    (state) => state.userVendorFree
  );
  const { result: AllCandidatesAlreadySent } = useSelector(
    (state) => state.candidateAlreadySent
  );

  // console.log(AllCandidatesAlreadySent);

  //use for check to change status waitInterview to waitResultInterview
  // const checkAndUpdateStatusCandidate = (candidates) => {
  //   const currentDateTime = new Date();
  //   console.log(currentDateTime)

  //   return candidates.map(candidate => {
  //     if (candidate.idStatus === 3) {
  //       const appointmentDateTime = new Date(`${candidate.appointmentDate}T${candidate.appointmentEndTime}`);

  //       if (currentDateTime > appointmentDateTime) {
  //         return { ...candidate, idStatus: 4 };
  //       }
  //     }
  //     return candidate;
  //   });
  // };

  // if(AllCandidatesAlreadySent){
  //   const AllCandidatesAlreadySent1 = checkAndUpdateStatusCandidate(AllCandidatesAlreadySent)
  //   console.log(AllCandidatesAlreadySent1);
  // }

  const { result: AllPassedCandidates } = useSelector(
    (state) => state.passedCandidatesCompany
  );

  const [statusSuccess, setStatusSuccess] = React.useState({
    label: "",
    isOpen: false,
  });

  const [statusFail, setStatusFail] = React.useState({
    label: "",
    isOpen: false,
  });

  useEffect(async () => {
    let idRequest = props.match.params.idRequest;
    await dispatch(getJobRequestByIdJobRequest(idRequest)).then((res) => {
      if (!res) {
        props.history.push("/recruitment");
      }
    });
    // if(!JobDetail && !isJobFetching){
    //   props.history.push("/recruitment");
    // }
  }, [props.match.params.idRequest]);

  useEffect(() => {
    if (UserMe && UserMe.idEmployees && props.match.params.idRequest) {
      dispatch(getAllFreeUsersByIdVendor());
      dispatch(getCandidatesAlreadySendToJob(props.match.params.idRequest));
    }
  }, [UserMe, props.match.params.idRequest]);

  useEffect(() => {
    if (
      UserMe &&
      UserMe.idEmployees &&
      JobDetail &&
      JobDetail.idEmployees === UserMe.idEmployees &&
      props.match.params.idRequest
    ) {
      dispatch(getPassedCandidatesInJob(JobDetail.idJobRecruit));
    }
  }, [UserMe, JobDetail, props.match.params.idRequest]);

  let timeoutDisplay;

  const handleOnSubmit = async (right, ResetRight) => {
    // console.log(right);
    if (dayjs().isBefore(JobDetail.closeRegisterDate)) {
      const result = await dispatch(
        addManpowersToCandidate(right, parseInt(props.match.params.idRequest))
      );
      if (result) {
        let idRequest = props.match.params.idRequest;
        dispatch(getAllFreeUsersByIdVendor());
        dispatch(getCandidatesAlreadySendToJob(idRequest));
        ResetRight();
        setStatusSuccess({ label: t("sendPersonnelSuccess"), isOpen: true });
        timeoutDisplay = setTimeout(handleCloseDisplay, 5000);
      }
    } else {
      setStatusFail({ label: t("sendPersonnelFailed"), isOpen: true });
    }
  };

  const handleCloseDisplay = () => {
    clearInterval(timeoutDisplay);
    setStatusSuccess({ label: "", isOpen: false });
  };

  const handleDispatchCandidateAlreadySent = () => {
    dispatch(getCandidatesAlreadySendToJob(props.match.params.idRequest));
  };

  return (
    <StyledRoot className={`page`}>
      {!statusSuccess.isOpen && JobDetail && UserMe && (
        <Container maxWidth="lg">
          <Typography variant="h4" style={{ padding: "24px 0" }}>
            {JobDetail && !JobDetail.isByPass
              ? `${t("WorkInfo")}`
              : t("bypassData")}
          </Typography>
          <Fragment>
            <CardStyle style={{ marginBottom: 24 }}>
              <StyledContent>
                <Typography variant="body2" color="#484848" gutterBottom>
                  {JobDetail && JobDetail.createdDate
                    ? `${t("ApplicationAnnouncementDate")}: ${dayjs(
                        JobDetail.createdDate
                      ).format("DD-MMM-YYYY")} (${dayjs().diff(
                        JobDetail.createdDate,
                        "day"
                      )} ${t("Days")})`
                    : `${t("ApplicationAnnouncementDate")}: ${t(
                        "NotSpecified"
                      )}`}
                </Typography>
                <div className={`part-one`}>
                  <Avatar
                    variant="rounded"
                    src={
                      JobDetail && JobDetail.idJobGroup
                        ? `${process.env.REACT_APP_API_URL}image/jobGroup/${JobDetail.idJobGroup}.png`
                        : "/assets/logo_default.png"
                    }
                  ></Avatar>
                  <Grid container>
                    <Grid container item direction={"column"} xs={12} md={5}>
                      <Grid item>
                        <div className="position">
                          <Typography variant="caption" color="#484848">
                            {`${t("Position")}`}
                          </Typography>
                          {JobDetail && (
                            <Typography fontSize={20}>
                              {getUserPosition(JobDetail)}
                            </Typography>
                          )}
                        </div>
                      </Grid>
                      <Grid item>
                        <Typography variant="caption" color="#484848">
                          {`${t("JobGroup")}`}
                        </Typography>
                        {JobDetail.jobGroupName ? (
                          <Box>
                            <Chip
                              label={JobDetail.jobGroupName}
                              style={{
                                margin: "5px 0px",
                                fontWeight: 700,
                                backgroundColor: "#E6E6FA",
                              }}
                            />
                          </Box>
                        ) : (
                          <Typography variant="h5">-</Typography>
                        )}
                      </Grid>
                      <Grid item>
                        <Typography variant="caption" color="#484848">
                          {`${t("Company")}`}
                        </Typography>
                        {JobDetail && (
                          <Typography fontSize={20}>
                            {getUserCompany(JobDetail)}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                    <Grid container item direction={"column"} xs={12} md={5}>
                      <Grid item>
                        <Typography variant="caption" color="#484848">
                          {`${t("Department")}`}
                        </Typography>
                        {JobDetail && (
                          <Typography fontSize={20}>
                            {getUserDepartment(JobDetail)}
                          </Typography>
                        )}
                      </Grid>
                      <Grid item>
                        <Typography variant="caption" color="#484848">
                          {`${t("JobRequester")}`}
                        </Typography>
                        {JobDetail && (
                          <Typography fontSize={20}>{`${
                            i18n.resolvedLanguage === "th"
                              ? JobDetail.employeeNameOpenJob_TH
                              : JobDetail.employeeNameOpenJob_EN
                              ? JobDetail.employeeNameOpenJob_EN
                              : JobDetail.employeeNameOpenJob_TH
                          } (${
                            i18n.resolvedLanguage === "th"
                              ? JobDetail.employeePositionOpenJob_TH
                              : JobDetail.employeePositionOpenJob_EN
                              ? JobDetail.employeePositionOpenJob_EN
                              : JobDetail.employeePositionOpenJob_TH
                          })`}</Typography>
                        )}
                      </Grid>
                      <Grid item>
                        <Typography variant="caption" color="#484848">
                          {`${t("Approver")}`}
                        </Typography>
                        {JobDetail && (
                          <Typography fontSize={20}>{`${
                            i18n.resolvedLanguage === "th"
                              ? JobDetail.employeeNameApproved_TH
                              : JobDetail.employeeNameApproved_EN
                              ? JobDetail.employeeNameApproved_EN
                              : JobDetail.employeeNameApproved_TH
                          } (${
                            i18n.resolvedLanguage === "th"
                              ? JobDetail.employeePositionApproved_TH
                              : JobDetail.employeePositionApproved_EN
                              ? JobDetail.employeePositionApproved_EN
                              : JobDetail.employeePositionApproved_TH
                          })`}</Typography>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-end",
                        }}
                      >
                        {JobDetail && !!JobDetail.isUrgent ? (
                          <StyledChip
                            label="Urgent"
                            size="small"
                            style={{ width: "fit-content" }}
                          />
                        ) : (
                          <></>
                        )}
                        <Typography
                          component={"div"}
                          variant="caption"
                          color="#484848"
                          align="right"
                          style={{ color: "#3CB371" }}
                        >
                          {`${t("JobOpenings")}`}
                        </Typography>
                        <Typography
                          variant="h4"
                          align="right"
                          style={{ color: "#3CB371" }}
                        >
                          {JobDetail && JobDetail.quota ? (
                            JobDetail.quota
                          ) : (
                            <i className="fa-solid fa-infinity"></i>
                          )}
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <div>
                  <Grid container marginTop={5} spacing={3}>
                    <Grid item xs={12} sm={6} md={3}>
                      <div className={`wrap-item`}>
                        <Typography className="label" color="#484848">
                          <i className="fa-sharp fa-regular fa-layer-group"></i>
                          <span>{`${t("JobLevel")}`}</span>
                        </Typography>
                        <Typography className="value">
                          {[JobDetail.levelJob]}
                        </Typography>
                      </div>
                    </Grid>
                    <Fragment>
                      <Grid item xs={12} sm={6} md={3}>
                        <div className={`wrap-item`}>
                          <Typography className="label" color="#484848">
                            <i className="fa-regular fa-briefcase"></i>
                            <span>{`${t("WorkExperience")}`}</span>
                          </Typography>
                          <Typography className="value">
                            {
                              [
                                `${t("Unlimited")}`,
                                `1-3 ${t("Year")}`,
                                `3-5 ${t("Year")}`,
                                `5-7 ${t("Year")}`,
                                `7 ${t("YearsAbove")}`,
                              ][JobDetail.workExperience]
                            }
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <div className={`wrap-item`}>
                          <Typography className="label" color="#484848">
                            <i className="fa-regular fa-graduation-cap"></i>
                            <span>{`${t("MinimumEducationDegree")}`}</span>
                          </Typography>
                          <Typography className="value">
                            {
                              [
                                `${t("Unlimited")}`,
                                `${t("PrimaryEducation")}`,
                                `${t("SecondaryEducation")}`,
                                `${t("​CertificateOfTechnicalVocation")}/${t(
                                  "VocationalCertificate"
                                )}`,
                                `${t("BachelorDegrees")}`,
                                `${t("MasterDegrees")}`,
                                `${t("DoctorDegrees")}`,
                              ][JobDetail.educationDegree]
                            }
                          </Typography>
                        </div>
                      </Grid>
                    </Fragment>

                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <div className={`wrap-item`}>
                        <Typography className="label" color="#484848">
                          <i className="far fa-calendar-times"></i>
                          <span>{`${t("ClosingDate")}`}</span>
                        </Typography>
                        <Typography className="value">
                          {JobDetail && JobDetail.closeRegisterDate
                            ? dayjs(JobDetail.closeRegisterDate).format(
                                "DD MMM YYYY"
                              )
                            : `${t("NotSpecified")}`}
                        </Typography>
                      </div>
                    </Grid>
                  </Grid>
                </div>
                <Fragment>
                  <div style={{ marginTop: 40, marginBottom: 8 }}>
                    <Typography
                      style={{ fontWeight: 600, fontSize: 18, marginBottom: 8 }}
                    >
                      {`${t("EmployeeType")}`}
                    </Typography>
                    {JobDetail.employmentTypeName && (
                      <Typography className="value">
                        {JobDetail.employmentTypeName}
                      </Typography>
                    )}
                  </div>
                  <div style={{ marginTop: 40, marginBottom: 8 }}>
                    <Typography
                      style={{ fontWeight: 600, fontSize: 18, marginBottom: 8 }}
                    >
                      {`${t("JobDescription")}`}
                    </Typography>
                    <Typography style={{ whiteSpace: "pre-line" }}>
                      {JobDetail && JobDetail.jobDescription
                        ? JobDetail.jobDescription
                        : "-"}
                    </Typography>
                  </div>
                  <div style={{ marginTop: 40, marginBottom: 8 }}>
                    <Typography
                      style={{ fontWeight: 600, fontSize: 18, marginBottom: 8 }}
                    >
                      {`${t("DesiredQualifications")}`}
                    </Typography>
                    <Typography style={{ whiteSpace: "pre-line" }}>
                      {JobDetail && JobDetail.qualificationRequire
                        ? JobDetail.qualificationRequire
                        : "-"}
                    </Typography>
                  </div>
                  <div style={{ marginTop: 40, marginBottom: 8 }}>
                    <Typography
                      style={{ fontWeight: 600, fontSize: 18, marginBottom: 8 }}
                    >
                      {`${t("WorkingLocationPlace")}`}
                    </Typography>
                    <Typography className="value">
                      {JobDetail.locationAddress +
                        " " +
                        JobDetail.locationDistrict +
                        " " +
                        JobDetail.locationProvince}
                    </Typography>
                  </div>
                </Fragment>
              </StyledContent>
            </CardStyle>
            {JobDetail && (
              <CardInterviewPanel
                idJobRecruit={parseInt(props.match.params.idRequest)}
                idJobGroup={JobDetail.idJobGroup}
              />
            )}
            {UserMe &&
              UserMe.idRole === 4 &&
              props.match.params.idRequest &&
              JobDetail && (
                <Fragment>
                  {AllCandidatesAlreadySent &&
                    AllCandidatesAlreadySent.length > 0 && (
                      <CardCandidateSent
                        idJobRecruit={parseInt(props.match.params.idRequest)}
                        listCandidatesSent={AllCandidatesAlreadySent}
                        onClickSubmitCancelled={
                          handleDispatchCandidateAlreadySent
                        }
                        idJobGroup={JobDetail.idJobGroup}
                      />
                    )}
                  {dayjs().isBefore(JobDetail.closeRegisterDate) && (
                    <CardSendManpower
                      type="job"
                      id={props.match.params.idRequest}
                      listManpowers={AllUsersVendorFree}
                      listManpowersFromManpowersPage={
                        (location.state && location.state.listSelected) || []
                      }
                      handleOnSubmit={handleOnSubmit}
                    />
                  )}
                </Fragment>
              )}
            {UserMe &&
              UserMe.idRole === 1 &&
              JobDetail &&
              JobDetail.idEmployees === UserMe.idEmployees &&
              props.match.params.idRequest && (
                <CardPassedCandidate
                  AllPassedCandidates={AllPassedCandidates}
                />
              )}
          </Fragment>

          {isJobFetching && (
            <Typography variant="h5" textAlign="center">
              {`${t("LoadingData")}`}
            </Typography>
          )}
          {!isJobFetching && !JobDetail && (
            <Typography variant="h5" textAlign="center">
              {`${t("NoData")}`}
            </Typography>
          )}
        </Container>
      )}
      {statusSuccess.isOpen && JobDetail && UserMe && (
        <Container maxWidth="lg">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              height: "calc(100vh - 160px)",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "80px",
              }}
            >
              <img
                style={{ width: 300, marginBottom: "20px" }}
                src={CompleteImage}
                // alt="https://www.freepik.com/free-vector/completed-concept-illustration_10802151.htm"
              />
              <Typography variant="h4" style={{ marginBottom: "40px" }}>
                {statusSuccess.label}
              </Typography>
              <ButtonBlue
                variant="outlined"
                style={{ borderRadius: "25px", width: "100px" }}
                onClick={handleCloseDisplay}
                to={`/company/request/${JobDetail.idJobRecruit}`}
              >
                <Typography variant="h6">{`${t("Close")}`}</Typography>
              </ButtonBlue>
            </div>
          </div>
        </Container>
      )}
      {statusFail.isOpen && (
        <DialogStyled
          open={statusFail.isOpen}
          onClose={() => setStatusFail({ label: "", isOpen: false })}
        >
          <DialogContent>
            <BoxStatus>
              <i className="fa-regular fa-circle-xmark icon error"></i>
              <Typography variant="h5" align="center" className="text error">
                {statusFail.label}
              </Typography>
              <Box
                className="CloseButton"
                onClick={() => setStatusFail({ label: "", isOpen: false })}
              >
                <Typography align="center" className="textButton">
                  {`${t("Close")}`}
                </Typography>
              </Box>
            </BoxStatus>
          </DialogContent>
        </DialogStyled>
      )}
    </StyledRoot>
  );
};

export default RequestInformationPageOpenJob;
