import React, { useState, useEffect } from "react";
import Pagination from "../../../shared/general/Pagination";
import { TeamData } from "../DataSet";
import { useHistory } from "react-router-dom";
import { Data } from "../mockData";
import { styled } from "@mui/styles";
import { useTranslation } from "react-i18next";
import {
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Toolbar,
  LinearProgress,
  Typography,
  Button,
  Container,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import SearchIcon from "@mui/icons-material/Search";
import FlagIcon from "@mui/icons-material/Flag";
import VpnKeyRoundedIcon from "@mui/icons-material/VpnKeyRounded";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiOutlinedInput-root": {
      borderRadius: 16,
    },
    "& .MuiTypography-h6": {
      fontSize: "1.1rem",
    },
  },
  formControl: {
    minWidth: 150,
    marginRight: 8,
  },
  cardEmp: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "start",
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: 8,
    minHeight: "350px",
    margin: 8,
    paddingBottom: 16,
    boxShadow: "rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px",
  },
  img_Section: {
    width: "100%",
  },
  imageEmp: {
    width: 120,
    height: 120,
    borderRadius: "50%",
    marginTop: 40,
    marginBottom: 16,
  },
  info_section: {
    marginBottom: 24,
  },
  total_section: {
    paddingLeft: 16,
    paddingRight: 16,
  },
  btn_Section: {
    padding: 16,
  },
  infoBtn: {
    width: "100%",
    color: "#51536b",
    borderRadius: "8px",
  },
  svgText: {
    display: "flex",
    alignItems: "center",
  },
  svgIcon: {
    marginRight: 8,
  },
  fontBold: {
    fontWeight: 700,
  },
  objective: {
    backgroundColor: "#e6eaff",
    color: "#4f65df",
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
    borderRadius: "10px",
    marginBottom: 8,
  },
  keyResult: {
    backgroundColor: "#ffebee",
    color: "#ef5350",
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
    borderRadius: "10px",
    marginBottom: 8,
  },
  moonShot: {
    backgroundColor: "#fff3e0",
    color: "#ff9800",
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 16,
    paddingRight: 16,
    borderRadius: "10px",
  },
}));

const ContainerStyled = styled(Container)({
  marginTop: "100px",
});

function TransparentList() {
  const history = useHistory();
  const classes = useStyles();
  const {t,i18n} = useTranslation();
  const [records, setRecords] = useState([]);
  const [recordItems, setRecordItems] = useState([]);
  const [teamSelected, setTeamSelected] = useState("");
  const [searchKey, setSearchKey] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [arrayToFilter, setArrayToFilter] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(5);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const fetchData = () => {
    setIsLoading(false);

    /*
    //fetch employee data
    axios
      .get(`${process.env.REACT_APP_API}/idvplans`)
      .then((res) => {
        idvPlans = res.data;
      })
      .catch((err) => console.log(err));

    axios
      .get(`${process.env.REACT_APP_API}/employees`)
      .then((res) => {
        let employees = res.data;
        employees.forEach((element) => {
          let plans = idvPlans.filter(function (item) {
            return (
              item.planStatus.includes("Approved") &&
              item.createBy.includes(element.fullName)
            );
          });

          element.objectiveTotal = plans.length;
          element.keyResultList = plans;

          //sum moonShotKR1
          let moon1 = 0;
          element.moonShot1Total = plans.reduce(
            (previousValue, currentValue) =>
              previousValue + currentValue.moonShotKR1,
            moon1
          );

          //sum moonShotKR2
          let moon2 = 0;
          element.moonShot2Total = plans.reduce(
            (previousValue, currentValue) =>
              previousValue + currentValue.moonShotKR2,
            moon2
          );

          //sum moonShotKR3
          let moon3 = 0;
          element.moonShot3Total = plans.reduce(
            (previousValue, currentValue) =>
              previousValue + currentValue.moonShotKR3,
            moon3
          );

          //sum moonShotAfterKR1
          let moonAfter1 = 0;
          element.moonShotAfter1Total = plans.reduce(
            (previousValue, currentValue) =>
              previousValue + currentValue.moonShotAfterKR1,
            moonAfter1
          );

          //sum moonShotAfterKR1
          let moonAfter2 = 0;
          element.moonShotAfter2Total = plans.reduce(
            (previousValue, currentValue) =>
              previousValue + currentValue.moonShotAfterKR2,
            moonAfter2
          );

          //sum moonShotAfterKR1
          let moonAfter3 = 0;
          element.moonShotAfter3Total = plans.reduce(
            (previousValue, currentValue) =>
              previousValue + currentValue.moonShotAfterKR3,
            moonAfter3
          );
        });

        fetchPlanData(employees);
      })
      .catch((err) => console.log(err));
    */
  };

  useEffect(() => {
    let x = [...recordItems];
    x = x.filter((y) => {
      return y.fullName.toLowerCase().includes(searchKey.toLocaleLowerCase());
    });
    setRecords(x);
  }, [searchKey]);

  useEffect(() => {
    setTeamSelected("");
    fetchData();
  }, []);

  return (
    <ContainerStyled>
      <div className="card-section">
        <div className="header-section">
          <Grid container>
            <Grid item>
              <h3 className="header-topic">OKR Transparent List</h3>
            </Grid>
          </Grid>
        </div>
        <Toolbar>
          <Grid container justifyContent="flex-end" spacing={2}>
            <Grid item>
              <TextField
                select
                label="Team"
                style={{ minWidth: "200px" }}
                value={teamSelected}
                onChange={(e) => setTeamSelected(e.target.value)}
              >
                {TeamData.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value === "None" ? "" : option.value}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item>
              <TextField
                variant="outlined"
                label="Search Employee"
                style={{ minWidth: "300px" }}
                value={searchKey}
                onChange={(e) => setSearchKey(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </div>
      {isLoading ? (
        <LinearProgress />
      ) : (
        <Grid container>
          {Data.length > 0 &&
            Data.filter((val) =>
              searchKey === ""
                ? {}
                : val.fullName.toLowerCase().search(searchKey.toLowerCase()) !=
                  -1
            )
              .filter((val) =>
                teamSelected === ""
                  ? {}
                  : val.position.search(teamSelected) != -1
              )
              .map((item) => (
                <Grid item lg={3} md={3} sm={6} xs={12}>
                  <div className={classes.cardEmp}>
                    {item.image === null || item.image === "string" ? (
                      <AccountCircleIcon
                        sx={{ color: "#e0e0e0" }}
                        className={classes.imageEmp}
                      />
                    ) : (
                      <img
                        src={`/images/employees/${item.image}`}
                        className={classes.imageEmp}
                      />
                    )}
                    <div className={classes.info_section}>
                      <Typography variant="h6">{item.fullName}</Typography>
                      <Typography
                        variant="body1"
                        align="center"
                        color="textSecondary"
                      >
                        {item.position}
                      </Typography>
                    </div>
                    <div className={classes.total_section}>
                      <Grid
                        container
                        justifyContent="space-between"
                        className={classes.objective}
                        alignItems="center"
                      >
                        <Grid item>
                          <Typography
                            variant="body2"
                            className={classes.svgText}
                          >
                            <FlagIcon className={classes.svgIcon} /> Objective
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="body2"
                            className={classes.fontBold}
                          >
                            {item.objectiveTotal}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        justifyContent="space-between"
                        className={classes.keyResult}
                        alignItems="center"
                      >
                        <Grid item>
                          <Typography
                            variant="body2"
                            className={classes.svgText}
                          >
                            <VpnKeyRoundedIcon className={classes.svgIcon} />{" "}
                            {t("KeyResult")}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="body2"
                            className={classes.fontBold}
                          >
                            {item.krTotal}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        justifyContent="space-between"
                        className={classes.moonShot}
                        alignItems="center"
                      >
                        <Grid item>
                          <Typography
                            variant="body2"
                            className={classes.svgText}
                          >
                            <i
                              className={classes.svgIcon}
                              class="fa-solid fa-space-station-moon"
                            ></i>
                            Moon Shot
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="body2"
                            className={classes.fontBold}
                          >
                            {item.moonShot1Total +
                              item.moonShot2Total +
                              item.moonShot3Total +
                              item.moonShotAfter1Total +
                              item.moonShotAfter2Total +
                              item.moonShotAfter3Total}
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                    <div className={classes.btn_Section}>
                      <Button
                        variant="outlined"
                        className={classes.infoBtn}
                        onClick={() => {
                          history.push({
                            pathname: "/employeesuccesslist",
                            state: { fullName: item.fullName },
                          });
                        }}
                      >
                        <SearchIcon />
                        View Info
                      </Button>
                    </div>
                  </div>
                </Grid>
              ))}
        </Grid>
      )}
      {Data.length > 0 ? (
        <Pagination
          paginate={paginate}
          postsPerPage={postsPerPage}
          totalPosts={Data.length}
        />
      ) : null}
    </ContainerStyled>
  );
}

export default TransparentList;
