import DoneAll from "@mui/icons-material/DoneAll";
import DeleteIcon from "@mui/icons-material/Delete";
import { Avatar, Box, Stack, styled, Typography, useMediaQuery } from "@mui/material";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  approveRequestTime,
  getAllRequestTimeBy,
  getAllRequestTimeWithDrawBy,
} from "../../../../actions/requestTime";
import ChipWithDrawCustom from "../../shared/chipWithDrawCustom";
import ButtonBlue from "../../shared/general/ButtonBlue";
import TableCustom from "../../shared/tableCustom";
import DialogConfirmReject from "./DialogConfirmReject";
import CardRequest from "./CardRequest";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getUserFullName, getUserPosition } from "../../../../utils/userData";
import { getOtHoursPerWeekByIdEmp } from "../../../../actions/employee";

const StyledButtonCancel = styled(ButtonBlue)({
  backgroundColor: "#E793B8",
  borderRadius: "8px",
  color: "#FFFFFF",
  marginRight: 8,
  width: 130,
  "&:hover": {
    backgroundColor: "#DC6384",
  },
});

const RequestOverTime = (props) => {
  const { searchDate, setSnackBarConfig, setNumberOfList } = props;
  const { t, i18n } = useTranslation();
  const mobileResponsive = useMediaQuery("(max-width:600px)");
  const { result: requestTimeList } = useSelector((state) => state.requestTime);
  const { result: requestTimeWithDrawList } = useSelector(
    (state) => state.requestTimeWithDraw
  );
  const { result: payrollSetting } = useSelector(
    (state) => state.payrollSetting
  );
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const [pendingRows, setPendingRows] = useState([]);

  const [selectedRows, setSelectedRows] = useState([]);

  const [isOpenRejectDialog, setIsOpenRejectDialog] = useState(false);

  const dispatch = useDispatch();

  function findSummaryOfOverTimeHour(row) {
    // const xWorkingDailyHoliday = row.xWorkingDailyHoliday && row.xWorkingDailyHoliday > 0 ? (parseFloat(row.xWorkingDailyHoliday / 60)) : 0;
    // const xWorkingMonthlyHoliday = row.xWorkingMonthlyHoliday && row.xWorkingMonthlyHoliday > 0 ? (parseFloat(row.xWorkingMonthlyHoliday / 60)) : 0;
    // const xOT = row.xOT && row.xOT > 0 ? (parseFloat(row.xOT / 60)) : 0;
    // const xOTHoliday = row.xOTHoliday && row.xOTHoliday > 0 ? (parseFloat(row.xOTHoliday / 60)) : 0;

    const summary = row.totalOtHours > 0 ? (row.totalOtHours / 60).toFixed(2) : 0.00;

    let status;
    if (summary > 29.9) {
      status = "red";
    } else if (summary > 8 && summary <= 29.9) {
      status = "yellow"
    } else {
      status = "green"
    };

    return {
      hours: `${summary}`,
      status: status
    }
  };

  const columns = [
    {
      name: `${t("FullName")}`,
      minWidth: "230px",
      width: "230px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Avatar
            sx={{
              marginRight: "8px",
              width: 40,
              height: 40,
              "& img": { objectFit: "contain" },
            }}
            src={row.imageURL}
          />
          <Box flexGrow={1}>
            <Typography>{getUserFullName(row)}</Typography>
            <Typography color="text.third" fontSize="14px">
              {getUserPosition(row)}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      name: `${t("Type")}`,
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Fragment>
          {row.idRequestTimeWithdraw && <ChipWithDrawCustom />}
          <Typography fontSize={14}>{t(row.name)}</Typography>
          <Fragment>
            {row.xWorkingDailyHoliday > 0 && (
              <Typography
                style={{ fontWeight: 500, fontSize: 14 }}
                color="text.third"
              >
                OT x {payrollSetting.xWorkingDailyHoliday} ={" "}
                {parseFloat(row.xWorkingDailyHoliday / 60).toFixed(2)}{" "}
                {`${t("Unit.ShortHours")}`}
              </Typography>
            )}
            {row.xWorkingMonthlyHoliday > 0 && (
              <Typography
                style={{ fontWeight: 500, fontSize: 14 }}
                color="text.third"
              >
                OT x {payrollSetting.xWorkingMonthlyHoliday} ={" "}
                {parseFloat(row.xWorkingMonthlyHoliday / 60).toFixed(2)}{" "}
                {`${t("Unit.ShortHours")}`}
              </Typography>
            )}
            {row.xOT > 0 && (
              <Typography
                style={{ fontWeight: 500, fontSize: 14 }}
                color="text.third"
              >
                OT x {payrollSetting.xOT} ={" "}
                {parseFloat(row.xOT / 60).toFixed(2)}{" "}
                {`${t("Unit.ShortHours")}`}
              </Typography>
            )}
            {row.xOTHoliday > 0 && (
              <Typography
                style={{ fontWeight: 500, fontSize: 14 }}
                color="text.third"
              >
                OT x {payrollSetting.xOTHoliday} ={" "}
                {parseFloat(row.xOTHoliday / 60).toFixed(2)}{" "}
                {`${t("Unit.ShortHours")}`}
              </Typography>
            )}
          </Fragment>
        </Fragment>
      ),
    },
    {
      name: `${t("Start")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>
            {dayjs(row.startText, "DD/MM/YYYY HH:mm").format(
              i18n.resolvedLanguage === "th" ? "D MMM BBBB" : "D MMM YYYY"
            )}
          </Typography>
          <Typography>
            {dayjs(row.startText, "DD/MM/YYYY HH:mm").format(
              i18n.resolvedLanguage === "th" ? "HH:mm" : "h:mm A"
            )}
          </Typography>
        </Box>
      ),
    },
    {
      name: `${t("End")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>
            {dayjs(row.endText, "DD/MM/YYYY HH:mm").format(
              i18n.resolvedLanguage === "th" ? "D MMM BBBB" : "D MMM YYYY"
            )}
          </Typography>
          <Typography>
            {dayjs(row.endText, "DD/MM/YYYY HH:mm").format(
              i18n.resolvedLanguage === "th" ? "HH:mm" : "h:mm A"
            )}
          </Typography>
        </Box>
      ),
    },
    {
      name: `${t("OTZone/Week")}`,
      headerTextAlign: "center",
      minWidth: "200px",
      // {findSummaryOfOverTimeHour(row).status}
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Stack alignItems={"center"} justifyContent={"center"} direction={"row"} spacing={1}>
            <Box
              sx={{
                display: 'inline-block',
                width: '16px',
                height: '16px',
                bgcolor: findSummaryOfOverTimeHour(row).status,
                borderRadius: '50%',
                marginLeft: '8px'
              }}
            />
            <Typography fontSize={14}>
              {"("}{findSummaryOfOverTimeHour(row).hours} {t("Unit.Hours")}{")"}
            </Typography>
          </Stack>
        </Box>
      ),
    },
    {
      name: `${t("Reason")}`,
      minWidth: "200px",
      cellRender: (row) => (
        <Box>
          <Typography fontSize="14px">
            {i18n.resolvedLanguage === "th"
              ? row.reasonName
              : row.reasonName_EN}
          </Typography>
          <Typography color="text.third" fontSize="14px">
            {row.otherReason}
          </Typography>
        </Box>
      ),
    },
  ];

  const handleApprovalRequest = async (approve, comment) => {
    const idRequestTimeLV1 = selectedRows
      .filter((x) => {
        return (
          (x.managerLV1ApproveBy === userProfile.idEmployees ||
            (x.idManagerGroupLV1 &&
              userProfile.idManagerGroupList.includes(x.idManagerGroupLV1))) &&
          x.isManagerLV1Approve === null
        );
      })
      .map(function (user) {
        return user.idRequestTime;
      });

    const idRequestTimeLV2 = selectedRows
      .filter((x) => {
        return (
          (x.managerLV2ApproveBy === userProfile.idEmployees ||
            (x.idManagerGroupLV2 &&
              userProfile.idManagerGroupList.includes(x.idManagerGroupLV2))) &&
          x.isManagerLV2Approve === null
        );
      })
      .map(function (user) {
        return user.idRequestTime;
      });

    const idRequestTimeWithdraw = selectedRows
      .filter((x) => {
        return (
          x.managerApprove === userProfile.idEmployees &&
          x.idRequestTimeWithdraw !== null &&
          x.idRequestTimeWithdraw !== undefined
        );
      })
      .map(function (user) {
        return user.idRequestTimeWithdraw;
      });

    const idRequestTime = selectedRows
      .filter((x) => {
        return x.managerApprove === userProfile.idEmployees;
      })
      .map(function (user) {
        return user.idRequestTime;
      });

    var formData = [
      {
        idRequestTimeLV1: idRequestTimeLV1,
        isManagerLV1Approve: approve,
        managerLV1ApproveBy: userProfile.idEmployees,
        approveDate: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        commentManagerLV1: comment ? comment : null,
      },
      {
        idRequestTimeLV2: idRequestTimeLV2,
        isManagerLV2Approve: approve,
        managerLV2ApproveBy: userProfile.idEmployees,
        approveDate: dayjs(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        commentManagerLV2: comment ? comment : null,
      },
      {
        idRequestTimeWithdraw: idRequestTimeWithdraw,
        idRequestTime: idRequestTime,
        isApprove: approve,
        commentManager: comment ? comment : null,
      },
    ];

    const result = await dispatch(approveRequestTime("manager", formData));
    if (result) {
      setSelectedRows([]);
      if (result.status === 200) {
        dispatch(getAllRequestTimeBy("manager", searchDate));
        dispatch(getAllRequestTimeWithDrawBy("manager", searchDate));
        setSnackBarConfig({
          open: true,
          message: `${t("Successfully")}`,
          type: "success",
        });
      } else {
        setSnackBarConfig({
          open: true,
          message: `${t("Unsuccessful")}`,
          type: "error",
        });
      }
    } else {
      setSnackBarConfig({
        open: true,
        message: `${t("Unsuccessful")}`,
        type: "error",
      });
    }
  };

  const handleConfirmReject = (comment) => {
    handleApprovalRequest(0, comment);
  };

  const handleClickAll = () => {
    setSelectedRows(pendingRows);
  };

  const handleCancel = () => {
    setSelectedRows([]);
  };

  useEffect(() => {
    const tempPending = [];

    requestTimeList.map((r) => {
      if (r.idRequestType === 2) {
        if (r.isDoubleApproval === 1) {
          if (
            (r.managerLV1ApproveBy === userProfile.idEmployees ||
              (r.idManagerGroupLV1 &&
                userProfile.idManagerGroupList.includes(
                  r.idManagerGroupLV1
                ))) &&
            r.isManagerLV1Approve === null
          ) {
            if (r.isManagerLV1Approve !== 1 && r.isManagerLV1Approve !== 0) {
              tempPending.push(r);
            }
          } else if (
            r.managerLV2ApproveBy === userProfile.idEmployees ||
            (r.idManagerGroupLV2 &&
              userProfile.idManagerGroupList.includes(r.idManagerGroupLV2))
          ) {
            if (r.isManagerLV2Approve !== 1 && r.isManagerLV2Approve !== 0) {
              tempPending.push(r);
            }
          }
        } else {
          if (r.approvalLevel === 1) {
            if (r.isManagerLV1Approve !== 1 && r.isManagerLV1Approve !== 0) {
              tempPending.push(r);
            }
          } else if (r.approvalLevel === 2) {
            if (r.isManagerLV2Approve !== 1 && r.isManagerLV2Approve !== 0) {
              tempPending.push(r);
            }
          }
        }
      }
    });

    requestTimeWithDrawList.map((r) => {
      if (r.idRequestType === 2) {
        if (r.isApprove === null) {
          tempPending.push(r);
        }
      }
    });
    let pendingList = tempPending.sort(function (a, b) {
      return new Date(b.createDate) - new Date(a.createDate);
    });
    setPendingRows(pendingList);
    setNumberOfList(pendingList.length);
  }, []);

  return (
    <Box>
      {mobileResponsive && (
        <Box display="flex" justifyContent="flex-end" paddingBottom="24px">
          {(selectedRows ? selectedRows.length : 0) > 0 && (
            <StyledButtonCancel
              startIcon={<DeleteIcon />}
              onClick={handleCancel}
            >
              {`${t("Cancel")}`}
            </StyledButtonCancel>
          )}
          {!(
            (selectedRows ? selectedRows.length : 0) === pendingRows.length
          ) && (
              <ButtonBlue
                startIcon={<DoneAll />}
                variant="contained"
                onClick={handleClickAll}
              >
                {`${t("SelectAll")}`}
              </ButtonBlue>
            )}
        </Box>
      )}
      {selectedRows.length > 0 && (
        <Box display="flex" justifyContent="flex-end" paddingBottom="24px">
          <ButtonBlue
            style={{ marginRight: 8 }}
            startIcon={<DoneAll />}
            variant="contained"
            onClick={() => handleApprovalRequest(1)}
          >
            {`${t("Approved")} ${selectedRows.length > 0
              ? `${selectedRows.length} ${t("List")}`
              : ""
              }`}
          </ButtonBlue>
          <ButtonBlue
            startIcon={<DoneAll />}
            variant="outlined"
            onClick={() => setIsOpenRejectDialog(true)}
          >
            {`${t("NotApproved")} ${selectedRows.length > 0
              ? `${selectedRows.length} ${t("List")}`
              : ""
              }`}
          </ButtonBlue>
        </Box>
      )}
      {!mobileResponsive ? (
        <TableCustom
          columns={columns}
          rows={requestTimeList && requestTimeWithDrawList ? pendingRows : []}
          canSelect
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      ) : (
        <div>
          {requestTimeList && requestTimeWithDrawList ? (
            pendingRows.map((row) => (
              <CardRequest
                key={row.idRequestTime}
                row={row}
                mode="OT"
                selectedRows={selectedRows}
                setSelectedRows={setSelectedRows}
              />
            ))
          ) : (
            <div>
              <Typography align="center">{`${t("NoData")}`}</Typography>
            </div>
          )}
        </div>
      )}

      <DialogConfirmReject
        rejectCount={selectedRows.length}
        open={isOpenRejectDialog}
        onClose={() => {
          setIsOpenRejectDialog(false);
        }}
        handleSubmit={(comment) => {
          handleConfirmReject(comment);
        }}
      />
    </Box>
  );
};

export default RequestOverTime;
