import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Typography, Grid, Paper, Avatar, Box, Button, Stack } from "@mui/material";
import { useHistory } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles";
import MailIcon from "@mui/icons-material/Mail";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";


import CardStyle from "../general/Card";
import { useTranslation } from "react-i18next";
import utils from "../../../../utils";
import dayjs from "dayjs";

const useStyles = makeStyles(() => ({
  avatar: {
    width: 200,
    height: 200,
    borderRadius: 180,
    "& .MuiSvgIcon-root": {
      fontSize: "5rem",
    },
  },
  name: {
    fontSize: "1.4rem",
    marginTop: 7,
    fontWeight: "bold",
  },
  data: {
    fontSize: "0.875rem",
    marginTop: 40,
    color: "#6e6e6e",
  },
  mainData: {
    fontSize: "1rem",
    fontWeight: 600,
  },
  Topic: {
    fontSize: "1rem",
  },
  dataProsonal: {
    fontSize: 17,
    marginTop: 10,
    display: "flex",
    alignItems: "center",
  },
  wrapIcon: {
    display: "flex",
    alignItems: "center",
  },
  phoneIcon: {
    color: "#4caf50",
    backgroundColor: "#e8f5e9",
    padding: "5px",
    borderRadius: "10px",
    marginRight: 16,
  },
  mailIcon: {
    color: "#f44336",
    backgroundColor: "#ffebee",
    padding: "5px",
    borderRadius: "10px",
    marginRight: 16,
  },
}));

const DataCareer = ({ data }) => {
  //use style in name of classes
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const { user: currentUser } = useSelector((state) => state.auth);

  const goSeeProfile = (idCompany, idEmployees) => {
    history.push(`/information/${idCompany}/${idEmployees}`)
  }

  return (
    <div style={{ marginBottom: 16 }}>
      <CardStyle>
        <div style={{ padding: "24px 24px 60px 24px" }}>
          <Grid container justifyContent="space-between" alignItems={"center"} spacing={2}>
            <Grid item xl={5} lg={5} md={5} sm={12} xs={12}>
              <center>
                <Avatar
                  alt={`${data.firstname_TH} ${data.lastname_TH}`}
                  src={data.imageProfile}
                  variant={"square"}
                  className={classes.avatar}
                />
              </center>
              <Typography
                className={classes.name}
                style={{ textAlign: "center" }}
              >
                {data.firstname_TH} {data.lastname_TH}
              </Typography>
              <Typography
                className={classes.Topic}
                style={{ textAlign: "center" }}
              >
                {data.positionName}
              </Typography>

              <Stack container justifyContent={"centr"} alignItems={"center"} sx={{ mt: 4 }}>
                <Stack spacing={2}>
                  <Typography variant="body2" className={classes.wrapIcon}>
                    <LocalPhoneIcon className={classes.phoneIcon} />
                    {`${data.telephoneMobile}`}
                  </Typography>
                  <Typography variant="body2" className={classes.wrapIcon}>
                    <MailIcon className={classes.mailIcon} />
                    {data.email}
                  </Typography>
                </Stack>
              </Stack>

              {data && data.idRole === 4 && (
                <Box
                  sx={{ display: "flex", justifyContent: "center", marginTop: 4 }}
                >
                  <Button
                    onClick={() => goSeeProfile(data.idCompany, data.idEmployees)}
                    variant="outlined"
                    sx={{ paddingLeft: 4, paddingRight: 4 }}
                  >
                    View Profile
                  </Button>
                </Box>
              )}
            </Grid>
            <Grid item xl={7} lg={7} md={7} xs={12}>
              <Grid container spacing={2}>
                <Grid item xl={4} md={4} sm={6} xs={12}>
                  <Typography className={classes.data}>
                    Employee Type
                  </Typography>
                  <Typography className={classes.mainData}>
                    {data.employmentTypeName ? data.employmentTypeName : "-"}
                  </Typography>
                  <Typography className={classes.data}>{t("Division")}</Typography>
                  <Typography className={classes.mainData}>
                    {data.divisionName ? data.divisionName : "-"}
                  </Typography>
                  <Typography className={classes.data}>Job Group</Typography>
                  <Typography className={classes.mainData}>
                    {data.jobGroupName ? data.jobGroupName : "-"}
                  </Typography>
                </Grid>
                <Grid item xl={4} md={4} sm={6} xs={12}>
                  <Typography className={classes.data}>Working Type</Typography>
                  <Typography className={classes.mainData}>
                    {data.workingType ? data.workingType : "-"}
                  </Typography>
                  <Typography className={classes.data}>{t("Department")}</Typography>
                  <Typography className={classes.mainData}>
                    {data.departmentName ? data.departmentName : "-"}
                  </Typography>
                  <Typography className={classes.data}>{t("Position")}</Typography>
                  <Typography className={classes.mainData}>
                    {data.positionName ? data.positionName : "-"}
                  </Typography>
                </Grid>
                <Grid item xl={4} md={4} sm={6} xs={12}>
                  <Typography className={classes.data}>{t("Company")}</Typography>
                  <Typography className={classes.mainData}>
                    {data.companyName ? data.companyName : "-"}
                  </Typography>
                  <Typography className={classes.data}>{t("Section")}</Typography>
                  <Typography className={classes.mainData}>
                    {data.sectionName ? data.sectionName : "-"}
                  </Typography>
                  <Typography className={classes.data}>Report to</Typography>
                  <Typography className={classes.mainData}>
                    {data.ManagerFirstname_TH ? data.ManagerFirstname_TH : "-"}{" "}{data.ManagerLastname_TH ? data.ManagerLastname_TH : "-"}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xl={4} md={4} sm={6} xs={12}>
                  <Typography className={classes.data}>
                    Personal Level
                  </Typography>
                  <Typography className={classes.mainData}>
                    {data.PersonalLevel ? data.PersonalLevel : "-"}
                  </Typography>
                  <Typography className={classes.data}>{t("ESY")}</Typography>
                  <Typography className={classes.mainData}>
                    {data.hiringDate
                      ? `${utils.getAgeMonthYear(
                        dayjs(data.hiringDate).format("DD/MM/YYYY"),
                        i18n.resolvedLanguage
                      )}`
                      : "-"}
                  </Typography>
                </Grid>
                <Grid item xl={4} md={4} sm={6} xs={12}>
                  <Typography className={classes.data}>{t("TIG")}</Typography>
                  <Typography className={classes.mainData}>
                    {data.employeePersonnelLevelStart
                      ? `${utils.getAgeMonthYear(
                        dayjs(data.employeePersonnelLevelStart).format("DD/MM/YYYY"),
                        i18n.resolvedLanguage
                      )}`
                      : "-"}
                  </Typography>
                  <Typography className={classes.data}>{t("ServiceYear")}</Typography>
                  <Typography className={classes.mainData}>
                    {data.hiringDate
                      ? `${utils.getAgeMonthYear(
                        dayjs(data.hiringDate).format("DD/MM/YYYY"),
                        i18n.resolvedLanguage
                      )}`
                      : "-"}
                  </Typography>
                </Grid>
                <Grid item xl={4} md={4} sm={6} xs={12}>
                  <Typography className={classes.data}>{t("TIP")}</Typography>
                  <Typography className={classes.mainData}>
                    {data.employeePositionStart
                      ? `${utils.getAgeMonthYear(
                        dayjs(data.employeePositionStart).format("DD/MM/YYYY"),
                        i18n.resolvedLanguage
                      )}`
                      : "-"}
                  </Typography>
                  <Typography className={classes.data}>Age</Typography>
                  <Typography className={classes.mainData}>
                    {data.birthday
                      ? `${utils.getAgeMonthYear(
                        dayjs(data.birthday).format("DD/MM/YYYY"),
                        i18n.resolvedLanguage
                      )}`
                      : "-"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </CardStyle>
    </div>
  );
};

export default DataCareer;
