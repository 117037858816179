import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Controller, useForm } from "react-hook-form";
import {
    Typography,
    Alert,
    MenuItem,
    Stack
} from "@mui/material";
import DrawerCustom from "../../shared/general/Drawer";
import ButtonBlue from "../../shared/general/ButtonBlue";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import { useHistory } from "react-router-dom";
import { PayrollListMonthXlsx } from "./xlsx-export/payroll-monthlist-xlsx";
import { getAllPayrollListByMonth } from "../../../../actions/payruns-export";
import { useDispatch, useSelector } from "react-redux";
import { getAffiliateOrganization, getCompanyOrganizationList } from "../../../../actions/company";
import SelectAffiliateCompany from "../../shared/general/SelectAffiliateCompany";
import { useTranslation } from "react-i18next";
import LoadingGif from "../../assets/social-media.gif";

const StyledRoot = styled("div")({
    maxWidth: 550,
    padding: 24,
    "& .GridTopicInput": {
        display: "flex",
        alignItems: "center"
    },
});

const PayrollListMonth = (props) => {
    const dispatch = useDispatch();
    const { open, handleClose } = props;
    const { control } = useForm({});
    const { t, i18n } = useTranslation();
    const currentYear = new Date().getFullYear();

    const { result: userProfile } = useSelector((state) => state.userProfile);
    const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);
    const [isFetching, setIsFetching] = useState(true);
    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [openAlert, setOpenAlert] = useState(false);
    const [payrollList, setPayrollList] = useState(null);

    const handleOpenAlertError = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
        setSelectedYear(null);
        setSelectedMonth(null);
    };

    const onChangeCompany = (newValue) => {
        setSelectedCompany(newValue);
    };

    const fetchedData = async () => {
        try {
            setIsFetching(true);
            const response = await getAllPayrollListByMonth(selectedCompany && selectedCompany.idCompany, selectedYear - 543, selectedMonth);
            if (response && response.data && response.data.length > 0) {
                setIsFetching(false);
                setPayrollList(response.data);
            } else {
                setIsFetching(false);
                handleOpenAlertError();
            }
        } catch (error) {
            setIsFetching(false);
            console.error('Error fetching data:', error);
        };
    }

    useEffect(() => {
        if (selectedCompany && selectedYear && selectedMonth) {
            fetchedData();
        } else {
            setIsFetching(false);
        }
    }, [selectedYear, selectedMonth, selectedCompany]);

    const handleFileExport = (data) => {
        PayrollListMonthXlsx(data, t);
    };

    const thaiYears = Array.from(
        { length: 6 },
        (_, index) => currentYear + 543 - index
    );

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };

    const handleMonthChange = (event) => {
        setSelectedMonth(event.target.value);
    };

    const months = [
        { value: "01", name: "มกราคม" },
        { value: "02", name: "กุมภาพันธ์" },
        { value: "03", name: "มีนาคม" },
        { value: "04", name: "เมษายน" },
        { value: "05", name: "พฤษภาคม" },
        { value: "06", name: "มิถุนายน" },
        { value: "07", name: "กรกฎาคม" },
        { value: "08", name: "สิงหาคม" },
        { value: "09", name: "กันยายน" },
        { value: "10", name: "ตุลาคม" },
        { value: "11", name: "พฤศจิกายน" },
        { value: "12", name: "ธันวาคม" },
    ];

    useEffect(() => {
        dispatch(getAffiliateOrganization());
    }, []);

    useEffect(() => {
        if (selectedCompany) {
            dispatch(getCompanyOrganizationList({
                idCompany: selectedCompany.idCompany,
                getSection: true,
            }));
        }
    }, [selectedCompany]);

    useEffect(() => {
        if (userProfile && affiliateOrganizationList && affiliateOrganizationList.length > 0) {
            let ownCompany = affiliateOrganizationList.find(x => x.idCompany === userProfile.idCompany);
            if (!ownCompany) {
                ownCompany = affiliateOrganizationList[0];
            }
            setSelectedCompany(ownCompany)
        }
    }, [userProfile, affiliateOrganizationList]);

    return (
        <DrawerCustom
            title={t("MonthlyPayrollReport")}
            anchor="right"
            open={open}
            onClose={handleClose}
        >
            <StyledRoot style={{ width: 400 }}>
                <Stack spacing={4}>
                    {openAlert && (
                        <Alert severity="error" onClose={() => handleCloseAlert()}>{t("NoData")}</Alert>
                    )}
                    <Stack spacing={2}>
                        <Stack spacing={1}>
                            <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">{t("Company")}</Typography>
                            <SelectAffiliateCompany
                                options={affiliateOrganizationList}
                                value={selectedCompany}
                                disabled={isFetching || openAlert === true}
                                onChange={(_, value) => {
                                    onChangeCompany(value);
                                }}
                            />
                        </Stack>
                        <Stack spacing={1}>
                            <Typography>{t("SelectYear")}</Typography>
                            <Stack
                                direction={"row"}
                                justifyContent="space-between"
                                alignItems={"center"}
                                spacing={2}
                            >
                                <Controller
                                    name="year"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldTheme
                                            {...field}
                                            variant="filled"
                                            select
                                            fullWidth
                                            value={selectedYear}
                                            onChange={handleYearChange}
                                            disabled={openAlert === true}
                                        >
                                            {thaiYears.map((year) => (
                                                <MenuItem key={year} value={year}>
                                                    {year}
                                                </MenuItem>
                                            ))}
                                        </TextFieldTheme>
                                    )}
                                />
                            </Stack>
                        </Stack>
                        <Stack spacing={1}>
                            <Typography>{t("SelectMonth")}</Typography>
                            <Stack
                                direction={"row"}
                                justifyContent="space-between"
                                alignItems={"center"}
                                spacing={2}
                            >
                                <Controller
                                    name="year"
                                    control={control}
                                    render={({ field }) => (
                                        <TextFieldTheme
                                            {...field}
                                            variant="filled"
                                            select
                                            fullWidth
                                            value={selectedMonth}
                                            onChange={handleMonthChange}
                                            disabled={openAlert === true}
                                        >
                                            {months.map((month) => (
                                                <MenuItem key={month} value={month.value}>
                                                    {month.name}
                                                </MenuItem>
                                            ))}
                                        </TextFieldTheme>
                                    )}
                                />
                            </Stack>
                        </Stack>
                    </Stack>

                    {isFetching ? (
                        <img src={LoadingGif} style={{ width: 80, height: 80 }} />
                    ) : (
                        <ButtonBlue
                            variant="contained"
                            disabled={isFetching || (payrollList && payrollList.length < 1 || !payrollList)}
                            onClick={() => handleFileExport(payrollList)}
                        >
                            {t("Download")}
                        </ButtonBlue>
                    )}
                </Stack>
            </StyledRoot>
        </DrawerCustom >
    )
}

export default PayrollListMonth;