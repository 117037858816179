import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { styled } from "@mui/material";
import { Visibility } from '@mui/icons-material';

import AlertResponse from "../../../../shared/general/AlertResponse";
import ButtonBlue from "../../../../shared/general/ButtonBlue";

import Paper from "@mui/material/Paper";
import DialogContract from "./DialogContract";
import { getEmploymentTypeByidCompany } from "../../../../../../actions/employment-type";
import { deleteEmployeeContract, getEmployeeContractByEmployee } from "../../../../../../actions/employee";
import { EmpContractPdfForm } from "../../fileDoc/emp_contract";
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';

const StyledWrapBranch = styled("div")({
    marginBottom: 36,
    "& .wrap-branch-top": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: 16,
        "& .MuiTypography-body2": {
            margin: 0,
        },
    },
    "& .branch-scroll": {
        height: 280,
        overflow: "auto",
    },
    "& .action": {
        textAlign: "right",
        "& .fal": {
            fontSize: 18,
        },
    },
    "& .address": {
        fontWeight: 600,
        fontSize: 18,
    },
    "& .contact": {
        "& .fal": {
            marginRight: 8,
        },
    },
});

const StyledHeadLabel = styled(Typography)({
    fontWeight: 600,
    fontSize: 14,
});

function createData(start, end, type, file, status) {
    return { start, end, type, file, status };
}

// const rows = [
//     createData("2023-01-01", "2023-06-01", "พนักงานประจำ", "", "active"),
//     createData("2023-06-01", "2023-09-01", "พนักงานประจำ", "", "active"),
//     createData("2023-09-01", "2025-06-01", "พนักงานประจำ", "", "active"),
// ];

const EmploymentContract = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { result: userProfile } = useSelector((state) => state.userProfile);
    const { result: employeeProfile } = useSelector((state) => state.employeeProfile);
    // const { result: employeeContract } = useSelector((state) => state.contract);
    const [employeeContract, setEmployeeContract] = useState(null);
    const [openContract, setOpenContract] = useState(false);
    const [idContract, setIdContract] = useState(null);
    const [typeDialog, setTypeDialog] = useState("Add");
    const [alertStatus, setAlertStatus] = useState({
        isOpen: false,
        type: null,
        label: null
    });

    const handleOpenContract = (type, idCont) => {
        if (type === "Add") {
            setTypeDialog("Add");
        } else {
            setTypeDialog("Edit");
        }
        if (idCont) {
            setIdContract(idCont);
        }
        setOpenContract(true);
    };

    const handleClose = () => {
        setOpenContract(false);
        setIdContract(null);
    };

    useEffect(() => {
        if (employeeProfile && employeeProfile.idCompany) {
            dispatch(getEmploymentTypeByidCompany(employeeProfile.idCompany));
        }
    }, [employeeProfile]);

    const fetchedData = async (idEmployees) => {
        try {
            const response = await getEmployeeContractByEmployee(idEmployees);
            if(response){
                setEmployeeContract(response.data);
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (employeeProfile && employeeProfile.idEmployees) {
            // dispatch(getEmployeeContractByEmployee(employeeProfile.idEmployees));
            fetchedData(employeeProfile.idEmployees)
        }
    }, [employeeProfile]);

    const DeleteContract = async (id) => {
        try {
            const result = await Swal.fire({
                title: "ยืนยันการลบสัญญาจ้าง",
                text: "คุณต้องการลบไฟล์นี้หรือไม่",
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#d33",
                cancelButtonColor: "#3085d6",
                confirmButtonText: t("Delete"),
                cancelButtonText: t("Cancel")
            });

            if (result.isConfirmed) {
                const response = await deleteEmployeeContract(id, employeeProfile.idEmployees);
                if (response && response.status === 200) {
                    Swal.fire({
                        title: "ลบแล้ว",
                        text: "สัญญาจ้างของคุณถูกลบแล้ว",
                        icon: "success",
                        confirmButtonColor: "#3085d6",
                        confirmButtonText: t("Success"),
                        didDestroy: () => {
                            window.location.reload();
                        }
                    });
                }
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div>
            <StyledWrapBranch>
                <div className="wrap-branch-top">
                    <StyledHeadLabel variant="body2" gutterBottom>
                        {t("EmploymentContract")}
                    </StyledHeadLabel>
                    {!userProfile.readOnly && (
                        <ButtonBlue
                            style={{ marginRight: 8 }}
                            size="small"
                            variant="contained"
                            onClick={() => handleOpenContract("Add")}
                        >
                            {t("AddEmploymentContract")}
                        </ButtonBlue>
                    )}
                </div>
                <TableContainer component={Paper} style={{ boxShadow: "none" }}>
                    <Table style={{ minWidth: 600 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">{t("StartDate")}</TableCell>
                                <TableCell align="center">{t("EndDate")}</TableCell>
                                <TableCell align="center">{t("EmploymentType")}</TableCell>
                                <TableCell align="center">{t("AttachFile")}</TableCell>
                                {!userProfile.readOnly && <TableCell align="center">{t("Cancel")}</TableCell>}
                                {/* <TableCell align="center">{t("Cancel")}</TableCell> */}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell align="center">
                                    {dayjs(employeeProfile.hiringDate)
                                        .format(
                                            i18n.resolvedLanguage === "th" ? "DD MMM BBBB" : "DD MMM YYYY"
                                        )}
                                </TableCell>
                                {employeeProfile.contractTermainatoinDate ? (
                                    <TableCell align="center">
                                        {dayjs(employeeProfile.contractTermainatoinDate)
                                            .format(
                                                i18n.resolvedLanguage === "th" ? "DD MMM BBBB" : "DD MMM YYYY"
                                            )}
                                    </TableCell>
                                ) : (
                                    <TableCell align="center">
                                        <Typography>-</Typography>
                                    </TableCell>
                                )}
                                <TableCell align="center">{employeeProfile.employmentTypeName}</TableCell>
                                <TableCell align="center">
                                    <ButtonBlue
                                        onClick={() => {
                                            EmpContractPdfForm(employeeProfile)
                                        }}
                                    >
                                        {t("OpenFile")}
                                    </ButtonBlue>
                                </TableCell>
                                {!userProfile.readOnly && (
                                    <TableCell align="center">
                                        <IconButton aria-label="delete" color="error" disabled>
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                )}
                            </TableRow>
                            {(employeeContract && employeeContract.length > 0) ?
                                employeeContract.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="center">
                                            {dayjs(row.startDate)
                                                .locale(localStorage.getItem("language") || "th")
                                                .format(
                                                    localStorage.getItem("language") === "th"
                                                        ? "DD MMM BBBB"
                                                        : "DD MMM YYYY"
                                                )}
                                        </TableCell>
                                        <TableCell align="center">
                                            {dayjs(row.endDate)
                                                .locale(localStorage.getItem("language") || "th")
                                                .format(
                                                    localStorage.getItem("language") === "th"
                                                        ? "DD MMM BBBB"
                                                        : "DD MMM YYYY"
                                                )}
                                        </TableCell>
                                        <TableCell align="center">{row.employmentTypeName}</TableCell>
                                        <TableCell align="center">
                                            <ButtonBlue
                                                onClick={() => {
                                                    if (row.filename !== null) {
                                                        window.open(row.fileUrl, "_blank");
                                                    }
                                                }}
                                                disabled={row.filename === null}
                                            >
                                                {t("OpenFile")}
                                            </ButtonBlue>
                                        </TableCell>
                                        <TableCell align="center">
                                            <IconButton
                                                aria-label="delete"
                                                color="error"
                                                onClick={() => {
                                                    DeleteContract(row.idEmployeeContract);
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))
                                : null
                                // (
                                //     <TableRow>
                                //         <TableCell align="center" colSpan={4}>
                                //             <Typography align="center"> ไม่พบสัญญา</Typography>
                                //         </TableCell>
                                //     </TableRow>
                                // )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </StyledWrapBranch>
            {openContract && (
                <DialogContract
                    open={openContract}
                    handleClose={handleClose}
                    type={typeDialog}
                    setAlertStatus={setAlertStatus}
                />
            )}
            {alertStatus.isOpen &&
                <AlertResponse
                    open={alertStatus.isOpen}
                    handleClose={() => {
                        setAlertStatus({
                            isOpen: false,
                            type: null,
                            label: null
                        })
                    }}
                    alertType={alertStatus.type}
                    label={alertStatus.label}
                />
            }
        </div>
    );
};

export default EmploymentContract;
