import React, { useEffect, useState, Fragment } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import {
  CardContent,
  Typography,
  Container,
  Menu,
  MenuItem,
  Stack,
  Box,
  TextField,
  Popper,
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

import AddIcon from "@mui/icons-material/Add";
import ExposureIcon from "@mui/icons-material/Exposure";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";

import HeaderPage from "../../../shared/header/headerPage";
import StyledCard from "../../../shared/general/Card";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DatePickerCustom from "../../../shared/date/datePicker";
import AlertResponse from "../../../shared/general/AlertResponse";

import { allPayruns } from "../../../../../actions/payruns";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import TableList from "./tableList";
import DialogNew from "./new";
import DialogUpload from "./upload-payroll";
import { PayrunXlsxFile } from "./PayrunXlsxFile";

//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .wrap-header": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
    "& .inner-header-name": {
      display: "flex",
      alignItems: "center",
    },
  },
  "& .btn-setting": {
    marginLeft: 8,
  },
});

const StyledCardContent = styled(CardContent)({});

const StyledBoxSearchDate = styled(Box)({
  maxWidth: 200,
  marginBottom: 16,
  marginRight: 16,
});
const StyledBoxSearchCompany = styled(Box)({
  width: 350,
  marginBottom: 16,
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      padding: "8px 16px",
      margin: "0 8px",
      borderRadius: 8,
    },
    [`& .${autocompleteClasses.groupLabel}`]: {
      lineHeight: "32px",
    },
  },
});

const PayRunsPage = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const today = dayjs().toDate();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: payRunList } = useSelector((state) => state.payRun);
  const { result: affiliateOrganizationList } = useSelector(
    (state) => state.affiliateOrganization
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [openDialogNew, setOpenDialogNew] = React.useState(false);
  const [openDialogUpload, setOpenDialogUpload] = React.useState(false);
  const [search, setSearch] = useState(new Date());
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companyList, setCompanyList] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  const mode = [
    {
      id: 1,
      header: `${t("Draft")}`,
      listPayrun: [],
      isDraft: 1,
      isApprove: 0,
      isPaid: 0,
    },
    {
      id: 2,
      header: `${t("WaitingForApproval")}`,
      listPayrun: [],
      isDraft: 0,
      isApprove: 0,
      isPaid: 0,
    },
    {
      id: 3,
      header: `${t("ApprovedPayroll")}`,
      listPayrun: [],
      isDraft: 0,
      isApprove: 1,
      isPaid: 0,
    },
    // {
    //   id: 3,
    //   header: "จ่ายเงินแล้ว",
    //   listPayrun: [],
    //   isDraft: 0,
    //   isApprove: 1,
    //   isPaid: 1,
    // },
  ];

  useEffect(() => {
    if (affiliateOrganizationList && affiliateOrganizationList.length > 0) {
      let temp = affiliateOrganizationList.map((x) => ({
        idCompany: x.idCompany,
        companyName: x.companyName,
        companyName_EN: x.companyName_EN,
      }));

      setSelectedCompany(temp[0]);
      setCompanyList(temp);
      dispatch(allPayruns(dayjs(today).format("YYYY"), temp[0].idCompany));
    }
  }, [affiliateOrganizationList]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenDialogNew = () => {
    setAnchorEl(null);
    setOpenDialogNew(true);
  };

  const handleCloseDialogNew = () => {
    setOpenDialogNew(false);
  };

  const handleOpenDialogUpload = () => {
    setAnchorEl(null);
    setOpenDialogUpload(true);
  };

  const handleCloseDialogUpload = () => {
    setOpenDialogUpload(false);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  return (
    <StyledRoot className={`page`}>
      <Container maxWidth="lg">
        <div className="wrap-header">
          <Fragment>
            <div className="inner-header-name">
              <HeaderPage textLabel={`${t("ManageSalary")}`} />
              <div>
                <ButtonBlue
                  className="btn-setting"
                  size="small"
                  component={NavLink}
                  to="/payroll/setting"
                  variant="outlined"
                  color="secondary"
                  startIcon={<SettingsRoundedIcon />}
                  disabled={false}
                >
                  {t("Setting")}
                </ButtonBlue>
              </div>
            </div>

            <div style={{ display: "flex" }}>
              <div style={{ marginRight: 8 }}>
                <ButtonBlue
                  variant={"outlined"}
                  component={NavLink}
                  to="/payroll/addition-deduction"
                  startIcon={<ExposureIcon />}
                >
                  {t("Addition")} - {t("Deduction")}
                </ButtonBlue>
              </div>
              <div style={{ marginLeft: 8 }}>
                <ButtonBlue
                  variant={"contained"}
                  startIcon={<AddIcon />}
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  {t("SalaryList")}
                </ButtonBlue>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={handleOpenDialogNew}>
                    {t("CreateSalaryList")}
                  </MenuItem>
                  <MenuItem onClick={handleOpenDialogUpload}>
                    {t("SalaryImport")}
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </Fragment>
        </div>
        <div>
          {payRunList && (
            <div style={{ display: "flex" }}>
              <StyledBoxSearchDate>
                <div className="search-date">
                  <DatePickerCustom
                    inputFormat="YYYY"
                    value={search}
                    name="start"
                    views={["year"]}
                    minDate={dayjs(today).set("year", payRunList.resultMinYear)}
                    maxDate={dayjs(today)}
                    onChange={(newValue) => {
                      setSearch(newValue);
                      dispatch(
                        allPayruns(
                          dayjs(newValue).format("YYYY"),
                          selectedCompany.idCompany
                        )
                      );
                    }}
                  />
                </div>
              </StyledBoxSearchDate>
              <StyledBoxSearchCompany>
                <StyledAutocomplete
                  options={affiliateOrganizationList ? companyList : []}
                  onChange={(event, newValue) => {
                    console.log("newValue Company:", newValue);
                    setSelectedCompany(newValue);
                    dispatch(
                      allPayruns(
                        dayjs(search).format("YYYY"),
                        newValue.idCompany
                      )
                    );
                  }}
                  value={selectedCompany}
                  disableClearable={true}
                  popupIcon={<i class="fa-light fa-chevron-down"></i>}
                  getOptionLabel={(option) =>
                    i18n.language === "th"
                      ? option.companyName
                      : option.companyName_EN
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      placeholder={`${t("AllCompany")}`}
                    />
                  )}
                  PopperComponent={StyledPopper}
                  noOptionsText={`${t("NoData")}`}
                />
              </StyledBoxSearchCompany>
            </div>
          )}
        </div>
        <div style={{ paddingBottom: 16 }}>
          {mode.map((value, index) => {
            return (
              <div key={index} style={{ marginBottom: 16 }}>
                <StyledCard>
                  <StyledCardContent>
                    <div>
                      <Stack
                        justifyContent={"space-between"}
                        alignItems={"center"}
                        direction={"row"}
                        style={{ marginBottom: 8 }}
                      >
                        <Typography variant="h6" gutterBottom>
                          {value.header}
                        </Typography>
                        {/* {value.id === 2 && (
                          <ButtonBlue
                            variant="outlined"
                            startIcon={<FileDownloadIcon />}
                            onClick={() => PayrunXlsxFile()}
                          >
                            {`${t("Download")}`}
                          </ButtonBlue>
                        )} */}
                      </Stack>
                    </div>
                    <div>
                      {payRunList && (
                        <TableList
                          payRunList={payRunList.resultData.filter(
                            (item) =>
                              item.isDraft === value.isDraft &&
                              item.isApprove === value.isApprove
                          )}
                        />
                      )}
                    </div>
                  </StyledCardContent>
                </StyledCard>
              </div>
            );
          })}
        </div>
        <DialogNew
          open={openDialogNew}
          handleClose={handleCloseDialogNew}
          isReadOnly={userProfile && userProfile.readOnly}
          handleOpenAlert={handleOpenAlert}
          handleChangeAlertType={handleChangeAlertType}
        />
        <DialogUpload
          open={openDialogUpload}
          handleClose={handleCloseDialogUpload}
          isReadOnly={userProfile && userProfile.readOnly}
        />
        <AlertResponse
          open={openAlert}
          handleClose={handleCloseAlert}
          alertType={alertType}
        />
      </Container>
    </StyledRoot>
  );
};

export default PayRunsPage;
