import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Avatar,
  Box,
  Container,
  Grid,
  Popper,
  TextField,
  Typography,
  autocompleteClasses,
  styled,
  Card,
  Select,
  MenuItem,
  Stack,
  SvgIcon,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { getAllIndividualObjectiveByIdEmp } from "../../../../../actions/okrs";
import { getAllEmployees } from "../../../../../actions/employee";
import { useDispatch, useSelector } from "react-redux";
import CardObjective from "./cardObjective";
import { useTranslation } from "react-i18next";
import {
  getUserCompany,
  getUserDepartment,
  getUserDivision,
  getUserFullName,
  getUserPosition,
  getUserSection,
} from "../../../../../utils/userData";
import dayjs from "dayjs";
import { ReactComponent as MoonshotIcon } from "../../../assets/moonshot_logo.svg";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import StarRateIcon from '@mui/icons-material/StarRate';

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiCardContent-root": {
      padding: 0,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 16,
    },
    "& .MuiOutlinedInput-input": {
      padding: "16px 14px",
    },
    "& .MuiTableCell-root": {
      paddingTop: "5px",
      paddingBottom: "5px",
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
  },
}));

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledRoot = styled(Box)({
  "& .keyresult-text": {
    fontSize: "20px",
    fontWeight: 500,
  },
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
});

const StyledCard = styled(Card)({
  minHeight: "70px",
  boxShadow: "none",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  background:
    "linear-gradient(to right, rgba(235, 148, 192, 0.5), rgba(188, 144, 213, 0.5))",
  "& .cardTitle": {
    fontSize: 22,
  },
  "& .MuiCardContent-root": {
    padding: 24,
  },
  "& .cardHeader": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
});

const StyleHeadBox = styled(Box)({
  "& .box-header": {
    minHeight: "200px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor:
      "linear-gradient(to right, rgba(235, 148, 192, 0.5), rgba(188, 144, 213, 0.5))",
    "& .text-header": {
      fontSize: "48px",
      fontWeight: "bold",
    },
    "& .img-header": {
      objectFit: "contain"
    },
  },

  "@media (max-width: 600px)": {
    "& .box-header": {
      flexDirection: "column",
      minHeight: "unset",
      justifyContent: "center",
      alignItems: "center",
      "& .text-header": {
        fontSize: "36px",
        marginTop: "20px",
        aliignText: "center",
      },
        // "& .img-header": {
        //   width: "30%",
        //   justifyContent: "flex-start",
        //   alignItems: "flex-start",
        // },
    },
  },
});

const StyleText = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  marginBottom: "16px",
  "& .name-text": {
    fontSize: "24px",
    marginTop: "20px",
    fontWeight: "500",
  },
});

const ContainerStyled = styled(Container)({
  paddingTop: "10px",
  height: "100%",
  minHeight: "100vh",
});

const StyledRating = styled(Box)(({ backgroundColor }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  gap: "8px",
  padding: "16px",
  borderRadius: "16px",
  maxWidth: "160px", 
  backgroundColor,
}));

function OKRStatus() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectYear, setSelectYear] = useState(dayjs().get('year'));
  const [individualObjective, setIndividualObjective] = useState(null);

  const { result: employeesList } = useSelector((state) => state.employees);

  const ratingInYear = individualObjective && individualObjective.reduce((acc, objective) => {
    if (objective.year === selectYear) {
      const k = objective.keyResults.reduce((acc, keyresult) => {
        let xRating = 0
        if (keyresult.isMoonShotPlanning) {
          xRating += 1.5
          acc.numberOfMoonShot += 1
        }
        if (keyresult.isMoonShotEvaluation) {
          xRating += 1.5
          acc.numberOfMoonShot += 1
        }
        if (keyresult.isSuccess) {
          acc.numberOfSuccess += 1
        }
        acc.managerRating +=
          xRating > 0
            ? keyresult.managerRating * xRating
            : keyresult.managerRating

        if (keyresult.managerRating !== null) {
          acc.numberOfRating += 1
        }
        
        return acc
      }, { numberOfMoonShot: 0, managerRating: 0, numberOfSuccess: 0, numberOfKeyResult: 0, numberOfRating: 0 })

      acc.totalMoonShot += k.numberOfMoonShot
      acc.totalRating += k.managerRating
      acc.totalSuccess += k.numberOfSuccess
      acc.totalKeyResult += objective.keyResults.length
      acc.totalNumberOfRating += k.numberOfRating
    }
    return acc
  },
  {
    totalMoonShot: 0,
    totalRating: 0,
    totalSuccess: 0,
    totalKeyResult: 0,
    totalNumberOfRating: 0,
  }) 

  const handleChageEmployee = async (employee) => {
    dispatch(getAllIndividualObjectiveByIdEmp(employee.idEmployees)).then((res) => {
      if (res.status === 200) {
        setIndividualObjective(res.data);
      }
    });
    setSelectedEmployee(employee)
  };

  const handleChange = (event) => {
    setSelectYear(event.target.value);
  };

  useEffect(() => {
    dispatch(getAllEmployees());
  }, []);

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        {employeesList && (
          <StyledCard
            style={{
              marginBottom: "48px",
              marginTop: "20px",
            }}
          >
            <StyleHeadBox>
              <Box className="box-header">
                <Grid 
                  container
                  pt={"40px"}
                  pl={{ xs: "16px", sm: "40px" }}
                  pb={{ xs: "0px", sm: "40px" }}
                >
                  <Grid item xs={12}>
                    <Typography className="text-header">
                      {`${t("OKRStatus")}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} display="flex" gap="8px" flexWrap="wrap">
                    <div>
                      <Typography
                        fontSize="14px"
                        fontWeight="600"
                        marginBottom="4px"
                        color="text.third"
                      >
                        {t("Employee")}
                      </Typography>
                      <StyledAutocomplete
                        disableClearable
                        style={{
                          width: "240px",
                          background: "#fff",
                          border: "none",
                          borderRadius: "8px",
                        }}
                        options={employeesList}
                        onChange={(event, newValue) => handleChageEmployee(newValue)}
                        popupIcon={<i className="fa-light fa-chevron-down"></i>}
                        getOptionLabel={(option) =>
                          `${option.firstname_TH} ${option.lastname_TH}`
                        }
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.idEmployees}>
                              {`${option.firstname_TH} ${option.lastname_TH}`}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="filled"
                            placeholder={`${t("SelectEmp")}`}
                          />
                        )}
                        PopperComponent={StyledPopper}
                        noOptionsText={`${t("NoData")}`}
                      />
                    </div>
                    <div>
                      <Typography
                        fontSize="14px"
                        fontWeight="600"
                        marginBottom="4px"
                        color="text.third"
                      >
                        {t("SelectYear")}
                      </Typography>
                      <Select
                        value={selectYear}
                        onChange={handleChange}
                        sx={{ borderRadius: "10px", backgroundColor: "#ffffff" }}
                      >
                        {[0, 1, 2].map((item) => (
                          <MenuItem key={item} value={dayjs().get('year') - item}>
                            {dayjs().subtract(item, 'year').format(i18n.resolvedLanguage === "th" ? "BBBB" : "YYYY")}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  </Grid>
                </Grid>
                <img
                  className="img-header"
                  src={`${process.env.PUBLIC_URL}/assets/okrs/admin/progress-status.png`}
                  alt="icon"
                />
              </Box>
            </StyleHeadBox>
          </StyledCard>
        )}

        <ContainerStyled>
          {individualObjective && individualObjective.length > 0 ? (
            <div className={classes.root}>
              <Grid container spacing={2} marginBottom={4}>
                <Grid item xs={12} sm={4}>
                  {selectedEmployee && (
                    <Stack alignItems="center" padding="16px" bgcolor={"#ffffff"} borderRadius="20px">
                      <Avatar
                        src={selectedEmployee.imageProfile}
                        alt={"imageProfile"}
                        style={{ width: 150, height: 150 }}
                      />
                      <StyleText>
                        <Typography className="name-text">
                          {getUserFullName({
                            firstname_TH: selectedEmployee.firstname_TH,
                            lastname_TH: selectedEmployee.lastname_TH,
                            firstname_EN: selectedEmployee.firstname_EN,
                            lastname_EN: selectedEmployee.lastname_EN,
                          })}
                        </Typography>
                        <Typography fontSize="18px" color="text.secondary">
                          {getUserPosition({
                            positionName: selectedEmployee.positionName,
                            positionName_EN: selectedEmployee.positionName_EN,
                          })}
                        </Typography>
                        <Typography fontSize="18px" color="text.secondary">
                          {getUserCompany({
                            companyName: selectedEmployee.companyName,
                            companyName_EN: selectedEmployee.companyName_EN,
                          })}
                        </Typography>
                        <Typography fontSize="18px" color="text.secondary">
                          {getUserDivision({
                            divisionName: selectedEmployee.divisionName,
                            divisionName_EN: selectedEmployee.divisionName_EN,
                          })}
                        </Typography>
                        <Typography fontSize="18px" color="text.secondary">
                          {getUserDepartment({
                            departmentName: selectedEmployee.departmentName,
                            departmentName_EN: selectedEmployee.departmentName_EN,
                          })}
                        </Typography>
                        <Typography fontSize="18px" color="text.secondary">
                          {getUserSection({
                            sectionName: selectedEmployee.sectionName,
                            sectionName_EN : selectedEmployee.sectionName_EN,
                          })}
                        </Typography>
                      </StyleText>
                      <Stack gap={"8px"}>
                        <StyledRating backgroundColor="#dfe8ff">
                          <SvgIcon fontSize="large">
                            <MoonshotIcon />
                          </SvgIcon>
                          <Typography fontSize="20px" fontWeight={500}>
                            {ratingInYear.totalMoonShot}
                          </Typography>
                        </StyledRating>
                        <StyledRating backgroundColor="#d4ffce">
                          <CheckCircleIcon
                            fontSize="large"
                            sx={{ "&.MuiSvgIcon-root": { color: "#33a522" }}}
                          />
                          <Typography fontSize="20px" fontWeight={500}>
                            {`${ratingInYear.totalSuccess / ratingInYear.totalKeyResult * 100}%`}
                          </Typography>
                        </StyledRating>
                        <StyledRating backgroundColor="#fff9db">
                          <StarRateIcon
                            fontSize="large"
                            sx={{ "&.MuiSvgIcon-root": { color: "#ffdd40" }}}
                          />
                          <Typography fontSize="20px" fontWeight={500}>
                            {ratingInYear.totalRating
                              ? parseFloat((ratingInYear.totalRating / ratingInYear.totalNumberOfRating).toFixed(1))
                              : 0
                            }
                          </Typography>
                        </StyledRating>
                      </Stack>
                    </Stack>
                  )}
                </Grid>
                <Grid item xs={12} sm={8}>
                  {individualObjective
                    .filter((item) => item.year === selectYear)
                    .map((objective, index) => (
                      <Box
                        key={index}
                        className="objectcard-wrap"
                        style={{ marginBottom: "10px" }}
                      >
                        <CardObjective data={objective} />
                      </Box>
                    ))}
                </Grid>
              </Grid>
            </div>
          ) : (
            individualObjective && (
              <Box display="flex" justifyContent="center" paddingTop="24px">
                <Typography>{`${t("NoItems")}`}</Typography>
              </Box>
            )
          )}
        </ContainerStyled>
      </Container>
    </StyledRoot>
  );
}

export default OKRStatus;
