import { httpClient } from "./httpClient";

const getEstimatePerformance = (query) => {
  return httpClient.get(`/company/estimate-performance`, { params: query });
};

const updateEstimatePerformance = (formData) => {
  return httpClient.put("/company/estimate-performance", formData);
};

const getEstimatePerformanceQuestion = () => {
  return httpClient.get(`/company/estimate-performance-question`);
};

const updateEstimatePerformanceQuestion = (formdata) => {
  return httpClient.put(`/company/estimate-performance-question`, formdata);
};

const updateEstimatePerformanceManager = (formdata) => {
  return httpClient.put(`/company/estimate-performance-manager`, formdata);
};

const getEstimatePerformanceResult = () => {
  return httpClient.get(`/company/estimate-performance-result`);
};

const getEstimateLevel = () => {
  return httpClient.get(`/estimate-level`);
};

const addEmployeePerformance = (formData) => {
  return httpClient.post(`/employee-performance`, formData);
};

const updateEmployeePerformance = (idEmployeePerformance, formData) => {
  return httpClient.put(`/employee-performance/${idEmployeePerformance}`, formData);
};

export default {
  getEstimatePerformance,
  updateEstimatePerformance,
  getEstimatePerformanceQuestion,
  updateEstimatePerformanceQuestion,
  updateEstimatePerformanceManager,
  getEstimatePerformanceResult,
  getEstimateLevel,
  addEmployeePerformance,
  updateEmployeePerformance
};
