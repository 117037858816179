import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/styles";
import ShowMiniData from "../../../shared/pages/okrs/components/ShowMiniData";
import EmployeeInfo from "../../../shared/pages/okrs/components/EmployeeInfo";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";

import {
  Avatar,
  Grid,
  InputAdornment,
  LinearProgress,
  Typography,
  Container,
  Card,
  Box,
  Pagination,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

import { useSelector, useDispatch } from "react-redux";
import { getAllOrgPlan } from "../../../../../actions/OrganizationPlans";
import { getAllEmployees } from "../../../../../actions/employee";
import { getUserProfile } from "../../../../../actions/user";

import SearchIcon from "@mui/icons-material/Search";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import VpnKeyRoundedIcon from "@mui/icons-material/VpnKeyRounded";
import FlagIcon from "@mui/icons-material/Flag";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiGrid-container": {
      marginBottom: "0 !important",
    },
    "& .MuiCardContent-root": {
      padding: 0,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 16,
    },
  },
  quater: {
    backgroundColor: "#e53935",
    fontWeight: "600",
    width: 48,
    height: 48,
  },
  PlanList_Object: {
    fontSize: "1.3rem",
    fontWeight: "600",
    color: "#222f3e",
  },
  PlanList_Impact: {
    color: "#9e9e9e",
    marginTop: 8,
  },
  PlanList_KR: {
    color: "#222f3e",
    fontSize: "1.1rem",
    fontWeight: "700",
  },
  PlanList_Status: {
    display: "flex",
    alignItems: "center",
    color: "#9e9e9e",
    marginBottom: 8,
  },
  noData_Section: {
    display: "flex",
    alignItems: "center",
  },
  noDataSvg: {
    fontSize: "30px",
    marginRight: 8,
  },
  inProgress: {
    backgroundColor: "#eeeeee",
    color: "#9e9e9e",
    minWidth: "100px",
    borderRadius: "15px",
    padding: "5px 10px 5px 10px",
    textAlign: "center",
    marginLeft: 8,
    "&:hover": {
      backgroundColor: "#eeeeee",
    },
  },
  achieved: {
    backgroundColor: "#e0f2f1",
    color: "#009688",
    width: "90px",
    borderRadius: "15px",
    padding: "5px",
    textAlign: "center",
    marginLeft: 8,
    "&:hover": {
      backgroundColor: "#e0f2f1",
    },
  },
  notAchieved: {
    backgroundColor: "#f9dde0",
    color: "#c62828",
    width: "130px",
    borderRadius: "15px",
    padding: "5px",
    textAlign: "center",
    marginLeft: 8,
    "&:hover": {
      backgroundColor: "#f9dde0",
    },
  },
  createBtn: {
    border: "1px solid #e0e0e0",
    borderRadius: "50px",
  },
  cardList_achieved: {
    cursor: "pointer",
    backgroundColor: "#fbe9e7",
    color: "#ff5722",
    width: 40,
    height: 40,
    marginRight: 8,
  },
  cardList_KR: {
    backgroundColor: "#ffebee",
    color: "#f44336",
  },
}));

const ContainerStyled = styled(Container)({
  marginTop: "10px",
});

const StyledRoot = styled(Box)({
  "& .objectcard-wrap": {
    marginBottom: "16px",
    "&:last-child": {
      marginBottom: 0,
    },
  },
  "& .objective-card": {
    padding: "16px",
  },
  "& .MuiFilledInput-root input": {
    padding: "16px 12px 16px 12px",
  },
  "& .MuiFilledInput-root input": {
    padding: "16px 12px 16px 12px",
  },
});

const StyledCard = styled(Card)({
  minHeight: "70px",
  boxShadow: "none",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  background:
    "linear-gradient(to right, rgba(235, 148, 192, 0.5), rgba(188, 144, 213, 0.5))",
  "& .cardTitle": {
    fontSize: 22,
  },
  "& .MuiCardContent-root": {
    padding: 24,
  },
  "& .cardHeader": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
  "& .searchContainer": {
    marginLeft: "41px",
    marginBottom: "100px",
  },
  "@media (max-width: 600px)": {
    "& .searchContainer": {
      marginBottom: "10px",
    },
  },
});

const StyleHeadBox = styled(Box)({
  "& .box-header": {
    minHeight: "200px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    backgroundColor:
      "linear-gradient(to right, rgba(235, 148, 192, 0.5), rgba(188, 144, 213, 0.5))",
    "& .text-header": {
      fontSize: "48px",
      fontWeight: "bold",
      marginLeft: "40px",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      marginTop: "80px",
    },
    "& .img-header": {
      width: "33%",
      height: "auto",
      marginBottom: "10px",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      marginRight: "20px",
    },
  },

  "@media (max-width: 600px)": {
    "& .box-header": {
      flexDirection: "column",
      minHeight: "unset",
      justifyContent: "center",
      alignItems: "center",
      "& .text-header": {
        fontSize: "35px",
        marginTop: "20px",
      },
      "& .img-header": {
        width: "50%",
        justifyContent: "flex-start",
        alignItems: "flex-start",
      },
    },
  },
});

function OrgEvaluationList() {
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: orgPlanStore } = useSelector((state) => state.OrgPlans);
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const [planList, setPlanList] = useState([]);
  const [planItems, setPlanItems] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [employeeId, setEmployeeId] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(2);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = planList.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const fetchData = () => {
    dispatch(getAllOrgPlan());
    dispatch(getAllEmployees());
  };

  useEffect(() => {
    if (orgPlanStore) {
      const records = orgPlanStore.filter(function (item) {
        return (
          item.planStatus.includes("Approved") &&
          item.achieveResult !== "Achieved"
        );
      });

      setPlanList(records);
      setPlanItems(records);
      setIsLoading(false);
    }
  }, [orgPlanStore]);

  useEffect(() => {
    if (userProfile) {
      setEmployeeId(userProfile.idEmployees);
    }
  }, [userProfile]);

  useEffect(() => {
    if (currentUser) {
      dispatch(getUserProfile(currentUser.username));
    }

    fetchData();
  }, []);

  useEffect(() => {
    let x = [...planItems];
    x = x.filter((y) => {
      return y.objectiveName
        .toLowerCase()
        .includes(searchKey.toLocaleLowerCase());
    });
    setPlanList(x);
  }, [searchKey]);

  return (
    <StyledRoot className="page">
      <Box padding="24px 0">
        <Container maxWidth="lg">
          <StyledCard
            style={{
              marginBottom: "48px",
              marginTop: "20px",
            }}
          >
            <StyleHeadBox>
              <Box className="box-header">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className="text-header">
                      Organization Evaluation
                    </Typography>
                  </Grid>

                  <Grid item xs={12} className="searchContainer">
                    <TextFieldTheme
                      variant="filled"
                      style={{
                        maxWidth: "250px",
                        background: "#fff",
                        borderRadius: "10px",
                      }}
                      value={searchKey}
                      onChange={(e) => setSearchKey(e.target.value)}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="center">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        placeholder: `${t("Search")}`,
                      }}
                    />
                  </Grid>
                </Grid>
                <img
                  className="img-header"
                  src={`${process.env.PUBLIC_URL}/assets/okrs/admin/organizational-goal-evaluation.png`}
                  alt="icon"
                />
              </Box>
            </StyleHeadBox>
          </StyledCard>

          <ContainerStyled>
            <div className={classes.root}>
              {isLoading ? (
                <LinearProgress />
              ) : (
                <>
                  {planList.length > 0 ? (
                    currentPosts.map((item) => {
                      return (
                        <div key={item.planOrgId}>
                          <Typography variant="h6" className="keyResult">
                            <FlagIcon fontSize="large" className="svgKey" />
                            Objective
                          </Typography>
                          <div
                            className="cardPlan_Objective"
                            style={{ backgroundColor: "white" }}
                          >
                            <Grid
                              container
                              alignItems="flex-start"
                              alignContent="space-between"
                            >
                              <Grid item xs={3} sm={2} md={1} lg={1} xl={1}>
                                <Avatar
                                  className={classes.quater}
                                  style={{ backgroundColor: "#EB94C0" }}
                                >
                                  {item.quater}
                                </Avatar>
                              </Grid>
                              <Grid item xs={7} sm={7} md={8} lg={8} xl={8}>
                                <Typography
                                  className={classes.PlanList_Object}
                                  gutterBottom
                                >
                                  {item.objectiveName}
                                </Typography>
                              </Grid>
                              <Grid
                                container
                                item
                                xs={2}
                                sm={3}
                                md={3}
                                lg={3}
                                xl={3}
                                justifyContent="flex-end"
                              >
                                <Avatar
                                  className={classes.cardList_achieved}
                                  onClick={() => {
                                    history.push({
                                      pathname: `/organizationevaluation/${item.objectiveName}`,
                                      state: { planList: item },
                                    });
                                  }}
                                >
                                  <LibraryBooksIcon />
                                </Avatar>
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid
                                item
                                xs={2}
                                sm={2}
                                md={1}
                                lg={1}
                                xl={1}
                              ></Grid>
                              <Grid item xs={10} sm={10} md={8} lg={8} xl={8}>
                                <Typography className={classes.PlanList_Status}>
                                  {item.impactName}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid container>
                              <Grid
                                item
                                xs={2}
                                sm={2}
                                md={1}
                                lg={1}
                                xl={1}
                              ></Grid>
                              <Grid item xs={10} sm={10} md={8} lg={8} xl={8}>
                                <ShowMiniData
                                  date={item.dateCreate}
                                  status={item.planStatus}
                                  progress={item.achieveResult}
                                />
                              </Grid>
                            </Grid>
                          </div>
                          <Typography variant="h6" className="keyResult">
                            <VpnKeyRoundedIcon
                              fontSize="large"
                              className="svgKey"
                            />
                            {t("KeyResult")}
                          </Typography>
                          {item.keyResult1 && (
                            <div className="cardPlan">
                              <Grid container alignItems="center">
                                <Grid item xs={2} md={1} lg={1} xl={1} sm={2}>
                                  <Avatar className={classes.cardList_KR}>
                                    <KeyboardArrowRightIcon />
                                  </Avatar>
                                </Grid>
                                <Grid
                                  item
                                  xs={10}
                                  md={8}
                                  lg={11}
                                  xl={11}
                                  sm={10}
                                >
                                  <Typography className={classes.PlanList_KR}>
                                    {item.keyResult1}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid container>
                                <Grid
                                  item
                                  xs={2}
                                  sm={2}
                                  md={1}
                                  lg={1}
                                  xl={1}
                                ></Grid>
                                <Grid item xs={10} sm={10} md={8} lg={8} xl={8}>
                                  <ShowMiniData
                                    date={item.dateCreate}
                                    status={item.kR1Status}
                                    progress={item.achieveResultKR1}
                                  />
                                </Grid>
                              </Grid>
                              {item.approvedBy ? (
                                <Grid container>
                                  <Grid item xs={2} md={1} lg={1}></Grid>
                                  <Grid item xs={10} md={8} lg={8}>
                                    <Typography
                                      className={classes.PlanList_Status}
                                    >
                                      Approved By : {item.approvedBy}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              ) : null}
                            </div>
                          )}
                          {item.keyResult2 && (
                            <div className="cardPlan">
                              <Grid container alignItems="center">
                                <Grid item xs={2} md={1} lg={1} xl={1} sm={2}>
                                  <Avatar className={classes.cardList_KR}>
                                    <KeyboardArrowRightIcon />
                                  </Avatar>
                                </Grid>
                                <Grid
                                  item
                                  xs={10}
                                  md={8}
                                  lg={11}
                                  xl={11}
                                  sm={10}
                                >
                                  <Typography className={classes.PlanList_KR}>
                                    {item.keyResult2}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid container>
                                <Grid
                                  item
                                  xs={2}
                                  sm={2}
                                  md={1}
                                  lg={1}
                                  xl={1}
                                ></Grid>
                                <Grid item xs={10} sm={10} md={8} lg={8} xl={8}>
                                  <ShowMiniData
                                    date={item.dateCreate}
                                    status={item.kR2Status}
                                    progress={item.achieveResultKR2}
                                  />
                                </Grid>
                              </Grid>
                              {item.approvedBy ? (
                                <Grid container>
                                  <Grid item xs={2} md={1} lg={1}></Grid>
                                  <Grid item xs={10} md={8} lg={8}>
                                    <Typography
                                      className={classes.PlanList_Status}
                                    >
                                      Approved By : {item.approvedBy}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              ) : null}
                            </div>
                          )}
                          {item.keyResult3 && (
                            <div
                              className="cardPlan"
                              style={{ marginBottom: "30px" }}
                            >
                              <Grid container alignItems="center">
                                <Grid item xs={2} md={1} lg={1} xl={1} sm={2}>
                                  <Avatar className={classes.cardList_KR}>
                                    <KeyboardArrowRightIcon />
                                  </Avatar>
                                </Grid>
                                <Grid
                                  item
                                  xs={10}
                                  md={8}
                                  lg={11}
                                  xl={11}
                                  sm={10}
                                >
                                  <Typography className={classes.PlanList_KR}>
                                    {item.keyResult3}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid container>
                                <Grid
                                  item
                                  xs={2}
                                  sm={2}
                                  md={1}
                                  lg={1}
                                  xl={1}
                                ></Grid>
                                <Grid item xs={10} sm={10} md={8} lg={8} xl={8}>
                                  <ShowMiniData
                                    date={item.dateCreate}
                                    status={item.kR3Status}
                                    progress={item.achieveResultKR3}
                                  />
                                </Grid>
                              </Grid>
                              {item.approvedBy ? (
                                <Grid container>
                                  <Grid item xs={2} md={1} lg={1}></Grid>
                                  <Grid item xs={10} md={8} lg={8}>
                                    <Typography
                                      className={classes.PlanList_Status}
                                    >
                                      Approved By : {item.approvedBy}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              ) : null}
                            </div>
                          )}
                        </div>
                      );
                    })
                  ) : (
                    <div className="cardPlan">
                      <Grid container justifyContent="center">
                        <Grid item>
                          <Typography
                            variant="h6"
                            className={classes.noData_Section}
                          >
                            <FindInPageIcon className={classes.noDataSvg} />
                            No Data
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  )}
                </>
              )}
              {planList.length > 0 ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Pagination
                    paginate={paginate}
                    postsPerPage={postsPerPage}
                    totalPosts={planList.length}
                  />
                </div>
              ) : null}
            </div>
          </ContainerStyled>
        </Container>
      </Box>
    </StyledRoot>
  );
}

export default OrgEvaluationList;
