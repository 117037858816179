import React, { useEffect, useState } from "react";
import DrawerCustom from "../../../../shared/general/Drawer";
import { Box, Grid, MenuItem, Typography, styled } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { getEmployeeProfile } from "../../../../../../actions/employee";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  addEmployeePerformance,
  updateEmployeePerformance,
} from "../../../../../../actions/estimatePerformance";

//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
  "& .field-label": {
    paddingBottom: "4px",
    fontWeight: "500",
    fontSize: "14px",
  },
});

const DialogPerformance = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { drawerConfig, onClose } = props;

  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: estimateLevelList } = useSelector(
    (state) => state.estimateLevel
  );

  const [alertConfig, setAlertConfig] = useState({
    isOpen: false,
    type: "success",
    message: "",
  });

  const validateYupSchema = yup.object({
    idEstimateLevel: yup.string().required(t("PleaseSelectInformation")),
    yearPerformance: yup.string().required(t("PleaseSelectInformation")),
  });

  const useHookForm = useForm({
    defaultValues: {
      idEstimateLevel: "",
      yearPerformance: dayjs().get('year'),
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all",
  });

  useEffect(() => {
    if (drawerConfig.isOpen === true) {
      if (drawerConfig.isEdit) {
        useHookForm.reset({
          idEstimateLevel: drawerConfig.data.idEstimateLevel || "",
        });
      } else {
        useHookForm.reset({
          idEstimateLevel: "",
        });
      }
    }
  }, [drawerConfig.isOpen]);

  const onSubmit = async (data) => {
    // console.log(data)
    let result = null;

    const formData = {
      ...data,
    };

    const findExistPerformance = drawerConfig.data.find(item => item.yearPerformance === Number(data.yearPerformance))

    if (findExistPerformance) {
      formData.idEmployees = employeeProfile.idEmployees;
      result = await dispatch(
        updateEmployeePerformance(
          findExistPerformance.idEmployeePerformance,
          formData
        )
      );
    } else {
      formData.idEmployees = employeeProfile.idEmployees;
      result = await dispatch(addEmployeePerformance(formData));
    }

    if (result && result.status === 200) {
      dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
      onClose();
    }
  };

  return (
    <DrawerCustom
      title={`${t("EditPerformance")}`}
      anchor="right"
      open={drawerConfig.isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <StyledRoot>
        <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography className="field-label">
                {t("PerformanceYear")}{" "}
                {dayjs().format("YYYY")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="idEstimateLevel"
                control={useHookForm.control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    label="Estimate Level"
                    select
                    helperText={
                      useHookForm.formState.errors[field.name]
                        ? useHookForm.formState.errors[field.name].message
                        : null
                    }
                    error={
                      useHookForm.formState.errors[field.name] ? true : false
                    }
                  >
                    {estimateLevelList &&
                      estimateLevelList.map((item) => (
                        <MenuItem value={item.idEstimateLevel}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </TextFieldTheme>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="yearPerformance"
                control={useHookForm.control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    label="Year"
                    select
                    helperText={
                      useHookForm.formState.errors[field.name]
                        ? useHookForm.formState.errors[field.name].message
                        : null
                    }
                    error={
                      useHookForm.formState.errors[field.name] ? true : false
                    }
                  >
                    {[...Array(6).keys()].map((item, index) => {
                      const currentYear = dayjs().get('year') - index;
                      const currentMonth = dayjs().get('month');

                      if (currentMonth < 9 && currentYear === dayjs().get('year')) {
                        // Exclude the current year if the current month is before October
                        return null;
                      } else {
                        return (
                          <MenuItem key={currentYear} value={currentYear}>
                            {currentYear}
                          </MenuItem>
                        );
                      }
                    })}
                  </TextFieldTheme>
                )}
              />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="space-between">
              <ButtonBlue
                variant="text"
                onClick={onClose}
                disabled={useHookForm.formState.isSubmitting}
              >
                {t("Cancel")}
              </ButtonBlue>
              <ButtonBlue
                variant="contained"
                type="submit"
                disabled={useHookForm.formState.isSubmitting}
              >
                {t("Save")}
              </ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogPerformance;
