import dayjs from 'dayjs';
import ExcelJS from 'exceljs';
export const ExportExcel = async (t,data = []) => {

  const headerCellStyle = { 
    font: { 
      name: "Tahoma", 
      size: 14,
      color: {argb: "FFFFFF"}
    }, 
    alignment: { 
      vertical: "middle", 
      horizontal: "center",
      wrapText: true,
    },
    fill: {
      type: 'pattern',
      pattern: 'solid',
      fgColor: {argb: "002060"}
    },
    // numFmt: "@",
  }

  const dataCellStyle = { 
    font: { 
      name: "Tahoma", 
      size: 14,
    }, 
    alignment: { 
      vertical: "middle", 
      horizontal: "center" 
    },
    // numFmt: "@",
  }

  // let header = [
  //   { 
  //     v: "รหัสพนักงาน", 
  //     t: "s", 
  //     s: headerCellStyle,
  //   },
  //   { 
  //     v: "ชื่อ", 
  //     t: "s", 
  //     s: headerCellStyle
  //   },
  //   { 
  //     v: "นามสกุล", 
  //     t: "s", 
  //     s: headerCellStyle
  //   },
  //   { 
  //     v: "ฝ่าย", 
  //     t: "s", 
  //     s: headerCellStyle
  //   },
  //   { 
  //     v: "ส่วน", 
  //     t: "s", 
  //     s: headerCellStyle
  //   },
  //   { 
  //     v: "แผนก", 
  //     t: "s", 
  //     s: headerCellStyle
  //   },
  //   { 
  //     v: "ตำแหน่งงาน", 
  //     t: "s", 
  //     s: headerCellStyle
  //   },
  //   { 
  //     v: "ประเภทการจ้าง", 
  //     t: "s", 
  //     s: headerCellStyle
  //   },
  //   { 
  //     v: "วันที่เข้างาน", 
  //     t: "s", 
  //     s: headerCellStyle
  //   },
  // ];

  const workBook = new ExcelJS.Workbook();
  const worksheet = workBook.addWorksheet(t("NewEmployee"));

  const headerRow = worksheet.addRow([
    t("EmployeeID"), t("FirstName"), t("LastName"), t("Division"), t("Department"), t("Section"), t("Position"), t("EmploymentType"), t("DateEntry")
  ]);

  headerRow.eachCell((cell) => {
    cell.style = headerCellStyle;
    cell.numFmt = "@";
  });

  headerRow.height = 50;

  const colWidths = [
    { key: "employeeID", width: 30 },
    { key: "firstname_TH", width: 50 },
    { key: "lastname_TH", width: 50 },
    { key: "divisionName", width: 50 },
    { key: "departmentName", width: 50 },
    { key: "sectionName", width: 50 },
    { key: "positionName", width: 50 },
    { key: "employmentTypeName", width: 50 },
    { key: "hiringDate", width: 30 },
  ];

  colWidths.forEach((col, index) => {
    worksheet.getColumn(index + 1).width = col.width;
  });

  data.map(item => {
    // const row = [
    //   {v: item.employeeID || "", t: "s", s: dataCellStyle},
    //   {v: item.firstname_TH || "", t: "s", s: dataCellStyle},
    //   {v: item.lastname_TH || "", t: "s", s: dataCellStyle},
    //   {v: item.divisionName || "", t: "s", s: dataCellStyle},
    //   {v: item.departmentName || "", t: "s", s: dataCellStyle},
    //   {v: item.sectionName || "", t: "s", s: dataCellStyle},
    //   {v: item.positionName || "", t: "s", s: dataCellStyle},
    //   {v: item.employmentTypeName || "", t: "s", s: dataCellStyle},
    //   {v: item.hiringDate? dayjs(item.hiringDate).format("DD/MM/YYYY") :"", t: "s", s: dataCellStyle},
    // ]
    const row = [
      item.employeeID || "",
      item.firstname_TH || "",
      item.lastname_TH || "",
      item.divisionName || "",
      item.departmentName || "",
      item.sectionName || "",
      item.positionName || "",
      item.employmentTypeName || "",
      item.hiringDate? dayjs(item.hiringDate).format("DD/MM/YYYY") :"",
    ]

    const excelRow = worksheet.addRow(row);
    excelRow.eachCell((cell) => {
      cell.style = dataCellStyle;
      cell.numFmt = "@";
    });
  })

  workBook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${t("SummaryOfNewEmployees")}.xlsx`;
    a.click();
    URL.revokeObjectURL(url);
  });
}