import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/styles";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import DescriptionIcon from "@mui/icons-material/Description";

import { useSelector, useDispatch } from "react-redux";
import { getUserProfile } from "../../../../../actions/user";
import { putEvaluationOrgPlan } from "../../../../../actions/OrganizationPlans";

import {
  FormControl,
  Grid,
  InputLabel,
  Typography,
  Select,
  MenuItem,
  TextField,
  Button,
  InputAdornment,
  Container,
  Card,
  Box,
} from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const achieveOptions = [
  { id: "Achieved", title: "Achieved" },
  { id: "Not Achieved", title: "Not Achieved" },
];

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiSelect-root": {
      width: "150px",
    },
  },
  headerCard: {
    background: "#fafbff",
    padding: 32,
    borderBottom: "1px solid #eeeeee",
  },
  cardPlan: {
    borderRadius: "0px 0px  24px 24px",
    padding: 32,
    background: "#fff",
  },
  mainData: {
    fontSize: 19,
  },
  data: {
    fontSize: 19,
    fontWeight: "bold",
  },
}));

const ContainerStyled = styled(Container)({
  marginTop: "10px",
});

const StyledRoot = styled(Box)({
  "& .objectcard-wrap": {
    marginBottom: "16px",
    "&:last-child": {
      marginBottom: 0,
    },
  },
  "& .objective-card": {
    padding: "16px",
  },
  "& .MuiFilledInput-root input": {
    padding: "16px 12px 16px 12px",
  },
  "& .MuiFilledInput-root input": {
    padding: "16px 12px 16px 12px",
  },
});

const StyledCard = styled(Card)({
  minHeight: "70px",
  boxShadow: "none",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  background:
    "linear-gradient(to right, rgba(235, 148, 192, 0.5), rgba(188, 144, 213, 0.5))",
  "& .cardTitle": {
    fontSize: 22,
  },
  "& .MuiCardContent-root": {
    padding: 24,
  },
  "& .cardHeader": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
  "& .searchContainer": {
    marginLeft: "41px",
    marginBottom: "100px",
  },
  "@media (max-width: 600px)": {
    "& .searchContainer": {
      marginBottom: "10px",
    },
  },
});

const StyleHeadBox = styled(Box)({
  "& .box-header": {
    minHeight: "200px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    backgroundColor:
      "linear-gradient(to right, rgba(235, 148, 192, 0.5), rgba(188, 144, 213, 0.5))",
    "& .text-header": {
      fontSize: "48px",
      fontWeight: "bold",
      marginLeft: "40px",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      marginTop: "50px",
    },
    "& .img-header": {
      width: "33%",
      height: "auto",
      marginBottom: "10px",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      marginRight: "20px",
    },
  },

  "@media (max-width: 600px)": {
    "& .box-header": {
      flexDirection: "column",
      minHeight: "unset",
      justifyContent: "center",
      alignItems: "center",
      "& .text-header": {
        fontSize: "35px",
        marginTop: "20px",
      },
      "& .img-header": {
        width: "50%",
        justifyContent: "flex-start",
        alignItems: "flex-start",
      },
    },
  },
});

const ResponsiveButtonBlue = styled(Button)(({ theme }) => ({
  backgroundColor: "rgba(219, 65, 120, 1)",
  width: "20%",
  height: "50px",
  fontSize: "15px",
  fontWeight: "bold",
  borderRadius: "7px",
  "&:hover": {
    backgroundColor: "#C53B6C",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    fontSize: "14px",
    height: "40px",
  },
}));

const StyleTypography = styled(Typography)({
  borderLeft: "4px solid transparent",
  borderImage:
    "linear-gradient(0deg, rgba(136,213,254,1) 0%, rgba(254,184,207,1) 99%, rgba(254,184,207,1) 100%) 5",
  padding: 5,
});

function OrgEvaluation() {
  const {t,i18n} = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const [values, setValues] = useState([]);
  const [hasError, setHasError] = useState(false);
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const { handleSubmit } = useForm();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const onPutEvaluationOrgPlan = async (id, formData) => {
    await dispatch(putEvaluationOrgPlan(id, formData));
    history.push("/orgvaluationlist");
  };

  const onSubmit = () => {
    if (values.achieveResult == null) {
      setHasError(true);
    } else {
      const formData = {
        achieveResult: values.achieveResult,
        achieveDescription: values.achieveDescription,
        achieveResultKR1: values.achieveResultKR1,
        achieveKR1Desc: values.achieveKR1Desc,
        achieveResultKR2: values.achieveResultKR2,
        achieveKR2Desc: values.achieveKR2Desc,
        achieveResultKR3: values.achieveResultKR3,
        achieveKR3Desc: values.achieveKR3Desc,
        achieveByEmpId: userProfile.idEmployees,
        dateAchieved: dayjs(new Date()).format("YYYY-MM-DD"),
        planOrgId: values.planOrgId,
      };

      onPutEvaluationOrgPlan(formData.planOrgId, formData);
    }
  };

  useEffect(() => {
    if (currentUser) {
      dispatch(getUserProfile(currentUser.username));
    }
    setValues(location.state.planList);
  }, []);

  return (
    <StyledRoot className="page">
      <Box padding="24px 0">
        <Container maxWidth="lg">
          <StyledCard
            style={{
              marginBottom: "48px",
              marginTop: "20px",
            }}
          >
            <StyleHeadBox>
              <Box className="box-header">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className="text-header">
                      Organization Evaluation
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container justifyContent="center">
                      <Grid item xs={10} sm={3} md={2} lg={2} xl={2}>
                        <Typography className={classes.data}>
                          Objective
                        </Typography>
                      </Grid>
                      <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
                        <Box bgcolor="white" borderRadius={2} p={1} mb={1}>
                          <Typography className={classes.mainData}>
                            {values.objectiveName}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container justifyContent="center">
                      <Grid item xs={10} sm={3} md={2} lg={2} xl={2}>
                        <Typography className={classes.data}>Impact</Typography>
                      </Grid>
                      <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
                        <Box bgcolor="white" borderRadius={2} p={1} mb={1}>
                          <Typography className={classes.mainData}>
                            {values.impactName}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <img
                  className="img-header"
                  src={`${process.env.PUBLIC_URL}/assets/okrs/admin/organizational-goal-evaluation.png`}
                  alt="icon"
                />
              </Box>
            </StyleHeadBox>
          </StyledCard>
          <ContainerStyled>
            <div className={classes.root}>
              <div className="card-section">
                <div
                  className={classes.cardPlan}
                  style={{ borderRadius: "10px 10px 0 0" }}
                >
                  <form
                    onSubmit={handleSubmit(onSubmit)}
                    novalidate
                    autoComplete="off"
                  >
                    <Grid container justifyContent="center">
                      <Grid item xl={8} lg={8} md={9} sm={10} xs={10}>
                        <Grid container>
                          <Grid
                            item
                            xl={3}
                            lg={3}
                            md={4}
                            sm={12}
                            xs={12}
                            style={{ marginBottom: 20 }}
                          >
                            <Typography className={classes.data}>
                              Achieve
                            </Typography>
                            <Typography style={{ fontSize: 15, color: "gray" }}>
                              description of the Achievement
                            </Typography>
                          </Grid>
                          <Grid item xl={9} lg={9} md={8} sm={12} xs={12}>
                            <FormControl
                              sx={{ marginBottom: 2 }}
                              fullWidth
                              error={hasError}
                            >
                              <InputLabel>Select Achieve</InputLabel>
                              <Select
                                name="achieveResult"
                                label="Select Achieve"
                                value={values.achieveResult}
                                onChange={handleInputChange}
                              >
                                {achieveOptions.map((item) => (
                                  <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <TextField
                              name="achieveDescription"
                              value={values.achieveDescription}
                              onChange={handleInputChange}
                              fullWidth
                              sx={{ marginBottom: 2 }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <DescriptionIcon />
                                  </InputAdornment>
                                ),
                              }}
                              label="Description"
                              placeholder="Description"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* Kr1 */}
                    <hr className="hrTag" />
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      sx={{ marginBottom: 2 }}
                    >
                      <Grid item xs={10} sm={3} md={2} lg={2} xl={2}>
                        <StyleTypography className={classes.data}>
                          {t("KeyResult")} 1
                        </StyleTypography>
                      </Grid>
                      <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
                        <Typography
                          className={classes.mainData}
                          style={{ fontSize: "16px" }}
                        >
                          {values.keyResult1}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container justifyContent="center">
                      <Grid item xl={8} lg={8} md={9} sm={10} xs={10}>
                        <Grid container>
                          <Grid
                            item
                            xl={3}
                            lg={3}
                            md={4}
                            sm={12}
                            xs={12}
                            style={{ marginBottom: 20 }}
                          >
                            <Typography className={classes.data}>
                              Achieve
                            </Typography>
                            <Typography style={{ fontSize: 15, color: "gray" }}>
                              description of the Achievement
                            </Typography>
                          </Grid>
                          <Grid item xl={9} lg={9} md={8} sm={12} xs={12}>
                            <FormControl
                              sx={{ marginBottom: 2 }}
                              fullWidth
                              error={hasError}
                            >
                              <InputLabel>Select Achieve</InputLabel>
                              <Select
                                name="achieveResultKR1"
                                label="Select Achieve"
                                value={values.achieveResultKR1}
                                onChange={handleInputChange}
                              >
                                {achieveOptions.map((item) => (
                                  <MenuItem key={item.id} value={item.id}>
                                    {item.title}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <TextField
                              name="achieveKR1Desc"
                              value={values.achieveKR1Desc}
                              onChange={handleInputChange}
                              fullWidth
                              sx={{ marginBottom: 2 }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <DescriptionIcon />
                                  </InputAdornment>
                                ),
                              }}
                              label="Description"
                              placeholder="Description"
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* Kr2 */}
                    {values.keyResult2 ? (
                      <>
                        <hr className="hrTag" />
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                          sx={{ marginBottom: 2 }}
                        >
                          <Grid item xs={10} sm={3} md={2} lg={2} xl={2}>
                            <StyleTypography className={classes.data}>
                              {t("KeyResult")} 2
                            </StyleTypography>
                          </Grid>
                          <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
                            <Typography
                              className={classes.mainData}
                              style={{ fontSize: "16px" }}
                            >
                              {values.keyResult2}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container justifyContent="center">
                          <Grid item xl={8} lg={8} md={9} sm={10} xs={10}>
                            <Grid container>
                              <Grid
                                item
                                xl={3}
                                lg={3}
                                md={4}
                                sm={12}
                                xs={12}
                                style={{ marginBottom: 20 }}
                              >
                                <Typography className={classes.data}>
                                  Achieve
                                </Typography>
                                <Typography
                                  style={{ fontSize: 15, color: "gray" }}
                                >
                                  description of the Achievement
                                </Typography>
                              </Grid>
                              <Grid item xl={9} lg={9} md={8} sm={12} xs={12}>
                                <FormControl
                                  sx={{ marginBottom: 2 }}
                                  fullWidth
                                  error={hasError}
                                >
                                  <InputLabel>Select Achieve</InputLabel>
                                  <Select
                                    name="achieveResultKR2"
                                    label="Select Achieve"
                                    value={values.achieveResultKR2}
                                    onChange={handleInputChange}
                                  >
                                    {achieveOptions.map((item) => (
                                      <MenuItem key={item.id} value={item.id}>
                                        {item.title}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                                <TextField
                                  name="achieveKR2Desc"
                                  value={values.achieveKR2Desc}
                                  onChange={handleInputChange}
                                  fullWidth
                                  sx={{ marginBottom: 2 }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <DescriptionIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                  label="Description"
                                  placeholder="Description"
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    ) : null}

                    {/* Kr3 */}
                    {values.keyResult3 ? (
                      <>
                        <hr className="hrTag" />
                        <Grid
                          container
                          justifyContent="center"
                          alignItems="center"
                          sx={{ marginBottom: 2 }}
                        >
                          <Grid item xs={10} sm={3} md={2} lg={2} xl={2}>
                            <StyleTypography className={classes.data}>
                              {t("KeyResult")} 3
                            </StyleTypography>
                          </Grid>
                          <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
                            <Typography
                              className={classes.mainData}
                              style={{ fontSize: "16px" }}
                            >
                              {values.keyResult3}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container justifyContent="center">
                          <Grid item xl={8} lg={8} md={9} sm={10} xs={10}>
                            <Grid container>
                              <Grid
                                item
                                xl={3}
                                lg={3}
                                md={4}
                                sm={12}
                                xs={12}
                                style={{ marginBottom: 20 }}
                              >
                                <Typography className={classes.data}>
                                  Achieve
                                </Typography>
                                <Typography
                                  style={{ fontSize: 15, color: "gray" }}
                                >
                                  description of the Achievement
                                </Typography>
                              </Grid>
                              <Grid item xl={9} lg={9} md={8} sm={12} xs={12}>
                                <FormControl
                                  sx={{ marginBottom: 2 }}
                                  fullWidth
                                  error={hasError}
                                >
                                  <InputLabel>Select Achieve</InputLabel>
                                  <Select
                                    name="achieveResultKR3"
                                    label="Select Achieve"
                                    value={values.achieveResultKR3}
                                    onChange={handleInputChange}
                                  >
                                    {achieveOptions.map((item) => (
                                      <MenuItem key={item.id} value={item.id}>
                                        {item.title}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                                <TextField
                                  name="achieveKR3Desc"
                                  value={values.achieveKR3Desc}
                                  onChange={handleInputChange}
                                  fullWidth
                                  sx={{ marginBottom: 2 }}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <DescriptionIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                  label="Description"
                                  placeholder="Description"
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </>
                    ) : null}
                    <hr className="hrTag" />
                    <Grid container justifyContent="flex-end">
                      <Grid item>
                        <ResponsiveButtonBlue variant="contained" type="submit">
                          Save
                        </ResponsiveButtonBlue>
                      </Grid>
                    </Grid>
                  </form>
                </div>
              </div>
            </div>
          </ContainerStyled>
        </Container>
      </Box>
    </StyledRoot>
  );
}

export default OrgEvaluation;
