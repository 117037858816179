import React from "react";
import { Avatar, Box, Grid, Icon, styled, Typography } from "@mui/material";
import CardData from "../../shared/dashboard/CardData";
import Utils from "../../../../utils";
import CardDashboard from "../../shared/dashboard/CardDashboard";
import { useSelector } from "react-redux";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getUserFullName } from "../../../../utils/userData";

const StyledRoot = styled(Box)({
  "& .top-employee": {
    padding: "8px",
    // paddingTop: "16px",
    display: "flex",
    alignItems: "center",
    border: "1px solid #ececec",
    borderRadius: "4px",
  },
});

const WorkingtimePanel = (props) => {
  const { result: dashboardWorkingtime } = useSelector(
    (state) => state.dashboardWorkingtime
  );
  const { t, i18n } = useTranslation();

  return (
    <StyledRoot>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CardData
                    title={`${t("Employees")}`}
                    dataValue={Utils.numberWithCommasWithoutDot(
                      dashboardWorkingtime.employeeTotal
                    )}
                    dataUnit={`${t("People")}`}
                    dynamicFontSize
                    titleIcon={
                      <Icon
                        baseClassName="fal"
                        className="fa-users"
                        color="primary"
                        style={{ display: "inline-table" }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CardData
                    title={`${t("Absent")}`}
                    dataValue={Utils.numberWithCommasWithoutDot(
                      dashboardWorkingtime.workingTimeEmployeeInfo.absentTotal
                    )}
                    dataUnit={`${t("Unit.Time")}`}
                    dynamicFontSize
                    titleIcon={
                      <Icon
                        baseClassName="fal"
                        className="fa-calendar-xmark"
                        color="error"
                        style={{ display: "inline-table" }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CardData
                    title={`${t("Late")}`}
                    dataValue={Utils.numberWithCommasWithoutDot(
                      dashboardWorkingtime.workingTimeEmployeeInfo.lateTotal
                    )}
                    dataUnit={`${t("Unit.Time")}`}
                    dynamicFontSize
                    titleIcon={
                      <Icon
                        baseClassName="fal"
                        className="fa-alarm-clock"
                        color="primary"
                        style={{ display: "inline-table", color: "#ffcd38" }}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={4}>
              <CardData
                title={`${t("Leave")}`}
                dataValue={Utils.numberWithCommasWithoutDot(
                  dashboardWorkingtime.workingTimeEmployeeInfo.leaveTotal
                )}
                dataUnit={`${t("Unit.Time")}`}
                dynamicFontSize
                titleIcon={
                  <Icon
                    baseClassName="fal"
                    className="fa-memo"
                    color="primary"
                    style={{ display: "inline-table" }}
                  />
                }
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <CardDashboard>
                <Typography marginBottom="16px">{`${t(
                  "Top5Absent"
                )}`}</Typography>
                {dashboardWorkingtime.workingTimeEmployeeInfo
                  .top5AbsentEmployees.length > 0 && (
                  <Box
                    style={{
                      display: "flex",
                      gap: "16px",
                      flexDirection: "column",
                    }}
                  >
                    {dashboardWorkingtime.workingTimeEmployeeInfo.top5AbsentEmployees.map(
                      (e, index) => (
                        <Box key={index} className="top-employee">
                          <Icon
                            className={`fa-solid fa-square-${index + 1}`}
                            color="primary"
                            style={{
                              display: "inline-table",
                              color: index <= 2 ? "#ffcd38" : undefined,
                            }}
                          />
                          <Box
                            style={{
                              flexGrow: "1",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Avatar
                              src={e.imageURL? e.imageURL: null}
                              style={{
                                marginRight: "8px",
                                width: "32px",
                                height: "32px",
                              }}
                            />
                            <Typography>{getUserFullName(e)}</Typography>
                          </Box>
                          <Typography>
                            {e.totalAbsent} {`${t("Days")}`}
                          </Typography>
                        </Box>
                      )
                    )}
                  </Box>
                )}
                {dashboardWorkingtime.workingTimeEmployeeInfo
                  .top5AbsentEmployees.length <= 0 && (
                  <Typography textAlign="center" color="text.third">{`${t(
                    "NoAbsent"
                  )}`}</Typography>
                )}
              </CardDashboard>
            </Grid>

            <Grid item xs={12} lg={6}>
              <CardDashboard>
                <Typography marginBottom="16px">{`${t(
                  "Top5Late"
                )}`}</Typography>
                {dashboardWorkingtime.workingTimeEmployeeInfo.top5LateEmployees
                  .length > 0 && (
                  <Box
                    style={{
                      display: "flex",
                      gap: "16px",
                      flexDirection: "column",
                    }}
                  >
                    {dashboardWorkingtime.workingTimeEmployeeInfo.top5LateEmployees.map(
                      (e, index) => (
                        <Box key={index} className="top-employee">
                          <Icon
                            className={`fa-solid fa-square-${index + 1}`}
                            color="primary"
                            style={{
                              display: "inline-table",
                              color: index <= 2 ? "#ffcd38" : undefined,
                            }}
                          />
                          <Box
                            style={{
                              flexGrow: "1",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <Avatar
                              src={e.imageURL? e.imageURL: null}
                              style={{
                                marginRight: "8px",
                                width: "32px",
                                height: "32px",
                              }}
                            />
                            <Typography>{getUserFullName(e)}</Typography>
                          </Box>
                          <Typography>
                            {e.totalLate} {`${t("Unit.Time")}`}
                          </Typography>
                        </Box>
                      )
                    )}
                  </Box>
                )}
                {dashboardWorkingtime.workingTimeEmployeeInfo.top5LateEmployees
                  .length <= 0 && (
                  <Typography textAlign="center" color="text.third">{`${t(
                    "NoLate"
                  )}`}</Typography>
                )}
              </CardDashboard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </StyledRoot>
  );
};

export default WorkingtimePanel;
