import React, { useEffect, useState } from "react";
import { Box, Button, Divider, Paper, Stack, Typography, Avatar } from "@mui/material";
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import utils from "../../../../utils";
import dayjs from "dayjs";
import SuccessorDetail from "./listSuccessor/successorDetail";
import { getSuccessorRole } from "../../../../actions/successor";
import { getUserFullName } from "../../../../utils/userData";

export const PositionCard = (props) => {
    const history = useHistory();
    const { t, i18n } = useTranslation();
    const { itemName, data, empData } = props;
    const [successor, setSuccessor] = useState(null);
    const [showSuccessorComponent, setShowSuccessorComponent] = useState(false);

    const handlePaperClick = (id) => {
        if (id) {
            history.push(`/successor/detailPage/${id}`);
        }
    };

    const handleViewClick = () => {
        setShowSuccessorComponent((prev) => !prev);
    };

    const fetchedSuccessorData = async (idEmployeePosition) => {
        try {
            const response = await getSuccessorRole(idEmployeePosition);
            if (response && response.data) {
                setSuccessor(response.data);
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (empData && empData.idEmployeePosition) {
            fetchedSuccessorData(empData.idEmployeePosition);
        }
    }, [empData]);

    return (
        <div style={{ position: "relative", marginTop: "30px" }}>
            <Box sx={{
                position: "absolute",
                top: -25,
                left: 0,
                right: 0,
            }}
            >
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        minHeight: "50px",
                        fontSize: "20px",
                        fontWeight: 600,
                        color: "white",
                        backgroundColor: "#46cbe2",
                        marginRight: "30px",
                        marginLeft: "30px",
                    }}
                >
                    <Typography fontSize={20} color={"#fff"} fontWeight={600} sx={{ m: 1 }}>
                        {itemName}
                    </Typography>
                </Box>
            </Box>

            <Paper elevation={1}>
                <div onClick={() => handlePaperClick(empData && empData.idEmployeePosition)}>
                    <Box sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                        p: 2
                    }}
                    >
                        <Avatar variant="circle" sx={{
                            minHeight: 128,
                            minWidth: 128,
                            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                            outline: "3px solid white",
                            mt: 3
                        }} />
                        <Typography textAlign={"center"} fontSize={20} fontWeight={600} sx={{ mt: 2 }}>
                            {empData?getUserFullName(empData): "-"}
                        </Typography>
                    </Box>

                    <Divider />

                    <Stack sx={{ p: 5 }} spacing={1}>
                        <Stack direction={"row"} spacing={1} alignItems={"center"}>
                            <Typography fontSize={18} color={"grey"}>{t("ESY")} : </Typography>
                            <Typography fontSize={18} color={"#46cbe2"} fontWeight={600}>
                                {empData && empData.hiringDate ?
                                    `${utils.getAgeMonthYear(
                                        dayjs(data.mainPosition.employee.hiringDate).format("DD/MM/YYYY"),
                                        i18n.resolvedLanguage
                                    )}` : "-"}
                            </Typography>
                        </Stack>
                        <Stack direction={"row"} spacing={1} alignItems={"center"}>
                            <Typography fontSize={18} color={"grey"}>{t("TIG")} : </Typography>
                            <Typography fontSize={18} color={"#46cbe2"} fontWeight={600}>
                                {empData && empData.employeePersonnelLevelStart ?
                                    `${utils.getAgeMonthYear(
                                        dayjs(data.mainPosition.employee.employeePersonnelLevelStart).format("DD/MM/YYYY"),
                                        i18n.resolvedLanguage
                                    )}` : "-"}
                            </Typography>
                        </Stack>
                        <Stack direction={"row"} spacing={1} alignItems={"center"}>
                            <Typography fontSize={18} color={"grey"}>{t("TIP")} : </Typography>
                            <Typography fontSize={18} color={"#46cbe2"} fontWeight={600}>
                                {empData && empData.start ?
                                    `${utils.getAgeMonthYear(
                                        dayjs(data.mainPosition.employee.start).format("DD/MM/YYYY"),
                                        i18n.resolvedLanguage
                                    )}` : "-"}
                            </Typography>
                        </Stack>
                        <Stack direction={"row"} spacing={1} alignItems={"center"}>
                            <Typography fontSize={18} color={"grey"}>{t("Age")} : </Typography>
                            <Typography fontSize={18} color={"#46cbe2"} fontWeight={600}>
                                {empData && empData.birthday ?
                                    `${utils.getAgeMonthYear(
                                        dayjs(data.mainPosition.employee.birthday).format("DD/MM/YYYY"),
                                        i18n.resolvedLanguage
                                    )}` : "-"}
                            </Typography>
                        </Stack>
                    </Stack>

                    <Box
                        sx={{
                            p: 2,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Box sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            border: "1.5px solid #46cbe2",
                            borderRadius: 2,
                            width: 300
                        }}>
                            <Typography sx={{ p: 1 }} fontSize={18}>Successor : {successor ? successor.length : 0} </Typography>
                        </Box>
                    </Box>
                </div>

                <Box
                    sx={{
                        p: 2,
                        display: "flex",
                        justifyContent: "flex-end"
                    }}
                >
                    <Button
                        onClick={() => handleViewClick()}
                        disabled={!successor}
                        variant="contained"
                        style={{ width: 80, height: 40, fontSize: 16, backgroundColor: "#46cbe2" }}
                    >
                        {t("View")}
                    </Button>
                </Box>
            </Paper>

            {showSuccessorComponent && <SuccessorDetail data={successor ? successor : null} />}
        </div>
    )
}