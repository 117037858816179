import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from "@pdf-lib/fontkit";
import dayjs from 'dayjs';
import 'dayjs/locale/th';
// import ArabicNumberToText from '../../../../../utils/index'

function CheckNumber(Number) {
    var decimal = false;
    Number = Number.toString();
    Number = Number.replace(/ |,|บาท|฿/gi, '');
    for (var i = 0; i < Number.length; i++) {
        if (Number[i] == '.') {
            decimal = true;
        }
    }
    if (decimal == false) {
        Number = Number + '.00';
    }
    return Number
}

function ArabicNumberToText(Number) {
    var Number = CheckNumber(Number);
    var NumberArray = new Array("ศูนย์", "หนึ่ง", "สอง", "สาม", "สี่", "ห้า", "หก", "เจ็ด", "แปด", "เก้า", "สิบ");
    var DigitArray = new Array("", "สิบ", "ร้อย", "พัน", "หมื่น", "แสน", "ล้าน");
    var BahtText = "";
    if (isNaN(Number)) {
        return "ข้อมูลนำเข้าไม่ถูกต้อง";
    } else {
        if ((Number - 0) > 9999999.9999) {
            return "ข้อมูลนำเข้าเกินขอบเขตที่ตั้งไว้";
        } else {
            Number = Number.split(".");
            if (Number[1].length > 0) {
                Number[1] = Number[1].substring(0, 2);
            }
            var NumberLen = Number[0].length - 0;
            for (var i = 0; i < NumberLen; i++) {
                var tmp = Number[0].substring(i, i + 1) - 0;
                if (tmp != 0) {
                    if ((i == (NumberLen - 1)) && (tmp == 1)) {
                        BahtText += "เอ็ด";
                    } else
                        if ((i == (NumberLen - 2)) && (tmp == 2)) {
                            BahtText += "ยี่";
                        } else
                            if ((i == (NumberLen - 2)) && (tmp == 1)) {
                                BahtText += "";
                            } else {
                                BahtText += NumberArray[tmp];
                            }
                    BahtText += DigitArray[NumberLen - i - 1];
                }
            }
            BahtText += "บาท";
            if ((Number[1] == "0") || (Number[1] == "00")) {
                BahtText += "ถ้วน";
            } else {
                var DecimalLen = Number[1].length - 0;
                for (var i = 0; i < DecimalLen; i++) {
                    var tmp = Number[1].substring(i, i + 1) - 0;
                    if (tmp != 0) {
                        if ((i == (DecimalLen - 1)) && (tmp == 1)) {
                            BahtText += "เอ็ด";
                        } else
                            if ((i == (DecimalLen - 2)) && (tmp == 2)) {
                                BahtText += "ยี่";
                            } else
                                if ((i == (DecimalLen - 2)) && (tmp == 1)) {
                                    BahtText += "";
                                } else {
                                    BahtText += NumberArray[tmp];
                                }
                        BahtText += DigitArray[DecimalLen - i - 1];
                    }
                }
                BahtText += "สตางค์";
            }
            return BahtText;
        }
    }
}

export const SPS110PDFfile = async (branchNumber, type, selectYear, selectedMonth, employeeDetails, contributionRate, imageData, branchNumber2) => {
    const url = `${process.env.REACT_APP_API_URL}files/${type}.pdf`;
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const urlFont = `${process.env.REACT_APP_API_URL}fonts/THSarabunNew.ttf`;
    const fontBytes = await fetch(urlFont).then((res) => res.arrayBuffer());
    pdfDoc.registerFontkit(fontkit);

    const font = await pdfDoc.embedFont(fontBytes);
    const pages = pdfDoc.getPages();
    const page1 = pages[0];
    const page2 = pages[1];
    const page3 = pages[2];
    const page4 = pages[3];

    const months = [
        { value: "01", name: "มกราคม" },
        { value: "02", name: "กุมภาพันธ์" },
        { value: "03", name: "มีนาคม" },
        { value: "04", name: "เมษายน" },
        { value: "05", name: "พฤษภาคม" },
        { value: "06", name: "มิถุนายน" },
        { value: "07", name: "กรกฎาคม" },
        { value: "08", name: "สิงหาคม" },
        { value: "09", name: "กันยายน" },
        { value: "10", name: "ตุลาคม" },
        { value: "11", name: "พฤศจิกายน" },
        { value: "12", name: "ธันวาคม" },
    ];

    const employeeData = employeeDetails.employeeDetails;
    const taxData = employeeDetails.totalData;

    const selectedMonthObject = months.find(month => month.value == selectedMonth);
    const selectedMonthName = selectedMonthObject ? selectedMonthObject.name : "Unknown Month";

    if (employeeData[0] && employeeData[0].socialSecurityAccount) {
        const socialAccount = employeeData[0].socialSecurityAccount;

        const formattedsocialAccount1 = `${socialAccount[0] + ' ' + socialAccount[1]}`
        const formattedsocialAccount2 = `${socialAccount[2] + ' ' + socialAccount[3] + ' ' + socialAccount[4] + ' ' + socialAccount[5] + ' ' + socialAccount[6] + ' ' + socialAccount[7] + ' ' + socialAccount[8]}`
        const formattedsocialAccount3 = `${socialAccount[9]} `

        page1.drawText(formattedsocialAccount1, {
            x: 568, y: 472, size: 20, font: font
        });
        page1.drawText(formattedsocialAccount2, {
            x: 600, y: 472, size: 20, font: font
        });
        page1.drawText(formattedsocialAccount3, {
            x: 686, y: 472, size: 20, font: font
        });
    }

    if (employeeDetails) {
        let totalActual = employeeDetails.totalActual;

        const numberText = ArabicNumberToText(taxData.totalTax + taxData.totalTax)

        page1.drawText(`${String(totalActual).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, {
            x: 290, y: 322, size: 18, font: font
        });

        page1.drawText(`${String(taxData.totalTax).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, {
            x: 290, y: 302, size: 18, font: font
        });

        page1.drawText(`${String(taxData.totalTax).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, {
            x: 290, y: 285, size: 18, font: font
        });

        page1.drawText(`${String(taxData.totalTax + taxData.totalTax).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, {
            x: 290, y: 265, size: 18, font: font
        });

        page1.drawText(numberText, {
            x: 100, y: 246, size: 18, font: font
        });
    }

    if (imageData) {
        const pngImage = await pdfDoc.embedPng(imageData);
        const pngDims = pngImage.scale(0.08);
        page1.drawImage(pngImage, {
            x: 300, y: 105, width: pngDims.width, height: pngDims.height
        });
    }

    const presentDay = new Date();
    const presentDayTH = dayjs(presentDay).locale('th').format("DD");
    const presentMonthTH = dayjs(presentDay).locale('th').format("MMMM");
    const thaiYear = dayjs(presentDay).format('YYYY');
    const formattedThaiYear = parseInt(thaiYear) + 543;

    page1.drawText(`${employeeData[0].certificateSignatureName ? employeeData[0].certificateSignatureName : "-"}`, {
        x: 250, y: 85, size: 15, font: font,
    });
    page1.drawText(`${employeeData[0].certificateSignaturePosition ? employeeData[0].certificateSignaturePosition : "-"}`, {
        x: 260, y: 68, size: 15, font: font,
    });

    page1.drawText(`${presentDayTH}`, { x: 270, y: 48, size: 15, font: font });
    page1.drawText(`${presentMonthTH}`, { x: 320, y: 48, size: 15, font: font });
    page1.drawText(`${formattedThaiYear}`, { x: 410, y: 48, size: 15, font: font });

    // const branchNumber = ``;
    const bankAccount = employeeData && employeeData[0].accountNo;
    const formattedBankAccount = bankAccount && bankAccount.split("");

    if (formattedBankAccount) {
        page1.drawText(`${formattedBankAccount[0] + " " + formattedBankAccount[1]}`, {
            x: 568, y: 473, size: 20, font: font
        });

        page1.drawText(`${formattedBankAccount[2] + " " + formattedBankAccount[3] + " " + formattedBankAccount[4] + " " + formattedBankAccount[5] + " " + formattedBankAccount[6] + " " + formattedBankAccount[7] + " " + formattedBankAccount[8]}`, {
            x: 599, y: 473, size: 20, font: font
        });

        page1.drawText(`${formattedBankAccount[9]}`, {
            x: 688, y: 473, size: 20, font: font
        });
    }

    page1.drawText(branchNumber, {
        x: 568, y: 448, size: 20, font: font
    });

    page1.drawText(`${contributionRate ? contributionRate : "-"}`, {
        x: 615, y: 429, size: 20, font: font
    });

    {
        employeeData && employeeData[0].companyName &&
            page1.drawText(`${employeeData[0].companyName} `, {
                x: 170, y: 495, size: 16, font: font
            })
    }
    {
        employeeData && employeeData[0].addressCompany &&
            page1.drawText(`${employeeData[0].addressCompany} `, {
                x: 190, y: 450, size: 16, font: font
            })
    }
    {
        employeeData && employeeData[0].districtCompany &&
            page1.drawText(`${employeeData[0].districtCompany} `, {
                x: 100, y: 430, size: 16, font: font
            })
    }
    {
        employeeData && employeeData[0].provinceCompany &&
            page1.drawText(`${employeeData[0].provinceCompany} `, {
                x: 170, y: 430, size: 16, font: font
            })
    }
    {
        employeeData && employeeData[0].areaCodeCompany &&
            page1.drawText(`${employeeData[0].areaCodeCompany} `, {
                x: 139, y: 407, size: 16, font: font
            })
    }
    {
        employeeData && employeeData[0].mainContactPhone &&
            page1.drawText(`${employeeData[0].mainContactPhone} `, {
                x: 229, y: 407, size: 16, font: font
            })
    }
    {
        selectedMonth && selectedMonth &&
            page1.drawText(`${selectedMonthName} `, {
                x: 232, y: 386, size: 16, font: font
            })
    }
    {
        selectYear && selectYear &&
            page1.drawText(`${selectYear} `, {
                x: 330, y: 386, size: 16, font: font
            })
    }

    const x = 90;
    const y = 385;
    const marginBottom = 1.5;

    const itemsPerPage = 10;
    let pageIndex = 3;

    if (employeeDetails) {
        page1.drawText(`${employeeData.length}`, {
            x: 310, y: 228, size: 16, font: font
        });

        const totalPages = Math.ceil(employeeData.length / itemsPerPage);

        for (let itemIndex = 0; itemIndex < employeeData.length; itemIndex++) {
            if (itemIndex % itemsPerPage === 0) {
                const [newPageTemplate] = await pdfDoc.copyPages(pdfDoc, [1]);
                pdfDoc.addPage(newPageTemplate);
                pageIndex++;

                let pageTotalValue = 0;
                let pageTotalValueActual = 0;

                for (let i = itemIndex; i < Math.min(itemIndex + itemsPerPage, employeeData.length); i++) {
                    pageTotalValue += employeeData[i].value;

                    if (employeeData[i].salary > 15000) {
                        employeeData[i].salary = 15000;
                        pageTotalValueActual += 15000;
                    } else {
                        pageTotalValueActual += employeeData[i].salary;
                    }
                }

                const currentPage = pdfDoc.getPages()[pageIndex];
                currentPage.drawText(`${String(pageTotalValueActual).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, { x: x + 510, y: 170, size: 14, font: font });
                currentPage.drawText(`${String(pageTotalValue).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, { x: x + 620, y: 170, size: 14, font: font });
            }

            const currentPage = pdfDoc.getPages()[pageIndex];
            const offsetY = y - (itemIndex % itemsPerPage) * (20 + marginBottom);
            const item = employeeData[itemIndex];
            const taxID = `${item.taxID}`;
            const heightestSalary = 15000;

            const formattedtaxID1 = `${' ' + taxID[0]}`;
            const formattedtaxID2 = `${taxID[1] + '   ' + taxID[2] + '   ' + taxID[3] + '   ' + taxID[4]}`;
            const formattedtaxID3 = `${taxID[5] + '   ' + taxID[6] + '   ' + taxID[7] + '    ' + taxID[8] + '   ' + taxID[9]}`;
            const formattedtaxID4 = `${taxID[10] + '   ' + taxID[11] + '     ' + taxID[12]} `;

            currentPage.drawText(`${itemIndex + 1}`, { x, y: offsetY + 5, size: 14, font: font });
            currentPage.drawText(`${item.taxID ? formattedtaxID1 : ""}`, { x: x + 31, y: offsetY + 8, size: 14, font: font });
            currentPage.drawText(`${item.taxID ? formattedtaxID2 : ""}`, { x: x + 55, y: offsetY + 8, size: 14, font: font });
            currentPage.drawText(`${item.taxID ? formattedtaxID3 : ""}`, { x: x + 120, y: offsetY + 8, size: 14, font: font });
            currentPage.drawText(`${item.taxID ? formattedtaxID4 : ""}`, { x: x + 200, y: offsetY + 8, size: 14, font: font });
            currentPage.drawText(`${item.title_TH}`, { x: x + 285, y: offsetY + 7, size: 14, font: font });
            currentPage.drawText(`${item.firstname_TH}`, { x: x + 315, y: offsetY + 7, size: 14, font: font });
            currentPage.drawText(`${item.lastname_TH}`, { x: x + 385, y: offsetY + 7, size: 14, font: font });
            currentPage.drawText(`${item.valueActual > heightestSalary ?
                `${String(heightestSalary).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}` :
                `${String(item.valueActual).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`}`,
                { x: x + 510, y: offsetY + 7, size: 14, font: font });
            currentPage.drawText(`${item.value}`, { x: x + 620, y: offsetY + 7, size: 14, font: font });

            if (itemIndex % itemsPerPage === 0 && imageData) {
                const pngImage = await pdfDoc.embedPng(imageData);
                const pngDims = pngImage.scale(0.05);
                currentPage.drawImage(pngImage, {
                    x: 670, y: 130, width: pngDims.width, height: pngDims.height
                })
            }

            if (itemIndex % itemsPerPage === 0) {
                {
                    selectedMonth && selectedMonth &&
                        currentPage.drawText(`${selectedMonthName} `, {
                            x: 175, y: 521, size: 16, font: font
                        })
                }
                {
                    selectYear && selectYear &&
                        currentPage.drawText(`${selectYear} `, {
                            x: 283, y: 521, size: 16, font: font
                        })
                }
                {
                    employeeData[0].companyName &&
                        currentPage.drawText(`${employeeData[0].companyName} `, {
                            x: 240, y: 500, size: 16, font: font
                        })
                }

                currentPage.drawText(`${pageIndex - 3}`, { x: x + 540, y: 522, size: 16, font: font });
                currentPage.drawText(`${totalPages}`, { x: x + 640, y: 522, size: 16, font: font });
                currentPage.drawText(`${branchNumber2}`, { x: x + 551, y: 480, size: 15, font: font })

                //account
                if (formattedBankAccount) {
                    currentPage.drawText(`${formattedBankAccount[0] + "  " + formattedBankAccount[1]}`, {
                        x: 641, y: 500, size: 18, font: font
                    });

                    currentPage.drawText(`${formattedBankAccount[2] + "  " + formattedBankAccount[3] + "  " + formattedBankAccount[4] + "  " + formattedBankAccount[5] + "  " + formattedBankAccount[6] + "  " + formattedBankAccount[7] + "  " + formattedBankAccount[8]}`, {
                        x: 676, y: 500, size: 18, font: font
                    });

                    currentPage.drawText(`${formattedBankAccount[9]}`, {
                        x: 783, y: 500, size: 18, font: font
                    });
                }

                //signature
                currentPage.drawText(`${employeeData[0].certificateSignatureName ? employeeData[0].certificateSignatureName : "-"}`, {
                    x: 630, y: 116, size: 15, font: font,
                });
                currentPage.drawText(`${employeeData[0].certificateSignaturePosition ? employeeData[0].certificateSignaturePosition : "-"}`, {
                    x: 620, y: 98, size: 15, font: font,
                });

                currentPage.drawText(`${presentDayTH}`, { x: 640, y: 80, size: 15, font: font });
                currentPage.drawText(`${presentMonthTH}`, { x: 688, y: 80, size: 15, font: font });
                currentPage.drawText(`${formattedThaiYear}`, { x: 778, y: 80, size: 15, font: font });
            }
        }
    }

    pdfDoc.removePage(2);
    pdfDoc.removePage(2);

    pdfDoc.addPage(page3);
    pdfDoc.addPage(page4);

    page3.drawText(`${selectedMonthName}`, { x: 410, y: 498, size: 18, font: font });
    page3.drawText(`${selectYear}`, { x: 545, y: 498, size: 18, font: font });

    {
        employeeData[0].companyName &&
            page3.drawText(`${employeeData[0].companyName}`, { x: 320, y: 478, size: 18, font: font })
    }

    if (formattedBankAccount) {
        page3.drawText(`${formattedBankAccount[0] + "  " + formattedBankAccount[1]}`, {
            x: 666, y: 478, size: 16, font: font
        });

        page3.drawText(`${formattedBankAccount[2] + "  " + formattedBankAccount[3] + " " + formattedBankAccount[4] + "  " + formattedBankAccount[5] + " " + formattedBankAccount[6] + " " + formattedBankAccount[7] + "  " + formattedBankAccount[8]}`, {
            x: 698, y: 478, size: 16, font: font
        });

        page3.drawText(`${formattedBankAccount[9]}`, {
            x: 782, y: 478, size: 16, font: font
        });
    }

    page3.drawText(`${contributionRate ? contributionRate : "-"}`, {
        x: 723, y: 450, size: 20, font: font
    });

    if (employeeDetails) {
        let totalActual = employeeDetails.totalActual;
        page3.drawText(`1`, {
            x: 75, y: 335, size: 18, font: font
        });
        page3.drawText(`0  0  0  0  0  0`, {
            x: 101, y: 335, size: 18, font: font
        });
        page3.drawText(`${String(totalActual).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, {
            x: 195, y: 335, size: 18, font: font
        });
        page3.drawText(`${String(taxData.totalTax).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, {
            x: 295, y: 335, size: 18, font: font
        });
        page3.drawText(`${String(taxData.totalTax).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, {
            x: 370, y: 335, size: 18, font: font
        });
        page3.drawText(`${String(taxData.totalTax + taxData.totalTax).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, {
            x: 440, y: 335, size: 18, font: font
        });
        page3.drawText(`${employeeData.length}`, {
            x: 530, y: 335, size: 18, font: font
        });

        page3.drawText(`${String(totalActual).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, {
            x: 195, y: 130, size: 18, font: font
        });
        page3.drawText(`${String(taxData.totalTax).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, {
            x: 295, y: 130, size: 18, font: font
        });
        page3.drawText(`${String(taxData.totalTax).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, {
            x: 370, y: 130, size: 18, font: font
        });
        page3.drawText(`${String(taxData.totalTax + taxData.totalTax).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, {
            x: 440, y: 130, size: 18, font: font
        });
        page3.drawText(`${employeeData.length}`, {
            x: 530, y: 130, size: 18, font: font
        });

        page3.drawText(`${String(totalActual).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, {
            x: 195, y: 107, size: 18, font: font
        });
        page3.drawText(`${String(taxData.totalTax).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, {
            x: 295, y: 107, size: 18, font: font
        });
        page3.drawText(`${String(taxData.totalTax).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, {
            x: 370, y: 107, size: 18, font: font
        });
        page3.drawText(`${String(taxData.totalTax + taxData.totalTax).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, {
            x: 440, y: 107, size: 18, font: font
        });
        page3.drawText(`${employeeData.length}`, {
            x: 530, y: 107, size: 18, font: font
        });

        //signature
        if (imageData) {
            const pngImage = await pdfDoc.embedPng(imageData);
            const pngDims = pngImage.scale(0.05);
            page3.drawImage(pngImage, {
                x: 420, y: 82, width: pngDims.width, height: pngDims.height
            })
        }

        page3.drawText(`${employeeData[0].certificateSignatureName ? employeeData[0].certificateSignatureName : "-"}`, {
            x: 390, y: 65, size: 15, font: font,
        });
        page3.drawText(`${employeeData[0].certificateSignaturePosition ? employeeData[0].certificateSignaturePosition : "-"}`, {
            x: 400, y: 47, size: 15, font: font,
        });

        page3.drawText(`${presentDayTH}`, { x: 410, y: 30, size: 15, font: font });
        page3.drawText(`${presentMonthTH}`, { x: 460, y: 30, size: 15, font: font });
        page3.drawText(`${formattedThaiYear}`, { x: 542, y: 30, size: 15, font: font });
    }

    {
        employeeData[0].companyName &&
            page4.drawText(`1                      1`, { x: 650, y: 498, size: 18, font: font })
    }

    {
        employeeData[0].companyName &&
            page4.drawText(`${employeeData[0].companyName}`, { x: 320, y: 472, size: 18, font: font })
    }

    if (formattedBankAccount) {
        page4.drawText(`${formattedBankAccount[0] + "  " + formattedBankAccount[1]}`, {
            x: 666, y: 468, size: 16, font: font
        });

        page4.drawText(`${formattedBankAccount[2] + "  " + formattedBankAccount[3] + " " + formattedBankAccount[4] + "  " + formattedBankAccount[5] + " " + formattedBankAccount[6] + " " + formattedBankAccount[7] + "  " + formattedBankAccount[8]}`, {
            x: 698, y: 468, size: 16, font: font
        });

        page4.drawText(`${formattedBankAccount[9]}`, {
            x: 782, y: 468, size: 16, font: font
        });
    }

    if (employeeDetails) {
        // let totalActual = 0;
        // for (let i = 0; i < employeeData.length; i++) {
        //     if (employeeData[i].salary > 15000) {
        //         employeeData[i].salary = 15000;
        //         totalActual += 15000
        //     } else {
        //         totalActual += employeeData[i].salary;
        //     }
        // }

        let totalActual = employeeDetails.totalActual;

        page4.drawText(`1`, {
            x: 82, y: 377, size: 18, font: font
        });
        page4.drawText(`0   0   0   0   0   0`, {
            x: 113, y: 377, size: 18, font: font
        });
        page4.drawText(`${String(totalActual).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, {
            x: 250, y: 377, size: 18, font: font
        });
        page4.drawText(`${String(taxData.totalTax).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, {
            x: 370, y: 377, size: 18, font: font
        });
        page4.drawText(`${String(taxData.totalTax).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, {
            x: 490, y: 377, size: 18, font: font
        });
        page4.drawText(`${String(taxData.totalTax + taxData.totalTax).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, {
            x: 610, y: 377, size: 18, font: font
        });
        page4.drawText(`${employeeData.length}`, {
            x: 745, y: 377, size: 18, font: font
        });

        page4.drawText(`${String(totalActual).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, {
            x: 250, y: 100, size: 18, font: font
        });
        page4.drawText(`${String(taxData.totalTax).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, {
            x: 370, y: 100, size: 18, font: font
        });
        page4.drawText(`${String(taxData.totalTax).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, {
            x: 490, y: 100, size: 18, font: font
        });
        page4.drawText(`${String(taxData.totalTax + taxData.totalTax).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`, {
            x: 610, y: 100, size: 18, font: font
        });
        page4.drawText(`${employeeData.length}`, {
            x: 745, y: 100, size: 18, font: font
        });

        //signature
        if (imageData) {
            const pngImage = await pdfDoc.embedPng(imageData);
            const pngDims = pngImage.scale(0.05);
            page4.drawImage(pngImage, {
                x: 520, y: 72, width: pngDims.width, height: pngDims.height
            })
        }

        page4.drawText(`${employeeData[0].certificateSignatureName ? employeeData[0].certificateSignatureName : "-"}`, {
            x: 490, y: 55, size: 15, font: font,
        });
        page4.drawText(`${employeeData[0].certificateSignaturePosition ? employeeData[0].certificateSignaturePosition : "-"}`, {
            x: 470, y: 37, size: 15, font: font,
        });

        page4.drawText(`${presentDayTH}`, { x: 490, y: 17, size: 15, font: font });
        page4.drawText(`${presentMonthTH}`, { x: 540, y: 17, size: 15, font: font });
        page4.drawText(`${formattedThaiYear}`, { x: 632, y: 17, size: 15, font: font });
    }

    const pdfBytes = await pdfDoc.save();
    const bytes = new Uint8Array(pdfBytes);
    const blob = new Blob([bytes], { type: "application/pdf" });
    const docUrl = URL.createObjectURL(blob);
    window.open(docUrl, "_blank");
}