import React, { useState, Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

//Translator TH-EN
import { useTranslation } from "react-i18next";

import { styled } from "@mui/material/styles";

import { Typography } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import EditIcon from "@mui/icons-material/Edit";

import ButtonBlue from "../../../../shared/general/ButtonBlue";

import { getEstimateLevel } from "../../../../../../actions/estimatePerformance";
import { getEmployeePotential, getEmployeeTalent } from "../../../../../../actions/employee";
import DialogTalent from "./DialogTalent";

const StyledRoot = styled("div")({
    "& .button-show-performance": {
        display: "flex",
        justifyContent: "center",
        margin: "16px 0",
    },
});

const StyleWrapFiveYear = styled("div")({
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
});

const StyledWrapTop = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
    "& .MuiTypography-body2": {
        margin: 0,
    },
});

const StyledHeadLabel = styled(Typography)({
    fontWeight: 600,
    fontSize: 14,
});

const CURRENT_YEAR_DISPLAY =
    dayjs().get("month") >= 11
        ? dayjs().get("year")
        : dayjs().subtract(1, "year").get("year");

const Talent = () => {
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();

    const { result: userProfile } = useSelector((state) => state.userProfile);
    const { result: employeeProfile } = useSelector((state) => state.employeeProfile);

    const [openEdit, setOpenEdit] = useState(false);
    const [talent, setTalent] = useState(null);
    const [openAllTalent, setOpenAllTalent] = useState(false);

    const [drawerConfig, setDrawerConfig] = useState({
        isOpen: false,
        isEdit: false,
        data: null,
    });

    const onDrawerClose = () => {
        setDrawerConfig((prev) => ({
            ...prev,
            isOpen: false,
        }));
    };

    const fetchedTalentData = async (idEmp) => {
        try {
            const response = await getEmployeeTalent(idEmp);
            if (response && response.data) {
                setTalent(response.data);
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (employeeProfile) {
            fetchedTalentData(employeeProfile && employeeProfile.idEmployees);
        }
    }, [employeeProfile]);

    const onOpenDrawer = () => {
        if (talent && talent.length > 0) {
            setDrawerConfig((prev) => ({
                ...prev,
                isOpen: true,
                isEdit: true,
                data: talent,
            }));
        } else {
            setDrawerConfig((prev) => ({
                ...prev,
                isOpen: true,
                isEdit: false,
            }));
        }
    };

    const toggleOpenAllTalent = (value) => {
        setOpenAllTalent(value);
    };

    const findLatestTalent = () => {
        const lastTalent = talent && talent.find(item => item.year === CURRENT_YEAR_DISPLAY);
        return (
            <Fragment>
                <Typography variant="h5" align="center">
                    {lastTalent ? lastTalent.talentName : "-"}
                </Typography>
                <Typography variant="body1" align="center" color="text.secondary">
                    {CURRENT_YEAR_DISPLAY}
                </Typography>
            </Fragment>
        );
    };

    const findPotential = (index) => {
        const talentData = talent && talent.find(item => (item.year === dayjs().subtract(dayjs().get("month") >= 11 ? index + 1 : index + 2, "year").get('year')));
        return talentData ? talentData.talentName : "-";
    }

    useEffect(() => {
        dispatch(getEstimateLevel());
    }, []);

    return (
        <StyledRoot>
            <StyledWrapTop>
                <StyledHeadLabel variant="body2" gutterBottom>
                    {t("LatestYearEvaluationResults")}
                </StyledHeadLabel>
                {!userProfile.readOnly && (
                    <ButtonBlue
                        variant="text"
                        size="small"
                        startIcon={<EditIcon />}
                        // onClick={() => setOpenEdit(true)}
                        onClick={() => {
                            onOpenDrawer();
                        }}
                    >
                        {t("EditData")}
                    </ButtonBlue>
                )}
            </StyledWrapTop>
            <div>{employeeProfile && findLatestTalent()}</div>
            <StyledWrapTop className="five-year">
                <StyledHeadLabel variant="body2" gutterBottom>
                    {t("EvaluateWorkOverThePast2Years")}
                </StyledHeadLabel>
            </StyledWrapTop>
            <StyleWrapFiveYear>
                {[...Array(2).keys()].map((item, index) => (
                    <div key={item}>
                        <Typography variant="h5" align="center">
                            {findPotential(index)}
                        </Typography>
                        <Typography variant="body1" align="center" color="text.secondary">
                            {CURRENT_YEAR_DISPLAY - (index + 1)}
                        </Typography>
                    </div>
                ))}
            </StyleWrapFiveYear>
            <div className="button-show-performance">
                <ButtonBlue
                    variant={openAllTalent ? "text" : "outlined"}
                    onClick={() => toggleOpenAllTalent(!openAllTalent)}
                >
                    {t("AllPerformance")}
                </ButtonBlue>
            </div>
            {openAllTalent && (
                <TableContainer>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">{t("Year")}</TableCell>
                                <TableCell align="center">{t("Level")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {talent && talent.length > 0 ? (
                                talent.map((potentail, index) => {
                                    return (
                                        <TableRow
                                            key={index}
                                            sx={{
                                                "&:last-child td, &:last-child th": { border: 0 },
                                            }}
                                        >
                                            <TableCell align="center" component="th" scope="row">
                                                {potentail.year}
                                            </TableCell>
                                            <TableCell align="center">
                                                {potentail.talentName}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            ) : (
                                <TableRow
                                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                >
                                    <TableCell
                                        align="center"
                                        component="th"
                                        scope="row"
                                        colSpan={2}
                                    >
                                        {t("NoPerformance")}
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}

            <DialogTalent drawerConfig={drawerConfig} onClose={onDrawerClose} />
        </StyledRoot>
    );
};

export default Talent;
