import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Grid, Typography } from "@mui/material";

import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import IconButton from "@mui/material/IconButton";
import Autocomplete from "@mui/material/Autocomplete";

import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";

import ButtonBlue from "../../../../shared/general/ButtonBlue";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";

import DrawerCustom from "../../../../shared/general/Drawer";

//Translator TH-EN
import { useTranslation } from "react-i18next";

import {
  getEmployeeProfile,
  updateEmployeePosition,
} from "../../../../../../actions/employee";
import {
  updateUser,
  updateManagerEmployee,
} from "../../../../../../actions/user";
import { getWorkInfoByidCompany } from "../../../../../../actions/company";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { getManager, getManagerGroup } from "../../../../../../actions/manager";
import DatePickerCustom from "../../../../shared/date/datePicker";

const StyledRoot = styled("div")({
  width: 550,
  padding: 24,
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledTextField = styled(TextField)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});
const StyledMenuItem = styled(MenuItem)({
  display: "flex",
  flexDirection: "column",
  alignItems: "baseline",
});

const StyledIconButton = styled(IconButton)({
  color: "#f15e5e",
  marginLeft: 4,
});

const StyledAutocomplete = styled(Autocomplete)({
  "& .MuiAutocomplete-input": {
    padding: "4.5px 4px 4.5px 5px !important",
  },
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 360,
    },
  },
};

const HiringDate = (props) => {
  const { handleChange, formData } = props;
  const { t, i18n } = useTranslation();

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("DateEntry")}</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <DatePickerCustom
          value={formData.hiringDate}
          inputFormat="DD/MM/YYYY"
          name={"hiringDate"}
          onChange={(date) => {
            handleChange("hiringDate", date);
          }}
          openTo="year"
          views={["year", "month", "day"]}
          renderInput={(params) => (
            <StyledTextField
              fullWidth
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: "วัน/เดือน/ปี (พ.ศ.)",
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("ContractRenewalDate")}</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <DatePickerCustom
          value={formData.contractTermainatoinDate}
          inputFormat="DD/MM/YYYY"
          name={"contractTermainatoinDate"}
          onChange={(date) => {
            handleChange("contractTermainatoinDate", date);
          }}
          openTo="year"
          views={["year", "month", "day"]}
          renderInput={(params) => (
            <StyledTextField
              fullWidth
              {...params}
              inputProps={{
                ...params.inputProps,
                placeholder: "วัน/เดือน/ปี (พ.ศ.)",
              }}
            />
          )}
        />
        {/* <LocalizationProvider dateAdapter={AdapterDateFns} locale={th}>
          <DatePicker
            value={formData.contractTermainatoinDate}
            inputFormat="dd/MM/yyyy"
            name={"contractTermainatoinDate"}
            onChange={(date, inputValue) => {
              console.log("contractTermainatoinDate", date)
              console.log("inputValue", inputValue);
              handleChange("contractTermainatoinDate", date);
            }}
            renderInput={(params) => <StyledTextField fullWidth {...params} />}
          />
        </LocalizationProvider> */}
      </Grid>
    </Grid>
  );
};

const EmployeeType = (props) => {
  const { handleChange, formData, handleChangeLocation } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: workTypesList } = useSelector((state) => state.workTypes);

  useEffect(() => {
    dispatch(getWorkInfoByidCompany());
  }, []);

  return (
    <Grid container alignItems="center" spacing={2}>
      {/* <Grid item xs={12} sm={3}>
        <StyledContentLabel>ระดับ(งาน)</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <StyledFormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="idJobLevel"
            value={formData.idJobLevel}
            onChange={handleChange}
          >
            {workTypesList &&
              workTypesList.jobLevel.map((item) => (
                <MenuItem value={item.idJobLevel}>{item.jobLevelName}</MenuItem>
              ))}
          </Select>
        </StyledFormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>ระดับ</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <StyledFormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="idPersonnelLevel"
            value={formData.idPersonnelLevel}
            onChange={handleChange}
          >
            {workTypesList &&
              workTypesList.personnelLevel.map((item) => (
                <MenuItem value={item.idPersonnelLevel}>
                  {item.personnelLevelName}
                </MenuItem>
              ))}
          </Select>
        </StyledFormControl>
      </Grid> */}
      {/* <Grid item xs={12} sm={3}>
        <StyledContentLabel>ประเภทการจ้าง</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <StyledFormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="idEmploymentType"
            value={formData.idEmploymentType}
            onChange={handleChange}
          >
            {workTypesList &&
              workTypesList.employeeType.map((item) => (
                <MenuItem value={item.idEmploymentType}>
                  {item.employmentTypeName}
                </MenuItem>
              ))}
          </Select>
        </StyledFormControl>
      </Grid> */}
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("EmploymentTime")}</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <StyledFormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="employmentTime"
            value={formData.employmentTime}
            onChange={handleChange}
          >
            <MenuItem value={"Full time"}>Full time</MenuItem>
            <MenuItem value={"Part time"}>Part time</MenuItem>
          </Select>
        </StyledFormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>Field/Office</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <StyledFormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="filed_office"
            value={formData.filed_office}
            onChange={handleChange}
          >
            <MenuItem value={"Field"}>Field</MenuItem>
            <MenuItem value={"Office"}>Office</MenuItem>
            <MenuItem value={"field/office"}>Field/Office</MenuItem>
          </Select>
        </StyledFormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("WorkingLocationPlace")}</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextFieldTheme
          name="workingLocation"
          value={formData.workingLocation}
          onChange={handleChange}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("GpsLocationWork")}</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <StyledAutocomplete
          freeSolo
          id="combo-box-demo"
          value={formData.groupGpsLocationsName}
          onChange={(event, newValue) => {
            const found = workTypesList.groupGpsLocations.find(
              (x) => x.name === newValue
            );
            handleChangeLocation(found);
          }}
          options={
            workTypesList && workTypesList.groupGpsLocations.length > 0
              ? workTypesList.groupGpsLocations.map((option) => option.name)
              : []
          }
          style={{ width: "100%" }}
          renderInput={(params) => <TextFieldTheme {...params} />}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("MethodAttendanceSpot")}</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <StyledFormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="methodAttendance"
            value={formData.methodAttendance}
            onChange={handleChange}
          >
            <MenuItem value={"main"}>{t("MainLocation")}</MenuItem>
            <MenuItem value={"location"}>{t("AllLocation")}</MenuItem>
            <MenuItem value={"point"}>{t("GPSPoint")}</MenuItem>
          </Select>
        </StyledFormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>{t("FingerScan")}</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <StyledFormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="isFinger"
            value={formData.isFinger}
            onChange={handleChange}
          >
            <MenuItem value={"1"}>{t("Yes")}</MenuItem>
            <MenuItem value={"0"}>{t("No")}</MenuItem>
          </Select>
        </StyledFormControl>
      </Grid>
    </Grid>
  );
};

const Manager = (props) => {
  const { handleChange, formData, handleDeleteManagerLV2 } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: managerList } = useSelector((state) => state.manager);
  const { result: managerGroupList } = useSelector(
    (state) => state.managerGroup
  );

  useEffect(() => {
    dispatch(getManager());
    dispatch(getManagerGroup());
  }, []);

  return (
    <Fragment>
      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={12} sm={3}>
          <StyledContentLabel>{t("No_Number")} 1</StyledContentLabel>
        </Grid>
        <Grid item xs={12} sm={9}>
          <StyledFormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="managerLV1"
              value={formData.managerLV1}
              onChange={handleChange}
              // onChange={(e) => {}}
            >
              {managerList &&
                managerGroupList &&
                [...managerList, ...managerGroupList].map((item, index) => {
                  if (item.idEmployees) {
                    return (
                      <StyledMenuItem
                        key={index}
                        value={`idEmployees-${item.idEmployees}`}
                      >
                        <Typography>{`${item.firstname_TH} ${item.lastname_TH}`}</Typography>
                        <Typography color="text.secondary" variant="body2">
                          {item.email}
                        </Typography>
                      </StyledMenuItem>
                    );
                  } else {
                    return (
                      <StyledMenuItem
                        key={index}
                        value={`idManagerGroup-${item.idManagerGroup}`}
                      >
                        <Typography>{`${item.managerGroupName}`}</Typography>
                        <Typography color="text.secondary" variant="body2">
                          {item.managerList.length} {t("Manager")}
                        </Typography>
                      </StyledMenuItem>
                    );
                  }
                })}
            </Select>
          </StyledFormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <StyledContentLabel>{t("No_Number")} 2</StyledContentLabel>
            <div>
              <StyledIconButton
                aria-label="delete"
                size="small"
                onClick={handleDeleteManagerLV2}
              >
                <HighlightOffRoundedIcon fontSize="inherit" />
              </StyledIconButton>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={9}>
          <StyledFormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="managerLV2"
              value={formData.managerLV2}
              // value={formData.idManagerLV2}
              onChange={handleChange}
            >
              {managerList &&
                managerGroupList &&
                [...managerList, ...managerGroupList].map((item, index) => {
                  if (item.idEmployees) {
                    return (
                      <StyledMenuItem
                        key={index}
                        value={`idEmployees-${item.idEmployees}`}
                      >
                        <Typography>{`${item.firstname_TH} ${item.lastname_TH}`}</Typography>
                        <Typography color="text.secondary" variant="body2">
                          {item.email}
                        </Typography>
                      </StyledMenuItem>
                    );
                  } else {
                    return (
                      <StyledMenuItem
                        key={index}
                        value={`idManagerGroup-${item.idManagerGroup}`}
                      >
                        <Typography>{`${item.managerGroupName}`}</Typography>
                        <Typography color="text.secondary" variant="body2">
                          {item.managerList.length} {t("Manager")}
                        </Typography>
                      </StyledMenuItem>
                    );
                  }
                })}
            </Select>
          </StyledFormControl>
        </Grid>
      </Grid>
    </Fragment>
  );
};

const OesyEdit = (props) => {
  const { handleChange, formData } = props;
  const { t, i18n } = useTranslation();

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>OESY ({t("Year")})</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <StyledFormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="OESY_Y"
            value={formData.OESY_Y}
            onChange={handleChange}
            MenuProps={MenuProps}
          >
            {[...Array(60).keys()].map((item) => (
              <MenuItem value={item + 1}>{item + 1}</MenuItem>
            ))}
          </Select>
        </StyledFormControl>
      </Grid>
      <Grid item xs={12} sm={3}>
        <StyledContentLabel>OESY ({t("Month")})</StyledContentLabel>
      </Grid>
      <Grid item xs={12} sm={9}>
        <StyledFormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            name="OESY_M"
            value={formData.OESY_M}
            onChange={handleChange}
          >
            {[...Array(12).keys()].map((item) => (
              <MenuItem value={item}>{item}</MenuItem>
            ))}
          </Select>
        </StyledFormControl>
      </Grid>
    </Grid>
  );
};

const DialogEdit = (props) => {
  const { open, mode, handleCloseDialog } = props;
  const dispatch = useDispatch();
  const today = dayjs().toDate();
  const { t, i18n } = useTranslation();

  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const [formData, setFormData] = useState(null);

  useEffect(() => {
    if (mode === "hiring-contract-date") {
      setFormData({
        idEmployees: employeeProfile.idEmployees,
        hiringDate: employeeProfile.hiringDate,
        contractTermainatoinDate: employeeProfile.contractTermainatoinDate,
      });
    } else if (mode === "employeeType") {
      setFormData({
        idEmployees: employeeProfile.idEmployees,
        mainWorkingLocationPoint: employeeProfile.mainWorkingLocationPoint,
        methodAttendance: employeeProfile.methodAttendance,
        groupGpsLocationsName: employeeProfile.groupGpsLocationsName,
        workingLocation: employeeProfile.workingLocation,
        employmentTime: employeeProfile.employmentTime,
        filed_office: employeeProfile.filed_office,
        isFinger: employeeProfile.isFinger,
      });
    } else if (mode === "manager") {
      setFormData({
        idEmployees: employeeProfile.idEmployees,
        idEmployeePosition: employeeProfile.idEmployeePosition,
        // idManagerLV1: employeeProfile.managerLV1_idManagerLV1,
        // idManagerLV2: employeeProfile.managerLV2_idManagerLV2,
        managerLV1: employeeProfile.managerLV1_idManagerLV1
          ? `idEmployees-${employeeProfile.managerLV1_idManagerLV1}`
          : employeeProfile.idManagerGroupLV1
          ? `idManagerGroup-${employeeProfile.idManagerGroupLV1}`
          : "",
        managerLV2: employeeProfile.managerLV2_idManagerLV2
          ? `idEmployees-${employeeProfile.managerLV2_idManagerLV2}`
          : employeeProfile.idManagerGroupLV2
          ? `idManagerGroup-${employeeProfile.idManagerGroupLV2}`
          : "",
      });
    } else if (mode === "experience") {
      setFormData({
        idEmployees: employeeProfile.idEmployees,
        OESY_Y: employeeProfile.OESY_Y,
        OESY_M: employeeProfile.OESY_M,
      });
    }
  }, [open]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleDeleteManagerLV2 = () => {
    setFormData({
      ...formData,
      ["idManagerLV2"]: null,
      ["managerLV2"]: "",
    });
  };

  const handleChangeDate = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleChangeLocation = (foundGroupLocations) => {
    let temp = { ...formData };
    temp.mainWorkingLocationPoint = foundGroupLocations.idGroupGpsLocations;
    temp.groupGpsLocationsName = foundGroupLocations.name;
    setFormData(temp);
  };

  const handleSubmit = async () => {
    let dataRequest = { ...formData };
    if (mode === "employeeType") {
      dataRequest.UpdateBy = userProfile.idEmployees;
      delete dataRequest["groupGpsLocationsName"];
      console.log(dataRequest);
      const result = await dispatch(updateUser(dataRequest));
      if (result) {
        handleCloseDialog();
        dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
      }
    } else if (mode === "manager") {
      delete dataRequest["idEmployees"];
      if (dataRequest.managerLV1) {
        if (dataRequest.managerLV1.split("-")[0] === "idEmployees") {
          dataRequest.idManagerLV1 = dataRequest.managerLV1.split("-")[1];
          dataRequest.idManagerGroupLV1 = null;
        } else if (dataRequest.managerLV1.split("-")[0] === "idManagerGroup") {
          dataRequest.idManagerLV1 = null;
          dataRequest.idManagerGroupLV1 = dataRequest.managerLV1.split("-")[1];
        }
      } else {
        dataRequest.idManagerLV1 = null;
        dataRequest.idManagerGroupLV1 = null;
      }
      if (dataRequest.managerLV2) {
        if (dataRequest.managerLV2.split("-")[0] === "idEmployees") {
          dataRequest.idManagerLV2 = dataRequest.managerLV2.split("-")[1];
          dataRequest.idManagerGroupLV2 = null;
        } else if (dataRequest.managerLV2.split("-")[0] === "idManagerGroup") {
          dataRequest.idManagerLV2 = null;
          dataRequest.idManagerGroupLV2 = dataRequest.managerLV2.split("-")[1];
        }
      } else {
        dataRequest.idManagerLV2 = null;
        dataRequest.idManagerGroupLV2 = null;
      }
      delete dataRequest["managerLV1"];
      delete dataRequest["managerLV2"];

      const result = await dispatch(updateEmployeePosition(dataRequest));
      if (result) {
        handleCloseDialog();
        dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
      }
    } else if (mode === "hiring-contract-date") {
      formData.UpdateBy = userProfile.idUsers;
      formData.hiringDate = formData.hiringDate
        ? dayjs(formData.hiringDate).format("YYYY-MM-DD")
        : null;
      formData.contractTermainatoinDate = formData.contractTermainatoinDate
        ? dayjs(formData.contractTermainatoinDate).format("YYYY-MM-DD")
        : null;

      const result = await dispatch(updateUser(formData));
      if (result) {
        handleCloseDialog();
        dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
      }
    } else if (mode === "experience") {
      console.log("experience formData :", formData);
      formData.UpdateBy = userProfile.idUsers;
      const result = await dispatch(updateUser(formData));
      if (result) {
        handleCloseDialog();
        dispatch(getEmployeeProfile(employeeProfile.idEmployees, true));
      }
    }
  };

  return (
    <DrawerCustom
      title={
        mode === "manager"
          ? `${t("EditManagerInfo")}`
          : mode === "resign"
          ? `${t("DateEntry")}/${t("ContractRenewalDate")}`
          : `${t("EditWorkInfo")}`
      }
      anchor={"right"}
      open={open}
      onClose={handleCloseDialog}
    >
      <StyledRoot>
        {mode === "manager" && (
          <Manager
            handleChange={handleChange}
            formData={formData}
            handleDeleteManagerLV2={handleDeleteManagerLV2}
          />
        )}
        {mode === "hiring-contract-date" && (
          <HiringDate handleChange={handleChangeDate} formData={formData} />
        )}
        {mode === "employeeType" && (
          <EmployeeType
            handleChange={handleChange}
            formData={formData}
            handleChangeLocation={handleChangeLocation}
          />
        )}

        {mode === "experience" && (
          <OesyEdit handleChange={handleChange} formData={formData} />
        )}

        <StyledFooter>
          <ButtonBlue
            variant="text"
            className="cancel"
            onClick={handleCloseDialog}
          >
            {t("Cancel")}
          </ButtonBlue>
          <ButtonBlue variant="contained" onClick={handleSubmit}>
            {t("Save")}
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogEdit;
