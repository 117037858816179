import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

// Material
import {
  Box,
  Card,
  TextField,
  InputAdornment,
  IconButton,
  styled,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import SendIcon from "@mui/icons-material/Send";
import Logo from "../../assets/Chatbot.png";

// Package
import dayjs from "dayjs";
import { PDFDocument } from "pdf-lib";

// Shared Component
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import ButtonBlue from "../../shared/general/ButtonBlue";
import PaySlipDetail from "../../shared/payslip";
import DatePickerCustom from "../../shared/date/datePicker";
import CardStyle from "../../shared/general/Card";

// API
import fileService from "../../../../services/file.service";
import { renderComponentToCanvas } from "../../../../utils/renderToCanvas";
import { getPayslipByEmployee } from "../../../../actions/payruns";
import { getAttendanceById } from "../../../../actions/attendance";
import {
  getBotResponse,
  getDocumentPDFFile,
  getEmpHistoryEnroll,
  getLateAbsent,
  getLeaveQuota,
  getOvertimeShift,
  getLeaveRequestAllYear,
  getPersonalTaxByMonth,
  getAllRequestTimeBy,
  getAllRequestTimeWithDrawBy,
  getLeaveRequest,
  getAllLeaveWithDrawBy,
  getEmpSkillById,
  getCompetencyEvaluate,
  getUserKPI,
  getEmpIDP,
  getEmpOnJobTraining,
  getEmpListEnrollCourse,
  getMyCertificate,
  getCourseStatus,
  getSkillStatus,
  getOnJobTrainingStatus,
} from "../../../../actions/webhook";

// Component
import LeaveButtons from "./leaveButtons";
import HistoryLeaveCard from "./HistoryLeaveCard";
import RequestTimeCard from "./RequestTimeCard";
import CardCourse from "./CardCourse";
import PersonalTax from "./personalTax";
import CardSkill from "./CardSkill";
import CompetencyDashboard from "./CompetencyDashboard";
import KpiCard from "./KpiCard";
import MyIDP from "./MyIDP";
import CardOJT from "./CardOJT";
import CardCertificate from "./CardCertificate";
import CardCourseStatus from "./CardCourseStatus";
import CardSkillStatus from "./CardSkillStatus";
import CardOJTStatus from "./CardOJTStatus";

const ScrollContainer = styled("div")({
  display: "flex",
  width: "100%",
  mb: 2,
  overflow: "hidden",
  overflowX: "auto",
  scrollbarWidth: "none !important" /* For Firefox */,

  /* Hide scrollbar for Chrome, Safari, and Edge */
  "&::-webkit-scrollbar": {
    display: "none !important",
  },
});

const StyledTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    borderRadius: "40px",
    "& input": {
      paddingLeft: "16px", // Adjust the left padding
    },
    "& textarea": {
      paddingLeft: "16px", // Adjust the left padding for multiline input
    },
  },
});

const ChatBot = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState("");
  const [dateInputs, setDateInputs] = useState({});
  const [search, setSearch] = useState({});
  const [type, setType] = useState("");
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const [isLoading, setIsLoading] = useState(false);
  const [isDateInputActive, setIsDateInputActive] = useState(false);
  const [personalID, setPersonalID] = useState("");
  const [isPersonalIDInputActive, setIsPersonalIDInputActive] = useState(false);
  const [valid, setValid] = useState(true);
  const today = dayjs().toDate();
  const firstDayOfMonth = dayjs().startOf("month");
  const lastDayOfMonth = dayjs().endOf("month");

  function useScrollToBottom() {
    const bottomRef = useRef(null);

    useEffect(() => {
      const observer = new MutationObserver(() => {
        if (bottomRef.current) {
          bottomRef.current.scrollIntoView({ behavior: "smooth" });
        }
      });

      const content = document.querySelector(".content");
      if (content) {
        observer.observe(content, { childList: true, subtree: true });
      }

      return () => observer.disconnect();
    }, []);

    return bottomRef;
  }
  const bottomRef = useScrollToBottom();

  useEffect(() => {
    if (userProfile) {
      if (i18n.resolvedLanguage === "th") {
        setMessages([
          {
            text: `สวัสดีครับคุณ ${userProfile.firstname_TH} มีอะไรให้ Jarvis ช่วยไหมครับ?`,
            sender: "bot",
          },
        ]);
      } else {
        setMessages([
          {
            text: `Hello, ${userProfile.title_EN} ${userProfile.firstname_EN}. How can I assist you today?`,
            sender: "bot",
          },
        ]);
      }
    } else {
      if (i18n.resolvedLanguage === "th") {
        setMessages([
          { text: "สวัสดีครับ มีอะไรให้ Jarvis ช่วยไหมครับ?", sender: "bot" },
        ]);
      } else {
        setMessages([
          { text: "Hello, How can I assist you today?", sender: "bot" },
        ]);
      }
    }
  }, [userProfile]);

  const handleSend = async () => {
    if (currentMessage.trim()) {
      // Add user message
      handleSetMessages("Current", currentMessage, "user");
      setCurrentMessage("");
      setIsLoading(true);

      try {
        const response = await getBotResponse({
          message: currentMessage,
        });

        if (response && response.tag) {
          const tag = response.tag;
          setType(tag);
          if (
            tag === "isLateAbsent" ||
            tag === "isOvertimeShift" ||
            tag === "workingTimeRequest" ||
            tag === "overTimeRequest" ||
            tag === "compensateRequest" ||
            tag === "leaveRequest"
          ) {
            setDateInputs({
              start: {
                type: "date",
                label: "StartDate",
              },
              end: {
                type: "date",
                label: "EndDate",
              },
            });

            setSearch({
              start: firstDayOfMonth.toDate(),
              end: lastDayOfMonth.toDate(),
            });
            handleSetMessages(tag, "กรุณาระบุวันที่เริ่มต้นและสิ้นสุด", "bot");
            setIsDateInputActive(true);
          } else if (
            tag === "Payslip" ||
            tag.includes("certificate") ||
            tag.includes("Visa")
          ) {
            handleSetMessages(
              tag,
              `${t("PleaseEnterNationalIDCardNumber")}`,
              "bot"
            );
            setIsPersonalIDInputActive(true);
          } else if (tag === "Tax") {
            setDateInputs({
              month: {
                type: "date",
                label: "StartDate",
              },
            });
            handleSetMessages(tag, "กรุณาระบุเดือนที่ต้องการทราบ", "bot");
            setIsDateInputActive(true);
          } else if (tag === "isLeave") {
            handleSetMessages(tag, `${t("BotLeaveDialog")}`, "bot");
          } else if (tag === "enrollHistory") {
            const data = await getEmpHistoryEnroll();

            if (data && data.length > 0) {
              handleSetDataMessages(tag, data, "bot");
            } else {
              handleSetMessages("Error", `${t("NoData")}`, "bot");
            }
          } else if (tag === "mySkill") {
            const data = await getEmpSkillById();

            if (data && data.length > 0) {
              handleSetDataMessages(tag, data, "bot");
            } else {
              handleSetMessages("Error", `${t("NoData")}`, "bot");
            }
          } else if (tag === "myCompetency") {
            const data = await getCompetencyEvaluate();

            if (data && data.length > 0) {
              handleSetDataMessages(tag, data, "bot");
            } else {
              handleSetMessages("Error", `${t("NoData")}`, "bot");
            }
          } else if (tag === "KPI") {
            const data = await getUserKPI();

            if (data && data.kpis && data.kpis.length > 0) {
              handleSetDataMessages(tag, data.kpis, "bot");
            } else {
              handleSetMessages("Error", `${t("NoData")}`, "bot");
            }
          } else if (tag === "myIDP") {
            const data = await getEmpIDP();

            if (data && Object.keys(data).length > 0) {
              handleSetDataMessages(tag, data, "bot");
            } else {
              handleSetMessages("Error", `${t("NoData")}`, "bot");
            }
          } else if (tag === "myOJT") {
            const data = await getEmpOnJobTraining();

            if (data && data.length > 0) {
              handleSetDataMessages(tag, data, "bot");
            } else {
              handleSetMessages("Error", `${t("NoData")}`, "bot");
            }
          } else if (tag === "upcomingCourse") {
            setDateInputs({
              start: {
                type: "date",
                label: "StartDate",
              },
              end: {
                type: "date",
                label: "EndDate",
              },
            });

            setSearch({
              start: dayjs(today).format("YYYY-MM-DD"),
              end: dayjs(
                new Date(
                  today.getFullYear(),
                  today.getMonth() + 1,
                  today.getDate()
                )
              ).format("YYYY-MM-DD"),
            });
            handleSetMessages(tag, "กรุณาระบุวันที่เริ่มต้นและสิ้นสุด", "bot");
            setIsDateInputActive(true);
          } else if (tag === "myCertificate") {
            const data = await getMyCertificate();

            if (data && data.length > 0) {
              handleSetDataMessages(tag, data, "bot");
            } else {
              handleSetMessages("Error", `${t("NoData")}`, "bot");
            }
          } else if (tag === "trainingCourseRequest") {
            const temp = await getCourseStatus();
            const data = temp.filter((item) => item.approveStatus === null)

            if (data && data.length > 0) {
              handleSetDataMessages(tag, data, "bot");
            } else {
              handleSetMessages("Error", `${t("NoData")}`, "bot");
            }
          } else if (tag === "skillTestRequest") {
            const temp = await getSkillStatus();
            const CheckedSkill = new Set(['waiting', 'testing'])
            const data = temp.filter((item) => CheckedSkill.has(item.status))

            if (data && data.length > 0) {
              handleSetDataMessages(tag, data, "bot");
            } else {
              handleSetMessages("Error", `${t("NoData")}`, "bot");
            }
          } else if (tag === "ojtRequest") {
            const temp = await getOnJobTrainingStatus();
            const data = temp.filter((item) => item.isFinish === 0 && item.isApprove === null)

            if (data && data.length > 0) {
              handleSetDataMessages(tag, data, "bot");
            } else {
              handleSetMessages("Error", `${t("NoData")}`, "bot");
            }
          } else if (tag === "Document") {
            handleSetMessages(
              tag,
              response.message,
              "bot",
              response.fileUrl,
              response.fileName
            );
          } else {
            handleSetMessages("AI", response.message, "bot");
          }
        }
      } catch (error) {
        handleSetMessages("Error", `${t("ContactAdmin")}`, "bot");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSetMessages = async (
    type,
    text,
    sender,
    file = null,
    fileName = null
  ) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        type: type,
        text: text,
        sender: sender,
        file: file,
        fileName: fileName,
      },
    ]);
  };

  const handleSetDataMessages = async (type, data, sender) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      {
        type: type,
        data: data,
        sender: sender,
      },
    ]);
  };

  const handleDateInputSubmit = async () => {
    let text = "";
    let typeText = "";
    let fileBlob = null;
    let fileName = null;
    let tag = "";
    setDateInputs({});
    setIsDateInputActive(false);
    setIsLoading(true);

    if (type && type === "isLateAbsent") {
      const response = await getLateAbsent({
        start: dayjs(search.start).format("YYYY-MM-DD"),
        end: dayjs(search.end).format("YYYY-MM-DD"),
      });
      text = response.data.message;
      typeText = `ข้อมูลขาดงาน มาสาย กลับก่อน ตั้งแต่วันที่ ${dayjs(
        search.start
      ).format("DD/MM/BBBB")} ถึง ${dayjs(search.end).format("DD/MM/BBBB")}`;
      tag = "lateAbsentDetail";
    } else if (type && type === "isOvertimeShift") {
      const response = await getOvertimeShift({
        start: dayjs(search.start).format("YYYY-MM-DD"),
        end: dayjs(search.end).format("YYYY-MM-DD"),
        text: messages[messages.length - 2],
      });
      text = response.data.message;
      typeText = `ข้อมูลโอทีและค่ากะ ตั้งแต่วันที่ ${dayjs(search.start).format(
        "DD/MM/BBBB"
      )} ถึง ${dayjs(search.end).format("DD/MM/BBBB")}`;
      tag = "overtimeShift";
    } else if (type === "Payslip") {
      const payslipDetail = await dispatch(
        getPayslipByEmployee(
          dayjs(search.month)
            .set("date", dayjs(search.month).daysInMonth())
            .format("YYYY-MM-DD")
        )
      );
      typeText = `สลิปเงินเดือนของเดือน ${dayjs(search.month).format("MMMM")}`;
      tag = type;
      if (payslipDetail && payslipDetail.length > 0) {
        fileBlob = await generatePayslipPDF(payslipDetail[0], userProfile);
        text = `${t("PayslipRequestDialog")} \n`;
        fileName = `Payslip_${dayjs(search.month).format("MMMM_YYYY")}.pdf`;
      } else {
        text = `${t("NoData")}`;
      }
    } else if (type === "Tax") {
      const data = await getPersonalTaxByMonth(
        dayjs(search.month)
          .set("date", dayjs(search.month).daysInMonth())
          .format("YYYY-MM-DD"),
        userProfile.idEmployees
      );

      if (data && Object.keys(data).length > 0) {
        handleSetDataMessages(type, data, "bot");
      } else {
        handleSetMessages("Error", `${t("NoData")}`, "bot");
      }
    } else if (type === "upcomingCourse") {
      const filter = {
        approve: "approve",
        nameCourse: "",
        category1: "",
        day: {
          start: dayjs(search.start).format("YYYY-MM-DD"),
          end: dayjs(search.end).format("YYYY-MM-DD"),
        },
      };
      const data = await getEmpListEnrollCourse(filter);

      if (data && data.length > 0) {
        handleSetDataMessages(type, data, "bot");
      } else {
        handleSetMessages("Error", `${t("NoData")}`, "bot");
      }
    } else if (type.includes("Request")) {
      let data = [];
      const rangeDate = {
        start: dayjs(search.start),
        end: dayjs(search.end),
      };

      if (type === "workingTimeRequest") {
        data = await renderData("1", rangeDate);
      } else if (type === "overTimeRequest") {
        data = await renderData("2", rangeDate);
      } else if (type === "compensateRequest") {
        data = await renderData("3", rangeDate);
      } else if (type === "leaveRequest") {
        data = await renderData("leave", rangeDate);
      }

      if (data && data.length > 0) {
        handleSetDataMessages(type, data, "bot");
      } else {
        handleSetMessages("Error", `${t("NoData")}`, "bot");
      }
    }

    if (
      !type.includes("Request") &&
      type !== "Tax" &&
      type !== "upcomingCourse"
    ) {
      handleSetMessages("User", typeText, "user");
      handleSetMessages(tag, text, "bot", fileBlob, fileName);
    }

    setType("");
    setIsLoading(false);
    // setSearch({});
  };

  const handleCheckPersonID = async (event) => {
    event.preventDefault();
    let temp = personalID;
    if (temp.trim().replace(/ /g, "") === userProfile.personalID) {
      setIsPersonalIDInputActive(false);
      if (type === "Payslip") {
        setDateInputs({
          month: {
            type: "date",
            label: "StartDate",
          },
        });
        handleSetMessages(type, "กรุณาระบุเดือนที่ต้องการทราบ", "bot");
        setValid(true);
        setIsDateInputActive(true);
      } else if (
        type === "certificateEmp" ||
        type === "certificateSalary" ||
        type === "touristVisa"
      ) {
        let fileBlob = null;
        let typeText = "";
        let text = "";
        let fileName = null;

        const response = await getDocumentPDFFile({ type: type });
        console.log(response);

        if (response && response.status === 200) {
          fileBlob = bufferToPDF(response.data);
        }

        if (type === "certificateEmp") {
          typeText = "ขอหนังสือรับรองการทำงานหน่อย";
          text = "นี่คือหนังสือรับรองการทำงานของคุณครับ";
          fileName = `${t("EmploymentCertificate")}.pdf`;
        } else if (type === "certificateSalary") {
          typeText = "ขอหนังสือรับรองเงินเดือนหน่อย";
          text = "นี่คือหนังสือรับรองเงินเดือนของคุณครับ";
          fileName = `${t("IncomeCertificate")}.pdf`;
        } else {
          typeText = "ขอหนังสือ Tourist Visa หน่อย";
          text = "นี่คือหนังสือ Tourist Visa ของคุณครับ";
          fileName = `${t("TouristVisa")}.pdf`;
        }

        if (!fileBlob) {
          text = "ขออภัยกรุณาลองใหม่อีกครั้งครับ";
        }
        handleSetMessages(typeText, "user");
        handleSetMessages(text, "bot", fileBlob, fileName);
        setValid(true);
      }
    } else {
      setValid(temp.trim().replace(/ /g, "") === userProfile.personalID);
    }
  };

  const handleLeaveInfoClick = () => {
    // Mock data for leave information
    const leaveInfo = "You have taken 5 days of leave this year.";
    handleSetMessages(null, leaveInfo, "bot");
  };

  const handleLeaveQuotaClick = async () => {
    setIsLoading(true);
    const response = await getLeaveQuota({
      idEmployees: userProfile.idEmployees,
    });
    const leaveQuota = response.data.message;
    handleSetMessages(
      null,
      `นี่คือสิทธิการลาทั้งหมดของคุณในปี ${dayjs(new Date()).format("YYYY")}`,
      "bot"
    );
    handleSetMessages(null, leaveQuota, "bot");

    setIsLoading(false);
  };

  const handleLeaveHistoryClick = async () => {
    setIsLoading(true);
    const response = await getLeaveRequestAllYear({
      filter: dayjs(new Date()).format("YYYY"),
    });

    if (response && response.length > 0) {
      handleSetDataMessages("LeaveRecord", response, "bot");
    } else {
      handleSetMessages("Error", `${t("NoData")}`, "bot");
    }
    setIsLoading(false);
  };

  const renderData = async (filterRequestType, search) => {
    const requestTimeList = await getAllRequestTimeBy("id", search);
    const requestTimeWithDrawList = await getAllRequestTimeWithDrawBy(
      "id",
      search
    );
    const leaveEmployeesList = await getLeaveRequest("id", search);
    const leaveEmployeeWithdrawsList = await getAllLeaveWithDrawBy(
      "id",
      search
    );
    let tempRequestTimeList = requestTimeList ? requestTimeList : [];
    let tempLeaveEmployeesList = leaveEmployeesList ? leaveEmployeesList : [];
    let tempRequestTimeWithDrawList = requestTimeWithDrawList
      ? requestTimeWithDrawList
      : [];
    let tempLeaveEmployeeWithdrawsList = leaveEmployeeWithdrawsList
      ? leaveEmployeeWithdrawsList
      : [];
    let temp = [
      ...tempRequestTimeList,
      ...tempLeaveEmployeesList,
      ...tempRequestTimeWithDrawList,
      ...tempLeaveEmployeeWithdrawsList,
    ];

    switch (filterRequestType) {
      case "1":
        temp = requestTimeList.filter(
          (x) => x.idRequestType && x.idRequestType === 1
        );
        break;
      case "2":
        temp = requestTimeList.filter(
          (x) => x.idRequestType && x.idRequestType === 2
        );
        break;
      case "3":
        temp = requestTimeList.filter(
          (x) => x.idRequestType && x.idRequestType === 3
        );
        break;
      case "leave":
        temp = leaveEmployeesList.filter((x) => x.idLeave && x.idLeave > 0);
        break;
      default:
        break;
    }

    temp.sort(function (a, b) {
      return new Date(b.createDate) - new Date(a.createDate);
    });

    return temp;
  };

  const bufferToPDF = (buffer) => {
    const arrayBuffer =
      buffer instanceof ArrayBuffer
        ? buffer
        : buffer.buffer.slice(
            buffer.byteOffset,
            buffer.byteOffset + buffer.byteLength
          );
    return new Blob([arrayBuffer], { type: "application/pdf" });
  };

  const generatePayslipPDF = async (payslipDetail, userProfile) => {
    const canvas = await renderComponentToCanvas(PaySlipDetail, {
      currentSlip: payslipDetail,
    });
    const imgData = canvas.toDataURL("image/png");

    const pdf = await PDFDocument.create();
    const page = pdf.addPage();

    let companyLogo = await fileService
      .getCompanyLogoFile(userProfile.idCompany)
      .then((res) => res.data)
      .catch(() => null);

    const pageSize = page.getSize();
    const pngImage = await pdf.embedPng(imgData);
    const pngDims = pngImage.scale(0.305);

    page.drawImage(pngImage, {
      x: 0,
      y: pageSize.height - (pngDims.height - 25),
      width: pngDims.width,
      height: pngDims.height,
    });

    if (companyLogo) {
      const pngCompanyLogo = await pdf.embedPng(companyLogo);
      const pngDimsCompanyLogo = pngCompanyLogo.scale(0.2);
      page.drawImage(pngCompanyLogo, {
        x: 30,
        y: pageSize.height - pngDimsCompanyLogo.height,
        width: pngDimsCompanyLogo.width,
        height: pngDimsCompanyLogo.height,
      });
    }

    const pdfBytes = await pdf.save();
    return new Blob([pdfBytes], { type: "application/pdf" });
  };

  const getLateAbsentDetail = async (detail) => {
    const data = detail.filter((item) => {
      const itemDate = new Date(item.date);
      return (
        itemDate >= new Date(dayjs(search.start).format("YYYY-MM-DD")) &&
        itemDate <= new Date(dayjs(search.end).format("YYYY-MM-DD")) &&
        itemDate < dayjs(new Date()).add(-1, "day")
      );
    });

    let absentDates = [];
    let lateDates = [];
    let earlyOutDates = [];

    data.forEach((item) => {
      if (item.absent) absentDates.push(item.date);
      if (item.isLate) lateDates.push(item.date);
      if (item.isEarlyOut) earlyOutDates.push(item.date);
    });

    let result = [];

    if (absentDates.length > 0) {
      result.push(
        `${t("Absent")}\n` +
          absentDates
            .map(
              (date, index) =>
                `${index + 1}) ${dayjs(date).format("DD/MM/BBBB")}`
            )
            .join("\n")
      );
    }

    if (lateDates.length > 0) {
      result.push(
        `\n${t("Late")}\n` +
          lateDates
            .map(
              (date, index) =>
                `${index + 1}) ${dayjs(date).format("DD/MM/BBBB")}`
            )
            .join("\n")
      );
    }

    if (earlyOutDates.length > 0) {
      result.push(
        `\n${t("LeaveEarly")}\n` +
          earlyOutDates
            .map(
              (date, index) =>
                `${index + 1}) ${dayjs(date).format("DD/MM/BBBB")}`
            )
            .join("\n")
      );
    }

    return result;
  };

  const submitDownload = (message) => {
    const url = URL.createObjectURL(message.file);
    // window.open(url, "_blank");
    const a = document.createElement("a");
    a.href = url;
    a.download = message.fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  const submitLateAbsentDetail = async () => {
    setIsLoading(true);
    let lateAbsentDetail = "";
    const detail = await dispatch(
      getAttendanceById({
        start:
          dayjs(search.start).add(-7, "day") < dayjs("2023-01-01")
            ? dayjs("2023-01-01")
            : dayjs(search.start).add(-7, "day"),
        end: search.end,
      })
    );

    if (detail.data && detail.data.length > 0) {
      lateAbsentDetail = await getLateAbsentDetail(detail.data);
    } else {
      lateAbsentDetail = `${t("NoData")}`;
    }
    // const detail = message.lateAbsent;
    handleSetMessages("", lateAbsentDetail, "bot");
    setIsLoading(false);
  };

  return (
    <CardStyle
      style={{
        width: "100%",
        // maxWidth: "80%",
        height: "80vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        padding: "16px",
      }}
    >
      <Box className="content">
        {messages.map((message, index) => (
          <Box key={index} className={`${message.sender}-message`}>
            {message.sender === "bot" && (
              <img
                src={Logo}
                alt="Logo"
                className="bot-logo"
                width="40"
                height="40"
              />
            )}
            {message.data ? (
              <>
                {message.type === "LeaveRecord" && (
                  <ScrollContainer>
                    {message.data.map((item) => (
                      <HistoryLeaveCard data={item} />
                    ))}
                  </ScrollContainer>
                )}
                {message.type === "enrollHistory" && (
                  <ScrollContainer>
                    {message.data.map((item) => (
                      <CardCourse data={item} />
                    ))}
                  </ScrollContainer>
                )}
                {message.type === "mySkill" && (
                  <ScrollContainer>
                    {message.data.map((item) => (
                      <CardSkill data={item} />
                    ))}
                  </ScrollContainer>
                )}
                {message.type === "myCompetency" && (
                  <div style={{ flexDirection: "column", width: "100%" }}>
                    <CompetencyDashboard evaluateList={message.data} />
                  </div>
                )}
                {message.type === "myIDP" && (
                  <div style={{ flexDirection: "column", width: "100%" }}>
                    <MyIDP data={message.data} />
                  </div>
                )}
                {message.type === "upcomingCourse" && (
                  <ScrollContainer>
                    {message.data.map((item) => (
                      <CardCourse data={item} />
                    ))}
                  </ScrollContainer>
                )}
                {message.type === "myOJT" && (
                  <ScrollContainer>
                    {message.data.map((item) => (
                      <CardOJT openDate data={item} />
                    ))}
                  </ScrollContainer>
                )}
                {message.type === "myCertificate" && (
                  <ScrollContainer>
                    {message.data.map((item) => (
                      <CardCertificate data={item} />
                    ))}
                  </ScrollContainer>
                )}
                {message.type === "trainingCourseRequest" && (
                  <div style={{ flexDirection: "column", width: "50%" }}>
                    {message.data.map((item) => (
                      <CardCourseStatus request={item} />
                    ))}
                  </div>
                )}
                {message.type === "skillTestRequest" && (
                  <div style={{ flexDirection: "column", width: "50%" }}>
                    {message.data.map((item) => (
                      <CardSkillStatus request={item} />
                    ))}
                  </div>
                )}
                {message.type === "ojtRequest" && (
                  <div style={{ flexDirection: "column", width: "50%" }}>
                    {message.data.map((item) => (
                      <CardOJTStatus request={item} />
                    ))}
                  </div>
                )}
                {message.type === "KPI" && (
                  <ScrollContainer>
                    {message.data.map((item) => (
                      <KpiCard data={item} />
                    ))}
                  </ScrollContainer>
                )}
                {message.type === "Tax" && (
                  <div style={{ flexDirection: "column", width: "100%" }}>
                    <PersonalTax data={message.data} />
                  </div>
                )}
                {(message.type === "workingTimeRequest" ||
                  message.type === "overTimeRequest" ||
                  message.type === "compensateRequest" ||
                  message.type === "leaveRequest") && (
                  <div style={{ flexDirection: "column", width: "50%" }}>
                    {message.data.map((item) => (
                      <RequestTimeCard request={item} />
                    ))}
                  </div>
                )}
              </>
            ) : (
              <>
                {message.text !== null &&
                  message.text !== undefined &&
                  message.text.length !== 0 && (
                    <Box className={`${message.sender}-bubble`}>
                      {message.text}
                      {message.type === "isLeave" && (
                        <LeaveButtons
                          onInfoClick={handleLeaveInfoClick}
                          onQuotaClick={handleLeaveQuotaClick}
                          onHistoryClick={handleLeaveHistoryClick}
                        />
                      )}
                      {message.type === "lateAbsentDetail" && (
                        <ButtonBlue
                          variant="contained"
                          style={{ width: "100%", marginTop: "4px" }}
                          onClick={() => submitLateAbsentDetail()}
                        >
                          {`${t("Descriptions")}`}
                        </ButtonBlue>
                      )}
                      {message.type === "Payslip" && message.file && (
                        <ButtonBlue
                          variant="contained"
                          style={{ width: "100%", marginTop: "16px" }}
                          onClick={() => submitDownload(message)}
                        >
                          {`${t("Download")}`}
                        </ButtonBlue>
                      )}
                      {message.type === "Document" && message.file && (
                        <ButtonBlue
                          variant="contained"
                          style={{ width: "100%", marginTop: "16px" }}
                          onClick={() => window.open(message.file, "_blank")}
                        >
                          {`${t("Download")}`}
                        </ButtonBlue>
                      )}
                    </Box>
                  )}
              </>
            )}
          </Box>
        ))}

        {isLoading && (
          <Box className="bot-message">
            <img
              src={Logo}
              alt="Logo"
              className="bot-logo"
              width="40"
              height="40"
            />
            <Box className="bot-bubble">
              <span className="thinking-dots">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </Box>
          </Box>
        )}
        {isPersonalIDInputActive && (
          <Grid item xs={12} sm={12}>
            <TextFieldTheme
              type={"password"}
              onChange={(e) => setPersonalID(e.target.value)}
              label={t("PersonalID")}
              error={!valid}
              helperText={!valid && t("InvalidData")}
              style={{ marginTop: "8px", marginBottom: "8px" }}
              fullWidth
            />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <ButtonBlue
                  variant={"outlined"}
                  onClick={() => setIsPersonalIDInputActive(false)}
                  fullWidth
                >
                  {t("Cancel")}
                </ButtonBlue>
              </Grid>
              <Grid item xs={6}>
                <ButtonBlue
                  variant={"contained"}
                  type="submit"
                  onClick={handleCheckPersonID}
                  fullWidth
                >
                  {t("Confirm")}
                </ButtonBlue>
              </Grid>
            </Grid>
          </Grid>
        )}
        {isDateInputActive && (
          <Box className="dynamic-inputs">
            {Object.entries(dateInputs).map(([key, input]) => (
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={12}>
                  {type === "Payslip" || type === "Tax" ? (
                    <DatePickerCustom
                      inputFormat="MMMM YYYY"
                      value={search[key]}
                      name="start"
                      minDate={new Date().setFullYear(
                        new Date().getFullYear() - 4
                      )}
                      maxDate={new Date()}
                      views={["year", "month"]}
                      onChange={(newValue) =>
                        setSearch((prev) => ({
                          ...prev,
                          [key]: newValue,
                        }))
                      }
                    />
                  ) : (
                    <DatePickerCustom
                      label={`${t(input.label)}`}
                      value={search[key] || input.defaultValue}
                      onChange={(newValue) =>
                        setSearch((prev) => ({
                          ...prev,
                          [key]: newValue,
                        }))
                      }
                      renderInput={(params) => (
                        <TextFieldTheme style={{ width: "100%" }} {...params} />
                      )}
                    />
                  )}
                </Grid>
              </Grid>
            ))}
            <ButtonBlue onClick={handleDateInputSubmit} variant={"contained"}>
              {`${t("Search")}`}
            </ButtonBlue>
          </Box>
        )}
        <div ref={bottomRef} />
      </Box>
      <StyledTextField
        multiline
        minRows={1}
        maxRows={4}
        variant="outlined"
        placeholder="Type your message here..."
        fullWidth
        value={currentMessage}
        onChange={(e) => setCurrentMessage(e.target.value)}
        disabled={isLoading || isDateInputActive || isPersonalIDInputActive}
        onKeyPress={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            handleSend();
          }
        }}
        InputProps={{
          style: { resize: "none" },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleSend}>
                <SendIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </CardStyle>
  );
};

export default ChatBot;
