import React from "react";
import { styled } from "@mui/material/styles";
import { useParams } from "react-router-dom";

import { Container, CardContent } from "@mui/material";

import StyledCard from "../../../shared/general/Card";

import General from "./general";
import Welfare from "./welfare";
import PerDiem from "./per-diem";
import Mileage from "./mileage";
import FlexCoin from "./flex-coin";

const StyledRoot = styled("div")({
  maxWidth: "1280px !important",
  margin: "auto",
  backgroundColor: "#FFFFFF !important",
  "& .MuiContainer-root": {
    paddingBottom: 24,
  },
  "& .head": {
    paddingTop: 8,
    marginBottom: 24,
    "& .MuiTypography-subtitle1": {
      fontWeight: 500,
    },
  },
});

const StyledCardContent = styled(CardContent)({
  padding: 24,
  "& .MuiFormControl-root": {
    marginBottom: 8,
  },
  "& .wrap-head": {
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
  },
  "& .wrap-row-sum": {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
});

const ExpenseUserEditPage = () => {
  const { typeExpense, typeEdit, idExpense } = useParams();

  return (
    <StyledRoot className="page">
      <Container maxWidth="xl">
        <StyledCard>
          <StyledCardContent>
            {typeExpense === "ServiceAndGoods" && (
              <General idExpense={idExpense} typeEdit={typeEdit} />
            )}
            {/* {typeExpense === "Welfare" && <Welfare idExpense={idExpense} typeEdit={typeEdit}/>} */}
            {typeExpense === "Allowance" && (
              <PerDiem idExpense={idExpense} typeEdit={typeEdit} />
            )}
            {typeExpense === "Mileage" && (
              <Mileage idExpense={idExpense} typeEdit={typeEdit} />
            )}
            {typeExpense === "Welfare" && (
              <Welfare idExpense={idExpense} typeEdit={typeEdit} />
            )}
            {typeExpense === "FlexCoin" && (
              <FlexCoin idExpense={idExpense} typeEdit={typeEdit} />
            )}
          </StyledCardContent>
        </StyledCard>
      </Container>
    </StyledRoot>
  );
};

export default ExpenseUserEditPage;
