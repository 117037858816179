import React, { useState, useEffect, Fragment } from "react";
import { IconButton, Grid, styled, Typography, Box, Paper, Divider, List, Stack } from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import EditIcon from '@mui/icons-material/Edit';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';


function formatNumberWithCommas(number) {
    if (number) {
        const floatNumber = parseFloat(number);
        const fixedNumber = floatNumber.toFixed(2);
        const formattedNumber = fixedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        return formattedNumber;
    } else {
        return "0.00";
    }
};

const BudgetItem = (props) => {
    const { label, value, isEditing, onEdit, onChange, onSave, inputValue, editable, onClose } = props;

    return (
        <Grid item container xs={12} justifyContent="space-between">
            <Grid item>
                <Typography fontSize={16}>{label}</Typography>
            </Grid>
            <Grid item>
                {!isEditing ? (
                    <Stack direction={"row"} justifyContent={"space-evenly"} alignItems={"center"}>
                        <Typography fontSize={16} fontWeight={500}>{formatNumberWithCommas(value)}</Typography>
                        {!editable && (
                            <IconButton onClick={onEdit}>
                                <EditIcon sx={{ width: 20, height: 20, color: "#46CBE2" }} />
                            </IconButton>
                        )}
                    </Stack>
                ) : (
                    <Stack direction={"row"} justifyContent={"space-evenly"} alignItems={"center"}>
                        <TextFieldTheme variant={"outlined"} type={"number"} value={inputValue} onChange={(e) => onChange(e.target.value)} />
                        {inputValue && (
                            <IconButton onClick={() => onSave(inputValue)} disabled={!inputValue}>
                                <SendIcon sx={{ width: 20, height: 20, color: inputValue ? "#46CBE2" : "grey" }} />
                            </IconButton>
                        )}
                        <IconButton onClick={onClose}>
                            <HighlightOffIcon sx={{ width: 20, height: 20, color: "red" }} />
                        </IconButton>
                    </Stack>
                )}
            </Grid>
        </Grid>
    );
};

const InfoRow = ({ label, value, valueWeight = 500 }) => {
    return (
        <Grid item container xs={12} justifyContent="space-between">
            <Grid item>
                <Typography fontSize={16}>{label}</Typography>
            </Grid>
            <Grid item>
                <Stack direction={"row"} justifyContent={"space-evenly"} alignItems={"center"}>
                    <Typography fontSize={16} fontWeight={valueWeight}>{formatNumberWithCommas(value)}</Typography>
                </Stack>
            </Grid>
        </Grid>
    );
};

export { BudgetItem, InfoRow };