import React, { useState } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import { Grid, Typography, Divider, Chip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import DialogEdit from "./DialogEdit";
import dayjs from "dayjs";
//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledDivider = styled(Divider)({
  marginTop: 16,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const Probation = () => {
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { t, i18n } = useTranslation();
  const thaiLanguage = i18n.resolvedLanguage === "th"
  const [drawerConfig, setDrawerConfig] = useState({
    isOpen: false,
    isEdit: false,
    data: {},
  });

  const onDrawerClose = () => {
    setDrawerConfig((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  return (
    <div>
      {employeeProfile && employeeProfile.probation.map((item, index) => (
        <Grid container spacing={2} key={index}>
          <Grid item xs={12} container alignItems="center">
            <Typography flexGrow="1" fontSize="16px" fontWeight="600">
              {`${t("ProbationResult")} ${item.probationRound}`}
            </Typography>
            {!userProfile.readOnly && item.idProbation && (
              <ButtonBlue
                variant="text"
                size="small"
                startIcon={<EditIcon />}
                onClick={() => {
                  setDrawerConfig((prev) => ({
                    ...prev,
                    isOpen: true,
                    idProbation: item.idProbation,
                  }));
                }}
              >
                {t("EditData")}
              </ButtonBlue>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledHeadLabel color="text.secondary">
              {t("StartDate")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {dayjs(item.start).format(thaiLanguage ? "DD MMMM BBBB" : "DD MMMM YYYY")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledHeadLabel color="text.secondary">
              {t("EndDate")}
            </StyledHeadLabel>
            <Typography variant="h6">
              {dayjs(item.end).format(thaiLanguage ? "DD MMMM BBBB" : "DD MMMM YYYY")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <StyledHeadLabel color="text.secondary">
              {t("ช่วงเวลาที่เปิดประเมิน")}
            </StyledHeadLabel>
            <Typography fontSize="14px">
              {`${dayjs(item.end)
                .subtract(30, "day")
                .format(thaiLanguage ? "DD MMMM BBBB" : "DD MMMM YYYY")} -
                ${dayjs(item.end)
                .add(30, "day")
                .format(thaiLanguage ? "DD MMMM BBBB" : "DD MMMM YYYY")}`}
            </Typography>
          </Grid>
          <Grid item xs={12} display="flex" alignItems="center" gap="8px">
            <StyledHeadLabel color="text.secondary">
              {t("ProbationResult")}
            </StyledHeadLabel>
            {dayjs(employeeProfile.resignDate).isBefore(item.end) ? (
              <Chip label={t("Terminated")} color="error" />
            ) : item.result === 1 ? (
              <Chip label={t("Pass")} color="success" />
            ) : item.result === 0 ? (
              <Chip label={t("NotPass")} color="error" />
            ) : (
              <Chip label={t("WaitingForEvaluation")} color="warning" />
            )}
          </Grid>
          {item.result !== null && (
            <Grid item xs={12}>
              <Typography fontSize="14px" color="text.secondary">
                {`${t("Assessor")} : ${
                  item.approvedBy
                    ? thaiLanguage
                      ? `${item.approvedBy} (${item.positionName})`
                      : `${item.approvedBy_EN} (${item.positionName_EN})`
                    : "ประเมินโดยระบบ"
                }`}
              </Typography>
              {item.approveDate && item.approvedBy && (
                <Typography fontSize="14px" color="text.secondary">
                  {dayjs(item.approveDate).format(
                    thaiLanguage
                      ? "D MMMM BBBB HH:mm:ss"
                      : "D MMMM YYYY HH:mm:ss"
                  )}
                </Typography>
              )}
            </Grid>
          )}
          {employeeProfile.probation.length !== index + 1 && (
            <Grid item xs={12}>
              <StyledDivider />
            </Grid>
          )}
        </Grid>
      ))}
      {drawerConfig.isOpen && (
        <DialogEdit drawerConfig={drawerConfig} handleCloseDrawer={onDrawerClose} />
      )}
    </div>
  );
};

export default Probation;
