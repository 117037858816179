import {
  REQUESTTIME_FETCHING,
  REQUESTTIME_SUCCESS,
  REQUESTTIME_FAILED,
  REQUESTTIME_WITHDRAW_FETCHING,
  REQUESTTIME_WITHDRAW_SUCCESS,
  REQUESTTIME_WITHDRAW_FAILED,
  OVERTIME_36HOURS_FETCHING,
  OVERTIME_36HOURS_SUCCESS,
  OVERTIME_36HOURS_FAILED,
  OVERTIME_36HOURS_DETAIL_FETCHING,
  OVERTIME_36HOURS_DETAIL_SUCCESS,
  OVERTIME_36HOURS_DETAIL_FAILED
} from "./types";

import RequestTimeService from "../services/requestTime.service";

export const getRequestReason = (idCompany) => async (dispatch) => {
  try {
    const res = await RequestTimeService.getRequestReason(idCompany);
    if (res) {
      return res.data;
    }
  } catch (err) {
    console.log(err);
  }
};

export const getAllRequestTimeById = (search) => async (dispatch) => {
  try {
    const res = await RequestTimeService.getAllRequestTimeById(search);
    if (res) {
      dispatch({
        type: REQUESTTIME_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: REQUESTTIME_FAILED,
    });
    console.log(err);
  }
};

export const getAllRequestTimeBy = (filter, search, idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: REQUESTTIME_FETCHING,
    });
    const res = await RequestTimeService.getAllRequestTimeBy(filter, search, idCompany);
    if (res) {
      dispatch({
        type: REQUESTTIME_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: REQUESTTIME_FAILED,
    });
    console.log(err);
  }
};

export const getAllRequestTimeByManager = (query) => async (dispatch) => {
  try {
    dispatch({
      type: REQUESTTIME_FETCHING,
    });
    const res = await RequestTimeService.getAllRequestTimeByManager(query);
    if (res) {
      dispatch({
        type: REQUESTTIME_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: REQUESTTIME_FAILED,
    });
    console.log(err);
  }
};

export const getAllRequestTimeWithDrawByManager = (query) => async (dispatch) => {
  try {
    dispatch({
      type: REQUESTTIME_WITHDRAW_FETCHING,
    });
    const res = await RequestTimeService.getAllRequestTimeWithDrawByManager(query);
    if (res) {
      dispatch({
        type: REQUESTTIME_WITHDRAW_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: REQUESTTIME_WITHDRAW_FAILED,
    });
    console.log(err);
  }
};

export const getAllRequestTime = (query) => async (dispatch) => {
  try {
    dispatch({
      type: REQUESTTIME_FETCHING,
    });
    const res = await RequestTimeService.getAllRequestTime(query);
    if (res) {
      dispatch({
        type: REQUESTTIME_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: REQUESTTIME_FAILED,
    });
    console.log(err);
  }
};

export const getAllRequestOTBy = (filter, search) => async (dispatch) => {
  try {
    dispatch({
      type: REQUESTTIME_FETCHING,
    });
    const res = await RequestTimeService.getAllRequestOTBy(filter, search);
    if (res) {
      dispatch({
        type: REQUESTTIME_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: REQUESTTIME_FAILED,
    });
    console.log(err);
  }
};

export const getAllRequestTimeCompany =
  (filter, search) => async (dispatch) => {
    try {
      const res = await RequestTimeService.getAllRequestTimeCompany(
        filter,
        search
      );
      if (res) {
        dispatch({
          type: REQUESTTIME_SUCCESS,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch({
        type: REQUESTTIME_FAILED,
      });
      console.log(err);
    }
  };

export const putRequestTime = (formData, idRequestTime) => async () => {
  try {
    const res = await RequestTimeService.putRequestTime(formData, idRequestTime);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const addRequestTime = (formData) => async () => {
  try {
    const res = await RequestTimeService.addRequestTime(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const approveRequestTime = (filter, formData) => async () => {
  try {
    const res = await RequestTimeService.approveRequestTime(filter, formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const getAllRequestTimeWithDrawBy =
  (filter, search, idCompany) => async (dispatch) => {
    try {
      dispatch({
        type: REQUESTTIME_WITHDRAW_FETCHING,
      });
      const res = await RequestTimeService.getAllRequestTimeWithDrawBy(
        filter,
        search,
        idCompany
      );
      if (res) {
        console.log("getAllRequestTimeWithDrawBy:", res.data);
        dispatch({
          type: REQUESTTIME_WITHDRAW_SUCCESS,
          payload: res.data,
        });
      }
    } catch (err) {
      dispatch({
        type: REQUESTTIME_WITHDRAW_FAILED,
      });
      console.log(err);
    }
  };

export const getAllRequestTimeWithDraw = (query) => async (dispatch) => {
  try {
    dispatch({
      type: REQUESTTIME_WITHDRAW_FETCHING,
    });
    const res = await RequestTimeService.getAllRequestTimeWithDraw(query);
    if (res) {
      dispatch({
        type: REQUESTTIME_WITHDRAW_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: REQUESTTIME_WITHDRAW_FAILED,
    });
    console.log(err);
  }
};

export const withdrawRequestTime = (formData) => async () => {
  try {
    const res = await RequestTimeService.withdrawRequestTime(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const addRequestReason = (data) => async () => {
  try {
    const res = await RequestTimeService.addRequestReason(data);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const updateRequestReason = (data) => async () => {
  try {
    const res = await RequestTimeService.updateRequestReason(data);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const deleteRequestReason = (idRequestReason) => async () => {
  try {
    const res = await RequestTimeService.deleteRequestReason(idRequestReason);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const deleteRequestTime = (idRequestTime) => async () => {
  try {
    const res = await RequestTimeService.deleteRequestTime(idRequestTime);
    if (res) {
      return res;
    }
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.name) ||
      error.name ||
      error.toString();
    return "Error";
  }
};

export const getOvertime36Hours = (searchDate) => async (dispatch) => {
  try {
    dispatch({
      type: OVERTIME_36HOURS_FETCHING,
    });
    const res = await RequestTimeService.getOvertime36Hours(searchDate);
    if (res) {
      dispatch({
        type: OVERTIME_36HOURS_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: OVERTIME_36HOURS_FAILED,
    });
    console.log(err);
  }
};

export const getOvertime36HoursDetail = (query) => async (dispatch) => {
  try {
    dispatch({
      type: OVERTIME_36HOURS_DETAIL_FETCHING,
    });
    const res = await RequestTimeService.getOvertime36HoursDetail(query);
    if (res) {
      dispatch({
        type: OVERTIME_36HOURS_DETAIL_SUCCESS,
        payload: res.data,
      });
    }
  } catch (err) {
    dispatch({
      type: OVERTIME_36HOURS_DETAIL_FAILED,
    });
    console.log(err);
  }
};

export const getNumberOfEachPendingList = async (query) => {
  try {
    const res = await RequestTimeService.getNumberOfEachPendingList(query);
    if (res) {
      return res;
    }
  } catch (err) {
    console.log(err);
  }
};

export const getNumberOfEachPendingListManager = async (query) => {
  try {
    const res = await RequestTimeService.getNumberOfEachPendingListManager(query);
    if (res) {
      return res;
    }
  } catch (err) {
    console.log(err);
  }
};