import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Typography,
  styled,
  Card,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import CardStyle from "../../../shared/general/Card";
import { useDispatch, useSelector } from "react-redux";
import { getMyIndividualObjective } from "../../../../../actions/okrs";
import { Link } from "react-router-dom";
import Loading from "../../../shared/loading";
import { Search } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

const StyledRoot = styled(Box)({
  "& .objective-card": {
    padding: "16px",
  },
  "& .MuiFilledInput-root input": {
    padding: "16px 12px 16px 12px",
  },
});

const StyledCard = styled(Card)({
  minHeight: "70px",
  boxShadow: "none",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  background:
    "linear-gradient(to right, rgba(104, 205, 232, 0.7), rgba(109, 238, 227, 0.7))",

  "& .cardTitle": {
    fontSize: 22,
  },
  "& .MuiCardContent-root": {
    padding: 24,
  },
  "& .cardHeader": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
});

const StyleHeadBox = styled(Box)({
  "& .box-header": {
    minHeight: "200px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor:
      "linear-gradient(to right, rgba(104, 205, 232, 0.7), rgba(109, 238, 227, 0.7))",

    "& .text-header": {
      fontSize: "48px",
      fontWeight: "bold",
      marginLeft: "40px",
      justifyContent: "flex-start",
      alignItems: "flex-end",
      marginTop: "170px",
    },
    "& .img-header": {
      width: "25%",
      height: "auto",
      marginBottom: "10px",
      justifyContent: "flex-end",
      alignItems: "flex-end",
    },
  },

  "@media (max-width: 600px)": {
    "& .box-header": {
      flexDirection: "column",
      minHeight: "unset",
      justifyContent: "center",
      alignItems: "center",
      "& .text-header": {
        fontSize: "36px",
        marginLeft: 0,
        marginTop: "20px",
      },
      "& .img-header": {
        width: "50%",
        marginBottom: "20px",
      },
    },
  },
});

const ResponsiveButtonBlue = styled(Button)(({ theme }) => ({
  backgroundColor: "rgba(219, 65, 120, 1)",
  width: "30%",
  height: "50px",
  fontSize: "15px",
  fontWeight: "bold",
  borderRadius: "7px",
  "&:hover": {
    backgroundColor: "#C53B6C",
  },
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    fontSize: "14px",
    height: "40px",
  },
}));

const CustomButton = styled(Button)({
  color: "#DB4178",
  border: "2px solid #DB4178",
  backgroundColor: "transparent",
  borderRadius: "7px",
});

const ObjectiveCard = (props) => {
  const { data } = props;
  const { t, i18n } = useTranslation();

  return (
    <CardStyle style={{ height: "100%" }}>
      <Box padding="24px">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography fontSize="24px">
            {`${dayjs()
              .set("year", data.year)
              .format(i18n.resolvedLanguage === "th" ? "BBBB" : "YYYY")}, Q${
              data.quarter
            }`}
          </Typography>
          <CustomButton
            component={Link}
            to={`/okrs/individual-planning/information/${data.idIndividualObjective}`}
            startIcon={<Search />}
          >
            {`${t("Descriptions")}`}
          </CustomButton>
        </Box>
        <Typography fontSize="20px" paddingTop="16px">
          {data.objectiveName}
        </Typography>
        <Typography fontSize="14px" paddingTop="16px">
          <span style={{ color: "#919eab", paddingRight: "8px" }}>{`${t(
            "TheResultingOutcome"
          )}`}</span>
          {data.impactName}
        </Typography>
        {data.description && (
          <Typography fontSize="14px" paddingTop="16px" whiteSpace="pre-line">
            <span style={{ color: "#919eab", paddingRight: "8px" }}>{`${t(
              "Descriptions"
            )}`}</span>
            {data.description}
          </Typography>
        )}
        <Typography fontSize="14px" paddingTop="16px" textAlign="right">
          <span style={{ color: "#919eab", paddingRight: "8px" }}>{`${t(
            "TheMainResult"
          )}`}</span>
          {data.keyResults.length} {`${t("List")}`}
        </Typography>
      </Box>
    </CardStyle>
  );
};

const IndividualPlanningPage = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: IndividualObjective, isFetching } = useSelector(
    (state) => state.individualObjective
  );

  const [IndividualObjectiveFilter, setIndividualObjectiveFilter] = useState(
    []
  );

  const [selectYear, setSelectYear] = useState(dayjs().get("year"));
  const handleChange = (event) => {
    setSelectYear(event.target.value);
  };

  useEffect(() => {
    let isMounted = true;
    dispatch(getMyIndividualObjective()).then((res) => {
      if (isMounted) {
        if (res.status === 200) {
          setIndividualObjectiveFilter(res.data);
        }
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  const onSearchChange = (event) => {
    const filter = IndividualObjective.filter((o) =>
      o.objectiveName.toLowerCase().includes(event.target.value)
    );
    setIndividualObjectiveFilter(filter);
  };

  return (
    <StyledRoot className="page">
      <Container sx={{ paddingTop: "40px" }}>
        <StyledCard
          style={{ marginBottom: "40px" }}
        >
          <StyleHeadBox>
            <Box className="box-header">
              <Typography className="text-header">{`${t(
                "PersonalGoals"
              )}`}</Typography>
              <img
                className="img-header"
                src={`${process.env.PUBLIC_URL}/assets/okrs/user/personal-goal.png`}
                alt="icon"
              />
            </Box>
          </StyleHeadBox>
        </StyledCard>

        <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={"24px"}>
          <FormControl fullWidth style={{ maxWidth: "160px" }}>
            <InputLabel>{t("SelectYear")}</InputLabel>
            <Select
              label={t("SelectYear")}
              value={selectYear}
              onChange={handleChange}
              sx={{ borderRadius: "10px", backgroundColor: "#ffffff" }}
            >
              {[0, 1, 2].map((item) => (
                <MenuItem key={item} value={dayjs().get("year") - item}>
                  {dayjs()
                    .subtract(item, "year")
                    .format(i18n.resolvedLanguage === "th" ? "BBBB" : "YYYY")}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <ResponsiveButtonBlue
            variant="contained"
            startIcon={<AddIcon />}
            component={Link}
            to="/okrs/individual-planning/form"
          >
            {`${t("AddPersonalPlan")}`}
          </ResponsiveButtonBlue>
        </Box>

        {!IndividualObjective && isFetching ? (
          <Fragment>
            <Box display="flex" justifyContent="center" paddingTop="24px">
              <Loading />
            </Box>
          </Fragment>
        ) : (
          <Box marginBottom={"24px"}>
            {IndividualObjective && IndividualObjectiveFilter.length > 0 ? (
              <Stack gap={"16px"}>
                {IndividualObjectiveFilter
                  .filter((item) => item.year === selectYear)
                  .map((objective, index) => (
                    <ObjectiveCard key={index} data={objective} />
                  ))}
              </Stack>
            ) : (
              <Box display="flex" justifyContent="center" paddingTop="24px">
                <Typography>{`${t("NoItems")}`}</Typography>
              </Box>
            )}
          </Box>
        )}
      </Container>
    </StyledRoot>
  );
};

export default IndividualPlanningPage;
