import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import dayjs from "dayjs";
import Notification from "../../../shared/general/Notification";
import "./ManagerTeamApprove.css";
import {
  Button,
  ButtonGroup,
  Grid,
  Typography,
  Avatar,
  Container,
  Box,
} from "@mui/material";
import { styled } from "@mui/styles";
import { useSelector, useDispatch } from "react-redux";
import { getUserProfile } from "../../../../../actions/user";
import { putApprovalTeamPlan } from "../../../../../actions/TeamPlans";
import { useTranslation } from "react-i18next";

import makeStyles from "@mui/styles/makeStyles";
import withStyles from "@mui/styles/withStyles";

import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

//icon
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CircleIcon from "@mui/icons-material/Circle";

const ContainerStyled = styled(Container)({
  marginTop: "100px",
});

const useStyles = makeStyles(() => ({
  dateRange: {
    marginLeft: 8,
    marginRight: 8,
  },
  accordionKR: {
    marginLeft: 48,
    marginRight: 48,
  },
  expandIcon: {
    border: "1px solid rgba(0, 0, 0, .125)",
    borderRadius: "50%",
    fontSize: "25px",
  },
  circle: {
    color: "#bcbcbc",
  },
  notSelectedQ: {
    backgroundColor: "#bcbcbc",
    width: 16,
    height: 16,
  },
  selectedQ: {
    // backgroundColor : '#3f51b5',
    width: 50,
    height: 50,
  },
  selectedIcon: {
    fontSize: 28,
  },
  mainData: {
    fontSize: 19,
  },
  data: {
    fontSize: 21,
    fontWeight: "bold",
  },
}));

const Accordion = withStyles({
  root: {
    backgroundColor: "#fff",
    border: "1px solid rgba(0, 0, 0, .125)",
    borderRadius: "5px",
    boxShadow: "none",
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
    "&$expanded": {
      margin: "auto",
    },
  },
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: "40px 16px 40px 16px",
    display: "block",
    backgroundColor: "#fafafa",
    borderLeft: "5px solid #4f65df",
    "& .MuiTypography-h6": {
      fontSize: "1.1rem",
      color: "#222f3e",
    },
  },
}))(MuiAccordionDetails);

function ManagerTeamApprove() {
  const {t,i18n} = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const [values, setValues] = useState(location.state.teamList);
  const [department, setDepartment] = useState("HR TPE");
  const { handleSubmit } = useForm();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const onPutApprovalTeamPlan = async (id, formData) => {
    await dispatch(putApprovalTeamPlan(id, formData));
    history.push(`/approval`);
  };

  const onSubmit = () => {
    const formData = {
      planTeamId: values.planTeamId,
      krApprove1: values.krApprove1 ? values.krApprove1 : null,
      kR1Status: values.kR1Status,
      krApprove2: values.krApprove2 ? values.krApprove2 : null,
      kR2Status: values.kR2Status,
      krApprove3: values.krApprove3 ? values.krApprove3 : null,
      kR3Status: values.kR3Status,
      approvedByEmpId: userProfile.idEmployees,
      planStatus: "Approved",
      dateApproved: dayjs(new Date()).format("YYYY-MM-DD"),
    };

    onPutApprovalTeamPlan(formData.planTeamId, formData);
  };

  useEffect(() => {
    if (currentUser) {
      dispatch(getUserProfile(currentUser.username));
    }
  }, []);

  return (
    <ContainerStyled>
      <div className="card-section">
        <div className="header-section">
          <Grid container>
            <Grid item>
              <h3 className="header-topic">Manager Approval</h3>
            </Grid>
          </Grid>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
          <Grid container justifyContent="center" alignItems="center">
            {["Q1", "Q2", "Q3", "Q4"].map((value) => {
              return (
                <Grid
                  item
                  xl={2}
                  md={2}
                  xs={2}
                  style={{ textAlign: "-webkit-center" }}
                >
                  {values.quater == value ? (
                    <Avatar
                      sx={{ backgroundColor: "primary.main" }}
                      className={classes.selectedQ}
                    >
                      <LocationOnIcon className={classes.selectedIcon} />
                    </Avatar>
                  ) : (
                    <Avatar className={classes.notSelectedQ}>
                      <CircleIcon className={classes.circle} />
                    </Avatar>
                  )}
                  <Typography className={classes.spaceFromLocationToQ}>
                    {value}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
          <br />

          {/* ข้อมูล objective และ impact */}
          <Grid container justifyContent="center" sx={{ marginBottom: 2 }}>
            <Grid item xs={10} sm={3} md={2} lg={2} xl={2}>
              <Typography className={classes.data}>Objective</Typography>
            </Grid>
            <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
              <Typography className={classes.mainData}>
                {values.objectiveName}
              </Typography>
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <Grid item xs={10} sm={3} md={2} lg={2} xl={2}>
              <Typography className={classes.data}>Impact</Typography>
            </Grid>
            <Grid item xs={10} sm={7} md={7} lg={6} xl={6}>
              <Typography className={classes.mainData}>
                {values.impactName}
              </Typography>
            </Grid>
          </Grid>
          <hr className="hrTag" />
          <div className={classes.accordionKR}>
            <Accordion
              square
              expanded={expanded === "kr1"}
              onChange={handleChange("kr1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography className={classes.data}>{t("KeyResult")} 1</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid
                  container
                  justifyContent="center"
                  sx={{ marginBottom: 2 }}
                >
                  <Grid container item xs={10} md={2} lg={2}>
                    <Typography variant="h6" className="keyResult">
                      {t("KeyResult")} 1
                    </Typography>
                  </Grid>
                  <Grid item md={1} lg={1}></Grid>
                  <Grid container item xs={10} md={8} lg={8}>
                    <Typography variant="subtitle1">
                      {values.keyResult1}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="center"
                  sx={{ marginBottom: 2 }}
                >
                  <Grid container item xs={10} md={2} lg={2}>
                    <Typography variant="h6" className="keyResult">
                      Description
                    </Typography>
                  </Grid>
                  <Grid item md={1} lg={1}></Grid>
                  <Grid container item xs={10} md={8} lg={8}>
                    <Typography variant="subtitle1">
                      {values.description1}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="center"
                  sx={{ marginBottom: 2 }}
                >
                  <Grid container item xs={10} md={2} lg={2}>
                    <Typography variant="h6" className="keyResult">
                      Tactics
                    </Typography>
                  </Grid>
                  <Grid item md={1} lg={1}></Grid>
                  <Grid container item xs={10} md={8} lg={8}>
                    <Typography variant="subtitle1">
                      {values.tactics1}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="center"
                  sx={{ marginBottom: 2 }}
                >
                  <Grid container item xs={10} md={2} lg={2}>
                    <Typography variant="h6" className="keyResult">
                      Cross Team
                    </Typography>
                  </Grid>
                  <Grid item md={1} lg={1}></Grid>
                  <Grid container item xs={10} md={8} lg={8}>
                    <Typography variant="subtitle1">
                      {values.crossTeam1}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="center"
                  sx={{ marginBottom: 2 }}
                >
                  <Grid container item xs={10} md={2} lg={2}>
                    <Typography variant="h6" className="keyResult">
                      Measure Evidence
                    </Typography>
                  </Grid>
                  <Grid item md={1} lg={1}></Grid>
                  <Grid container item xs={10} md={8} lg={8}>
                    <Typography variant="subtitle1">
                      {values.measureEvidence1}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="center"
                  sx={{ marginBottom: 2 }}
                >
                  <Grid container item xs={10} md={2} lg={2}>
                    <Typography variant="h6" className="keyResult">
                      Commit Quality
                    </Typography>
                  </Grid>
                  <Grid item md={1} lg={1}></Grid>
                  <Grid container item xs={10} md={8} lg={8}>
                    <Typography variant="subtitle1">
                      {values.commitQuality1}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="center"
                  sx={{ marginBottom: 2 }}
                >
                  <Grid container item xs={10} md={2} lg={2}>
                    <Typography variant="h6" className="keyResult">
                      Type
                    </Typography>
                  </Grid>
                  <Grid item md={1} lg={1}></Grid>
                  <Grid container item xs={10} md={8} lg={8}>
                    <Typography variant="subtitle1">
                      {values.krType1}
                    </Typography>
                  </Grid>
                </Grid>
                {values.nameCrossTeam1 == department ? (
                  <div>
                    <hr className="hrTag" />
                    <Grid container justifyContent="center">
                      <Grid item>
                        <Typography variant="h6">Approval</Typography>
                      </Grid>
                    </Grid>
                    <Grid container item justifyContent="center">
                      <Button
                        variant="outlined"
                        className={
                          values.krApprove1 == true
                            ? "approved_contained"
                            : "approved_outlined"
                        }
                        startIcon={<CheckCircleIcon />}
                        onClick={() => {
                          setValues({
                            ...values,
                            krApprove1: true,
                            kR1Status: "Approved",
                          });
                        }}
                      >
                        Approved
                      </Button>
                      <Button
                        variant="outlined"
                        className={
                          values.krApprove1 == false
                            ? "notApproved_contained"
                            : "notApproved_outlined"
                        }
                        startIcon={<CancelIcon />}
                        onClick={() => {
                          setValues({
                            ...values,
                            krApprove1: false,
                            kR1Status: "Not Approved",
                          });
                        }}
                      >
                        Not Approved
                      </Button>
                    </Grid>
                  </div>
                ) : (
                  <></>
                )}
              </AccordionDetails>
            </Accordion>
            {values.keyResult2 && (
              <Accordion square>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <Typography className={classes.data}>{t("KeyResult")} 2</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    container
                    justifyContent="center"
                    sx={{ marginBottom: 2 }}
                  >
                    <Grid container item xs={10} md={2} lg={2}>
                      <Typography variant="h6" className="keyResult">
                        {t("KeyResult")} 2
                      </Typography>
                    </Grid>
                    <Grid item md={1} lg={1}></Grid>
                    <Grid container item xs={10} md={8} lg={8}>
                      <Typography variant="subtitle1">
                        {values.keyResult2}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="center"
                    sx={{ marginBottom: 2 }}
                  >
                    <Grid container item xs={10} md={2} lg={2}>
                      <Typography variant="h6" className="keyResult">
                        Description
                      </Typography>
                    </Grid>
                    <Grid item md={1} lg={1}></Grid>
                    <Grid container item xs={10} md={8} lg={8}>
                      <Typography variant="subtitle1">
                        {values.description2}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="center"
                    sx={{ marginBottom: 2 }}
                  >
                    <Grid container item xs={10} md={2} lg={2}>
                      <Typography variant="h6" className="keyResult">
                        Tactics
                      </Typography>
                    </Grid>
                    <Grid item md={1} lg={1}></Grid>
                    <Grid container item xs={10} md={8} lg={8}>
                      <Typography variant="subtitle1">
                        {values.tactics2}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="center"
                    sx={{ marginBottom: 2 }}
                  >
                    <Grid container item xs={10} md={2} lg={2}>
                      <Typography variant="h6" className="keyResult">
                        Cross Team
                      </Typography>
                    </Grid>
                    <Grid item md={1} lg={1}></Grid>
                    <Grid container item xs={10} md={8} lg={8}>
                      <Typography variant="subtitle1">
                        {values.crossTeam2}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="center"
                    sx={{ marginBottom: 2 }}
                  >
                    <Grid container item xs={10} md={2} lg={2}>
                      <Typography variant="h6" className="keyResult">
                        Measure Evidence
                      </Typography>
                    </Grid>
                    <Grid item md={1} lg={1}></Grid>
                    <Grid container item xs={10} md={8} lg={8}>
                      <Typography variant="subtitle1">
                        {values.measureEvidence2}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="center"
                    sx={{ marginBottom: 2 }}
                  >
                    <Grid container item xs={10} md={2} lg={2}>
                      <Typography variant="h6" className="keyResult">
                        Commit Quality
                      </Typography>
                    </Grid>
                    <Grid item md={1} lg={1}></Grid>
                    <Grid container item xs={10} md={8} lg={8}>
                      <Typography variant="subtitle1">
                        {values.commitQuality2}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="center"
                    sx={{ marginBottom: 2 }}
                  >
                    <Grid container item xs={10} md={2} lg={2}>
                      <Typography variant="h6" className="keyResult">
                        Type
                      </Typography>
                    </Grid>
                    <Grid item md={1} lg={1}></Grid>
                    <Grid container item xs={10} md={8} lg={8}>
                      <Typography variant="subtitle1">
                        {values.krType2}
                      </Typography>
                    </Grid>
                  </Grid>
                  {values.nameCrossTeam2 == department ? (
                    <div>
                      <hr className="hrTag" />
                      <Grid container justifyContent="center">
                        <Grid item>
                          <Typography variant="h6">Approval</Typography>
                        </Grid>
                      </Grid>
                      <Grid container item justifyContent="center">
                        <Button
                          variant="outlined"
                          className={
                            values.krApprove2 == true
                              ? "approved_contained"
                              : "approved_outlined"
                          }
                          startIcon={<CheckCircleIcon />}
                          onClick={() => {
                            setValues({
                              ...values,
                              krApprove2: true,
                              kR2Status: "Approved",
                            });
                          }}
                        >
                          Approved
                        </Button>
                        <Button
                          variant="outlined"
                          className={
                            values.krApprove2 == false
                              ? "notApproved_contained"
                              : "notApproved_outlined"
                          }
                          startIcon={<CancelIcon />}
                          onClick={() => {
                            setValues({
                              ...values,
                              krApprove2: false,
                              kR2Status: "Not Approved",
                            });
                          }}
                        >
                          Not Approved
                        </Button>
                      </Grid>
                    </div>
                  ) : (
                    <></>
                  )}
                </AccordionDetails>
              </Accordion>
            )}
            {values.keyResult3 && (
              <Accordion square>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className={classes.expandIcon} />}
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <Typography className={classes.data}>{t("KeyResult")} 3</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid
                    container
                    justifyContent="center"
                    sx={{ marginBottom: 2 }}
                  >
                    <Grid container item xs={10} md={2} lg={2}>
                      <Typography variant="h6" className="keyResult">
                        {t("KeyResult")} 3
                      </Typography>
                    </Grid>
                    <Grid item md={1} lg={1}></Grid>
                    <Grid container item xs={10} md={8} lg={8}>
                      <Typography variant="subtitle1">
                        {values.keyResult3}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="center"
                    sx={{ marginBottom: 2 }}
                  >
                    <Grid container item xs={10} md={2} lg={2}>
                      <Typography variant="h6" className="keyResult">
                        Description
                      </Typography>
                    </Grid>
                    <Grid item md={1} lg={1}></Grid>
                    <Grid container item xs={10} md={8} lg={8}>
                      <Typography variant="subtitle1">
                        {values.description3}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="center"
                    sx={{ marginBottom: 2 }}
                  >
                    <Grid container item xs={10} md={2} lg={2}>
                      <Typography variant="h6" className="keyResult">
                        Tactics
                      </Typography>
                    </Grid>
                    <Grid item md={1} lg={1}></Grid>
                    <Grid container item xs={10} md={8} lg={8}>
                      <Typography variant="subtitle1">
                        {values.tactics3}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="center"
                    sx={{ marginBottom: 2 }}
                  >
                    <Grid container item xs={10} md={2} lg={2}>
                      <Typography variant="h6" className="keyResult">
                        Cross Team
                      </Typography>
                    </Grid>
                    <Grid item md={1} lg={1}></Grid>
                    <Grid container item xs={10} md={8} lg={8}>
                      <Typography variant="subtitle1">
                        {values.crossTeam3}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="center"
                    sx={{ marginBottom: 2 }}
                  >
                    <Grid container item xs={10} md={2} lg={2}>
                      <Typography variant="h6" className="keyResult">
                        Measure Evidence
                      </Typography>
                    </Grid>
                    <Grid item md={1} lg={1}></Grid>
                    <Grid container item xs={10} md={8} lg={8}>
                      <Typography variant="subtitle1">
                        {values.measureEvidence3}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="center"
                    sx={{ marginBottom: 2 }}
                  >
                    <Grid container item xs={10} md={2} lg={2}>
                      <Typography variant="h6" className="keyResult">
                        Commit Quality
                      </Typography>
                    </Grid>
                    <Grid item md={1} lg={1}></Grid>
                    <Grid container item xs={10} md={8} lg={8}>
                      <Typography variant="subtitle1">
                        {values.commitQuality3}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    justifyContent="center"
                    sx={{ marginBottom: 2 }}
                  >
                    <Grid container item xs={10} md={2} lg={2}>
                      <Typography variant="h6" className="keyResult">
                        Type
                      </Typography>
                    </Grid>
                    <Grid item md={1} lg={1}></Grid>
                    <Grid container item xs={10} md={8} lg={8}>
                      <Typography variant="subtitle1">
                        {values.krType3}
                      </Typography>
                    </Grid>
                  </Grid>
                  {values.nameCrossTeam3 == department ? (
                    <div>
                      <hr className="hrTag" />
                      <Grid container justifyContent="center">
                        <Grid item>
                          <Typography variant="h6">Approval</Typography>
                        </Grid>
                      </Grid>
                      <Grid container item justifyContent="center">
                        <Button
                          variant="outlined"
                          className={
                            values.krApprove3 == true
                              ? "approved_contained"
                              : "approved_outlined"
                          }
                          startIcon={<CheckCircleIcon />}
                          onClick={() => {
                            setValues({
                              ...values,
                              krApprove3: true,
                              kR3Status: "Approved",
                            });
                          }}
                        >
                          Approved
                        </Button>
                        <Button
                          variant="outlined"
                          className={
                            values.krApprove3 == false
                              ? "notApproved_contained"
                              : "notApproved_outlined"
                          }
                          startIcon={<CancelIcon />}
                          onClick={() => {
                            setValues({
                              ...values,
                              krApprove3: false,
                              kR3Status: "Not Approved",
                            });
                          }}
                        >
                          Not Approved
                        </Button>
                      </Grid>
                    </div>
                  ) : (
                    <></>
                  )}
                </AccordionDetails>
              </Accordion>
            )}
          </div>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Box sx={{ marginTop: 4, marginRight: 4 }}>
                <Button
                  variant="contained"
                  color="primary"
                  className="saveButton"
                  type="submit"
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
        <Notification notify={notify} setNotify={setNotify} />
      </div>
    </ContainerStyled>
  );
}

export default ManagerTeamApprove;
