import React, { useEffect, useState } from "react"
import { Autocomplete, Box, Grid, MenuItem, Typography, styled } from "@mui/material";
import DrawerCustom from "../../shared/general/Drawer";
import ButtonBlue from "../../shared/general/ButtonBlue";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import AlertResponse from "../../shared/general/AlertResponse";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import DatePickerCustom from "../../shared/date/datePicker";
import SelectAffiliateCompany from "../../shared/general/SelectAffiliateCompany";
import { getAffiliateOrganization } from "../../../../actions/company";
import { getSummaryTimeAttendanceDepartment } from "../../../../actions/report";
import { DetailedTimeStampDepartment } from "./xlsx-export/timeStamp-department-xlsx";
import CircularProgress from '@mui/material/CircularProgress';
import LoadingGif from "../../assets/social-media.gif";

const StyledRoot = styled("div")({
    maxWidth: 550,
    padding: 24,
    "& .GridTopicInput": {
        display: "flex",
        alignItems: "center",
    },
});

const StyledFooter = styled("div")({
    padding: 16,
    display: "flex",
    justifyContent: "flex-end",
    "& .cancel": {
        marginRight: 8,
    },
});

const SummaryTimeStampDepartment = (props) => {

    const dispatch = useDispatch();
    const { open, handleClose } = props;
    const today = dayjs().toDate();
    const { t, i18n } = useTranslation();

    const { result: userProfile } = useSelector((state) => state.userProfile);
    const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);

    const [openAlert, setOpenAlert] = useState(false);
    const [alertType, setAlertType] = useState(false);
    const [SummaryTimeAttendanceData, setSummaryTimeAttendanceData] = useState(null);
    const [allLeaveTypes, setAllLeavesType] = useState(null);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [isFetching, setIsFetching] = useState(true);
    const [search, setSearch] = useState({
        start: null,
        end: null,
    });

    const handleOpenAlert = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const handleChangeAlertType = (newValue) => {
        setAlertType(newValue);
    };

    const onChangeCompany = (newValue) => {
        setSelectedCompany(newValue);
    }

    const handleSubmit = () => {
        if (SummaryTimeAttendanceData !== null) {
            DetailedTimeStampDepartment(t, SummaryTimeAttendanceData, allLeaveTypes, search.start, search.end);
        } else {
            handleChangeAlertType(t("NoData"));
            handleOpenAlert(true);
        }
    }

    const fetchData = async () => {
        try {
            setIsFetching(true);
            const response = await getSummaryTimeAttendanceDepartment({
                start: dayjs(search.start).format("YYYY-MM-DD"),
                end: dayjs(search.end).format("YYYY-MM-DD"),
                idCompany: selectedCompany && selectedCompany.idCompany,
            });
            if (response && response.data && response.data.aggregatedData && response.data.aggregatedData.length > 0) {
                setIsFetching(false);
                setSummaryTimeAttendanceData(response.data.aggregatedData);
                setAllLeavesType(response.data.AllLeavesTypeResult);
            } else {
                handleChangeAlertType(t("NoData"));
                handleOpenAlert(true);
                setIsFetching(false);
            }
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        if (search && search.start && search.end && selectedCompany && selectedCompany.idCompany) {
            fetchData();
        } else {
            setIsFetching(false);
        }
    }, [search, selectedCompany]);

    useEffect(() => {
        dispatch(getAffiliateOrganization());
    }, []);

    useEffect(() => {
        if (userProfile && affiliateOrganizationList && affiliateOrganizationList.length > 0) {
            let ownCompany = affiliateOrganizationList.find(x => x.idCompany === userProfile.idCompany);
            if (!ownCompany) {
                ownCompany = affiliateOrganizationList[0];
            }
            setSelectedCompany(ownCompany)
        }
    }, [userProfile, affiliateOrganizationList]);

    return (
        <DrawerCustom
            title={`${t("SummaryTimeStampDepartment")}`}
            anchor="right"
            open={open}
            onClose={handleClose}
        >
            <StyledRoot style={{ width: 400 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">{t("Company")}</Typography>
                        <SelectAffiliateCompany
                            options={affiliateOrganizationList}
                            value={selectedCompany}
                            disabled={isFetching}
                            onChange={(_, value) => {
                                onChangeCompany(value);
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">
                            {t("StartDate")}
                        </Typography>
                        <div className="search-date">
                            <DatePickerCustom
                                minDate={new Date(new Date().getFullYear() - 4, 0, 1)}
                                inputFormat="DD/MM/YYYY"
                                value={search.start}
                                name="start"
                                disabled={isFetching}
                                onChange={(newValue) => {
                                    setSearch({ ...search, ["start"]: newValue });
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <Typography color="text.third" fontSize="14px" fontWeight="600" marginBottom="8px">
                            {t("EndDate")}
                        </Typography>
                        <div className="search-date">
                            <DatePickerCustom
                                minDate={search.start}
                                inputFormat="DD/MM/YYYY"
                                value={search.end}
                                name="end"
                                disabled={isFetching}
                                onChange={(newValue) => {
                                    setSearch({ ...search, ["end"]: newValue });
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>

                <StyledFooter>
                    <ButtonBlue className="cancel" onClick={handleClose}>
                        {t("Cancel")}
                    </ButtonBlue>

                    {isFetching ? (
                        // <CircularProgress />
                        <img src={LoadingGif} style={{ width: 80, height: 80 }} />
                    ) : (
                        <ButtonBlue
                            variant="outlined"
                            startIcon={<DownloadRoundedIcon />}
                            onClick={() => handleSubmit()}
                            disabled={!SummaryTimeAttendanceData || isFetching}
                        >
                            {t("Download")}
                        </ButtonBlue>
                    )}
                </StyledFooter>
                <AlertResponse
                    open={openAlert}
                    handleClose={handleCloseAlert}
                    alertType={alertType}
                />
            </StyledRoot>
        </DrawerCustom>

    );
};

export default SummaryTimeStampDepartment