import React, { useState, useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import html2canvas from "html2canvas";
import { PDFDocument } from "pdf-lib";
import { Grid, Typography, Box, TextField, Popper } from "@mui/material";

import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";

import DatePickerCustom from "../../../shared/date/datePicker";

import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";

import CardStyle from "../../../shared/general/Card";
import ButtonBlue from "../../../shared/general/ButtonBlue";

import {
  getPayslipByEmployee,
  getPayslipByIdPayrunDetail,
} from "../../../../../actions/payruns";

import SlipDetails from "../../../shared/payslip";
import fileService from "../../../../../services/file.service";
import { useTranslation } from "react-i18next";
import SelectAffiliateCompany from "../../../shared/general/SelectAffiliateCompany";
import { getAffiliateOrganization } from "../../../../../actions/company";
import AlertResponse from "../../../shared/general/AlertResponse";

const StyledWrapSearch = styled("div")({
  padding: 16,
  marginBottom: 16,
});

const StyledBoxSearch = styled(Box)({
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  "& .MuiPaper-root": {
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      paddingLeft: 8,
      margin: "0 8px",
      borderRadius: 8,
      "& .MuiTypography-root": {
        textAlign: "left",
        width: "100%",
      },
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledSlipDetails = styled(Box)({
  width: "100%",
  backgroundColor: "#FFFFFF",
  overflowX: "auto",
  marginLeft: "auto",
  marginRight: "auto",
  borderRadius: 8,
});

const PayslipAdmin = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { result: departmentList } = useSelector((state) => state.department);
  const { result: payslipDetail, isFetching: payslipDetailIsFetching } = useSelector((state) => state.payslipDetail);
  const { isFetching: payslipIsFetching } = useSelector(
    (state) => state.payslip
  );
  const { result: payRunDetailEmployeesList } = useSelector(
    (state) => state.payRunDetailEmployees
  );
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: affiliateOrganizationList } = useSelector((state) => state.affiliateOrganization);

  const [search, setSearch] = useState(new Date());
  const [selectedDepartment, setSelectedDepartment] = React.useState(null);
  const [selectedEmployee, setSelectedEmployee] = React.useState(null);
  const [selectedCompany, setSelectedCompany] = React.useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  useEffect(() => {
    if (payslipDetail && payslipDetail.length < 1) {
      handleOpenAlert(true);
      handleChangeAlertType(t("NoData"));
    }
  }, [payslipDetail]);

  useEffect(() => {
    if (selectedCompany) {
      dispatch(
        getPayslipByEmployee(
          dayjs(search)
            .set("date", dayjs(search).daysInMonth())
            .format("YYYY-MM-DD"),
          "admin",
          { idCompany: selectedCompany.idCompany }
        )
      );
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (selectedEmployee) {
      dispatch(
        getPayslipByIdPayrunDetail(
          selectedEmployee.idEmployees,
          dayjs(search)
            .set("date", dayjs(search).daysInMonth())
            .format("YYYY-MM-DD")
        )
      );
    }
  }, [selectedEmployee]);

  useEffect(() => {
    if (userProfile && affiliateOrganizationList && affiliateOrganizationList.length > 0) {
      const foundCompany = affiliateOrganizationList.find(x => x.idCompany === userProfile.idCompany);
      if (foundCompany) {
        setSelectedCompany(foundCompany)
      } else {
        setSelectedCompany(affiliateOrganizationList[0])
      }
    }
  }, [userProfile, affiliateOrganizationList])

  const handleChange = () => { };

  const printDocument = () => {
    const input = document.getElementById("divToPrint");
    html2canvas(input, {
      scale: 2,
    }).then(async (canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = await PDFDocument.create();
      const page = pdf.addPage();
      let companyLogo = await fileService
        .getCompanyLogoFile(userProfile.idCompany)
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          return null;
        });
      const pageSize = page.getSize();
      const pngImage = await pdf.embedPng(imgData);
      const pngDims = pngImage.scale(0.305);
      page.drawImage(pngImage, {
        x: 0,
        y: pageSize.height - (pngDims.height - 25),
        width: pngDims.width,
        height: pngDims.height,
      });

      if (companyLogo) {
        const pngCompanyLogo = await pdf.embedPng(companyLogo);
        const pngDimsCompanyLogo = pngCompanyLogo.scale(0.2);
        page.drawImage(pngCompanyLogo, {
          x: 30,
          y: pageSize.height - pngDimsCompanyLogo.height,
          width: pngDimsCompanyLogo.width,
          height: pngDimsCompanyLogo.height,
        });
      }
      const pdfBytes = await pdf.save();
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      const bytes = new Uint8Array(pdfBytes);
      const blob = new Blob([bytes], { type: "application/pdf" });
      const docUrl = window.URL.createObjectURL(blob);
      a.href = docUrl;

      a.download =
        "สลิปเงินเดือน " +
        dayjs(payRunDetailEmployeesList[0].monthPeriod).format("MMMM BBBB") +
        ".pdf";
      a.click();
      a.setAttribute(
        "id",
        "สลิปเงินเดือน " +
        dayjs(payRunDetailEmployeesList[0].monthPeriod).format("MMMM BBBB") +
        ".pdf"
      );
      document
        .getElementById(
          "สลิปเงินเดือน " +
          dayjs(payRunDetailEmployeesList[0].monthPeriod).format("MMMM BBBB") +
          ".pdf"
        )
        .remove();
      window.URL.revokeObjectURL(docUrl);
    });
  };

  useEffect(() => {
    dispatch(getAffiliateOrganization())
  }, [])

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box padding="16px 16px 0">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <StyledBoxSearch>
                  <Typography className="label" color="text.third">
                    {t("Company")}
                  </Typography>
                  <SelectAffiliateCompany
                    options={affiliateOrganizationList}
                    value={selectedCompany}
                    onChange={(_, value) => {
                      setSelectedEmployee(null)
                      setSelectedCompany(value)
                    }}
                    disabled={payslipDetailIsFetching}
                  />
                </StyledBoxSearch>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <StyledWrapSearch>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <StyledBoxSearch>
                  <Typography className="label" color="text.third">
                    {t("SearchDate")}
                  </Typography>
                  <div className="search-date">
                    <DatePickerCustom
                      inputFormat="MMMM YYYY"
                      value={search}
                      name="start"
                      views={["year", "month"]}
                      onChange={(newValue) => {
                        setSelectedEmployee(null)
                        setSearch(newValue);
                        if (selectedCompany) {
                          dispatch(
                            getPayslipByEmployee(
                              dayjs(newValue)
                                .set("date", dayjs(newValue).daysInMonth())
                                .format("YYYY-MM-DD"),
                              "admin",
                              { idCompany: selectedCompany.idCompany }
                            )
                          );
                        }
                      }}
                      disabled={payslipDetailIsFetching}
                    />
                  </div>
                </StyledBoxSearch>
              </Grid>
              {payslipDetail && payslipDetail.length > 0 && (
                <Grid item xs={4}>
                  <StyledBoxSearch>
                    <Typography className="label" color="text.third">
                      {t("Employee")}
                    </Typography>
                    <div className="search-name">
                      <StyledAutocomplete
                        options={payslipDetail}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            setSelectedEmployee(newValue);
                          } else {
                            setSelectedEmployee(null);
                          }
                        }}
                        loading={payslipIsFetching}
                        popupIcon={<i className="fa-light fa-chevron-down"></i>}
                        getOptionLabel={(option) =>
                          i18n.resolvedLanguage === "th"
                            ? `${option.firstname_TH} ${option.lastname_TH}`
                            : `${option.firstname_EN} ${option.lastname_EN}`
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="filled"
                            placeholder={t("SearchFullName")}
                          />
                        )}
                        PopperComponent={StyledPopper}
                        noOptionsText={t("NoData")}
                      />
                    </div>
                  </StyledBoxSearch>
                </Grid>
              )}
            </Grid>
          </StyledWrapSearch>
        </Grid>
        {selectedEmployee && payRunDetailEmployeesList && payRunDetailEmployeesList.length > 0 && (
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: 16,
              }}
            >
              <ButtonBlue
                variant="text"
                disabled={!payslipDetail}
                startIcon={<DownloadRoundedIcon />}
                onClick={printDocument}
              >
                {t("Download")}
              </ButtonBlue>
            </div>
            <CardStyle style={{ padding: 16 }}>
              <StyledSlipDetails>
                {payRunDetailEmployeesList.map((payslip) => (
                  <SlipDetails
                    currentSlip={payslip}
                    key={payslip.idPayrunDetail}
                  />
                ))}
              </StyledSlipDetails>
            </CardStyle>
          </Grid>
        )}
      </Grid>

      {selectedEmployee && (payRunDetailEmployeesList && payRunDetailEmployeesList.length < 1) && (
        <Box sx={{ p: 4, display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Typography variant="h6" textAlign={"center"}>
            {t("NoData")}
          </Typography>
        </Box>
      )}

      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </div>
  );
};

export default PayslipAdmin;
