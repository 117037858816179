import { PDFDocument, rgb } from 'pdf-lib';
import fontkit from "@pdf-lib/fontkit";
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export const SPS609PDFfile = async (type, employeeDetails, imageData) => {
    const url = `${process.env.REACT_APP_API_URL}files/${type}.pdf`;
    const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());

    const pdfDoc = await PDFDocument.load(existingPdfBytes);
    const urlFont = `${process.env.REACT_APP_API_URL}fonts/THSarabunNew.ttf`;
    const fontBytes = await fetch(urlFont).then((res) => res.arrayBuffer());
    pdfDoc.registerFontkit(fontkit);

    const font = await pdfDoc.embedFont(fontBytes);
    const pages = pdfDoc.getPages();
    const page1 = pages[0];
    const page2 = pages[1];
    const page3 = pages[2];

    const employeeData = employeeDetails && employeeDetails.employeeDetails;

    const branchNumber = `0  0  0  0  0  0`
    page1.drawText(branchNumber, {
        x: 667, y: 478, size: 20, font: font
    });

    // CompanyName
    if (employeeData && employeeData[0].companyName) {
        page1.drawText(`${employeeData[0].companyName}`, {
            x: 190,
            y: 506,
            size: 20,
            font: font
        })
    }

    if (employeeData && employeeData[0].mainContactPhone) {
        // const formattedPhoneNumber = employeeData[0].mainContactPhone ? parsePhoneNumberFromString(employeeData[0].mainContactPhone, 'TH').formatNational() : '';
        const formattedPhoneNumber = employeeData[0].mainContactPhone;
        page1.drawText(`${formattedPhoneNumber}`, {
            x: 660, y: 441, size: 16, font: font
        });
    }

    // addressCompany
    if (employeeData && employeeData[0].addressCompany) {
        let address = employeeData[0].addressCompany.split(" ");
        let houseNo = address[0];
        let road = address[1];
        let subDistrict = address[2];

        page1.drawText(`${houseNo} `, {
            x: 180, y: 461, size: 16, font: font
        });

        page1.drawText(`${road} `, {
            x: 506, y: 461, size: 16, font: font
        });

        page1.drawText(`${subDistrict} `, {
            x: 675, y: 461, size: 16, font: font
        });
    }

    // districtCompany
    if (employeeData && employeeData[0].districtCompany) {
        page1.drawText(`${employeeData[0].districtCompany}`, {
            x: 116,
            y: 441,
            size: 16,
            font: font
        })
    }

    // provinceCompany
    if (employeeData && employeeData[0].provinceCompany) {
        page1.drawText(`${employeeData[0].provinceCompany}`, {
            x: 315,
            y: 441,
            size: 16,
            font: font
        })
    }

    // areaCodeCompany
    if (employeeData && employeeData[0].areaCodeCompany) {
        const areaCode = employeeData[0].areaCodeCompany;

        const formattedareaCode1 = `${areaCode[0] + '  ' + areaCode[1]}`
        const formattedareaCode2 = `${areaCode[2] + '  ' + areaCode[3]}`
        const formattedareaCode3 = `${areaCode[4]} `

        // Draw the formatted areaCode
        page1.drawText(formattedareaCode1, {
            x: 506, y: 436, size: 20, font: font
        });
        page1.drawText(formattedareaCode2, {
            x: 540, y: 436, size: 20, font: font
        });
        page1.drawText(formattedareaCode3, {
            x: 571, y: 436, size: 20, font: font
        });
    }

    // socialSecurityAccount
    if (employeeData && employeeData[0].socialSecurityAccount) {
        const socialAccount = employeeData[0].socialSecurityAccount;

        const formattedsocialAccount1 = `${socialAccount[0] + '  ' + socialAccount[1]}`
        const formattedsocialAccount2 = `${socialAccount[2] + '  ' + socialAccount[3] + '  ' + socialAccount[4] + '  ' + socialAccount[5] + '  ' + socialAccount[6] + '  ' + socialAccount[7] + '  ' + socialAccount[8]}`
        const formattedsocialAccount3 = `${socialAccount[9]} `

        // Draw the formatted social account
        page1.drawText(formattedsocialAccount1, {
            x: 588, y: 501, size: 20, font: font
        });
        page1.drawText(formattedsocialAccount2, {
            x: 636, y: 501, size: 20, font: font
        });
        page1.drawText(formattedsocialAccount3, {
            x: 764, y: 501, size: 20, font: font
        });
    }

    const x = 30;
    const y = 360;
    let index = 1;
    const marginBottom = 1.5;

    const itemsPerPage = 7;
    let pageIndex = 2;

    const presentDay = new Date();
    const presentDayTH = dayjs(presentDay).locale('th').format("DD");
    const presentMonthTH = dayjs(presentDay).locale('th').format("MMMM");
    const thaiYear = dayjs(presentDay).format('YYYY');
    const formattedThaiYear = parseInt(thaiYear) + 543;

    if (employeeDetails) {
        for (let itemIndex = 0; itemIndex < employeeData.length; itemIndex++) {
            if (itemIndex % itemsPerPage === 0) {
                const [newPageTemplate] = await pdfDoc.copyPages(pdfDoc, [0]);
                pdfDoc.addPage(newPageTemplate);
                pageIndex++;
            }

            const currentPage = pdfDoc.getPages()[pageIndex];
            const offsetY = y - (itemIndex % itemsPerPage) * (19.5);
            const item = employeeData[itemIndex];
            const taxID = `${item.taxID}`;
            const thaiFormattedDate = dayjs(item.contractTermainatoinDate).locale('th').format('DD MMMM');
            const thaiFormattedYear = dayjs(item.contractTermainatoinDate).format('YYYY');
            const thaiYear = parseInt(thaiFormattedYear) + 543;

            const formattedtaxID1 = `${' ' + taxID[0]}`;
            const formattedtaxID2 = `${taxID[1] + '   ' + taxID[2] + '   ' + taxID[3] + '   ' + taxID[4]}`;
            const formattedtaxID3 = `${taxID[5] + '   ' + taxID[6] + '   ' + taxID[7] + '  ' + taxID[8] + '   ' + taxID[9]}`;
            const formattedtaxID4 = `${taxID[10] + '   ' + taxID[11] + '      ' + taxID[12]} `;

            currentPage.drawText(`${itemIndex + 1}`, { x, y: offsetY + 5, size: 14, font: font });
            currentPage.drawText(`${item.taxID ? formattedtaxID1 : ""}`, { x: x + 27, y: offsetY + 5, size: 14, font: font });
            currentPage.drawText(`${item.taxID ? formattedtaxID2 : ""}`, { x: x + 52, y: offsetY + 5, size: 14, font: font });
            currentPage.drawText(`${item.taxID ? formattedtaxID3 : ""}`, { x: x + 115, y: offsetY + 5, size: 14, font: font });
            currentPage.drawText(`${item.taxID ? formattedtaxID4 : ""}`, { x: x + 192, y: offsetY + 5, size: 14, font: font });
            currentPage.drawText(`${item.title_TH}`, { x: x + 280, y: offsetY + 6, size: 14, font: font });
            currentPage.drawText(`${item.firstname_TH}`, { x: x + 320, y: offsetY + 6, size: 14, font: font });
            currentPage.drawText(`${item.lastname_TH}`, { x: x + 380, y: offsetY + 6, size: 14, font: font });

            if (item.contractTermainatoinDate) {
                currentPage.drawText(`${thaiFormattedDate + " " + thaiYear}`, { x: x + 480, y: offsetY + 6, size: 14, font: font });
            } else {
                currentPage.drawText(`-`, { x: x + 515, y: offsetY + 6, size: 14, font: font });
            }

            if (imageData) {
                const pngImage = await pdfDoc.embedPng(imageData);
                const pngDims = pngImage.scale(0.05);
                currentPage.drawImage(pngImage, {
                    x: 420, y: 140, width: pngDims.width, height: pngDims.height
                })
            }

            currentPage.drawText(`${employeeData[0].certificateSignatureName ? employeeData[0].certificateSignatureName : "-"}`, {
                x: 380, y: 125, size: 15, font: font,
            });
            currentPage.drawText(`${employeeData[0].certificateSignaturePosition ? employeeData[0].certificateSignaturePosition : "-"}`, {
                x: 410, y: 105, size: 15, font: font,
            });

            currentPage.drawText(`${presentDayTH}`, { x: 382, y: 85, size: 15, font: font });
            currentPage.drawText(`${presentMonthTH}`, { x: 420, y: 85, size: 15, font: font });
            currentPage.drawText(`${formattedThaiYear}`, { x: 506, y: 85, size: 15, font: font });
        }
    }

    pdfDoc.removePage(1);
    pdfDoc.removePage(1);

    // Add modified pages
    pdfDoc.addPage(page2);
    pdfDoc.addPage(page3);

    const pdfBytes = await pdfDoc.save();
    const bytes = new Uint8Array(pdfBytes);
    const blob = new Blob([bytes], { type: "application/pdf" });
    const docUrl = URL.createObjectURL(blob);
    window.open(docUrl, "_blank");
}