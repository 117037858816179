import { httpClient } from "./httpClient";

const getProbationQuestion = (query) => {
  return httpClient.get(`/probation-question`, { params: query });
};

const addProbationQuestion = (formdata) => {
  return httpClient.post(`/probation-question`, formdata);
};

const updateProbationQuestion = (formdata) => {
  return httpClient.put(`/probation-question`, formdata);
};

const getProbation = (idProbation) => {
  return httpClient.get(`/probation/${idProbation}`);
};

const updateProbationManager = (formdata) => {
  return httpClient.put(`/probation/manager-probation`, formdata);
};

const getProbationAndAnswer = () => {
  return httpClient.get(`/probation/result-probation`);
};

const updateProbationAdmin = (formdata) => {
  return httpClient.put(`/probation/admin-probation/${formdata.idProbation}`, formdata);
};

export default {
  getProbationQuestion,
  addProbationQuestion,
  updateProbationQuestion,
  getProbation,
  updateProbationManager,
  getProbationAndAnswer,
  updateProbationAdmin,
};
