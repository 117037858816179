import React, { Fragment, useEffect, useState } from "react";
import { Avatar, Box, CircularProgress, Grid, IconButton, Link, Typography, styled } from "@mui/material";
import DrawerCustom from "../../shared/general/Drawer";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import AddIcon from "../../assets/add.png";
import PaperClipIcon from "../../assets/paper-clip.png";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { openNotificationAlert } from "../../../../actions/notificationAlert";
import { uploadExcelEmployeeHoliday } from "../../../../actions/uploadExcel";
import ButtonBlue from "../../shared/general/ButtonBlue";

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
  "& .field-label": {
    fontSize: "14px",
    fontWeight: "500",
    paddingBottom: "4px",
  },
});

const StyledDragDrop = styled(Box)({
  "& .dropzone-upload-file": {
    textAlign: "center",
    backgroundColor: "#F9F9FB",
    border: "1px solid #D0D0D0",
    borderStyle: "dashed",
    borderRadius: 8,
    height: 200,
    display: "flex",
    justifyContent: "center",
    "& .inner-dropzone": {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      "& img": {
        marginBottom: 16,
      },
    },
  },
});

const DragDrop = (props) => {
  const { name, useHookForm } = props;
  const { t } = useTranslation();

  const { getRootProps, getInputProps } = useDropzone({
    accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    onDrop: (acceptedFiles, rejectedFiles) => {
      if(acceptedFiles){
        useHookForm.setValue("file", acceptedFiles[0])
      }
      if(rejectedFiles.length > 0 && rejectedFiles[0].errors){
        if(rejectedFiles[0].errors[0].code === 'file-invalid-type'){
          useHookForm.setError("file", {message: t("Excel_file_invalid_type")})
        }
        if(rejectedFiles[0].errors[0].code === 'file-too-large'){
          useHookForm.setError("file", {message: t("file_too_large")})
        }
        if(rejectedFiles[0].errors[0].code === 'too-many-files'){
          useHookForm.setError("file", {message: t("too_many_files")})
        }
      }
    },
    multiple: false,
    maxSize: 1024*1024*5
  });

  const getSizeText = (size) => {
    const unit = ["K", "M", "G"];
    let tempSize = size / 1024;
    let i = 0;
    while (tempSize > 1024 && i < 2) {
      tempSize = tempSize / 1024;
      i++;
    }

    return `${Math.round(tempSize * 100) / 100} ${unit[i]}B`;
  };

  return (
    <StyledDragDrop>
      <Controller
        control={useHookForm.control}
        name={name}
        render={({ field }) => (
          <Fragment>
            {!field.value ? (
              <Box>
                <div
                  {...getRootProps({
                    className: "dropzone-upload-file",
                  })}
                  style={{
                    userSelect: "none",
                  }}
                >
                  <input {...getInputProps()} />
                  <div className="inner-dropzone">
                    <img
                      alt="AddIcon"
                      src={AddIcon}
                      width="80"
                      style={{ pointerEvents: "none" }}
                    />
                    <Typography
                      style={{
                        marginTop: 8,
                        backgroundColor: "transparent",
                      }}
                      className={`placeholderLabel`}
                      variant="body2"
                      color="text.secondary"
                    >
                      {t("DragDropOrClickSelectFile")}
                    </Typography>
                  </div>
                </div>
                {useHookForm.formState.errors[field.name] && (
                  <Typography
                    margin="3px 0px 0px"
                    fontSize="14px"
                    color="error"
                  >
                    {useHookForm.formState.errors[field.name].message}
                  </Typography>
                )}
                <Typography
                  marginTop="8px"
                  fontSize="14px"
                  fontWeight="500"
                  color="text.secondary"
                >
                  *{t("Excel_file_invalid_type")}/ {t("file_too_large")}/
                  {t("too_many_files")}
                </Typography>
              </Box>
            ) : (
              <Box
                display="flex"
                padding="4px"
                style={{
                  border: "1px solid #D0D0D0",
                  borderStyle: "dashed",
                  borderRadius: 8,
                  userSelect: "none",
                }}
              >
                <Avatar
                  src={PaperClipIcon}
                  variant="rounded"
                  style={{
                    marginRight: "8px",
                    marginLeft: "8px",
                    width: "32px",
                    height: "32px",
                    marginTop: "auto",
                    marginBottom: "auto",
                    pointerEvents: "none",
                  }}
                />
                <Box minWidth="0" flexGrow="1">
                  <Typography
                    fontSize="14px"
                    textOverflow="ellipsis"
                    overflow="hidden"
                    whiteSpace="nowrap"
                  >
                    {field.value.name}
                  </Typography>
                  <Typography fontSize="12px" color="text.secondary">
                    {getSizeText(field.value.size)}
                  </Typography>
                </Box>
                <IconButton
                  color="error"
                  onClick={() => {
                    field.onChange(null);
                  }}
                >
                  <ClearOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </Fragment>
        )}
      />
    </StyledDragDrop>
  );
};

const DrawerUploadEmployeeHoliday = (props) => {

  const { drawerConfig, onClose, selectedCompany, handleOnClickSearch } = props;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [errorConfig, setErrorConfig] = useState({
    isError: false,
    message: "",
  })

  const validateYupSchema = yup.object({
    file: yup
      .mixed()
      .nullable()
      .required(`${t("ThisFieldIsRequired")}`),
  });

  const useHookForm = useForm({
    defaultValues: {
      file: null
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all",
  });

  useEffect(() => {
    if(drawerConfig.isOpen === true){
      useHookForm.reset({
        file: null
      })
      setErrorConfig(prev => ({
        ...prev,
        isError: false,
      }))
    }
  }, [drawerConfig.isOpen])

  const handleClickDownloadTemplate = () => {
    window.open(`${process.env.REACT_APP_API_URL}files/Employee Holiday Template v1.0.1.xlsx`)
  }

  const onSubmit = async (data) => {

    const formData = new FormData();
    formData.append("file", data.file)
    formData.append("idCompany", selectedCompany.idCompany)

    const result = await dispatch(uploadExcelEmployeeHoliday(formData))

    if(result){
      if(result.status === 200){
        dispatch(openNotificationAlert({
          type: "success",
          message: "อัพโหลดเวลาทำงานสำเร็จ"
        }));
        onClose();
        handleOnClickSearch();
      } else {
        setErrorConfig({
          isError: true,
          message: result.data.message,
        })
      }
    } else {
      setErrorConfig({
        isError: true,
        message: result.data.message,
      })
    }
  }

  return (
    <DrawerCustom
      title={t("UploadEmployeePublicHolidays")}
      anchor="right"
      open={drawerConfig.isOpen}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      <StyledRoot>
        <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12} container justifyContent="flex-end">
              <Link variant="body2" color="#46cbe2" onClick={handleClickDownloadTemplate} style={{cursor: "pointer"}}>
                {t("DownloadTemplate")} Employee Holiday Template v1.0.1.xlsx
              </Link>
            </Grid>
            <Grid item xs={12}>
              <Typography className="field-label" color="text.secondary">
                {`${t("AttachFile")}`}
              </Typography>
              <DragDrop name="file" useHookForm={useHookForm} />
            </Grid>
            <Grid item xs={12} container justifyContent="space-between">
              <ButtonBlue variant="text" onClick={onClose} disabled={useHookForm.formState.isSubmitting}>{t("Cancel")}</ButtonBlue>
              <ButtonBlue
                variant="contained"
                type="submit"
                disabled={useHookForm.formState.isSubmitting}
                startIcon={useHookForm.formState.isSubmitting? <CircularProgress style={{color: "inherit", width: "16px", height: "16px"}}/>: undefined}
              >
                {t("Upload")}
              </ButtonBlue>
            </Grid>
          </Grid>
        </form>
      </StyledRoot>
    </DrawerCustom>
  )
}

export default DrawerUploadEmployeeHoliday