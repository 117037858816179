import { httpClient } from "./httpClient";
import dayjs from "dayjs";

const allHoliday = (year, query) => {
  return httpClient.get(`/holiday/${year}`, { params: query });
};

const getHoliday = (query) => {
  return httpClient.get(`/holiday`, { params: query });
};


const postHoliday = (data) => {
  return httpClient.post(`/company/holiday/postHoliday`, data);
};

const putHoliday = (data) => {
  return httpClient.put(`/holiday`, data);
};

const deleteHoliday = (id, data) => {
  return httpClient.post(`/company/holiday/deleteHoliday/${id}`, data);
};

const uploadFileHoliday = (formData) => {
  return httpClient.post(`/holiday`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
   }) ;
};

export default {
  allHoliday,
  getHoliday,
  postHoliday,
  putHoliday,
  deleteHoliday,
  uploadFileHoliday
};
