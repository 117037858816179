import React, { useEffect, useState } from "react";
import { Avatar, Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import "dayjs/locale/th";
import { useDispatch, useSelector } from "react-redux";
import { getEstimatePerformance } from "../../../../actions/estimatePerformance";

import AlertResponse from "../../shared/general/AlertResponse";
import ButtonBlue from "../../shared/general/ButtonBlue";
import TableCustom from "../../shared/tableCustom";
import DialogEstimatePerformance from "./DialogEstimatePerformance";
//Translator TH-EN
import { useTranslation } from "react-i18next";

dayjs.locale("th");

const RequestEstimateYear = (props) => {
  const { setNumberOfList, selectedCompany } = props;
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const { result: employeesEstimatePerformance } = useSelector(
    (state) => state.employeesEstimatePerformance
  );

  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [idEducation, setIdEducation] = useState(null);
  const [value, setValue] = useState(null);
  const [typeDialog, setTypeDialog] = useState("Add");

  const columns = [
    {
      name: `${t("FullName")}`,
      minWidth: "230px",
      width: "230px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Avatar
            sx={{
              marginRight: "8px",
              width: 40,
              height: 40,
              "& img": { objectFit: "contain" },
            }}
            src={row.imageURL}
          />
          <Box flexGrow={1}>
            <Typography>
              {row.firstname_TH} {row.lastname_TH}
            </Typography>
            <Typography color="text.third" fontSize="14px">
              {row.positionName}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      name: `${t("HiringDate")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>{dayjs(row.hiringDate).format("D MMM BBBB")}</Typography>
        </Box>
      ),
    },
    {
      name: `${t("EvaluationPeriod")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Typography>
          {`${dayjs(row.startDate).format("DD MMM")} - ${dayjs(
            row.endDate
          ).format("DD MMM BBBB")}`}
        </Typography>
      ),
    },
    {
      name: `${t("Evaluate")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              align: "center",
            }}
          >
            <ButtonBlue
              variant="contained"
              onClick={() => {
                setValue(row);
                handleOpenEducation("Add");
              }}
            >
              {`${t("Evaluate")}`}
              &nbsp;
            </ButtonBlue>
          </div>
        </Box>
      ),
    },
  ];

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleOpenEducation = (type, idEdu) => {
    if (type === "Add") {
      setTypeDialog("Add");
    } else {
      setTypeDialog("Edit");
    }
    if (idEdu) {
      setIdEducation(idEdu);
    }
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setIdEducation(null);
  };

  const handleSubmitEstimatePerformance = async (result) => {
    handleClose();
    if (result) {
      handleOpenAlert();
      if (result.status === 201) {
        dispatch(getEstimatePerformance({ idCompany: selectedCompany.idCompany }));
        handleChangeAlertType("success");
      } else {
        handleChangeAlertType("error");
      }
    } else {
      handleChangeAlertType("error");
    }
  };

  useEffect(() => {
    setNumberOfList(employeesEstimatePerformance.length);
  }, [employeesEstimatePerformance]);

  return (
    <>
      {employeesEstimatePerformance && (
        <TableCustom columns={columns} rows={employeesEstimatePerformance} />
      )}
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
      {openDialog && (
        <DialogEstimatePerformance
          open={openDialog}
          handleClose={handleClose}
          idEdu={idEducation}
          type={typeDialog}
          value={value}
          handleSubmitEstimatePerformance={handleSubmitEstimatePerformance}
        />
      )}
    </>
  );
};

export default RequestEstimateYear;
