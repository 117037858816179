import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Container,
  Typography,
  styled,
  Card,
  Button,
  Grid,
  MenuItem,
  Stack,
} from "@mui/material";
import CardStyle from "../../../../shared/general/Card";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import { useDispatch, useSelector } from "react-redux";
import { getOrganizationObjectives } from "../../../../../../actions/okrs";
import Loading from "../../../../shared/loading";
import { Search } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";

const StyledRoot = styled(Box)({
  "& .objective-card": {
    padding: "16px",
  },
  "& .MuiFilledInput-root input": {
    padding: "16px 12px 16px 12px",
  },
  "& .MuiFilledInput-root input": {
    padding: "16px 12px 16px 12px",
  },
});

const StyledCard = styled(Card)({
  minHeight: "70px",
  boxShadow: "none",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  background:
    "linear-gradient(to right, rgba(235, 148, 192, 0.5), rgba(188, 144, 213, 0.5))",
  "& .cardTitle": {
    fontSize: 22,
  },
  "& .MuiCardContent-root": {
    padding: 24,
  },
  "& .cardHeader": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
  "& .searchContainer": {
    marginLeft: "41px",
    marginBottom: "100px",
  },
  "@media (max-width: 600px)": {
    "& .searchContainer": {
      marginBottom: "10px",
    },
  },
});

const StyleHeadBox = styled(Box)({
  "& .box-header": {
    minHeight: "200px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    backgroundColor:
      "linear-gradient(to right, rgba(235, 148, 192, 0.5), rgba(188, 144, 213, 0.5))",
    "& .text-header": {
      fontSize: "48px",
      fontWeight: "bold",
      marginLeft: "40px",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      marginTop: "80px",
    },
    "& .img-header": {
      width: "33%",
      height: "auto",
      marginBottom: "10px",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      marginRight: "20px",
    },
  },

  "@media (max-width: 600px)": {
    "& .box-header": {
      flexDirection: "column",
      minHeight: "unset",
      justifyContent: "center",
      alignItems: "center",
      "& .text-header": {
        fontSize: "30px",
        marginLeft: "20px",
        marginTop: "20px",
      },
    },
  },
});

const CustomButton = styled(Button)({
  color: "#DB4178",
  border: "2px solid #DB4178",
  backgroundColor: "transparent",
  borderRadius: "7px",
  height: "50px",
  "&:hover": {
    color: "#DB4178",
    border: "2px solid #C53B6C",
    backgroundColor: "transparent",
    borderRadius: "7px",
    height: "50px",
  },
});

const ObjectiveCard = (props) => {
  const { data } = props;
  const { t, i18n } = useTranslation();

  return (
    <CardStyle>
      <Box padding="24px">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography fontSize="24px">
            {`${dayjs()
              .set("year", data.year)
              .format(i18n.resolvedLanguage === "th" ? "BBBB" : "YYYY")}, Q${
              data.quarter
            }`}
          </Typography>
          <CustomButton
            component={Link}
            to={`/okrs/organization-progress/objective/${data.idOrganizationObjective}`}
            variant="outlined"
            startIcon={<Search />}
          >
            {`${t("Descriptions")}`}
          </CustomButton>
        </Box>
        <Typography fontSize="20px" paddingTop="16px">
          {data.objectiveName}
        </Typography>
        <Typography fontSize="14px" paddingTop="16px">
          <span style={{ color: "#919eab", paddingRight: "8px" }}>
            {`${t("TheResultingOutcome")}`}
          </span>
          {data.impactName}
        </Typography>
        {data.description && (
          <Typography fontSize="14px" paddingTop="16px" whiteSpace="pre-line">
            <span style={{ color: "#919eab", paddingRight: "8px" }}>
              {`${t("Descriptions")}`}
            </span>
            {data.description}
          </Typography>
        )}
        <Typography fontSize="14px" paddingTop="16px" textAlign="right">
          <span style={{ color: "#919eab", paddingRight: "8px" }}>
            {`${t("TheMainResult")}`}
          </span>
          {data.keyResults.length} {`${t("List")}`}
        </Typography>
      </Box>
    </CardStyle>
  );
};

const UpdateProgressOrganizationPage = (props) => {
  const { t, i18n } = useTranslation();
  const { idOrganizationObjective } = useParams();
  const dispatch = useDispatch();
  const { result: organizationObjective, isFetching } = useSelector((state) => state.organizationObjective);
  
  const [selectYear, setSelectYear] = useState(dayjs().get('year'));
  const handleChange = (event) => {
    setSelectYear(event.target.value);
  };

  useEffect(() => {
    dispatch(getOrganizationObjectives(idOrganizationObjective));
  }, []);

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg" sx={{ paddingTop: "40px"}}>
        <StyledCard style={{ marginBottom: "40px"}}>
          <StyleHeadBox>
            <Box className="box-header">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography className="text-header">{`${t(
                    "UpdateOrganizationalGoals"
                  )}`}</Typography>
                </Grid>

                <Grid item xs={12} className="searchContainer">
                  <TextFieldTheme
                    select
                    variant="filled"
                    label={t("SelectYear")}
                    value={selectYear}
                    onChange={handleChange}
                    style={{
                      maxWidth: "250px",
                      backgroundColor: "#ffffff",
                      borderRadius: "10px",
                    }}
                  >
                    {[0, 1, 2].map((item) => (
                      <MenuItem key={item} value={dayjs().get('year') - item}>
                        {dayjs().subtract(item, 'year').format(i18n.resolvedLanguage === "th" ? "BBBB" : "YYYY")}
                      </MenuItem>
                    ))}
                  </TextFieldTheme>
                </Grid>
              </Grid>
              <img
                src={`${process.env.PUBLIC_URL}/assets/okrs/admin/update-organizational-goals.png`}
                alt="icon"
                style={{ objectFit: "contain" }}
              />
            </Box>
          </StyleHeadBox>
        </StyledCard>

        {isFetching ? (
          <Fragment>
            <Box display="flex" justifyContent="center" paddingTop="24px">
              <Loading />
            </Box>
          </Fragment>
        ) : organizationObjective && organizationObjective.length > 0 ? (
          <Stack gap={"16px"}>
            {organizationObjective
              .filter((item) => item.year === selectYear)
              .map((objective, index) => (
                <ObjectiveCard data={objective} key={index} />
              ))}
          </Stack>
        ) : (
          <Box display="flex" justifyContent="center" paddingTop="24px">
            <Typography>{`${t("NoItems")}`}</Typography>
          </Box>
        )}
      </Container>
    </StyledRoot>
  );
};

export default UpdateProgressOrganizationPage;
