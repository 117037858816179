import React from "react";
import { Box, Button, Typography, styled } from "@mui/material";
import CardStyle from "../../../shared/general/Card";
import SearchIcon from "@mui/icons-material/Search";
import StarRateIcon from '@mui/icons-material/StarRate';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

const CustomButton = styled(Button)({
  color: "#DB4178",
  border: "2px solid #DB4178",
  backgroundColor: "transparent",
  borderRadius: "7px",
  height: "50px",
  "&:hover": {
    color: "#DB4178",
    border: "2px solid #C53B6C",
    backgroundColor: "transparent",
    borderRadius: "7px",
    height: "50px",
  },
});

const CardObjective = (props) => {
  const { data } = props;
  const { t, i18n } = useTranslation();

  const totalRating = data.keyResults.reduce((acc, keyresult) => {
    let xRating = 0
    if (keyresult.isMoonShotPlanning) {
      xRating += 1.5
    }
    if (keyresult.isMoonShotEvaluation) {
      xRating += 1.5
    }
    if (keyresult.isSuccess) {
      acc +=
        xRating > 0
          ? keyresult.managerRating * xRating
          : keyresult.managerRating;
    }
    
    return acc
  }, 0)

  return (
    <CardStyle style={{ height: "100%" }}>
      <Box padding="24px">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography fontSize="24px">
            {`${dayjs()
              .set("year", data.year)
              .format(i18n.resolvedLanguage === "th" ? "BBBB" : "YYYY")}, Q${
              data.quarter
            }`}
          </Typography>
          <CustomButton
            component={Link}
            to={`/okrs/individual-planning/information/${data.idIndividualObjective}`}
            variant="outlined"
            startIcon={<SearchIcon />}
          >
            {`${t("Descriptions")}`}
          </CustomButton>
        </Box>
        <Typography fontSize="20px" paddingTop="3px">
          {data.objectiveName}
        </Typography>
        <Typography fontSize="14px" paddingTop="40px">
          <span style={{ color: "#919eab", paddingRight: "8px" }}>
            {`${t("TheResultingOutcome")}`}
          </span>
          {data.impactName}
        </Typography>
        {data.description && (
          <Typography fontSize="14px" paddingTop="16px" whiteSpace="pre-line">
            <span style={{ color: "#919eab", paddingRight: "8px" }}>
              {`${t("Descriptions")}`}
            </span>
            {data.description}
          </Typography>
        )}
        <div
          style={{
            display:"flex",
            justifyContent:"space-between",
            alignItems: "flex-end",
          }}
        >
          <Typography fontSize="14px" paddingTop="16px" textAlign="right">
            <span style={{ color: "#919eab", paddingRight: "8px" }}>
              {`${t("TheMainResult")}`}
            </span>
            {data.keyResults.length} {`${t("List")}`}
          </Typography>
          <div
            style={{
              display:"flex",
              justifyContent:"space-between",
              alignItems:"center",
              backgroundColor:"#fff9db",
              gap: "8px",
              padding: "8px",
              borderRadius: "16px",
            }}
          >
            <StarRateIcon
              sx={{ "&.MuiSvgIcon-root": { color: "#ffdd40" }}}
            />
            <Typography fontSize="20px" fontWeight={500}>
              {totalRating > 0 ? Number(totalRating).toFixed(1) : 0}
            </Typography>
          </div>
        </div>
      </Box>
    </CardStyle>
  );
};

export default CardObjective;
