import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  Fragment,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import * as XLSX from "xlsx";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import AlertResponse from "../../../../shared/general/AlertResponse";
import { styled } from "@mui/material/styles";
import CardContent from "@mui/material/CardContent";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import DialogEdit from "./dialog";
import StyledCard from "../../../../shared/general/Card";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import utils from "../../../../../../utils";
import {
  Autocomplete,
  Box,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import { PayrollDataGridCard } from "./DataGridCard";
import { getPayrunByMonthAndCompanyToCompare } from "../../../../../../actions/payruns";
import { DashboardPayrollList } from "./Dashboard";
// import { BarChart } from '@mui/x-charts/BarChart';

const StyledCardContent = styled(CardContent)({
  height: "100%",
  padding: 24,
  // display: "flex",
  // justifyContent: "center",
  // alignItems: "center",
  "& .wrap-header-filter": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 24,
    "& .wrap-filter": {
      display: "flex",
      "& .btn-filter": {
        marginRight: 8,
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: 8,
      },
    },
  },
  "& .wrap-manage": {
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .wrap-manage-group": {
      display: "flex",
      alignItems: "center",
      "& .btn-mamage-group-company": {
        marginRight: 8,
      },
    },
    "& .wrap-edit-selected": {},
  },
  "& .wrap-panel": {
    height: 490,
    overflow: "auto",
    display: "flex",
  },
});

const StyledWrapDataGrid = styled("div")({
  '& .column-info[role="columnheader"]': {
    backgroundColor: "#283593",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-ot[role="columnheader"]': {
    backgroundColor: "#7c4dff",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-shift[role="columnheader"]': {
    backgroundColor: "#2196f3",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-salary[role="columnheader"]': {
    backgroundColor: "#00796b",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-addition[role="columnheader"]': {
    backgroundColor: "#26a69a",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-earnings-all[role="columnheader"]': {
    backgroundColor: "#005e55",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-earnings[role="columnheader"]': {
    backgroundColor: "#00897b",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-sso[role="columnheader"], .column-tax[role="columnheader"], .column-pf[role="columnheader"]':
  {
    backgroundColor: "#ef5350",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-deduction[role="columnheader"]': {
    backgroundColor: "#e53935",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-deduction[role="columnheader"]': {
    backgroundColor: "#c62828",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-deduction-all[role="columnheader"]': {
    backgroundColor: "#a30000",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-net[role="columnheader"]': {
    backgroundColor: "#ffd600",
    color: "#313f4c",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#313f4c",
    },
  },
  '& .column-accumulate[role="columnheader"]': {
    backgroundColor: "#ff9100",
    color: "#313f4c",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#313f4c",
    },
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const TableEmployee = (props) => {
  const {
    handleGetAllDetail,
    handleClosePayroll,
    handleDeletePayroll,
    // lastPayrun
  } = props;
  const { t, i18n } = useTranslation();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: payRunDetail } = useSelector((state) => state.payRunDetail);
  const { result: payRunDetailEmployees } = useSelector(
    (state) => state.payRunDetailEmployees
  );

  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedRowsData, setSelectedRowsData] = React.useState(null);
  const [data, setData] = React.useState(null);
  const [gridColumnsAddition, setGridColumnsAddition] = React.useState([]);
  const [gridColumnsDeduction, setGridColumnsDeduction] = React.useState([]);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [openAddFile, setOpenAddFile] = useState(false);
  const [anchorElBank, setAnchorElBank] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [doCompare, setDoCompare] = useState(false);
  const [selectedEmp, setSelectedEmp] = useState(null);
  const open = Boolean(anchorEl);
  const openBank = Boolean(anchorElBank);

  let gridColumnsOT = [
    {
      dataField: "otOneHours",
      caption: `OT 1 (${t("Unit.ShortHours")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
    },
    {
      dataField: "otOneAmount",
      caption: `OT 1 (${t(`CurrencyUnit.${payRunDetail.paymentCurrency}`)})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
    },
    {
      dataField: "otOneFiveHours",
      caption: `OT 1.5 (${t("Unit.ShortHours")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
    },
    {
      dataField: "otOneFiveAmount",
      caption: `OT 1.5 (${t(`CurrencyUnit.${payRunDetail.paymentCurrency}`)})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
    },
    {
      dataField: "otTwoHours",
      caption: `OT 2 (${t("Unit.ShortHours")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
    },
    {
      dataField: "otTwoAmount",
      caption: `OT 2 (${t(`CurrencyUnit.${payRunDetail.paymentCurrency}`)})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
    },
    {
      dataField: "otThreeHours",
      caption: `OT 3 (${t("Unit.ShortHours")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
    },
    {
      dataField: "otThreeAmount",
      caption: `OT 3 (${t(`CurrencyUnit.${payRunDetail.paymentCurrency}`)})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
    },
  ];

  let gridColumnsShift = [
    {
      dataField: "shiftMorning",
      caption: `ค่ากะเช้า`,
      format: "###,###,###.##",
      cssClass: "column-shift",
    },
    {
      dataField: "shiftNoon",
      caption: `ค่ากะบ่าย`,
      format: "###,###,###.##",
      cssClass: "column-shift",
    },
    {
      dataField: "shiftNight",
      caption: `ค่ากะดึก`,
      format: "###,###,###.##",
      cssClass: "column-shift",
    },
  ];

  let listOfBank = [
    {
      key: "กรุงศรีอยุธยา",
      value: "025 Bank of Ayudhya Public Company Limited",
    },
    {
      key: "กสิกร",
      value: "004 Kasikorn Bank",
    },
    {
      key: "ไทยพาณิชย์",
      value: "014 Siam Commercial Bank",
    },
    {
      key: "กรุงเทพ",
      value: "002 Bangkok Bank PCL",
    },
    {
      key: "กรุงไทย",
      value: "006 Krung Thai Bank Public Company Limited",
    },
    {
      key: "ทหารไทยธนชาต",
      value: "011 TMB Bank Public Company Limited ",
    },
  ];

  useEffect(() => {
    if (payRunDetailEmployees && payRunDetailEmployees.length > 0) {
      let temp = [...payRunDetailEmployees];
      let dataList = [];
      let tempGridColumnsAddition = [];
      let tempGridColumnsDeduction = [];
      temp.forEach((element, index) => {
        element["index"] = index + 1;

        element["isNetLessThenZero"] = element["isNetLessThenZero"] && `เงินสุทธิติดลบ ${element["netLessThenZero"]} บาท`;

        element["netAboard"] = element["netAll"] - element["net"];

        const find = element.payrunDetailMain.find(
          (x) => x.idPayrollType === 1
        );
        element["salaryPaid"] = find ? find.value : 0;
        element["salaryActual"] = find ? find.valueActual : 0;


        const findSSO = element.payrunDetailMain.find(
          (x) => x.idPayrollType === 11
        );
        element["ssoEmployee"] = findSSO ? findSSO.value : 0;

        const findTax = element.payrunDetailMain.find(
          (x) => x.idPayrollType === 12
        );
        element["tax"] = findTax ? findTax.value : 0;

        const findLateEarly = element.payrunDetailMain.find(
          (x) => x.idPayrollType === 16
        );
        element["lateEarly"] = findLateEarly ? findLateEarly.value : null;
        element["lateEarlyMinute"] = findLateEarly
          ? findLateEarly.valueHour
          : null;

        const findAbsent = element.payrunDetailMain.find(
          (x) => x.idPayrollType === 15
        );
        element["absent"] = findAbsent ? findAbsent.value : null;
        element["absentDay"] = findAbsent ? findAbsent.valueHour : null;

        const findLeaveWithoutPay = element.payrunDetailMain.find(
          (x) => x.idPayrollType === 21
        );
        element["leaveWithoutPay"] = findLeaveWithoutPay
          ? findLeaveWithoutPay.value
          : null;
        element["leaveWithoutPayDay"] = findLeaveWithoutPay
          ? findLeaveWithoutPay.valueHour
          : null;

        element.payrunDetailAddition.forEach((a) => {
          tempGridColumnsAddition.push({
            dataField: `addition${a.code}`,
            caption: `${a.name} (${a.code})`,
            format: "###,###,##0.00",
            cssClass: "column-addition",
            indexAddition: a.indexAddition,
          });
          element[`addition${a.code}`] = a.value;
        });

        element.payrunDetailDeduction.forEach((a) => {
          tempGridColumnsDeduction.push({
            dataField: `deduction${a.code}`,
            caption: `${a.name} (${a.code})`,
            format: "###,###,##0.00",
            cssClass: "column-deduction",
            indexDeduction: a.indexDeduction,
          });
          element[`deduction${a.code}`] = a.value;
        });

        const findOtOne = element.payrunDetailMain.find(
          (x) => x.idPayrollType === 2
        );
        const findOtOneFive = element.payrunDetailMain.find(
          (x) => x.idPayrollType === 3
        );
        const findOtTwo = element.payrunDetailMain.find(
          (x) => x.idPayrollType === 4
        );
        const findOtThree = element.payrunDetailMain.find(
          (x) => x.idPayrollType === 5
        );

        element["otOneHours"] = findOtOne ? findOtOne.valueHour : 0;
        element["otOneAmount"] = findOtOne ? findOtOne.value : 0;

        element["otOneFiveHours"] = findOtOneFive ? findOtOneFive.valueHour : 0;
        element["otOneFiveAmount"] = findOtOneFive ? findOtOneFive.value : 0;

        element["otTwoHours"] = findOtTwo ? findOtTwo.valueHour : 0;
        element["otTwoAmount"] = findOtTwo ? findOtTwo.value : 0;

        element["otThreeHours"] = findOtThree ? findOtThree.valueHour : 0;
        element["otThreeAmount"] = findOtThree ? findOtThree.value : 0;

        element["totalOTHours"] =
          element["otOneHours"] +
          element["otOneFiveHours"] +
          element["otTwoHours"] +
          element["otThreeHours"];

        const findCompanyPF = element.payrunDetailMain.find(
          (x) => x.idPayrollType === 18
        );
        const findEmployeePF = element.payrunDetailMain.find(
          (x) => x.idPayrollType === 10
        );

        element["pfCompanyPercent"] = findCompanyPF
          ? findCompanyPF.valueHour + ".00%"
          : null;
        element["pfCompanyTHB"] = findCompanyPF ? findCompanyPF.value : null;
        element["pfEmployeePercent"] = findEmployeePF
          ? findEmployeePF.valueHour + ".00%"
          : null;
        element["pfEmployeeTHB"] = findEmployeePF ? findEmployeePF.value : null;

        const findShiftMorning = element.payrunDetailMain.find(
          (x) => x.idPayrollType === 7
        );
        const findShiftNoon = element.payrunDetailMain.find(
          (x) => x.idPayrollType === 8
        );
        const findShiftNight = element.payrunDetailMain.find(
          (x) => x.idPayrollType === 9
        );
        const findShiftSum = element.payrunDetailMain.find(
          (x) => x.idPayrollType === 13
        );

        element["shiftMorning"] = findShiftMorning
          ? findShiftMorning.value
          : null;
        element["shiftNoon"] = findShiftNoon ? findShiftNoon.value : null;
        element["shiftNight"] = findShiftNight ? findShiftNight.value : null;

        dataList.push(element);
      });

      tempGridColumnsAddition.sort(function (a, b) {
        return new Date(a.indexAddition) - new Date(b.indexAddition);
      });
      tempGridColumnsDeduction.sort(function (a, b) {
        return new Date(a.indexDeduction) - new Date(b.indexDeduction);
      });

      setData(dataList);
      setGridColumnsAddition([
        ...new Map(
          tempGridColumnsAddition.map((item) => [item["dataField"], item])
        ).values(),
      ]);
      setGridColumnsDeduction([
        ...new Map(
          tempGridColumnsDeduction.map((item) => [item["dataField"], item])
        ).values(),
      ]);
    }
  }, [payRunDetailEmployees]);

  const onSelectionChanged = ({ selectedRowsData }) => {
    handleOpenDialog();
    setSelectedRowsData(selectedRowsData[0]);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClickIndividual = (selected) => {
    if (selected.row.key.isApprove !== 1) {
      handleOpenDialog();
      setSelectedRowsData(selected.row.data);
    }
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const onExporting = useCallback(() => {
    // let workbook = new ExcelJS.Workbook();
    // let worksheet = workbook.addWorksheet("SheetName");
    // exportDataGrid({
    //   component: grid.current.instance,
    //   worksheet: worksheet,
    // }).then(function () {
    //   workbook.xlsx.writeBuffer().then(function (buffer) {
    //     saveAs(
    //       new Blob([buffer], { type: "application/octet-stream" }),
    //       `${payRunDetail.payrunName}.xlsx`
    //     );
    //   });
    // });
  }, []);

  const onExportingBBL = () => {
    const elementList = [];
    for (let index = 0; index < payRunDetailEmployees.length; index++) {
      //console.log(payRunDetailEmployees[index]);
      console.log(dayjs(payRunDetailEmployees[index].payDate).get("day"));
      console.log(
        "Payment Date: ",
        dayjs(payRunDetailEmployees[index].payDate).get("day") === 6
          ? dayjs(payRunDetailEmployees[index].payDate)
            .subtract(1, "day")
            .format("YYYY-MM-DD")
          : dayjs(payRunDetailEmployees[index].payDate).get("day") === 0
            ? dayjs(payRunDetailEmployees[index].payDate)
              .subtract(2, "day")
              .format("YYYY-MM-DD")
            : dayjs(payRunDetailEmployees[index].payDate).format("YYYY-MM-DD")
      );

      const element = {
        "No.": index + 1,
        "Account Number": payRunDetailEmployees[index].reportBankBookBankID,
        "Account Name": payRunDetailEmployees[index].reportBankName,
        "Bank Name": payRunDetailEmployees[index].reportBankBankName,
        "Branch Code": payRunDetailEmployees[index].reportBankBankID,
        Amount: payRunDetailEmployees[index].net,
        Reference: payRunDetailEmployees[index].reportBankRef || "",
        "Payment Date":
          dayjs(payRunDetailEmployees[index].payDate).get("day") === 6
            ? dayjs(payRunDetailEmployees[index].payDate)
              .subtract(1, "day")
              .format("DDMMYYYY")
            : dayjs(payRunDetailEmployees[index].payDate).get("day") === 0
              ? dayjs(payRunDetailEmployees[index].payDate)
                .subtract(2, "day")
                .format("DDMMYYYY")
              : dayjs(payRunDetailEmployees[index].payDate).format("DDMMYYYY"),
      };

      elementList.push(element);
    }

    const ws = XLSX.utils.json_to_sheet(elementList);
    var workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, "sheet1");
    XLSX.writeFile(workbook, `BBL.xlsx`, { type: "file" });
  };

  const onExportingKbank = () => {
    const elementList = [];
    for (let index = 0; index < payRunDetailEmployees.length; index++) {
      const element = {
        เลขที่บัญชี: payRunDetailEmployees[index].reportBankBookBankID,
        ชื่อผู้รับเงิน: payRunDetailEmployees[index].reportBankName,
        จำนวนเงิน: payRunDetailEmployees[index].net,
      };

      elementList.push(element);
    }

    const ws = XLSX.utils.json_to_sheet(elementList);
    var workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, "sheet1");
    XLSX.writeFile(workbook, `KBank.xlsx`, { type: "file" });
  };

  const onExportingSCB = () => {
    const elementList = [];
    for (let index = 0; index < payRunDetailEmployees.length; index++) {
      const element = {
        "Benef. Code": index + 1,
        "Benef. Name": `${payRunDetailEmployees[index].reportBankName}`,
        "Account No.": payRunDetailEmployees[index].reportBankBookBankID,
        Amount: payRunDetailEmployees[index].net,
        Bank: payRunDetailEmployees[index].reportBankBankName,
      };

      elementList.push(element);
    }

    const ws = XLSX.utils.json_to_sheet(elementList);
    var workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, "sheet1");
    XLSX.writeFile(workbook, `SCB.xlsx`, { type: "file" });
  };

  const onExportingDeutscheBank = () => {
    const elementList = [];
    for (let index = 0; index < payRunDetailEmployees.length; index++) {
      //console.log(payRunDetailEmployees[index]);
      console.log(dayjs(payRunDetailEmployees[index].payDate).get("day"));
      console.log(
        "Payment Date: ",
        dayjs(payRunDetailEmployees[index].payDate).get("day") === 6
          ? dayjs(payRunDetailEmployees[index].payDate)
            .subtract(1, "day")
            .format("YYYY-MM-DD")
          : dayjs(payRunDetailEmployees[index].payDate).get("day") === 0
            ? dayjs(payRunDetailEmployees[index].payDate)
              .subtract(2, "day")
              .format("YYYY-MM-DD")
            : dayjs(payRunDetailEmployees[index].payDate).format("YYYY-MM-DD")
      );

      const element = {
        "Order Party account": index + 1,
        "Order party Acct Currency": "",
        "Trxn Date":
          dayjs(payRunDetailEmployees[index].payDate).get("day") === 6
            ? dayjs(payRunDetailEmployees[index].payDate)
              .subtract(1, "day")
              .format("DDMMYYYY")
            : dayjs(payRunDetailEmployees[index].payDate).get("day") === 0
              ? dayjs(payRunDetailEmployees[index].payDate)
                .subtract(2, "day")
                .format("DDMMYYYY")
              : dayjs(payRunDetailEmployees[index].payDate).format("DDMMYYYY"),

        "Trxn Amount": payRunDetailEmployees[index].net,
        "Counter-Party Code": "",
        "Counter Party Name": payRunDetailEmployees[index].reportBankName,
        "Vendor Bank account no":
          payRunDetailEmployees[index].reportBankBookBankID,
        "Vendor Bank Branch Code":
          payRunDetailEmployees[index].reportBankBankID,
        "Vendor Bank Code": "",
      };

      elementList.push(element);
    }

    const ws = XLSX.utils.json_to_sheet(elementList);
    var workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, "Credit_Transfer");
    XLSX.writeFile(workbook, `DeutscheBank.xlsx`, { type: "file" });
  };

  const handleClickOpenMenuBank = (event) => {
    setAnchorElBank(event.currentTarget);
  };

  const handleCloseMenuBank = () => {
    setAnchorElBank(null);
  };

  const handleClickOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const customizeNumber = (data) => {
    return data.value
      ? `${utils.numberWithCommas(data.value)} ${t(
        `CurrencyUnit.${payRunDetail.paymentCurrency}`
      )}`
      : "";
  };

  const customizeDate = (data) => {
    return data.value ? dayjs(data.value).format("YYYY-MM-DD") : "";
  };

  const customizeHours = (data) => {
    return data.value ? `${utils.numberWithCommas(data.value)} ${t("Unit.ShortHours")}` : "";
  };

  const onExportingPND = () => {
    const elementList = [];
    for (let index = 0; index < payRunDetailEmployees.length; index++) {
      let element = null;
      const tax = payRunDetailEmployees[index].payrunDetailMain.find(
        (x) => x.idPayrollType === 12
      );

      element = {
        ลำดับที่: index + 1,
        เลขประจำตัวผู้เสียภาษี: payRunDetailEmployees[index].taxID,
        คำนำหน้า: payRunDetailEmployees[index].title_TH,
        ชื่อ: payRunDetailEmployees[index].firstname_TH,
        สกุล: payRunDetailEmployees[index].lastname_TH,
        เงินได้ตามมาตรา: "401N",
        วันเดือนปีที่จ่าย: dayjs(payRunDetailEmployees[index].payDate).format(
          "DD/MM/BBBB"
        ),
        จำนวนเงินได้: tax
          ? tax.valueActual
          : payRunDetailEmployees[index].totalEarnings -
          payRunDetailEmployees[index].deductionTaxable,
        "ภาษีหัก ณ ที่จ่าย": tax ? tax.value : 0,
        เงื่อนไข: 1,
      };
      elementList.push(element);
    }

    const ws = XLSX.utils.json_to_sheet(elementList);
    var workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, "sheet1");
    XLSX.writeFile(workbook, `PND1.xlsx`, { type: "file" });
  };

  const onExportingSSO = () => {
    const elementList = [];
    for (let index = 0; index < payRunDetailEmployees.length; index++) {
      let element = null;
      const sso = payRunDetailEmployees[index].payrunDetailMain.find(
        (x) => x.idPayrollType === 11
      );
      if (sso) {
        element = {
          เลขประจำตัวประชาชน: payRunDetailEmployees[index].ssoID,
          คำนำหน้าชื่อ: payRunDetailEmployees[index].title_TH,
          ชื่อผู้ประกันตน: payRunDetailEmployees[index].firstname_TH,
          นามสกุลผู้ประกันตน: payRunDetailEmployees[index].lastname_TH,
          ค่าจ้าง: sso.valueActual,
          จำนวนเงินสมทบ: sso.value,
        };
        elementList.push(element);
      }
    }

    const ws = XLSX.utils.json_to_sheet(elementList);
    var workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, ws, "sheet1");
    XLSX.writeFile(workbook, `SSO.xlsx`, { type: "file" });
  };

  const filteredColumns = [
    { name: "ค่าจ้าง", dataField: "salaryPaid" },
    { name: "รวม OT", dataField: "totalOT" },
    { name: "รวมเงินได้", dataField: "totalAdditions" },
    { name: "รวมเงินได้สะสม", dataField: "totalEarnings" },
    { name: "ประกันสังคม", dataField: "ssoEmployee" },
    { name: "ภาษี", dataField: "tax" },
    { name: "กองทุนสำรองเลี้ยงชีพส่วนบริษัท", dataField: "pfCompanyTHB" },
    { name: "กองทุนสำรองเลี้ยงชีพส่วนพนักงาน", dataField: "pfEmployeeTHB" },
    { name: "รวมค่ากะ", dataField: "totalShiftPay" },
    { name: "รวมเงินหัก", dataField: "totalDeductions" },
    { name: "เงินได้สุทธิ", dataField: "net" },
  ];

  const filteredPercentageDiff = [
    { name: "10%", value: 10 },
    { name: "20%", value: 20 },
    { name: "30%", value: 30 },
    { name: "40%", value: 40 },
    { name: "50% ขึ้นไป", value: 50 },
  ];

  const [selectedColumns, setSelectedColumns] = useState(null);
  const [selectedPercent, setSelectedPercent] = useState(null);
  const [selectedEmployees, setSelectedEmployees] = useState(null);
  const [lastPayrun, setLastPayrun] = useState(null);
  const [isDashboard, setIsDashboard] = useState(false);

  const handleCloseAlert = () => {
    setOpenAlert(false);
    // setSelectedColumns(null);
    setSelectedPercent(null);
  };

  const handlePercentage = (value) => {
    setSelectedPercent(value);
  };

  const handleCancel = () => {
    setDoCompare(false);
    setSelectedColumns(null);
    handleGetAllDetail();
    setLastPayrun(null);
  };

  const handleColumnChange = (value) => {
    setSelectedPercent(null);
    setSelectedColumns(value);
    setLastPayrun(null);

    // if (value) {
    //   setSelectedPercent({ name: "10%", value: 10 });
    // }
  };

  const handleSeeDashBoard = () => {
    setIsDashboard(true);
  };

  const fetchPayruntoCompare = async (payrunPeriod) => {
    try {
      // const findDataEmp = data.find(item => item.idEmployees === (selectedEmployees && selectedEmployees.idEmployees));
      const response = await getPayrunByMonthAndCompanyToCompare({
        idPayrun: payRunDetail.idPayrun,
        idCompany: payRunDetail.idCompany,
        monthPeriod: dayjs(payrunPeriod).format("YYYY-MM-DD"),
        dataField: selectedColumns.dataField,
        percent: selectedPercent.value,
      });

      if (response && response.data) {
        const payrunData = response.data;
        const mergedData = [];

        data.forEach((employee) => {
          const employeeId = employee.idEmployees;
          const matchingPayrunData = payrunData[employeeId] || [];

          if (matchingPayrunData.length > 0) {
            matchingPayrunData.forEach((payrunItem) => {
              mergedData.push({ ...employee });
              mergedData.push({ ...payrunItem });
              mergedData.push({
                idEmployees: payrunItem.idEmployees,
                [selectedColumns.dataField]: payrunItem.diffValue,
              });
            });
          }
        });

        setLastPayrun(mergedData);
      } else {
        setOpenAlert(true);
        setAlertType(t("NoDataFromSelectedField"));
        setLastPayrun(null);
      };
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (
      payRunDetail &&
      selectedColumns &&
      selectedColumns.dataField &&
      selectedPercent &&
      selectedPercent.value &&
      data
    ) {
      fetchPayruntoCompare(payRunDetail.payrunPeriod);
    }
  }, [payRunDetail, selectedColumns, selectedPercent, data]);

  const handleOpenComparingMode = () => {
    setDoCompare(true);
    setSelectedPercent({ name: "10%", value: 10 });
    setSelectedColumns({ name: "ค่าจ้าง", dataField: "salaryPaid" });
  };

  return (
    <Fragment>
      <div style={{ marginTop: 16, marginBottom: 16 }}>
        {payRunDetail.isDraft === 1 ? (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <ButtonBlue
              variant="contained"
              onClick={handleClosePayroll}
              style={{ marginRight: 8 }}
            >
              {`${t("CloseAccountingPeriod")}`}
            </ButtonBlue>

            <Stack direction={"row"} spacing={1} alignItems={"center"}>
              {userProfile && (userProfile.idCompany === 1 || userProfile.idCompany === 2) && (
                <div>
                  {!doCompare ? (
                    <ButtonBlue
                      variant="contained"
                      style={{ marginRight: 8 }}
                      onClick={handleOpenComparingMode}
                    >
                      {t("ComparePayroll")}
                    </ButtonBlue>
                  ) : (
                    <>
                      {isDashboard ? (
                        <ButtonBlue
                          color="error"
                          variant="contained"
                          style={{ marginRight: 8 }}
                          onClick={() => setIsDashboard(false)}
                        >
                          ปิด Dashboard
                        </ButtonBlue>
                      ) : (
                        <ButtonBlue
                          variant="contained"
                          style={{ marginRight: 8 }}
                          onClick={() => handleSeeDashBoard()}
                        >
                          ดู Dashboard
                        </ButtonBlue>
                      )}
                    </>
                  )}
                </div>
              )}
              <div>
                <ButtonBlue
                  variant="contained"
                  color="error"
                  startIcon={<DeleteForeverRoundedIcon />}
                  onClick={handleDeletePayroll}
                  style={{ marginRight: 8 }}
                >
                  {`${t("Cancel")}`}
                </ButtonBlue>
              </div>
            </Stack>
          </div>
        ) : (
          <Fragment>
            <ButtonBlue
              aria-controls={openBank ? "bank-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openBank ? "true" : undefined}
              onClick={handleClickOpenMenuBank}
              variant="contained"
              startIcon={<DownloadRoundedIcon />}
              style={{ marginRight: 16 }}
            >
              {`${t("BankFile")}`}
            </ButtonBlue>
            <Menu
              id="bank-menu"
              anchorEl={anchorElBank}
              open={openBank}
              onClose={handleCloseMenuBank}
              MenuListProps={{
                "aria-labelledby": "bank-button",
              }}
            >
              <MenuItem onClick={onExportingBBL}>{`${t(
                "BangkokBank"
              )}`}</MenuItem>
              <MenuItem onClick={onExportingKbank}>{`${t(
                "KasikornBank"
              )}`}</MenuItem>
              <MenuItem onClick={onExportingSCB}>{`${t(
                "SiamCommercialBank"
              )}`}</MenuItem>
              <MenuItem onClick={onExportingDeutscheBank}>
                Deutsche Bank
              </MenuItem>
            </Menu>
            <ButtonBlue
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClickOpenMenu}
              variant="outlined"
              startIcon={<DownloadRoundedIcon />}
            >
              {`${t("VariousFiles")}`}
            </ButtonBlue>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleCloseMenu}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={onExportingSSO}>{`${t("SSO")}`}</MenuItem>
              <MenuItem onClick={onExportingPND}>{`${t("P.N.D.1")}`}</MenuItem>
            </Menu>
          </Fragment>
        )}

        {/* <ButtonBlue
          onClick={onExporting}
          variant="outlined"
          startIcon={<DownloadRoundedIcon />}
        >
          ดาวน์โหลด
        </ButtonBlue> */}
        {/* <ButtonBlue
          onClick={onExportingBBL}
          variant="outlined"
          startIcon={<DownloadRoundedIcon />}
        >
          ดาวน์โหลด BBL
        </ButtonBlue> */}
      </div>

      <StyledCard>
        {isDashboard ? (
          <StyledCardContent>
            <DashboardPayrollList
              payRunDetail={payRunDetail ? payRunDetail : null}
            />
          </StyledCardContent>
        ) : (
          <StyledCardContent>
            {payRunDetailEmployees && payRunDetail.isDraft === 1 && (
              <Stack direction={"row"} alignItems={"center"} spacing={1}>
                {doCompare && (
                  <Stack direction={"row"} alignItems={"center"} spacing={1}>
                    <StyledAutocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={filteredColumns}
                      getOptionLabel={(option) => `${option.name}`}
                      sx={{ width: 300 }}
                      onChange={(event, newValue) => {
                        handleColumnChange(newValue);
                      }}
                      value={selectedColumns}
                      renderInput={(params) => (
                        <TextFieldTheme {...params} label="เลือกประเภท" />
                      )}
                    />

                    <StyledAutocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={filteredPercentageDiff}
                      getOptionLabel={(option) => `${option.name}`}
                      sx={{ width: 300 }}
                      onChange={(event, newValue) => {
                        handlePercentage(newValue);
                      }}
                      value={selectedPercent}
                      disabled={!selectedColumns}
                      renderInput={(params) => (
                        <TextFieldTheme {...params} label="% ส่วนต่าง" />
                      )}
                    />

                    <StyledAutocomplete
                      disablePortal
                      id="combo-box-demo"
                      options={payRunDetailEmployees}
                      getOptionLabel={(option) =>
                        `${option.firstname_TH} ${option.lastname_TH}`
                      }
                      sx={{ width: 300 }}
                      onChange={(event, newValue) => {
                        setSelectedEmployees(newValue);
                      }}
                      value={selectedEmployees}
                      renderInput={(params) => (
                        <TextFieldTheme {...params} label="เลือกพนักงาน" />
                      )}
                    />
                  </Stack>
                )}

                {doCompare && (
                  <ButtonBlue
                    variant="contained"
                    onClick={() => handleCancel()}
                  >
                    {t("Cancel")}
                  </ButtonBlue>
                )}
              </Stack>
            )}

            <StyledWrapDataGrid sx={{ mt: 2 }}>
              <PayrollDataGridCard
                customizeDate={customizeDate}
                customizeHours={customizeHours}
                customizeNumber={customizeNumber}
                onSelectionChanged={onSelectionChanged}
                onExporting={onExporting}
                doCompare={doCompare}
                handleClickIndividual={handleClickIndividual}
                gridColumnsOT={gridColumnsOT}
                payRunDetail={payRunDetail}
                data={data}
                gridColumnsShift={gridColumnsShift}
                gridColumnsDeduction={gridColumnsDeduction}
                gridColumnsAddition={gridColumnsAddition}
                selectedColumns={selectedColumns}
                lastPayrun={lastPayrun ? lastPayrun : []}
                selectedEmployees={
                  selectedEmployees ? selectedEmployees.idEmployees : null
                }
              />

              <DialogEdit
                open={openDialog}
                handleClose={handleCloseDialog}
                selectedRowsData={selectedRowsData}
                handleChangeAlertType={handleChangeAlertType}
                handleOpenAlert={handleOpenAlert}
                handleCloseAlert={handleCloseAlert}
                handleGetAllDetail={handleGetAllDetail}
              />
            </StyledWrapDataGrid>
          </StyledCardContent>
        )}
      </StyledCard>
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </Fragment>
  );
};
export default TableEmployee;