import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import dayjs from 'dayjs';
import {
    TextField,
    FormControl,
    Grid,
    Popper,
    Typography,
    Box,
    Alert,
    MenuItem,
    Stack
} from "@mui/material";
import DrawerCustom from "../../shared/general/Drawer";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import { TotalWageOTxlsx } from "./xlsx-export/totalWageOTxlsx";
import ButtonBlue from "../../shared/general/ButtonBlue";
import { TotalHoursOTxlsx } from "./xlsx-export/totatHourOTxlsx";
import TextFieldTheme from "../../shared/general/TextFieldTheme";
import AlertResponse from "../uniRecruit/interviewer/alertResponse";
import { getAllOTHour, getAllOTWage } from "../../../../actions/payruns-export";
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
    maxWidth: 550,
    padding: 24,
    "& .GridTopicInput": {
        display: "flex",
        alignItems: "center"
    },
});

const SummaryOT = (props) => {
    const { open, handleClose } = props;
const { t, i18n } = useTranslation();
    const { control } = useForm({});
    const currentYear = new Date().getFullYear();
    const [selectedYear, setSelectedYear] = useState(null);
    const [selectedYear2, setSelectedYear2] = useState(null);
    const [openAlert, setOpenAlert] = useState(false);
    const [openAlert2, setOpenAlert2] = useState(false);
    const [exportedData, setExportedData] = useState(null);
    const [exportedData2, setExportedData2] = useState(null);

    const handleOpenAlertError = () => {
        setOpenAlert(true);
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const handleOpenAlertError2 = () => {
        setOpenAlert2(true);
    };

    const handleCloseAlert2 = () => {
        setOpenAlert2(false);
    };

    const thaiYears = Array.from(
        { length: 6 },
        (_, index) => currentYear + 543 - index
    );

    const handleYearChange = (event) => {
        setSelectedYear(event.target.value);
    };

    const handleYearChange2 = (event) => {
        setSelectedYear2(event.target.value);
    };

    useEffect(() => {
        const fetchedData = async () => {
            try {
                const data = await getAllOTHour(selectedYear - 543);
                if (data) {
                    setExportedData(data);
                }
            } catch (error) {
                console.error(error);
            }
        }

        if (selectedYear !== null) {
            fetchedData();
        }
    }, [selectedYear])

    useEffect(() => {
        const fetchedData = async () => {
            try {
                const data = await getAllOTWage(selectedYear - 543);
                if (data) {
                    setExportedData2(data);
                }
            } catch (error) {
                console.error(error);
            }
        }

        if (selectedYear !== null) {
            fetchedData();
        }
    }, [selectedYear])

    const handleFileExportOTHour = async (year) => {
        if (exportedData && exportedData.length > 0) {
            TotalHoursOTxlsx(year, exportedData);
        } else {
            handleOpenAlertError();
            return;
        }
    }

    const handleFileExportOTWage = async (year) => {
        if (exportedData2 && exportedData2.length > 0) {
            TotalWageOTxlsx(year, exportedData2);
        } else {
            handleOpenAlertError2();
            return;
        }
    }

    return (
        <DrawerCustom
            title={t("SummaryOT")}
            anchor="right"
            open={open}
            onClose={handleClose}
        >
            <StyledRoot style={{ width: 400 }}>
                <Stack spacing={4}>
                    <Stack spacing={1}>
                        <Typography>{`${t("TotalHours")} OT`}</Typography>
                        <Stack
                            direction={"row"}
                            justifyContent="space-between"
                            alignItems={"center"}
                            spacing={2}
                        >
                            <Controller
                                name="year"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldTheme
                                        {...field}
                                        variant="filled"
                                        select
                                        fullWidth
                                        value={selectedYear}
                                        onChange={handleYearChange}
                                    >
                                        {thaiYears.map((year) => (
                                            <MenuItem key={year} value={year}>
                                                {year}
                                            </MenuItem>
                                        ))}
                                    </TextFieldTheme>
                                )}
                            />
                            <ButtonBlue
                                variant="contained"
                                disabled={selectedYear === null}
                                onClick={() => handleFileExportOTHour(selectedYear - 543)}
                            >
                                {t("Download")}
                            </ButtonBlue>
                        </Stack>
                        {openAlert && (
                            <Alert severity="error" onClose={() => handleCloseAlert()}>{t("NoData")}</Alert>
                        )}
                    </Stack>


                    <Stack spacing={1}>
                        <Typography>{t("SummaryExpensesOT")}</Typography>
                        <Stack
                            direction={"row"}
                            justifyContent="space-between"
                            alignItems={"center"}
                            spacing={2}
                        >
                            <Controller
                                name="year"
                                control={control}
                                render={({ field }) => (
                                    <TextFieldTheme
                                        {...field}
                                        variant="filled"
                                        select
                                        fullWidth
                                        value={selectedYear2}
                                        onChange={handleYearChange2}
                                    >
                                        {thaiYears.map((year) => (
                                            <MenuItem key={year} value={year}>
                                                {year}
                                            </MenuItem>
                                        ))}
                                    </TextFieldTheme>
                                )}
                            />
                            <ButtonBlue
                                variant="contained"
                                disabled={selectedYear2 === null}
                                onClick={() => handleFileExportOTWage(selectedYear2 - 543)}
                            >
                                {t("Download")}
                            </ButtonBlue>
                        </Stack>
                        {openAlert2 && (
                            <Alert severity="error" onClose={() => handleCloseAlert2()}>{t("NoData")}</Alert>
                        )}
                    </Stack>
                </Stack>
            </StyledRoot>
        </DrawerCustom >
    )
}

export default SummaryOT;