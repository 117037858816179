import React, { useEffect, useState } from "react";
import DrawerCustom from "../../../shared/general/Drawer";
import { Box, Grid, Link, Typography, styled } from "@mui/material";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import DragDrop from "./dragDrop";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { dumpExcelEmployeeShiftDaily } from "../../../../../actions/shift";
import { getAllEmployeesShift } from "../../../../../actions/employee";
import CancelIcon from "../../../assets/cancel.png";
import CircularProgress from '@mui/material/CircularProgress';
import { openNotificationAlert } from "../../../../../actions/notificationAlert";
import ExcelJS from 'exceljs';

const StyledRoot = styled(Box)({
  padding: "16px",
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0
      }
    }
  },
  "& .field-label": {
    paddingBottom: "4px",
    fontWeight: "500",
    fontSize: "14px"
  }
})

const DrawerUpload = (props) => {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { drawerConfig, onClose } = props;

  const [errorConfig, setErrorConfig] = useState({
    isError: false,
    message: "",
  })

  const validateYupSchema = yup.object({
    file: yup.mixed().required(t("pleaseUploadFile")).nullable()
  })

  const useHookForm = useForm({
    defaultValues: {
      file: null
    },
    resolver: yupResolver(validateYupSchema),
    mode: "all"
  })

  const handleClickDownloadTemplate = () => {
    window.open(`${process.env.REACT_APP_API_URL}files/Employee Shift Template v1.24.326.xlsx`)
  }

  useEffect(() => {
    if (drawerConfig.isOpen === true) {
      useHookForm.reset({
        file: null
      })
      setErrorConfig(prev => ({
        ...prev,
        isError: false,
      }))
    }
  }, [drawerConfig.isOpen])

  const onSubmit = async (data) => {

    const formData = new FormData();
    formData.append("file", data.file);
    formData.append("idShiftGroup", drawerConfig.data.shiftGroup.idShiftGroup);
    formData.append("idCompany", drawerConfig.data.shiftGroup.idCompany);
    const result = await dispatch(dumpExcelEmployeeShiftDaily(formData));

    if (result) {
      if (result.status === 200) {
        dispatch(openNotificationAlert({
          type: "success",
          message: t("uploadWorkingTimeSuccess")
        }));
        onClose();
        dispatch(getAllEmployeesShift(drawerConfig.data.shiftGroup.idShiftGroup, drawerConfig.data.tempSearchDate))
      } else {
        setErrorConfig({
          isError: true,
          message: result.data.message,
        })
      }
    } else {
      setErrorConfig({
        isError: true,
        message: result.data.message,
      })
    }
  }

  const handleClickDownloadShiftDailyXlsx = async () => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(t("employeeShiftDaily"));

    const headerRow = worksheet.addRow([
      t("EmployeeID"),
      t("FirstName"),
      t("LastName"),
      t("PersonalID"),
      `${t("Month")} (YYYY-MM)`,
      ...Array.from({ length: 31 }, (_, index) => `${t("Date")} ${index + 1}`),
    ]);

    headerRow.height = 50;
    const headerStyle = {
      font: { bold: true, size: 18, name: 'TH SarabunPSK', color: { argb: 'FFFFFFFF' } },
      alignment: { horizontal: "center", vertical: 'middle' },
      fill: {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '002060' }
      },
    };

    headerRow.eachCell((cell) => {
      cell.style = headerStyle;
      cell.border = {
        top: { style: 'thin', color: { argb: '000000' } },
        left: { style: 'thin', color: { argb: '000000' } },
        bottom: { style: 'thin', color: { argb: '000000' } },
        right: { style: 'thin', color: { argb: '000000' } }
      };
    });

    const colWidths = [
      { key: "employeeID", width: 50 },
      { key: "firstname", width: 50 },
      { key: "lastname", width: 50 },
      { key: "personalID", width: 50, numFmt: '0' },
      { key: "month", width: 50 },
      ...Array.from({ length: 31 }, () => ({ width: 20 }))
    ];

    colWidths.forEach((col, index) => {
      const column = worksheet.getColumn(index + 1);
      column.width = col.width;
      if (col.numFmt) {
        column.numFmt = col.numFmt; // Apply number format if specified
      }
    });

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${t("DownloadTemplate")} ${t("employeeShiftDaily")}.xlsx`;
      a.click();
      URL.revokeObjectURL(url);
    });

  };

  return (
    <DrawerCustom
      title={t("UploadWorkingHours")}
      anchor="right"
      open={drawerConfig.isOpen}
      onClose={() => {
        if (!useHookForm.formState.isSubmitting) {
          onClose()
        }
      }}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px"
        }
      }}
    >
      <StyledRoot>
        {!errorConfig.isError && <form onSubmit={useHookForm.handleSubmit(onSubmit, (error) => { console.log(error) })}>
          <Grid container spacing={2}>
            <Grid item xs={12} container justifyContent="flex-end">
              <Link variant="body2" color="#46cbe2" onClick={handleClickDownloadTemplate} style={{ cursor: "pointer" }}>
                {t("DownloadTemplate")} {t("employeeShift")} v1.24.326.xlsx
              </Link>
            </Grid>
            <Grid item xs={12} container justifyContent="flex-end">
              {/* <Link variant="body2" color="#46cbe2" onClick={handleClickDownloadShiftDailyXlsx} style={{ cursor: "pointer" }}>
                {t("DownloadTemplate")} {t("employeeShiftDaily")}.xlsx
              </Link> */}

              <Link variant="body2" color="#acacac" style={{ cursor: "pointer" }}>
                {t("DownloadTemplate")} {t("employeeShiftDaily")}.xlsx
              </Link>
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize="14px" color="text.secondary">{t("ShiftGroup")}</Typography>
              <Typography fontSize="20px" fontWeight="500">{drawerConfig.data.shiftGroup.shiftGroupName}</Typography>
              <Typography fontSize="12px" color="text.secondary">({drawerConfig.data.shiftGroup.shiftGroupCode})</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize="14px" color="text.secondary">{t("currentShiftWorkingTime")}</Typography>
              {(drawerConfig.data.shiftGroup && drawerConfig.data.shiftGroup.shiftType)
                ? drawerConfig.data.shiftGroup.shiftType.map(item => (
                  <Box key={item.idShiftType} paddingTop="16px">
                    <Typography fontSize="12px" color="text.secondary" lineHeight="1">
                      {dayjs(item.timeIn, "HH:mm:ss").format("HH:mm")} - {dayjs(item.timeOut, "HH:mm:ss").format("HH:mm")}
                    </Typography>
                    <Typography fontSize="20px" fontWeight="500">
                      {item.shiftTypeName}
                    </Typography>
                    <Typography fontSize="12px" color="text.secondary">
                      ({item.shiftTypeCode})
                    </Typography>
                  </Box>
                ))
                : <></>}
            </Grid>
            <Grid item xs={12}>
              <Typography fontSize="14px" color="text.secondary" paddingBottom="8px">{t("SelectUploadItem")}</Typography>
              <DragDrop useHookForm={useHookForm} />
            </Grid>
            <Grid item xs={12} container justifyContent="space-between">
              <ButtonBlue variant="text" onClick={onClose} disabled={useHookForm.formState.isSubmitting}>{t("Cancel")}</ButtonBlue>
              <ButtonBlue
                variant="contained"
                type="submit"
                disabled={useHookForm.formState.isSubmitting}
                startIcon={useHookForm.formState.isSubmitting ? <CircularProgress style={{ color: "inherit", width: "16px", height: "16px" }} /> : undefined}
              >
                {t("Upload")}
              </ButtonBlue>
            </Grid>
          </Grid>
        </form>}
        {errorConfig.isError && (
          <Grid container spacing={2}>
            <Grid item xs={12} container justifyContent="center">
              <img src={CancelIcon} width="60" style={{ marginBottom: 16 }} />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" align="center" whiteSpace="pre-line" style={{ wordBreak: "break-work" }}>
                {errorConfig.message}
              </Typography>
            </Grid>
            <Grid item xs={12} container justifyContent="center">
              <ButtonBlue variant="contained" onClick={onClose}>{t("close")}</ButtonBlue>
            </Grid>
          </Grid>
        )}
      </StyledRoot>
    </DrawerCustom>
  )
}

export default DrawerUpload;