import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Typography, styled, Grid, Divider } from "@mui/material";
import { useDropzone } from "react-dropzone";

import EditIcon from "@mui/icons-material/Edit";

import DialogEdit from "./DialogEdit";

import ButtonBlue from "../../../../shared/general/ButtonBlue";

//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledWrapBranch = styled("div")({
  marginBottom: 36,
  "& .wrap-branch-top": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
    "& .MuiTypography-body2": {
      margin: 0,
    },
  },
  "& .branch-scroll": {
    height: 280,
    overflow: "auto",
  },
  "& .action": {
    textAlign: "right",
    "& .fal": {
      fontSize: 18,
    },
  },
  "& .address": {
    fontWeight: 600,
    fontSize: 18,
  },
  "& .contact": {
    "& .fal": {
      marginRight: 8,
    },
  },
  "& .dropzone-company-profile": {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#bdbdbd",
    outline: "none",
    transition: "border 0.24s ease-in-out",
    minHeight: 160,
    margin: "auto",
    borderRadius: 8,
    padding: 8,
    border: "1px dashed rgba(145, 158, 171, 0.32)",
  },
  "& .inner-dropzone-company-profile": {
    cursor: "pointer",
    zIndex: 0,
    width: "fit-content",
    height: "100%",
    outline: "none",
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    borderRadius: 8,
    position: "relative",
    "& .placeholder": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  "& .uploadImage": {
    width: "100%",
    height: "fit-content",
    zIndex: 8,
  },
});

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 14,
});

const StyledDivider = styled(Divider)({
  marginTop: 0,
  marginBottom: 16,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledEmpty = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 50,
});

const LocationTab = (props) => {
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { t, i18n } = useTranslation();

  const [fileSelected, setFileSelected] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [mode, setMode] = useState("");

  const handleClick = (mode) => {
    setOpenDialog(true);
    setMode(mode);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png",
    onDrop: (acceptedFiles) => {
      let formData = new FormData();
      acceptedFiles.map((file) => formData.append("file", file));
      setFileSelected(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
    },
    maxFiles: 1,
  });

  const thumbs = () => {
    if (employeeProfile.criminalImage) {
      return (
        <img
          alt={employeeProfile.name}
          src={`${process.env.REACT_APP_API_URL}image/criminalImage/${employeeProfile.criminalImage}`}
          className={`uploadImage`}
        />
      );
    } else {
      if (fileSelected.length !== 0) {
        return (
          <img
            alt={fileSelected[0].name}
            src={fileSelected[0].preview}
            className={`uploadImage`}
          />
        );
      }
    }
  };

  return (
    <div>
      <StyledWrapBranch>
        <div className="wrap-branch-top">
          <StyledHeadLabel variant="body2" gutterBottom>
            {t("PersonalInfo")}
          </StyledHeadLabel>
          {!userProfile.readOnly && (
            <ButtonBlue
              variant="text"
              size="small"
              startIcon={<EditIcon />}
              onClick={() => handleClick("personalDetail")}
            >
              {t("EditData")}
            </ButtonBlue>
          )}
        </div>
        {employeeProfile && (
          <Grid container spacing={2} style={{ marginBottom: 16 }}>
            <Grid item xs={12} md={6}>
              <StyledHeadLabel color="text.secondary">
                Passport ID
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.passportNumber
                  ? employeeProfile.passportNumber
                  : "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledHeadLabel color="text.secondary">
                {t("Status")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.maritalStatus
                  ? employeeProfile.maritalStatus
                  : "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledHeadLabel color="text.secondary">
                {t("Nationality")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.nationality
                  ? employeeProfile.nationality
                  : "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledHeadLabel color="text.secondary">
                {t("Religion")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.idReligion
                  ? t(`Religion_Code.${employeeProfile.religionCode}`)
                  : "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledHeadLabel color="text.secondary">
                Work Permit
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.workPermitNumber
                  ? employeeProfile.workPermitNumber
                  : "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledHeadLabel color="text.secondary">
                Tax ID
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.taxID
                  ? employeeProfile.taxID
                  : "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledHeadLabel color="text.secondary">
                SSO ID
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.ssoID
                  ? employeeProfile.ssoID
                  : "-"}
              </Typography>
            </Grid>
          </Grid>
        )}
      </StyledWrapBranch>
      <StyledDivider />
      <StyledWrapBranch>
        <div className="wrap-branch-top">
          <StyledHeadLabel variant="body2" gutterBottom>
            {t("Address")}
          </StyledHeadLabel>
          {!userProfile.readOnly && ( 
            <ButtonBlue
              variant="text"
              size="small"
              startIcon={<EditIcon />}
              onClick={() => handleClick("address")}
            >
              {t("EditData")}
            </ButtonBlue>
          )}
        </div>
        {employeeProfile && (
          <Grid container spacing={2} style={{ marginBottom: 16 }}>
            <Grid item xs={6} sm={4}>
              <StyledHeadLabel color="text.secondary">
                {t("HouseNo")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.houseNo ? employeeProfile.houseNo : "-"}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4}>
              <StyledHeadLabel color="text.secondary">
                {t("Village")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.village ? employeeProfile.village : "-"}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4}>
              <StyledHeadLabel color="text.secondary">
                {t("VillageNo")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.villageNo ? employeeProfile.villageNo : "-"}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4}>
              <StyledHeadLabel color="text.secondary">
                {t("Alley")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.alley ? employeeProfile.alley : "-"}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4}>
              <StyledHeadLabel color="text.secondary">
                {t("Road")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.road ? employeeProfile.road : "-"}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4}>
              <StyledHeadLabel color="text.secondary">
                {t("SubDistrict")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.subDistrict
                  ? employeeProfile.subDistrict
                  : "-"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <StyledHeadLabel color="text.secondary">
                {t("District")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.district
                  ? employeeProfile.district.replace("อำเภอ", "").trim()
                  : "-"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <StyledHeadLabel color="text.secondary">
                {t("Province")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.provience
                  ? employeeProfile.provience.replace("จังหวัด", "").trim()
                  : "-"}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <StyledHeadLabel color="text.secondary">
                {t("AreaCode")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.areaCode ? employeeProfile.areaCode : "-"}
              </Typography>
            </Grid>
          </Grid>
        )}
      </StyledWrapBranch>
      <StyledDivider />
      <StyledWrapBranch>
        <div className="wrap-branch-top">
          <StyledHeadLabel variant="body2" gutterBottom>
            {t("EmergencyContact")}
          </StyledHeadLabel>
          {!userProfile.readOnly && ( 
            <ButtonBlue
              variant="text"
              size="small"
              startIcon={<EditIcon />}
              onClick={() => handleClick("emergency")}
            >
              {t("EditData")}
            </ButtonBlue>
          )}
        </div>
        {employeeProfile && (
          <Grid container spacing={2} style={{ marginBottom: 16 }}>
            <Grid item xs={12}>
              <StyledHeadLabel color="text.secondary">
                {t("FullName")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.emergencyContact
                  ? employeeProfile.emergencyContact
                  : "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledHeadLabel color="text.secondary">
                {t("EmergencyPhone")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.emergencyPhone
                  ? employeeProfile.emergencyPhone
                  : "-"}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <StyledHeadLabel color="text.secondary">
                {t("EmergencyRelationship")}
              </StyledHeadLabel>
              <Typography variant="h6">
                {employeeProfile.emergencyRelationship
                  ? employeeProfile.emergencyRelationship
                  : "-"}
              </Typography>
            </Grid>
          </Grid>
        )}
      </StyledWrapBranch>
      <StyledDivider />
      <StyledWrapBranch>
        <div className="wrap-branch-top">
          <StyledHeadLabel variant="body2" gutterBottom>
            {t("CriminalImage")}
          </StyledHeadLabel>
          {!userProfile.readOnly && ( 
            <ButtonBlue
              variant="text"
              size="small"
              startIcon={<EditIcon />}
              onClick={() => handleClick("criminalImage")}
              disabled
            >
              {t("EditData")}
            </ButtonBlue>
          )}
        </div>
        {
          employeeProfile && (
            <StyledEmpty className="empty-holiday">
              <Typography color="text.secondary" align="center">
                {t("NoData")}
              </Typography>
            </StyledEmpty>
          )
          // <Fragment>
          //     <div className="inner-dropzone-company-profile">
          //       <Fragment>{thumbs()}</Fragment>
          //       <div
          //         className={`placeholder ${
          //           (fileSelected.length != 0 ||
          //             employeeProfile.criminalImage != null) &&
          //           `placeholderImageProfile`
          //         }`}
          //       >
          //         <AddAPhotoIcon />
          //         <Typography
          //           style={{ marginTop: 8, backgroundColor: "transparent" }}
          //           className={`${fileSelected != 0 && `placeholderLabel`}`}
          //           variant="body2"
          //         >
          //           Upload Photo
          //         </Typography>
          //       </div>
          //   </div>
          // </Fragment>
        }
      </StyledWrapBranch>

      <DialogEdit
        mode={mode}
        open={openDialog}
        handleCloseDialog={handleCloseDialog}
      />
    </div>
  );
};

export default LocationTab;
