import React, { useState, useEffect } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import "dayjs/locale/th";

import {
  Button,
  Container,
  Typography,
  CardContent,
  ToggleButtonGroup,
  ToggleButton,
  TablePagination,
  TextField,
  Grid,
  Popper,
  Box,
  Divider,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  FormControl,
  FormHelperText,
  Menu,
  MenuItem,
  IconButton,
  createFilterOptions,
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import Dropzone, { useDropzone } from "react-dropzone";

import AddRoundedIcon from "@mui/icons-material/AddRounded";
import DeleteIcon from "@mui/icons-material/Delete";
import FilePresentIcon from "@mui/icons-material/FilePresent";

import GalleryIcon from "../../../../assets/gallery.png";
import utils from "../../../../../../utils";
import { currency } from "../../../../assets/data/currency";

import ButtonBlue from "../../../../shared/general/ButtonBlue";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import AlertResponse from "../../../../shared/general/AlertResponse";
import NumberFormatTheme from "../../../../shared/general/NumberFormatTheme";

import SelectApprover from "../../select-approver";
import ActionButtons from "../action-buttons";
import SelectCC from "../../select-cc";

import {
  addExpenseAdvance,
  getExpensePaymentTypes,
  getExpenseReturnAdvanceTypes,
} from "../../../../../../actions/expense";
import { getEmployeesRoleAdmin } from "../../../../../../actions/admins";
import { getUserFullName } from "../../../../../../utils/userData";
import DatePickerCustom from "../../../../shared/date/datePicker";

const StyledHeadLabel = styled(Typography)({
  fontWeight: 600,
  "&.MuiTypography-h6": {
    fontSize: 18,
  },
  "&.MuiTypography-body2": {
    fontSize: 14,
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiOutlinedInput-root": {
    height: 50,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 24,
  marginBottom: 20,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  display: "flex",
  "& .MuiToggleButtonGroup-grouped": {
    color: "#46cbe2",
    border: "1px solid #46cbe2",
    backgroundColor: "#FFFFFF",
    "&:hover": {
      borderColor: "#00C0E1",
      backgroundColor: "#00C0E1",
      color: "#FFFFFF",
    },
    "&.Mui-selected": {
      color: "#FFFFFF",
      backgroundColor: "#46cbe2",
      "&:hover": {
        borderColor: "#00C0E1",
        backgroundColor: "#00C0E1",
        color: "#FFFFFF",
      },
    },
  },
  "& ,MuiToggleButtonGroup-root": {
    height: 50,
  },
}));

const StyledPopper = styled(Popper)({
  "& .MuiPaper-root": {
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
    transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      paddingLeft: 8,
      margin: "0 8px",
      borderRadius: 8,
      "& .MuiTypography-root": {
        textAlign: "left",
        width: "100%",
      },
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledDropzone = styled(Box)({
  width: "100%",
  marginBottom: 24,
  backgroundColor: "#f9f9fb",
  "& .dropzone-leave": {
    // height: 98,
    borderRadius: 8,
    padding: 8,
    border: "1px dashed rgba(145, 158, 171, 0.32)",
  },

  "& .inner-dropzone": {
    cursor: "pointer",
    zIndex: 0,
    width: "100%",
    // height: 90,
    outline: "none",
    display: "flex",
    overflow: "hidden",
    borderRadius: 4,
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    // backgroundColor: "#efefef",
    flexDirection: "column",
    padding: "40px 0",
    "& img": {
      width: 72,
      height: 72,
    },
  },
  "& .inner-dropzone:hover .placeholder": {
    zIndex: 9,
  },
});

const Advance = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { typeExpense } = props;
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: employees } = useSelector((state) => state.employees);
  const { result: admins } = useSelector((state) => state.admins);
  const { result: expensePaymentTypes } = useSelector(
    (state) => state.expensePaymentTypes
  );
  const { result: expenseReturnAdvanceTypes } = useSelector(
    (state) => state.expenseReturnAdvanceTypes
  );
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [listMenuTaxOrWithholding, setListMenuTaxOrWithholding] =
    React.useState(null);
  const open = Boolean(anchorEl);
  const [fileSelected, setFileSelected] = useState([]);
  const [isInternational, setIsInternational] = useState(false);
  const [currencyRate, setCurrencyRate] = useState(1.0);
  const today = dayjs().toDate();
  const [tempDate, setTempDate] = useState(
    new Date(today.getFullYear(), today.getMonth(), today.getDate())
  );
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const periodRegex = new RegExp(/^\d+(\.\d{0,2})?$/);
  let [paymentTypes, setPaymentTypes] = useState([]);
  let [returnTypes, setReturnTypes] = useState([]);
  const [modalStatusConfig, setModalStatusConfig] = useState({
    open: false,
    title: "",
    content: "",
    type: "success",
  });
  const [formData, setFormData] = useState({
    nameExpense: "",
    description: "",
    currency: "THB",
    currencyItem: currency()[0],
    currencyUnit: currency()[0].unit,
    currencyRate: currencyRate,
    file: null,
    remark: "",
    typeExpenseName: typeExpense,
    total: 0,
    net: 0,
    approver: "",
    cc_email: [],
    reviewer: null,
    paymentType: 1,
  });
  const defaultCurrency = currency()[0];
  const MAX_FILE_SIZE = 512000; //500KB

  const validFileExtensions = { file: ["jpg", "png", "jpeg"] };

  const isValidFileType = (fileName, fileType) => {
    return (
      fileName &&
      validFileExtensions[fileType].indexOf(fileName.split(".").pop()) > -1
    );
  };

  const filterOptions = createFilterOptions({
    stringify: (value) =>
      `${value.firstname_TH} ${value.lastname_TH} ${value.email}`,
  });

  const validationSchema = Yup.object().shape({
    nameExpense: Yup.string().required(t("ThisFieldIsRequired")),
    approver: Yup.string().required(t("ThisFieldIsRequired")),
    currency: Yup.string().required(t("ThisFieldIsRequired")),
    currencyUnit: Yup.string().required(t("ThisFieldIsRequired")),
    remark: Yup.string(),
    typeExpenseName: Yup.string().required(t("ThisFieldIsRequired")),
    total: Yup.string().required(t("ThisFieldIsRequired")).default(0),
    net: Yup.string().required(t("ThisFieldIsRequired")).default(0),
    file: Yup.mixed()
      .test("is-valid-type", "Not a valid file type", (value) => {
        if (value) {
          return isValidFileType(value && value.name.toLowerCase(), "file");
        } else {
          return true;
        }
      })
      .test("is-valid-size", "Max allowed size is 500KB", (value) => {
        if (value) {
          return value && value.size <= MAX_FILE_SIZE;
        } else {
          return true;
        }
      }),
  });

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
    watch,
    reset,
    register,
  } = useForm({
    defaultValues: { ...formData },
    resolver: yupResolver(validationSchema),
  });

  const { append, remove, fields } = useFieldArray({
    control: control,
    name: "listExpense",
  });

  const checkValidation = (error) => {
    console.log(getValues("listExpense"));
    console.log("error : ", error);
    setModalStatusConfig({
      open: true,
      title: "",
      content: t("ThisFieldIsRequired"),
      type: "error",
    });
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png, application/pdf",
    onDrop: (acceptedFiles) => {
      let formDataFile = new FormData();
      acceptedFiles.map((file) => formDataFile.append("file", file));
      setFormData({
        ...formData,
        file: formDataFile,
      });
    },
    maxFiles: 1,
  });

  useEffect(() => {
    if (!admins) {
      dispatch(getEmployeesRoleAdmin());
    }
    if (userProfile && userProfile.idManagerLV1 && employees) {
      setFormData({
        ...formData,
        approver: userProfile.idManagerLV1,
      });
      setValue("approver", userProfile.idManagerLV1);
    }
  }, [employees, userProfile]);

  useEffect(() => {
    dispatch(getExpensePaymentTypes());
    dispatch(getExpenseReturnAdvanceTypes());
  }, []);

  useEffect(() => {
    if (expensePaymentTypes) {
      setPaymentTypes(expensePaymentTypes);
    }
  }, [expensePaymentTypes]);

  useEffect(() => {
    if (expenseReturnAdvanceTypes) {
      setReturnTypes(expenseReturnAdvanceTypes);
    }
  }, [expenseReturnAdvanceTypes]);

  const thumbs = fileSelected.map((file) => (
    <img key={file.name} src={file.preview} />
  ));

  const convertCurrency = (value) => {
    if (getValues("currencyRate")) {
      return fixPoint(
        parseFloat(value) * parseFloat(getValues("currencyRate"))
      );
    } else {
      return 0;
    }
  };

  const fixPoint = (value) => {
    return parseFloat(value).toFixed(2);
  };

  const createFormData = (statusId) => {
    let data = new FormData();
    data.append("nameExpense", getValues("nameExpense"));
    data.append("description", getValues("description"));
    data.append("currency", getValues("currency"));
    data.append("currencyItem", getValues("currencyItem"));
    data.append("remark", getValues("remark"));
    data.append("receiveDate", getValues("receiveDate"));
    data.append("returnDate", getValues("returnDate"));
    data.append("typeExpense", 6);
    data.append("typeExpenseName", getValues("typeExpenseName"));
    data.append("lastUpdateDate", dayjs(today).format("YYYY/MM/DD HH:mm"));
    data.append("status", statusId);
    data.append("total", getValues("total"));
    data.append("net", getValues("net"));
    data.append("idEmpApprover", getValues("approver"));
    data.append(
      "idEmpReviewer",
      getValues("reviewer")
        ? getValues("reviewer").idEmployees
        : JSON.stringify(null)
    );
    data.append(
      "cc_email",
      JSON.stringify(
        getValues("cc_email")
          ? getValues("cc_email")
              .map((e) => e.email)
              .join(";")
          : null
      )
    );
    data.append("idPosition", userProfile.idPosition);
    return data;
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleOnClickCleardata = () => {
    reset(formData);
  };

  const handleOnClickSavedraft = async () => {
    let data = createFormData(1);
    let result = null;
    if (userProfile && userProfile.idCompany && userProfile.idEmployees) {
      console.log(userProfile);
      result = await dispatch(addExpenseAdvance(data));
    }
    if (result) {
      if (result.status === 200) {
        handleChangeAlertType("success");
        handleOpenAlert();
        history.push(`/expense/Advance/Draft/${result.data.idExpense}`);
      } else {
        handleChangeAlertType("error");
        handleOpenAlert();
      }
    }
  };

  const handleOnClickSent = async () => {
    let data = createFormData(2);
    let result = null;
    if (userProfile && userProfile.idCompany && userProfile.idEmployees) {
      result = await dispatch(addExpenseAdvance(data));
    }
    if (result) {
      if (result.status === 200) {
        handleChangeAlertType("success");
        handleOpenAlert();
        history.push(`/expense`);
      } else {
        handleChangeAlertType("error");
        handleOpenAlert();
      }
    }
  };

  return (
    <Box>
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
      <form>
        <Grid container spacing={2} style={{}} alignItems={"center"}>
          <Grid item xs={12}>
            <div className="wrap-head">
              <StyledHeadLabel variant="h6">
                {t("GeneralInformation")}
              </StyledHeadLabel>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <StyledHeadLabel variant="body2" color="text.third" gutterBottom>
                {t("TitleName")}
              </StyledHeadLabel>
              <FormControl
                fullWidth
                error={errors && errors.nameExpense ? true : false}
              >
                {errors && errors.nameExpense && errors.nameExpense.message && (
                  <FormHelperText error>
                    {errors.nameExpense.message}
                  </FormHelperText>
                )}
                <Controller
                  name="nameExpense"
                  control={control}
                  errors={errors}
                  render={({ field }) => (
                    <>
                      <TextFieldTheme
                        {...field}
                        name="nameExpense"
                        inputProps={{ maxLength: 200 }}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                      />
                      <Typography
                        component="p"
                        textAlign="right"
                        variant="caption"
                        color="text.secondary"
                      >{`${field.value.length}/200`}</Typography>
                    </>
                  )}
                />
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={12}>
            <div>
              <StyledHeadLabel variant="body2" color="text.third" gutterBottom>
                {t("Descriptions")}
              </StyledHeadLabel>
              <FormControl
                fullWidth
                error={errors && errors.description ? true : false}
              >
                {errors && errors.description && errors.description.message && (
                  <FormHelperText error>
                    {errors.description.message}
                  </FormHelperText>
                )}
                <Controller
                  name="description"
                  control={control}
                  errors={errors}
                  render={({ field }) => (
                    <>
                      <TextFieldTheme
                        {...field}
                        name="description"
                        inputProps={{ maxLength: 500 }}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                        }}
                      />
                      <Typography
                        component="p"
                        textAlign="right"
                        variant="caption"
                        color="text.secondary"
                      >{`${field.value.length}/500`}</Typography>
                    </>
                  )}
                />
              </FormControl>
            </div>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              error={errors && errors.approver ? true : false}
            >
              {errors && errors.approver && errors.approver.message && (
                <FormHelperText error>{errors.approver.message}</FormHelperText>
              )}
              <Controller
                name="approver"
                control={control}
                errors={errors}
                render={({ field }) => (
                  <>
                    <SelectApprover
                      {...field}
                      idApprover={getValues("approver")}
                      handleChange={setValue}
                      position={userProfile && userProfile.idCompany === 3}
                    />
                  </>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <SelectCC control={control} errors={errors} />
          </Grid>

          <Grid item xs={12}>
            <StyledHeadLabel variant="body2" color="text.third" gutterBottom>
              {t("Inspector")}
            </StyledHeadLabel>
            <FormControl
              fullWidth
              error={errors && errors.reviewer ? true : false}
            >
              {errors && errors.reviewer && errors.reviewer.message && (
                <FormHelperText error>{errors.reviewer.message}</FormHelperText>
              )}
              <Controller
                name="reviewer"
                control={control}
                errors={errors}
                render={({ field }) => (
                  <>
                    <StyledAutocomplete
                      options={admins ? admins : []}
                      getOptionLabel={(option) => getUserFullName(option)}
                      isOptionEqualToValue={(option, value) =>
                        option.idEmployees === value.idEmployees
                      }
                      filterOptions={filterOptions}
                      renderOption={(props, option) => (
                        <MenuItem {...props} key={option.idEmployees}>
                          <Typography>{getUserFullName(option)}</Typography>
                        </MenuItem>
                      )}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          placeholder={t("AddInspector")}
                          onBlur={field.onBlur}
                        />
                      )}
                      value={field.value}
                      onChange={(_, value) => {
                        field.onChange(value);
                      }}
                      noOptionsText={t("NoData")}
                    />
                  </>
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
        <StyledDivider />
        <Grid item xs={12}>
          <div className="wrap-head">
            <StyledHeadLabel variant="h6">
              {t("receiveAdvance")}
            </StyledHeadLabel>
          </div>
        </Grid>
        <Grid>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <StyledHeadLabel
                  variant="body2"
                  color="text.third"
                  gutterBottom
                >
                  {t("Currency")}
                </StyledHeadLabel>
                <FormControl
                  fullWidth
                  error={errors && errors.currency ? true : false}
                >
                  <Controller
                    name="currencyItem"
                    control={control}
                    errors={errors}
                    render={({ field }) => (
                      <>
                        <StyledAutocomplete
                          {...field}
                          fullWidth
                          options={currency()}
                          onChange={(e, newValue) => {
                            if (newValue === null) {
                              setValue("currencyItem", defaultCurrency);
                              setValue("currency", "THB");
                              setValue("currencyUnit", "THB");
                              setValue("currencyRate", 1);
                            } else {
                              if (newValue.unit === "THB") {
                                setValue("currencyRate", 1);
                              }
                              setValue("currencyItem", newValue);
                              setValue("currency", newValue.unit);
                              setValue("currencyUnit", newValue.unit);
                            }
                          }}
                          popupIcon={<i class="fa-light fa-chevron-down"></i>}
                          renderOption={(props, option) => (
                            <Box component="li" {...props}>
                              <img
                                loading="lazy"
                                width="20"
                                style={{ marginRight: 16, flexShrink: 0 }}
                                src={`https://flagcdn.com/16x12/${option.code.toLowerCase()}.png`}
                                srcSet={`https://flagcdn.com/32x24/${option.code.toLowerCase()}.png 2x`}
                                alt={option.code}
                              />
                              {t(`${option.label}`)}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              placeholder=""
                              name="vatType"
                            />
                          )}
                          PopperComponent={StyledPopper}
                          noOptionsText={t("NoData")}
                        />
                      </>
                    )}
                  />
                  {errors && errors.currency && errors.currency.message && (
                    <FormHelperText error>
                      {errors.currency.message}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
              {/* {watch("currency") && watch("currency") !== "THB" && (
                <Grid item xs={12} sm={6}>
                  <StyledHeadLabel
                    variant="body2"
                    color="text.third"
                    gutterBottom
                  >
                    {t("ExchangeRate")}
                    <Typography variant="body" color="text.secondary">
                      {`(${t("Baht")} ${t("Per")} 1 ${t(
                        `${watch("currencyUnit")}`
                      )})`}
                    </Typography>
                  </StyledHeadLabel>
                  <FormControl
                    fullWidth
                    error={errors && errors.currencyRate ? true : false}
                  >
                    <Controller
                      name="currencyRate"
                      control={control}
                      errors={errors}
                      render={({ field }) => (
                        <>
                          <TextFieldTheme
                            {...field}
                            name="currencyRate"
                            InputProps={{
                              inputComponent: NumberFormatTheme,
                            }}
                            inputProps={{
                              decimalScale: 2,
                              allowNegative: false,
                              value: field.value,
                              onValueChange: (values) => {
                                const { value } = values;
                                field.onChange(value);
                              },
                            }}
                          />
                        </>
                      )}
                    />
                  </FormControl>
                  {errors &&
                    errors.currencyRate &&
                    errors.currencyRate.message && (
                      <FormHelperText error>
                        {errors.currencyRate.message}
                      </FormHelperText>
                    )}
                </Grid>
              )} */}
              <Grid item xs={12} sm={6}>
                <div>
                  <StyledHeadLabel
                    variant="body2"
                    color="text.third"
                    gutterBottom
                  >
                    {t("AmountOfMoney")}
                  </StyledHeadLabel>
                  <FormControl
                    fullWidth
                    error={errors && errors.amount ? true : false}
                  >
                    {errors && errors.amount && errors.amount.message && (
                      <FormHelperText error>
                        {errors.amount.message}
                      </FormHelperText>
                    )}
                    <Controller
                      name="amount"
                      control={control}
                      errors={errors}
                      render={({ field }) => (
                        <>
                          <TextFieldTheme
                            {...field}
                            name="amount"
                            inputProps={{ maxLength: 100 }}
                            onChange={(e) => {
                              field.onChange(e.target.value);
                            }}
                          />
                        </>
                      )}
                    />
                  </FormControl>
                </div>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <StyledHeadLabel
                  variant="body2"
                  color="text.third"
                  gutterBottom
                >
                  {t("receiveType")}
                </StyledHeadLabel>
                <FormControl
                  fullWidth
                  error={errors && errors.receiveType ? true : false}
                >
                  {errors &&
                    errors.receiveDate &&
                    errors.receiveType.message && (
                      <FormHelperText
                        error
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        {errors.receiveType.message}
                      </FormHelperText>
                    )}
                  <Controller
                    name="receiveType"
                    control={control}
                    errors={errors}
                    render={({ field }) => (
                      <StyledAutocomplete
                        options={paymentTypes ? paymentTypes : []}
                        getOptionLabel={(option) => option.name_TH}
                        isOptionEqualToValue={(option, value) =>
                          option.idExpensePaymentType ===
                          value.idExpensePaymentType
                        }
                        getOptionSelected={(option, value) =>
                          option.idExpensePaymentType ===
                          value.idExpensePaymentType
                        }
                        filterOptions={filterOptions}
                        renderOption={(props, option) => (
                          <MenuItem
                            {...props}
                            key={option.idExpensePaymentType}
                          >
                            <Box>
                              <Typography>{option.name_TH}</Typography>
                            </Box>
                          </MenuItem>
                        )}
                        renderInput={(params) => (
                          <TextFieldTheme
                            {...params}
                            placeholder={t("Search")}
                            onBlur={field.onBlur}
                          />
                        )}
                        multiple
                        value={field.value}
                        onChange={(_, value) => {
                          console.log(value);
                          console.log(field.value);
                          field.onChange(value);
                        }}
                        noOptionsText={t("NoData")}
                      ></StyledAutocomplete>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledHeadLabel
                  variant="body2"
                  color="text.third"
                  gutterBottom
                >
                  {t("receiveDate")}
                </StyledHeadLabel>
                <FormControl
                  fullWidth
                  error={errors && errors.receiveDate ? true : false}
                >
                  {errors &&
                    errors.receiveDate &&
                    errors.receiveDate.message && (
                      <FormHelperText
                        error
                        sx={{
                          textAlign: "center",
                        }}
                      >
                        {errors.receiveDate.message}
                      </FormHelperText>
                    )}
                  <Controller
                    name={`receiveDate`}
                    control={control}
                    errors={errors}
                    render={({ field }) => (
                      <DatePickerCustom
                        {...field}
                        inputFormat="DD/MM/YYYY"
                        onChange={(e) => {
                          field.onChange(dayjs(e).format("YYYY/MM/DD"));
                        }}
                        renderInput={(params) => (
                          <TextFieldTheme fullWidth {...params} />
                        )}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          {watch("paymentType") == 1 && (
            <Grid item xs={12}>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                  <div>
                    <StyledHeadLabel
                      variant="body2"
                      color="text.third"
                      gutterBottom
                    >
                      {t("BookBank")}
                    </StyledHeadLabel>
                    <FormControl
                      fullWidth
                      error={errors && errors.bookBank ? true : false}
                    >
                      {errors && errors.bookBank && errors.bookBank.message && (
                        <FormHelperText error>
                          {errors.bookBank.message}
                        </FormHelperText>
                      )}
                      <Controller
                        name="bookBank"
                        control={control}
                        errors={errors}
                        render={({ field }) => (
                          <>
                            <TextFieldTheme
                              {...field}
                              name="bookBank"
                              inputProps={{ maxLength: 100 }}
                              onChange={(e) => {
                                field.onChange(e.target.value);
                              }}
                            />
                          </>
                        )}
                      />
                    </FormControl>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <div>
                    <StyledHeadLabel
                      variant="body2"
                      color="text.third"
                      gutterBottom
                    >
                      {t("BookID")}
                    </StyledHeadLabel>
                    <FormControl
                      fullWidth
                      error={errors && errors.accountNumber ? true : false}
                    >
                      {errors &&
                        errors.accountNumber &&
                        errors.accountNumber.message && (
                          <FormHelperText error>
                            {errors.accountNumber.message}
                          </FormHelperText>
                        )}
                      <Controller
                        name="accountNumber"
                        control={control}
                        errors={errors}
                        render={({ field }) => (
                          <>
                            <TextFieldTheme
                              {...field}
                              name="accountNumber"
                              inputProps={{ maxLength: 100 }}
                              onChange={(e) => {
                                field.onChange(e.target.value);
                              }}
                            />
                          </>
                        )}
                      />
                    </FormControl>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          )}

          <StyledDivider />
          <Grid item xs={12}>
            <div className="wrap-head">
              <StyledHeadLabel variant="h6">
                {t("returnAdvance")}
              </StyledHeadLabel>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <StyledHeadLabel
                  variant="body2"
                  color="text.third"
                  gutterBottom
                >
                  {t("returnType")}
                </StyledHeadLabel>
                <FormControl
                  fullWidth
                  error={errors && errors.returnType ? true : false}
                >
                  {errors && errors.returnDate && errors.returnType.message && (
                    <FormHelperText
                      error
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      {errors.returnType.message}
                    </FormHelperText>
                  )}
                  <Controller
                    name="returnType"
                    control={control}
                    errors={errors}
                    render={({ field }) => (
                      <StyledAutocomplete
                        options={returnTypes ? returnTypes : []}
                        getOptionLabel={(option) => option.name_TH}
                        isOptionEqualToValue={(option, value) =>
                          option.idExpenseReturnAdvanceType ===
                          value.idExpenseReturnAdvanceType
                        }
                        getOptionSelected={(option, value) =>
                          option.idExpenseReturnAdvanceType ===
                          value.idExpenseReturnAdvanceType
                        }
                        filterOptions={filterOptions}
                        renderOption={(props, option) => (
                          <MenuItem
                            {...props}
                            key={option.idExpenseReturnAdvanceType}
                          >
                            <Box>
                              <Typography>{option.name_TH}</Typography>
                            </Box>
                          </MenuItem>
                        )}
                        renderInput={(params) => (
                          <TextFieldTheme
                            {...params}
                            placeholder={t("Search")}
                            onBlur={field.onBlur}
                          />
                        )}
                        multiple
                        value={field.value}
                        onChange={(_, value) => {
                          console.log(value);
                          console.log(field.value);
                          field.onChange(value);
                        }}
                        noOptionsText={t("NoData")}
                      ></StyledAutocomplete>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledHeadLabel
                  variant="body2"
                  color="text.third"
                  gutterBottom
                >
                  {t("returnDate")}
                </StyledHeadLabel>
                <FormControl
                  fullWidth
                  error={errors && errors.returnDate ? true : false}
                >
                  {errors && errors.returnDate && errors.returnDate.message && (
                    <FormHelperText
                      error
                      sx={{
                        textAlign: "center",
                      }}
                    >
                      {errors.returnDate.message}
                    </FormHelperText>
                  )}
                  <Controller
                    name={`returnDate`}
                    control={control}
                    errors={errors}
                    render={({ field }) => (
                      <DatePickerCustom
                        {...field}
                        inputFormat="DD/MM/YYYY"
                        onChange={(e) => {
                          field.onChange(dayjs(e).format("YYYY/MM/DD"));
                        }}
                        renderInput={(params) => (
                          <TextFieldTheme fullWidth {...params} />
                        )}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <StyledDivider />
        <div>
          <div>
            <div className="wrap-row-sum">
              <Typography color="text.third">
                {t("TotalValue")}&nbsp;&nbsp;
              </Typography>
              <Typography color="text.third" variant="h6">
                {utils.numberWithCommas(parseFloat(watch("total")))}
              </Typography>
              <Typography color="text.third">
                &nbsp;&nbsp;{t("Baht")}
              </Typography>
            </div>
            <div className="wrap-row-sum">
              <Typography variant="h5">
                {t("TotalNetWorth")}&nbsp;&nbsp;
              </Typography>
              <Typography variant="h4">
                {utils.numberWithCommas(parseFloat(watch("net")))}
              </Typography>
              <Typography variant="h5">&nbsp;&nbsp;{t("Baht")}</Typography>
            </div>
          </div>
        </div>
        <StyledDivider />
        <div>
          <div className="wrap-head">
            <StyledHeadLabel variant="h6">{t("AttachFile")}</StyledHeadLabel>
          </div>
          {!watch("file") ? (
            <StyledDropzone>
              <Dropzone
                accept="image/jpeg, image/png, application/pdf"
                maxFiles={1}
                multiple={false}
                maxSize={3145728}
                onDrop={(acceptedFiles, rejectedFiles) => {
                  if (acceptedFiles.length > 0) {
                    setValue("file", acceptedFiles[0]);
                  }
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: "dropzone-upload-file" })}>
                    <div className="inner-dropzone">
                      <input {...getInputProps()} />
                      {/* <Fragment>{thumbs}</Fragment> */}
                      <div style={{ textAlign: "center" }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <img alt="add" src={GalleryIcon} />
                        </div>
                        <Typography
                          variant="caption"
                          style={{ lineHeight: 0.5, color: "#999999" }}
                        >
                          {t("imageFileNotMore500KB")}
                        </Typography>
                      </div>
                    </div>
                  </div>
                )}
              </Dropzone>
            </StyledDropzone>
          ) : (
            <Box
              style={{
                position: "relative",
                textAlign: "center",
              }}
            >
              <IconButton
                style={{ position: "absolute", margin: 7, right: 0 }}
                color="error"
                onClick={() => {
                  setValue("file", null);
                }}
              >
                <DeleteIcon />
              </IconButton>
              <div
                style={{
                  padding: 15,
                  backgroundColor: "#f9f9fb",
                  display: "flex",
                  borderRadius: 15,
                }}
              >
                <FilePresentIcon
                  height="24"
                  style={{
                    marginRight: 10,
                  }}
                ></FilePresentIcon>
                <Typography>{watch("file.path")}</Typography>
              </div>
            </Box>
          )}
        </div>
        <StyledDivider />
        <div>
          <div className="wrap-head">
            <StyledHeadLabel variant="h6">{t("Note")}</StyledHeadLabel>
          </div>
          <Controller
            name={"remark"}
            control={control}
            errors={errors}
            render={({ field }) => (
              <>
                <TextFieldTheme {...field} multiline rows={4} />
                <Typography
                  component="p"
                  textAlign="right"
                  variant="caption"
                  color="text.secondary"
                >{`${field.value ? field.value.length : 0}/500`}</Typography>
              </>
            )}
          />
        </div>
        <StyledDivider />
        <ActionButtons
          handleOnClickCleardata={handleOnClickCleardata}
          handleSubmitDraft={handleSubmit(
            handleOnClickSavedraft,
            checkValidation
          )}
          handleSubmitSent={handleSubmit(handleOnClickSent, checkValidation)}
        />
      </form>
    </Box>
  );
};

export default Advance;
