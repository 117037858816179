import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Grid,
  SvgIcon,
  Typography,
  styled,
  Chip,
} from "@mui/material";
import CardStyle from "../../../../../shared/general/Card";
import { AccessTimeRounded } from "@mui/icons-material";
import {
  getIndividualObjectiveForApprove,
  updateIndividualKeyResult,
} from "../../../../../../../actions/okrs";
import { useDispatch } from "react-redux";
import dayjs from "dayjs";
import MoonShotSwitch from "../../../../../shared/pages/okrs/components/MoonShotSwitch";
import ChipStatus from "../../../../../shared/pages/okrs/components/ChipStatus";
import { ReactComponent as MoonshotIcon } from "../../../../../assets/moonshot_logo.svg";
import { useTranslation } from "react-i18next";

const StyledRoot = styled(Box)({
  "& .objective-information-container": {
    padding: "16px",
    "& .objective-name": {
      fontSize: "20px",
      fontWeight: "500",
    },
    "& .objective-description": {
      paddingTop: "16px",
      fontSize: "14px",
    },
    "& .impact-header": {
      paddingRight: "8px",
      color: "#919eab",
    },
  },
  "& .key-result-topic-container": {
    margin: "16px 0",
    padding: "0 16px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiTypography-root": {
      fontSize: "18px",
    },
  },
  "& .key-result-wrap": {
    // marginBottom: "24px",
    height: "100%",
  },
  "& .key-result-wrap:last-child": {
    marginBottom: "0",
  },
  "& .key-result-list-container": {
    "& .key-result-item-container": {
      padding: "16px",
      "& .key-result-name": {
        flexGrow: 1,
        fontWeight: "500",
      },
      "& .key-result-description": {
        paddingTop: "16px",
        fontSize: "14px",
      },
      "& .due-date-container": {
        paddingTop: "16px",
        display: "flex",
        alignItems: "center",
        color: "#919eab",
        fontSize: "14px",
        "& .MuiSvgIcon-root": {
          marginRight: "8px",
        },
        "& .MuiTypography-root, & .MuiSvgIcon-root": {
          fontSize: "inherit",
          color: "inherit",
        },
      },
      "& .MuiDivider-root": {
        paddingTop: "16px",
      },
      "& .manager-label": {
        paddingRight: "8px",
        color: "#919eab",
      },
    },
  },
});

const ApprovalIndividualObjective = (props) => {
  const { match } = props;

  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();

  const [individualObjective, setIndividualObjective] = useState(null);

  const [isFetching, setIsFetching] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    let isMounted = true;
    dispatch(
      getIndividualObjectiveForApprove(match.params.idIndividualObjective)
    ).then((res) => {
      if (isMounted) {
        setIsFetching(false);
        if (res.status === 200) {
          setIndividualObjective(res.data);
        } else {
          setIsError(true);
        }
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        {individualObjective && (
          <Fragment>
            <CardStyle style={{ marginTop: "20px" }}>
              <Box padding="24px">
                <Chip
                  label={`${dayjs()
                    .set("year", individualObjective.year)
                    .format(
                      i18n.resolvedLanguage === "th" ? "BBBB" : "YYYY"
                    )}, Q${individualObjective.quarter}`}
                  sx={{
                    border: "2px solid #DB4178",
                    color: "#DB4178",
                    bgcolor: "transparent",
                    fontSize: "20px",
                  }}
                />
                <div
                  style={{
                    padding: 8,
                    display: "flex",
                    flexDirection: "column",
                    gap: 8,
                    marginTop: "20px",
                  }}
                >
                  <Typography fontSize="20px">
                    {individualObjective.objectiveName}
                  </Typography>
                  <Typography fontSize="16px">
                    <span
                      style={{ color: "#919eab", paddingRight: "8px" }}
                    >{`${t("Result")}`}</span>
                    {individualObjective.impactName}
                  </Typography>
                  {individualObjective.description && (
                    <Typography
                      fontSize="14px"
                      paddingTop="16px"
                      whiteSpace="pre-line"
                    >
                      <span
                        style={{ color: "#919eab", paddingRight: "8px" }}
                      >{`${t("Descriptions")}`}</span>
                      {individualObjective.description}
                    </Typography>
                  )}
                </div>
              </Box>
            </CardStyle>

            <Box marginTop="30px" display="flex" justifyContent="flex-end">
              <Typography>
                {`${t("PendingApproval")}`}{" "}
                {
                  individualObjective.keyResults.filter(
                    (k) => k.isApprove === null
                  ).length
                }{" "}
                {`${t("List")}`}
              </Typography>
            </Box>

            <Box marginTop="24px">
              <Grid container spacing={2}>
                {individualObjective.keyResults.map((keyResult, index) => (
                  <Grid item xs={12}>
                    <KeyResult data={keyResult} />
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Fragment>
        )}
      </Container>
    </StyledRoot>
  );
};

const KeyResult = (props) => {
  const { data: value } = props;

  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();

  const [data, setData] = useState(value);

  const [isMoonShotPlanningValue, setIsMoonShotPlanningValue] = useState(false);

  const [isFetching, setIsFetching] = useState(false);

  const onSubmit = async (approve) => {
    setIsFetching(true);

    const formData = {
      isApprove: approve,
      approveDate: dayjs().format("YYYY-MM-DD HH:mm:ss"),
    };

    if (approve === 1) {
      formData.isMoonShotPlanning = isMoonShotPlanningValue ? 1 : 0;
    } else {
      formData.isMoonShotPlanning = 0;
    }

    dispatch(
      updateIndividualKeyResult(data.idIndividualKeyResult, formData)
    ).then((res) => {
      setIsFetching(false);
      if (res.status === 200) {
        setData({
          ...data,
          isApprove: approve,
          isMoonShotPlanning: formData.isMoonShotPlanning,
        });
      }
    });
  };

  const getStatusApprove = (status) => {
    if (status === 1) {
      return 2;
    } else {
      return 3;
    }
  };

  return (
    <Box style={{ marginBottom: "20px" }}>
      <CardStyle>
        <Box padding="24px">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            <Typography fontSize="20px">{data.keyResultName}</Typography>
            {data.isApprove !== null && (
              <Box display="flex" justifyContent="space-between">
                <ChipStatus
                  status={getStatusApprove(data.isApprove)}
                  size="large"
                />
              </Box>
            )}
          </Box>
          {/* {data.isMoonShotPlanning === 1 && (
            <Box display="flex" marginTop="8px">
              <Chip
                icon={
                  <SvgIcon sx={{ fontSize: "36px" }}>
                    <MoonshotIcon />
                  </SvgIcon>
                }
                label="Moon shot"
                sx={{
                  border: "2px solid #DB4178",
                  borderRadius: "20px",
                  color: "#DB4178",
                  bgcolor: "transparent",
                  fontSize: "16px",
                  height: "48px",
                  minWidth: "130px",
                }}
              />
            </Box>
          )} */}

          <Box display="flex" alignItems="center" marginTop="8px">
            <AccessTimeRounded
              fontSize="14px"
              style={{ marginRight: "8px", color: "#919eab" }}
            />
            <Typography
              fontSize="14px"
              lineHeight="1"
              color="text.third"
            >{`${dayjs(data.startDate).format("D MMM")} - ${dayjs(
              data.endDate
            ).format("D MMM BB")}`}</Typography>
          </Box>
          <Box marginTop="20px">
            <Typography fontSize="15px">
              <span style={{ color: "#919eab", paddingRight: "8px" }}>
                {`${t("Strategy")}`}
              </span>
              {data.tacticName}
            </Typography>
            <Typography fontSize="15px" paddingTop="8px">
              <span style={{ color: "#919eab", paddingRight: "8px" }}>{`${t(
                "MeasurementData"
              )}`}</span>
              {data.measureEvidence}
            </Typography>
            <Typography fontSize="15px" paddingTop="8px">
              <span style={{ color: "#919eab", paddingRight: "8px" }}>{`${t(
                "ObjectiveMeasurementData"
              )}`}</span>
              {data.commitQuality}
            </Typography>
            <Typography fontSize="15px" paddingTop="8px">
              <span style={{ color: "#919eab", paddingRight: "8px" }}>{`${t(
                "TypeMeasurementData"
              )}`}</span>
              {data.measureDataType === 1 ? "ตัวเลข" : "คำบรรยาย"}
            </Typography>
            {data.description && (
              <Typography
                fontSize="15px"
                paddingTop="16px"
                whiteSpace="pre-line"
              >
                <span style={{ color: "#919eab", paddingRight: "8px" }}>{`${t(
                  "Descriptions"
                )}`}</span>
                {data.description}
              </Typography>
            )}
          </Box>
          {data.isApprove === null && (
            <Box
              marginTop="8px"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box display="flex" alignItems="center">
                <Typography paddingRight="8px">Moon Shot</Typography>
                <MoonShotSwitch
                  checked={isMoonShotPlanningValue}
                  onChange={(event) => {
                    setIsMoonShotPlanningValue(event.target.checked);
                  }}
                  disabled={isFetching}
                />
              </Box>
              <Box display="flex" gap="8px">
                <Button
                  style={{
                    color: "#ffffff",
                    backgroundColor: "#6FDFBD",
                    borderRadius: "10px",
                    width: "96px",
                  }}
                  onClick={() => {
                    onSubmit(1);
                  }}
                  disabled={isFetching}
                >{`${t("Approved")}`}</Button>
                <Button
                  style={{
                    color: "#ffffff",
                    backgroundColor: "#E46A76",
                    borderRadius: "10px",
                    width: "160px",
                  }}
                  onClick={() => {
                    onSubmit(0);
                  }}
                  disabled={isFetching}
                >{`${t("NotApproved")}`}</Button>
              </Box>
            </Box>
          )}
        </Box>
      </CardStyle>
    </Box>
  );
};

export default ApprovalIndividualObjective;
