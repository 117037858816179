import React from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
} from "@mui/material";
import dayjs from "dayjs";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { useTranslation } from "react-i18next";
import { getUserFullName } from "../../../../../utils/userData";

const More = (props) => {
  const { data } = props;
  const { result: onBoardingEmployee } = useSelector(
    (state) => state.onBoardingEmployeeByID
  );
  const { t, i18n } = useTranslation();

  const StyledTableContainer = styled(TableContainer)({
    width: "100%",
    margin: 0,
    padding: 0,
  });

  const StyledTableCell = styled(TableCell)({
    padding: "8px 16px",
  });

  return (
    <div>
      {onBoardingEmployee && (
        <Box sx={{ padding: 0 }}>
          <StyledTableContainer component={Paper} elevation={0}>
            <Table sx={{ minWidth: 600 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <StyledTableCell align="center">
                    {t("ItemName")}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {t("Inspector")}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {t("Department")}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {t("ProceedInternally")}({t("Unit.Days")})
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    {t("Status")}
                  </StyledTableCell>
                  <StyledTableCell align="center">{t("Date")}</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {onBoardingEmployee.map((row, index) => (
                  <TableRow key={index}>
                    <StyledTableCell align="center">
                      {row.OnBoardingListName}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      <Box display="flex" alignItems="center">
                        <Avatar
                          alt={`${row.reviewer_firstname_TH} ${row.reviewer_lastname_TH}`}
                          src={
                            row.imageProfile
                              ? `data:image/jpeg;base64,${row.imageProfile}`
                              : ""
                          }
                          style={{ marginRight: 8, width: 32, height: 32 }}
                        />
                        {getUserFullName({
                          firstname_TH: row.reviewer_firstname_TH,
                          lastname_TH: row.reviewer_lastname_TH,
                          firstname_EN: row.reviewer_firstname_EN,
                          lastname_EN: row.reviewer_lastname_EN,
                        })}
                      </Box>
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {row.departmentName || row.departmentName_EN || "-"}
                    </StyledTableCell>
                    <StyledTableCell align="center">{row.day}</StyledTableCell>
                    <StyledTableCell align="center">
                      {row.isApprove ? (
                        <CheckCircleOutlineIcon style={{ color: "green" }} />
                      ) : (
                        <CancelOutlinedIcon style={{ color: "red" }} />
                      )}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {dayjs(row.updateDate).format(
                        i18n.resolvedLanguage === "th"
                          ? "DD/MM/BBBB"
                          : "DD/MM/YYYY"
                      )}
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Box>
      )}
    </div>
  );
};

export default More;
