import React, { useEffect } from "react";
import DrawerCustom from "../../../../shared/general/Drawer";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
  styled,
} from "@mui/material";
import AssessmentIcon from '@mui/icons-material/Assessment';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EventIcon from '@mui/icons-material/Event';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import { getEmployeeProfile } from "../../../../../../actions/employee";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getProbation, updateProbationAdmin } from "../../../../../../actions/probation";
import { openNotificationAlert } from "../../../../../../actions/notificationAlert";
import dayjs from "dayjs";

const StyledRoot = styled(Box)({
  padding: "16px",
});

const score = {
  verygood: 100,
  good: 80,
  meduim: 60,
  fair: 40,
  prettybad: 20,
  bad: 0,
};

const DialogEdit = (props) => {
  const { drawerConfig: { isOpen, idProbation }, handleCloseDrawer } = props;
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { result: employeeProfile } = useSelector((state) => state.employeeProfile);
  const { result: probation } = useSelector((state) => state.probationAnswer);

  let scorePercents = 0;
  if (probation) {
    scorePercents = Math.round(probation.question.reduce((acc, curr) => acc + score[curr.answer], 0) / probation.question.length) || 0;
  }

  const validateYupSchema = yup.object({
    probationResult: yup.number().required("กรุณากรอกข้อมูล"),
  });

  const { control, handleSubmit, reset, formState: { isSubmitting, errors } } = useForm({
    defaultValues: { probationResult: "" },
    resolver: yupResolver(validateYupSchema),
  });

  const onSubmit = async (data) => {
    const formData = {
      idProbation: probation.idProbation,
      probationResult: data.result
    }

    const res = await dispatch(updateProbationAdmin(formData))
    if (res && res.status === 200) {
      dispatch(openNotificationAlert({ message: "success", type: "success" }));
      dispatch(getEmployeeProfile(employeeProfile.idEmployees));
    } else {
      dispatch(openNotificationAlert({ message: "error", type: "error" }));
    }
    handleCloseDrawer();
  };

  useEffect(() => {
    dispatch(getProbation(idProbation))
  }, []);

  useEffect(() => {
    if (probation) {
      reset({ probationResult: probation.result })
    }
  }, [probation]);

  return (
    <DrawerCustom
      title={`${t("EditProbationResult")}`}
      anchor="right"
      open={isOpen}
      onClose={handleCloseDrawer}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "500px",
        },
      }}
    >
      {probation && (
        <StyledRoot>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography fontSize="16px" fontWeight="600">
                {`${t("ProbationRound")} ${probation.probationRound}`}
              </Typography>
            </Grid>
            {probation.expandTime && (
              <Grid item xs={12} display={"flex"} alignItems={"center"} gap={1}>
                <AddCircleIcon />
                <Typography>
                  {`${t("ExtendProbationPeriod")}: ${probation.expandTime} ${t("Unit.Days")}`}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} display={"flex"} alignItems={"center"} gap={1}>
              <EventIcon />
              <Typography>
                {`${t("ProbationaryExpirationDate")} :  ${dayjs(
                  probation.probationDate
                ).format(
                  i18n.resolvedLanguage === "th" ? "D MMM BBBB" : "D MMM YYYY"
                )}`}
              </Typography>
            </Grid>
            <Grid item xs={12} display={"flex"} alignItems={"center"} gap={1}>
              <CheckCircleIcon />
              <Typography>
                {`${t("EvaluatedBy")} : ${
                  probation.approveBy === null
                    ? "ประเมินโดยระบบ"
                    : i18n.resolvedLanguage === "th"
                    ? probation.approvedBy
                    : probation.approvedBy_EN
                }`}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider
                sx={{
                  marginTop: "16px",
                  borderColor: "#9e9e9e66",
                }}
              />
            </Grid>
            {probation && (
              <>
                <Grid item xs={12}>
                  {probation.question.map((data, index) => (
                    <Grid key={index} container>
                      <Grid item xs={12} marginTop={2}>
                        <i className="fa-regular fa-square-question fa-xl"></i>
                        &nbsp;&nbsp;
                        {`${t("Question")} ${index + 1} : ${data.question}`}
                      </Grid>
                      <Grid item xs={12} marginTop={1}>
                        <RadioGroup
                          row
                          value={data.answer}
                        >
                          <FormControlLabel
                            value="verygood"
                            control={<Radio />}
                            label={t("Excellence")}
                          />
                          <FormControlLabel
                            value="good"
                            control={<Radio />}
                            label={t("Good")}
                          />
                          <FormControlLabel
                            value="meduim"
                            control={<Radio />}
                            label={t("Neutral")}
                          />
                          <FormControlLabel
                            value="fair"
                            control={<Radio />}
                            label={t("Fair")}
                          />
                          <FormControlLabel
                            value="prettybad"
                            control={<Radio />}
                            label={t("Poor")}
                          />
                          <FormControlLabel
                            value="bad"
                            control={<Radio />}
                            label={t("VeryPoor")}
                          />
                        </RadioGroup>
                      </Grid>
                      <Grid item xs={12}>
                        <Divider
                          sx={{
                            marginTop: "16px",
                            borderColor: "#9e9e9e66",
                          }}
                        />
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
                <Grid item xs={12}>
                  <i className="fa-regular fa-bone fa-xl"></i>
                  &nbsp;&nbsp;{t("Strength")}
                </Grid>
                <Grid item xs={12}>
                  <TextFieldTheme
                    inputProps={{ readOnly: true }}
                    multiline
                    rows={3}
                    value={probation.goodPoint || ""}
                  ></TextFieldTheme>
                </Grid>
                <Grid item xs={12}>
                  <i className="fa-regular fa-bone-break fa-xl"></i>
                  &nbsp;&nbsp;{t("Weakness")}
                </Grid>
                <Grid item xs={12}>
                  <TextFieldTheme
                    inputProps={{ readOnly: true }}
                    multiline
                    rows={3}
                    value={probation.weakPoint || ""}
                  ></TextFieldTheme>
                </Grid>
                <Grid item xs={12}>
                  {t("Other")}
                </Grid>
                <Grid item xs={12}>
                  <TextFieldTheme
                    inputProps={{ readOnly: true }}
                    multiline
                    rows={3}
                    value={probation.others || ""}
                  ></TextFieldTheme>
                </Grid>
                <Grid item xs={12}>
                  {t("AdditionalCommentsFromSupervisor")}
                </Grid>
                <Grid item xs={12}>
                  <TextFieldTheme
                    inputProps={{ readOnly: true }}
                    multiline
                    rows={3}
                    value={probation.commentManagerLV1 || ""}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  marginTop={2}
                  marginBottom={2}
                  display={"flex"}
                  justifyContent={"center"}
                >
                  <Box style={{ position: "relative", display: "inline-flex" }}>
                    <CircularProgress
                      variant="determinate"
                      style={{
                        color: "#eeeeee",
                      }}
                      size={70}
                      thickness={4}
                      value={100}
                    />
                    <CircularProgress
                      variant="determinate"
                      value={scorePercents}
                      style={{
                        color: "#007afe",
                        animationDuration: "550ms",
                        position: "absolute",
                        left: 0,
                      }}
                      size={70}
                    />
                    <Box
                      style={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: "absolute",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Typography
                        variant="h6"
                        style={{ fontSize: 16 }}
                        component="div"
                        color="text.third"
                      >
                        {scorePercents}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              </>
            )}
            {probation.result === null && (
              <Grid item xs={12}>
                <Typography marginBottom={1}>{t("ReasonForExtendingProbation")}</Typography>
                <TextFieldTheme
                  inputProps={{ readOnly: true }}
                  multiline
                  rows={3}
                  value={probation.reasonExpandTime || ""}
                />
              </Grid>
            )}
            {probation.result === 0 && (
              <Grid item xs={12}>
                <Typography marginBottom={1}>{t("ReasonForNotPassingProbation")}</Typography>
                <TextFieldTheme
                  inputProps={{ readOnly: true }}
                  multiline
                  rows={3}
                  value={probation.reasonTermination || t("ReasonForNotPassingProbation")}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div style={{ marginBottom: "1rem" }}>
                  <Typography
                    fontSize="14px"
                    fontWeight="600"
                    marginBottom={1}
                  >
                    {t("ProbationResult")}
                  </Typography>
                  <Controller
                    name="probationResult"
                    control={control}
                    render={({ field, fieldState }) => (
                      <TextFieldTheme
                        {...field}
                        select
                        helperText={fieldState.error && fieldState.error.message}
                        error={Boolean(fieldState.error)}
                      >
                        <MenuItem value={1}>{t("PassedProbation")}</MenuItem>
                        <MenuItem value={0}>{t("NotPassedProbation")}</MenuItem>
                      </TextFieldTheme>
                    )}
                  />
                </div>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <ButtonBlue
                    variant="text"
                    onClick={handleCloseDrawer}
                    disabled={isSubmitting}
                  >
                    {t("Cancel")}
                  </ButtonBlue>
                  <ButtonBlue
                    variant="contained"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {t("Save")}
                  </ButtonBlue>
                </div>
              </form>
            </Grid>
          </Grid>
        </StyledRoot>
      )}
    </DrawerCustom>
  );
};

export default DialogEdit;
