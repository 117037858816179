import React, { Fragment, useEffect, useState } from "react";
import { Autocomplete, Box, Breadcrumbs, Button, CircularProgress, Container, Grid, Typography, styled } from "@mui/material";
import CardStyle from "../../../shared/general/Card";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { getAffiliateCompany } from "../../../../../actions/affiliate";
import DatePickerCustom from "../../../shared/date/datePicker";
import additionSepecialService from "../../../../../services/additionSepecial.service";
import { openNotificationAlert } from "../../../../../actions/notificationAlert";
import { getDiligenceAllowanceReport } from "../../../../../actions/report";
import DataGrid, { Column, Export, HeaderFilter, SearchPanel } from "devextreme-react/data-grid";
import { calculateDiligenceAllowance } from "../../../../../actions/additionSpecial";
import DialogConfirm from "../../../shared/general/DialogConfirm";
import LoadingGif from "../../../assets/social-media.gif";

const StyledRoot = styled(Box)({
  backgroundColor: "#FFFFFF !important",
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root, & .MuiFilledInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      height: "56px",
      "& input": {
        padding: 0,
        borderRadius: 0
      },
    },
  },
  "& .field-label": {
    fontSize: "14px",
    fontWeight: "500",
    paddingBottom: "4px",
  },
})

const StyledWrapDataGrid = styled(Box)({
  '& .column-info[role="columnheader"]': {
    backgroundColor: "#283593",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-ot[role="columnheader"]': {
    backgroundColor: "#7c4dff",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-shift[role="columnheader"]': {
    backgroundColor: "#2196f3",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },

  '& .column-total-addition[role="columnheader"]': {
    backgroundColor: "#009688",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-deduction[role="columnheader"]': {
    backgroundColor: "#e53935",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },

  '& .column-net[role="columnheader"]': {
    backgroundColor: "#ffd600",
    color: "#313f4c",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#313f4c",
    },
  },

  '& .column-working-hours[role="columnheader"]': {
    backgroundColor: "#FFFF00",
    color: "#313f4c",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
});

const DiligenceAllowancePage = () => {

  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const { result: affiliateList } = useSelector((state) => state.affiliate);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: DiligenceAllowanceReport, isFetching: isFetchingDiligenceAllowanceReport } = useSelector((state) => state.diligenceAllowanceReport);
  const [isCalculating, setIsCalculating] = useState(false);
  const [dialogReCalConfig, setDialogReCalConfig] = useState({
    isOpen: false,
    data: {},
  })
  const [isSubmitted, setIsSubmitted] = useState(false);

  const useHookForm = useForm({
    defaultValues: {
      company: null,
      searchDate: dayjs(),
    },
  })

  useEffect(() => {
    dispatch(getAffiliateCompany())
  }, [])

  useEffect(() => {
    if (userProfile && affiliateList && affiliateList.length > 0) {
      const foundCompany = affiliateList.find(x => x.idCompany === userProfile.idCompany);
      if (foundCompany) {
        useHookForm.resetField("company", { defaultValue: foundCompany })
      } else {
        useHookForm.resetField("company", { defaultValue: null })
      }
    }
  }, [affiliateList, userProfile]);

  const onSubmit = async (data) => {
    setIsSubmitted(true)
    dispatch(getDiligenceAllowanceReport({
      idCompany: data.company.idCompany,
      year: dayjs(data.searchDate).format("YYYY"),
      month: dayjs(data.searchDate).format("M"),
    }))

    // const res = await additionSepecialService.exportExcelAdditionSpecial1({
    //   idCompany: data.company.idCompany,
    //   searchDate: dayjs(data.searchDate).format("YYYY-MM-DD"),
    // })

    // if(res && res.status === 200){
    //   const excelBlob = new Blob([res.data], {
    //     type: res.headers["content-type"],
    //   });

    //   const downloadLink = document.createElement("a");
    //   downloadLink.href = URL.createObjectURL(excelBlob);
    //   downloadLink.download = res.headers["content-disposition"]
    //     .split("filename")[1]
    //     .slice(2, -1);
    //   downloadLink.click();
    // } else {
    //   dispatch(openNotificationAlert({ message: "error", type: "error" }));
    // }
  };

  const onCalculateDigigenceAllowance = async (data) => {
    setIsCalculating(true);

    const result = await dispatch(calculateDiligenceAllowance({
      idCompany: data.company.idCompany,
      year: dayjs(data.searchDate).format("YYYY"),
      month: dayjs(data.searchDate).format("M"),
    }))

    if (result && result.status === 200) {
      dispatch(openNotificationAlert({
        type: "success",
        message: t("DataSaveSuccessful"),
      }));
      dispatch(getDiligenceAllowanceReport({
        idCompany: data.company.idCompany,
        year: dayjs(data.searchDate).format("YYYY"),
        month: dayjs(data.searchDate).format("M"),
      }))
    } else {
      dispatch(openNotificationAlert({
        type: "error",
        message: t("AnErrorOccurred")
      }));
    }

    setIsCalculating(false);
  }

  const handleOpenDialogConfirmReCal = (data) => {
    setDialogReCalConfig(prev => ({
      ...prev,
      isOpen: true,
      data: data,
    }))
  }

  const handleCloseDialogConfirmReCal = () => {
    setDialogReCalConfig(prev => ({
      ...prev,
      isOpen: false,
    }))
  }

  const handleConfirmDialogConfirmReCal = () => {
    useHookForm.handleSubmit(onCalculateDigigenceAllowance)();
    handleCloseDialogConfirmReCal();
  }

  return (
    <StyledRoot className="page">
      <Container
        maxWidth="lg"
        style={{ paddingTop: "24px", paddingBottom: "24px" }}
      >
        <Box marginBottom="24px">
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            <Link
              style={{ textDecoration: "none", color: "inherit" }}
              to={"/employees-report"}
            >
              {`${t("AllReports")}`}
            </Link>
            <Typography color="text.primary">{`${t("SummaryDiligenceAllowance")}`}</Typography>
          </Breadcrumbs>
        </Box>

        <Typography variant="h4">{t("SummaryDiligenceAllowance")}</Typography>

        <CardStyle style={{ marginTop: "24px", padding: 24 }}>
          <form onSubmit={useHookForm.handleSubmit(onSubmit)}>
            <Grid container spacing={2} alignItems={"center"}>
              <Grid item xs={5}>
                <Typography className="label" color="text.third">{t("Company")}</Typography>
                <Controller
                  name="company"
                  control={useHookForm.control}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      options={affiliateList ? affiliateList : []}
                      getOptionLabel={(option) => `${option.companyName}`}
                      isOptionEqualToValue={(option, value) => option.idCompany === value.idCompany}
                      renderInput={(params) => (
                        <TextFieldTheme
                          {...params}
                          variant="filled"
                          placeholder={`${t("SelectCompany")}`}
                          onBlur={field.onBlur}
                          helperText={fieldState.error ? fieldState.error.message : null}
                          error={fieldState.error ? true : false}
                        />
                      )}
                      value={field.value}
                      onChange={(_, value) => {
                        field.onChange(value);
                        setIsSubmitted(false);
                      }}
                      noOptionsText={`${t("NoData")}`}
                      disableClearable
                    />
                  )}
                />
              </Grid>
              <Grid item xs={5}>
                <Typography className="label" color="text.third">{t("SelectMonth")}</Typography>
                <Controller
                  control={useHookForm.control}
                  name="searchDate"
                  render={({ field }) => (
                    <DatePickerCustom
                      {...field}
                      minDate={dayjs().subtract(1, 'month')}
                      maxDate={dayjs()}
                      inputFormat="MMMM YYYY"
                      value={field.value}
                      views={['year', 'month']}
                      openTo={'month'}
                      inputProps={{
                        readOnly: true
                      }}
                      onChange={(newValue) => {
                        field.onChange(newValue);
                        setIsSubmitted(false);
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2}>
                <Box sx={{ mt: 3 }}>
                  <ButtonBlue
                    variant="contained"
                    type="submit"
                    disabled={useHookForm.formState.isSubmitting}
                  >
                    {t("Search")}
                  </ButtonBlue>
                </Box>
              </Grid>
            </Grid>
          </form>

          {/* {!isSubmitted && (
            <Box marginTop="24px">
              <Typography fontSize="24px" fontStyle="oblique" textAlign="center">กรุณากรอกข้อมูลแล้วกดค้นหา</Typography>
            </Box>
          )} */}

          {isSubmitted && isFetchingDiligenceAllowanceReport && (
            <Box
              marginTop="24px"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              {/* <Typography fontSize="24px" fontStyle="oblique" textAlign="center">{t("LoadingData")}...</Typography> */}
              <img src={LoadingGif} style={{ width: 100, height: 100 }} />
            </Box>
          )}

          {isSubmitted && !isFetchingDiligenceAllowanceReport && <Fragment>
            {isCalculating ? (
              <Box marginTop="24px">
                <Typography fontSize="24px" fontStyle="oblique" textAlign="center">กำลังคำนวณ...</Typography>
              </Box>
            ) : (
              <Fragment>
                {DiligenceAllowanceReport.isFound === 1 ? (
                  <Box marginTop="24px">
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography color="text.secondary">เดือน</Typography>
                        <Typography fontSize="20px">{dayjs(DiligenceAllowanceReport.monthPeriod).format("MMMM YYYY")}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography color="text.secondary">วันที่ของข้อมูลสถิติที่นำมาใช้คำนวณ</Typography>
                        <Typography fontSize="20px">{dayjs(DiligenceAllowanceReport.dataDateStart).format("D MMM YYYY")} - {dayjs(DiligenceAllowanceReport.dataDateEnd).format("D MMM YYYY")}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography color="text.secondary">{t("Company")}</Typography>
                        <Typography fontSize="20px">{DiligenceAllowanceReport.companyName}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <StyledWrapDataGrid>
                          <DataGrid
                            dataSource={DiligenceAllowanceReport.diligenceAllowanceDetail ? DiligenceAllowanceReport.diligenceAllowanceDetail : []}
                            showBorders={true}
                            showColumnLines={true}
                            rowAlternationEnabled={true}
                            columnAutoWidth={true}
                            allowColumnResizing={true}
                            columnResizingMode='widget'
                          >
                            <Column
                              caption={`${t("EmployeeID")}`}
                              dataField="employeeID"
                              dataType="string"
                              width={150}
                              cssClass="column-info"
                            />
                            <Column
                              dataField={i18n.resolvedLanguage === "th" ? "fullname_TH" : "fullname_EN"}
                              caption={`${t("FullName")}`}
                              dataType="string"
                              cssClass="column-info"
                            >
                              <HeaderFilter allowSearch={true} />
                            </Column>
                            <Column
                              dataField={i18n.resolvedLanguage === "th" ? "businessUnitName" : "businessUnitName_EN"}
                              caption={`${t("BusinessUnit")}`}
                              dataType="string"
                              cssClass="column-info"
                            />
                            <Column
                              dataField={i18n.resolvedLanguage === "th" ? "companyName" : "companyName_EN"}
                              caption={`${t("Company")}`}
                              dataType="string"
                              cssClass="column-info"
                            />
                            <Column
                              dataField={i18n.resolvedLanguage === "th" ? "divisionName" : "divisionName_EN"}
                              caption={`${t("Division")}`}
                              dataType="string"
                              cssClass="column-info"
                            />
                            <Column
                              dataField={i18n.resolvedLanguage === "th" ? "departmentName" : "departmentName_EN"}
                              caption={`${t("Department")}`}
                              dataType="string"
                              cssClass="column-info"
                            />
                            <Column
                              dataField={i18n.resolvedLanguage === "th" ? "sectionName" : "sectionName_EN"}
                              caption={`${t("Section")}`}
                              dataType="string"
                              cssClass="column-info"
                            />
                            <Column
                              dataField={i18n.resolvedLanguage === "th" ? "positionName" : "positionName_EN"}
                              caption={`${t("Position")}`}
                              dataType="string"
                              cssClass="column-info"
                            />
                            <Column
                              dataField="accumulateMonthInRow"
                              caption={"จำนวนเดือนที่ได้รับต่อเนื่อง"}
                              dataType="string"
                              cssClass="column-info"
                            />
                            <Column
                              dataField="amount"
                              caption={"ค่าเบี้ยขยัน"}
                              format="###,###,##0.00"
                              cssClass="column-info"
                            />
                            <SearchPanel
                              visible={true}
                              width={240}
                              placeholder={`${t("Search")}`}
                            />
                            <Export
                              enabled={true}
                              fileName={`รายงานเบี้ยขยัน ${DiligenceAllowanceReport.companyName} เดือน ${dayjs(DiligenceAllowanceReport.monthPeriod).format("MMMM YYYY")} ข้อมูล ณ ${dayjs().format("DD.MM.YYYY HH.mm.ss")}`}
                              texts={{
                                exportAll: t("DownloadAllDataToExcel"),
                              }}
                            />
                          </DataGrid>
                        </StyledWrapDataGrid>
                      </Grid>
                      <Grid item xs={12} container justifyContent="flex-end">
                        <ButtonBlue
                          variant="outlined"
                          disabled={useHookForm.formState.isSubmitting}
                          // onClick={useHookForm.handleSubmit(onCalculateDigigenceAllowance)}
                          onClick={handleOpenDialogConfirmReCal}
                        >
                          คำนวณเบี้ยขยันอีกครั้ง
                        </ButtonBlue>
                      </Grid>
                    </Grid>

                  </Box>
                ) : (
                  <Box marginTop="24px">
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography fontSize="24px" fontStyle="oblique" textAlign="center">{t("NoData")}</Typography>
                      </Grid>
                      <Grid item xs={12} container justifyContent="center">
                        <ButtonBlue
                          variant="outlined"
                          disabled={useHookForm.formState.isSubmitting}
                          onClick={useHookForm.handleSubmit(onCalculateDigigenceAllowance)}
                        >
                          {t("CalculateDiligenceAllowance")}
                        </ButtonBlue>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Fragment>
            )}
          </Fragment>}

        </CardStyle>
      </Container>
      <DialogConfirm
        open={dialogReCalConfig.isOpen}
        handleClose={handleCloseDialogConfirmReCal}
        text={""}
        content1={"คำนวณเบี้ยขยันอีกครั้ง"}
        content2={"ระบบจะทำการบันทึกแทนที่ข้อมูลเดิมของเดือนนี้"}
        handleConfirm={handleConfirmDialogConfirmReCal}
      />
    </StyledRoot >
  )
}

export default DiligenceAllowancePage;