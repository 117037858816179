import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Collapse,
  CircularProgress,
  Box,
  Typography,
  Paper,
  Avatar,
} from "@mui/material";
import { styled } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { useDispatch, useSelector } from "react-redux";
import {
  getTerminateEmployee,
  getTerminateEmployeeByID,
} from "../../../../../actions/offBoarding";
import More from "./viewmore";
import { useTranslation } from "react-i18next";
import {
  getUserDepartment,
  getUserFullName,
  getUserPosition,
  getUserDivision,
} from "../../../../../utils/userData";

const StyledWrapBranch = styled("div")(({ theme }) => ({
  marginBottom: 36,
  "& .wrap-branch-top": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  borderRadius: 12,
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
  backgroundColor: "#ffffff",
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: "#ffffff",
  fontWeight: 600,
  textAlign: "center",
  borderBottom: "2px solid #e0e0e0",
  padding: "8px 16px",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#ffffff",
  },
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
    cursor: "pointer", // เพิ่ม cursor แบบ pointer เมื่อ hover
  },
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 40,
  height: 40,
  marginRight: 8,
}));

const StyledCollapseRow = styled(TableRow)(({ theme }) => ({
  "& > *": {
    borderBottom: "unset",
  },
}));

const StatusOffBoarding = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { result: terminateEmployee } = useSelector(
    (state) => state.terminateEmployee
  );
  const { result: terminateEmployeeByID } = useSelector(
    (state) => state.terminateEmployeeByID
  );

  const [progress, setProgress] = useState({});
  const [openRow, setOpenRow] = useState(null);

  useEffect(() => {
    dispatch(getTerminateEmployee());
  }, [dispatch]);

  useEffect(() => {
    if (terminateEmployee && terminateEmployee.length > 0) {
      terminateEmployee.forEach((row) => {
        setProgress((prev) => ({ ...prev, [row.idEmployees]: 0 }));
        const timer = setInterval(() => {
          setProgress((prev) => {
            const newProgress = { ...prev };
            if (newProgress[row.idEmployees] < row.status) {
              newProgress[row.idEmployees] += 2;
            } else {
              clearInterval(timer);
            }
            return newProgress;
          });
        }, 10);
      });
    }
  }, [terminateEmployee]);

  const handleOpenMore = (row) => {
    if (openRow === row) {
      setOpenRow(null);
    } else {
      setOpenRow(row);
      dispatch(getTerminateEmployeeByID(row.idEmployees));
    }
  };

  return (
    <StyledWrapBranch>
      {terminateEmployee && terminateEmployee.length > 0 ? (
        <StyledTableContainer component={Paper} elevation={3}>
          <Table>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell></StyledTableCell>
                <StyledTableCell>{t("FullName")}</StyledTableCell>
                <StyledTableCell>{t("Position")}</StyledTableCell>
                <StyledTableCell>{t("Division")}</StyledTableCell>
                <StyledTableCell>{t("Department")}</StyledTableCell>
                <StyledTableCell>{t("Status")}</StyledTableCell>
                <StyledTableCell>{t("Descriptions")}</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {terminateEmployee.map((row, index) => (
                <React.Fragment key={row.idEmployees}>
                  <StyledTableRow onClick={() => handleOpenMore(row)}>
                    <TableCell align="center" padding="none">
                      {index + 1}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        width: "200px",
                        maxWidth: "200px",
                        minWidth: "150px",
                      }}
                    >
                      <Box display="flex" alignItems="center">
                        <StyledAvatar
                          alt={row.firstname_TH}
                          src={
                            row.imageProfile
                              ? `data:image/jpeg;base64,${row.imageProfile}`
                              : ""
                          }
                        />
                        <Typography variant="subtitle2">
                          {getUserFullName(row)}
                        </Typography>
                      </Box>
                    </TableCell>
                    <TableCell align="center">{getUserPosition(row)}</TableCell>
                    <TableCell align="center">{getUserDivision(row)}</TableCell>
                    <TableCell align="center">
                      {getUserDepartment(row)}
                    </TableCell>
                    <TableCell align="center">
                      <Box position="relative" display="inline-flex">
                        <CircularProgress
                          variant="determinate"
                          value={progress[row.idEmployees] || 0}
                          size={50}
                          thickness={4}
                          style={{
                            color:
                              progress[row.idEmployees] > 51
                                ? "#4caf50"
                                : "#f44336",
                          }}
                        />
                        <Box
                          top={0}
                          left={0}
                          bottom={0}
                          right={0}
                          position="absolute"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Typography
                            variant="caption"
                            component="div"
                            color="textSecondary"
                            fontSize="0.8rem"
                          >
                            {`${Math.round(progress[row.idEmployees] || 0)}%`}
                          </Typography>
                        </Box>
                      </Box>
                    </TableCell>
                    <TableCell align="center">
                      {openRow === row ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </TableCell>
                  </StyledTableRow>
                  <StyledCollapseRow>
                    <TableCell
                      style={{ paddingBottom: 0, paddingTop: 0 }}
                      colSpan={7}
                    >
                      <Collapse
                        in={openRow === row}
                        timeout="auto"
                        unmountOnExit
                      >
                        <More data={terminateEmployeeByID} />
                      </Collapse>
                    </TableCell>
                  </StyledCollapseRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      ) : (
        <Typography variant="body1" color="textSecondary">
          {t("NoItems")}
        </Typography>
      )}
    </StyledWrapBranch>
  );
};

export default StatusOffBoarding;
