import React from "react";
import {
  Avatar,
  Box,
  Card,
  Fab,
  Grid,
  Icon,
  styled,
  Typography,
} from "@mui/material";
import CardData from "../../shared/dashboard/CardData";
import { useSelector } from "react-redux";
import Utils from "../../../../utils";
import CardDashboard from "../../shared/dashboard/CardDashboard";
import ChartPolarAreaCustom from "../../shared/dashboard/ChartPolarArea";
import ChartAreaCustom from "../../shared/dashboard/ChartArea";
import dayjs from "dayjs";
import TableCustom from "../../shared/tableCustom";
import EmployeeInOut from "./employeeInOut";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getUserFullName } from "../../../../utils/userData";
import ButtonBlue from "../../shared/general/ButtonBlue";

import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import { useHistory } from "react-router-dom";

const StyledRoot = styled(Box)({
  "& .top-employee": {
    padding: "8px",
    // paddingTop: "16px",
    display: "flex",
    alignItems: "center",
    border: "1px solid #ececec",
    borderRadius: "4px",
  },
});

const OverviewPanel = (props) => {
  const { month, year } = props;
  const { t, i18n } = useTranslation();

  const history = useHistory();

  const { result: dashboardOverview } = useSelector(
    (state) => state.dashboardOverview
  );

  const leaveColumns = [
    {
      name: `${t("FullName")}`,
      minWidth: "230px",
      width: "230px",
      cellRender: (row) => (
        <Box display="flex" alignItems="center">
          <Avatar
            sx={{
              marginRight: "8px",
              width: 40,
              height: 40,
              "& img": { objectFit: "contain" },
            }}
            src={row.imageProfile}
          />
          <Box flexGrow={1}>
            <Typography>{getUserFullName(row)}</Typography>
            <Typography color="text.third" fontSize="14px">
              {row.positionName}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      name: `${t("Type")}`,
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => <Typography fontSize={14}>{row.name}</Typography>,
    },
    {
      name: `${t("Start")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>
            {dayjs(row.startText, "DD/MM/YYYY HH:mm").format(
              i18n.resolvedLanguage === "th" ? "D MMM BBBB" : "D MMM YYYY"
            )}
          </Typography>
          {row.isFullDay === 1 ? (
            <Typography>{t("FullDay")}</Typography>
          ) : (
            <Typography>
              {dayjs(row.startText, "DD/MM/YYYY HH:mm").format("HH:mm")}
            </Typography>
          )}
        </Box>
      ),
    },
    {
      name: `${t("End")}`,
      headerTextAlign: "center",
      minWidth: "150px",
      width: "150px",
      cellRender: (row) => (
        <Box sx={{ textAlign: "center" }}>
          <Typography>
            {dayjs(row.endText, "DD/MM/YYYY HH:mm").format(
              i18n.resolvedLanguage === "th" ? "D MMM BBBB" : "D MMM YYYY"
            )}
          </Typography>
          {row.isFullDay === 1 ? (
            <Typography>{t("FullDay")}</Typography>
          ) : (
            <Typography>
              {dayjs(row.endText, "DD/MM/YYYY HH:mm").format("HH:mm")}
            </Typography>
          )}
        </Box>
      ),
    },
    {
      name: `${t("Description")}`,
      minWidth: "200px",
      cellRender: (row) => (
        <Box>
          <Typography color="text.third" fontSize="14px">
            {row.description}
          </Typography>
        </Box>
      ),
    },
  ];

  return (
    <StyledRoot>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <CardData
                    title={`${t("Employee")}`}
                    dataValue={Utils.numberWithCommasWithoutDot(
                      dashboardOverview.companyEmployeeInfo.count
                    )}
                    dataUnit={`${t("Person")}`}
                    dynamicFontSize
                    titleIcon={
                      <Icon
                        baseClassName="fal"
                        className="fa-users"
                        color="primary"
                        style={{ display: "inline-table" }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CardData
                    title={`${t("SalaryTotal")}`}
                    // dataValue={Utils.numberWithCommas(
                    //   dashboardOverview.salaryTotal
                    // )}
                    dataValue={Utils.numberWithCommas(dashboardOverview.companySalaryInfo.totalSalary)}
                    dataUnit={`${t("Baht")}`}
                    dynamicFontSize
                    titleIcon={
                      <Icon
                        className="fal fa-sack-dollar"
                        color="warning"
                        style={{ display: "inline-table", color: "#ffcd38" }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CardData
                    title={`${t("OTOVer36Hr")}`}
                    // dataValue={[{name: "1", value: "20"}, {name: "2", value: "30"}]}
                    dataValue={dashboardOverview.employeeOTOver36Total ? dashboardOverview.employeeOTOver36Total.weekInMonth.map(
                      (week) => {
                        return {
                          name: `${dayjs(week.weekStartDateText).format(
                            "dd D MMM"
                          )} - ${dayjs(week.weekEndDateText).format(
                            "dd D MMM"
                          )}`,
                          value: week.over36HoursEmployeeTotal,
                        };
                      }
                    ) : []}
                    dataUnit={`${t("Person")}`}
                    dynamicFontSize
                    select
                    titleIcon={
                      <Icon
                        className="far fa-alarm-exclamation"
                        color="error"
                        style={{ display: "inline-table" }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CardData
                    title={`${t("OTTotal")}`}
                    dataValue={dashboardOverview.otTotal ? Utils.numberWithCommas(
                      Object.keys(dashboardOverview.otTotal).reduce(
                        (a, b) => a + dashboardOverview.otTotal[b].payTotal,
                        0
                      )
                    ) : 0}
                    dataUnit={`${t("Baht")}`}
                    dynamicFontSize
                    titleIcon={
                      <Icon
                        className="fal fa-hand-holding-usd"
                        color="warning"
                        style={{ display: "inline-table", color: "#ffcd38" }}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} lg={6}>
              <CardDashboard>
                <Typography marginBottom="16px">
                  {t("SalaryTotal")} ({t("Yearly")} {year})
                </Typography>
                <ChartAreaCustom
                  series={[
                    {
                      name: "รายจ่าย",
                      data: dashboardOverview.salaryTotalAllYear ? dashboardOverview.salaryTotalAllYear.map((s) => {

                        return {
                          x: dayjs()
                            .month(s.month - 1)
                            .format("MMM"),
                          y: s.salaryTotal,
                        };
                      }) : [],
                    },
                  ]}
                />
              </CardDashboard>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <CardDashboard>
                <Typography marginBottom="16px">
                  {t("OTTotalByType")}
                </Typography>
                <ChartPolarAreaCustom
                  series={dashboardOverview.otTotal ? Object.keys(dashboardOverview.otTotal).map(
                    (ot) => dashboardOverview.otTotal[ot].payTotal
                  ) : []}
                  labels={dashboardOverview.otTotal ? Object.keys(dashboardOverview.otTotal).map(
                    (ot) => `OT x${ot}`
                  ) : []}
                />
              </CardDashboard>
            </Grid>

            <Grid item xs={12} lg={6}>
              <CardDashboard>
                <Typography marginBottom="16px">
                  {t("OTTotal")} ({t("Yearly")} {year})
                </Typography>
                <ChartAreaCustom
                  series={[
                    {
                      name: "รายจ่าย",
                      data: dashboardOverview.otTotalAllYear ? dashboardOverview.otTotalAllYear.map((s) => {
                        return {
                          x: dayjs()
                            .month(s.month - 1)
                            .format("MMM"),
                          y: s.payTotal,
                        };
                      }) : [],
                    },
                  ]}
                />
              </CardDashboard>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8}>
              <CardDashboard>
                <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom="16px">
                  <Typography>
                    {`${t("Leave")} `}
                    {dayjs()
                      .month(month - 1)
                      .year(year)
                      .format("MMMM YYYY")}
                  </Typography>
                  <ButtonBlue
                    endIcon={<NavigateNextRoundedIcon />}
                    onClick={() => {
                      history.push({
                        pathname: "/admin/history-approve-list",
                        state: {
                          start: dayjs().year(year).month(month - 1).date(1).format("YYYY-MM-DD"),
                          end: dayjs().year(year).month(month - 1).date(dayjs().year(year).month(month - 1).daysInMonth()).format("YYYY-MM-DD"),
                          tab: 2,
                        }
                      })
                    }}
                  >
                    {t("All")}
                  </ButtonBlue>
                </Box>
                <TableCustom
                  columns={leaveColumns}
                  rows={dashboardOverview.workingTimeEmployeeInfo ? dashboardOverview.workingTimeEmployeeInfo.leave.sort(
                    (a, b) =>
                      dayjs(a.startText, "DD/MM/YYYY").isBefore(
                        dayjs(b.startText, "DD/MM/YYYY")
                      )
                        ? -1
                        : 1
                  ).slice(0, 8) : []}
                  rowsPerPageProp={5}
                  rowsPerPageOptions={[5]}
                />
              </CardDashboard>
            </Grid>
            <Grid item xs={12} lg={4}>
              <CardDashboard>
                <Typography marginBottom="16px">
                  {`${t("Top5Absent")} `}
                  {dayjs()
                    .month(month - 1)
                    .format("MMMM")}
                </Typography>
                {dashboardOverview.workingTimeEmployeeInfo && dashboardOverview.workingTimeEmployeeInfo.top5AbsentEmployees
                  .length > 0 && (
                    <Box
                      style={{
                        display: "flex",
                        gap: "16px",
                        flexDirection: "column",
                      }}
                    >
                      {dashboardOverview.workingTimeEmployeeInfo.top5AbsentEmployees.map(
                        (e, index) => (
                          <Box key={index} className="top-employee">
                            <Icon
                              className={`fa-solid fa-square-${index + 1}`}
                              color="primary"
                              style={{
                                display: "inline-table",
                                color: index <= 2 ? "#ffcd38" : undefined,
                              }}
                            />
                            <Box
                              style={{
                                flexGrow: "1",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Avatar
                                src={e.imageProfile}
                                style={{
                                  marginRight: "8px",
                                  width: "32px",
                                  height: "32px",
                                }}
                              />
                              <Typography>{getUserFullName(e)}</Typography>
                            </Box>
                            <Typography>
                              {e.absentTotal} {t("Unit.Days")}
                            </Typography>
                          </Box>
                        )
                      )}
                    </Box>
                  )}
                {dashboardOverview.workingTimeEmployeeInfo && dashboardOverview.workingTimeEmployeeInfo.top5AbsentEmployees
                  .length <= 0 && (
                    <Typography textAlign="center" color="text.third">
                      {t("NoAbsent")}
                    </Typography>
                  )}
              </CardDashboard>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} lg={3}>
              <CardData
                title={`${t("EmployeeIn")}`}
                dataValue={String(
                  // dashboardOverview.employeeInfo.employeeInTotal
                  dashboardOverview.companyEmployeeInfo.newEmployee
                )}
                dataUnit={`${t("Person")}`}
                dynamicFontSize
                titleIcon={
                  <Icon
                    className="fas fa-transporter"
                    color="success"
                    style={{ display: "inline-table" }}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <CardData
                title={`${t("EmployeeOut")}`}
                dataValue={String(
                  // dashboardOverview.employeeInfo.employeeOutTotal
                  dashboardOverview.companyEmployeeInfo.resignEmployee
                )}
                dataUnit={`${t("Person")}`}
                dynamicFontSize
                titleIcon={
                  <Icon
                    className="fas fa-person-circle-xmark"
                    color="error"
                    style={{ display: "inline-table" }}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <CardData
                title={t("TurnoverRate")}
                dataValue={dashboardOverview.companyEmployeeInfo.turnOverRate}
                dataUnit="%"
                dynamicFontSize
                titleIcon={
                  <Icon
                    className="fas fa-chart-line"
                    color="primary"
                    style={{ display: "inline-table" }}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <CardData
                title={t("TurnoverYear")}
                dataValue={dashboardOverview.companyEmployeeInfo.turnOverRateYear}
                dataUnit="%"
                dynamicFontSize
                titleIcon={
                  <Icon
                    className="fas fa-calendar-days"
                    color="primary"
                    style={{ display: "inline-table" }}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <CardData
                title={`${t("Male")}`}
                // dataValue={String(dashboardOverview.employeeInfo.maleTotal)}
                dataValue={String(dashboardOverview.companyEmployeeInfo.male)}
                dataUnit={`${t("Person")}`}
                dynamicFontSize
                titleIcon={
                  <Icon
                    className="fas fa-mars"
                    color="warning"
                    style={{ display: "inline-table", color: "#35baf6" }}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <CardData
                title={`${t("Female")}`}
                // dataValue={String(dashboardOverview.employeeInfo.femaleTotal)}
                dataValue={String(dashboardOverview.companyEmployeeInfo.female)}
                dataUnit={`${t("Person")}`}
                dynamicFontSize
                titleIcon={
                  <Icon
                    className="fas fa-venus"
                    color="warning"
                    style={{ display: "inline-table", color: "#ed4b82" }}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <CardData
                title={`${t("AverageAge")}`}
                // dataValue={String(dashboardOverview.employeeInfo.averageAge)}
                dataValue={String(dashboardOverview.companyEmployeeInfo.avgAge)}
                dataUnit={`${t("Year")}`}
                titleIcon={
                  <Icon
                    className="fas fa-people-group"
                    color="primary"
                    style={{ display: "inline-table" }}
                  />
                }
              />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
              <CardData
                title={`${t("AverageWork")}`}
                // dataValue={String(
                //   dashboardOverview.employeeInfo.averageWorkExperience
                // )}
                dataValue={String(dashboardOverview.companyEmployeeInfo.avgWorkingYear)}
                dataUnit={`${t("Year")}`}
                titleIcon={
                  <Icon
                    className="fas fa-briefcase"
                    color="primary"
                    style={{ display: "inline-table" }}
                  />
                }
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <CardDashboard>
            <Typography marginBottom="16px">
              {`${t("EmployeeIn")}`} - {`${t("EmployeeOut")} `}
              {dayjs()
                .month(month - 1)
                .format("MMMM")}
            </Typography>
            <EmployeeInOut />
          </CardDashboard>
        </Grid>
      </Grid>
    </StyledRoot>
  );
};

export default OverviewPanel;
