import {
    Avatar, Box, Typography, Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    styled,
    TableRow,
} from "@mui/material";
import dayjs from "dayjs";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ChipWithDrawCustom from "../../shared/chipWithDrawCustom";
import TableCustom from "../../shared/tableCustom";
import StatusRequest from "../../shared/general/stausRequest";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import MenuStatus from "./MenuStatus";
import {
    getAllProjectEmployees,
    getUsersInProject,
    getUsersInProjectShowHour,
    getAllEmployeesInWorkforce,
    getEmployeesWeek,
    getUsersTimeforApprove,
    updateManagerWorkforceApproveTime
} from "../../../../actions/workforceEmployees";

const StyledCellHeader = styled(TableCell)({
    borderBottom: "none",
    color: "#637381",
    backgroundColor: "#f4f6f8",
    padding: "8px 14px",
    "&:first-of-type": {
        paddingLeft: 24,
        borderTopLeftRadius: 8,
        borderBottomLeftRadius: 8,
    },
    "&:last-of-type": {
        paddingRight: 24,
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
    },
});


const RequestInputHourUserWorkforce = ({ searchDate, setSnackBarConfig }) => {
    const dispatch = useDispatch()

    const { t, i18n } = useTranslation();
    const [filteredData, setFilteredData] = useState([]);


    useEffect(() => {
        dispatch(getUsersTimeforApprove())
    }, [])

    useEffect(() => {
        if (searchDate.start || searchDate.end) {
      
          let tempPending = projectGetUserTimeforApprove.filter((item) =>
            dayjs(item.workDate).isBetween(
              searchDate.start || dayjs(),
              searchDate.end || dayjs(),
              null,
              '[]' 
            )
          );
      
          const pendingList = tempPending.filter((data) => data.approvedDate !== null);

          setFilteredData(pendingList);
        } else {
          const pendingList = projectGetUserTimeforApprove.filter((data) => data.approvedDate !== null);
          setFilteredData(pendingList);
        }
      }, [searchDate, projectGetUserTimeforApprove]);

    const { result: projectGetUserTimeforApprove } = useSelector(
        (state) => state.projectGetUserTimeforApprove
    );

    
    const filter = projectGetUserTimeforApprove.filter((data) => data.approvedDate !== null)

    const thaiMonths = [
        'มกราคม', 'กุมภาพันธ์', 'มีนาคม', 'เมษายน', 'พฤษภาคม', 'มิถุนายน',
        'กรกฎาคม', 'สิงหาคม', 'กันยายน', 'ตุลาคม', 'พฤศจิกายน', 'ธันวาคม'
    ];
    const Header = [
        {
            name: `${t("Status")}`,
            minWidth: "32px",
            width: "32px",
            cellRender: (row) => (
                <StatusRequest
                    boxSize={40}
                    fontSize={22}
                    borderRadius={8}
                    status={row.isApprove === 0 || row.isApprove === 2 ? 0 : 1}
                />
            ),
        },
        {
            name: `${t("FullName")}`,
            minWidth: "230px",
            width: "230px",
            cellRender: (row) => (
                <Box display="flex" alignItems="center">
                    <Avatar
                        style={{
                            marginRight: "8px",
                            width: 40,
                            height: 40,
                            "& img": { objectFit: "contain" },
                        }}
                        src={row.imageProfile}
                    />
                    <Box flexGrow={1}>
                        <Typography>
                            {i18n.resolvedLanguage === "th"
                                ? `${row.firstname_TH} ${row.lastname_TH}`
                                : `${row.firstname_EN} ${row.lastname_EN}`}
                        </Typography>
                        <Typography color="text.third" fontSize="14px">
                            {i18n.resolvedLanguage === "th"
                                ? `${row.positionName}`
                                : `${row.positionName_EN}`}
                        </Typography>
                    </Box>
                </Box>
            ),
        },
        {
            name: `${t("Date")}`,
            headerTextAlign: "center",
            minWidth: "150px",
            width: "150px",
            cellRender: (row) => (
                <Box style={{ textAlign: "center" }}>
                    <Typography>
                        {dayjs(row.workDate, "YYYY/MM/DD").format(
                            i18n.resolvedLanguage === "th" ? "D MMM BBBB" : "D MMM YYYY"
                        )}

                    </Typography>
                </Box>
            ),
        },
        {
            name: `${t("สัปดาห์/เดือน/ปีทีทำ")}`,
            headerTextAlign: "center",
            minWidth: "150px",
            width: "150px",
            cellRender: (row) => (
                <Box style={{ textAlign: "center" }}>
                    <Typography>
                        <div>สัปดาห์ที่ {row.inWeek}</div>
                        <div>{thaiMonths[row.monthWork - 1]} {row.inYear}</div>
                    </Typography>
                </Box>
            ),
        },
        {
            name: `${t("เวลาทำงาน")}`,
            minWidth: "150px",
            headerTextAlign: "center",
            cellRender: (row) => (
                <Box sx={{ textAlign: "center" }}>
                    <Typography>
                        {row.totalWorkingHours}
                    </Typography>

                </Box>
            ),
        }, ,
        {
            name: `${t("ApprovedDate")}`,
            minWidth: "150px",
            headerTextAlign: "center",
            cellRender: (row) => (
                <Box sx={{ textAlign: "center" }}>
                    <Typography>
                        {dayjs(row.approvedDate, "YYYY/MM/DD").format(
                            i18n.resolvedLanguage === "th" ? "D MMM BBBB" : "D MMM YYYY"
                        )}

                    </Typography>
                </Box>
            ),
        },
    ];


    return (
        <Box>
            <TableCustom
                columns={Header}
                rows={filteredData && filteredData ? filteredData.filter((data) => data.approvedDate !== null) : []}
            />
        </Box>
    );
};

export default RequestInputHourUserWorkforce;
