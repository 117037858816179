import React, { useCallback, useEffect, useState } from "react";
import {
  Avatar,
  Button,
  Chip,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  styled,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DownloadIcon from "@mui/icons-material/Download";
import { useDispatch } from "react-redux";
import DataGrid, { Column, FilterRow, Pager } from "devextreme-react/data-grid";
import { utils, writeFile } from "xlsx";
import {
  addGradeOKR,
  getCarlibrateOrganization,
} from "../../../../../actions/okrs";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getUserFirstName, getUserFullName ,getUserLastName, getUserPosition} from "../../../../../utils/userData";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiGrid-container": {
      marginBottom: 0,
    },
    "& .MuiCardContent-root": {
      padding: 0,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 16,
    },
    "& .MuiOutlinedInput-input": {
      padding: "16px 14px",
    },
    "& .MuiTableCell-root": {
      paddingTop: "5px",
      paddingBottom: "5px",
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
  },
}));

const StyledWrapDataGrid = styled("div")({
  padding: "0 24px",
  '& .column-info[role="columnheader"]': {
    backgroundColor: "#283593",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-addition[role="columnheader"]': {
    backgroundColor: "#009688",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-info[role="gridcell"]': {
    verticalAlign: "middle !important",
  },
  '& .column-total-addition[role="gridcell"]': {
    verticalAlign: "middle !important",
  },
});

const ContainerStyled = styled(Container)({
  paddingTop: "100px",
  height: "100dvh",
});

function MenuGrade({ idEmployees, fechData }) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeGrade = (value) => {
    const formData = { idEmployees, grade: value };
    dispatch(addGradeOKR(formData)).then((res) => {
      if (res.status === 201) {
        handleClose();
        fechData();
      }
    });
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleChangeGrade("A")}>{"A"}</MenuItem>
        <MenuItem onClick={() => handleChangeGrade("B")}>{"B"}</MenuItem>
        <MenuItem onClick={() => handleChangeGrade("C")}>{"C"}</MenuItem>
        <MenuItem onClick={() => handleChangeGrade("D")}>{"D"}</MenuItem>
      </Menu>
    </div>
  );
}

function CompanyCalibrate() {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [calibrateData, setCalibrateData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const exportFile = useCallback(() => {
    const dataExport = calibrateData.map((item) => ({
      idEmployees: item.idEmployees,
      firstname: getUserFirstName(item),
      lastname: getUserLastName(item),
      position: getUserPosition(item),
      department: item.departmentName,
      q1: item.quarter1,
      q2: item.quarter2,
      q3: item.quarter3,
      q4: item.quarter4,
      average: item.average,
      grade: item.grade,
    }));

    const ws = utils.json_to_sheet(dataExport);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    writeFile(wb, `${t("Calibrate")}.xlsx`);
  }, [calibrateData]);

  const fechData = () => {
    dispatch(getCarlibrateOrganization()).then((res) => {
      if (res.status === 200) {
        setCalibrateData(res.data);
      }
    });
  };

  useEffect(() => {
    let isMounted = true;
    dispatch(getCarlibrateOrganization()).then((res) => {
      if (isMounted) {
        if (res.status === 200) {
          setCalibrateData(res.data);
        }
      }
    });

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>
      {calibrateData && (
        <>
          <StyledWrapDataGrid>
            <Typography fontWeight={700} color={"#4f65df"} fontSize={"1.5rem"}>
              {`${t("CompanyPerformanceSummary")}`}
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginBottom: 8,
              }}
            >
              <Button
                variant="contained"
                size="small"
                sx={{ backgroundColor: "#4f65df" }}
                disableElevation
                startIcon={<DownloadIcon />}
                onClick={exportFile}
              >
                Excel
              </Button>
            </div>
            <DataGrid
              dataSource={calibrateData}
              showRowLines={true}
              showColumnLines={false}
            >
              <FilterRow visible={true} />
              <Column
                dataField={t("EmployeeID")}
                caption={t("EmployeeID")}
                visible={false}
              />
              <Column
                dataField={t("FullName")}
                caption={t("FullName")}
                cssClass="column-info"
                minWidth={240}
                allowSorting={false}
                cellRender={(cellData) => (
                  <div
                    style={{ display: "flex", alignItems: "center", gap: 8 }}
                  >
                    <Avatar src={cellData.data.imageURL} />
                    {getUserFullName(cellData.data)}
                  </div>
                )}
              />
              <Column
                dataField={t("PositionName")}
                caption={t("PositionName")}
                minWidth={160}
                cssClass="column-info"
                allowFiltering={false}
                allowSorting={false}
              />
              <Column
                dataField={t("departmentName")}
                caption={t("departmentName")}
                minWidth={160}
                cssClass="column-info"
                filterOperations={["contains"]}
                allowSorting={false}
              />
              <Column
                dataField="quarter1"
                caption="Q1"
                dataType={"number"}
                cssClass="column-info"
                allowFiltering={false}
                allowSorting={false}
              />
              <Column
                dataField="quarter2"
                caption="Q2"
                dataType={"number"}
                cssClass="column-info"
                allowFiltering={false}
                allowSorting={false}
              />
              <Column
                dataField="quarter3"
                caption="Q3"
                dataType={"number"}
                cssClass="column-info"
                allowFiltering={false}
                allowSorting={false}
              />
              <Column
                dataField="quarter4"
                caption="Q4"
                dataType={"number"}
                cssClass="column-info"
                allowFiltering={false}
                allowSorting={false}
              />
              <Column
                dataField={t("Average")}
                dataType={"number"}
                cssClass="column-info"
                allowFiltering={false}
                sortOrder="desc"
              />
              <Column
                dataField={t("Grade")}
                cssClass="column-total-addition"
                filterOperations={["="]}
                cellRender={(cellData) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Chip
                      size="small"
                      label={cellData.value}
                      sx={{
                        backgroundColor: "#4f65df",
                        color: "#ffffff",
                        flexGrow: 1,
                      }}
                    />
                    <MenuGrade
                      idEmployees={cellData.data.idEmployees}
                      fechData={fechData}
                    />
                  </div>
                )}
              />
              <Pager
                allowedPageSizes={[10, 25, 50, 100]}
                showPageSizeSelector={true}
              />
            </DataGrid>
          </StyledWrapDataGrid>
        </>
      )}
    </>
  );
}

export default CompanyCalibrate;
