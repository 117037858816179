import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  Grid,
  Typography,
  TextField,
  Avatar,
  Box,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import { getDepartment } from "../../../../actions/company";
import { getAllEmployees } from "../../../../actions/employee";

import ButtonBlue from "../../shared/general/ButtonBlue";
import CardStyle from "../../shared/general/Card";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";

//Translator TH-EN
import { useTranslation } from "react-i18next";

import More from "./viewmore";
import DialogImport from "./dialogImport";
import {
  getAllDocuments,
  getDocumentTypeName,
} from "../../../../actions/document";
import {
  getCompanyProfile,
} from "../../../../actions/company";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  paddingBottom: 48,
  "& .wrap-check, .wrap-leave": {
    marginTop: 36,
  },
  "& .wrap-check-in, .wrap-check-out, .leave": {
    borderRadius: 16,
    "& .head": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: 24,
    },
  },
  "& .leave": {
    "& .filter": {
      padding: 24,
      display: "flex",
      justifyContent: "space-between",
    },
  },
});

const StyledBox = styled(Box)({
  "&.open": {
    paddingLeft: 16,
    width: "calc(100% - 1000px)",
  },
  "&.close": {
    paddingLeft: 0,
    width: "100%",
  },
});

const StyledWrapFilter = styled("div")({
  width: "100%",
  display: "flex",
  alignItems: "center",
  marginBottom: 36,
  ["@media (min-width: 0px)"]: {
    flexDirection: "column",
  },
  ["@media (min-width: 900px)"]: {
    flexDirection: "row",
  },
  "& .search-name, .search-date": {
    width: "100%",
  },
  "& .wrap-search-action": {
    marginTop: 50,
    display: "flex",
    justifyContent: "flex-start",
    "& .btn-export": {
      marginLeft: 8,
    },
  },
});

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const StyledCellHeader = styled(TableCell)({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  "& .MuiTypography-root": {
    color: "#637381",
    backgroundColor: "#f4f6f8",
    "&.weekend": {
      fontStyle: "oblique",
    },
    "&.workday": {
      fontWeight: 600,
    },
  },
  "&.sticky": {
    padding: 0,
    position: "sticky",
    left: 0,
    zIndex: 4,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow: "20px 0px 20px #EEEEEE",
    "& .MuiTableCell-root": {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledRowContent = styled(TableRow)({
  cursor: "pointer",
  textDecoration: "none",
  "&.MuiTableRow-hover:hover": {
    backgroundColor: "#f7f7f7",
    "& .sticky": {
      backgroundColor: "#f7f7f7",
    },
  },
});

const StyledCellContent = styled(TableCell)(({ theme }) => ({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  padding: 8,
  "&.sticky": {
    position: "sticky",
    left: 0,
    backgroundColor: "#FFFFFF",
    boxShadow: "8px 20px 20px #EEEEEE",
  },
  "& .avatar": {
    minWidth: 240,
    "& .MuiAvatar-root": {
      marginRight: 8,
    },
    display: "flex",
    alignItems: "center",
  },
  "& .fullname": {
    fontWeight: 600,
  },
  "& .position": {
    color: theme.palette.text.secondary,
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 300,
  },
  "& .secondaryAction": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& .location": {
      fontWeight: 600,
    },
  },
  "&:first-of-type": {
    paddingLeft: 0,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
}));

const DocumentPage = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: departmentList } = useSelector((state) => state.department);
  const { result: employeesList } = useSelector((state) => state.employees);
  const { result: documentName } = useSelector((state) => state.documentItem);
  const { result: employeeDocList } = useSelector((state) => state.document);
  const { result: companyProfile } = useSelector((state) => state.companyProfile);

  const { t, i18n } = useTranslation();

  const { idDocumentGroup } = props;
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [formData, setFormData] = useState({ idCompany: 1 });
  const [doc, setDoc] = useState({ idDocumentType: 1, documentTypeName: 'default' });
  const [docName, setDocname] = useState([]);
  const [idEmployees, setIdEmployees] = React.useState(null);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    dispatch(getDepartment());
    dispatch(getAllEmployees());
    dispatch(getAllDocuments());
    dispatch(getCompanyProfile());
  }, []);

  useEffect(() => {
    if (userProfile) {
      dispatch(getAllDocuments());
      dispatch(getDocumentTypeName(idDocumentGroup, idEmployees, doc.idDocumentType, doc.documentTypeName, userProfile.idCompany));
    }
  }, [userProfile, idDocumentGroup]);

  const docNameActivate = documentName ? documentName.filter(doc => doc.isActivate === 1) : [];

  const setdata = () => {
    if (employeeDocList)
      if (employeeDocList.length > 0 && selectedDepartment) {
        let temp = employeeDocList.filter((row) => {
          if (selectedDepartment) {
            return row.idDepartment === selectedDepartment;
          } else {
            return row;
          }
        });
        if (employeeDocList.length > 0 && selectedEmployee) {
          return [...temp].filter(
            (item) => item.idEmployees === (selectedEmployee && selectedEmployee.idEmployees)
          );
        } else {
          return [...temp];
        }
      } else if (employeeDocList.length > 0 && selectedEmployee) {
        return [...employeeDocList].filter(
          (item) => item.idEmployees === (selectedEmployee && selectedEmployee.idEmployees)
        );
      } else {
        return [...employeeDocList];
      }
  };

  useEffect(() => {
    if (documentName)  // ชนิดที่ 1 ถึง 5
      setDocname(
        documentName.filter((d) => {
          return d.isShow === 1;
        })
      );
  }, [documentName]);

  const [openRow, setOpenRow] = useState(null);
  const [open, setOpen] = useState(false);
  const [typeDialog, setTypeDialog] = useState(null);
  const [savedDoc, setSavedDoc] = useState(null)

  const handleToggle = (type, index, d) => {
    setOpenRow(openRow === index ? null : index);
    setSavedDoc(d);
  };

  useEffect(() => {
    if (!open && typeDialog === 'more' && savedDoc) {
      setDoc(savedDoc);
    }
  }, [open, typeDialog, savedDoc]);

  const handleClose = () => {
    setOpen(false);
    setTypeDialog("more");
  };

  useEffect(() => {
    if (reload) {
      dispatch(getAllDocuments());
      setReload(true);
    } else {
      setReload(false);
    }
  }, [reload]);

  const handleDialog = async (type, formData, d) => {
    setTypeDialog(type);
    setOpen(true);

    if (formData) {
      setFormData(formData);
      setIdEmployees(formData.idEmployees);
    }
    if (d) {
      if (type === 'edit') {
        try {
          const res = await dispatch(
            getDocumentTypeName(
              d.idDocumentGroup,
              formData.idEmployees,
              d.idDocumentType,
              d.documentTypeName,
              companyProfile.idCompany
            ));
          console.log('res', res);

          const foundDocumentType = res.data.find(x => x.idDocumentType === d.idDocumentType);
          if (foundDocumentType) {
            setDoc({
              ...foundDocumentType,
              idDocumentGroup: d.idDocumentGroup,
              idDocumentType: d.idDocumentType,
              documentTypeName: d.documentTypeName,
              fileURL: foundDocumentType.fileURL,
              idCompany: companyProfile.idCompany
            });
          }
        } catch (err) {
          console.error('Error fetching document type:', err);
        }
      } else if (type === 'more') {
        setDoc(d);
      } else if (type === 'import') {
        setOpenRow(null)
        setDoc(d);
      }
    } else {
      setDoc({ idDocumentType: 1, documentTypeName: '' });
    }
  };

  return (
    <div>
      <StyledRoot>
        <StyledWrapFilter>
          <Grid
            container
            spacing={2}
            columns={12}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item xs={12} sm={6} md={3}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {t("SearchEmp")}
                </Typography>
                <div className="search-name">
                  {employeesList && (
                    <StyledAutocomplete
                      options={employeesList}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setSelectedEmployee(newValue);
                        } else {
                          setSelectedEmployee(null);
                        }
                      }}
                      popupIcon={<i className="fa-light fa-chevron-down"></i>}
                      getOptionLabel={(option) =>
                        `${option.firstname_TH} ${option.lastname_TH}`
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="filled"
                          placeholder={`${t("SelectEmp")}`}
                        />
                      )}
                      PopperComponent={StyledPopper}
                      noOptionsText={`${t("NoData")}`}
                    />
                  )}
                </div>
              </StyledBoxSearch>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {t("Department")}
                </Typography>
                <div className="search-name">
                  {departmentList && (
                    <StyledAutocomplete
                      options={departmentList}
                      onChange={(event, newValue) => {
                        if (newValue)
                          setSelectedDepartment(newValue.idDepartment);
                        else setSelectedDepartment(null);
                      }}
                      popupIcon={<i className="fa-light fa-chevron-down"></i>}
                      getOptionLabel={(option) => option.departmentName}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="filled"
                          placeholder={`${t("AllDepartment")}`}
                        />
                      )}
                      PopperComponent={StyledPopper}
                      noOptionsText={`${t("NoData")}`}
                    />
                  )}
                </div>
              </StyledBoxSearch>
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <Grid
                container
                spacing={2}
                justifyContent="flex-end"
              >
                <Grid item xs={12}>
                  <StyledBoxSearch>
                    <div className="wrap-search-action" style={{ display: "flex", justifyContent: "flex-end" }}>
                      <ButtonBlue
                        variant="contained"
                        startIcon={<FileUploadOutlinedIcon />}
                        onClick={() => {
                          history.push(`/employees-documents/add`);
                        }}
                        style={{ marginRight: 10 }}
                      >
                        {t("UploadDocuments")}
                      </ButtonBlue>
                      <ButtonBlue
                        variant="contained"
                        startIcon={<FileUploadOutlinedIcon />}
                        onClick={() => history.push("/employees-documents/edit")}
                      >
                        {t("EditDocumentType")}
                      </ButtonBlue>
                    </div>
                  </StyledBoxSearch>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </StyledWrapFilter>
        {docName && employeeDocList ? (
          <CardStyle>
            <Box style={{ padding: "24px", userSelect: 'none' }}>
              <Box style={{ display: "flex", position: "relative" }}>
                <StyledBox className={"close"}>
                  <TableContainer style={{ width: "100%", maxHeight: 600 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <StyledCellHeader
                            align={"center"}
                            className="sticky"
                            style={{ minWidth: 240 }}
                          >
                            {t("FullName")}
                          </StyledCellHeader>
                          {docName.map((d, index) => {
                            return (
                              <StyledCellHeader>
                                <Typography variant="body2" align="center">
                                  {d.documentTypeName}
                                </Typography>
                              </StyledCellHeader>
                            );
                          })}
                          <StyledCellHeader>
                            <Typography variant="body2" align="center">
                              {t("More")}
                            </Typography>
                          </StyledCellHeader>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {employeeDocList ?
                          setdata().map((row, index) => (
                            <React.Fragment key={index}>
                              <StyledRowContent>
                                <StyledCellContent
                                  key={`${index}`}
                                  className="sticky"
                                  style={{
                                    borderBottom: "1px dashed rgba(224, 224, 224, 1)",
                                  }}
                                >
                                  <div className="avatar">
                                    <Avatar src={`${row.imageProfile}`} />
                                    <div>
                                      <Typography className="fullname">
                                        {row.firstname_TH} {row.lastname_TH}
                                      </Typography>
                                    </div>
                                  </div>
                                </StyledCellContent>
                                {docName.map((d, idx) => (
                                  <StyledCellContent align="center" key={idx}>
                                    {row.file.find((r) => r.idDocumentType === d.idDocumentType) ? (
                                      <CheckCircleOutlineIcon
                                        style={{ color: "green" }}
                                        onClick={() =>
                                          // window.open(
                                          //   row.file.find((r) => r.idDocumentType === d.idDocumentType).fileURL
                                          // )
                                          handleDialog("edit", row, d)
                                        }
                                      />
                                    ) : (
                                      <CancelOutlinedIcon
                                        style={{ color: "red" }}
                                        onClick={() => handleDialog("import", row, d)}
                                      />
                                    )}
                                  </StyledCellContent>
                                ))}
                                <StyledCellContent align="center">
                                  <ExpandCircleDownOutlinedIcon
                                    onClick={() => {
                                      handleDialog("more", row, docNameActivate);
                                      handleToggle("more", index, docNameActivate);
                                    }}
                                  />
                                </StyledCellContent>
                              </StyledRowContent>
                              {doc && openRow === index && typeDialog === "more" && (
                                <StyledRowContent>
                                  <StyledCellContent colSpan={docName.length + 4}>
                                    <More
                                      doc={doc}
                                      data={formData}
                                      handleDialog={handleDialog}
                                      handleToggle={handleToggle}
                                    />
                                  </StyledCellContent>
                                </StyledRowContent>
                              )}
                            </React.Fragment>
                          )) : `${t("NoData")}`}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </StyledBox>
              </Box>
            </Box>
          </CardStyle>
        ) : (
          ""
        )}
      </StyledRoot>
      {typeDialog === "import" ? (
        <DialogImport
          open={open}
          setReload={setReload}
          mode={typeDialog}
          empData={formData}
          doc={doc}
          setOpen={setOpen}
          setTypeDialog={setTypeDialog}
          handleClose={handleClose}
          type={typeDialog}
          companyProfile={companyProfile}
        />
      ) : (
        ""
      )}
      {typeDialog === "edit" ? (
        <DialogImport
          open={open}
          setReload={setReload}
          mode={typeDialog}
          empData={formData}
          doc={doc}
          setOpen={setOpen}
          setTypeDialog={setTypeDialog}
          handleClose={handleClose}
          type={typeDialog}
          companyProfile={companyProfile}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default DocumentPage;
