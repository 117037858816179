import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";

import CardStyle from "../../shared/general/Card";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  paddingBottom: 48,
  "& .wrap-check, .wrap-leave": {
    marginTop: 36,
  },
  "& .wrap-check-in, .wrap-check-out, .leave": {
    borderRadius: 16,
    "& .head": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: 24,
    },
  },
  "& .leave": {
    "& .filter": {
      padding: 24,
      display: "flex",
      justifyContent: "space-between",
    },
  },
});

const StyledBox = styled(Box)({
  "&.open": {
    paddingLeft: 16,
    width: "calc(100% - 1000px)",
  },
  "&.close": {
    paddingLeft: 0,
    width: "100%",
  },
});

const StyledCellHeader = styled(TableCell)({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  "& .MuiTypography-root": {
    color: "#637381",
    backgroundColor: "#f4f6f8",
    "&.weekend": {
      fontStyle: "oblique",
    },
    "&.workday": {
      fontWeight: 600,
    },
  },
  "&.sticky": {
    padding: 0,
    position: "sticky",
    left: 0,
    zIndex: 4,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow: "20px 0px 20px #EEEEEE",
    "& .MuiTableCell-root": {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledRowContent = styled(TableRow)({
  cursor: "pointer",
  textDecoration: "none",
  "&.MuiTableRow-hover:hover": {
    backgroundColor: "#f7f7f7",
    "& .sticky": {
      backgroundColor: "#f7f7f7",
    },
  },
  justifyContent: "space-between",
});

const StyledCellContent = styled(TableCell)(({ theme }) => ({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  padding: 8,
  "&.sticky": {
    position: "sticky",
    left: 0,
    backgroundColor: "#FFFFFF",
    boxShadow: "8px 20px 20px #EEEEEE",
  },
  "& .avatar": {
    minWidth: 240,
    "& .MuiAvatar-root": {
      marginRight: 8,
    },
    display: "flex",
    alignItems: "center",
  },
  "& .fullname": {
    fontWeight: 600,
  },
  "& .position": {
    color: theme.palette.text.secondary,
    fontSize: 14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: 300,
  },
  "& .secondaryAction": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& .location": {
      fontWeight: 600,
    },
  },
  "&:first-of-type": {
    paddingLeft: 0,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
}));

const More = (props) => {
  const { data, doc, handleDialog} = props;
  console.log('data222: ', data);
  console.log(' data444: ',  doc);
  
  const { t, i18n } = useTranslation();

  return (
    <div>
      {data && (
        <StyledRoot>
          <CardStyle>
            <Box style={{ padding: "24px" }}>
              <Box style={{ display: "flex", position: "relative" }}>
                <StyledBox className={"close"}>
                  <div style={{ marginBottom: 8 }}>
                    <Typography
                      variant="h6"
                      style={{ paddingTop: 8, paddingBottom: 4 }}
                    >
                      {t("AdditionalDocumentFile")} {data.firstname_TH}{" "}
                      {data.lastname_TH}
                    </Typography>
                  </div>
                  <TableContainer style={{ width: "100%", maxHeight: 600 }}>
                    <Table stickyHeader aria-label="sticky table">
                      <TableHead>
                        <TableRow>
                          <StyledCellHeader
                            align={"center"}
                            className="sticky"
                            style={{ minWidth: 240 }}
                          >
                            {t("Document")} 
                          </StyledCellHeader>
                          <StyledCellHeader>
                            <Typography variant="body2" align="center">
                              file 
                            </Typography>
                          </StyledCellHeader>
                          <StyledCellHeader>
                            <Typography variant="body2" align="center">
                              {t("StartDate")}
                            </Typography>
                          </StyledCellHeader>
                          <StyledCellHeader>
                            <Typography variant="body2" align="center">
                              {t("ExpirationDate")}
                            </Typography>
                          </StyledCellHeader>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Array.isArray(doc)  && doc.length > 0 ? (
                          doc.map((row, index) =>
                                row.isShow === 0 ? (
                                  <StyledRowContent>
                                    <StyledCellContent
                                      className="sticky"
                                      style={{
                                        borderBottom:
                                          "1px dashed rgba(224, 224, 224, 1)",
                                      }}
                                    >
                                      <Typography>
                                        {row.documentTypeName}
                                      </Typography>
                                    </StyledCellContent>

                                    <StyledCellContent align="center">
                                      {data.file.find((r) => {
                                        console.log('r.idDocumentType:', data); 
                                        return (
                                          r.idDocumentType === row.idDocumentType
                                        );
                                      }) ? (
                                        <>
                                          <CheckCircleOutlineIcon
                                            style={{ color: "green" }}
                                            onClick={() => {
                                              handleDialog("edit", data, row)
                                            }}
                                          />
                                          <EditIcon
                                            onClick={() => {
                                              handleDialog("edit", data, row);
                                            }}
                                          />
                                        </>
                                      ) : (
                                        <CancelOutlinedIcon
                                          style={{ color: "red" }}
                                          onClick={() =>
                                            handleDialog("import", data, row)
                                          }
                                        />
                                      )}
                                    </StyledCellContent>

                                    <StyledCellContent align="center">
                                      <Typography>
                                        {row.startDate ? row.startDate : "-"}
                                      </Typography>
                                    </StyledCellContent>

                                    <StyledCellContent align="center">
                                      <Typography>
                                        {row.endDate ? row.endDate : "-"}
                                      </Typography>
                                    </StyledCellContent>
                                  </StyledRowContent>
                                ) : (
                                  ""
                                )
                        ))
                        : `${t("NoData")}`}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </StyledBox>
              </Box>
            </Box>
          </CardStyle>
        </StyledRoot>
      )}
    </div>
  );
};

export default More;
