import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Container,
  Grid,
  styled,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Button,
  IconButton,
  TableRow,
  Menu,
  MenuItem,
  Popper,
  useMediaQuery,
  ListSubheader,
  Chip,
} from "@mui/material";

import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

import SearchIcon from "@mui/icons-material/Search";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";

import DatePickerCustom from "../../shared/date/datePicker";

import CardDate from "./cardDate";
import CardStyle from "../../shared/general/Card";
import ButtonBlue from "../../shared/general/ButtonBlue";
import AlertResponse from "../../shared/general/AlertResponse";
import DialogConfirm from "../../shared/general/DialogConfirm";
import DialogConfirmDayOffHoliday from "../../shared/general/DialogConfirmDayOffHoliday";
import DialogConfirmDelete from "../../shared/general/DialogConfirmDelete";
import StatusRequest from "../../shared/general/stausRequest";

import Timer from "../../assets/timer.png";

//Translator TH-EN
import { useTranslation } from "react-i18next";

import {
  getTimeschedule,
  employeeChangeTime,
  employeeChangeTimeWithdraw,
  getTimescheduleHolidayUsed,
} from "../../../../actions/employee";
import { getShift } from "../../../../actions/shift";
import { allHoliday } from "../../../../actions/holiday";
import { getTimeSceduleByIdEmployeesV2 } from "../../../../actions/attendance";
import { openNotificationAlert } from "../../../../actions/notificationAlert";

dayjs.locale("th");

const StyledRoot = styled(Box)({
  backgroundColor: "#FFFFFF !important",
  "& .MuiContainer-root": {
    paddingBottom: 16,
  },
});

const StyledWrapFilter = styled("div")({
  width: "100%",
  display: "flex",
  alignItems: "center",
  marginBottom: 36,
  ["@media (min-width: 0px)"]: {
    flexDirection: "column",
  },
  ["@media only screen and (max-width: 600px)"]: {
    marginBottom: "16px",
  },
  ["@media (min-width: 900px)"]: {
    flexDirection: "row",
  },
  "& .search-name, .search-date": {
    width: "100%",
  },
  "& .wrap-search-action": {
    marginTop: 50,
    display: "flex",
    justifyContent: "flex-start",
    ["@media only screen and (max-width: 600px)"]: {
      marginTop: 0,
    },
    "& .btn-export": {
      marginLeft: 8,
    },
  },
});

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  ["@media only screen and (max-width: 600px)"]: {
    marginTop: 0,
  },
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledBox = styled(Box)({
  "&.open": {
    paddingLeft: 16,
    width: "calc(100% - 1000px)",
  },
  "&.close": {
    paddingLeft: 0,
    width: "100%",
  },
});

const StyledCellHeader = styled(TableCell)({
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  "& .MuiTypography-root": {
    color: "#637381",
    backgroundColor: "#f4f6f8",
    "&.weekend": {
      fontStyle: "oblique",
    },
    "&.workday": {
      fontWeight: 600,
    },
  },
  "&.sticky": {
    padding: 0,
    position: "sticky",
    left: 0,
    zIndex: 4,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    boxShadow: "20px 0px 20px #EEEEEE",
    "& .MuiTableCell-root": {
      borderTopLeftRadius: 8,
      borderBottomLeftRadius: 8,
    },
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledRowContent = styled(TableRow)({
  cursor: "pointer",
  textDecoration: "none",
  "&.MuiTableRow-hover:hover": {
    backgroundColor: "#f7f7f7",
    "& .sticky": {
      backgroundColor: "#f7f7f7",
    },
  },
});

const StyledCellContent = styled(TableCell)({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  padding: 8,
  "&.sticky": {
    position: "sticky",
    left: 0,
    backgroundColor: "#FFFFFF",
    boxShadow: "8px 20px 20px #EEEEEE",
  },
  "&:first-of-type": {
    paddingLeft: 0,
  },
  "&:last-of-type": {
    paddingRight: 24,
  },
});

const StyledMenu = styled(Menu)({
  "& .MuiMenu-paper": {
    borderRadius: 8,
    boxShadow:
      "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 12px 24px 0px",
  },
  "& .MuiList-root": {
    padding: 8,
  },
  "& .MuiMenuItem-root": {
    borderRadius: 6,
    "&:hover": {
      textDecoration: "none",
      backgroundColor: "#919eab14",
    },
  },
  "& .MuiListSubheader-root": {
    paddingLeft: 8,
    paddingRight: 8,
  },
});

const StyledIconDelete = styled(IconButton)({
  "&:hover": {
    backgroundColor: "#fdf3f5",
  },
});

const StyledButtonTime = styled(Button)(({ status, color }) => ({
  borderRadius: 8,
  color: "#212b36",
  borderColor: "#dcdcdc",
  ...(status === 0 && {
    borderColor: "#f5f5f5",
    color: "#212b36",
    backgroundColor: "#f5f5f5",
    "&:hover": {
      borderColor: "#f0f0f0",
      backgroundColor: "#f0f0f0",
    },
  }),
}));

const ITEM_HEIGHT = 48;

const StyledHolidayChip = styled(Chip)({
  margin: 0,
  fontSize: 14,
  fontWeight: 600,
  height: 26,
  backgroundColor: "#f7f5ff",
  color: "#7451ff",
})

const TimeSchedule = () => {
  const today = dayjs().toDate();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const mobileResponsive = useMediaQuery("(max-width:600px)");
  const [inputSearch, setInputSearch] = useState(dayjs().add(1, "month"));
  const { result: shiftGroupList } = useSelector((state) => state.shift);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: holiday } = useSelector((state) => state.holiday);
  const [rowData, setRowData] = useState(null);
  const [openAlert, setOpenAlert] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openConfirmHoliday, setOpenConfirmHoliday] = useState(false);
  const [openDialogConfirmDelete, setOpenDialogConfirmDelete] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selected, setSelected] = React.useState(null);
  const [selectedTime, setSelectedTime] = React.useState(null);
  const [selectedHoliday, setSelectedHoliday] = React.useState(null);
  const [holidayUsedList, setHolidayUsedList] = React.useState([]);

  const [selectedDate, setSelectedDate] = useState(null);

  const { result: employeeShiftList } = useSelector(
    (state) => state.employeeShift
  );

  useEffect(() => {
    if(userProfile){
      fetchData();
      dispatch(getShift());
      dispatch(allHoliday(dayjs(new Date()).format("YYYY"), { idEmployees: userProfile.idEmployees }));
    }
  }, [userProfile]);

  // useEffect(() => {
  //   if(userProfile !== null){

  //   }
  // }, [userProfile]);

  const fetchData = async () => {
    const start = dayjs(inputSearch).set("date", 1);
    const end = dayjs(inputSearch).set("date", start.daysInMonth());
    dispatch(getTimeSceduleByIdEmployeesV2(userProfile.idEmployees, {start: dayjs(start).format("YYYY-MM-DD"), end: dayjs(end).format("YYYY-MM-DD")}))
    // const result = await dispatch(
    //   getTimeschedule(dayjs(inputSearch).format("YYYY-MM-DD"))
    // );
    // if (result) {
    //   if (result.status === 200) {
    //     console.log("result.data: ", result.data);
    //     let tempData = result.data;
    //     tempData.forEach((element) => {
    //       let foundWaitingApprove = element.requestChange.find(
    //         (x) => x.isApprove === null
    //       );
    //       element.isWaitingApprove = foundWaitingApprove ? true : false;
    //     });
    //     setRowData(tempData);
    //   } else {
    //     handleChangeAlertType("error");
    //     handleOpenAlert();
    //   }
    // }

    const holidayUsed = await dispatch(
      getTimescheduleHolidayUsed({
        start: dayjs(inputSearch).format("YYYY-MM-DD"),
      })
    ).then((res) => {
      if (res) {
        setHolidayUsedList(res.data);
      }
    });
  };

  const handleClickSearch = async () => {
    fetchData();
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleClickChangeTime = (item) => {
    console.log("handleClickChangeTime: ", item);
    setSelectedTime(item);
    setOpenConfirm(true);
  };

  const handleClickChangeDayOff = (holiday) => {
    if(selectedDate){
      setSelectedTime({
        ...selectedDate.dataRender
      })
      setOpenConfirmHoliday(true);
    }
  };

  const handleConfirmHoliday = async () => {

    if(selectedDate.dataRender.isWorkingDay === 0){
      dispatch(openNotificationAlert({
        message: "ไม่สามารถเปลี่ยนวันหยุดตรงกับวันหยุดนขัตฤกษ์ได้",
        type: "error"
      }))
      handleCloseDialog();
      return 
    }

    if(selectedDate.requestChange && selectedDate.requestChange.find(item => item.isActive === 1 && item.isApprove === null)){
      dispatch(openNotificationAlert({
        message: "ไม่สามารถเปลี่ยนซ้ำรายการที่อยู่ระหว่างรออนุมัติได้",
        type: "error"
      }))
      handleCloseDialog();
      return 
    }

    const dataForm = {
      idEmployees: userProfile.idEmployees,
      idShift: selectedDate.dataRender.idShift,
      idShiftType: selectedDate.dataRender.idShiftType,
      workingDate: selectedDate.date,
      idShiftGroup: selectedDate.dataRender.idShiftGroup,
      isWorkingDay: selectedDate.dataRender.isWorkingDay,
      idHoliday: selectedHoliday,
      idShiftGroupPrev: selectedDate.dataRender.idShiftGroup,
      idShiftPrev: selectedDate.dataRender.idShift,
      idShiftTypePrev: selectedDate.dataRender.idShiftType,
      isWebsite: 1,
    };

    if(!dataForm.idShiftType){
      dispatch(openNotificationAlert({
        message: "ไม่สามารถเปลี่ยนรายการนี้ได้",
        type: "error"
      }))
      handleCloseDialog();
      return 
    }

    const result = await dispatch(employeeChangeTime(dataForm));
    if (result && result.status === 200) {
      const start = dayjs(inputSearch).set("date", 1);
      const end = dayjs(inputSearch).set("date", start.daysInMonth());
      dispatch(getTimeSceduleByIdEmployeesV2(userProfile.idEmployees, {start: dayjs(start).format("YYYY-MM-DD"), end: dayjs(end).format("YYYY-MM-DD")}))
      handleChangeAlertType("success");
      handleOpenAlert();
      handleCloseDialog();
    } else {
      handleChangeAlertType("error");
      handleOpenAlert();
      handleCloseDialog();
    }
  }

  // const handleConfirm = async () => {
  //   let dataForm = null;
  //   // console.log("selected", selected)
  //   // console.log("selectedTime", selectedTime)
  //   // console.log(selectedHoliday)
  //   // console.log(selectedHoliday)
  //   if (selectedTime.isWorkingDay === 0) {
  //     console.log(selectedTime)
  //     dataForm = {
  //       idEmployees: userProfile.idEmployees,
  //       idShift: selected.item.dataRender.idShift,
  //       idShiftType: selectedHoliday? selectedTime.idShiftType: (selectedTime.shiftType? selectedTime.shiftType.find((x) => x.isWorkingDay === 0).idShiftType: selectedTime.idShiftType),
  //       workingDate: selected.item.date,
  //       idShiftGroup: selectedTime.idShiftGroup,
  //       isWorkingDay: 0,
  //       idHoliday: selectedHoliday,
  //     };
  //   } else {
  //     dataForm = {
  //       idEmployees: userProfile.idEmployees,
  //       idShift: selectedTime.shift[0].idShift,
  //       idShiftType: selectedTime.shiftType.find((x) => x.isWorkingDay === 1).idShiftType,
  //       workingDate: selected.item.date,
  //       idShiftGroup: selectedTime.idShiftGroup,
  //       isWorkingDay: 1,
  //     };
  //   }

  //   const result = await dispatch(employeeChangeTime(dataForm));
  //   if (result && result.status === 200) {
  //     fetchData();
  //     handleChangeAlertType("success");
  //     handleOpenAlert();
  //     handleCloseDialog();
  //   } else {
  //     handleChangeAlertType("error");
  //     handleOpenAlert();
  //     handleCloseDialog();
  //   }
  // };

  const handleConfirm = async () => {
    console.log("selectedTime: ", selectedTime);
    console.log("selected: ", selected);

    if(selectedTime.isWorkingDay === 0 && selectedDate.dataRender.holiday){
      dispatch(openNotificationAlert({
        message: "ไม่สามารถเปลี่ยนวันหยุดตรงกับวันหยุดนขัตฤกษ์ได้",
        type: "error"
      }))
      handleCloseDialog();
      return 
    }

    if(selectedDate.requestChange && selectedDate.requestChange.find(item => item.isActive === 1 && item.isApprove === null)){
      dispatch(openNotificationAlert({
        message: "ไม่สามารถเปลี่ยนซ้ำรายการที่อยู่ระหว่างรออนุมัติได้",
        type: "error"
      }))
      handleCloseDialog();
      return 
    }

    let dataForm = {
      idEmployees: userProfile.idEmployees,
      idShift: selectedTime.idShift,
      idShiftType: selectedTime.idShiftType,
      workingDate: selected.item.date,
      idShiftGroup: selectedTime.idShiftGroup,
      isWorkingDay: selectedTime.isWorkingDay,
      idShiftGroupPrev: selectedDate.dataRender.idShiftGroup,
      idShiftPrev: selectedDate.dataRender.idShift,
      idShiftTypePrev: selectedDate.dataRender.idShiftType,
      isWebsite: 1,
    };
    

    const result = await dispatch(employeeChangeTime(dataForm));
    if (result && result.status === 200) {
      const start = dayjs(inputSearch).set("date", 1);
      const end = dayjs(inputSearch).set("date", start.daysInMonth());
      dispatch(getTimeSceduleByIdEmployeesV2(userProfile.idEmployees, {start: dayjs(start).format("YYYY-MM-DD"), end: dayjs(end).format("YYYY-MM-DD")}))
      // dispatch(getTimeSceduleByIdEmployeesV2(
      //   selectedEmployee.idEmployees,
      //   {
      //     start: dayjs(search.start).format("YYYY-MM-DD"),
      //     end: dayjs(search.end).format("YYYY-MM-DD"),
      //   },
      // ))
      handleChangeAlertType("success");
      handleOpenAlert();
      handleCloseDialog();
    } else {
      handleChangeAlertType("error");
      handleOpenAlert();
      handleCloseDialog();
    }
  };

  const handleSubmitConfirmDelete = async () => {
    console.log("selected: ", selected);
    const dataForm = {
      idEmployeeShiftDaily: selected.idEmployeeShiftDaily,
      idEmployees: selected.idEmployees,
    };

    const result = await dispatch(employeeChangeTimeWithdraw(dataForm));
    if (result && result.status === 200) {
      fetchData();
      handleChangeAlertType("success");
      handleOpenAlert();
      handleCloseDialog();
    } else {
      handleChangeAlertType("error");
      handleOpenAlert();
      handleCloseDialog();
    }
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleCloseDialog = () => {
    setOpenConfirm(false);
    setOpenConfirmHoliday(false);
    setOpenDialogConfirmDelete(false);
    setAnchorEl(null);
    setSelectedHoliday(null);
  };

  const getStatus = (item) => {

    if(item.requestChange && item.requestChange.length > 0){

      const lastestEmployeeShiftDaily = item.requestChange.filter(x => x.isApprove === 1 && x.isActive === 1);

      if(lastestEmployeeShiftDaily.length > 0){
        return lastestEmployeeShiftDaily[lastestEmployeeShiftDaily.length-1].isWorkingDay;
      }
    }

    return item.dataRender.isWorkingDay
  }

  const renderHolidayChip = (item) => {
    if(item.dataRender.holiday){
      if(item.dataRender.holiday.isCompensate){
        return (
          <StyledHolidayChip
            label={`ชดเชย ${item.dataRender.holiday.name}`}
          />
        )
      } else {
        return (
          <StyledHolidayChip
            label={`${item.dataRender.holiday.name}`}
          />
        )
      }
    }
  }

  const renderShiftTime = (item) => {
    if(item.requestChange && item.requestChange.length > 0){

      const lastestEmployeeShiftDaily = item.requestChange.filter(x => x.isApprove === 1 && x.isActive === 1);

      if(lastestEmployeeShiftDaily.length > 0){
        if(lastestEmployeeShiftDaily[lastestEmployeeShiftDaily.length-1].isWorkingDay === 1){
          return (
            <Fragment>
              <img src={Timer} alt="Timer" width={26} style={{ marginRight: 8 }} />
              {lastestEmployeeShiftDaily[lastestEmployeeShiftDaily.length-1].shiftGroupName}
            </Fragment>
          )
        } else {
          return `${t("DayOff")}`
        }
      }
    }


    if (item.dataRender.isWorkingDay === 0) {
      if (item.dataRender.idHoliday > 0) {
        const foundHoliday = holiday.find(
          (x) => x.idHoliday === item.dataRender.idHoliday
        );
        return (
          <Fragment>
            <Typography>{`${t("PublicHoliday")}`}</Typography>
          </Fragment>
        );
      } else {
        return `${t("DayOff")}`;
      }
    } else {
      return (
        <Fragment>
          <img src={Timer} alt="Timer" width={26} style={{ marginRight: 8 }} />
          {item.dataRender.shiftGroupName}
        </Fragment>
      );
    }
  };

  const renderHoliday = (item) => {
    if (item.dataRender.isWorkingDay === 0) {
      if (item.dataRender.idHoliday > 0) {
        const foundHoliday = holiday.find(
          (x) => x.idHoliday === item.dataRender.idHoliday
        );
        return (
          <Fragment>
            {foundHoliday && (
              <Typography
                style={{
                  marginTop: 4,
                  marginBottom: 4,
                  fontSize: 14,
                  fontStyle: "oblique",
                }}
                color="text.secondary"
              >
                {foundHoliday.name}
              </Typography>
            )}
          </Fragment>
        );
      }
    }
  };

  const handleClickDeleteButton = (request) => {
    setOpenDialogConfirmDelete(true);
    setSelected(request);
  };

  const renderShiftChange = (requestChange, mode) => {
    let request = null;
    if (mode === "wait") {
      request = requestChange.find((x) => x.isApprove === null);
    } else {
      request = requestChange.filter((x) => x.isApprove !== null);
      request = request[request.length - 1];
    }

    const foundHoliday = holiday.find(
      (x) => request && x.idHoliday === request.idHoliday
    );

    if (shiftGroupList && request) {
      const foundShiftGroup = shiftGroupList.find(
        (x) => x.idShiftGroup === request.idShiftGroup
      );
      const foundShiftType =
        foundShiftGroup &&
        foundShiftGroup.shiftType.find(
          (x) => x.idShiftType === request.idShiftType
        );

      return (
        <Fragment>
          {request.isActive && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <StatusRequest
                boxSize={40}
                fontSize={22}
                borderRadius={8}
                status={request.isApprove}
                active={request.isActive}
              />
              <Fragment>
                {request.isApprove !== null ? (
                  <div style={{ marginLeft: 8 }}>
                    <Typography variant="subtitle1" color="text.third">
                      <Typography
                        style={{ marginRight: 4, fontWeight: 500 }}
                        component="span"
                        color="text.primary"
                      >
                        {request.fillInApprove
                          ? `${request.fillInChange_firstname_th} ${request.fillInChange_lastname_th}`
                          : request.approveBy
                            ? `${request.firstname_TH} ${request.lastname_TH}`
                            : "-"
                        }
                      </Typography>
                      {request.isApprove === 1
                        ? `(${t("Approved")})`
                        : request.isApprove === 0
                        ? `(${t("NotApproved")})`
                        : `(${t("PendingApproval")})`}
                    </Typography>
                    {request.idHoliday && (
                      <Typography color="#7451FF" fontSize="12px" fontWeight="500">{request.holidayName}</Typography>
                    )}
                    <Typography color="text.secondary" variant="body2">
                      {`${t("ApprovedDate")}: ${
                        request.approveDate
                          ? dayjs(request.approveDate).format("DD/MM/BBBB HH:mm")
                          : "-"
                      }`}
                    </Typography>
                  </div>
                ) : (
                  <div style={{ marginLeft: 8 }}>
                    <div style={{ display: "flex" }}>
                      <Typography variant="subtitle1" color="text.third">
                        <Typography
                          style={{ marginRight: 4, fontWeight: 500 }}
                          component="span"
                          color="text.primary"
                        >
                          {foundShiftType ? 
                            (foundShiftType.isWorkingDay === 0)
                              ? foundHoliday
                                ? `${t("PublicHoliday")}: ${foundHoliday.name}`
                                : "วันหยุด"
                              : foundShiftGroup.shiftGroupName
                            : "ไม่พบประเภทเวลาทำงาน"
                          }
                        </Typography>
                        {request.isApprove === 1
                          ? `(${t("Approved")})`
                          : request.isApprove === 0
                          ? `(${t("NotApproved")})`
                          : `(${t("PendingApproval")})`}
                      </Typography>
                      <StyledIconDelete
                        aria-label="delete"
                        size="small"
                        onClick={() => {
                          //console.log("request: ", request);
                          handleClickDeleteButton(request);
                        }}
                      >
                        <HighlightOffRoundedIcon
                          style={{ color: "#e46a76" }}
                          fontSize="inherit"
                        />
                      </StyledIconDelete>
                    </div>
                    {request.idHoliday && (
                      <Typography color="#7451FF" fontSize="12px" fontWeight="500">{request.holidayName}</Typography>
                    )}
                    <Typography color="text.secondary" variant="body2">
                      {`${t("Create")}: ${dayjs(
                        request.createDateText,
                        "YYYY-MM-DD HH:mm"
                      ).format("DD/MM/BBBB HH:mm")}`}
                    </Typography>
                  </div>
                )}
              </Fragment>
            </div>
          )}
        </Fragment>
      );
    }
  };

  const handleClickButtonTime = (event, item) => {
    setSelectedDate(item)
    if (item.isWaitingApprove) {
      alert(
        `${t("CannotProceedTransactionAsThereArePendingApprovalRequests")}`
      );
    } else {
      setAnchorEl(event.currentTarget);
      setSelected({ rowData, item });
    }
  };

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Typography variant="h4" style={{ paddingTop: 8, marginBottom: 16 }}>
          {`${t("ShiftSchedule")}`}
        </Typography>
        {userProfile && (
          <Fragment>
            <StyledWrapFilter>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={4}>
                  <StyledBoxSearch>
                    <Typography className="label" color="text.third">
                      {`${t("SearchDate")}`}
                    </Typography>
                    <div className="search-date">
                      <DatePickerCustom
                        minDate={
                          dayjs(userProfile.hiringDate).isAfter(
                            dayjs("2023-04-01")
                          )
                            ? new Date(userProfile.hiringDate)
                            : new Date("2023-04-01")
                        }
                        inputFormat="MMMM YYYY"
                        value={inputSearch}
                        name="start"
                        views={["year", "month"]}
                        openTo="month"
                        onChange={(newValue) => {
                          setInputSearch(newValue);
                        }}
                      />
                    </div>
                  </StyledBoxSearch>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <StyledBoxSearch>
                    <div className="wrap-search-action">
                      <div>
                        <ButtonBlue
                          variant="contained"
                          startIcon={<SearchIcon />}
                          onClick={handleClickSearch}
                        >
                          {`${t("Search")}`}
                        </ButtonBlue>
                      </div>
                      {/* <div className="btn-export">
                    <ButtonBlue
                      variant="outlined"
                      startIcon={<FileDownloadIcon />}
                      onClick={onExportExcel}
                    >
                      ดาวน์โหลด
                    </ButtonBlue>
                  </div> */}
                    </div>
                  </StyledBoxSearch>
                </Grid>
              </Grid>
            </StyledWrapFilter>

            {!mobileResponsive ? (
              <CardStyle>
                <Box style={{ padding: "24px" }}>
                  <Box style={{ display: "flex", position: "relative" }}>
                    <StyledBox className={"close"}>
                      <TableContainer style={{ width: "100%" }}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              <StyledCellHeader
                                key="date"
                                align={"center"}
                                style={{ minWidth: 150 }}
                              >
                                {`${t("Date")}`}
                              </StyledCellHeader>
                              <StyledCellHeader
                                key="date"
                                align={"center"}
                                style={{ minWidth: 200, maxWidth: 300 }}
                              >
                                {t("WorkingTime")}
                              </StyledCellHeader>
                              <StyledCellHeader
                                key="date"
                                align={"center"}
                                style={{ minWidth: 250 }}
                              >
                                {`${t("Status")}`}
                              </StyledCellHeader>
                              <StyledCellHeader
                                key="note"
                                align={"center"}
                                style={{ minWidth: 250, maxWidth: 300 }}
                              >
                                {t("Note")}
                              </StyledCellHeader>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {/* {holiday &&
                              rowData &&
                              rowData */}
                            {holiday &&
                              employeeShiftList &&
                              employeeShiftList
                                // .filter((r) => {
                                //   return !dayjs(r.date).isBefore(
                                //     dayjs(userProfile.hiringDate)
                                //   );
                                // })
                                .map((item, index) => (
                                  <StyledRowContent key={`${index}`}>
                                    <StyledCellContent align={"center"}>
                                      <div>
                                        <Typography
                                          variant="h6"
                                          style={{ fontSize: 16 }}
                                        >
                                          {dayjs(item.date).format(
                                            "DD MMM BBBB"
                                          )}
                                        </Typography>
                                        <Typography color="text.secondary">{`(${dayjs(
                                          item.date
                                        ).format("dddd")})`}</Typography>
                                      </div>
                                    </StyledCellContent>
                                    <StyledCellContent align={"center"}>
                                      <StyledButtonTime
                                        style={{ width: 156 }}
                                        variant="outlined"
                                        status={getStatus(item)}
                                        disableFocusRipple={true}
                                        disableRipple={true}
                                        aria-label="more"
                                        onClick={(event) =>
                                          handleClickButtonTime(event, item)
                                        }
                                      >
                                        {renderShiftTime(item)}
                                      </StyledButtonTime>
                                      {renderHoliday(item)}
                                    </StyledCellContent>
                                    <StyledCellContent>
                                      {item.requestChange &&
                                        item.requestChange.length > 0 &&
                                        renderShiftChange(
                                          item.requestChange,
                                          "approve"
                                        )}
                                      {item.requestChange &&
                                        item.requestChange.length > 0 &&
                                        renderShiftChange(
                                          item.requestChange,
                                          "wait"
                                        )}
                                    </StyledCellContent>
                                    <StyledCellContent align={"center"} style={{ minWidth: 250, maxWidth: 300 }}>
                                      {renderHolidayChip(item)}
                                    </StyledCellContent>
                                  </StyledRowContent>
                                ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </StyledBox>
                  </Box>
                </Box>
              </CardStyle>
            ) : (
              <div>
                {rowData &&
                rowData.filter((r) => {
                  return !dayjs(r.date).isBefore(dayjs(userProfile.hiringDate));
                }).length > 0 ? (
                  rowData
                    .filter((r) => {
                      return !dayjs(r.date).isBefore(
                        dayjs(userProfile.hiringDate)
                      );
                    })
                    .map((row, index) => (
                      <CardDate
                        row={row}
                        keys={`${index}_${dayjs(row.date).format(
                          "DD/MM/BBBB"
                        )}`}
                        handleClickButtonTime={handleClickButtonTime}
                        setOpenDialogConfirmDelete={setOpenDialogConfirmDelete}
                        setSelected={setSelected}
                        handleClickDeleteButton={handleClickDeleteButton}
                      />
                    ))
                ) : (
                  <Typography>{`${t("NoData")}`}</Typography>
                )}
              </div>
            )}
          </Fragment>
        )}
        {shiftGroupList && (
          <StyledMenu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
              },
            }}
          >
            <ListSubheader>{t("WorkingDay")}</ListSubheader>
            {shiftGroupList
              .filter((s) => s.idWorkingType === 2)
              .map(shiftGroup => (
                <Fragment>
                  {shiftGroup.shiftType.filter(s => s.isWorkingDay === 1).map(shiftType => (
                    <MenuItem onClick={() => handleClickChangeTime({...shiftType, shiftGroupName: shiftGroup.shiftGroupName,idShift: shiftGroup.shift[0].idShift})}>
                      {shiftGroup.shiftGroupName}
                    </MenuItem>
                  ))}
                </Fragment>
              ))
            }
            <ListSubheader>{t("WeeklyHoliday")}</ListSubheader>
            {shiftGroupList
              .filter((s) => s.idWorkingType === 2)
              .map(shiftGroup => (
                <Fragment>
                  {shiftGroup.shiftType.filter(s => s.isWorkingDay === 0).map(shiftType => (
                    <MenuItem onClick={() => handleClickChangeTime({...shiftType, shiftGroupName: shiftGroup.shiftGroupName, idShift: shiftGroup.shift[0].idShift})}>
                      {shiftGroup.shiftGroupName}
                    </MenuItem>
                  ))}
                </Fragment>
              ))
            }
            <ListSubheader>{t("PublicHoliday")}</ListSubheader>
            <MenuItem onClick={() => handleClickChangeDayOff("holiday")}>
              {t("PublicHoliday")}
            </MenuItem>
          </StyledMenu>
        )}

        <AlertResponse
          open={openAlert}
          handleClose={handleCloseAlert}
          alertType={alertType}
        />
        {selected && openConfirm && (
          <DialogConfirm
            open={openConfirm}
            handleClose={handleCloseDialog}
            text={`${t("ChangeWorkingTime")}`}
            content1={
              selected && `${dayjs(selected.item.date).format("D MMMM BBBB")}`
            }
            content2={
              selectedTime &&
              `${
                selectedTime.isWorkingDay === 0
                  ? `${t("WeeklyHoliday")}`
                  : selectedTime.shiftGroupName
              }`
            }
            handleConfirm={handleConfirm}
          />
        )}
        {selected && openConfirmHoliday && (
          <DialogConfirmDayOffHoliday
            open={openConfirmHoliday}
            handleClose={handleCloseDialog}
            text={`${t("ChangeWorkingTime")}`}
            content1={
              selected && `${dayjs(selected.item.date).format(
                i18n.resolvedLanguage === "th" ? "D MMMM BBBB" : "D MMMM YYYY"
              )}`
            }
            content2={selectedTime && `${t("PublicHoliday")}`}
            handleConfirm={handleConfirmHoliday}
            setSelectedHoliday={setSelectedHoliday}
            holidayUsedList={holidayUsedList}
          />
        )}

        {selected && openDialogConfirmDelete && (
          <DialogConfirmDelete
            open={openDialogConfirmDelete}
            handleClose={() => setOpenDialogConfirmDelete(false)}
            handleDelete={handleSubmitConfirmDelete}
            text={`${t("Date")} ${dayjs(selected.workingDateText).format(
              "D MMMM YYYY"
            )}`}
          />
        )}
      </Container>
    </StyledRoot>
  );
};

export default TimeSchedule;
