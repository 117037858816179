import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Container,
  Pagination,
  Typography,
  styled,
  Card,
  Button,
  Grid,
} from "@mui/material";
import CardStyle from "../../../../shared/general/Card";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import { useDispatch } from "react-redux";
import { getTeamObjective } from "../../../../../../actions/okrs";
import Loading from "../../../../shared/loading";
import { Search } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

const StyledRoot = styled(Box)({
  "& .objectcard-wrap": {
    marginBottom: "16px",
    "&:last-child": {
      marginBottom: 0,
    },
  },
  "& .objective-card": {
    padding: "16px",
  },
  "& .MuiFilledInput-root input": {
    padding: "16px 12px 16px 12px",
  },
  "& .MuiFilledInput-root input": {
    padding: "16px 12px 16px 12px",
  },
});

const StyledCard = styled(Card)({
  minHeight: "70px",
  boxShadow: "none",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  background:
    "linear-gradient(to right, rgba(254, 196, 144, 0.7), rgba(235, 148, 192, 0.7))",
  "& .cardTitle": {
    fontSize: 22,
  },
  "& .MuiCardContent-root": {
    padding: 24,
  },
  "& .cardHeader": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
  "& .searchContainer": {
    marginLeft: "41px",
    marginBottom: "100px",
  },
  "@media (max-width: 600px)": {
    "& .searchContainer": {
      marginBottom: "10px",
    },
  },
});

const StyleHeadBox = styled(Box)({
  "& .box-header": {
    padding: "0px 24px",
    minHeight: "200px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    backgroundColor:
      "linear-gradient(to right, rgba(254, 196, 144, 0.7), rgba(235, 148, 192, 0.7))",
    "& .text-header": {
      fontSize: "48px",
      fontWeight: "bold",
      marginLeft: "40px",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      marginTop: "80px",
    },
    "& .img-header": {
      objectFit: "contain",
      // width: "33%",
      // height: "auto",
      // marginBottom: "10px",
      // justifyContent: "flex-end",
      // alignItems: "flex-end",
      // marginRight: "20px",
    },
  },

  "@media (max-width: 600px)": {
    "& .box-header": {
      flexDirection: "column",
      minHeight: "unset",
      justifyContent: "center",
      alignItems: "center",
      "& .text-header": {
        fontSize: "30px",
        marginLeft: "20px",
        marginTop: "20px",
      },
      // "& .img-header": {
      //   width: "50%",
      //   justifyContent: "flex-start",
      //   alignItems: "flex-start",
      // },
    },
  },
});

const CustomButton = styled(Button)({
  color: "#DB4178",
  border: "2px solid #DB4178",
  backgroundColor: "transparent",
  borderRadius: "7px",
  height: "50px",
  "&:hover": {
    color: "#DB4178",
    border: "2px solid #C53B6C",
    backgroundColor: "transparent",
    borderRadius: "7px",
    height: "50px",
  },
});

const ObjectiveCard = (props) => {
  const { data } = props;
  const { t, i18n } = useTranslation();

  return (
    <CardStyle>
      <Box padding="24px">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography fontSize="24px">
            {`${dayjs()
              .set("year", data.year)
              .format(i18n.resolvedLanguage === "th" ? "BBBB" : "YYYY")}, Q${
              data.quarter
            }`}
          </Typography>
          <CustomButton
            component={Link}
            to={`/okrs/update-progress/team/objective/${data.idTeamObjective}`}
            variant="outlined"
            startIcon={<Search />}
          >
            {`${t("Descriptions")}`}
          </CustomButton>
        </Box>
        <Typography fontSize="20px" paddingTop="16px">
          {data.objectiveName}
        </Typography>
        <Typography fontSize="14px" paddingTop="16px">
          <span style={{ color: "#919eab", paddingRight: "8px" }}>{`${t(
            "TheResultingOutcome"
          )}`}</span>
          {data.impactName}
        </Typography>
        {data.description && (
          <Typography fontSize="14px" paddingTop="16px" whiteSpace="pre-line">
            <span style={{ color: "#919eab", paddingRight: "8px" }}>{`${t(
              "Descriptions"
            )}`}</span>
            {data.description}
          </Typography>
        )}
        <Typography fontSize="14px" paddingTop="16px" textAlign="right">
          <span style={{ color: "#919eab", paddingRight: "8px" }}>{`${t(
            "TheMainResult"
          )}`}</span>
          {data.keyResults.length} {`${t("List")}`}
        </Typography>
      </Box>
    </CardStyle>
  );
};

const UpdateProgressTeamPage = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [TeamObjectiveFilter, setTeamObjectiveFilter] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [isError, setIsError] = useState(null);

  useEffect(() => {
    let isMounted = true;
    dispatch(getTeamObjective()).then((res) => {
      if (isMounted) {
        setTeamObjectiveFilter(res.data);
        setIsFetching(false);
      }
    });
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <StyledRoot className="page">
      <Box padding="24px 0">
        <Container maxWidth="lg">
          <StyledCard
            style={{
              marginBottom: "48px",
              marginTop: "20px",
            }}
          >
            <StyleHeadBox>
              <Box className="box-header">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className="text-header">{`${t(
                      "UpdateTeamGoalStatus"
                    )}`}</Typography>
                  </Grid>

                  <Grid item xs={12} className="searchContainer">
                    <TextFieldTheme
                      variant="filled"
                      style={{
                        maxWidth: "250px",
                        background: "#fff",
                        borderRadius: "10px",
                      }}
                      // onChange={(e) => {onSearchChange(e)}}
                      inputProps={{
                        placeholder: `${t("Search")}`,
                      }}
                    />
                  </Grid>
                </Grid>
                <img
                  className="img-header"
                  src={`${process.env.PUBLIC_URL}/assets/okrs/manager/update-team-goals.png`}
                  alt="icon"
                />
              </Box>
            </StyleHeadBox>
          </StyledCard>

          {isFetching ? (
            <Fragment>
              <Box display="flex" justifyContent="center" paddingTop="24px">
                {/* <CircularProgress /> */}
                <Loading />
              </Box>
              {/* <Typography>กำลังโหลด</Typography> */}
            </Fragment>
          ) : (
            <Fragment>
              {TeamObjectiveFilter.length > 0 ? (
                <Fragment>
                  <Box
                    marginBottom="16px"
                    display="flex"
                    justifyContent="right"
                  >
                    {TeamObjectiveFilter.length} {`${t("List")}`}
                  </Box>

                  {TeamObjectiveFilter.map((objective, index) => (
                    <Box key={index} className="objectcard-wrap">
                      <ObjectiveCard data={objective} />
                    </Box>
                  ))}

                  <Box padding="24px 0" display="flex" justifyContent="center">
                    <Pagination component="div" />
                  </Box>
                </Fragment>
              ) : (
                <Box display="flex" justifyContent="center" paddingTop="24px">
                  {/* <CircularProgress /> */}
                  <Typography>{`${t("NoItems")}`}</Typography>
                </Box>
              )}
            </Fragment>
          )}
        </Container>
      </Box>
    </StyledRoot>
  );
};

export default UpdateProgressTeamPage;
