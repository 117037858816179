import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import {
  MenuItem,
  Grid,
  styled,
  Typography,
  FormControl,
  Select,
} from "@mui/material";

import ButtonBlue from "../../../../shared/general/ButtonBlue";
import DrawerCustom from "../../../../shared/general/Drawer";
import TextFieldTheme from "../../../../shared/general/TextFieldTheme";
import DatePickerCustom from "../../../../shared/date/datePicker";

import { allPayTypes } from "../../../../../../actions/paytypes";

//Translator TH-EN
import { useTranslation } from "react-i18next";

import {
  getEmployeeAdditionDeduction,
  updateEmployeeAdditionDeduction,
} from "../../../../../../actions/employee";

const StyledRoot = styled("div")({
  width: 500,
  padding: 24,
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
});

const StyledFooter = styled("div")({
  padding: 16,
  display: "flex",
  justifyContent: "flex-end",
  "& .cancel": {
    marginRight: 8,
  },
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const DialogEdit = (props) => {
  const { open, mode, handleCloseDialog, dataEdit, dataAlready, isReadOnly } = props;
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { result: paytypesList } = useSelector((state) => state.paytypes);
  const { result: employeeProfile } = useSelector(
    (state) => state.employeeProfile
  );
  const [formData, setFormData] = useState({
    name: "",
    value: "",
    start: null,
    end: null,
  });
  const [listItem, setListItem] = useState(null);

  useEffect(() => {
    dispatch(allPayTypes({ idCompany: employeeProfile.idCompany }));
  }, []);

  useEffect(() => {
    if (paytypesList) {
      let splitMode = mode.split(" ");
      setFormData({ ...formData, mode: splitMode[0] });
      if (splitMode[0] === "addition") {
        setListItem(paytypesList.addition.filter((x) => x.isRecurring === 1));
      } else {
        setListItem(paytypesList.deduction.filter((x) => x.isRecurring === 1));
      }
    }
  }, [mode, paytypesList]);

  useEffect(() => {
    if (dataEdit) {
      let splitMode = mode.split(" ");
      setFormData({
        name: dataEdit.name,
        value: dataEdit.value,
        start: dataEdit.start || null,
        end: dataEdit.end || null,
        mode: splitMode[0],
        isActive: 1,
        idEmployeeAddition: dataEdit.idEmployeeAddition || null,
        idEmployeeDeduction: dataEdit.idEmployeeDeduction || null,
      });
    } else {
      let splitMode = mode.split(" ");
      setFormData({
        name: "",
        value: "",
        start: null,
        end: null,
        mode: splitMode[0],
      });
    }
  }, [dataEdit]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const TitleDialog = () => {
    let splitMode = mode.split(" ");
    let title = splitMode[1] === "new" ? `${t("AddItem")}` : `${t("EditItem")}`;
    if (splitMode[0] === "addition") {
      title += `${t("AdditionsPayment")}`;
    } else {
      title += `${t("RegularDeduction")}`;
    }
    return title;
  };

  const handleSaveChange = async () => {
    formData.idEmployees = employeeProfile.idEmployees;
    const result = await dispatch(updateEmployeeAdditionDeduction(formData));
    formData.start = dayjs(formData.start).format("YYYY-MM-DD");
    if (result && result.status === 200) {
      dispatch(getEmployeeAdditionDeduction(employeeProfile.idEmployees));
      // handleSetAlertType("success");
      // handleOpenAlert();
      handleCloseDialog();
    } else {
      // handleSetAlertType("error");
      // handleOpenAlert();
      handleCloseDialog();
    }
  };

  return (
    <DrawerCustom
      title={TitleDialog()}
      anchor="right"
      open={open}
      onClose={handleCloseDialog}
    >
      <StyledRoot>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={4}
            style={{ display: "flex", alignItems: "center" }}
          >
            <StyledContentLabel>{`${t("ResignationName")}`}</StyledContentLabel>
          </Grid>
          <Grid item xs={12} sm={8}>
            {formData.name ? (
              <TextFieldTheme
                name="name"
                value={formData.name}
                fullWidth
                onChange={handleChange}
                InputProps={{
                  readOnly: true,
                }}
              />
            ) : (
              <StyledFormControl fullWidth>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="idSelect"
                  onChange={handleChange}
                >
                  {listItem && listItem.length > 0
                    ? listItem[0].idAddition
                      ? listItem
                          .filter(
                            (item) =>
                              dataAlready &&
                              !dataAlready.additions.find(
                                (d) => d.idAddition === item.idAddition
                              )
                          )
                          .map((item) => (
                            <MenuItem value={item.idAddition}>
                              {item.name}
                            </MenuItem>
                          ))
                      : listItem
                          .filter(
                            (item) =>
                              dataAlready &&
                              !dataAlready.deductions.find(
                                (d) => d.idDeduction === item.idDeduction
                              )
                          )
                          .map((item) => (
                            <MenuItem value={item.idDeduction}>
                              {item.name}
                            </MenuItem>
                          ))
                    : null}
                </Select>
              </StyledFormControl>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            style={{ display: "flex", alignItems: "center" }}
          >
            <StyledContentLabel>{`${t("Quantity")}`}</StyledContentLabel>
          </Grid>
          <Grid item xs={12} sm={8}>
            {formData && (
              <TextFieldTheme
                name="value"
                value={formData.value}
                fullWidth
                onChange={handleChange}
              />
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            style={{ display: "flex", alignItems: "center" }}
          >
            <StyledContentLabel>{`${t("StartMonth")}`}</StyledContentLabel>
          </Grid>
          <Grid item xs={12} sm={8}>
            <DatePickerCustom
              views={["month", "year"]}
              inputFormat="MMMM YYYY"
              value={formData.start}
              onChange={(date) => {
                setFormData({
                  ...formData,
                  ["start"]: date,
                });
              }}
              name="start"
              variant="outlined"
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            style={{ display: "flex", alignItems: "center" }}
          >
            <StyledContentLabel>{`${t("EndMonth")}`}</StyledContentLabel>
          </Grid>
          <Grid item xs={12} sm={8}>
            <DatePickerCustom
              views={["month", "year"]}
              inputFormat="MMMM YYYY"
              value={formData.end}
              onChange={(date) => {
                setFormData({
                  ...formData,
                  ["end"]: date,
                });
              }}
              name="end"
              variant="outlined"
            />
          </Grid>
        </Grid>
        <StyledFooter>
          <ButtonBlue
            variant="text"
            className="cancel"
            onClick={handleCloseDialog}
          >
            {`${t("Cancel")}`}
          </ButtonBlue>
          <ButtonBlue variant="contained" onClick={handleSaveChange} disabled={isReadOnly}>
            {`${t("SaveData")}`}
          </ButtonBlue>
        </StyledFooter>
      </StyledRoot>
    </DrawerCustom>
  );
};

export default DialogEdit;
