import React, { Fragment } from "react";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import StyledCard from "../../../shared/general/Card";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import IconButton from "@mui/material/IconButton";

import AvatarShift from "../../../shared/general/AvatarShift";
import ButtonBlue from "../../../shared/general/ButtonBlue";

import EngineeringIcon from "@mui/icons-material/Engineering";
import SearchIcon from "@mui/icons-material/Search";
import LoopIcon from "@mui/icons-material/Loop";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import {
  getUserFirstName,
  getUserFullName,
  getUserLastName,
  getUserPosition,
} from "../../../../../utils/userData";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const { t, i18n } = useTranslation();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <React.Fragment>{children}</React.Fragment>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const StyledTabs = styled(Tabs)(({}) => ({
  minWidth: 100,
  maxWidth: 100,
  border: "1px solid",
  borderColor: "#0000001f",
  backgroundColor: "#f3f6f8",
  borderTopLeftRadius: 8,
  borderBottomLeftRadius: 8,
  "& .Mui-selected": {
    color: "#007afe !important",
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "#007afe !important",
  },
}));

const StyledTabPanel = styled(TabPanel)(({}) => ({
  border: "1px solid",
  borderLeft: "none",
  borderColor: "#0000001f",
  borderTopRightRadius: 8,
  borderBottomRightRadius: 8,
  padding: 8,
  minWidth: "calc(100% - 116px)",
  maxWidth: "calc(100% - 116px)",
  "& .wrap-tab": {
    display: "flex",
    flexWrap: "wrap",
    overflow: "auto",
    "& .wrap-null-employee": {
      width: "100%",
      height: "350px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
  },
}));

const StyledAvatar = styled(Avatar)({
  width: 50,
  height: 50,
});

const StyledBox = styled(Box)({
  width: 120,
  padding: 8,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

const StyledFormControlSelect = styled(FormControl)(({}) => ({
  width: 200,
  marginRight: 8,
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
  },
  "& .MuiInputLabel-root": {
    top: -7,
  },
  "& .MuiInputLabel-root.Mui-focused": {
    top: 0,
  },
}));

const StyledFormControlSearch = styled(FormControl)(({}) => ({
  width: 200,
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
  },
}));

const StyledSection = styled("div")(({ shift }) => ({
  flexGrow: 1,
  display: "flex",
  padding: 16,
  paddingTop: 8,
  height: 350,
  width: "calc(100% - 32px)",
}));

const StyledWrapHead = styled("div")(({ shift }) => ({
  padding: 16,
  paddingBottom: 8,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-end",
  "& .MuiInputLabel-root": {
    top: -7,
  },
  "& .MuiInputLabel-root.Mui-focused": {
    top: 0,
  },
  "& .display-shift-name": {},
  "& .display-shift-time": {
    display: "flex",
    flexDirection: "column",
    "& .MuiTypography-root": {
      fontSize: 14,
    },
  },
}));

export default function EmployeeShiftList(props) {
  const { handleOpenSwitchShift, idShiftGroup } = props;
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: shiftGroup } = useSelector((state) => state.shiftGroup);
  const { result: employeesShift } = useSelector(
    (state) => state.employeeShift
  );
  const [step, setStep] = React.useState(0);
  const [search, setSearch] = React.useState("");
  const [department, setDepartment] = React.useState("");
  const [dataShift, setDataShift] = React.useState(null);
  const [ListEmployeeShift, setListEmployeeShift] = React.useState([]);
  const { t, i18n } = useTranslation();

  const handleChangeStep = (event, newValue) => {
    setStep(newValue);
  };

  const handleChangeSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleOnClickSearch = (prop) => (event) => {
    //setSearch(event.target.value );
  };

  const handleChangeDepartment = (event, newValue) => {
    setDepartment(newValue);
  };

  const displayAllTime = () => {
    return shiftGroup.shiftType
      .filter((x) => {
        return x.isWorkingDay === 1;
      })
      .map((x, index) => (
        <Typography
          key={index}
          color="text.secondary"
          style={{ fontWeight: 500 }}
          variant="caption"
          component="p"
        >
          {`${x.shiftTypeName} ${dayjs(x.timeIn, "HH:mm:ss").format(
            "HH:mm"
          )} - ${dayjs(x.timeOut, "HH:mm:ss").format("HH:mm")}`}
        </Typography>
      ));
  };

  const renderEmployee = (step, value) => {
    if (step === 0) {
      return employeesShift;
    } else {
      return employeesShift.filter(
        (x) => x.idShift === shiftGroup.shift[step - 1].idShift
      );
    }
  };

  const renderDepartment = () => {
    const valuesDepartment = [
      ...new Map(
        employeesShift.map((item) => [
          item["idDepartment"],
          item["departmentName"],
        ])
      ),
    ];
    console.log(valuesDepartment);
    return valuesDepartment;
  };

  return (
    <StyledCard>
      <div>
        <StyledWrapHead>
          <div style={{ display: "flex" }}>
            <EngineeringIcon style={{ fontSize: 36, marginRight: 18 }} />
            <div>
              {shiftGroup && (
                <React.Fragment>
                  <Typography variant="h5">
                    {`${
                      shiftGroup.idWorkingType === 1 ? `${t("Shift")}` : ""
                    } ${
                      shiftGroup.shiftGroupName
                        ? shiftGroup.shiftGroupName
                        : "-"
                    }`}
                  </Typography>
                  <div className="display-shift-time">{displayAllTime()}</div>
                </React.Fragment>
              )}
            </div>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            {!userProfile.readOnly && (
              <div style={{ marginRight: 12 }}>
                <ButtonBlue
                  style={{ width: "100%" }}
                  variant={"contained"}
                  startIcon={<LoopIcon />}
                  onClick={handleOpenSwitchShift}
                >
                  {t("SwitchShift")}
                </ButtonBlue>
              </div>
            )}
            <StyledFormControlSearch variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                {t("SearchEmp")}
              </InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                value={search}
                onChange={handleChangeSearch}
                size="small"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleOnClickSearch}
                      edge="end"
                    >
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                }
                label="ค้นหาพนักงาน"
              />
            </StyledFormControlSearch>
          </div>
        </StyledWrapHead>
        <StyledSection>
          <StyledTabs
            orientation="vertical"
            variant="scrollable"
            value={step}
            onChange={handleChangeStep}
            aria-label="Vertical tabs example"
          >
            <Tab
              label={
                <div>
                  <Typography
                    style={{ color: "inherit", fontSize: "0.875rem" }}
                  >
                    {t("Total")}
                  </Typography>
                  <Typography
                    style={{ color: "inherit", fontSize: "0.875rem" }}
                  >
                    {`(${employeesShift ? employeesShift.length : 0} ${t(
                      "Person"
                    )})`}
                  </Typography>
                </div>
              }
              {...a11yProps(0)}
            />
            {employeesShift &&
              shiftGroup &&
              shiftGroup.idWorkingType === 1 &&
              shiftGroup.shift.map((shift, index) => {
                return (
                  <Tab
                    key={`${index}${shift.nameShift}`}
                    label={
                      <div>
                        <Typography
                          style={{ color: "inherit", fontSize: "0.875rem" }}
                        >
                          {`Shift ${shift.shiftName}`}
                        </Typography>
                        <Typography
                          style={{ color: "inherit", fontSize: "0.875rem" }}
                        >
                          {`(${
                            employeesShift.filter(
                              (x) => x.idShift === shift.idShift
                            ).length
                          } ${t("Person")})`}
                        </Typography>
                      </div>
                    }
                    {...a11yProps(index + 1)}
                  />
                );
              })}
          </StyledTabs>
          <StyledTabPanel
            value={step}
            index={0}
            style={{ overflow: "auto" }}
            key={0}
          >
            <div className="wrap-tab">
              {employeesShift && employeesShift.length > 0 ? (
                employeesShift
                .filter(x => (
                  !search || String(`${x.firstname_TH} ${x.lastname_TH}`).includes(search)
                ))
                .map((emp, indexEmployee) => {
                  return (
                    <StyledBox key={`${indexEmployee}`}>
                      <Badge
                        overlap="circular"
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "right",
                        }}
                        badgeContent={
                          <Fragment>
                            {emp.idWorkingType === 1 && (
                              <AvatarShift
                                fontSize={12}
                                title={emp.shiftName}
                                size={14}
                              />
                            )}
                          </Fragment>
                        }
                      >
                        <StyledAvatar
                        src={`${emp.imageProfile}`}
                          alt={getUserFullName(emp)}
                        />
                      </Badge>

                      <div
                        style={{
                          width: "100%",
                        }}
                      >
                        <Typography align="center" variant="body1">
                          {getUserFullName(emp)}
                        </Typography>
                        <Typography
                          align="center"
                          variant="body2"
                          style={{
                            color: "#999999",
                            fontStyle: "oblique",
                          }}
                        >
                          {getUserPosition(emp)}
                        </Typography>
                      </div>
                    </StyledBox>
                  );
                })
              ) : (
                <div className="wrap-null-employee">
                  <i
                    className="fa-solid fa-users"
                    style={{ fontSize: "44px", color: "#919EAB" }}
                  />
                  <Typography variant="h5" style={{ color: "#919EAB" }}>
                    {t("NoEmpListInShift")}
                  </Typography>
                </div>
              )}
            </div>
          </StyledTabPanel>
          {employeesShift &&
            shiftGroup &&
            shiftGroup.idWorkingType === 1 &&
            shiftGroup.shift.map((value, index) => {
              return (
                <StyledTabPanel
                  value={step}
                  index={index + 1}
                  style={{ overflow: "auto" }}
                  key={index + 1}
                >
                  <div className="wrap-tab">
                    {employeesShift.length > 0 ? (
                      renderEmployee(step, value)
                      .filter(x => (
                        !search || String(getUserFullName(x)).includes(search)
                      ))
                      .map((emp, indexEmployee) => {
                        return (
                          <StyledBox key={`${indexEmployee + step}`}>
                            <Badge
                              overlap="circular"
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                              }}
                              badgeContent={
                                <AvatarShift
                                  fontSize={12}
                                  title={emp.shiftName}
                                  size={14}
                                />
                              }
                            >
                              <StyledAvatar
                                alt={"username"}
                                // src={`${process.env.REACT_APP_API_URL}image/vendor/${emp.idVendor}/${emp.personalId}.jpg`}
                              />
                            </Badge>

                            <div
                              style={{
                                width: "100%",
                              }}
                            >
                              <Typography align="center" variant="body1">
                                {getUserFirstName(emp)}
                              </Typography>
                              <Typography align="center" variant="body1">
                                {getUserLastName(emp)}
                              </Typography>
                              <Typography
                                align="center"
                                variant="body2"
                                style={{
                                  color: "#999999",
                                  fontStyle: "oblique",
                                }}
                              >
                                {getUserPosition(emp)}
                              </Typography>
                            </div>
                          </StyledBox>
                        );
                      })
                    ) : (
                      <div className="wrap-null-employee">
                        <i
                          className="fa-solid fa-users"
                          style={{ fontSize: "44px", color: "#919EAB" }}
                        />
                        <Typography variant="h5" style={{ color: "#919EAB" }}>
                          {t("NoEmpListInShift")}
                        </Typography>
                      </div>
                    )}
                  </div>
                </StyledTabPanel>
              );
            })}
        </StyledSection>
      </div>
    </StyledCard>
  );
}
