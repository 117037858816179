import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Grid, Typography, Divider, Stack, TextField } from "@mui/material";
import SaveRoundedIcon from "@mui/icons-material/SaveRounded";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import DatePickerCustom from "../../../shared/date/datePicker";

import CardStyle from "../../../shared/general/Card";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import AlertResponse from "../../../shared/general/AlertResponse";

import DialogEstimatePerformance from "./DialogEstimatePerformance";
import { getCompanyProfile } from "../../../../../actions/company";
import { getEstimatePerformance, updateEstimatePerformance } from "../../../../../actions/estimatePerformance";
import EstimateLevel from "./estimateLevel";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "30px",
    "& .MuiTypography-h6": {
      fontSize: "1.5rem",
    },
  },
  headingPage: {
    marginBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(1),
    borderRadius: "20px",
    boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  },
}));

const StyledHeadingPage = styled("div")({
  marginBottom: 16,
  "& .wrap-search": {
    marginTop: 16,
    "& .fill": {
      display: "flex",
    },
  },
});

const BoxStyled = styled("div")({
  "& .header": {
    display: "flex",
    alignItems: "center",
    marginBottom: 16,
    "& .text": {
      fontSize: 16,
    },
    "& .icon": {
      color: "#637381",
      fontSize: 16,
      marginRight: 8,
    },
  },
  "& .btn-save": {
    justifyContent: "flex-end",
  },
  "& .flex-box": {
    justifyContent: "space-between",
    marginTop: 16,
    display: "flex",
  },
});

const StyledContentLabel = styled(Typography)({
  fontWeight: 600,
  fontSize: 16,
  marginRight: 8,
  marginBottom: 16,
});

function EstimatePerformance() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { result: companyProfile } = useSelector((state) => state.companyProfile);
  const { result: estimatePerformance } = useSelector((state) => state.getEstimatePerformance);

  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [idEducation, setIdEducation] = useState(null);
  const [typeDialog, setTypeDialog] = useState("Add");
  const [state, setstate] = useState({
    timeEstimate: null,
  });

  useEffect(() => {
    dispatch(getCompanyProfile());
    dispatch(getEstimatePerformance());
  }, []);

  useEffect(() => {
    if (companyProfile) {
      setstate(companyProfile);
    }
  }, [companyProfile]);

  const defaultEstimatePerformance = {
    startDate: dayjs(),
    endDate: dayjs().add(1, "day"),
    level: [{ name: "", active: true }],
  };

  const validationSchema = Yup.object().shape({
    startDate: Yup.date(),
    endDate: Yup.date().when("startDate", (startDate) => {
      return Yup.date().min(
        dayjs(startDate).add(1, "day"),
        "End date must be later than start date"
      );
    }),
    level: Yup.array().of(
      Yup.object().shape({
        active: Yup.boolean(),
        name: Yup.string().when("active", {
          is: true,
          then: (schema) =>
            schema.min(1, "ความยาวอย่างน้อย 1 ตัวอักษร").max(40),
        }),
      })
    ),
  });

  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: defaultEstimatePerformance,
    resolver: yupResolver(validationSchema),
  });

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleClickSave = async (values) => {
    const result = await dispatch(updateEstimatePerformance(values));
    if (result) {
      handleOpenAlert();
      if (result.status === 201) {
        dispatch(getEstimatePerformance());
        handleChangeAlertType("success");
      } else {
        handleChangeAlertType("error");
      }
    } else {
      handleChangeAlertType("error");
    }
  };

  const handleSubmitEstimatePerformance = async (result) => {
    handleClose();
    if (result) {
      handleOpenAlert();
      if (result.status === 200) {
        dispatch(getEstimatePerformance());
        handleChangeAlertType("success");
      } else {
        handleChangeAlertType("error");
      }
    } else {
      handleChangeAlertType("error");
    }
  };

  const handleOpenDrawer = (type, idEdu) => {
    if (type === "Add") {
      setTypeDialog("Add");
    } else {
      setTypeDialog("Edit");
    }
    if (idEdu) {
      setIdEducation(idEdu);
    }
    setOpenDrawer(true);
  };

  const handleClose = () => {
    setOpenDrawer(false);
    setIdEducation(null);
  };

  useEffect(() => {
    if (estimatePerformance) {
      reset({
        startDate: estimatePerformance.startDate,
        endDate: estimatePerformance.endDate,
        level: estimatePerformance.level,
      });
    }
  }, [estimatePerformance]);

  return (
    <div className={classes.root}>
      <StyledHeadingPage>
        <Typography variant="h6">แบบฟอร์มประเมินผลงาน</Typography>
      </StyledHeadingPage>
      {estimatePerformance && (
        <form onSubmit={handleSubmit(handleClickSave)}>
          <CardStyle style={{ padding: 16 }}>
            <BoxStyled>
              <div className="header">
                <StyledContentLabel variant="body1">
                  {`${t("เลือกช่วงเวลาประเมินผลงาน")}`}
                  &nbsp;&nbsp;
                  <i class="fa-regular fa-calendar-days"></i>
                </StyledContentLabel>
              </div>
              <Stack direction={"row"} spacing={2}>
                <Controller
                  name="startDate"
                  control={control}
                  render={({ field }) => (
                    <DatePickerCustom
                      {...field}
                      label="วันเริ่มการประเมิน"
                      minDate={dayjs()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={errors.startDate}
                          helperText={
                            errors.startDate && errors.startDate.message
                          }
                        />
                      )}
                    />
                  )}
                />
                <Controller
                  name="endDate"
                  control={control}
                  render={({ field }) => (
                    <DatePickerCustom
                      {...field}
                      label="วันสิ้นสุดการประเมิน"
                      minDate={dayjs(watch("startDate")).add(1, "day")}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={errors.endDate}
                          helperText={
                            errors.endDate && errors.endDate.message
                          }
                        />
                      )}
                    />
                  )}
                />
              </Stack>
            </BoxStyled>
          </CardStyle>
          <StyledHeadingPage sx={{ marginTop: "20px" }}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Typography variant="h6">คำถามประเมินผลงานประจำปี</Typography>
              </Grid>
            </Grid>
          </StyledHeadingPage>
          <CardStyle style={{ padding: "16px", marginTop: "20px" }}>
            <BoxStyled>
              <div className="flex-box">
                <StyledContentLabel variant="body1">
                  {`${t("คำถามที่ใช้ทั้งหมด")} ${
                    estimatePerformance.question.filter(
                      (item) => item.isActive
                    ).length
                  }/10`}
                  &nbsp;&nbsp;
                  <i
                    className="fa-sharp fa-solid fa-check fa-beat fa-xl"
                    style={{ color: "#fde64b" }}
                  ></i>
                </StyledContentLabel>
                <div className="btn-save">
                  <ButtonBlue
                    variant="contained"
                    onClick={() => handleOpenDrawer("Add")}
                  >
                    {t("Question")}
                    &nbsp;
                    <AddCircleRoundedIcon />
                    <RemoveCircleIcon />
                  </ButtonBlue>
                </div>
              </div>
              <Grid container rowGap={2}>
                {estimatePerformance.question
                    .filter((item) => item.isActive)
                    .map((estimateQuestion, index) => (
                      <Grid
                        key={estimateQuestion.idQuestionEstimatePerformance}
                        item
                        xs={12}
                      >
                        <div style={{ marginBottom: 8 }}>
                          <i className="fa-regular fa-square-question fa-bounce fa-xl"></i>
                          &nbsp;&nbsp;{`คำถามที่ ${index + 1}`}
                        </div>
                        <Typography>{estimateQuestion.question}</Typography>
                        <Divider
                          sx={{
                            marginTop: "16px",
                            borderColor: "#9e9e9e66",
                          }}
                        />
                      </Grid>
                    ))}
              </Grid>
            </BoxStyled>
          </CardStyle>
          <CardStyle style={{ padding: "16px", marginTop: "20px" }}>
            <BoxStyled>
              <StyledContentLabel variant="body1">
                {`${t("คำถามที่ถูกยกเลิกการใช้")}`}
                &nbsp;&nbsp;
                <i
                  className="fa-sharp fa-solid fa-xmark fa-beat fa-xl"
                  style={{ color: "#ff0000" }}
                ></i>
              </StyledContentLabel>
              <Grid container rowGap={2}>
                {estimatePerformance.question
                    .filter((item) => !item.isActive)
                    .map((estimateQuestion, index) => (
                      <Grid
                        key={estimateQuestion.idQuestionEstimatePerformance}
                        item
                        xs={12}
                      >
                        <div style={{ marginBottom: 8 }}>
                          <i className="fa-regular fa-square-question fa-bounce fa-xl"></i>
                          &nbsp;&nbsp;{`คำถามที่ ${index + 1}`}
                        </div>
                        <Typography>{estimateQuestion.question}</Typography>
                        <Divider
                          sx={{
                            marginTop: "16px",
                            borderColor: "#9e9e9e66",
                          }}
                        />
                      </Grid>
                    ))}
              </Grid>
            </BoxStyled>
          </CardStyle>
          <CardStyle style={{ padding: "16px", marginTop: "20px" }}>
            <BoxStyled>
              <StyledContentLabel variant="body1">
                {`${t("ระดับการประเมินผลงาน")}`}
                &nbsp;&nbsp;
                <i class="fa-solid fa-layer-group fa-beat"></i>
              </StyledContentLabel>
              <EstimateLevel control={control} />
            </BoxStyled>
          </CardStyle>
          <Stack alignItems={"flex-end"} marginTop={2}>
            <ButtonBlue
              type={"submit"}
              variant="contained"
              startIcon={<SaveRoundedIcon />}
            >
              {t("Save")}
            </ButtonBlue>
          </Stack>
        </form>
      )}
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
      {openDrawer && (
        <DialogEstimatePerformance
          open={openDrawer}
          handleClose={handleClose}
          idEdu={idEducation}
          type={typeDialog}
          handleSubmitEstimatePerformance={handleSubmitEstimatePerformance}
        />
      )}
    </div>
  );
}

export default EstimatePerformance;
