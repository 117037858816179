import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT, SET_MESSAGE, MOBILE_TOKEN_SUCCESS, MOBILE_TOKEN_FETCHING, MOBILE_TOKEN_FAILED } from "./types";

import AuthService from "../services/auth.service";

export const login = (username, password) => (dispatch) => {
  return AuthService.login(username, password).then(
    (data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });

      return data;
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: LOGIN_FAIL,
      });

      return Promise.reject();
    }
  );
};

export const changePassword = (formData) => async () => {
  try {
    const res = await AuthService.changePassword(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
  }
};

export const resetPassword = (formData) => async () => {
  try {
    const res = await AuthService.resetPassword(formData);
    if (res) {
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
  }
};

export const logout = () => (dispatch) => {
  AuthService.logout();

  dispatch({
    type: LOGOUT,
  });
};

export const getMobileToken = (idEmployee,idCompany) => async (dispatch) => {
  try {
    dispatch({
      type: MOBILE_TOKEN_FETCHING
    });
    const res =  await AuthService.getMobileToken(idCompany,idEmployee);
    if(res){
      dispatch({
        type: MOBILE_TOKEN_SUCCESS,
        payload: res.data
      });
    }
  } catch (error) {
    dispatch({
      type: MOBILE_TOKEN_FAILED
    });
  }
};

export const resetToken = (id) => async () => {
  try {
    const res = await AuthService.resetToken(id);
    if (res) {
      return res;
    }
  } catch (error) {
    if (error.response) {
      return error.response.data;
    }
  }
};