import { httpClient } from "./httpClient";

const getAllDocuments = () => {
  return httpClient.get("documents");
};

const getallCompanyDocuments = (idCompany) => {
  return httpClient.get(`allDocumentsCompany/${idCompany}`);
};

const getallSmartDocuments = () => {
  return httpClient.get("allSmartDocuments");
};

const getDocument = (id) => {
  return httpClient.get("documents/" + id);
};

const getDowloadCompanyDocument = (idCompany) => {
  return httpClient.get("document/getDowloadCompanyDocument/" + idCompany);
};

const getDowloadEmployeeDocument = (idEmployees, idCompany) => {
  return httpClient.get(`employees/${idEmployees}/${idCompany}/document`);
}

const getisUploadEmployeeDocument = (idEmployees, idCompany) => {
  return httpClient.get(`employees/${idEmployees}/${idCompany}/document`);
}

const getDowloadSmartDocument = (idEmployees, idCompany) => {
  return httpClient.get(`document/${idEmployees}/${idCompany}/getDowloadSmartDocument`);
};

const getDocumentGroupName = () => {
  return httpClient.get("document/GroupName");
};

const getDocumentTypeName = (idDocumentGroup, id, idDocumentType, documentTypeName, idCompany) => {
  return httpClient.get("document/TypeName/" + idDocumentGroup + "/" + id + "/" + idDocumentType + "/" + documentTypeName + "/" + idCompany);
};

const getAllDocumentTypeName = () => {
  return httpClient.get("document/AllTypeName");
};

const getAllCompanyDocumentType = (idCompany) => {
  return httpClient.get(`document/AllCompanyDocumentType/${idCompany}`);
};

const getAllCompanyDocumentGroupName = (idCompany) => {
  return httpClient.get(`document/AllDocumentGroupName/${idCompany}`);
};

const getUnionDocumentGroupAndTypeName = (idCompany) => {
  return httpClient.get(`document/AllUnionDocumentGroupType/${idCompany}`);
};

const addDocument = (formData) => {
  return httpClient.post(`addDocument`, formData);
};

const editDocument = (formData) => {
  return httpClient.put(`editDocument`, formData);
};

const editCompanyDocument = (formData) => {
  return httpClient.put(`editCompanyDocument`, formData);
};

const addDocumentType = (formData) => {
  return httpClient.post(`addDocumentType`, formData);
};

const addCompanyDocumentType = (formData) => {
  return httpClient.post(`addCompanyDocumentType`, formData);
};

const addAllDocumentTypeName = (formData) => {
  return httpClient.post(`addAllDocumentTypeName`, formData);
};

const addAllCompanyDocumentType = (formData) => {
  return httpClient.post(`addAllCompanyDocumentType`, formData);
};

const addCompanyDocumentGroup = (formData) => {
  return httpClient.post(`addCompanyDocumentGroup`, formData);
};

const addCompanyDocument = (formData) => {
  return httpClient.post(`addCompanyDocument`, formData);
};

const createPdfDocument = (formData) => {
  return httpClient.post(`createPdfDocument`, formData);
};

const editDocumentType = (formData) => {
  return httpClient.put(`editDocumentType`, formData);
};

const editCompanyDocumentTypeGroup = (formData) => {
  return httpClient.put(`editCompanyDocumentTypeGroup`, formData);
};


const deleteCompanyDocumentInfo = (formData) => {
  console.log('formData b', formData);

  const encodedDocumentGroupName = encodeURIComponent(formData.documentGroupName);
  const encodedDocumentTypeName = encodeURIComponent(formData.documentTypeName);
  // const encodedFilename = encodeURIComponent(formData.filename);
  const encodedidCompanyDocumentType = encodeURIComponent(formData.idCompanyDocumentType);
  const encodedidCompany = encodeURIComponent(formData.idCompany);


  const url = `deleteCompanyDocumentInfo/${encodedDocumentGroupName}/${encodedDocumentTypeName}/${encodedidCompany}/${encodedidCompanyDocumentType}`;
  return httpClient.delete(url);
};

const deleteEmployeeDocumentInfo = (formData) => {
  console.log('formData b', formData);

  const encodedidDocumentGroup = encodeURIComponent(formData.idDocumentGroup);
  const encodedidDocumentType = encodeURIComponent(formData.idDocumentType);
  const encodedDocumentTypeName = encodeURIComponent(formData.documentTypeName);
  const encodedidCompany = encodeURIComponent(formData.idCompany);

  const url = `deleteEmployeeDocumentInfo/${encodedidDocumentGroup}/${encodedidDocumentType}/${encodedDocumentTypeName}/${encodedidCompany}`;
  return httpClient.delete(url);
};


const deleteCompanyDocumentGroup = (formData) => {

  const encodedDocumentGroupName = encodeURIComponent(formData.documentGroupName);

  const url = `deleteCompanyDocumentGroup/${encodedDocumentGroupName}`;
  console.log('url: ', url);

  return httpClient.delete(url);
};

const deleteCompanyDocument = (formData) => {

  const encodedDocumentGroupName = encodeURIComponent(formData.documentGroupName);
  const encodedDocumentTypeName = encodeURIComponent(formData.documentTypeName);
  const encodedFilename = encodeURIComponent(formData.filename);
  const encodedidCompanyDocumentType = encodeURIComponent(formData.idCompanyDocumentType);
  const encodedidCompany = encodeURIComponent(formData.idCompany);


  const url = `deleteCompanyDocument/${encodedDocumentGroupName}/${encodedDocumentTypeName}/${encodedidCompany}/${encodedFilename}/${encodedidCompanyDocumentType}`;
  return httpClient.delete(url);
};

// const deleteCompanyDocument = (documentGroupName, documentTypeName, idCompany, filename, idCompanyDocumentType) => {

//   const encodedDocumentGroupName = encodeURIComponent(documentGroupName);
//   const encodedDocumentTypeName = encodeURIComponent(documentTypeName);
//   const encodedFilename = encodeURIComponent(filename);

//   const url = `deleteCompanyDocument/${encodedDocumentGroupName}/${encodedDocumentTypeName}/${idCompany}/${encodedFilename}/${idCompanyDocumentType}`;
//   return httpClient.delete(url);
// };



export default {
  getAllDocuments,
  getallCompanyDocuments,
  getDocument,
  getDowloadCompanyDocument,
  getisUploadEmployeeDocument,
  getDowloadEmployeeDocument,
  getDowloadSmartDocument,
  getallSmartDocuments,
  getDocumentGroupName,
  getDocumentTypeName,
  getAllDocumentTypeName,
  getUnionDocumentGroupAndTypeName,
  getAllCompanyDocumentType,
  addAllCompanyDocumentType,
  getAllCompanyDocumentGroupName,
  addDocument,
  addDocumentType,
  addCompanyDocumentType,
  addCompanyDocument,
  addAllDocumentTypeName,
  addCompanyDocumentGroup,
  createPdfDocument,
  editDocument,
  editCompanyDocument,
  editCompanyDocumentTypeGroup,
  editDocumentType,
  deleteEmployeeDocumentInfo,
  deleteCompanyDocumentGroup,
  deleteCompanyDocument,
  deleteCompanyDocumentInfo,
};
