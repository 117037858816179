import React, { useState, useEffect, useRef, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import XLSX from "xlsx";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import {
  Container,
  Grid,
  Typography,
  TextField,
  Box,
  Popper,
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Card,
  CardContent,
  Paper,
  Breadcrumbs,
} from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import ButtonBlue from "../../shared/general/ButtonBlue";
import Backdrop from "../../shared/general/Backdrop";
import DialogSetting from "./component/dialogSetting";
import AlertResponse from "../../shared/general/AlertResponse";
import {
  getPayrollCompany,
  getPayrollemployee,
} from "../../../../actions/report";
import { getAffiliate } from "../../../../actions/company";
import { Link } from "react-router-dom";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import DatePickerCustom from "../../shared/date/datePicker";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  paddingBottom: 48,
});

const StyledWrapFilter = styled("div")({
  width: "100%",
  display: "flex",
  alignItems: "center",
  marginBottom: 36,
  ["@media (min-width: 0px)"]: {
    flexDirection: "column",
  },
  ["@media (min-width: 900px)"]: {
    flexDirection: "row",
  },
  "& .search-name, .search-date": {
    width: "100%",
  },
  "& .wrap-search-action": {
    marginTop: 50,
    display: "flex",
    justifyContent: "flex-start",
    "& .btn-export": {
      marginLeft: 8,
    },
  },
});

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const colorCard = [
  "#D1CCED",
  "#CBEBF7",
  " #DCF7DE",
  "#FFFEDB",
  "#FFDBD1",
  "#F5CBDC",
  "#FAD2FC",
  "#CCCCFC",
  "#E0ECFF",
  "#FFFEED",
  "#FFEBC9",
  "#FFC4CD",
];

const Payroll = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const options = [
    `${t("Download")} ${t("CompanyInformation")}`,
    `${t("Download")} ${t("PersonalInformation")}`,
    `${t("Setting")}`,
  ];
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: affiliateList } = useSelector((state) => state.affiliate);
  const { result: payRun } = useSelector((state) => state.payRun);
  const { result: payslip } = useSelector((state) => state.payslip);
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [inputSearch, setInputSearch] = useState(new Date());
  const [openDrawer, setOpenDrawer] = useState(false);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [value, setValue] = useState(`${t("CompanyInformation")}`);
  const [data, setData] = useState({
    autoEmail: null,
    ReportName: "payroll",
    idEmployees: null,
  });

  const handleClick = () => {
    if (selectedIndex == 0 || selectedIndex == 1) {
      onExportExcel();
    } else {
      setOpenDrawer(true);
    }
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
    if (index == 0) {
      setValue(`${t("CompanyInformation")}`);
    } else if (index == 1) {
      setValue(`${t("PersonalInformation")}`);
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  useEffect(() => {
    dispatch(getAffiliate());
  }, []);

  useEffect(() => {
    dispatch(
      getPayrollemployee(selectedCompany, dayjs(inputSearch).startOf("month"))
    );
    dispatch(
      getPayrollCompany(selectedCompany, dayjs(inputSearch).startOf("month"))
    );
  }, [inputSearch, selectedCompany]);

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const Render = (key, name) => {
    let value = "item.value." + key;

    return (
      <>
        <div style={{ marginTop: 16, alignContent: "center" }}>
          <Typography variant="h6"> {name} </Typography>
        </div>
        <TableContainer component={Paper} variant="standard">
          <Table sx={{ borderBottom: "none" }}>
            <TableRow>
              {payRun &&
                payRun.length > 0 &&
                payRun.map((item, index) => {
                  if (item.value && eval(value))
                    return (
                      <TableCell>
                        <Card
                          sx={{
                            minWidth: 300,
                            backgroundColor: colorCard[index],
                          }}
                          variant="outlined"
                        >
                          <CardContent>
                            <Typography variant="h5" component="div">
                              {dayjs().set("month", item.id).format("MMMM")}{" "}
                              {dayjs(inputSearch).format("YYYY")}
                            </Typography>
                            <Typography color="text.secondary" sx={{ mb: 1 }}>
                              {`${t("Total")}`}
                            </Typography>
                            <Typography variant="body2">
                              {`${eval(value)} ${t("Baht")}`}
                            </Typography>
                          </CardContent>
                        </Card>
                      </TableCell>
                    );
                })}
            </TableRow>
          </Table>
        </TableContainer>
      </>
    );
  };

  const onExportExcel = () => {
    if (payRun.length > 0) {
      const elementList = [];
      if (value == t("PersonalInformation")) {
        payslip.map((item, index) => {
          let element = {};
          element["#"] = index + 1;
          element[t("EmployeeID")] = item.employeeId;
          element[t("FirstName")] = item.firstname_TH;
          element[t("LastName")] = item.lastname_TH;
          element[t("PersonalID")] = item.personalId;
          element[t("Position")] = item.positionName;
          element[t("Company")] = item.companyName;
          element[t("Department")] = item.divisionName;
          element[t("Section")] = item.sectionName;
          for (let m = 1; m < 13; m++) {
            let month = "item.m" + m;
            if (eval(month)) {
              element[`${t("NetIncome")} ${t("Month")}${m}`] = eval(month).net;
              element[`${t("OnlyIncome")} ${t("Month")}${m}`] = eval(month).totalEarnings;
              element[`${t("OnlyDeductions")} ${t("Month")}${m}`] = eval(month).totalDeductions;
              element[`${t("OnlySocialSecurityDeductions")} ${t("Month")} ${m}`] = eval(month).totalPF;
              element[`${t("OnlyTaxDeductions")} ${t("Month")}${m}`] = eval(month).totalTax;
            } else {
              element[`${t("NetIncome")} ${t("Month")}${m}`] = "";
              element[`${t("OnlyIncome")} ${t("Month")}${m}`] = "";
              element[`${t("OnlyDeductions")} ${t("Month")}${m}`] = "";
              element[`${t("OnlySocialSecurityDeductions")} ${t("Month")}${m}`] = "";
              element[`${t("OnlyTaxDeductions")} ${t("Month")}${m}`] = "";
            }
          }
          elementList.push(element);
        });
      } else if (value == t("CompanyInformation")) {
        let element = {};
        element[t("Company")] = userProfile.companyName;
        payRun.map((item, index) => {
          element[`${t("TotalPaid")} ${t("Month")}${item.id + 1}`] = item.value.earning;
          element[`${t("AppMenu.Payroll")} ${t("Month")}${item.id + 1}`] = item.value.salary;
          element[`OT ${t("Month")}${item.id + 1}`] = item.value.ot;
          element[`${t("ShiftValue")} ${t("Month")}${item.id + 1}`] = item.value.shift;
          element[`${t("TaxRemitted")} ${t("Month")}${item.id + 1}`] = item.value.tax;
          element[`${t("CompanySocialSecurity")} ${t("Month")}${item.id + 1}`] =
            item.value.provident;
          element[`${t("EmployeeSocialSecurity")} ${t("Month")}${item.id + 1}`] =
            item.value.provident;
          element[`PF${t("CompanyPart")} ${t("Month")}${item.id + 1}`] = item.value.pfCompany;
          element[`PF${t("EmployeePart")} ${t("Month")}${item.id + 1}`] = item.value.pfEmployee;
          element[`${t("Addition")} (Addition) ${t("Month")}${item.id + 1}`] =
            item.value.additions;
          element[`${t("Deduction")} (Deduction) ${t("Month")}${item.id + 1}`] =
            item.value.deduction;
        });
        elementList.push(element);
      }
      const workSheet = XLSX.utils.json_to_sheet(elementList);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(
        workBook,
        workSheet,
        `${t("AppMenu.Payroll")}`
      );
      XLSX.writeFile(
        workBook,
        `${t("PayrollReport")} ${dayjs(new Date()).format("YYYY-MM-DD")}.xlsx`
      );
    } else {
      handleChangeAlertType(t("NoData"));
      handleOpenAlert(true);
    }
  };

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          <Link
            style={{ textDecoration: "none", color: "inherit" }}
            to={"/employees-report"}
          >
            {`${t("AllReports")}`}
          </Link>
          <Typography color="text.primary">{t("PayrollReport")}</Typography>
        </Breadcrumbs>
        <div style={{ marginBottom: 8 }}>
          <Typography variant="h4" style={{ paddingTop: 8 }}>
            {`${t("PayrollReport")}`}
          </Typography>
        </div>
        <StyledWrapFilter>
          <Grid container spacing={2} columns={14} alignItems="center">
            <Grid item xs={3}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {`${t("Company")}`}
                </Typography>
                <div className="search-name">
                  {affiliateList && (
                    <StyledAutocomplete
                      options={affiliateList}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setSelectedCompany(newValue.idCompany);
                        } else {
                          setSelectedCompany(userProfile.idCompany);
                        }
                      }}
                      popupIcon={<i className="fa-light fa-chevron-down"></i>}
                      getOptionLabel={(option) => option.companyName}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="filled"
                          placeholder={`${t("AllCompany")}`}
                        />
                      )}
                      PopperComponent={StyledPopper}
                      noOptionsText={`${t("NoData")}`}
                    />
                  )}
                </div>
              </StyledBoxSearch>
            </Grid>
            <Grid item xs={3}>
              <StyledBoxSearch>
                <Typography className="label" color="text.third">
                  {`${t("Year")}`}
                </Typography>
                <div className="search-name">
                  <DatePickerCustom
                    minDate={new Date(new Date().getFullYear() - 4, 0, 1)}
                    maxDate={new Date()}
                    inputFormat="YYYY"
                    value={inputSearch}
                    name="start"
                    views={["year"]}
                    openTo="year"
                    onChange={(newValue) => {
                      setInputSearch(newValue);
                    }}
                  />
                </div>
              </StyledBoxSearch>
            </Grid>

            <Grid item xs={3}>
              <StyledBoxSearch>
                <div className="wrap-search-action">
                  <Fragment>
                    <ButtonGroup ref={anchorRef} aria-label="split button">
                      <ButtonBlue variant="outlined" onClick={handleClick}>
                        {options[selectedIndex]}
                      </ButtonBlue>
                      <ButtonBlue
                        size="small"
                        variant="outlined"
                        aria-controls={open ? "split-button-menu" : undefined}
                        aria-expanded={open ? "true" : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={handleToggle}
                      >
                        <ArrowDropDownIcon />
                      </ButtonBlue>
                    </ButtonGroup>
                    <Popper
                      sx={{
                        zIndex: 1,
                      }}
                      open={open}
                      anchorEl={anchorRef.current}
                      role={undefined}
                      transition
                      disablePortal
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin: "center top",
                          }}
                        >
                          <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                              <MenuList id="split-button-menu" autoFocusItem>
                                {options.map((option, index) => (
                                  <MenuItem
                                    key={option}
                                    disabled={index === 0 && payRun === null}
                                    selected={index === selectedIndex}
                                    onClick={(event) =>
                                      handleMenuItemClick(event, index)
                                    }
                                  >
                                    {option}
                                  </MenuItem>
                                ))}
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </Fragment>
                </div>
              </StyledBoxSearch>
            </Grid>
          </Grid>
        </StyledWrapFilter>
        {payRun ? (
          <StyledRoot>
            {Render("salary", t("AppMenu.Payroll"))}
            {Render("ot", t("Overtime"))}
            {Render("shift", t("ShiftValue"))}
            {Render("tax", t("TaxRemitted"))}
            {Render("provident", t("SocialSecurityRemitted"))}
            {Render("pfCompany", t("CompanyFundRemitted"))}
            {Render("pfCompany", t("EmployeeFundRemitted"))}
            {Render("additions", t("Addition"))}
            {Render("deduction", t("Deduction"))}
            {Render("earning", t("TotalPaid"))}
          </StyledRoot>
        ) : (
          <Backdrop open={true} />
        )}
      </Container>
      {openDrawer && (
        <DialogSetting
          setData={setData}
          handleClose={handleCloseDrawer}
          open={openDrawer}
          data={data}
        />
      )}
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </StyledRoot>
  );
};

export default Payroll;
