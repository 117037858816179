import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import Pagination from "../../../shared/general/Pagination";
import { useTranslation } from "react-i18next";
import {
  Grid,
  Typography,
  LinearProgress,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  IconButton,
  Container,
  Box,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { styled } from "@mui/styles";

import { useSelector, useDispatch } from "react-redux";
import { getAllProgressOrgPlan } from "../../../../../actions/ProgressOrganizationPlans";

import FindInPageIcon from "@mui/icons-material/FindInPage";
import FlagIcon from "@mui/icons-material/Flag";
import VpnKeyRoundedIcon from "@mui/icons-material/VpnKeyRounded";
import EventIcon from "@mui/icons-material/Event";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CommentIcon from "@mui/icons-material/Comment";

const ContainerStyled = styled(Container)({
  marginTop: "100px",
});

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiToolbar-root": {
      display: "flex",
      flexDirection: "column",
      paddingLeft: 40,
      paddingRight: 40,
    },
    "& .MuiSvgIcon-root": {
      marginRight: 8,
    },
    "& .MuiAccordion-root:before": {
      backgroundColor: "transparent",
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
    "& .MuiAccordion-rounded": {
      borderRadius: "10px",
      marginTop: 8,
      marginBottom: 16,
      paddingTop: 8,
      paddingLeft: 8,
      paddingRight: 8,
      paddingBottom: 8,
      boxShadow: "rgba(17, 17, 26, 0.1) 0px 2px 0px",
    },
    "& .MuiTypography-h6": {
      color: "#222f3e",
    },
    "& .MuiAccordionDetails-root": {
      flexDirection: "column",
      paddingTop: 32,
      paddingBottom: 32,
      backgroundColor: "#fafafa",
      borderLeft: "5px solid #4f65df",
      marginLeft: 8,
      marginRight: 8,
    },
  },
  svgText: {
    display: "flex",
    alignItems: "center",
  },
  svgAccord: {
    width: 48,
    height: 48,
    backgroundColor: "#e1f5fe",
    color: "#03a9f4",
  },
  textBold: {
    fontWeight: "bold",
  },
  hrTag: {
    width: "100%",
    border: "1px solid #eeeeee",
    marginTop: 32,
    marginBottom: 32,
  },
  noData_Section: {
    display: "flex",
    alignItems: "center",
  },
  noDataSvg: {
    fontSize: "30px",
    marginRight: 8,
  },
  resultGood: {
    width: "110px",
    padding: "5px",
    borderRadius: "15px",
    backgroundColor: "#f1f8e9",
    color: "#4caf50",
    textAlign: "center",
    "&:hover": { backgroundColor: "#f1f8e9", color: "#4caf50" },
  },
  resultNormal: {
    width: "110px",
    padding: "5px",
    borderRadius: "15px",
    backgroundColor: "#fff8e1",
    color: "#fbc02d",
    textAlign: "center",
    "&:hover": { backgroundColor: "#fff8e1", color: "#fbc02d" },
  },
  resultBad: {
    width: "110px",
    padding: "5px",
    borderRadius: "15px",
    backgroundColor: "#ffebee",
    color: "#e53935",
    textAlign: "center",
    "&:hover": { backgroundColor: "#ffebee", color: "#e53935" },
  },
}));

function OrganizationRecord() {
  const {t,i18n} = useTranslation();
  const location = useLocation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { result: progressOrgPlanStore } = useSelector(
    (state) => state.ProgressOrgPlans
  );

  const [records, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(5);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = records.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const fetchData = () => {
    dispatch(getAllProgressOrgPlan());
  };

  useEffect(() => {
    if (progressOrgPlanStore) {
      let progressList = progressOrgPlanStore.filter(
        (x) => x.planOrgId === location.state.planList.planOrgId
      );

      progressList.sort(function (a, b) {
        return new Date(b.progressOrgId) - new Date(a.progressOrgId);
      });

      setRecords(progressList);
      setIsLoading(false);
    }
  }, [progressOrgPlanStore]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <ContainerStyled>
      <div className="card-section">
        <div className="header-section">
          <Grid container>
            <Grid item>
              <h3 className="header-topic">Organization Records</h3>
            </Grid>
          </Grid>
        </div>
        <Box sx={{ paddingRight: 4, paddingLeft: 4 }}>
          <Grid container>
            <Grid item xs={12} md={12} lg={12}>
              <Typography variant="h6" className={classes.svgText} gutterBottom>
                <FlagIcon sx={{ marginRight: 1 }} />
                Objective : {location.state.planList.objectiveName}
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} md={12} lg={12}>
              {location.state.planList.keyResult1 && (
                <>
                  <Typography variant="h6" gutterBottom>
                    {t("KeyResult")}
                  </Typography>
                  <Typography
                    variant="body1"
                    className={classes.svgText}
                    gutterBottom
                  >
                    <VpnKeyRoundedIcon sx={{ marginRight: 1 }} />
                    {location.state.planList.keyResult1}
                  </Typography>
                </>
              )}
              {location.state.planList.keyResult2 && (
                <>
                  <Typography
                    variant="body1"
                    className={classes.svgText}
                    gutterBottom
                  >
                    <VpnKeyRoundedIcon sx={{ marginRight: 1 }} />
                    {location.state.planList.keyResult2}
                  </Typography>
                </>
              )}
              {location.state.planList.keyResult3 && (
                <>
                  <Typography
                    variant="body1"
                    className={classes.svgText}
                    gutterBottom
                  >
                    <VpnKeyRoundedIcon sx={{ marginRight: 1 }} />
                    {location.state.planList.keyResult3}
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      </div>
      {isLoading ? (
        <LinearProgress />
      ) : (
        <>
          {/* records */}
          {(records.length > 0 &&
            // currentPosts
            currentPosts.map((item) => (
              <div>
                <Typography variant="h6" className={classes.svgText}>
                  <EventIcon sx={{ marginRight: 1 }} />
                  {dayjs(item.dateUpdate).format("DD/MM/YYYY")}
                </Typography>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Grid container alignItems="center">
                      <Grid item xs={2} md={1} lg={1}>
                        <Avatar className={classes.svgAccord}>
                          <CommentIcon style={{ marginRight: "0px" }} />
                        </Avatar>
                      </Grid>
                      <Grid item xs={10} md={8} lg={8}>
                        <Typography variant="h6">
                          {location.state.planList.impactName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    {location.state.planList.keyResult1 && (
                      <>
                        <Grid container sx={{ marginBottom: 2 }}>
                          <Grid item xs={1} md={1} lg={1}></Grid>
                          <Grid item xs={3} md={2} lg={2}>
                            <Typography
                              variant="body1"
                              className={classes.textBold}
                              gutterBottom
                            >
                              {t("KeyResult")} 1{" "}
                            </Typography>
                          </Grid>
                          <Grid item xs>
                            <Typography variant="body1">
                              {location.state.planList.keyResult1}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container sx={{ marginBottom: 2 }}>
                          <Grid item xs={1} md={1} lg={1}></Grid>
                          <Grid item xs={3} md={2} lg={2}>
                            <Typography
                              variant="body1"
                              className={classes.textBold}
                              gutterBottom
                            >
                              Result Month
                            </Typography>
                          </Grid>
                          <Grid item>
                            <IconButton
                              className={
                                item.score1 === 1
                                  ? `${classes.resultBad}`
                                  : item.score1 === 2
                                  ? `${classes.resultNormal}`
                                  : `${classes.resultGood}`
                              }
                              size="large"
                            >
                              <Typography
                                style={{ fontWeight: 600 }}
                                variant="subtitle2"
                              >
                                {item.score1 === 1
                                  ? "Bad"
                                  : item.score1 === 2
                                  ? "Normal"
                                  : "Good"}
                              </Typography>
                            </IconButton>
                          </Grid>
                        </Grid>
                        <Grid container sx={{ marginBottom: 2 }}>
                          <Grid item xs={1} md={1} lg={1}></Grid>
                          <Grid item xs={3} md={2} lg={2}>
                            <Typography
                              variant="body1"
                              className={classes.textBold}
                              gutterBottom
                            >
                              Progress
                            </Typography>
                          </Grid>
                          <Grid item xs>
                            <Typography variant="body1">
                              {item.progress1}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container sx={{ marginBottom: 2 }}>
                          <Grid item xs={1} md={1} lg={1}></Grid>
                          <Grid item xs={3} md={2} lg={2}>
                            <Typography
                              variant="body1"
                              className={classes.textBold}
                              gutterBottom
                            >
                              Description
                            </Typography>
                          </Grid>
                          <Grid item xs>
                            <Typography variant="body1">
                              {item.description1}
                            </Typography>
                          </Grid>
                        </Grid>
                      </>
                    )}

                    {location.state.planList.keyResult2 && (
                      <>
                        <hr className="hrTag" />
                        <Grid container sx={{ marginBottom: 2 }}>
                          <Grid item xs={1} md={1} lg={1}></Grid>
                          <Grid item xs={3} md={2} lg={2}>
                            <Typography
                              variant="body1"
                              className={classes.textBold}
                              gutterBottom
                            >
                              {t("KeyResult")} 2{" "}
                            </Typography>
                          </Grid>
                          <Grid item xs>
                            <Typography variant="body1">
                              {location.state.planList.keyResult2}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container sx={{ marginBottom: 2 }}>
                          <Grid item xs={1} md={1} lg={1}></Grid>
                          <Grid item xs={3} md={2} lg={2}>
                            <Typography
                              variant="body1"
                              className={classes.textBold}
                            >
                              Result Month
                            </Typography>
                          </Grid>
                          <Grid item>
                            <IconButton
                              className={
                                item.score2 === 1
                                  ? `${classes.resultBad}`
                                  : item.score2 === 2
                                  ? `${classes.resultNormal}`
                                  : `${classes.resultGood}`
                              }
                              size="large"
                            >
                              <Typography
                                style={{ fontWeight: 600 }}
                                variant="subtitle2"
                              >
                                {item.score2 === 1
                                  ? "Bad"
                                  : item.score2 === 2
                                  ? "Normal"
                                  : "Good"}
                              </Typography>
                            </IconButton>
                          </Grid>
                        </Grid>
                        <Grid container sx={{ marginBottom: 2 }}>
                          <Grid item xs={1} md={1} lg={1}></Grid>
                          <Grid item xs={3} md={2} lg={2}>
                            <Typography
                              variant="body1"
                              className={classes.textBold}
                              gutterBottom
                            >
                              Progress
                            </Typography>
                          </Grid>
                          <Grid item xs>
                            <Typography variant="body1">
                              {item.progress2}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container sx={{ marginBottom: 2 }}>
                          <Grid item xs={1} md={1} lg={1}></Grid>
                          <Grid item xs={3} md={2} lg={2}>
                            <Typography
                              variant="body1"
                              className={classes.textBold}
                              gutterBottom
                            >
                              Description
                            </Typography>
                          </Grid>
                          <Grid item xs>
                            <Typography variant="body1">
                              {item.description2}
                            </Typography>
                          </Grid>
                        </Grid>
                      </>
                    )}
                    {location.state.planList.keyResult3 && (
                      <>
                        <hr className="hrTag" />
                        <Grid container>
                          <Grid item xs={1} md={1} lg={1}></Grid>
                          <Grid item xs={3} md={2} lg={2}>
                            <Typography
                              variant="body1"
                              className={classes.textBold}
                              gutterBottom
                            >
                              {t("KeyResult")} 3{" "}
                            </Typography>
                          </Grid>
                          <Grid item xs>
                            <Typography variant="body1">
                              {location.state.planList.keyResult3}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container sx={{ marginBottom: 2 }}>
                          <Grid item xs={1} md={1} lg={1}></Grid>
                          <Grid item xs={3} md={2} lg={2}>
                            <Typography
                              variant="body1"
                              className={classes.textBold}
                              gutterBottom
                            >
                              Result Month
                            </Typography>
                          </Grid>
                          <Grid item>
                            <IconButton
                              className={
                                item.score3 === 1
                                  ? `${classes.resultBad}`
                                  : item.score3 === 2
                                  ? `${classes.resultNormal}`
                                  : `${classes.resultGood}`
                              }
                              size="large"
                            >
                              <Typography
                                style={{ fontWeight: 600 }}
                                variant="subtitle2"
                              >
                                {item.score3 === 1
                                  ? "Bad"
                                  : item.score3 === 2
                                  ? "Normal"
                                  : "Good"}
                              </Typography>
                            </IconButton>
                          </Grid>
                        </Grid>
                        <Grid container sx={{ marginBottom: 2 }}>
                          <Grid item xs={1} md={1} lg={1}></Grid>
                          <Grid item xs={3} md={2} lg={2}>
                            <Typography
                              variant="body1"
                              className={classes.textBold}
                              gutterBottom
                            >
                              Progress
                            </Typography>
                          </Grid>
                          <Grid item xs>
                            <Typography variant="body1">
                              {item.progress3}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container sx={{ marginBottom: 2 }}>
                          <Grid item xs={1} md={1} lg={1}></Grid>
                          <Grid item xs={3} md={2} lg={2}>
                            <Typography
                              variant="body1"
                              className={classes.textBold}
                              gutterBottom
                            >
                              Description
                            </Typography>
                          </Grid>
                          <Grid item xs>
                            <Typography variant="body1">
                              {item.description3}
                            </Typography>
                          </Grid>
                        </Grid>
                      </>
                    )}
                  </AccordionDetails>
                </Accordion>
              </div>
            ))) || (
            <div className="cardPlan">
              <Grid container justifyContent="center">
                <Grid item>
                  <Typography variant="h6" className={classes.noData_Section}>
                    <FindInPageIcon className={classes.noDataSvg} />
                    No Data
                  </Typography>
                </Grid>
              </Grid>
            </div>
          )}
        </>
      )}
      {/* records */}
      {records.length > 0 ? (
        <Pagination
          paginate={paginate}
          postsPerPage={postsPerPage}
          totalPosts={records.length}
        />
      ) : null}
    </ContainerStyled>
  );
}

export default OrganizationRecord;
