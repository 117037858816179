import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Avatar,
  Box,
  Container,
  Grid,
  Popper,
  TextField,
  Typography,
  autocompleteClasses,
  styled,
  Card,
  Pagination,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useDispatch, useSelector } from "react-redux";
import { getAllEmployees } from "../../../../actions/employee";
import { getAllIndividualObjectiveByIdEmp } from "../../../../actions/okrs";
import CardObjective from "../../admin/okrs/Status/cardObjective";
import { useTranslation } from "react-i18next";
import { getUserFullName, getUserPosition } from "../../../../utils/userData";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiCardContent-root": {
      padding: 0,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 16,
    },
    "& .MuiOutlinedInput-input": {
      padding: "16px 14px",
    },
    "& .MuiTableCell-root": {
      paddingTop: "5px",
      paddingBottom: "5px",
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
  },
}));

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiFilledInput-root": {
    backgroundColor: "#919eab14",
    height: 56,
    padding: "0px 12px",
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: "#919eab14",
    },
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      backgroundColor: "#919eab29",
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: "border-box",
    [`& .${autocompleteClasses.option}`]: {
      "&:hover": {
        backgroundColor: "#f6f7f8",
        margin: "0 8px",
        borderRadius: 8,
        paddingLeft: 8,
      },
    },
  },
});

const ContainerStyled = styled(Container)({
  paddingTop: "10px",
  height: "100%",
  minHeight: "100vh",
});

const StyledRoot = styled(Box)({
  "& .keyresult-text": {
    fontSize: "20px",
    fontWeight: 500,
  },
  "& .MuiAutocomplete-root": {
    "& .MuiOutlinedInput-root": {
      padding: "13.5px 14px",
      paddingRight: "32px",
      "& input": {
        padding: 0,
      },
    },
  },
});

const StyledCard = styled(Card)({
  minHeight: "70px",
  boxShadow: "none",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  background:
    "linear-gradient(to right, rgba(254, 196, 144, 0.7), rgba(235, 148, 192, 0.7))",
  "& .cardTitle": {
    fontSize: 22,
  },
  "& .MuiCardContent-root": {
    padding: 24,
  },
  "& .cardHeader": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
  },
  "& .yearSelectionContainer": {
    marginLeft: "41px",
    marginBottom: "100px",
  },
  "@media (max-width: 600px)": {
    "& .yearSelectionContainer": {
      marginBottom: "1px",
    },
  },
});

const StyleHeadBox = styled(Box)({
  "& .box-header": {
    padding: "0px 24px",
    minHeight: "200px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor:
      "linear-gradient(to right, rgba(254, 196, 144, 0.7), rgba(235, 148, 192, 0.7))",
    "& .text-header": {
      fontSize: "48px",
      fontWeight: "bold",
      marginLeft: "40px",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      marginTop: "80px",
    },
    "& .img-header": {
      objectFit: "contain",
      // width: "33%",
      // height: "auto",
      // marginBottom: "10px",
      // justifyContent: "flex-end",
      // alignItems: "flex-end",
    },
  },

  "@media (max-width: 600px)": {
    "& .box-header": {
      flexDirection: "column",
      minHeight: "unset",
      justifyContent: "center",
      alignItems: "center",
      "& .text-header": {
        fontSize: "36px",
        marginTop: "20px",
        aliignText: "center",
      },
      // "& .img-header": {
      //   width: "30%",
      //   justifyContent: "flex-start",
      //   alignItems: "flex-start",
      // },
    },
  },
});

const StyleText = styled(Typography)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",

  "& .name-text": {
    fontSize: "30px",
    marginTop: "20px",
    fontWeight: "bold",
  },
  "& .normal-text": {
    fontSize: "22px",
    marginTop: "1px",
    color: "gray",
  },
});

function OKRStatusTeam() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [individualObjective, setIndividualObjective] = useState(null);

  const { result: employeeList } = useSelector((state) => state.employees);

  const itemsPerPage = 2;
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = individualObjective
    ? Math.ceil(individualObjective.length / itemsPerPage)
    : 0;

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = Math.min(
    startIndex + itemsPerPage,
    individualObjective ? individualObjective.length : 0
  );

  const currentObjectives = individualObjective
    ? individualObjective.slice(startIndex, endIndex)
    : [];

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
  };

  const handleChageEmployee = async (idEmployees) => {
    dispatch(getAllIndividualObjectiveByIdEmp(idEmployees)).then((res) => {
      if (res.status === 200) {
        setIndividualObjective(res.data);
      }
    });
  };

  useEffect(() => {
    dispatch(getAllEmployees("manager"));
  }, []);

  return (
    <div>
      <StyledRoot className="page">
        <Container maxWidth="lg">
          <StyledCard
            style={{
              marginBottom: "48px",
              marginTop: "20px",
            }}
          >
            <StyleHeadBox>
              <Box className="box-header">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography className="text-header">
                      {`${t("OKRStatus")}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} className="yearSelectionContainer">
                    <Typography
                      fontSize="14px"
                      fontWeight="600"
                      marginBottom="4px"
                      color="text.third"
                    >{`${t("Employee")}`}</Typography>
                    {employeeList && (
                      <StyledAutocomplete
                        style={{
                          width: "250px",
                          background: "#fff",
                          border: "none",
                          borderRadius: "8px",
                        }}
                        options={employeeList}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            handleChageEmployee(newValue.idEmployees);
                          }
                          setSelectedEmployee(newValue);
                        }}
                        popupIcon={<i className="fa-light fa-chevron-down"></i>}
                        getOptionLabel={(option) =>
                          `${getUserFullName(option)}`
                        }
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option.idIndividualObjective}>
                              {`${getUserFullName(option)}`}
                            </li>
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="filled"
                            placeholder={`${t("SelectEmp")}`}
                          />
                        )}
                        PopperComponent={StyledPopper}
                        noOptionsText={`${t("NoData")}`}
                      />
                    )}
                  </Grid>
                </Grid>
                <img
                  className="img-header"
                  src={`${process.env.PUBLIC_URL}/assets/okrs/manager/progress-status.png`}
                  alt="icon"
                />
              </Box>
            </StyleHeadBox>
          </StyledCard>

          <ContainerStyled>
            {individualObjective && individualObjective.length > 0 ? (
              <div className={classes.root}>
                <Box marginBottom="16px" display="flex" justifyContent="right">
                  {individualObjective.length} {`${t("List")}`}
                </Box>

                <Grid container spacing={2} marginBottom={4}>
                  <Grid item xs={12} sm={4}>
                    <div
                      className="card-section"
                      style={{ marginTop: "40px", height: "450px" }}
                    >
                      <Grid container spacing={5} padding={2}>
                        <Grid item xs={12}>
                          {selectedEmployee && (
                            <Grid
                              container
                              direction="column"
                              alignItems="center"
                              spacing={1}
                            >
                              <Grid item>
                                <Avatar
                                  src={selectedEmployee.imageProfile}
                                  alt={"imageProfile"}
                                  style={{ width: 150, height: 150 }}
                                />
                              </Grid>
                              <Grid item>
                                <StyleText>
                                  <Typography className="name-text">{`${getUserFullName(selectedEmployee)}`}</Typography>
                                  <Typography className="normal-text">
                                    {getUserPosition(selectedEmployee)}
                                  </Typography>
                                  <Typography className="normal-text">
                                    {selectedEmployee.departmentName}
                                  </Typography>
                                </StyleText>
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={8}>
                    {currentObjectives.map((objective, index) => (
                      <Box
                        key={index}
                        className="objectcard-wrap"
                        style={{ marginBottom: "10px" }}
                      >
                        <CardObjective data={objective} />
                      </Box>
                    ))}
                  </Grid>
                </Grid>
              </div>
            ) : (
              individualObjective && (
                <Box display="flex" justifyContent="center" paddingTop="24px">
                  <Typography>{`${t("NoItems")}`}</Typography>
                </Box>
              )
            )}

            {totalPages > 1 && (
              <Box display="flex" justifyContent="center" marginTop={2}>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                />
              </Box>
            )}
          </ContainerStyled>
        </Container>
      </StyledRoot>
    </div>
  );
}

export default OKRStatusTeam;
