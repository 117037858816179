import React, { useRef, useState } from "react";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import TextFieldTheme from "../../../shared/general/TextFieldTheme";
import NumberFormatTheme from "../../../shared/general/NumberFormatTheme";
import ClearIcon from "@mui/icons-material/Clear";
import employeeService from "../../../../../services/employee.service";
import ButtonBlue from "../../../shared/general/ButtonBlue";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import DatePickerCustom from "../../../shared/date/datePicker";

const BasicInfo = (params) => {
  const {
    useForm,
    isDuplicatedPersonalID,
    setIsDuplicatedPersonalID,
    // isDuplicatedEmail,
    setIsDuplicatedEmail,
    rehiringConfig,
    setRehiringConfig,
    registrationMetadata,
  } = params;
  const { t, i18n } = useTranslation();

  const [isFetchingPersonalIDCheck, setIsFetchingPersonalIDCheck] =
    useState(false);

  const [isFetchingEmailCheck, setIsFetchingEmailCheck] = useState(false);

  const timeoutEmailRef = useRef(null);
  const timeoutPersonalIDRef = useRef(null);

  const handleEmailChange = (value) => {
    if (timeoutEmailRef.current) {
      clearTimeout(timeoutEmailRef.current);
    }
    const newTimeoutId = setTimeout(() => {
      handleDebounceEmailValidation("email", value);
    }, 500);
    timeoutEmailRef.current = newTimeoutId;
  };

  const handlePersonalIDChange = (value) => {
    if (timeoutPersonalIDRef.current) {
      clearTimeout(timeoutPersonalIDRef.current);
    }
    const newTimeoutId = setTimeout(() => {
      handleDebouncePersonalIDValidation("personalID", value);
    }, 500);
    timeoutPersonalIDRef.current = newTimeoutId;
  };

  const handleDebouncePersonalIDValidation = async (type, value) => {
    if (value.length >= 7) {
      setIsFetchingPersonalIDCheck(true);
      const res = await employeeService.checkEmployeeDuplicate({
        type: type,
        value: value,
      });
      if (res && res.status === 200) {
        setIsDuplicatedPersonalID(res.data.isDuplicate);
        setRehiringConfig((prev) => ({
          ...prev,
          isRehiring: res.data.isRehiring,
          idEmployeesRehiring: res.data.idEmployeesRehiring,
        }));
      } else {
        setIsDuplicatedPersonalID(false);
      }
      useForm.trigger("personalID");
      setIsFetchingPersonalIDCheck(false);
    } else {
      if (isDuplicatedPersonalID) {
        setIsDuplicatedPersonalID(false);
      }
      if (rehiringConfig.isRehiring) {
        setRehiringConfig((prev) => ({
          ...prev,
          isRehiring: false,
          idEmployeesRehiring: null,
        }));
      }
    }
  };

  const handleDebounceEmailValidation = async (type, value) => {
    if (value) {
      setIsFetchingEmailCheck(true);
      const res = await employeeService.checkEmployeeDuplicate({
        type: type,
        value: value,
      });
      if (res && res.status === 200) {
        setIsDuplicatedEmail(res.data.isDuplicate);
      } else {
        setIsDuplicatedEmail(false);
      }
      useForm.trigger("email");
      setIsFetchingEmailCheck(false);
    }
  };

  const onClickLoadingRehiringEmployeeInfo = async () => {
    const result = await employeeService.getEmployeeProfile(
      rehiringConfig.idEmployeesRehiring,
      "admin"
    );
    if (result && result.status === 200) {
      console.log(result.data);
      useForm.setValue("employeeID", result.data.employeeID || "");
      useForm.setValue("title_TH", result.data.title_TH || "");
      useForm.setValue("firstname_TH", result.data.firstname_TH || "");
      useForm.setValue("lastname_TH", result.data.lastname_TH || "");
      useForm.setValue("nickname_TH", result.data.nickname_TH || "");
      useForm.setValue("title_EN", result.data.title_EN || "");
      useForm.setValue("firstname_EN", result.data.firstname_EN || "");
      useForm.setValue("lastname_EN", result.data.lastname_EN || "");
      useForm.setValue("nickname_EN", result.data.nickname_EN || "");
      useForm.setValue("gender", result.data.gender || "");
      useForm.setValue("birthday", result.data.birthday || "");
      useForm.setValue("telephoneMobile", result.data.telephoneMobile || "");
      useForm.setValue("email", result.data.email || "");
      useForm.setValue("houseNo", result.data.houseNo || "");
      useForm.setValue("village", result.data.village || "");
      useForm.setValue("villageNo", result.data.villageNo || "");
      useForm.setValue("alley", result.data.alley || "");
      useForm.setValue("road", result.data.road || "");
      useForm.setValue("subDistrict", result.data.subDistrict || "");
      useForm.setValue("district", result.data.district || "");
      useForm.setValue("provience", result.data.provience || "");
      useForm.setValue("nationality", result.data.nationality || "");
      useForm.setValue("idReligion", result.data.idReligion || "");
      useForm.setValue("areaCode", result.data.areaCode || "");
      useForm.setValue("maritalStatus", result.data.maritalStatus || "");
      useForm.setValue("passportNumber", result.data.passportNumber || "");
      useForm.setValue("workPermitNumber", result.data.workPermitNumber || "");
      useForm.setValue("emergencyContact", result.data.emergencyContact || "");
      useForm.setValue("emergencyPhone", result.data.emergencyPhone || "");
      useForm.setValue(
        "emergencyRelationship",
        result.data.emergencyRelationship || ""
      );
      useForm.setValue("bookBank", result.data.bookBank || "");
      useForm.setValue(
        "bookBankBranchName",
        result.data.bookBankBranchName || ""
      );
      useForm.setValue("bookID", result.data.bookID || "");
      useForm.setValue(
        "reportBankBankName",
        result.data.reportBankBankName || ""
      );
      useForm.setValue("reportBankBankID", result.data.reportBankBankID || "");
      useForm.setValue(
        "reportBankBookBankID",
        result.data.reportBankBookBankID || ""
      );
      useForm.setValue("reportBankName", result.data.reportBankName || "");
      useForm.setValue("reportBankRef", result.data.reportBankRef || "");
    }
  };

  return (
    <Box>
      <Typography className="title-text">{`${t(
        "GeneralInformation"
      )}`}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Typography sx={{whiteSpace: "pre-line"}}>{`${t("PersonalID")}/\n${t("PassportNumber")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="personalID"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                InputProps={{
                  // inputComponent: NumberFormatTheme,
                  ...(isFetchingPersonalIDCheck && {
                    endAdornment: (
                      <InputAdornment position="end">
                        <Box color="#919eab">
                          <CircularProgress size="14px" color="inherit" />
                        </Box>
                      </InputAdornment>
                    ),
                  }),
                  // endAdornment: <InputAdornment position="end"><Box color="#919eab"><CircularProgress size="14px" color="inherit"/></Box></InputAdornment>
                }}
                inputProps={{
                  // format: (() => {
                  //   if (field.value.length > 13){
                  //     return ""
                  //   } else if (field.value.length === 13) {
                  //     return "#-####-#####-##-#";
                  //   } else if (
                  //     field.value.length >= 11 &&
                  //     field.value.length < 13
                  //   ) {
                  //     return "#-####-#####-###";
                  //   } else if (field.value.length >= 6) {
                  //     return "#-####-######";
                  //   } else if (field.value.length >= 2) {
                  //     return "#-#####";
                  //   } else {
                  //     return "##";
                  //   }
                  // })(),
                  // allowNegative: false,
                  // allowLeadingZeros: true,
                  // value: field.value,
                  // onValueChange: (values) => {
                  //   const { value } = values;
                  //   field.onChange(value);
                  //   handlePersonalIDChange(value);
                  // },
                }}
                onChange={(event) => {
                  field.onChange(event.target.value);
                  handlePersonalIDChange(event.target.value);
                }}
                error={useForm.formState.errors[field.name] ? true : false}
                helperText={
                  useForm.formState.errors[field.name]
                    ? useForm.formState.errors[field.name].message
                    : null
                }
              />
            )}
          />
          {rehiringConfig && rehiringConfig.isRehiring && (
            <Box marginTop="8px">
              <ButtonBlue
                variant="contained"
                onClick={() => {
                  onClickLoadingRehiringEmployeeInfo();
                }}
              >
                {`${t("LoadingEmpData")}`}
              </ButtonBlue>
              <Typography
                marginTop="8px"
                fontSize="12px"
                color="text.secondary"
              >
                {`${t("SystemDetectedNationalIDCardRehiringSameEmp")}`}
              </Typography>
            </Box>
          )}
        </Grid>

        <Grid item xs={4}>
          <Typography>{`${t("EmploymentType")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="idEmploymentType"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                select
                error={useForm.formState.errors[field.name] ? true : false}
                helperText={
                  useForm.formState.errors[field.name]
                    ? useForm.formState.errors[field.name].message
                    : null
                }
                onChange={(e) => {
                  field.onChange(e.target.value);
                  useForm.setValue(
                    "employeeID",
                    registrationMetadata.newEmployeeId.find(
                      (item) => item.idEmploymentType === e.target.value
                    ).employeeID || "",
                    { shouldValidate: true }
                  );
                }}
              >
                {registrationMetadata.employmentTypes.map(
                  (employmentType, index) => (
                    <MenuItem
                      key={index}
                      value={employmentType.idEmploymentType}
                    >
                      {employmentType.employmentTypeName}
                    </MenuItem>
                  )
                )}
              </TextFieldTheme>
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>{`${t("EmployeeID")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="employeeID"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                error={useForm.formState.errors[field.name] ? true : false}
                helperText={
                  useForm.formState.errors[field.name]
                    ? useForm.formState.errors[field.name].message
                    : null
                }
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>{`${t("Title")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="title_TH"
            control={useForm.control}
            rules={{
              required: { value: true, message: `${t("ThisFieldIsRequired")}` },
            }}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                error={useForm.formState.errors[field.name] ? true : false}
                helperText={
                  useForm.formState.errors[field.name]
                    ? useForm.formState.errors[field.name].message
                    : null
                }
                select
              >
                <MenuItem value="นาย">{`${t("Mr")}`}</MenuItem>
                <MenuItem value="นาง">{`${t("Mrs")}`}</MenuItem>
                <MenuItem value="นางสาว">{`${t("Miss")}`}</MenuItem>
              </TextFieldTheme>
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>{`${t("FirstName")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="firstname_TH"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                error={useForm.formState.errors[field.name] ? true : false}
                helperText={
                  useForm.formState.errors[field.name]
                    ? useForm.formState.errors[field.name].message
                    : null
                }
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>{`${t("LastName")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="lastname_TH"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                error={useForm.formState.errors[field.name] ? true : false}
                helperText={
                  useForm.formState.errors[field.name]
                    ? useForm.formState.errors[field.name].message
                    : null
                }
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>{`${t("Nickname")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="nickname_TH"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                error={useForm.formState.errors[field.name] ? true : false}
                helperText={
                  useForm.formState.errors[field.name]
                    ? useForm.formState.errors[field.name].message
                    : null
                }
                InputProps={{
                  placeholder: `${t("Optional")}`,
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>{`${t("TitleEng")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="title_EN"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                error={useForm.formState.errors[field.name] ? true : false}
                helperText={
                  useForm.formState.errors[field.name]
                    ? useForm.formState.errors[field.name].message
                    : null
                }
                select
              >
                <MenuItem value="Mr.">Mr.</MenuItem>
                <MenuItem value="Mrs.">Mrs.</MenuItem>
                <MenuItem value="Miss">Miss</MenuItem>
              </TextFieldTheme>
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>{`${t("FirstNameEng")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="firstname_EN"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                error={useForm.formState.errors[field.name] ? true : false}
                helperText={
                  useForm.formState.errors[field.name]
                    ? useForm.formState.errors[field.name].message
                    : null
                }
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>{`${t("LastNameEng")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="lastname_EN"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                error={useForm.formState.errors[field.name] ? true : false}
                helperText={
                  useForm.formState.errors[field.name]
                    ? useForm.formState.errors[field.name].message
                    : null
                }
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>{`${t("NicknameEng")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="nickname_EN"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                error={useForm.formState.errors[field.name] ? true : false}
                helperText={
                  useForm.formState.errors[field.name]
                    ? useForm.formState.errors[field.name].message
                    : null
                }
                InputProps={{
                  placeholder: `${t("Optional")}`,
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>{`${t("Gender")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="gender"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                // InputProps={{
                //   endAdornment: field.value? (<IconButton className="clear-button" onClick={()=>{field.onChange("")}}><ClearIcon/></IconButton>): null
                // }}
                error={useForm.formState.errors[field.name] ? true : false}
                helperText={
                  useForm.formState.errors[field.name]
                    ? useForm.formState.errors[field.name].message
                    : null
                }
                select
              >
                <MenuItem value="Male">{`${t("Male")}`}</MenuItem>
                <MenuItem value="Female">{`${t("Female")}`}</MenuItem>
              </TextFieldTheme>
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>{`${t("BirthDate")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="birthday"
            control={useForm.control}
            render={({ field }) => (
              <DatePickerCustom
                {...field}
                views={["year", "month", "day"]}
                inputFormat="DD MMMM YYYY"
                disableFuture
                disableMaskedInput
                openTo="year"
                value={field.value}
                onChange={(newValue) => {
                  field.onChange(newValue);
                }}
                renderInput={(params) => (
                  <TextFieldTheme
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: `${t("DayMonthYear(AD)")}`,
                      readOnly: true,
                    }}
                    // InputProps={{
                    //   ...params.InputProps,
                    //   endAdornment: ([field.value? <IconButton className="clear-button date" onClick={()=>{field.onChange(null)}}><ClearIcon/></IconButton>: null, params.InputProps.endAdornment])
                    // }}
                    onBlur={field.onBlur}
                    error={
                      useForm.formState.errors[field.name] ? true : false
                    }
                    helperText={
                      useForm.formState.errors[field.name]
                        ? useForm.formState.errors[field.name].message
                        : null
                    }
                  />
                )}
              />
            )}
          />
        </Grid>

        {/* <Grid item xs={4}>
          <Typography>หมายเลขบัตรประชาชน</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="personalID"
            control={useForm.control}
            render={({field}) => (
              <TextFieldTheme
                {...field}
                InputProps={{
                  inputComponent: NumberFormatTheme,
                }}
                inputProps={{
                  format: (() => {
                    if(field.value.length >= 13){
                      return "#-####-#####-##-#"
                    } else if(field.value.length >= 11 && field.value.length < 13) {
                      return "#-####-#####-###"
                    } else if(field.value.length >= 6){
                      return "#-####-######"
                    } else if(field.value.length >= 2){
                      return "#-#####"
                    } else {
                      return "##"
                    }
                  })(),
                  allowNegative: false,
                  allowLeadingZeros: true,
                  value: field.value,
                  onValueChange: (values) => {
                    const { value } = values;
                    field.onChange(value)
                  },
                }}
                onChange={()=>{}}
                error={useForm.formState.errors[field.name]? true: false}
                helperText={useForm.formState.errors[field.name]? useForm.formState.errors[field.name].message: null}
              />
            )}
          />
        </Grid> */}

        <Grid item xs={4}>
          <Typography>{`${t("EmergencyPhone")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="telephoneMobile"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                InputProps={{
                  inputComponent: NumberFormatTheme,
                }}
                inputProps={{
                  format: (() => {
                    if (field.value.length >= 7) {
                      return "###-###-####";
                    } else if (field.value.length >= 4) {
                      return "###-####";
                    } else {
                      return "####";
                    }
                  })(),
                  allowNegative: false,
                  allowLeadingZeros: true,
                  value: field.value,
                  onValueChange: (values) => {
                    const { value } = values;
                    field.onChange(value);
                  },
                }}
                onChange={() => {}}
                error={useForm.formState.errors[field.name] ? true : false}
                helperText={
                  useForm.formState.errors[field.name]
                    ? useForm.formState.errors[field.name].message
                    : null
                }
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>{`${t("Email")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="email"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                InputProps={{
                  ...(isFetchingEmailCheck && {
                    endAdornment: (
                      <InputAdornment position="end">
                        <Box color="#919eab">
                          <CircularProgress size="14px" color="inherit" />
                        </Box>
                      </InputAdornment>
                    ),
                  }),
                }}
                error={useForm.formState.errors[field.name] ? true : false}
                helperText={
                  useForm.formState.errors[field.name]
                    ? useForm.formState.errors[field.name].message
                    : null
                }
                onChange={(e) => {
                  field.onChange(e.target.value);
                  handleEmailChange(e.target.value);
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>{`${t("Address")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Controller
                name="houseNo"
                control={useForm.control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    error={useForm.formState.errors[field.name] ? true : false}
                    helperText={
                      useForm.formState.errors[field.name]
                        ? useForm.formState.errors[field.name].message
                        : null
                    }
                    label={`${t("HouseNo")} (${t("Optional")})`}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="village"
                control={useForm.control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    error={useForm.formState.errors[field.name] ? true : false}
                    helperText={
                      useForm.formState.errors[field.name]
                        ? useForm.formState.errors[field.name].message
                        : null
                    }
                    label={`${t("Village")} (${t("Optional")})`}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="villageNo"
                control={useForm.control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    error={useForm.formState.errors[field.name] ? true : false}
                    helperText={
                      useForm.formState.errors[field.name]
                        ? useForm.formState.errors[field.name].message
                        : null
                    }
                    label={`${t("VillageNo")} (${t("Optional")})`}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="road"
                control={useForm.control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    error={useForm.formState.errors[field.name] ? true : false}
                    helperText={
                      useForm.formState.errors[field.name]
                        ? useForm.formState.errors[field.name].message
                        : null
                    }
                    label={`${t("Road")} (${t("Optional")})`}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="alley"
                control={useForm.control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    error={useForm.formState.errors[field.name] ? true : false}
                    helperText={
                      useForm.formState.errors[field.name]
                        ? useForm.formState.errors[field.name].message
                        : null
                    }
                    label={`${t("Alley")} (${t("Optional")})`}
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="areaCode"
                control={useForm.control}
                render={({ field }) => (
                  <TextFieldTheme
                    {...field}
                    error={useForm.formState.errors[field.name] ? true : false}
                    helperText={
                      useForm.formState.errors[field.name]
                        ? useForm.formState.errors[field.name].message
                        : null
                    }
                    label={`${t("AreaCode")} (${t("Optional")})`}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4}>
          <Typography>{`${t("SubDistrict")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="subDistrict"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                error={useForm.formState.errors[field.name] ? true : false}
                helperText={
                  useForm.formState.errors[field.name]
                    ? useForm.formState.errors[field.name].message
                    : null
                }
                InputProps={{
                  placeholder: `${t("Optional")}`,
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>{`${t("District")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="district"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                error={useForm.formState.errors[field.name] ? true : false}
                helperText={
                  useForm.formState.errors[field.name]
                    ? useForm.formState.errors[field.name].message
                    : null
                }
                InputProps={{
                  placeholder: `${t("Optional")}`,
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>{`${t("Province")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="provience"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                error={useForm.formState.errors[field.name] ? true : false}
                helperText={
                  useForm.formState.errors[field.name]
                    ? useForm.formState.errors[field.name].message
                    : null
                }
                InputProps={{
                  placeholder: `${t("Optional")}`,
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>{`${t("Nationality")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="nationality"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                error={useForm.formState.errors[field.name] ? true : false}
                helperText={
                  useForm.formState.errors[field.name]
                    ? useForm.formState.errors[field.name].message
                    : null
                }
                InputProps={{
                  placeholder: `${t("Optional")}`,
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>{`${t("Religion")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="idReligion"
            control={useForm.control}
            render={({field, fieldState}) => (
              <TextFieldTheme
                {...field}
                select
                error={fieldState.error? true: false}
                helperText={fieldState.error? fieldState.error.message: null}
                SelectProps={{
                  displayEmpty: true,
                }}
              >
                <MenuItem value="">{t("NotSpecified")}</MenuItem>
                <MenuItem value="1">{t("Religion_Code.buddha")}</MenuItem>
                <MenuItem value="2">{t("Religion_Code.islamic")}</MenuItem>
                <MenuItem value="3">{t("Religion_Code.christ")}</MenuItem>
                <MenuItem value="4">{t("Religion_Code.hinduism")}</MenuItem>
                <MenuItem value="5">{t("Religion_Code.sikhism")}</MenuItem>
              </TextFieldTheme>
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>{`${t("MaritalStatus")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="maritalStatus"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                InputProps={{
                  endAdornment: field.value ? (
                    <IconButton
                      className="clear-button"
                      onClick={() => {
                        field.onChange("");
                      }}
                    >
                      <ClearIcon />
                    </IconButton>
                  ) : null,
                  placeholder: `${t("Optional")}`,
                }}
                error={useForm.formState.errors[field.name] ? true : false}
                helperText={
                  useForm.formState.errors[field.name]
                    ? useForm.formState.errors[field.name].message
                    : null
                }
                select
                // InputProps={{
                //   placeholder: "(ไม่บังคับ)"
                // }}
              >
                <MenuItem value="โสด">{`${t("Single")}`}</MenuItem>
                <MenuItem value="สมรส">{`${t("Married")}`}</MenuItem>
                <MenuItem value="หม้าย">{`${t("Widowed")}`}</MenuItem>
                <MenuItem value="หย่า">{`${t("Divorced")}`}</MenuItem>
              </TextFieldTheme>
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>{`${t("PassportNumber")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="passportNumber"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                error={useForm.formState.errors[field.name] ? true : false}
                helperText={
                  useForm.formState.errors[field.name]
                    ? useForm.formState.errors[field.name].message
                    : null
                }
                InputProps={{
                  placeholder: `${t("Optional")}`,
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>{`${t("WorkPermitNumber")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="workPermitNumber"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                error={useForm.formState.errors[field.name] ? true : false}
                helperText={
                  useForm.formState.errors[field.name]
                    ? useForm.formState.errors[field.name].message
                    : null
                }
                InputProps={{
                  placeholder: `${t("Optional")}`,
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>{`${t("EmergencyContact")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="emergencyContact"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                error={useForm.formState.errors[field.name] ? true : false}
                helperText={
                  useForm.formState.errors[field.name]
                    ? useForm.formState.errors[field.name].message
                    : null
                }
                InputProps={{
                  placeholder: `${t("Optional")}`,
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>{`${t("EmergencyContactNumber")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="emergencyPhone"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                InputProps={{
                  inputComponent: NumberFormatTheme,
                  placeholder: `${t("Optional")}`,
                }}
                inputProps={{
                  format: (() => {
                    if (field.value.length >= 7) {
                      return "###-###-####";
                    } else if (field.value.length >= 4) {
                      return "###-####";
                    } else {
                      return "####";
                    }
                  })(),
                  allowNegative: false,
                  allowLeadingZeros: true,
                  value: field.value,
                  onValueChange: (values) => {
                    const { value } = values;
                    field.onChange(value);
                  },
                }}
                onChange={() => {}}
                error={useForm.formState.errors[field.name] ? true : false}
                helperText={
                  useForm.formState.errors[field.name]
                    ? useForm.formState.errors[field.name].message
                    : null
                }
                // InputProps={{
                //   placeholder: "(ไม่บังคับ)"
                // }}
              />
            )}
          />
        </Grid>

        <Grid item xs={4}>
          <Typography>{`${t("EmergencyRelationship")}`}</Typography>
        </Grid>
        <Grid item xs={8}>
          <Controller
            name="emergencyRelationship"
            control={useForm.control}
            render={({ field }) => (
              <TextFieldTheme
                {...field}
                error={useForm.formState.errors[field.name] ? true : false}
                helperText={
                  useForm.formState.errors[field.name]
                    ? useForm.formState.errors[field.name].message
                    : null
                }
                InputProps={{
                  placeholder: `${t("Optional")}`,
                }}
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default BasicInfo;
