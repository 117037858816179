import React, { useEffect, useMemo, useState } from "react";
import { Avatar, Box, Typography, Grid, styled, TextField, Popper, CircularProgress, circularProgressClasses } from "@mui/material";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import DatePickerCustom from "../../../shared/date/datePicker";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import ArticleIcon from '@mui/icons-material/Article';

import ButtonBlue from "../../../shared/general/ButtonBlue";
import TableCustom from "../../../shared/tableCustom";

import { getEstimatePerformanceEmployeesStatus, getEmployeesRoleOneTwo } from "../../../../../actions/employee";
import DialogEstimatePerformance from "./DialogEstimatePerformance";
import { getEstimatePerformanceResult } from "../../../../../actions/estimatePerformance";


const StyledHeadingPage = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 16,
});

const StyledBoxSearch = styled(Box)({
    marginTop: 22,
    "& .label": {
        fontWeight: 600,
        fontSize: 14,
        marginBottom: 8,
    },
});

const StyledWrapFilter = styled("div")({
    width: "100%",
    display: "flex",
    alignItems: "center",
    marginBottom: 36,
    ["@media (min-width: 0px)"]: {
        flexDirection: "column",
    },
    ["@media (min-width: 900px)"]: {
        flexDirection: "row",
    },
    "& .search-name, .search-date": {
        width: "100%",
    },
    "& .wrap-search-action": {
        marginTop: 50,
        display: "flex",
        justifyContent: "flex-start",
        "& .btn-export": {
            marginLeft: 8,
        },
    },
});


const StyledAutocomplete = styled(Autocomplete)({
    width: "100%",
    border: 0,
    "& .MuiFilledInput-root": {
        backgroundColor: "#919eab14",
        height: 56,
        padding: "0px 12px",
        borderRadius: 8,
        "&.Mui-focused": {
            backgroundColor: "#919eab14",
        },
        "& .MuiInputAdornment-root": {
            width: 32,
            marginTop: "0!important",
            fontSize: 24,
            color: "#919EAB",
            "& i": {
                marginRight: 8,
            },
        },
        "& .MuiAutocomplete-endAdornment": {
            "& .MuiButtonBase-root": {
                fontSize: 14,
                width: 22,
                height: 22,
            },
        },
        "&:hover": {
            backgroundColor: "#919eab29",
            "&:before": {
                border: "none !important",
            },
        },
        "&::after": {
            border: "none",
        },
        "&::before": {
            border: "none",
        },
    },
});

const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: "border-box",
        [`& .${autocompleteClasses.option}`]: {
            "&:hover": {
                backgroundColor: "#f6f7f8",
                margin: "0 8px",
                borderRadius: 8,
                paddingLeft: 8,
            },
        },
    },
});


const EstimatePerformanceStatus = () => {
    const { result: employeesEstimatePerformanceStatus } = useSelector((state) => state.employeesEstimatePerformanceStatus);
    const { result: employeesRoleOneTwo } = useSelector((state) => state.employeesRoleOneTwo);
    const { result: estimatePerformanceAnswer } = useSelector((state) => state.estimatePerformanceAnswer);

    const [inputSearch, setInputSearch] = useState(null);
    const [data, setData] = useState(null);
    const [openEducation, setOpenEducation] = useState(false);
    const [idEducation, setIdEducation] = useState(null);
    const [selectedEmployee, setSelectedEmployee] = useState(null);
    const [typeDialog, setTypeDialog] = useState("Add");
    const [detail, setDetail] = useState(null);
    const [value, setValue] = useState(null);

    const statusShow = (row) => {
        const currentDate = dayjs()
        if (row.estimatePerformanceApproved === 0 || currentDate.diff(row.updateEstimatePerformance, 'year') > 0) {
            return <div style={{ display: "flex", justifyContent: "center", align: "center" }}><ButtonBlue sx={{ backgroundColor: "#FFFF00", color: "#000000" }} variant="contained">รอประเมิน</ButtonBlue></div>
        } else if (row.estimatePerformanceApproved === 1 && currentDate.diff(row.updateEstimatePerformance, 'year') <= 0) {
            return <div style={{ display: "flex", justifyContent: "center", align: "center" }}><ButtonBlue sx={{ backgroundColor: "#90EE90", color: "#000000" }} variant="contained" >ประเมินแล้ว</ButtonBlue></div>
        }
    }

    const dispatch = useDispatch();

    const handleOpenEducation = (type, idEdu) => {
        if (type === "Add") {
            setTypeDialog("Add");
        } else {
            setTypeDialog("Edit");
        }
        if (idEdu) {
            setIdEducation(idEdu);
        }
        setOpenEducation(true);
    };

    const handleClose = () => {
        setOpenEducation(false);
        setIdEducation(null);
    };

    const handleEachDetail = (row) => {
        const currentDate = dayjs()
        if (row.estimatePerformanceApproved === 1 && currentDate.diff(row.updateEstimatePerformance, 'year') <= 0) {
            var temp2 = estimatePerformanceAnswer.find((value) => (value.idEmployees === row.idEmployees && currentDate.year() - dayjs(row.createdDate).year() <= 0))
            setDetail(temp2)
            console.log("detail",temp2)
        }
        setValue(row)
    }

    const handleDisable = (row) => {
        const currentDate = dayjs()
        if (row.estimatePerformanceApproved === 0 || currentDate.diff(row.updateEstimatePerformance, 'year') > 0) {
            return true
        } else {
            return false
        }
    }

    const columns = [
        {
            name: "ชื่อ-สกุล",
            minWidth: "200px",
            width: "200px",
            cellRender: (row) => (
                <Box display="flex" alignItems="center">
                    <Avatar
                        sx={{
                            marginRight: "8px",
                            width: 40,
                            height: 40,
                            "& img": { objectFit: "contain" },
                        }}
                        src={row.imageURL}
                    />
                    <Box textAlign="center" flexGrow={1}>
                        <Typography>
                            {row.firstname_TH} {row.lastname_TH}
                        </Typography>
                        <Typography color="text.third" fontSize="14px">
                            {row.positionName}
                        </Typography>
                    </Box>
                </Box>
            ),
        },
        {
            name: "วันที่ประเมิน",
            headerTextAlign: "center",
            minWidth: "150px",
            width: "150px",
            cellRender: (row) => (
                <Box sx={{ textAlign: "center" }}>
                    <Typography>
                        {row.updateEstimatePerformance ? dayjs(row.updateEstimatePerformance).format("D MMM BBBB") : "-"}
                    </Typography>
                </Box>
            ),
        },
        {
            name: "สถานะ",
            headerTextAlign: "center",
            minWidth: "120px",
            width: "120px",
            cellRender: (row) => (
                statusShow(row)

            ),
        },
        {
            name: "รายละเอียด",
            headerTextAlign: "center",
            minWidth: "100px",
            width: "100px",
            cellRender: (row) => (
                <div style={{ display: "flex", justifyContent: "center", align: "center" }}>
                    <ButtonBlue
                        variant="contained"
                        onClick={() => { handleEachDetail(row); handleOpenEducation(); }}
                        disabled={handleDisable(row)}

                    >
                        <ArticleIcon />
                    </ButtonBlue>
                </div>
            ),
        },
    ];

    useEffect(() => {
        dispatch(getEstimatePerformanceEmployeesStatus());
        dispatch(getEmployeesRoleOneTwo());
        dispatch(getEstimatePerformanceResult());
        setData(employeesEstimatePerformanceStatus);
    }, []);

    const handleClickSearch = () => {
        var temp = [...employeesEstimatePerformanceStatus];
        var serchName = employeesRoleOneTwo.find((e) => e.idEmployees === selectedEmployee)
        if (inputSearch !== null) {
            var dataDateSearch = employeesEstimatePerformanceStatus.filter(
                (data) => (dayjs(data.endDate).get("month") === dayjs(inputSearch).get("month")) && (dayjs(data.endDate).get("year") === dayjs(inputSearch).get("year"))
            )
            if (selectedEmployee !== null) {
                dataDateSearch.filter((value) => (value.idEmployees === serchName.idEmployees))
                setData(name);
            }
        } else if (selectedEmployee !== null) {
            var name = temp.filter((value) => (value.idEmployees === serchName.idEmployees))
            setData(name);
        } else {
            setData(temp);
        }
    };

    const approvePercentage = useMemo(() => {
        if(employeesEstimatePerformanceStatus) {
            const numberOfApprove = employeesEstimatePerformanceStatus.filter(item => item.estimatePerformanceApproved === 1).length
            const approvePercentage = Math.round(numberOfApprove / employeesEstimatePerformanceStatus.length * 100)
            return approvePercentage 
        }
    }, [employeesEstimatePerformanceStatus])

    return (
        <Box marginTop={"30px"}>
            <StyledHeadingPage>
                <Typography variant="h6" fontSize={"1.5rem"}>สถานะประเมินผลงานประจำปี</Typography>
                <div style={{ display: "flex", alignItems: "center", gap: 8 }}>  
                    <Typography>ตอบแบบประเมินแล้ว</Typography>
                    <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                        <CircularProgress
                            variant="determinate"
                            sx={{
                                color: (theme) => theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
                            }}
                            size={50}
                            value={100}
                        />
                        <CircularProgress
                            variant="determinate"
                            sx={{
                                color: '#1a90ff',
                                position: 'absolute',
                                left: 0,
                            }}
                            size={50}
                            value={approvePercentage}
                        />
                        <Box
                            sx={{
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                position: 'absolute',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Typography
                                variant="caption"
                                component="div"
                                color="text.secondary"
                                fontSize={16}
                            >
                                {`${approvePercentage}%`}
                            </Typography>
                        </Box>
                    </Box>
                </div>
            </StyledHeadingPage>
            <StyledWrapFilter>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={4} sm={4}>
                        <StyledBoxSearch>
                            <Typography className="label" color="text.third">
                                วันที่ค้นหา
                            </Typography>
                            <div className="search-date">
                                <DatePickerCustom
                                    inputFormat="MMMM YYYY"
                                    value={inputSearch}
                                    name="start"
                                    views={["year", "month"]}
                                    openTo="month"
                                    onChange={(newValue) => {
                                        setInputSearch(newValue);
                                    }}
                                />
                            </div>
                        </StyledBoxSearch>
                    </Grid>
                    <Grid item xs={4} sm={4}>
                        <StyledBoxSearch>
                            <Typography className="label" color="text.third">
                                ค้นหาพนักงาน
                            </Typography>
                            <div className="search-name">
                                {employeesRoleOneTwo && (
                                    <StyledAutocomplete
                                        options={employeesRoleOneTwo}
                                        onChange={(event, newValue) => {
                                            if (newValue) {
                                                setSelectedEmployee(newValue.idEmployees);
                                            } else {
                                                setSelectedEmployee(null);
                                            }
                                        }}
                                        popupIcon={<i className="fa-light fa-chevron-down"></i>}
                                        getOptionLabel={(option) => `${option.firstname_TH} ${option.lastname_TH}`}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="filled"
                                                placeholder="เลือกพนักงาน"
                                            />
                                        )}
                                        PopperComponent={StyledPopper}
                                        noOptionsText={"ไม่พบข้อมูล"}
                                    />
                                )}
                            </div>
                        </StyledBoxSearch>
                    </Grid>

                    <Grid item xs={4} sm={4}>
                        <StyledBoxSearch>
                            <div className="wrap-search-action">
                                <div>
                                    <ButtonBlue
                                        variant="contained"
                                        startIcon={<SearchIcon />}
                                        onClick={() => { handleClickSearch(); }}
                                    >
                                        ค้นหา
                                    </ButtonBlue>
                                </div>
                            </div>
                        </StyledBoxSearch>
                    </Grid>


                </Grid>


            </StyledWrapFilter>
            <TableCustom
                columns={columns}
                rows={
                    data ? data : []
                }
            />
            {openEducation && (
                <DialogEstimatePerformance
                    open={openEducation}
                    handleClose={handleClose}
                    idEdu={idEducation}
                    type={typeDialog}
                    value={value}
                    detail={detail}
                />
            )}
        </Box>
    );
};

export default EstimatePerformanceStatus;
