import React, { useCallback, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Chip,
  Container,
  Grid,
  styled,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import DownloadIcon from "@mui/icons-material/Download";
import { useDispatch } from "react-redux";
import DataGrid, {
  Column,
  FilterRow,
  Pager,
} from "devextreme-react/data-grid";
import { utils, writeFile } from "xlsx";
import { getCarlibrateDepartment } from "../../../../../actions/okrs";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import Gauge from "./gauge";
import { getUserFullName, getUserLastName, getUserFirstName, getUserPosition } from "../../../../../utils/userData";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiGrid-container": {
      marginBottom: 0,
    },
    "& .MuiCardContent-root": {
      padding: 0,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 16,
    },
    "& .MuiOutlinedInput-input": {
      padding: "16px 14px",
    },
    "& .MuiTableCell-root": {
      paddingTop: "5px",
      paddingBottom: "5px",
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none",
    },
  },
}));

const StyledWrapDataGrid = styled("div")({
  padding: "0 24px 24px 24px",
  '& .column-info[role="columnheader"]': {
    backgroundColor: "#283593",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-addition[role="columnheader"]': {
    backgroundColor: "#009688",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-info[role="gridcell"]': {
    verticalAlign: "middle !important",
  },
  '& .column-total-addition[role="gridcell"]': {
    verticalAlign: "middle !important",
  },
});

const ContainerStyled = styled(Container)({
  paddingTop: "100px",
  height: "100dvh",
});

function CalibrateDepartment() {
  const {t} = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  const [calibrateData, setCalibrateData] = useState(null);
  const [grades, setGrades] = useState(null);
  const [departmentName, setDepartmentName] = useState("");

  const { id } = useParams();
  const history = useHistory();

  const exportFile = useCallback(() => {
    const dataExport = calibrateData.map((item) => ({
      idEmployees: item.idEmployees,
      firstname: getUserFirstName(item),
      lastname: getUserLastName(item),
      position: getUserPosition(item),
      department: departmentName,
      q1: item.grades.A,
      q2: item.quarter2,
      q3: item.quarter3,
      q4: item.quarter4,
      average: item.average,
      grade: item.grade,
    }));

    const ws = utils.json_to_sheet(dataExport);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    writeFile(wb, `${t("Calibrate")}.xlsx`);
  }, [calibrateData]);

  useEffect(() => {
    let isMounted = true;
    dispatch(getCarlibrateDepartment()).then((res) => {
      if (isMounted) {
        if (res.status === 200) {
          const selectDepartment = res.data.find((item) => item.idDepartment == id);
          if (selectDepartment.employees && selectDepartment.grades) {
            setGrades(selectDepartment.grades);
            setDepartmentName(selectDepartment.departmentName);
            setCalibrateData(selectDepartment.employees);
          } else {
            history.replace("/okrs/calibrate")
          }
        }
      }
    });

    return () => {
      isMounted = false;
    };
  }, [id]);

  return (
    <ContainerStyled>
      <div className={classes.root}>
        <div className="card-section">
          <div className="header-section" style={{ marginBottom: 0 }}>
            <Grid container>
              <Grid item>
                <h3 className="header-topic">{departmentName}</h3>
              </Grid>
            </Grid>
          </div>
          {grades && (
            <Box sx={{ width: 500, height: 500, marginLeft: "auto", marginRight: "auto" }}>
              <Gauge grades={grades} />
            </Box>
          )}
          {calibrateData && (
            <>
              <StyledWrapDataGrid>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginBottom: 8,
                  }}
                >
                  <Button
                    variant="contained"
                    size="small"
                    sx={{ backgroundColor: "#4f65df" }}
                    disableElevation
                    startIcon={<DownloadIcon />}
                    onClick={exportFile}
                  >
                    Excel
                  </Button>
                </div>
                <DataGrid
                  dataSource={calibrateData}
                  showRowLines={true}
                  showColumnLines={false}
                >
                  <FilterRow visible={true} />
                  <Column
                    dataField={t("EmployeeID")}
                    caption={t("EmployeeID")}
                    visible={false}
                  />
                  <Column
                    dataField={t("FullName")}
                    caption={t("FullName")}
                    cssClass="column-info"
                    minWidth={240}
                    allowSorting={false}
                    cellRender={(cellData) => (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 8,
                        }}
                      >
                        <Avatar src={cellData.data.imageURL} />
                        {getUserFullName(cellData.data)}
                      </div>
                    )}
                  />
                  <Column
                    dataField={t("PositionName")}
                    caption={t("PositionName")}
                    cssClass="column-info"
                    allowFiltering={false}
                    allowSorting={false}
                  />
                  <Column
                    dataField="quarter1"
                    caption="Q1"
                    dataType={"number"}
                    cssClass="column-info"
                    allowFiltering={false}
                    allowSorting={false}
                  />
                  <Column
                    dataField="quarter2"
                    caption="Q2"
                    dataType={"number"}
                    cssClass="column-info"
                    allowFiltering={false}
                    allowSorting={false}
                  />
                  <Column
                    dataField="quarter3"
                    caption="Q3"
                    dataType={"number"}
                    cssClass="column-info"
                    allowFiltering={false}
                    allowSorting={false}
                  />
                  <Column
                    dataField="quarter4"
                    caption="Q4"
                    dataType={"number"}
                    cssClass="column-info"
                    allowFiltering={false}
                    allowSorting={false}
                  />
                  <Column
                    dataField={t("Average")}
                    dataType={"number"}
                    cssClass="column-info"
                    allowFiltering={false}
                    sortOrder="desc"
                  />
                  <Column 
                    dataField={t("Grade")}
                    cssClass="column-total-addition"
                    filterOperations={["="]} 
                    alignment={"center"}
                    cellRender={(cellData) => (
                      <Chip 
                        label={cellData.value}
                        sx={{ backgroundColor: "#4f65df", color: "#ffffff" }}
                      />
                    )}
                  />
                  <Pager allowedPageSizes={[10, 25, 50, 100]} showPageSizeSelector={true} />
                </DataGrid>
              </StyledWrapDataGrid>
            </>
          )}
        </div>
      </div>
    </ContainerStyled>
  );
}

export default CalibrateDepartment;
