import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import {
  Container,
  Tab,
  Tabs,
  Table,
  TablePagination,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Badge,
  Box,
  CardContent,
  Button,
  Chip,
  Autocomplete,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import ButtonBlue from "../../shared/general/ButtonBlue";

import ListAltIcon from "@mui/icons-material/ListAlt";
import CardStyle from "../../shared/general/Card";
import HistoryIcon from "@mui/icons-material/History";

import { getAllExpenseByIdApprover } from "../../../../actions/expense";
import dayjs from "dayjs";
import utils from "../../../../utils";
import SubTableWithButton from "./subTable";

const StyledRoot = styled("div")({
  backgroundColor: "#FFFFFF !important",
  "& .MuiContainer-root": {
    paddingBottom: 24,
  },
  "& .head": {
    paddingTop: 8,
    marginBottom: 24,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .head-2": {
    paddingTop: 8,
    marginBottom: 24,
    marginTop: 36,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  "& .wrap-sum-amount": {
    marginBottom: 16,
  },
});

const StyledAutocomplete = styled(Autocomplete)({
  width: "100%",
  border: 0,
  "& .MuiOutlinedInput-root": {
    height: 50,
    padding: "0px 12px",
    borderRadius: 8,
    "& .MuiInputAdornment-root": {
      width: 32,
      marginTop: "0!important",
      fontSize: 24,
      color: "#919EAB",
      "& i": {
        marginRight: 8,
      },
    },
    "& .MuiAutocomplete-endAdornment": {
      "& .MuiButtonBase-root": {
        fontSize: 14,
        width: 22,
        height: 22,
      },
    },
    "&:hover": {
      "&:before": {
        border: "none !important",
      },
    },
    "&::after": {
      border: "none",
    },
    "&::before": {
      border: "none",
    },
  },
});

const StyledDivTab = styled("div")(({ theme }) => ({
  width: "100%",
  padding: "0 8px",
  marginBottom: 16,
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  fontSize: 16,
  "& .MuiBadge-badge": {
    right: -16,
    top: 12,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    backgroundColor: "#e46a76",
  },
}));

const StyledCard = styled(Card)({
  padding: "16px 32px",
  boxShadow: "rgb(90 114 123 / 11%) 0px 7px 30px 0px",
  transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  borderRadius: 20,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  textAlign: "center",
  ["@media only screen and (min-width:768px)"]: {
    height: 104,
    flexDirection: "column-reverse",
  },
  ["@media only screen and (min-width:992px)"]: {
    height: 80,
    flexDirection: "row",
  },
  "& .MuiTypography-h6": {
    fontSize: 22,
    lineHeight: 1.25,
  },
  "& .MuiTypography-body2": {
    fontSize: 16,
  },
  "&.expense": {
    backgroundColor: "#d0fce0",
    "& .MuiTypography-root": {
      color: "#045966",
    },
  },
  "&.advance": {
    backgroundColor: "#ff563029",
    "& .MuiTypography-root": {
      color: "#b71d18",
    },
  },
  "& .MuiCardContent-root": {
    padding: 24,
  },
});

const StyledTable = styled(Table)({
  "& .MuiTableCell-root": {
    textAlign: "center",
    "&.nothing": {
      padding: 32,
    },
  },
  "& .tableCellHead": {
    border: "none",
    backgroundColor: "#f3f6f8",
  },
  "& .tableCellHead:first-of-type": {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  "& .cellAction": {
    width: 48,
    padding: "0px 0px 0px 4px",
  },
  "& .tableCellHead-document-no": {
    width: 140,
  },
  "& .tableCellHead-document-name": {
    textAlign: "center",
  },
  "& .tableCellHead-document-amount": {
    width: 120,
  },
  "& .tableCellHead-document-status": {
    width: 120,
  },
  "& .tableCellHead:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
  "& .tableCellBody-document-name": {
    textAlign: "left",
  },
  "& .tableCellBody-center": {
    textAlign: "center",
  },

  "& .tableCellWeekend": {
    color: "#959595",
    fontStyle: "oblique",
  },
  "& .cellStatusAction": {
    textAlign: "left",
    "& .wrap-status": {
      display: "flex",
      "& .item": {
        marginRight: 4,
      },
    },
  },
  "& .cellRequest": {},
  "& .cellStartStop": {
    minWidth: 80,
    "& .MuiButtonBase-root": {
      marginRight: 2,
    },
  },
  "& .rowLink": {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "rgb(243, 246, 248, 0.5)",
    },
  },
});

const StyledChipTab = styled(Chip)({
  display: "flex",
  flexDirection: "column",
  width: 20,
  height: 20,
  marginLeft: 5,
  backgroundColor: "orangered",
  color: "#fff",
  "& .MuiChip-label": {
    display: "block",
    whiteSpace: "normal",
    textAlign: "center",
  },
});

const DetailButton = styled(Button)(({ variant, theme }) => ({
  color: "#46cbe2",
  backgroundColor: "transparent",
  border: "solid",
  borderColor: "#46cbe2",
  paddingLeft: 16,
  paddingRight: 16,
  paddingTop: 4,
  paddingBottom: 4,
  borderRadius: 18,
  "&:hover": {
    color: "#ffffff",
    backgroundColor: "#46cbe2",
  },
}));

const StyledTabs = styled(Tabs)({
  "& .Mui-selected": {
    color: "#46cbe2 !important",
  },
  "& .MuiTabs-indicator": {
    backgroundColor: "#46cbe2 !important",
  },
  "& .fal": {
    marginRight: 8,
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ExpensePage = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [statusTab, setStatusTab] = React.useState(0);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: expense } = useSelector((state) => state.expense);

  useEffect(() => {
    dispatch(getAllExpenseByIdApprover());
  }, [userProfile]);

  const handleApproveTabsChange = (event, newValue) => {
    setStatusTab(newValue);
  };

  const handleViewDetail = (idExpense, expenseType, idEmployees) => {
    if (idExpense) {
      history.push(
        `/expense/detail/${expenseType}/${idExpense}/${idEmployees}/edit`
      );
    }
  };

  return (
    <StyledRoot className="page">
      <Container maxWidth="lg">
        <div className="head">
          <Typography variant="h4">
            {t("RequestApprovalDisburseExpenses")}
          </Typography>
          <div>
            <ButtonBlue
              variant="contained"
              startIcon={<HistoryIcon />}
              onClick={() => history.push("/expense/history")}
            >
              {t("HistoryApprovedList")}
            </ButtonBlue>
          </div>
        </div>

        <CardStyle>
          {expense && (
            <CardContent>
              <StyledDivTab>
                <StyledTabs
                  value={statusTab}
                  onChange={handleApproveTabsChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  centered
                >
                  {"ServiceAndGoods" in expense && (
                    <Tab
                      style={{ minWidth: 180 }}
                      label={
                        <StyledBadge color="secondary">
                          {t("ProductService")}
                          {expense.ServiceAndGoods &&
                          expense.ServiceAndGoods.length &&
                          expense.ServiceAndGoods.length > 0 ? (
                            <StyledChipTab
                              label={expense.ServiceAndGoods.length}
                            />
                          ) : (
                            <span></span>
                          )}
                        </StyledBadge>
                      }
                      {...a11yProps(0)}
                    />
                  )}

                  {"Allowance" in expense && (
                    <Tab
                      style={{ minWidth: 180 }}
                      label={
                        <StyledBadge color="secondary">
                          {t("Allowance")}
                          {expense.Allowance &&
                          expense.Allowance.length &&
                          expense.Allowance.length > 0 ? (
                            <StyledChipTab label={expense.Allowance.length} />
                          ) : (
                            <span></span>
                          )}
                        </StyledBadge>
                      }
                      {...a11yProps(1)}
                    />
                  )}
                  {"Mileage" in expense && (
                    <Tab
                      style={{ minWidth: 180 }}
                      label={
                        <StyledBadge color="secondary">
                          Mileage
                          {expense.Mileage &&
                          expense.Mileage.length &&
                          expense.Mileage.length > 0 ? (
                            <StyledChipTab label={expense.Mileage.length} />
                          ) : (
                            <span></span>
                          )}
                        </StyledBadge>
                      }
                      {...a11yProps(2)}
                    />
                  )}
                  {"FlexCoin" in expense && (
                    <Tab
                      style={{ minWidth: 180 }}
                      label={
                        <StyledBadge color="secondary">
                          FlexCoin
                          {expense.FlexCoin &&
                          expense.FlexCoin.length &&
                          expense.FlexCoin.length > 0 ? (
                            <StyledChipTab label={expense.FlexCoin.length} />
                          ) : (
                            <span></span>
                          )}
                        </StyledBadge>
                      }
                      {...a11yProps(3)}
                    />
                  )}
                </StyledTabs>
              </StyledDivTab>

              <Box className="wrap-table">
                <TabPanel value={statusTab} index={0}>
                  <SubTableWithButton
                    data={
                      expense.ServiceAndGoods ? expense.ServiceAndGoods : []
                    }
                    handleClick={handleViewDetail}
                  ></SubTableWithButton>
                </TabPanel>
                <TabPanel value={statusTab} index={1}>
                  <SubTableWithButton
                    data={expense.Allowance ? expense.Allowance : []}
                    handleClick={handleViewDetail}
                  ></SubTableWithButton>
                </TabPanel>
                <TabPanel value={statusTab} index={2}>
                  <SubTableWithButton
                    data={expense.Mileage ? expense.Mileage : []}
                    handleClick={handleViewDetail}
                  ></SubTableWithButton>
                </TabPanel>
                <TabPanel value={statusTab} index={3}>
                  <SubTableWithButton
                    data={expense.FlexCoin ? expense.FlexCoin : []}
                    handleClick={handleViewDetail}
                  ></SubTableWithButton>
                </TabPanel>
              </Box>
            </CardContent>
          )}
        </CardStyle>
      </Container>
    </StyledRoot>
  );
};

export default ExpensePage;
