import React from "react";
import { Box, Grid, Icon, styled, Typography } from "@mui/material";
import CardData from "../../shared/dashboard/CardData";
import Utils from "../../../../utils";
import ChartAreaCustom from "../../shared/dashboard/ChartArea";
import dayjs from "dayjs";
import CardDashboard from "../../shared/dashboard/CardDashboard";
import { useSelector } from "react-redux";
//Translator TH-EN
import { useTranslation } from "react-i18next";

const StyledRoot = styled(Box)({});

const CostPanel = (props) => {
  const { month, year } = props;
  const { t, i18n } = useTranslation();

  const { result: dashboardCost } = useSelector((state) => state.dashboardCost);

  return (
    <StyledRoot>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CardData
                    title={`${t("Employees")}`}
                    dataValue={Utils.numberWithCommasWithoutDot(
                      dashboardCost.employeeTotal
                    )}
                    dataUnit={`${t("People")}`}
                    dynamicFontSize
                    titleIcon={
                      <Icon
                        baseClassName="fal"
                        className="fa-users"
                        color="primary"
                        style={{ display: "inline-table" }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CardData
                    title={`${t("SalaryTotal")}`}
                    dataValue={Utils.numberWithCommas(
                      dashboardCost.salaryTotal
                    )}
                    dataUnit={`${t("Baht")}`}
                    allowedPermission={dashboardCost.canManagerAccessPayroll}
                    dynamicFontSize
                    titleIcon={
                      <Icon
                        className="fal fa-sack-dollar"
                        color="warning"
                        style={{ display: "inline-table", color: "#ffcd38" }}
                      />
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CardData
                    title={`${t("OTTotal")}`}
                    dataValue={Utils.numberWithCommas(
                      Object.keys(dashboardCost.otTotal).reduce(
                        (a, b) => a + dashboardCost.otTotal[b].payTotal,
                        0
                      )
                    )}
                    dataUnit={`${t("Baht")}`}
                    allowedPermission={dashboardCost.canManagerAccessPayroll}
                    dynamicFontSize
                    titleIcon={
                      <Icon
                        className="fal fa-hand-holding-usd"
                        color="warning"
                        style={{ display: "inline-table", color: "#ffcd38" }}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} lg={6}>
              <CardData
                title={`${t("TotalPay")}`}
                dataValue={Utils.numberWithCommas(
                  Number(
                    (
                      Object.keys(dashboardCost.otTotal).reduce(
                        (a, b) => a + dashboardCost.otTotal[b].payTotal,
                        0
                      ) + dashboardCost.salaryTotal
                    ).toFixed(2)
                  )
                )}
                dataUnit={`${t("Baht")}`}
                allowedPermission={dashboardCost.canManagerAccessPayroll}
                dynamicFontSize
                titleIcon={
                  <Icon
                    className="fal fa-search-dollar"
                    color="warning"
                    style={{ display: "inline-table", color: "#ffcd38" }}
                  />
                }
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <CardDashboard>
            <Typography marginBottom="16px">
              {`${t("SalaryTotal")}`} ({`${t("Yearly")}`} {year})
            </Typography>
            {dashboardCost.canManagerAccessPayroll? (
              <ChartAreaCustom
                series={[
                  {
                    name: `${t("SalaryTotal")}`,
                    data: dashboardCost.salaryTotalAllYear.map((s) => {
                      return {
                        x: dayjs()
                          .month(s.month - 1)
                          .format("MMM"),
                        y: s.salaryTotal,
                      };
                    }),
                  },
                ]}
              />
              ) : (
                <Box flexGrow="1" display="flex" justifyContent="center" alignItems="center">
                  <Typography color="error" fontSize="14px" align="center" margin="auto">{t("AccessDenied")}</Typography>
                </Box>
              )
            }
          </CardDashboard>
        </Grid>

        <Grid item xs={12}>
          <CardDashboard>
            <Typography marginBottom="16px">
              {`${t("OTTotal")}`} ({`${t("Yearly")}`} {year})
            </Typography>
            {dashboardCost.canManagerAccessPayroll? (
              <ChartAreaCustom
                series={[
                  {
                    name: `${t("OTTotal")}`,
                    data: dashboardCost.otTotalAllYear.map((s) => {
                      return {
                        x: dayjs()
                          .month(s.month - 1)
                          .format("MMM"),
                        y: s.payTotal,
                      };
                    }),
                  },
                ]}
              />
              ) : (
                <Box flexGrow="1" display="flex" justifyContent="center" alignItems="center">
                  <Typography color="error" fontSize="14px" align="center" margin="auto">{t("AccessDenied")}</Typography>
                </Box>
              )
            }
          </CardDashboard>
        </Grid>
      </Grid>
    </StyledRoot>
  );
};

export default CostPanel;
