import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";

import {
  Typography,
  FormControl,
  FormControlLabel,
  MenuItem,
  Switch,
  Select,
  Grid,
} from "@mui/material";

import DatePickerCustom from "../../../../shared/date/datePicker";
import ButtonBlue from "../../../../shared/general/ButtonBlue";
import MonthPanel from "./month-panel";
import { useTranslation } from "react-i18next";
import { updatePayrollDateCustom } from "../../../../../../actions/paytypes"

const StyledRoot = styled("div")({
  "&.wrap-item-data": {
    marginLeft: 16,
    marginBottom: 8,
  },
  "& .item-name": {
    fontWeight: 600,
    marginBottom: 4,
  },
});

const StyledFormControl = styled(FormControl)({
  width: "100%",
  "& .MuiOutlinedInput-root": {
    borderRadius: 8,
    "& .MuiOutlinedInput-input": {
      padding: "13.5px 14px",
    },
    "& .MuiInputBase-inputMultiline": {
      padding: 0,
    },
  },
});

const StyledFormControlLabelSwitch = styled(FormControlLabel)({
  marginRight: 0,
});

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const timePayslip = [
  "00:00",
  "01:00",
  "02:00",
  "03:00",
  "04:00",
  "05:00",
  "06:00",
  "07:00",
  "08:00",
  "09:00",
  "10:00",
  "11:00",
  "12:00",
  "13:00",
  "14:00",
  "15:00",
  "16:00",
  "17:00",
  "18:00",
  "19:00",
  "20:00",
  "21:00",
  "22:00",
  "23:00",
];

const RoundOne = (props) => {
  const {t,i18n} = useTranslation();
  const { dataForm, handleOnChangeData } = props;
  const dispatch = useDispatch();
  const today = dayjs().toDate();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: payrollSetting } = useSelector(
    (state) => state.payrollSetting
  );

  const [payDateYearList, setPayDateYearList] = useState(null);

  useEffect(() => {
    let payDate = [];
    for (let index = -2; index < 3; index++) {
      payDate.push(dayjs(today).add(index, "year").format("YYYY"));
    }

    setPayDateYearList(payDate);
  }, []);

  const [dateCustom, setDateCustom] = useState(null);

  useEffect(() => {
    if (payrollSetting && userProfile) {
      let temp = [];
      for (let index = 0; index < 12; index++) {
        let find = payrollSetting.payrollDateCustom.find(
          (x) => x.year === 2024 && x.month === index
        );
        if (find) {
          temp.push(find);
        } else {
          temp.push({
            year: 2024,
            round: 1,
            month: index,
            payDate: null,
            payslipDate: null,
            payslipTime: payrollSetting.firstPayslipTime,
            idCompany: userProfile.idCompany,
          });
        }
      }
      console.log("temp (MonthPanel): ", temp);
      setDateCustom(temp);
    }
  }, [payrollSetting]);

  const handleChangeDateSetting = (data) => {
    let temp = [...dateCustom];
    temp[data.index][data.name] = data.value;
    setDateCustom(temp);
  };

  const handleOnClickSaveCustomDate = async () => {
    console.log("dateCustom: ", dateCustom);
    const result = await dispatch(updatePayrollDateCustom(dateCustom));

    
  }

  return (
    <StyledRoot className="wrap-item-data">
      <Grid container spacing={4}>
        <Grid item xs={12} sm={3}>
          <Typography className="item-name">{t("DayCutOff")}</Typography>
          <StyledFormControl fullWidth>
            <Select
              inputProps={{ readOnly: true }}
              MenuProps={MenuProps}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="firstCutRoundDate"
              value={dataForm.firstCutRoundDate}
            >
              <MenuItem key={99} value={-1}>
                {t("LastDayOfMonth")}
              </MenuItem>
              {[...Array(29).keys()].map((item) => (
                <MenuItem key={item} value={item + 1}>
                  {item + 1}
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <div className="wrap-label-switch">
            <Typography className="item-name">{t("cutOffApproval")}</Typography>
            <StyledFormControlLabelSwitch
              checked={dataForm.firstCutOffNextDay}
              control={<Switch size="small" />}
              label={t("NextMonth")}
              labelPlacement="nextMonth"
            />
          </div>

          <StyledFormControl fullWidth>
            <Select
              inputProps={{ readOnly: true }}
              MenuProps={MenuProps}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="firstCutOff"
              value={dataForm.firstCutOff}
            >
              {[...Array(31).keys()].map((item) => (
                <MenuItem key={item} value={item + 1}>
                  {item + 1}
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <div className="wrap-label-switch">
            <Typography className="item-name">{t("overtimeCalculation")}</Typography>
          </div>

          <StyledFormControl fullWidth>
            <Select
              MenuProps={MenuProps}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="firstRoundOt"
              value={dataForm.firstRoundOt}
            >
              <MenuItem key={1} value={"now"}>
                {t("cutOffPeriod")}
              </MenuItem>
              <MenuItem key={2} value={"previous​"}>
                {t("oneMonthBack")}
              </MenuItem>
            </Select>
          </StyledFormControl>
        </Grid>
        <Grid item xs={12} sm={3}>
          <div className="wrap-label-switch">
            <Typography className="item-name">
              {t("latenessCalculation")}/{t("Absent")}/{t("AppMenu.Leave")}
            </Typography>
          </div>

          <StyledFormControl fullWidth>
            <Select
              inputProps={{ readOnly: true }}
              MenuProps={MenuProps}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="firstRoundLateAbsentLeave"
              value={dataForm.firstRoundLateAbsentLeave}
            >
              <MenuItem key={1} value={"now"}>
                {t("cutOffPeriod")}
              </MenuItem>
              <MenuItem key={2} value={"previous​"}>
                {t("oneMonthBack")}
              </MenuItem>
            </Select>
          </StyledFormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="wrap-label-switch">
            <Typography className="item-name">{t("paySlipDateFormat")}</Typography>
          </div>
          <StyledFormControl fullWidth>
            <Select
              MenuProps={MenuProps}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="fitstPaysilpPublicType"
              value={dataForm.fitstPaysilpPublicType}
              onChange={handleOnChangeData}
            >
              <MenuItem key={"1"} value={"auto"}>
                {t("automatic")}
              </MenuItem>
              <MenuItem key={"2"} value={"custom"}>
                {t("Custom")}
              </MenuItem>
            </Select>
          </StyledFormControl>
        </Grid>
        {dataForm.fitstPaysilpPublicType === "custom" && (
          <Fragment>
            <Grid item xs={12} sm={3}>
              <div className="wrap-label-switch">
                <Typography className="item-name">{t("annualSettings")}</Typography>
              </div>
              <StyledFormControl fullWidth>
                <Select
                  MenuProps={MenuProps}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="settingYear"
                  value={dataForm.settingYear}
                  onChange={handleOnChangeData}
                >
                  <MenuItem key={"1"} value={2024}>
                    2024
                  </MenuItem>
                </Select>
              </StyledFormControl>
            </Grid>
            <Grid item xs={12} sm={3}>
              <Typography className="item-name">
                {t("paySlipTime")}
              </Typography>
              <StyledFormControl fullWidth>
                <Select
                  inputProps={{ readOnly: true }}
                  MenuProps={MenuProps}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="firstPayslipTime"
                  value={dataForm.firstPayslipTime}
                >
                  {timePayslip.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </StyledFormControl>
            </Grid>
            <Grid item xs={12}>
              {dateCustom.map((item) => (
                <MonthPanel
                  item={item}
                  handleOnChange={handleChangeDateSetting}
                />
              ))}
            </Grid>
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <ButtonBlue variant="contained" onClick={handleOnClickSaveCustomDate}>SAVE</ButtonBlue>
              </div>
            </Grid>
          </Fragment>
        )}

        {dataForm.fitstPaysilpPublicType === "auto" && (
          <Fragment>
            <Grid item xs={12} sm={6}>
              {dataForm.fitstPaysilpPublicType === "auto" ? (
                <Fragment>
                  <div className="wrap-label-switch">
                    <Typography className="item-name">
                      {t("salaryPaymentDate")}
                    </Typography>
                    <StyledFormControlLabelSwitch
                      checked={dataForm.payslipNextMonth}
                      control={<Switch size="small" />}
                      label= {t("NextMonth")}
                      labelPlacement="nextMonth"
                    />
                  </div>
                  <StyledFormControl fullWidth>
                    <Select
                      MenuProps={MenuProps}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="firstPayDate"
                      value={dataForm.firstPayDate}
                    >
                      <MenuItem key={99} value={-1}>
                        {t("LastDayOfMonth")}
                      </MenuItem>
                      {[...Array(29).keys()].map((item) => (
                        <MenuItem key={item} value={item + 1}>
                          {item + 1}
                        </MenuItem>
                      ))}
                    </Select>
                  </StyledFormControl>
                </Fragment>
              ) : (
                <Fragment>
                  <div className="wrap-label-switch">
                    <Typography className="item-name">
                      {t("annualPayDate")}
                    </Typography>
                    <StyledFormControlLabelSwitch
                      checked={dataForm.payslipNextMonth}
                      control={<Switch size="small" />}
                      label={t("NextMonth")}
                      labelPlacement="nextMonth"
                    />
                  </div>
                  <StyledFormControl fullWidth>
                    <Select
                      MenuProps={MenuProps}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="payDateYear"
                      value={dataForm.payDateYear}
                    >
                      {payDateYearList &&
                        payDateYearList.map((x, index) => (
                          <MenuItem key={index} value={x}>
                            {x}
                          </MenuItem>
                        ))}
                    </Select>
                  </StyledFormControl>
                </Fragment>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <div className="wrap-label-switch">
                <Typography className="item-name">
                  {t("paySlipReleaseDay")}
                </Typography>
                <StyledFormControlLabelSwitch
                  checked={dataForm.firstPayslipNextMonth}
                  control={<Switch size="small" />}
                  label={t("NextMonth")}
                  labelPlacement="nextMonth"
                />
              </div>

              <StyledFormControl fullWidth>
                <Select
                  MenuProps={MenuProps}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="firstPayslipDate"
                  value={dataForm.firstPayslipDate}
                >
                  <MenuItem key={99} value={-1}>
                    {t("LastDayOfMonth")}
                  </MenuItem>
                  {[...Array(29).keys()].map((item) => (
                    <MenuItem key={item} value={item + 1}>
                      {item + 1}
                    </MenuItem>
                  ))}
                </Select>
              </StyledFormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography className="item-name">
                {t("paySlipTime")}
              </Typography>
              <StyledFormControl fullWidth>
                <Select
                  inputProps={{ readOnly: true }}
                  MenuProps={MenuProps}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="firstPayslipTime"
                  value={dataForm.firstPayslipTime}
                >
                  {timePayslip.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </StyledFormControl>
            </Grid>
          </Fragment>
        )}
      </Grid>
    </StyledRoot>
  );
};

export default RoundOne;
