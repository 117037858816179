import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { useTitle } from "react-use";
import "./styles.css";
import AddEvent from "./AddEvent";
import InfoEvent from "./InfoEvent";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Box,
  Grid,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Stack,
  FormControl,
  InputLabel,
  TextField,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import EditIcon from "@mui/icons-material/Edit";
import ModeEditOutlineRoundedIcon from "@mui/icons-material/ModeEditOutlineRounded";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import AlertResponse from "../../../shared/general/AlertResponse";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";

import format from "date-fns/format";
import getDay from "date-fns/getDay";
import th from "date-fns/locale/th";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CircleIcon from "@mui/icons-material/Circle";

import DialogUpload from "./DialogUpload";

import { getHoliday } from "../../../../../actions/holiday";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import DatePickerCustom from "../../../shared/date/datePicker";

const StyledRoot = styled("div")({
  marginTop: 28,
  marginBottom: "100px",
  "& .MuiTypography-h6": {
    fontSize: "1.5rem",
  },
});

const StyledCalendar = styled(Calendar)({
  height: "350px !important",
  "& .rbc-date-cell": {
    paddingRight: 0,
    textAlign: "center",
  },
  "& .rbc-month-row": {
    justifyContent: "center",
  },
  "& .rbc-event-content": {
    display: "none !important",
  },
  "& .rbc-row-segment": {
    //]]display: "none !important",
  },
  "& .rbc-today": {
    borderRadius: 4,
  },
});

const StyledToolbarCalendar = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 16,
  "& .todayBtn": {
    borderColor: "#46cbe2",
    color: "#ffffff",
    backgroundColor: "#46cbe2",
  },
  "& .prevNextBtn": {
    marginRight: 4,
    marginLeft: 4,
  },
  "& .toolbarCalendarLabel": {
    fontSize: 24,
  },
});

const StyledEmpty = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: 350,
});

const StyledCellHeader = styled(TableCell)({
  padding: "13.5px 16px",
  borderBottom: "none",
  color: "#637381",
  backgroundColor: "#f4f6f8",
  "&.sticky": {
    position: "sticky",
    left: 0,
    backgroundColor: "#f4f6f8",
    boxShadow: "20px -10px 20px #EEEEEE",
    zIndex: 4,
  },
  "&:first-of-type": {
    paddingLeft: 24,
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
    backgroundColor: "#f4f6f8",
  },
  "&:last-of-type": {
    paddingRight: 24,
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
});

const StyledRowContent = styled(TableRow)({
  cursor: "pointer",
  textDecoration: "none",
  "&.MuiTableRow-hover:hover": {
    backgroundColor: "transparent",
    "& .sticky": {
      backgroundColor: "#FFFFFF",
    },
  },
});

const StyledCellContent = styled(TableCell)({
  borderBottom: "1px dashed rgba(224, 224, 224, 1)",
  padding: "8px 16px",
});

const locales = {
  "th-TH": th,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

function CalendarSetting(props) {
  const { selectedCompany, isReadOnly } = props;
  const dispatch = useDispatch();
  const today = dayjs().toDate();
  dayjs.extend(isSameOrAfter);
  const { t, i18n } = useTranslation();

  const { result: holiday } = useSelector((state) => state.holiday);
  const [activeMonth, setActiveMonth] = useState(dayjs(today).get("month"));
  const [activeDate, setActiveDate] = useState({
    date: today,
    direction: null,
  });
  const [openDialogUpload, setOpenDialogUpload] = useState(false);
  const [addModal, setAddModal] = useState({
    isOpen: false,
  });
  const [infoModal, setInfoModal] = useState({
    isOpen: false,
    event: [],
  });
  const [openAlert, setOpenAlert] = useState(false);
  const [alertType, setAlertType] = useState(false);
  const [selectYear, setSelectYear] = useState(dayjs());

  useEffect(() => {
    if (selectedCompany) {
      dispatch(
        getHoliday({
          start: dayjs(today)
            .subtract(1, "year")
            .set("date", 1)
            .set("month", 0)
            .format("YYYY-MM-DD"),
          end: dayjs(today)
            .add(1, "year")
            .set("date", 31)
            .set("month", 11)
            .format("YYYY-MM-DD"),
          idCompany: selectedCompany.idCompany,
        })
      );
    }
  }, [selectedCompany]);

  const toggleDrawerUpload = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenDialogUpload(open);
  };

  const handleOpenDialog = () => {
    setOpenDialogUpload(true);
  };

  const handleCloseDialog = () => {
    setOpenDialogUpload(false);
  };

  const eventPropGetter = (event, start, end, isSelected) => {
    let newStyle = {
      color: "black",
      borderRadius: 4,
      border: "none",
    };
    if (event.resource) {
      return {
        className: event.resource.typeEvent + "Event",
        style: newStyle,
      };
    } else {
      return {
        className: "",
        style: newStyle,
      };
    }
  };

  const CustomToolbar = (toolbar) => {
    const goToBack = async () => {
      toolbar.onNavigate("PREV");
      setActiveDate({
        date: toolbar.date,
        direction: "PREV",
      });
    };

    const goToNext = async () => {
      toolbar.onNavigate("NEXT");
      setActiveDate({
        date: toolbar.date,
        direction: "NEXT",
      });
    };

    const goToCurrent = async () => {
      toolbar.onNavigate("TODAY");
      setActiveDate({
        date: toolbar.date,
        direction: "TODAY",
      });
    };

    const label = () => {
      const date = dayjs(toolbar.date);
      return (
        <span style={{ fontSize: 18, margin: 8 }}>
          <b>{date.format("MMMM")}</b>
          <span> {date.format("YYYY")}</span>
        </span>
      );
    };

    return (
      <StyledToolbarCalendar>
        <label className={"toolbarCalendarLabel"}>{label()}</label>
        <div>
          <IconButton
            aria-label="prev"
            size="small"
            className={`prevNextBtn`}
            onClick={goToBack}
          >
            <ChevronLeftIcon fontSize="small" />
          </IconButton>
          <ButtonBlue
            size={"small"}
            variant={"contained"}
            onClick={goToCurrent}
          >
            {t("Today")}
          </ButtonBlue>
          <IconButton
            aria-label="next"
            size="small"
            className={`prevNextBtn`}
            onClick={goToNext}
          >
            <ChevronRightIcon fontSize="small" />
          </IconButton>
        </div>
      </StyledToolbarCalendar>
    );
  };

  const RenderHolidayList = () => {
    const { t, i18n } = useTranslation();

    var dateCurrent = null;
    if (activeDate.direction === "NEXT") {
      dateCurrent = dayjs(activeDate.date).add(1, "month").format("YYYY-MM-DD");
    } else if (activeDate.direction === "PREV") {
      dateCurrent = dayjs(activeDate.date)
        .subtract(1, "month")
        .format("YYYY-MM-DD");
    } else {
      dateCurrent = dayjs(today).format("YYYY-MM-DD");
    }

    if (holiday) {
      var holidayMonth = holiday.filter(
        (value) =>
          dayjs(value.dateHoliday).get("month") ===
          dayjs(dateCurrent).get("month") &&
          dayjs(value.dateHoliday).get("year") ===
          dayjs(dateCurrent).get("year")
      );
      if (holidayMonth.length > 0) {
        return holidayMonth.map((value) => (
          <div style={{ marginBottom: 8 }}>
            <Typography variant="h6" style={{ fontSize: 18 }}>
              <CircleIcon
                style={{
                  fontSize: 12,
                  marginRight: 4,
                  color: "#fc4b6c",
                }}
              />
              {i18n.resolvedLanguage === "th"
                          ? value.name
                          : value.name_EN
                            ? value.name_EN
                            : value.name}
            </Typography>
            <Typography
              color="text.secondary"
              style={{
                paddingLeft: 12,
                fontSize: 16,
                fontWeight: 500,
              }}
            >
              {dayjs(value.dateHoliday).format("DD MMM YYYY")}
            </Typography>
          </div>
        ));
      } else {
        return (
          <StyledEmpty className="empty-holiday">
            <Typography color="text.secondary" align="center">
              {t("NoHolidays")}
            </Typography>
          </StyledEmpty>
        );
      }
    }
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  const handleChangeAlertType = (newValue) => {
    setAlertType(newValue);
  };

  const handleClose = () => {
    setAddModal({
      ...addModal,
      isOpen: false,
      type: "",
      data: [],
    });
  };

  const RenderTable = () => {
    const { t, i18n } = useTranslation();
    if (holiday) {
      const holidayMonth = holiday.filter((value) =>
        dayjs(value.dateHoliday).isSame(selectYear, "year")
      );
      if (holidayMonth.length > 0) {
        return (
          <TableContainer style={{ width: "100%", minHeight: "450px" }}>
            <Table aria-label="table">
              <TableHead>
                <TableRow>
                  <StyledCellHeader align="center">
                    {t("Date")}/{t("HolidayName")}
                  </StyledCellHeader>
                  <StyledCellHeader align="center">
                    {t("Date")}/{t("NameOfHolidayCompensate")}
                  </StyledCellHeader>
                  <StyledCellHeader align="center">
                    {t("Status")}
                  </StyledCellHeader>
                  <StyledCellHeader align="center">
                    {t("Manage")}
                  </StyledCellHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {holidayMonth.map((row, index) => (
                  <TableRow key={index}>
                    <StyledCellContent>
                      <Typography variant="subtitle1">
                        {dayjs(row.dateHoliday).format("DD MMM YYYY")}
                      </Typography>
                      <Typography color="text.third">
                        {i18n.resolvedLanguage === "th"
                          ? row.name
                          : row.name_EN
                            ? row.name_EN
                            : row.name}
                      </Typography>
                    </StyledCellContent>
                    <StyledCellContent>
                      <Typography variant="subtitle1">
                        {row.compensateDate
                          ? dayjs(row.compensateDate).format("DD MMM YYYY")
                          : "-"}
                      </Typography>
                      <Typography color="text.third">
                        {i18n.resolvedLanguage === "th"
                          ? row.compensateName
                          : row.compensateName_EN
                            ? row.compensateName_EN
                            : row.compensateName}
                      </Typography>
                    </StyledCellContent>
                    <StyledCellContent align="center">
                      {row.isActive === 1 ? `${t("Use")}` : `${t("NotInUse")}`}
                    </StyledCellContent>
                    <StyledCellContent align="center">
                      <ButtonBlue
                        size="small"
                        startIcon={<ModeEditOutlineRoundedIcon />}
                        onClick={() => {
                          setAddModal({
                            ...addModal,
                            isOpen: true,
                            type: "Edit",
                            data: row,
                          });
                        }}
                      ></ButtonBlue>
                    </StyledCellContent>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        );
      } else {
        return (
          <StyledEmpty className="empty-holiday">
            <Typography color="text.secondary" align="center">
              {t("NoHolidays")}
            </Typography>
          </StyledEmpty>
        );
      }
    }
  };

  return (
    <StyledRoot>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography variant="h6">{t("AnnualHoliday")}</Typography>
        </Grid>
        <Grid item>
          <ButtonBlue
            variant={"contained"}
            style={{ marginRight: 8 }}
            onClick={handleOpenDialog}
          >
            {t("UploadHolidays")}
          </ButtonBlue>
          {/* <ButtonBlue
            variant={"contained"}
            startIcon={<AddIcon />}
            onClick={() => {
              setAddModal({
                ...addModal,
                isOpen: true,
              });
            }}
          >
            เพิ่มปฏิทิน
          </ButtonBlue> */}
        </Grid>
      </Grid>
      <div style={{ marginBottom: 28 }}></div>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <div>
            {holiday && (
              <StyledCalendar
                className="calendar-leave"
                localizer={localizer}
                events={holiday}
                views={["month"]}
                startAccessor="start"
                endAccessor="end"
                popup={true}
                onSelectEvent={(selected) => {
                  console.log(selected);
                }}
                eventPropGetter={eventPropGetter}
                components={{
                  toolbar: CustomToolbar,
                }}
                formats={{
                  dateFormat: "d",
                  dayHeaderFormat: "d MMMM yyyy",
                  weekdayFormat: "E",
                }}
              />
            )}
          </div>
        </Grid>
        <Grid item xs={6}>
          <div>{RenderHolidayList()}</div>
        </Grid>
      </Grid>
      <Stack spacing={2} marginTop={5}>
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="h6">{t("ListOfAnnualHolidays")}</Typography>
          <DatePickerCustom
            label={t("SelectYear")}
            views={["year"]}
            minDate={dayjs()}
            maxDate={dayjs().add(1, "year")}
            value={selectYear}
            onChange={(newValue) => setSelectYear(newValue)}
            renderInput={(params) => <TextField {...params} />}
          />
        </Stack>
        {RenderTable()}
      </Stack>
      <DialogUpload
        open={openDialogUpload}
        handleCloseDialog={handleCloseDialog}
        handleOpenAlert={handleOpenAlert}
        handleChangeAlertType={handleChangeAlertType}
        selectedCompany={selectedCompany}
        isReadOnly={isReadOnly}
      />
      <AddEvent
        handleClose={handleClose}
        addModal={addModal}
        setAddModal={setAddModal}
        isReadOnly={isReadOnly}
      />
      <InfoEvent infoModal={infoModal} setInfoModal={setInfoModal} />
      <AlertResponse
        open={openAlert}
        handleClose={handleCloseAlert}
        alertType={alertType}
      />
    </StyledRoot>
  );
}

export default CalendarSetting;
