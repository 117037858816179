import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Alert, MenuItem, Stack } from "@mui/material";
import DrawerCustom from "../../shared/general/Drawer";
import ButtonBlue from "../../shared/general/ButtonBlue";
import TextFieldTheme from "../../shared/general/TextFieldTheme";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getEmployeesTaxDetails } from "../../../../actions/employee";
import { PfPDFfile } from "../../user/governmentReport/filePDFgeneration.js/PfPDFfile";
import AlertResponse from "../../shared/general/AlertResponse";
import { PVFxlsxFile } from "../../user/governmentReport/fileXLSXgeneration.js/PVFxlsxFile";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .GridTopicInput": {
    display: "flex",
    alignItems: "center",
  },
});

const Provident = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const { open, handleClose } = props;
  const [openAlert, setOpenAlert] = useState(false);

  const months = [
    { value: "01", name: "มกราคม" },
    { value: "02", name: "กุมภาพันธ์" },
    { value: "03", name: "มีนาคม" },
    { value: "04", name: "เมษายน" },
    { value: "05", name: "พฤษภาคม" },
    { value: "06", name: "มิถุนายน" },
    { value: "07", name: "กรกฎาคม" },
    { value: "08", name: "สิงหาคม" },
    { value: "09", name: "กันยายน" },
    { value: "10", name: "ตุลาคม" },
    { value: "11", name: "พฤศจิกายน" },
    { value: "12", name: "ธันวาคม" },
  ];

  const { control } = useForm({});
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const { result: userProfile } = useSelector((state) => state.userInformation);

  const thaiYears = Array.from(
    { length: 6 },
    (_, index) => currentYear + 543 - index
  );

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const handleOpenAlertError = (label) => {
    setOpenAlert({ isOpen: true, type: "error", label: label });
  };

  const handleCloseAlert = () => {
    setOpenAlert({ isOpen: false, type: null, label: null });
  };

  useEffect(() => {
    if (selectedMonth && selectedYear) {
      getEmployeesTaxDetails(selectedMonth, selectedYear);
    }
  }, [selectedMonth, selectedYear]);

  const DownloadFile = async (
    type,
    selectedYear,
    selectedMonth,
    idPayrollType,
    fileType
  ) => {
    try {
      const data = await getEmployeesTaxDetails(
        selectedMonth,
        selectedYear,
        idPayrollType,
        null,
        type
      );
      if (!data.data) {
        handleOpenAlertError("ไม่มีข้อมูล");
        return;
      }

      if (selectedYear === null || selectedMonth === null) {
        handleOpenAlertError("ข้อมูลไม่ครบ");
        return;
      }

      if (fileType === "PDF") {
        PfPDFfile(type, selectedYear, selectedMonth, data.data);
      } else if (fileType === "XLSX") {
        PVFxlsxFile(data.data);
      }
    } catch (error) {
      handleOpenAlertError("เกิดข้อผิดพลาขณะดึงข้อมูล");
    }
  };

  return (
    <DrawerCustom
      title={`${t("ProvidentFundReport")}`}
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      <StyledRoot style={{ width: 400 }}>
        <Stack spacing={4}>
          <Stack spacing={2}>
            <Stack spacing={1}>
              <Typography>{t("SelectYear")}</Typography>
              <Stack
                direction={"row"}
                justifyContent="space-between"
                alignItems={"center"}
                spacing={2}
              >
                <Controller
                  name="year"
                  control={control}
                  render={({ field }) => (
                    <TextFieldTheme
                      {...field}
                      variant="filled"
                      select
                      fullWidth
                      value={selectedYear}
                      onChange={handleYearChange}
                    >
                      {thaiYears.map((year) => (
                        <MenuItem key={year} value={year}>
                          {year}
                        </MenuItem>
                      ))}
                    </TextFieldTheme>
                  )}
                />
              </Stack>
            </Stack>
            <Stack spacing={1}>
              <Typography>{t("SelectMonth")}</Typography>
              <Stack
                direction={"row"}
                justifyContent="space-between"
                alignItems={"center"}
                spacing={2}
              >
                <Controller
                  name="year"
                  control={control}
                  render={({ field }) => (
                    <TextFieldTheme
                      {...field}
                      variant="filled"
                      select
                      fullWidth
                      value={selectedMonth}
                      onChange={handleMonthChange}
                    >
                      {months.map((month) => (
                        <MenuItem key={month} value={month.value}>
                          {month.name}
                        </MenuItem>
                      ))}
                    </TextFieldTheme>
                  )}
                />
              </Stack>
            </Stack>
          </Stack>

          <Stack spacing={1} direction={"row"} justifyContent={"flex-end"}>
            <ButtonBlue
              variant="contained"
              disabled={selectedYear === null || selectedMonth === null}
              onClick={() =>
                DownloadFile("PVF", selectedYear, selectedMonth, 10, "PDF")
              }
            >
              {t("View")} PDF
            </ButtonBlue>

            <ButtonBlue
              variant="contained"
              disabled={selectedYear === null || selectedMonth === null}
              onClick={() =>
                DownloadFile("PVF", selectedYear, selectedMonth, 10, "XLSX")
              }
            >
              {t("Download")} Xlsx
            </ButtonBlue>
          </Stack>
        </Stack>
      </StyledRoot>
      {openAlert.isOpen && (
        <AlertResponse
          open={openAlert.isOpen}
          alertType={openAlert.type}
          label={openAlert.label}
          handleClose={handleCloseAlert}
        />
      )}
    </DrawerCustom>
  );
};

export default Provident;
