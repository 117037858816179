import React from "react";
import {
  Avatar,
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
  Card,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import dayjs from "dayjs";
import ChipStatus from "../../../shared/pages/okrs/components/ChipStatus";
import { useTranslation } from "react-i18next";
import { getUserFullName, getUserPosition, getUserManagerFullName } from "../../../../../utils/userData";

const StyleText = styled(Typography)({
  "& .name-text": {
    fontSize: "20px",
    fontWeight: "bold",
  },
  "& .gray-color-text": {
    fontSize: "16px",
    color: "gray",
  },
  "& .content-text": {
    fontSize: "16px",
    color: "black",
  },
});

const StyleImg = styled(Box)({
  "& .img-emp": {
    width: "140px",
    height: "180px",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "auto",
  },
});

const getStatusApprove = (status) => {
  if (status === null) {
    return 1;
  } else if (status === 1) {
    return 2;
  } else if (status === 0) {
    return 3;
  } else {
    return 1;
  }
};

const getStatusAchieveApprove = (status) => {
  if (status === 1) {
    return 5;
  } else if (status === 0) {
    return 6;
  } else {
    return 4;
  }
};

const FCard = ({
  avatarSrc,
  fullName,
  positionName,
  department,
  objective,
  transactionDate,
  year,
  quarter,
  sectionName,
}) => {
  const {t} = useTranslation();
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid #ccc",
        padding: "16px 0",
      }}
    >
      <div style={{ flex: "20%", marginRight: "16px" }}>
        <StyleImg>
          <Avatar className="img-emp" src={avatarSrc} variant="square" />
        </StyleImg>
      </div>
      <div style={{ flex: "80%" }}>
        <StyleText>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography className="name-text" style={{ marginRight: "8px" }}>
              {fullName}
            </Typography>
            <Typography className="gray-color-text">{`( ${positionName} ${department} )`}</Typography>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography className="gray-color-text">{t("Section")} : </Typography>
            <Typography className="content-text" style={{ marginLeft: "4px" }}>
              {sectionName}
            </Typography>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography className="gray-color-text">{t("Objective")} : </Typography>
            <Typography className="content-text" style={{ marginLeft: "4px" }}>
              {objective}
            </Typography>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography className="gray-color-text">{t("ApprovedDate")} : </Typography>
            <Typography className="content-text" style={{ marginLeft: "4px" }}>
              {transactionDate}
            </Typography>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography className="gray-color-text">{t("Year")} : </Typography>
            <Typography className="content-text" style={{ marginLeft: "4px" }}>
              {year}
            </Typography>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography className="gray-color-text">{t("Quarter")} : </Typography>
            <Typography className="content-text" style={{ marginLeft: "4px" }}>
              {quarter}
            </Typography>
          </div>
        </StyleText>
      </div>
    </div>
  );
};

function Row(props) {
  const { row } = props;
  const { t, i18n } = useTranslation();
  const [open, setOpen] = React.useState(false);

  return (
    <>
      {/* <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell sx={{ maxWidth: "0.1rem" }}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <div style={{ display: "flex", gap: 8 }}>
            <Avatar src={row.imageURL} />
            <div>
              <Typography>{`${row.firstname_TH} ${row.lastname_TH}`}</Typography>
              <Typography>{row.positionName}</Typography>
              <Typography>{row.departmentName}</Typography>
              <Typography>{row.sectionName}</Typography>
            </div>
          </div>
        </TableCell>
        <TableCell>{row.objectiveName}</TableCell>
        <TableCell align="center">
          {dayjs(row.approveDate).format("DD/MM/YYYY")}
        </TableCell>
        <TableCell align="center">{row.year}</TableCell>
        <TableCell align="center">{row.quarter}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                {`${t("Descriptions")}`}
              </Typography>
              <Table
                style={{ backgroundColor: "#F5F5F5", borderRadius: "8px" }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>{t("KeyResult")}</TableCell>
                    <TableCell>{`${t("Duration")}`}</TableCell>
                    <TableCell>{`${t("Employees")}`}</TableCell>
                    <TableCell>{`${t("EvaluationResults")}`}</TableCell>
                    <TableCell>{`${t("Status")}`}</TableCell>
                    <TableCell>{`${t("Assessor")}`}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.keyResults.map((keyResult) => (
                    <TableRow
                      key={keyResult.date}
                      sx={{ "&:last-child td": { border: 0 } }}
                    >
                      <TableCell>{keyResult.keyResultName}</TableCell>
                      <TableCell component="th" scope="row">
                        {`${dayjs(keyResult.startDate).format(
                          "DD/MM/YYYY"
                        )} - ${dayjs(keyResult.endDate).format("DD/MM/YYYY")}`}
                      </TableCell>
                      <TableCell>
                        <ChipStatus
                          status={getStatusAchieveApprove(keyResult.isAchieve)}
                        />
                      </TableCell>
                      <TableCell>
                        <ChipStatus
                          status={getStatusAchieveApprove(
                            keyResult.isAchieveApprove
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <ChipStatus
                          status={getStatusApprove(keyResult.isApprove)}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <div style={{ display: "flex", gap: 8 }}>
                          <Avatar src={keyResult.managerImageURL} />
                          <div>
                            <Typography>{`${keyResult.manager_firstname_TH} ${keyResult.manager_lastname_TH}`}</Typography>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow> */}

      <Card style={{ margin: "10px", borderRadius: "20px" }} variant="outlined">
        <FCard
          avatarSrc={row.imageURL}
          fullName={`${getUserFullName(row)}`}
          positionName={getUserPosition(row)}
          department={row.departmentName}
          objective={row.objectiveName}
          approveDate={dayjs(row.approveDate).format("DD/MM/YYYY")}
          year={row.year}
          quarter={row.quarter}
          sectionName={row.sectionName}
        />

        <div style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                {`${t("Descriptions")}`}
              </Typography>
              <Table
                style={{ backgroundColor: "#F5F5F5", borderRadius: "8px" }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>{t("KeyResult")}</TableCell>
                    <TableCell>{`${t("Duration")}`}</TableCell>
                    <TableCell>{`${t("Employee")}`}</TableCell>
                    <TableCell>{`${t("EvaluationResults")}`}</TableCell>
                    <TableCell>{`${t("Status")}`}</TableCell>
                    <TableCell>{`${t("Assessor")}`}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.keyResults.map((keyResult) => (
                    <TableRow
                      key={keyResult.date}
                      sx={{ "&:last-child td": { border: 0 } }}
                    >
                      <TableCell>{keyResult.keyResultName}</TableCell>
                      <TableCell component="th" scope="row">
                        {`${dayjs(keyResult.startDate).format(
                          "DD/MM/YYYY"
                        )} - ${dayjs(keyResult.endDate).format("DD/MM/YYYY")}`}
                      </TableCell>
                      <TableCell>
                        <ChipStatus
                          status={getStatusAchieveApprove(keyResult.isAchieve)}
                        />
                      </TableCell>
                      <TableCell>
                        <ChipStatus
                          status={getStatusAchieveApprove(
                            keyResult.isSuccess
                          )}
                        />
                      </TableCell>
                      <TableCell>
                        <ChipStatus
                          status={getStatusApprove(keyResult.isApprove)}
                        />
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <div style={{ display: "flex", gap: 8 }}>
                          <Avatar src={keyResult.managerImageURL} />
                          <div>
                            <Typography>{`${getUserManagerFullName(keyResult)}`}</Typography>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </div>

        <TableCell
          style={{
            textAlign: "center",
            width: "5%",
          }}
        >
          <Box display="flex" justifyContent="center" alignItems="center">
            <IconButton
              aria-label="expand row"
              size="large"
              onClick={() => setOpen(!open)}
            >
              {open ? (
                <KeyboardArrowUpIcon fontSize="large" />
              ) : (
                <KeyboardArrowDownIcon fontSize="large" />
              )}
            </IconButton>
          </Box>
        </TableCell>
      </Card>
    </>
  );
}

const StatusApprove = ({ data }) => {
  const { t, i18n } = useTranslation();
  if (data.length === 0) {
    return (
      <Typography align="center" marginTop={4}>{`${t("NoData")}`}</Typography>
    );
  }

  return (
    <div sx={{ minWidth: 650 }} aria-label="simple table">
      {/* <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>{`${t("OKRSetter")}`}</TableCell>
            <TableCell>Objective</TableCell>
            <TableCell align="center">{`${t("ApprovedDate")}`}</TableCell>
            <TableCell align="center">{`${t("Year")}`}</TableCell>
            <TableCell align="center">{`${t("Quarter")}`}</TableCell>
          </TableRow>
        </TableHead> */}

      {data.map((row) => (
        <Row key={row.idIndividualObjective} row={row} />
      ))}
    </div>
  );
};

export default StatusApprove;
