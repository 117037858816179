import React, { Fragment, useEffect, useState } from "react";
import dayjs from "dayjs";
import clsx from "clsx";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Alert,
  Box,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  MenuItem,
  Snackbar,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";

import ButtonBlue from "../../shared/general/ButtonBlue";
import DrawerBeneficiary from "./drawerBeneficiary";
import TextFieldTheme from "../../shared/general/TextFieldTheme";

import {
  getBeneficiaryUser,
  deleteBeneficiary,
} from "../../../../actions/beneficiary";
import {
  getProvidentFundUser,
  updateProvidentFundUser,
} from "../../../../actions/providentFund";

const StyledRoot = styled(Box)({
  "& .Topic": {
    fontWeight: 600,
    fontSize: 20,
  },
  "& .disbledEdit": {
    color: "#bdbdbd",
  },
  "& .beneficiary": {
    widht: "100%",
    marginTop: 48,
    "& .navButton": {
      display: "flex",
      justifyContent: "flex-end",
    },
    "& .tableContainer": {
      width: "100%",
      overflow: "auto",
      minWidth: "0",
      "& .TableCellHead": {
        padding: "0 !important",
      },
      "& .MuiTableCell-root": {
        borderWidth: 0,
        padding: "16px 8px",
      },
      "& .icon": {
        fontSize: 16,
        cursor: "pointer",
      },
    },
  },
});

const StyledDivider = styled(Divider)({
  marginTop: 8,
  marginBottom: 8,
  borderWidth: "0px 0px thin",
  borderColor: "#919eab52",
  borderStyle: "dashed",
  width: "100%",
});

const PF = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const mobile = useMediaQuery("(max-width:600px)");
  const { result: AllBeneficiarys } = useSelector(
    (state) => state.userBeneficiarys
  );
  const { result: UserProvidentFund } = useSelector(
    (state) => state.userProvidentFund
  );
  const [openDrawerBeneficiary, setOpenDrawerBeneficiary] = useState(false);
  const [beneficiarySelected, setBeneficiarySelected] = useState(null);
  const [snackBar, setSnackBar] = useState({
    isOpen: false,
    result: null,
    label: "",
  });
  const [openDeleteDialog, setOpenDeleteDialog] = useState({
    isOpen: false,
    idSelected: null,
  });
  const [openDialogChangePF, setOpenDialogChangePF] = useState(false);
  const [isChangePF, setIsChangePF] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    getValues,
  } = useForm({
    defaultValues: {
      newEmployeePF: "",
    },
  });

  useEffect(() => {
    dispatch(getBeneficiaryUser());
    dispatch(getProvidentFundUser());
  }, []);

  useEffect(() => {
    if (UserProvidentFund && UserProvidentFund.newUserPF) {
      setValue("newEmployeePF", `${UserProvidentFund.newUserPF || ""}`);
    }
  }, [UserProvidentFund]);

  const onHandleSubmit = async () => {
    setOpenDialogChangePF(false);
    let newCompanyPF = CompanyPFByTextField();
    let useDate = dayjs()
      .date(1)
      .add(
        UserProvidentFund &&
          UserProvidentFund.firstCutOff &&
          dayjs().isSameOrBefore(
            dayjs().date(UserProvidentFund.firstCutOff),
            "date"
          )
          ? 0
          : 1,
        "month"
      )
      .format("YYYY-MM-DD");
    const res = await dispatch(
      updateProvidentFundUser(getValues("newEmployeePF"), newCompanyPF, useDate)
    );
    if (res.status === 200) {
      setValue("newEmployeePF", "");
      await dispatch(getProvidentFundUser());
      setSnackBar({
        isOpen: true,
        result: "success",
        label: `${t("ChangeCumulativeSuccessRate")}`,
      });
    } else {
      setSnackBar({
        isOpen: true,
        result: "error",
        label: `${t("ChangeCumulativeSuccessRateUnsuccessful")}`,
      });
    }
  };

  const handleCloseNotify = (_, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackBar({ isOpen: false, result: null, label: "" });
  };

  const handleDelete = async (idBeneficiary) => {
    const res = await dispatch(deleteBeneficiary(idBeneficiary));
    if (res.status === 200) {
      setSnackBar({
        isOpen: true,
        result: "success",
        label: `${t("DeleteSuccessfulBeneficiary")}`,
      });
      await dispatch(getBeneficiaryUser());
    } else {
      setSnackBar({
        isOpen: true,
        result: "error",
        label: `${t("DeleteUnsuccessfulBeneficiary")}`,
      });
    }
    setOpenDeleteDialog({ isOpen: false, idSelected: null });
  };

  const isBetweenEditDate = () => {
    if (
      UserProvidentFund &&
      UserProvidentFund.startEditDate &&
      UserProvidentFund.endEditDate &&
      !(
        dayjs().isBefore(dayjs(UserProvidentFund.startEditDate)) ||
        dayjs().isAfter(dayjs(UserProvidentFund.endEditDate))
      )
    ) {
      return false;
    } else {
      return true;
    }
  };

  const CompanyPFByTextField = () => {
    let result = "-";
    if (UserProvidentFund) {
      let newEmployeePF = watch("newEmployeePF");
      if (newEmployeePF && UserProvidentFund.listEmployeePF.length > 0) {
        UserProvidentFund.listEmployeePF.map((pf) => {
          if (
            pf.minEmployeePF <= newEmployeePF &&
            pf.maxEmployeePF >= newEmployeePF
          ) {
            result = pf.companyPF;
          }
        });
      }
    }
    return result;
  };

  const displaySevYearMonth = (ServPFDate) => {
    let allMonth = Math.floor(dayjs().diff(dayjs(ServPFDate), "month", true));
    let year = Math.floor(allMonth / 12);
    let month = allMonth - year * 12;

    if (year === 0 && month === 0) {
      return `0 ${t("Month")}`;
    } else {
      return `${year > 0 ? `${year} ${t("Year")} ` : ""}${
        month > 0 ? `${month} ${t("Month")}` : ""
      }`;
    }
  };

  return (
    <StyledRoot>
      <form onSubmit={handleSubmit(() => setOpenDialogChangePF(true))}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} style={{ padding: "24px 16px" }}>
            <Typography variant="h6" align="center" className="text">
              {`${t("ServiceDateInFund")}`}
            </Typography>
            <Typography
              className="text"
              style={{ fontSize: 24, fontWeight: 400, marginTop: 16 }}
              align="center"
            >
              {(UserProvidentFund &&
                UserProvidentFund.hiringDate &&
                `${dayjs(UserProvidentFund.hiringDate)
                  .locale(localStorage.getItem("i18nextLng") || "th")
                  .format(
                    localStorage.getItem("i18nextLng") === "en"
                      ? "DD MMMM YYYY"
                      : "DD MMMM BBBB"
                  )}`) ||
                "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} style={{ padding: "24px 16px" }}>
            <Typography variant="h6" align="center" className="text">
              {t("ServiceYear")}
            </Typography>
            <Typography
              className="text"
              style={{ fontSize: 24, fontWeight: 400, marginTop: 16 }}
              align="center"
            >
              {(UserProvidentFund &&
                UserProvidentFund.hiringDate &&
                `${displaySevYearMonth(UserProvidentFund.hiringDate)}`) ||
                "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} style={{ padding: "24px 16px" }}>
            <Typography variant="h6" align="center" className="text">
              {`${t("FundEntryDate")}`}
            </Typography>
            <Typography
              className="text"
              style={{ fontSize: 24, fontWeight: 400, marginTop: 16 }}
              align="center"
            >
              {(UserProvidentFund &&
                UserProvidentFund.PFDate &&
                `${dayjs(UserProvidentFund.PFDate)
                  .locale(localStorage.getItem("i18nextLng") || "th")
                  .format(
                    localStorage.getItem("i18nextLng") === "en"
                      ? "DD MMMM YYYY"
                      : "DD MMMM BBBB"
                  )}`) ||
                "-"}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} style={{ padding: "24px 16px" }}>
            <Typography variant="h6" align="center" className="text">
              {`${t("YearInFund")}`}
            </Typography>
            <Typography
              className="text"
              style={{ fontSize: 24, fontWeight: 400, marginTop: 16 }}
              align="center"
            >
              {(UserProvidentFund &&
                UserProvidentFund.PFDate &&
                `${displaySevYearMonth(UserProvidentFund.PFDate)}`) ||
                "-"}
            </Typography>
          </Grid>
          {UserProvidentFund &&
          [0, 1].includes(UserProvidentFund.isServYPFDate) ? (
            <Grid
              item
              xs={12}
              container
              style={{ padding: "8px 0px 8px 16px" }}
              justifyContent={"space-between"}
            >
              <Grid item>
                <Typography
                  className="text"
                  style={{ fontSize: 20, fontWeight: 400 }}
                >
                  {`${t("CalYearInFund")}`}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  className="text"
                  style={{
                    fontSize: 20,
                    fontWeight: 600,
                    marginTop: mobile ? "8px" : "0px",
                  }}
                >
                  {UserProvidentFund.isServYPFDate
                    ? `${t("FundEntryDate")}`
                    : `${t("ServiceDateInFund")}`}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
          <Grid item xs={12}>
            <div
              style={{
                display: "flex",
                flexDirection: mobile ? "column" : "row",
                justifyContent: "space-between",
                alignItems: mobile ? "flex-start" : "flex-end",
              }}
            >
              <Typography className="Topic">{`${t(
                "AccumulatedRate"
              )}`}</Typography>
              {UserProvidentFund && UserProvidentFund.leftDate && (
                <Typography
                  style={{
                    marginTop: mobile ? "8px" : "0px",
                  }}
                >{`${t("MonthExitFund")} : ${dayjs(
                  UserProvidentFund.leftDate
                ).format("MMM YYYY")}`}</Typography>
              )}
            </div>
          </Grid>
          {mobile ? (
            <Fragment>
              <Grid item xs={12}>
                <StyledDivider />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6">{`${t(
                  "CurrentAccumulatedRate"
                )}`}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" align="center">
                  {`${t("EmployeeContribution")}`} (%)
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" align="center">
                  {(UserProvidentFund &&
                    UserProvidentFund.userPF &&
                    `${UserProvidentFund.userPF}`) ||
                    "-"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" align="center">
                  {`${t("ContributionCompany")}`} (%)
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" align="center">
                  {(UserProvidentFund &&
                    UserProvidentFund.companyPF &&
                    `${UserProvidentFund.companyPF}`) ||
                    "-"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <StyledDivider />
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                className={clsx({
                  disbledEdit: UserProvidentFund ? isBetweenEditDate() : true,
                })}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="h6" style={{ color: "inherit" }}>
                  {`${t("NewAccumulatedRate")}`}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" align="center">
                  {`${t("EmployeeContribution")}`} (%)
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="newEmployeePF"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: `${t("PleaseSelectNewCumulativeRate")}`,
                    },
                  }}
                  render={({ field }) => (
                    <TextFieldTheme
                      {...field}
                      select
                      SeleectProps={{
                        native: true,
                      }}
                      onChange={(e) => {
                        field.onChange(e);
                        if (!isChangePF) {
                          setIsChangePF(true);
                        }
                      }}
                      helperText={
                        errors &&
                        errors.newEmployeePF &&
                        errors.newEmployeePF.message
                      }
                      error={errors && errors.newEmployeePF ? true : false}
                      disabled={UserProvidentFund ? isBetweenEditDate() : true}
                    >
                      <MenuItem value="" disabled></MenuItem>
                      {[
                        ...Array(
                          UserProvidentFund &&
                            UserProvidentFund.minEmployeePF &&
                            UserProvidentFund.maxEmployeePF
                            ? UserProvidentFund.maxEmployeePF -
                                UserProvidentFund.minEmployeePF +
                                1
                            : 0
                        ),
                      ].map((_, index) => {
                        return (
                          <MenuItem
                            value={`${
                              index + (UserProvidentFund.minEmployeePF || 0)
                            }`}
                            key={index + (UserProvidentFund.minEmployeePF || 0)}
                          >
                            {`${
                              index + (UserProvidentFund.minEmployeePF || 0)
                            }`}
                          </MenuItem>
                        );
                      })}
                    </TextFieldTheme>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h6" align="center">
                  {`${t("ContributionCompany")}`} (%)
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                className={clsx({
                  disbledEdit: UserProvidentFund ? isBetweenEditDate() : true,
                })}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="h6"
                  align="center"
                  style={{ color: "inherit" }}
                >
                  {!isChangePF &&
                  UserProvidentFund &&
                  UserProvidentFund.newCompanyPF
                    ? UserProvidentFund.newCompanyPF
                    : CompanyPFByTextField()}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <StyledDivider />
              </Grid>
            </Fragment>
          ) : (
            <Fragment>
              <Grid item xs={12} md={4}></Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="h6" align="center">
                  {`${t("EmployeeContribution")}`}(%)
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="h6" align="center">
                  {`${t("ContributionCompany")}`}(%)
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="h6">{`${t(
                  "CurrentAccumulatedRate"
                )}`}</Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="h6" align="center">
                  {(UserProvidentFund &&
                    UserProvidentFund.userPF &&
                    `${UserProvidentFund.userPF}`) ||
                    "-"}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="h6" align="center">
                  {(UserProvidentFund &&
                    UserProvidentFund.companyPF &&
                    `${UserProvidentFund.companyPF}`) ||
                    "-"}
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                className={clsx({
                  disbledEdit: UserProvidentFund ? isBetweenEditDate() : true,
                })}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography variant="h6" style={{ color: "inherit" }}>
                  {`${t("NewAccumulatedRate")}`}
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Controller
                  name="newEmployeePF"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: `${t("PleaseSelectNewCumulativeRate")}`,
                    },
                  }}
                  render={({ field }) => (
                    <TextFieldTheme
                      {...field}
                      select
                      onChange={(e) => {
                        field.onChange(e);
                        if (!isChangePF) {
                          setIsChangePF(true);
                        }
                      }}
                      helperText={
                        errors &&
                        errors.newEmployeePF &&
                        errors.newEmployeePF.message
                      }
                      error={errors && errors.newEmployeePF ? true : false}
                      disabled={UserProvidentFund ? isBetweenEditDate() : true}
                    >
                      {[
                        ...Array(
                          UserProvidentFund &&
                            UserProvidentFund.minEmployeePF &&
                            UserProvidentFund.maxEmployeePF
                            ? UserProvidentFund.maxEmployeePF -
                                UserProvidentFund.minEmployeePF +
                                1
                            : 0
                        ),
                      ].map((_, index) => {
                        return (
                          <MenuItem
                            value={`${
                              index + (UserProvidentFund.minEmployeePF || 0)
                            }`}
                            key={index + (UserProvidentFund.minEmployeePF || 0)}
                          >
                            {`${
                              index + (UserProvidentFund.minEmployeePF || 0)
                            }`}
                          </MenuItem>
                        );
                      })}
                    </TextFieldTheme>
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={4}
                className={clsx({
                  disbledEdit: UserProvidentFund ? isBetweenEditDate() : true,
                })}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="h6"
                  align="center"
                  style={{ color: "inherit" }}
                >
                  {!isChangePF &&
                  UserProvidentFund &&
                  UserProvidentFund.newCompanyPF
                    ? UserProvidentFund.newCompanyPF
                    : CompanyPFByTextField()}
                </Typography>
              </Grid>
            </Fragment>
          )}
          <Grid
            item
            container
            xs={12}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              {mobile ? (
                <Fragment>
                  <Typography>{`${t("EditablePeriod")} : `}</Typography>
                  <Typography style={{ color: "#46cbe2", fontWeight: 600 }}>
                    {`${
                      (UserProvidentFund &&
                        UserProvidentFund.startEditDate &&
                        dayjs(UserProvidentFund.startEditDate)
                          .locale(localStorage.getItem("i18nextLng") || "th")
                          .format(
                            localStorage.getItem("i18nextLng") === "en"
                              ? "DD MMMM YYYY"
                              : "DD MMMM BBBB"
                          )) ||
                      "-"
                    } - ${
                      (UserProvidentFund &&
                        UserProvidentFund.endEditDate &&
                        dayjs(UserProvidentFund.endEditDate)
                          .locale(localStorage.getItem("i18nextLng") || "th")
                          .format(
                            localStorage.getItem("i18nextLng") === "en"
                              ? "DD MMMM YYYY"
                              : "DD MMMM BBBB"
                          )) ||
                      "-"
                    }`}
                  </Typography>
                </Fragment>
              ) : (
                <Typography>
                  {`${t("EditablePeriod")} : `}
                  <span style={{ color: "#46cbe2", fontWeight: 600 }}>
                    {`${
                      (UserProvidentFund &&
                        UserProvidentFund.startEditDate &&
                        dayjs(UserProvidentFund.startEditDate)
                          .locale(localStorage.getItem("i18nextLng") || "th")
                          .format(
                            localStorage.getItem("i18nextLng") === "en"
                              ? "DD MMMM YYYY"
                              : "DD MMMM BBBB"
                          )) ||
                      "-"
                    } - ${
                      (UserProvidentFund &&
                        UserProvidentFund.endEditDate &&
                        dayjs(UserProvidentFund.endEditDate)
                          .locale(localStorage.getItem("i18nextLng") || "th")
                          .format(
                            localStorage.getItem("i18nextLng") === "en"
                              ? "DD MMMM YYYY"
                              : "DD MMMM BBBB"
                          )) ||
                      "-"
                    }`}
                  </span>
                </Typography>
              )}
            </Grid>
            <Grid container item justifyContent={"flex-end"}>
              <Grid item>
                <ButtonBlue
                  variant="contained"
                  type="submit"
                  disabled={isBetweenEditDate()}
                >
                  {`${t("Save")}`}
                </ButtonBlue>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
      {UserProvidentFund &&
        UserProvidentFund.listPFDate &&
        UserProvidentFund.listPFDate.length > 0 && (
          <Box>
            <Typography className="Topic text">{`${t(
              "HistoryInFund"
            )}`}</Typography>
            {UserProvidentFund.listPFDate.map((pfDate, index) => {
              return (
                <>
                  <Typography className="text" style={{ marginTop: "16px" }}>
                    {`${t("No.")} ${index + 1} : ${dayjs(pfDate.PFDate).format(
                      "D MMMM YYYY"
                    )}${
                      pfDate.leftDate
                        ? ` - ${dayjs(pfDate.leftDate).format("D MMMM YYYY")}`
                        : ""
                    }`}
                  </Typography>
                  <StyledDivider />
                </>
              );
            })}
          </Box>
        )}
      <Box className="beneficiary">
        <Typography className="Topic">{`${t("Beneficiary")}`}</Typography>
        <Box className="navButton">
          <ButtonBlue
            variant="contained"
            onClick={() => setOpenDrawerBeneficiary(true)}
          >
            {`${t("AddBeneficiary")}`}
          </ButtonBlue>
        </Box>
        {AllBeneficiarys && AllBeneficiarys.length > 0 ? (
          <TableContainer className="tableContainer">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    className="TableCellHead"
                    style={{ width: 56, minWidth: 56 }}
                  ></TableCell>
                  <TableCell
                    className="TableCellHead"
                    style={{ width: 120, minWidth: 120 }}
                  ></TableCell>
                  <TableCell
                    className="TableCellHead"
                    style={{ width: 120, minWidth: 120 }}
                  ></TableCell>
                  <TableCell
                    className="TableCellHead"
                    style={{ width: 88, minWidth: 88 }}
                  ></TableCell>
                  <TableCell
                    className="TableCellHead"
                    style={{ width: 104, minWidth: 104 }}
                  ></TableCell>
                  <TableCell
                    className="TableCellHead"
                    style={{ width: 40, minWidth: 40 }}
                  ></TableCell>
                  <TableCell
                    className="TableCellHead"
                    style={{ width: 48, minWidth: 48 }}
                  ></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {AllBeneficiarys.map((beneficiary, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <Typography>{beneficiary.title_TH}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{beneficiary.firstname_TH}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{beneficiary.lastname_TH}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{beneficiary.relationship_TH}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{beneficiary.telephoneMobile}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>{`${beneficiary.percentage}%`}</Typography>
                    </TableCell>
                    <TableCell>
                      <Box style={{ display: "flex" }}>
                        <Box
                          style={{ marginRight: 16 }}
                          onClick={() => {
                            setBeneficiarySelected(beneficiary);
                            setOpenDrawerBeneficiary(true);
                          }}
                        >
                          <i
                            className="fa-regular fa-pen icon"
                            style={{ color: "#1976D2" }}
                          ></i>
                        </Box>
                        <Box
                          onClick={() => {
                            setOpenDeleteDialog({
                              isOpen: true,
                              idSelected: beneficiary.idBeneficiary,
                            });
                          }}
                        >
                          <i
                            className="fa-regular fa-trash icon"
                            style={{ color: "#ff0000" }}
                          ></i>
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div style={{ marginTop: 32 }}>
            <Typography align="center">{`${t("NoBeneficiary")}`}</Typography>
          </div>
        )}
      </Box>
      {openDrawerBeneficiary && (
        <DrawerBeneficiary
          open={openDrawerBeneficiary}
          handleClose={() => {
            setOpenDrawerBeneficiary(false);
            setBeneficiarySelected(null);
          }}
          data={beneficiarySelected}
          setSnackBar={setSnackBar}
        />
      )}
      {openDeleteDialog.isOpen && (
        <Dialog
          open={openDeleteDialog.isOpen}
          onClose={() => setOpenDeleteDialog(false)}
        >
          <DialogContent style={{ width: 200 }}>
            <Typography variant="h5" align="center">
              {`${t("ConfirmToDelete")}`}
            </Typography>
            <Grid
              container
              justifyContent="space-between"
              style={{ marginTop: 16 }}
            >
              <Grid item>
                <ButtonBlue
                  onClick={() =>
                    setOpenDeleteDialog({ isOpen: false, idSelected: null })
                  }
                >
                  {`${t("Cancel")}`}
                </ButtonBlue>
              </Grid>
              <Grid item>
                <ButtonBlue
                  variant="contained"
                  onClick={() => handleDelete(openDeleteDialog.idSelected)}
                >
                  {`${t("Confirm")}`}
                </ButtonBlue>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      )}
      {openDialogChangePF && (
        <Dialog
          open={openDialogChangePF}
          onClose={() => setOpenDialogChangePF(false)}
        >
          <DialogContent style={{ width: 350, maxWidth: "calc(100% - 48px)" }}>
            <Typography variant="h5" align="center">
              <i
                className="fa-solid fa-triangle-exclamation"
                style={{ marginRight: 16, color: "#FFBF00" }}
              />
              {`${t("ConfirmCumulativeRateChange")}`}
            </Typography>
            <Typography align="center" style={{ marginTop: 16 }}>
              {`${
                UserProvidentFund &&
                UserProvidentFund.firstCutOff &&
                dayjs().isSameOrBefore(
                  dayjs().date(UserProvidentFund.firstCutOff),
                  "date"
                )
                  ? `${t("ChangedCumulativeRateThisMonth")}`
                  : `${t("ChangedCumulativeRateNextMonth")}`
              }`}
            </Typography>
            <Grid
              container
              justifyContent="space-between"
              style={{ marginTop: 16 }}
            >
              <Grid item>
                <ButtonBlue onClick={() => setOpenDialogChangePF(false)}>
                  {`${t("Cancel")}`}
                </ButtonBlue>
              </Grid>
              <Grid item>
                <ButtonBlue variant="contained" onClick={onHandleSubmit}>
                  {`${t("Confirm")}`}
                </ButtonBlue>
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      )}
      {snackBar.isOpen && (
        <Snackbar
          open={snackBar.isOpen}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          onClose={handleCloseNotify}
        >
          <Alert severity={snackBar.result} onClose={handleCloseNotify}>
            {snackBar.label}
          </Alert>
        </Snackbar>
      )}
    </StyledRoot>
  );
};

export default PF;
