import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Grid,
  TextField,
  Button,
  Typography,
  Container,
} from "@mui/material";
import { useForm } from "react-hook-form";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import  {LocalizationProvider}  from "@mui/x-date-pickers/LocalizationProvider";
import  {DatePicker}  from "@mui/x-date-pickers/DatePicker";
import Autocomplete from "@mui/material/Autocomplete";
import { styled } from "@mui/styles";
import dayjs from "dayjs";

import { useSelector, useDispatch } from "react-redux";
import { getUserProfile } from "../../../../../actions/user";
import { postFailureHistory } from "../../../../../actions/FailureHistory";
import { t } from "i18next";

const initialValues = () => ({
  failureID: 0,
  subject: "",
  keyword: "",
  createBy: "",
  dateCreate: new Date(),
  concerned: "",
  details: "",
  keyLearning: "",
  dateProblem: new Date(),
});

const ContainerStyled = styled(Container)({
  marginTop: "100px",
});

function FailureHistory() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user: currentUser } = useSelector((state) => state.auth);
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const {t,i18n} = useTranslation();
  const [values, setValues] = useState(initialValues);
  const { handleSubmit } = useForm();
  const [employees] = useState([]);
  const [tagValues, setTagValues] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const onPostFailureHistory = async (formData) => {
    console.log("formData", formData);
    await dispatch(postFailureHistory(formData));
    history.push("/failurehistorylist");
  };

  const onSubmit = (e) => {
    const newValues = { ...values };
    newValues.concerned = tagString();

    const formData = {
      subject: values.subject,
      keyword: values.keyword,
      createByEmpId: userProfile.idEmployees,
      concerned: newValues.concerned,
      details: values.details,
      keyLearning: values.keyLearning,
      dateProblem: dayjs(values.dateProblem).format("YYYY-MM-DD"),
    };

    onPostFailureHistory(formData);
  };

  const tagString = () => {
    const length = tagValues.length - 1;
    let str = "";
    for (var i = 0; i <= length; i++) {
      if (i === 0) {
        str = tagValues[i].fullName;
      } else {
        str = str + " , " + tagValues[i].fullName;
      }
    }

    return str;
  };

  const onTagChange = (event, newValues) => {
    setTagValues(newValues);
  };

  useEffect(() => {
    if (currentUser) {
      dispatch(getUserProfile(currentUser.username));
    }

    setValues({
      ...values,
      createBy: "Admin",
    });

    /*
    axios
      .get(`${process.env.REACT_APP_API}/employees`)
      .then((res) => {
        const empList = res.data.map((item) => ({
          fullName: item.fullName,
        }));
        setEmployees(empList);
      })
      .catch((err) => console.log(err));
    */
  }, []);

  return (
    <ContainerStyled>
      <div className="card-section">
        <div className="header-section">
          <Grid container>
            <Grid item>
              <h3 className="header-topic">Failure History</h3>
            </Grid>
          </Grid>
        </div>
        <form onSubmit={handleSubmit(onSubmit)} noValidate autoComplete="off">
          <Grid container justifyContent="center" style={{ marginTop: 20 }}>
            <Grid item xl={10} lg={10} md={10} sm={10} xs={10}>
              <Grid container>
                <Grid
                  item
                  xl={3}
                  lg={3}
                  md={4}
                  sm={12}
                  xs={12}
                  style={{ marginBottom: 20 }}
                >
                  <Typography style={{ fontSize: 17, fontWeight: "bold" }}>
                    Main data
                  </Typography>
                  <Typography style={{ fontSize: 15, fontWeight: "normal" }}>
                    description failure history
                  </Typography>
                </Grid>
                <Grid item xl={9} lg={9} md={8} sm={12} xs={12}>
                  <TextField
                    name="subject"
                    variant="outlined"
                    placeholder="Enter Problem / Subject Name"
                    value={values.subject}
                    onChange={handleInputChange}
                    sx={{ paddingBottom: 2 }}
                    /*
                    {...register("subject", { required: true })}
                    error={errors.subject ? true : false}
                    helperText={
                      errors.subject ? "This field is required" : null
                    }
                     */
                    fullWidth
                  />
                  <TextField
                    name="keyword"
                    variant="outlined"
                    placeholder="Enter Keyword"
                    value={values.keyword}
                    onChange={handleInputChange}
                    sx={{ paddingBottom: 2 }}
                    /*
                    {...register("keyword", { required: true })}
                    error={errors.subject ? true : false}
                    helperText={
                      errors.subject ? "This field is required" : null
                    }
                     */
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              xl={10}
              lg={10}
              md={10}
              sm={10}
              xs={10}
              style={{ marginTop: 10 }}
            >
              <Grid container>
                <Grid
                  item
                  xl={3}
                  lg={3}
                  md={4}
                  sm={12}
                  xs={12}
                  style={{ marginBottom: 20 }}
                >
                  <Typography style={{ fontSize: 17, fontWeight: "bold" }}>
                    {t("SecondData")}
                  </Typography>
                  <Typography style={{ fontSize: 15, fontWeight: "normal" }}>
                    {t("DetailOfKeyResult")}
                  </Typography>
                </Grid>
                <Grid item xl={9} lg={9} md={8} sm={12} xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      fullWidth
                      margin="normal"
                      format="dd/MM/yyyy"
                      inputVariant="outlined"
                      value={values.dateProblem}
                      onChange={(date) => {
                        setValues({
                          ...values,
                          dateProblem: date,
                        });
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                  <Autocomplete
                    multiple
                    filterSelectedOptions
                    limitTags={4}
                    options={employees.sort(
                      (a, b) => -b.fullName.localeCompare(a.fullName)
                    )}
                    getOptionLabel={(option) => option.fullName}
                    onChange={onTagChange}
                    sx={{ paddingBottom: 2, paddingTop: 2 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Select Involver"
                      />
                    )}
                  />

                  <TextField
                    name="details"
                    variant="outlined"
                    rows={4}
                    placeholder="Enter Detail Action"
                    value={values.details}
                    sx={{ paddingBottom: 2 }}
                    onChange={handleInputChange}
                    fullWidth
                    multiline
                  />

                  <TextField
                    name="keyLearning"
                    variant="outlined"
                    rows={4}
                    placeholder="Enter Key Learning"
                    value={values.keyLearning}
                    sx={{ paddingBottom: 2 }}
                    onChange={handleInputChange}
                    fullWidth
                    multiline
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {/* <Grid container justifyContent='center'>
            <Grid item xs={10} md={10} lg={10}>
              <Typography variant='body1' gutterBottom>
                Detail Action / Result
              </Typography>
              <TextField
                name='details'
                variant='outlined'
                rows={4}
                placeholder='Enter Detail Action'
                value={values.details}
                onChange={handleInputChange}
                fullWidth
                multiline
              />
            </Grid>
          </Grid> */}
          {/* <Grid container justifyContent='center'>
            <Grid item xs={10} md={10} lg={10}>
              <Typography variant='body1' gutterBottom>
                Key Learning
              </Typography>
              <TextField
                name='keyLearning'
                variant='outlined'
                rows={4}
                placeholder='Enter Key Learning'
                value={values.keyLearning}
                onChange={handleInputChange}
                fullWidth
                multiline
              />
            </Grid>
          </Grid> */}
          <Grid container justifyContent="center">
            <Grid
              container
              item
              xs={10}
              md={10}
              lg={10}
              justifyContent="flex-end"
            >
              <Button
                variant="contained"
                color="primary"
                className="saveButton"
                type="submit"
                style={{ marginTop: "40px" }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </ContainerStyled>
  );
}

export default FailureHistory;
