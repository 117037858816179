import React, { useState, useEffect } from "react";
import Notification from "../../../shared/general/Notification";
import ConfirmDialog from "../../../shared/general/ConfirmDialog";
import { useHistory } from "react-router-dom";
import Pagination from "../../../shared/general/Pagination";
import { styled } from "@mui/styles";
import ConfirmModal from "../../../shared/general/ConfirmModal";
import { useTranslation } from "react-i18next";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import SearchIcon from "@mui/icons-material/Search";
import VpnKeyRoundedIcon from "@mui/icons-material/VpnKeyRounded";
import FlagIcon from "@mui/icons-material/Flag";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import ShowMiniData from "../../../shared/pages/okrs/components/ShowMiniData";

import { useSelector, useDispatch } from "react-redux";
import {
  getAllIdvPlan,
  deleteIdvPlan,
} from "../../../../../actions/IndividualPlans";

import {
  Grid,
  Button,
  Toolbar,
  TextField,
  InputAdornment,
  Typography,
  Avatar,
  LinearProgress,
  Container,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles(() => ({
  root: {
    "& .MuiGrid-container": {
      marginBottom: "0 !important",
    },
    "& .MuiCardContent-root": {
      padding: 0,
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: 16,
    },
  },
  newBtn: {
    color: "#4f65df",
    border: "2px solid #4f65df",
    borderRadius: 16,
    height: 48,
    
  },
  deleteBtn: {
    color: "#e53935",
    fontWeight: "600",
  },
  quater: {
    backgroundColor: "#e53935",
    fontWeight: "600",
    width: 48,
    height: 48,
  },
  PlanList_Object: {
    fontSize: "1.3rem",
    fontWeight: "600",
    color: "#222f3e",
  },
  PlanList_Impact: {
    color: "#9e9e9e",
    marginTop: 8,
  },
  PlanList_KR: {
    color: "#222f3e",
    fontSize: "1.1rem",
    fontWeight: "700",
  },
  moreButton_cardPlan: {
    borderRadius: "50px",
    border: "1px solid rgba(0, 0, 0, 0.23)",
  },
  PlanList_Status: {
    display: "flex",
    alignItems: "center",
    color: "#9e9e9e",
    marginBottom: 8,
  },
  noData_Section: {
    display: "flex",
    alignItems: "center",
  },
  noDataSvg: {
    fontSize: "30px",
    marginRight: 8,
  },
  inProgress: {
    backgroundColor: "#eeeeee",
    color: "#9e9e9e",
    minWidth: "100px",
    borderRadius: "15px",
    padding: "5px 10px 5px 10px",
    textAlign: "center",
    marginLeft: 8,
    "&:hover": {
      backgroundColor: "#eeeeee",
    },
  },
  achieved: {
    backgroundColor: "#e0f2f1",
    color: "#009688",
    width: "90px",
    borderRadius: "15px",
    padding: "5px",
    textAlign: "center",
    marginLeft: 8,
    "&:hover": {
      backgroundColor: "#e0f2f1",
    },
  },
  notAchieved: {
    backgroundColor: "#f9dde0",
    color: "#c62828",
    width: "110px",
    borderRadius: "15px",
    padding: "5px",
    textAlign: "center",
    marginLeft: 8,
    "&:hover": {
      backgroundColor: "#f9dde0",
    },
  },
  viewBtn: {
    cursor: "pointer",
    backgroundColor: "#e6eaff",
    color: "#4f65df",
    width: 40,
    height: 40,
    marginRight: 8,
    marginTop: 8,
  },
  cardList_Edit: {
    cursor: "pointer",
    backgroundColor: "#fff3e0",
    color: "#ff9800",
    width: 40,
    height: 40,
    marginRight: 8,
    marginTop: 8,
  },
  cardList_Delete: {
    cursor: "pointer",
    backgroundColor: "#ffebee",
    color: "#ef5350",
    width: 40,
    height: 40,
    marginRight: 8,
    marginTop: 8,
  },
  cardList_KR: {
    backgroundColor: "#ffebee",
    color: "#f44336",
  },
}));

const ContainerStyled = styled(Container)({
  marginTop: "100px",
});

function IndividualPlanList() {
  const history = useHistory();
  const classes = useStyles();
  const {t,i18n} = useTranslation();
  const dispatch = useDispatch();
  const { result: idvPlanStore } = useSelector((state) => state.IdvPlans);

  const [planList, setPlanList] = useState([]);
  const [planItems, setPlanItems] = useState([]);
  const [values, setValues] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(5);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = planList.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const fetchData = () => {
    dispatch(getAllIdvPlan());
  };

  const onDeleteIdvPlan = async (formData) => {
    await dispatch(deleteIdvPlan(formData));
  };

  const onDelete = (id) => {
    //Delete OrgPlan
    onDeleteIdvPlan(id);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    setNotify({
      isOpen: true,
      message: "Deleted Successfully",
      type: "success",
    });
    fetchData();
  };

  useEffect(() => {
    if (idvPlanStore) {
      let idvPlanList = [...idvPlanStore];

      /*
      idvPlanList.filter(function (item) {
        return item.createBy.includes(name);
      });
      */

      setPlanItems(idvPlanList);
      setPlanList(idvPlanList);
      setIsLoading(false);
    }
  }, [idvPlanStore]);

  useEffect(() => {
    fetchData();
  }, [confirmModal]);

  useEffect(() => {
    let x = [...planItems];
    x = x.filter((y) => {
      return y.objectiveName
        .toLowerCase()
        .includes(searchKey.toLocaleLowerCase());
    });
    setPlanList(x);
  }, [searchKey]);

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <ContainerStyled>
      <div className="card-section">
        <div className="header-section">
          <Grid container>
            <Grid item>
              <h3 className="header-topic">Individual Planning List</h3>
            </Grid>
          </Grid>
        </div>
        <Toolbar>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Button
                variant="outlined"
                className={classes.newBtn}
                onClick={() => {
                  history.push({
                    pathname: "/individual",
                    state: { planList: undefined },
                  });
                }}
              >
                Add New OKR
              </Button>
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <TextField
                variant="outlined"
                style={{ width: "100%" }}
                label="Search Objective"
                value={searchKey}
                onChange={(e) => setSearchKey(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Toolbar>
      </div>
      {isLoading ? (
        <LinearProgress />
      ) : (
        <>
          {(planList.length > 0 &&
            currentPosts.map((item) => {
              return (
                <div key={item.planIdvId}>
                  <Typography
                    variant="h6"
                    style={{ marginTop: "40px" }}
                    className="keyResult"
                  >
                    <FlagIcon fontSize="large" className="svgKey" />
                    Objective
                  </Typography>
                  <div className="cardPlan_Objective">
                    <Grid
                      container
                      alignItems="flex-start"
                      alignContent="space-between"
                    >
                      <Grid item xs={3} sm={2} md={1} lg={1} xl={1}>
                        <Avatar className={classes.quater}>
                          {item.quater}
                        </Avatar>
                      </Grid>
                      <Grid item xs={7} sm={7} md={8} lg={8} xl={8}>
                        <Typography
                          className={classes.PlanList_Object}
                          gutterBottom
                        >
                          {item.objectiveName}
                        </Typography>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={2}
                        sm={3}
                        md={3}
                        lg={3}
                        xl={3}
                        justifyContent="flex-end"
                      >
                        <Avatar
                          className={classes.viewBtn}
                          onClick={() => {
                            history.push({
                              pathname: "/individual",
                              state: { planList: item },
                              isInfo: true,
                            });
                          }}
                        >
                          <SearchIcon />
                        </Avatar>
                        {item.planStatus === "Waiting Approval" ? (
                          <>
                            <Avatar
                              className={classes.cardList_Edit}
                              onClick={() => {
                                history.push({
                                  pathname: "/individual",
                                  state: { planList: item },
                                });
                              }}
                            >
                              <EditRoundedIcon />
                            </Avatar>
                            <Avatar
                              className={classes.cardList_Delete}
                              onClick={() => {
                                setConfirmDialog({
                                  isOpen: true,
                                  title: "Are you sure you want to delete ?",
                                  subTitle: "You can't undo this operation",
                                  onConfirm: () => {
                                    onDelete(item.planIdvId);
                                  },
                                });
                              }}
                            >
                              <DeleteForeverIcon />
                            </Avatar>
                          </>
                        ) : null}
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={2} sm={2} md={1} lg={1} xl={1}></Grid>
                      <Grid item xs={10} sm={10} md={8} lg={8} xl={8}>
                        <Typography className={classes.PlanList_Status}>
                          {item.impactName}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={2} sm={2} md={1} lg={1} xl={1}></Grid>
                      <Grid item xs={10} sm={10} md={8} lg={8} xl={8}>
                        <ShowMiniData
                          date={item.dateCreate}
                          status={item.planStatus}
                          progress={item.achieveResult}
                        />
                      </Grid>
                    </Grid>
                  </div>
                  <Typography variant="h6" className="keyResult">
                    <VpnKeyRoundedIcon fontSize="large" className="svgKey" />
                    {t("KeyResult")}
                  </Typography>
                  <div className="cardPlan">
                    <Grid container alignItems="center">
                      <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
                        <Avatar className={classes.cardList_KR}>
                          <KeyboardArrowRightIcon />
                        </Avatar>
                      </Grid>
                      <Grid item xs={10} sm={10} md={8} lg={8} xl={8}>
                        <Typography
                          className={classes.PlanList_KR}
                          gutterBottom
                        >
                          {item.keyResult1}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={2} sm={2} md={1} lg={1} xl={1}></Grid>
                      <Grid item xs={10} sm={10} md={8} lg={8} xl={8}>
                        <ShowMiniData
                          date={item.dateCreate}
                          status={item.kR1Status}
                          progress={item.achieveResultKR1}
                        />
                      </Grid>
                    </Grid>
                    {item.approvedBy ? (
                      <Grid container>
                        <Grid item xs={2} sm={2} md={1} lg={1} xl={1}></Grid>
                        <Grid item xs={10} sm={10} md={8} lg={8} xl={8}>
                          <Typography className={classes.PlanList_Status}>
                            Approved By : {item.approvedBy}
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : null}
                  </div>
                  {item.keyResult2 ? (
                    <div className="cardPlan">
                      <Grid container alignItems="center">
                        <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
                          <Avatar className={classes.cardList_KR}>
                            <KeyboardArrowRightIcon />
                          </Avatar>
                        </Grid>
                        <Grid item xs={10} sm={10} md={8} lg={8} xl={8}>
                          <Typography className={classes.PlanList_KR}>
                            {item.keyResult2}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={2} sm={2} md={1} lg={1} xl={1}></Grid>
                        <Grid item xs={10} sm={10} md={8} lg={8} xl={8}>
                          <ShowMiniData
                            date={item.dateCreate}
                            status={item.kR2Status}
                            progress={item.achieveResultKR2}
                          />
                        </Grid>
                      </Grid>
                      {item.approvedBy ? (
                        <Grid container>
                          <Grid item xs={2} sm={2} md={1} lg={1} xl={1}></Grid>
                          <Grid item xs={10} sm={10} md={8} lg={8} xl={8}>
                            <Typography className={classes.PlanList_Status}>
                              Approved By : {item.approvedBy}
                            </Typography>
                          </Grid>
                        </Grid>
                      ) : null}
                    </div>
                  ) : null}
                  {item.keyResult3 ? (
                    <div className="cardPlan" style={{ marginBottom: "30px" }}>
                      <Grid container alignItems="center">
                        <Grid item xs={2} sm={2} md={1} lg={1} xl={1}>
                          <Avatar className={classes.cardList_KR}>
                            <KeyboardArrowRightIcon />
                          </Avatar>
                        </Grid>
                        <Grid item xs={10} sm={10} md={8} lg={8} xl={8}>
                          <Typography className={classes.PlanList_KR}>
                            {item.keyResult3}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid container>
                        <Grid item xs={2} sm={2} md={1} lg={1} xl={1}></Grid>
                        <Grid item xs={10} sm={10} md={8} lg={8} xl={8}>
                          <ShowMiniData
                            date={item.dateCreate}
                            status={item.kR3Status}
                            progress={item.achieveResultKR3}
                          />
                        </Grid>
                      </Grid>
                      {item.approvedBy ? (
                        <Grid container>
                          <Grid item xs={2} sm={2} md={1} lg={1} xl={1}></Grid>
                          <Grid item xs={10} sm={10} md={8} lg={8} xl={8}>
                            <Typography className={classes.PlanList_Status}>
                              Approved By : {item.approvedBy}
                            </Typography>
                          </Grid>
                        </Grid>
                      ) : null}
                    </div>
                  ) : null}
                </div>
              );
            })) || (
            <div className="cardPlan">
              <Grid container justifyContent="center">
                <Grid item>
                  <Typography variant="h6" className={classes.noData_Section}>
                    <FindInPageIcon className={classes.noDataSvg} />
                    No Data
                  </Typography>
                </Grid>
              </Grid>
            </div>
          )}
        </>
      )}
      {planList.length > 0 ? (
        <Pagination
          paginate={paginate}
          postsPerPage={postsPerPage}
          totalPosts={planList.length}
        />
      ) : null}
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      <ConfirmModal
        confirmModal={confirmModal}
        setConfirmModal={setConfirmModal}
        values={values}
      />
    </ContainerStyled>
  );
}

export default IndividualPlanList;
