import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Route, Redirect } from "react-router-dom";
import { styled } from "@mui/material/styles";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { CardContent, Typography, Container, Stack } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import DataGrid, {
  Column,
  Paging,
  Pager,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  Scrolling,
  Sorting,
  Selection,
  TotalItem,
  Summary,
  Export,
  ColumnChooser,
  ColumnChooserSearch,
  ColumnChooserSelection,
  Position,
} from "devextreme-react/data-grid";

import StyledCard from "../../../shared/general/Card";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import utils from "../../../../../utils";

import { getPayrunsSummaryYear } from "../../../../../actions/payruns";
import { PayrollXlsxFile } from "./payrollXlsxFile";

const StyledRoot = styled("div")({
  minHeight: 700,
  backgroundColor: "#FFFFFF !important",
  "& .wrap-btn": {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 16,
    marginTop: 16,
  },
});

const StyledCardContent = styled(CardContent)({
  "& .wrap-header": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .sub-header-1": {
    fontWeight: 500,
    fontSize: 18,
    textDecoration: "underline",
  },
  "& .sub-header": {
    fontWeight: 500,
  },
  "& .header-item": {
    fontSize: "1.25rem",
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
  },
  "& .wrap-item-data": {
    marginLeft: 16,
    marginBottom: 8,
  },
  "& .wrap-check": {
    display: "flex",
    alignItems: "center",
    "& .MuiCheckbox-root": {
      paddingLeft: 0,
    },
  },
  "& .item-name": {
    fontWeight: 600,
    marginBottom: 4,
  },
  "& .wrap-label-switch": {
    display: "flex",
    justifyContent: "space-between",
  },
  "& .custom-date-payment": {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 8,
    alignItems: "center",
  },
});

const StyledWrapDataGrid = styled("div")({
  '& .column-info[role="columnheader"]': {
    backgroundColor: "#283593",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-ot[role="columnheader"]': {
    backgroundColor: "#7c4dff",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-shift[role="columnheader"]': {
    backgroundColor: "#2196f3",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-salary[role="columnheader"]': {
    backgroundColor: "#00796b",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-addition[role="columnheader"]': {
    backgroundColor: "#26a69a",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-earnings-all[role="columnheader"]': {
    backgroundColor: "#005e55",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-earnings[role="columnheader"]': {
    backgroundColor: "#00897b",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-sso[role="columnheader"], .column-tax[role="columnheader"], .column-pf[role="columnheader"]':
  {
    backgroundColor: "#ef5350",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-deduction[role="columnheader"]': {
    backgroundColor: "#e53935",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-deduction[role="columnheader"]': {
    backgroundColor: "#c62828",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-total-deduction-all[role="columnheader"]': {
    backgroundColor: "#a30000",
    color: "#FFFFFF",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#FFFFFF",
    },
  },
  '& .column-net[role="columnheader"]': {
    backgroundColor: "#ffd600",
    color: "#313f4c",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#313f4c",
    },
  },
  '& .column-accumulate[role="columnheader"]': {
    backgroundColor: "#ff9100",
    color: "#313f4c",
    fontWeight: "500",
    "& .dx-header-filter": {
      color: "#313f4c",
    },
  },
});

const PayrollSummaryYearPage = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { year, idCompany } = useParams();
  const today = dayjs().toDate();
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: payRunSummaryYear } = useSelector(
    (state) => state.payRunSummaryYear
  );

  const [isPayroll, setIsPayroll] = useState(null);
  const [selectedRowsData, setSelectedRowsData] = React.useState(null);
  const [gridColumnsAddition, setGridColumnsAddition] = React.useState([]);
  const [gridColumnsDeduction, setGridColumnsDeduction] = React.useState([]);
  const [data, setData] = React.useState(null);
  const [showHide, setShowHide] = useState({
    general: "hide",
    lateAbsent: "hide",
    otShift: "hide",
  });

  let gridColumnsOT = [
    {
      dataField: "otOneHours",
      caption: `OT 1 (${t("Unit.ShortHours")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
    },
    {
      dataField: "otOneAmount",
      caption: `OT 1 (${t("Baht")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
    },
    {
      dataField: "otOneFiveHours",
      caption: `OT 1.5 (${t("Unit.ShortHours")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
    },
    {
      dataField: "otOneFiveAmount",
      caption: `OT 1.5 (${t("Baht")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
    },
    {
      dataField: "otTwoHours",
      caption: `OT 2 (${t("Unit.ShortHours")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
    },
    {
      dataField: "otTwoAmount",
      caption: `OT 2 (${t("Baht")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
    },
    {
      dataField: "otThreeHours",
      caption: `OT 3 (${t("Unit.ShortHours")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
    },
    {
      dataField: "otThreeAmount",
      caption: `OT 3 (${t("Baht")})`,
      format: "###,###,##0.00",
      cssClass: "column-ot",
    },
  ];

  let gridColumnsShift = [
    {
      dataField: "shiftMorning",
      caption: `${t("ShiftPayMorning")}`,
      format: "###,###,###.##",
      cssClass: "column-shift",
    },
    {
      dataField: "shiftNoon",
      caption: `${t("ShiftPayAfternoon")}`,
      format: "###,###,###.##",
      cssClass: "column-shift",
    },
    {
      dataField: "shiftNight",
      caption: `${t("ShiftPayNight")}`,
      format: "###,###,###.##",
      cssClass: "column-shift",
    },
  ];

  useEffect(() => {
    dispatch(getPayrunsSummaryYear(year, idCompany));
  }, []);

  useEffect(() => {
    if (payRunSummaryYear && payRunSummaryYear.length > 0) {
      let temp = [...payRunSummaryYear];
      temp.forEach((element, index) => {
        element["index"] = index;
      });

      setData(temp);
    }
  }, [payRunSummaryYear]);

  const customizeNumber = (data) => {
    return data.value
      ? `${utils.numberWithCommas(data.value)} ${t("Baht")}`
      : "";
  };

  const customizeHours = (data) => {
    return data.value
      ? `${utils.numberWithCommas(data.value)} ${t("Unit.ShortHours")}`
      : "";
  };

  return (
    <Fragment>
      {userProfile && (
        <Fragment>
          {isPayroll === false ? (
            <Route>
              <Redirect to="/unauthorized" />
            </Route>
          ) : (
            <StyledRoot className={`page`}>
              <Container maxWidth="lg">
                <div style={{ marginBottom: 8 }}>
                  <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    alignItem="center"
                  >
                    <Typography variant="h4" style={{ paddingTop: 8 }}>
                      {`${t("MoreThan")} ${year}`}
                    </Typography>
                    <ButtonBlue
                      variant="outlined"
                      startIcon={<FileDownloadIcon />}
                      onClick={() => PayrollXlsxFile(year)}
                      diabled={(data && data.length < 1) || !data}
                    >
                      {t("DownloadData")}
                    </ButtonBlue>
                  </Stack>
                </div>
                <StyledCard>
                  <StyledCardContent>
                    <StyledWrapDataGrid>
                      <DataGrid
                        dataSource={data ? data : []}
                        showBorders={false}
                        showColumnLines={false}
                        rowAlternationEnabled={true}
                        columnAutoWidth={true}
                      >
                        <ColumnChooser enabled={true} mode={"select"} title={t("ColumnChooser")}>
                          <Position
                            my="right top"
                            at="right bottom"
                            of=".dx-datagrid-column-chooser-button"
                          />

                          <ColumnChooserSearch
                            enabled={true}
                            editorOptions={{ placeholder: "Search column" }}
                          />

                          <ColumnChooserSelection
                            allowSelectAll={true}
                            selectByClick={true}

                          />
                        </ColumnChooser>

                        <Column
                          caption={`${t("No_Number")}`}
                          dataField="index"
                          width={60}
                          allowFiltering={false}
                          alignment="center"
                          cssClass="column-info"
                        />

                        <Column
                          caption={`${t("EmployeeID")}`}
                          dataField="employeeID"
                          dataType="string"
                          width={120}
                          cssClass="column-info"
                        />

                        <Column
                          caption={`${t("Title")}`}
                          dataType="string"
                          cssClass="column-info"
                          dataField="title_TH"
                        ></Column>
                        <Column
                          caption={`${t("FirstName")}`}
                          dataType="string"
                          cssClass="column-info"
                          dataField="firstname_TH"
                        >
                          <HeaderFilter allowSearch={true} />
                        </Column>
                        <Column
                          caption={`${t("LastName")}`}
                          dataType="string"
                          cssClass="column-info"
                          dataField="lastname_TH"
                        >
                          <HeaderFilter allowSearch={true} />
                        </Column>

                        <Column
                          dataField="personalID"
                          caption={`${t("PersonalID")}`}
                          cssClass="column-info"
                        />

                        <Column
                          dataField="accumulateEarnings"
                          caption={t("CumulativeIncome")}
                          cssClass="column-total-earnings"
                          format="###,###,##0.00"
                        />
                        <Column
                          dataField="accumulateTax"
                          caption={t("AccumulatedTax")}
                          cssClass="column-total-earnings"
                          format="###,###,##0.00"
                        />
                        <Column
                          dataField="accumulateSSO"
                          caption={t("CumulativeSS")}
                          cssClass="column-total-earnings"
                          format="###,###,##0.00"
                        />
                        <Column
                          dataField="accumulatePF"
                          caption={t("CumulativePF")}
                          cssClass="column-total-earnings"
                          format="###,###,##0.00"
                        />

                        <Column
                          dataField="totalEarningsOfYear"
                          caption={t("TotalIncomeForTheYear")}
                          cssClass="column-deduction"
                          format="~###,###,##0.00"
                        />

                        <Column
                          dataField="taxThisYear"
                          caption={t("TaxPayableThisYear")}
                          cssClass="column-shift"
                          format="###,###,##0.00"
                        />
                        <Column
                          dataField="taxBalance"
                          caption={t("Excess/Shortfall")}
                          cssClass="column-net"
                          format="###,###,##0.00"
                        />

                        <Export
                          enabled={true}
                          allowExportSelectedData={true}
                          fileName={t("AppMenu.SummaryTime")}
                          texts={{
                            exportAll: t("DownloadAllDataToExcel"),
                            exportSelectedRows: t(
                              "DownloadSelectedRowsToExcel"
                            ),
                          }}
                        />
                        <Paging defaultPageSize={20} />
                        <Pager
                          visible={true}
                          allowedPageSizes={[10, 20, 30]}
                          showPageSizeSelector={true}
                          showNavigationButtons={true}
                          showInfo={true}
                        />
                        <Selection mode="single" />
                        <Sorting mode="multiple" />
                        <Scrolling columnRenderingMode="virtual" />
                        <FilterRow visible={false} />
                        <HeaderFilter visible={true} />
                        <SearchPanel
                          visible={true}
                          width={240}
                          placeholder={t("Search")}
                        />
                      </DataGrid>
                    </StyledWrapDataGrid>
                  </StyledCardContent>
                </StyledCard>
              </Container>
            </StyledRoot>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default PayrollSummaryYearPage;
