import React, { useEffect, Fragment } from "react";
import {
  Typography,
  Grid,
  Box,
  RadioGroup,
  FormControlLabel,
  FormHelperText,
  Radio,
  Divider,
  CircularProgress,
  Stack,
  Switch,
  FormGroup,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import PersonIcon from '@mui/icons-material/Person';
import DomainIcon from '@mui/icons-material/Domain';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EventIcon from '@mui/icons-material/Event';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import dayjs from "dayjs";
import { Controller, useForm, useFieldArray, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import NumberFormat from "react-number-format";

import ButtonBlue from "../../shared/general/ButtonBlue";
import DrawerCustom from "../../shared/general/Drawer";
import TextFieldTheme from "../../shared/general/TextFieldTheme";

import { useDispatch, useSelector } from "react-redux";
import { updateProbationManager } from "../../../../actions/probation";
//Translator TH-EN
import { useTranslation } from "react-i18next";
import { getUserFullName } from "../../../../utils/userData";

const StyledRoot = styled("div")({
  maxWidth: 550,
  padding: 24,
  "& .GridTopicInput": {
    display: "flex",
    alignItems: "center",
  },
});

const StyledFooter = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  gap: "8px",
  marginTop: "24px",
});

const NumberFormatTheme = React.forwardRef((props, ref) => {
  return <NumberFormat {...props} getInputRef={ref} />;
});

const score = {
  "verygood": 100,
  "good": 80,
  "meduim": 60,
  "fair": 40,
  "prettybad": 20,
  "bad":0,
}

const percentageScore = (questionList = []) => {
  let totalRating = 0
  let answerLength = 0
  for (const item of questionList) {
    if (item.answer) {
      answerLength += 1;
      totalRating += score[item.answer];
    }
  }

  return answerLength > 0 ? Math.round(totalRating / answerLength) : 0;
};

const DialogProbation = (props) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const { open, handleClose, value, handleSubmitProbation } = props;
  const { result: probationQuestion } = useSelector((state) => state.probationQuestion);
  const { result: userProfile } = useSelector((state) => state.userProfile);

  const validationSchema = yup.object().shape({
    isExpand: yup.boolean(),
    statusProbation: yup.number().nullable().when("isExpand", {
      is: (val) => !val,
      then: (schema) => schema.required(t("PleaseSelectStatus"))
    }),
    questionList: yup.array().when("isExpand", {
      is: (val) => !val,
      then: (schema) =>
        schema.of(
          yup.object().shape({
            answer: yup.string().required(`${t("ThisFieldIsRequired")}`),
          })
        ),
    }),
    goodPoint: yup.string().max(300, `${t("NotMoreThan")} 300 ${t("Characters")}`),
    weakPoint: yup.string().max(300, `${t("NotMoreThan")} 300 ${t("Characters")}`),
    others: yup.string().max(300, `${t("NotMoreThan")} 300 ${t("Characters")}`),
    expandTime: yup
      .number()
      .integer()
      .transform((val, orig) => (orig === "" ? undefined : val))
      .when("isExpand", {
        is: (val) => val,
        then: (schema) =>
          schema
            .min(1, `${t("NotLessThan")} 1 ${t("Unit.Days")}`)
            .max(60, `${t("NotMoreThan")} 60 ${t("Unit.Days")}`)
            .nullable()
            .required(t("ThisFieldIsRequired")),
      }),
    reasonExpandTime: yup.string().when("isExpand", {
      is: (val) => val,
      then: (schema) =>
        schema
          .min(1, t("ThisFieldIsRequired"))
          .max(300, `${t("NotMoreThan")} 300 ${t("Characters")}`),
    }),
    reasonTermination: yup.string().when("statusProbation", {
      is: (val) => val === 0,
      then: (schema) =>
        schema
          .min(1, t("ThisFieldIsRequired"))
          .max(300, `${t("NotMoreThan")} 300 ${t("Characters")}`),
    }),
  });

  const { control, handleSubmit, formState: { isSubmitting } } = useForm({
    defaultValues: {
      questionList: [],
      goodPoint: "",
      weakPoint: "",
      others: "",
      statusProbation: null,
      isExpand: false,
      expandTime: "",
      reasonExpandTime: "",
      reasonTermination: "",
      commentManagerLV1: "",
    },
    resolver: yupResolver(validationSchema),
  });

  const questionUseFieldArray = useFieldArray({
    control: control,
    name: "questionList",
  });

  const questionList = useWatch({ control, name: "questionList" })
  const isExpand = useWatch({ control, name: "isExpand" })
  const statusProbation = useWatch({ control, name: "statusProbation" })

  const onSubmit = async (formData) => {
    const cleanQuestion = formData.questionList.map(item => ({
      idProbationQuestion: item.idProbationQuestion,
      answer: item.answer,
    }))

    const cleansingForm = { 
      ...formData,
      idProbation: value.idProbation,
      idEmployees: value.idEmployees,
      probationRound: value.probationRound,
      questionList: cleanQuestion,
      result: formData.statusProbation,
      reasonTermination: formData.statusProbation === 0 ? formData.reasonTermination : null
    };

    const response = await dispatch(updateProbationManager(cleansingForm));
    handleSubmitProbation(response);
  };

  useEffect(() => {
    if (probationQuestion) {
      const activeQuestion = probationQuestion
        .filter((item) => item.isActive)
        .map((item) => ({
          idProbationQuestion: item.idProbationQuestion,
          question: item.question,
          answer: "",
        }));
      
      questionUseFieldArray.replace(activeQuestion);
    }
  }, []);

  return (
    <DrawerCustom
      title={`${t("ProbationEvaluationForm")}`}
      anchor="right"
      open={open}
      onClose={handleClose}
    >
      {!questionUseFieldArray.fields.length === 0 &&
      probationQuestion.length > 0 ? (
        <CircularProgress />
      ) : (
        <StyledRoot>
          <Grid container spacing={2} marginBottom={6}>
            <Grid item xs={12} display={"flex"} alignItems={"center"} gap={1}>
              <PersonIcon />
              <Typography>
                {` ${t("PersonBeingAssessed")} : ${value.firstname_TH} ${
                  value.lastname_TH
                }`}{" "}
              </Typography>
            </Grid>
            <Grid item xs={12} display={"flex"} alignItems={"center"} gap={1}>
              <DomainIcon />
              <Typography>
                {`${t("Position")} : ${value.positionName}`}{" "}
              </Typography>
            </Grid>
            <Grid item xs={12} display={"flex"} alignItems={"center"} gap={1}>
              <AssessmentIcon />
              <Typography>
                {`${t("ProbationRound")} : ${value.probationRound}`}
              </Typography>
            </Grid>
            {value.expandTime && (
              <Grid item xs={12} display={"flex"} alignItems={"center"} gap={1}>
                <AddCircleIcon />
                <Typography>
                  {`${t("ExtendProbationPeriod")}: ${value.expandTime} ${t("Unit.Days")}`}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12} display={"flex"} alignItems={"center"} gap={1}>
              <EventIcon />
              <Typography>
                {`${t("ProbationaryExpirationDate")} :  ${dayjs(
                  value.probationDate
                ).format(
                  i18n.resolvedLanguage === "th" ? "D MMM BBBB" : "D MMM YYYY"
                )}`}
              </Typography>
            </Grid>
            <Grid item xs={12} display={"flex"} alignItems={"center"} gap={1}>
              <CheckCircleIcon />
              <Typography>
                {`${t("EvaluatedBy")} : ${getUserFullName(userProfile)}`}
              </Typography>
            </Grid>
          </Grid>

          <form onSubmit={handleSubmit(onSubmit)}>
            {!value.expandTime && (
              <Box marginBottom={2}>
                <Controller
                  control={control}
                  name={"isExpand"}
                  render={({ field }) => (
                    <FormGroup>
                      <FormControlLabel
                        control={<Switch {...field} checked={field.value} />}
                        label={t("ExtendPeriod")}
                        />
                    </FormGroup>
                  )}
                />
              </Box>
            )}
            {isExpand ? (
              <Stack spacing={2} marginBottom={4}>
                <div>
                  <Typography marginBottom={"8px"}>{`ระยะเวลาที่ขยายการทดลองงาน (${t("Unit.Day")})`}</Typography>
                  <Controller
                    control={control}
                    name={"expandTime"}
                    render={({ field, fieldState }) => (
                      <TextFieldTheme
                        {...field}
                        fullWidth
                        InputProps={{
                          inputComponent: NumberFormatTheme,
                        }}
                        inputProps={{
                          onValueChange: (values) => {
                            const { value } = values;
                            field.onChange(value);
                          },
                          value: field.value,
                        }}
                        onChange={() => {}}
                        error={Boolean(fieldState.error)}
                        helperText={fieldState.error && fieldState.error.message}
                      />
                    )}
                  />
                </div>
                <div>
                  <Typography marginBottom={1}>{t("ReasonForExtendingProbation")}</Typography>
                  <Controller
                    control={control}
                    name={"reasonExpandTime"}
                    render={({ field, fieldState }) => (
                      <TextFieldTheme
                        {...field}
                        multiline
                        rows={2}
                        error={Boolean(fieldState.error)}
                        helperText={fieldState.error && fieldState.error.message}
                      />
                    )}
                  />
                </div>
              </Stack>
            ) : (
              <>
                <Grid container spacing={1} marginBottom={4}>
                  {questionUseFieldArray.fields.map((question, index) => (
                    <Grid key={question.id} item>
                      <Grid item xs={12}>
                        <i className="fa-regular fa-square-question fa-xl"></i>
                        &nbsp;&nbsp;
                        {`${t("Question")} ${index + 1} : ${question.question}`}
                      </Grid>
                      <Grid item xs={12} marginBottom={1}>
                        <Controller
                          control={control}
                          name={`questionList.${index}.answer`}
                          render={({ field, fieldState }) => (
                            <>
                              <RadioGroup
                                {...field}
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                              >
                                <FormControlLabel
                                  value="verygood"
                                  control={<Radio />}
                                  label={`${t("Excellence")}`}
                                />
                                <FormControlLabel
                                  value="good"
                                  control={<Radio />}
                                  label={`${t("Good")}`}
                                />
                                <FormControlLabel
                                  value="meduim"
                                  control={<Radio />}
                                  label={`${t("Neutral")}`}
                                />
                                <FormControlLabel
                                  value="fair"
                                  control={<Radio />}
                                  label={`${t("Fair")}`}
                                />
                                <FormControlLabel
                                  value="prettybad"
                                  control={<Radio />}
                                  label={`${t("Poor")}`}
                                />
                                <FormControlLabel
                                  value="bad"
                                  control={<Radio />}
                                  label={`${t("VeryPoor")}`}
                                />
                              </RadioGroup>
                              {Boolean(fieldState.error) && (
                                <FormHelperText sx={{ color: "#ff0000" }}>
                                  {fieldState.error.message}
                                </FormHelperText>
                              )}
                            </>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Divider sx={{ borderColor: "#9e9e9e66" }} />
                      </Grid>
                    </Grid>
                  ))}
                  {questionUseFieldArray.fields.length <= 0 && (
                    <Grid item xs={12}>
                      <Typography textAlign="center" color="text.secondary">{`${t(
                        "NoQuestions"
                      )}`}</Typography>
                    </Grid>
                  )}
                </Grid>
                <Grid container spacing={2} marginBottom={4}>
                  <Grid item xs={12}>
                    <div style={{ display: "flex", alignItems: "center", gap: "8px", marginBottom: "8px" }}>
                      <i className="fa-regular fa-bone fa-xl"></i>
                      <Typography>{t("Strength")}</Typography>
                    </div>
                    <Controller
                      control={control}
                      name={`goodPoint`}
                      render={({ field, fieldState }) => (
                        <TextFieldTheme
                          {...field}
                          autoComplete="goodPoint"
                          multiline
                          rows={3}
                          error={Boolean(fieldState.error)}
                          helperText={fieldState.error && fieldState.error.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <div style={{ display: "flex", alignItems: "center", gap: "8px", marginBottom: "8px" }}>
                      <i className="fa-regular fa-bone-break fa-xl"></i>
                      <Typography>{t("Weakness")}</Typography>
                    </div>
                    <Controller
                      control={control}
                      name={`weakPoint`}
                      render={({ field, fieldState }) => (
                        <TextFieldTheme
                          {...field}
                          multiline
                          rows={3}
                          autoComplete="weakPoint"
                          error={Boolean(fieldState.error)}
                          helperText={fieldState.error && fieldState.error.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography marginBottom={"8px"}>{t("Other")}</Typography>
                    <Controller
                      control={control}
                      name={`others`}
                      render={({ field, fieldState }) => (
                        <TextFieldTheme
                          {...field}
                          multiline
                          rows={2}
                          autoComplete="others"
                          error={Boolean(fieldState.error)}
                          helperText={fieldState.error && fieldState.error.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography marginBottom={"8px"}>{t("AdditionalCommentsFromSupervisor")}</Typography>
                    <Controller
                      control={control}
                      name={"commentManagerLV1"}
                      render={({ field, fieldState }) => (
                        <TextFieldTheme
                          {...field}
                          multiline
                          rows={2}
                          error={Boolean(fieldState.error)}
                          helperText={fieldState.error && fieldState.error.message}
                        />
                      )}
                    />
                  </Grid>
                  <Grid 
                    item 
                    xs={12} 
                    marginTop={3} 
                    display={"flex"} 
                    justifyContent={"center"}
                  >
                    <Box style={{ position: "relative", display: "inline-flex" }}>
                      <CircularProgress
                        variant="determinate"
                        style={{
                          color: "#eeeeee",
                        }}
                        size={70}
                        thickness={4}
                        value={100}
                      />
                      <CircularProgress
                        variant="determinate"
                        value={percentageScore(questionList)}
                        style={{
                          color: "#007afe",
                          animationDuration: "550ms",
                          position: "absolute",
                          left: 0,
                        }}
                        size={70}
                      ></CircularProgress>
                      <Box
                        style={{
                          top: 0,
                          left: 0,
                          bottom: 0,
                          right: 0,
                          position: "absolute",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography
                          variant="h6"
                          style={{ fontSize: 16 }}
                          component="div"
                          color="text.third"
                        >
                          {percentageScore(questionList)}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Controller
                      control={control}
                      name={"statusProbation"}
                      render={({ field, fieldState }) => (
                        <Fragment>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-around",
                              align: "center",
                            }}
                          >
                            <ButtonBlue
                              sx={{ backgroundColor: "#4caf50" }}
                              variant="contained"
                              disabled={statusProbation === 1}
                              onClick={() => field.onChange(1)}
                            >
                              {t("Pass")}
                            </ButtonBlue>
                            <ButtonBlue
                              sx={{ backgroundColor: "#ef5350" }}
                              variant="contained"
                              disabled={statusProbation === 0}
                              onClick={() => field.onChange(0)}
                            >
                              {t("NotPass")}
                            </ButtonBlue>
                          </div>
                          {Boolean(fieldState.error) && (
                            <FormHelperText sx={{ color: "#ff0000" }}>
                              {fieldState.error.message}
                            </FormHelperText>
                          )}
                        </Fragment>
                      )}
                    />
                  </Grid>
                </Grid>
                {statusProbation === 0 && (
                  <Box marginBottom={4}>
                    <Typography marginBottom={1}>{t("ReasonForNotPassingProbation")}</Typography>
                    <Controller
                      control={control}
                      name={"reasonTermination"}
                      render={({ field, fieldState }) => (
                        <TextFieldTheme
                          {...field}
                          multiline
                          rows={2}
                          error={Boolean(fieldState.error)}
                          helperText={fieldState.error && fieldState.error.message}
                        />
                      )}
                    />
                  </Box>
                )}
              </>
            )}
            
            <StyledFooter>
              <ButtonBlue type="button" onClick={handleClose}>
                {t("Cancel")}
              </ButtonBlue>
              <ButtonBlue type="submit" variant="contained" disabled={isSubmitting}>
                {t("SaveData")}
              </ButtonBlue>
            </StyledFooter>
          </form>
        </StyledRoot>
      )}
    </DrawerCustom>
  );
};

export default DialogProbation;
