import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Grid,
  styled,
  Container,
  CircularProgress,
  Breadcrumbs,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ButtonBlue from "../../../shared/general/ButtonBlue";
import DialogProbation from "./DialogProbation";
import dayjs from "dayjs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateProbationAdmin } from "../../../../../actions/probation";
import {
  getAllEmployees,
  getProbationEmployeesStatus,
} from "../../../../../actions/employee";
import { ProbationXlsxFile } from "./ProbationXlsxFile";

//Translator TH-EN
import { useTranslation } from "react-i18next";
import DatePickerCustom from "../../../shared/date/datePicker";
import TableProbationStatus from "./tableProbationStatus";
import AlertResponse from "../../../shared/general/AlertResponse";
import CardStyle from "../../../shared/general/Card";
import { getAffiliateOrganization } from "../../../../../actions/company";
import SelectAffiliateCompany from "../../../shared/general/SelectAffiliateCompany";
import LoadingGif from "../../../assets/social-media.gif";

const StyledRoot = styled(Box)({
  backgroundColor: "#FFFFFF !important",
  "& .MuiFilledInput-root": {
    height: 56,
    padding: "0px 12px",
    "& .MuiFilledInput-input": {
      padding: "7px 4px",
    },
  },
  "& .search-date-container": {
    marginTop: "24px",
    display: "flex",
    flexWrap: "nowrap",
    gap: "16px",
    "& .search-date-box": {
      maxWidth: "350px",
      width: "100%",
    },
    "& .search-button": {
      marginTop: "29px",
      display: "flex",
      alignItems: "center",
    },
  },

  ["@media only screen and (max-width: 600px)"]: {
    "& .search-date-container": {
      flexDirection: "column",
      "& .search-date-box": {
        maxWidth: "100%",
      },
      "& .search-button": {
        marginTop: "0",
        width: "100%",
      },
    },
  },
});

const StyledHeadingPage = styled("div")({
  marginTop: 16,
  marginBottom: 16,
  "& .wrap-search": {
    marginTop: 16,
    "& .fill": {
      display: "flex",
    },
  },
});

const StyledBoxSearch = styled(Box)({
  marginTop: 22,
  "& .label": {
    fontWeight: 600,
    fontSize: 14,
    marginBottom: 8,
  },
});

const StyledWrapFilter = styled("div")({
  width: "100%",
  display: "flex",
  alignItems: "center",
  marginBottom: 36,
  ["@media (min-width: 0px)"]: {
    flexDirection: "column",
  },
  ["@media (min-width: 900px)"]: {
    flexDirection: "row",
  },
  "& .search-name, .search-date": {
    width: "100%",
  },
  "& .wrap-search-action": {
    marginTop: 50,
    display: "flex",
    justifyContent: "flex-start",
    "& .btn-export": {
      marginLeft: 8,
    },
  },
});

const ProbationStatus = () => {
  const { result: userProfile } = useSelector((state) => state.userProfile);
  const { result: affiliateOrganizationList } = useSelector(
    (state) => state.affiliateOrganization
  );
  const { result: employeesProbationStatus, isFetching } = useSelector(
    (state) => state.employeesProbationStatus
  );
  const { result: probationAnswer } = useSelector(
    (state) => state.probationAnswer
  );
  const { t, i18n } = useTranslation();

  const [inputSearch, setInputSearch] = useState(dayjs());
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [detail, setDetail] = useState(null);
  const [user, setUser] = useState(null);
  const [configAlert, setConfigAlert] = useState({ openAlert: false, alertType: "" })
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleEachDetail = (row, empInfo) => {
    setDetail(row);
    setUser(empInfo);
    setOpenDialog(true);
  };

  const handleClickSearch = () => {
    if (selectedCompany) {
      dispatch(
        getProbationEmployeesStatus({
          idCompany: selectedCompany.idCompany,
          year: inputSearch.get("year"),
        })
      );
      dispatch(getAllEmployees(null, selectedCompany.idCompany));
    }
  };

  const handleSubmit = async (formData) => {
    const res = await dispatch(updateProbationAdmin(formData))
    if (res.status === 200) {
      setConfigAlert({ openAlert: true, alertType: "success" })
    } else {
      setConfigAlert({ openAlert: true, alertType: "error" })
    }
    handleClose();
    dispatch(
      getProbationEmployeesStatus({
        idCompany: selectedCompany.idCompany,
        year: inputSearch.get("year"),
      })
    );
  }

  const handleCloseAlert = () => {
    setConfigAlert({ openAlert: false, alertType: "" });
  };

  useEffect(() => {
    dispatch(getAffiliateOrganization());
  }, []);

  useEffect(() => {
    if (
      userProfile &&
      affiliateOrganizationList &&
      affiliateOrganizationList.length > 0
    ) {
      const foundCompany = affiliateOrganizationList.find(
        (x) => x.idCompany === userProfile.idCompany
      );
      if (foundCompany) {
        setSelectedCompany(foundCompany);
      } else {
        setSelectedCompany(affiliateOrganizationList[0]);
      }
    }
  }, [userProfile, affiliateOrganizationList]);

  return (
    <StyledRoot className="page">
      <Container
        maxWidth="lg"
        style={{ paddingTop: "24px", paddingBottom: "24px" }}
      >

        <Box marginBottom="24px">
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link
              style={{ textDecoration: "none", color: "inherit" }}
              to={"/employees-report"}
            >
              {`${t("AllReports")}`}
            </Link>
            <Typography color="text.primary">{`${t("EmpProbationStatus")}`}</Typography>
          </Breadcrumbs>
        </Box>

        <StyledHeadingPage>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Typography variant="h4">{t("EmpProbationStatus")}</Typography>
            </Grid>
          </Grid>
        </StyledHeadingPage>
        <CardStyle style={{ padding: "16px" }}>
          <StyledWrapFilter>
            <Grid container spacing={2} alignItems="end">
              <Grid item xs={12} sm={6} md={3}>
                <StyledBoxSearch>
                  <Typography className="label" color="text.third">
                    {t("SearchDate")}
                  </Typography>
                  <div className="search-date">
                    <DatePickerCustom
                      inputFormat="YYYY"
                      value={inputSearch}
                      minDate={dayjs().subtract(3, 'year')}
                      maxDate={dayjs()}
                      name="start"
                      views={["year"]}
                      openTo="year"
                      onChange={(newValue) => setInputSearch(newValue)}
                      disabled={isFetching}
                    />
                  </div>
                </StyledBoxSearch>
              </Grid>
              <Grid item xs={12} sm={6} md={5}>
                <StyledBoxSearch>
                  <Typography className="label" color="text.third">
                    {t("SelectCompany")}
                  </Typography>
                  <SelectAffiliateCompany
                    options={affiliateOrganizationList}
                    value={selectedCompany}
                    onChange={(_, value) =>
                      setSelectedCompany(value)
                    }
                    disabled={isFetching}
                  />
                </StyledBoxSearch>
              </Grid>
              <Grid item xs={12} md={4} display="flex" gap="8px">
                <ButtonBlue
                  variant="contained"
                  startIcon={<SearchIcon />}
                  onClick={handleClickSearch}
                  disabled={isFetching || !selectedCompany}
                >
                  {t("Search")}
                </ButtonBlue>
                <ButtonBlue
                  variant="outlined"
                  startIcon={<FileDownloadIcon />}
                  onClick={() => ProbationXlsxFile(t, employeesProbationStatus, probationAnswer)}
                  disabled={isFetching || !employeesProbationStatus}
                >
                  {t("Download")}
                </ButtonBlue>
              </Grid>
            </Grid>
          </StyledWrapFilter>
          {isFetching ? (
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <img src={LoadingGif} style={{ width: 100, height: 100 }} />
            </Box>
          ) : (
            employeesProbationStatus && (
              <TableProbationStatus
                rows={employeesProbationStatus}
                handleEachDetail={handleEachDetail}
              />
            )
          )}
        </CardStyle>
        {openDialog && (
          <DialogProbation
            open={openDialog}
            handleClose={handleClose}
            user={user}
            detail={detail}
            handleSubmit={handleSubmit}
          />
        )}
        {configAlert.openAlert && (
          <AlertResponse
            open={configAlert.openAlert}
            handleClose={handleCloseAlert}
            alertType={configAlert.alertType}
          />
        )}
      </Container>
    </StyledRoot>
  );
};

export default ProbationStatus;
