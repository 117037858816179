import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import {
  Box,
  CardContent,
  Chip,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";

import DeleteOutlineRoundedIcon from "@mui/icons-material/DeleteOutlineRounded";
import AttachFileIcon from "@mui/icons-material/AttachFile";

import ChipWithDrawCustom from "../../../../shared/chipWithDrawCustom";
import DialogConfirmWithdraw from "../../../../shared/general/DialogConfirmWithdraw";
import CardStyled from "../../../../shared/general/Card";

import StatusRequest from "../../../../user/leave/statusRequest";
import { useTranslation } from "react-i18next";

const StyledChip = styled(Chip)({
  backgroundColor: "#ebf5ff",
  color: "#007aff",
  fontSize: 16,
  fontWeight: 500,
  "&.not-approve": {
    backgroundColor: "#EEEEEE",
    color: "#919eab",
  },
});

const StyledWrapChip = styled("div")({
  display: "flex",
  justifyContent: "center",
  margin: "8px 0",
});

const StyledWrapHead = styled("div")({
  display: "flex",
  alignItems: "center",
  "&.approve": {
    justifyContent: "space-between",
  },
  "&.not-approve": {
    justifyContent: "flex-end",
  },
});

const StyledTextDate = styled(Typography)({
  "&.not-approve": {
    color: "#919eab",
    fontStyle: "oblique",
    fontWeight: 400,
  },
});

const CardHistoryLeave = (props) => {
  const {
    data,
    handleDeleteLeave,
    setDataWithDraw,
    // openConfirmDialog,
    // setOpenConfirmDialog,
  } = props;

  const { t, i18n } = useTranslation();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const { result: leaveWithdrawEmployeesList } = useSelector(
    (state) => state.leaveEmployeesWithDraw
  );

  const handleClickOpenDialog = () => {
    setDataWithDraw(data);
    setOpenConfirmDialog(true);
  };

  const handleClickCloseDialog = () => {
    setOpenConfirmDialog(false);
  };

  const renderClassname = () => {
    if(data.withdraw && data.withdraw.isApprove === 1){
      return "not-approve"
    }

    if(data.isDoubleApproval === 1){

      if(data.isManagerLV1Approve === 0 || data.isManagerLV2Approve === 0){
        return "not-approve";
      } else {
        return "approve";
      }

    } else if (data.approvalLevel === 1) {
      if(data.isManagerLV1Approve === 0){
        return "not-approve"
      } else {
        return "approve"
      }
    } else if (data.approvalLevel === 2) {
      if(data.isManagerLV2Approve === 0){
        return "not-approve"
      } else {
        return "approve"
      }
    } else {
      return "not-approve";
    }
  };

  const renderStatusRequest = (data) => {

    let statusValue = null;
    
    if(data.isDoubleApproval === 1){
       if(data.isManagerLV1Approve === null || (
        data.isManagerLV1Approve === 1 && data.isManagerLV2Approve === null
       )) {
        statusValue = 0;
       } else if (data.isManagerLV1Approve === 1 && data.isManagerLV2Approve === 1){
        statusValue = 1;
       } else if (data.isManagerLV1Approve === 0 || data.isManagerLV2Approve === 0){
        statusValue = 2;
       }
    } else if (data.approvalLevel === 1) {
      if(data.isManagerLV1Approve === null){
        statusValue = 0;
      } else if(data.isManagerLV1Approve === 1){
        statusValue = 1;
      } else if(data.isManagerLV1Approve === 0){
        statusValue = 2;
      }
    } else if (data.approvalLevel === 2) {
      if(data.isManagerLV2Approve === null){
        statusValue = 0;
      } else if(data.isManagerLV2Approve === 1){
        statusValue = 1;
      } else if(data.isManagerLV2Approve === 0){
        statusValue = 2;
      }
    }

    if(data.withdraw && data.withdraw.isApprove === 1){
      return null;
    }

    return <StatusRequest status={statusValue}/>
  }

  const renderWithdrawStatus = (data) => {
    if(data.withdraw && data.withdraw.isApprove === 1){
      return <ChipWithDrawCustom />
    }
  }

  return (
    <>
      <CardStyled style={{ height: "100%" }}>
        <CardContent style={{ padding: 16 }}>
          <StyledWrapHead
            className={data.isApprove !== 0 ? "approve" : "not-approve"}
            style={{justifyContent: "space-between"}}
          >
            <Box display="flex" gap="8px">
              {data.filename && (
                <IconButton
                  aria-label="delete"
                  size="small"
                  component={"a"}
                  href={data.fileURL}
                  target="_blank"
                >
                  <AttachFileIcon fontSize="inherit" />
                </IconButton>
              )}
            </Box>

            <Box>
              {renderStatusRequest(data)}
              {renderWithdrawStatus(data)}
            </Box>
          </StyledWrapHead>
          <StyledWrapChip>
            <StyledChip
              className={renderClassname()}
              label={`${i18n.resolvedLanguage === "th"
                ? data.name
                : data.name_EN ? data.name_EN : ""} ${data.used} 
              ${i18n.resolvedLanguage === "th"
                  ? data.isLeaveCompensate ? t("Unit.ShortHours") : "วัน"
                  : data.isLeaveCompensate ? "hr" : "day"
                }`}
            />
          </StyledWrapChip>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="body2" color="text.secondary">
                {t("Start")}
              </Typography>
              <StyledTextDate
                className={renderClassname()}
                variant="h6"
              >
                {dayjs(data.startText, "DD/MM/YYYY HH:mm").format(
                  i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY"
                )}
              </StyledTextDate>
              <StyledTextDate
                className={renderClassname()}
                variant="h6"
                style={{ height: 32 }}
              >
                {dayjs(data.startText, "DD/MM/YYYY HH:mm").format("HH:mm") !=
                  "00:00"
                  ? dayjs(data.startText, "DD/MM/YYYY HH:mm").format("HH:mm")
                  : ""}
              </StyledTextDate>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="body2" align="right" color="text.secondary">
                {t("End")}
              </Typography>
              <StyledTextDate
                className={renderClassname()}
                variant="h6"
                align="right"
              >
                {dayjs(data.endText, "DD/MM/YYYY HH:mm").format(
                  i18n.resolvedLanguage === "th" ? "DD/MM/BBBB" : "DD/MM/YYYY"
                )}
              </StyledTextDate>
              <StyledTextDate
                className={renderClassname()}
                variant="h6"
                align="right"
                style={{ height: 32 }}
              >
                {dayjs(data.endText, "DD/MM/YYYY HH:mm").format("HH:mm") !=
                  "00:00"
                  ? dayjs(data.endText, "DD/MM/YYYY HH:mm").format("HH:mm")
                  : ""}
              </StyledTextDate>
            </div>
          </div>
          {data.holidayName && (
            <div style={{ padding: 8 }}>
              <Typography
                color="text.secondary"
                style={{
                  fontSize: 14,
                  fontStyle: "oblique",
                }}
              >
                {`** ${data.holidayName}`}
              </Typography>
            </div>
          )}

          <div style={{ padding: 8 }}>
            <Typography
              color="text.third"
              style={{
                fontSize: 16,
                fontStyle: "oblique",
              }}
            >
              {`${t("Description")}: ${
                data.description ? data.description : "-"
              }`}
            </Typography>
          </div>
        </CardContent>
      </CardStyled>
      <DialogConfirmWithdraw
        mode={"2"}
        open={openConfirmDialog}
        data={data}
        handleClose={handleClickCloseDialog}
        handleDeleteLeave={handleDeleteLeave}
      />
    </>
  );
};

export default CardHistoryLeave;